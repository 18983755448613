import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

import { MatSort } from '@angular/material/sort';
import { StateService } from '@uirouter/core';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { BlockedKit } from '@models/core/kit';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'kit-block',
    templateUrl: './kit-block.html',
})
export class KitBlock implements OnInit, AfterViewInit {
    @Input() blockedKit: BlockedKit;

    kitId: number;
    scanId: number;
    loading: boolean = false;
    canadianHospital: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['item_name', 'epc', 'lot_num'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.kitId = this.$state.params.kitId;
        this.scanId = this.$state.params.scanId;
        this.canadianHospital = this.hospitalInfoService.canadianHospital();

        if (!this.blockedKit || !this.blockedKit.kit || !this.blockedKit.untrusted_items) {
            this.$state.go('kit', { kitId: this.kitId, scanId: this.scanId });
        }
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.blockedKit.untrusted_items);
        this.dataSource.sort = this.sort;
    }

    goManageItems(): void {
        this.loading = true;
        this.$state.go('inventory-manage-items', { epc: null });
    }
}
