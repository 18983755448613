import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// description and detail can contain html
interface ConfirmData {
    bins: Array<any>;
    currentBin: any;
}

@Component({
    selector: 'choose-bin-verify-dialog',
    templateUrl: './choose-bin-verify-dialog.html',
    styleUrls: ['./choose-bin-verify-dialog.scss'],
})
export class ChooseBinVerifyDialog {
    bins: Array<any>;
    currentBin: any;
    addToBinSelected: boolean;
    selectedBin: number;

    constructor(
        public dialogRef: MatDialogRef<ChooseBinVerifyDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {}

    ngOnInit() {
        this.bins = this.data.bins;
        this.currentBin = this.data.currentBin;
        this.addToBinSelected = false;
        this.selectedBin = this.bins.length - 1 === 1 ? this.bins[0].id : null;
    }

    toggleAddToBinSelected = () => {
        this.addToBinSelected = !this.addToBinSelected;
    };

    selectBin = (selected) => {
        const data: any = {
            bin_id: selected,
        };

        this.dialogRef.close(data);
    };

    cancel() {
        this.dialogRef.close();
    }
}
