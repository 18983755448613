import { Component, Input } from '@angular/core';
import { NaturalSortService } from '@services/utils/natural-sort.service';

@Component({
    selector: 'pick-list',
    templateUrl: './pick-list.html',
    styleUrls: ['./pick-list.scss'],
})
export class PickList {
    @Input() kitData;
    @Input() billingData;

    constructor() {}

    naturalSort(segments) {
        return NaturalSortService.sort(segments, 'name');
    }
}
