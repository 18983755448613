import { Injectable } from '@angular/core';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { BannerService } from '@services/system/banner.service';
import { FileUploadService } from '@services/utils/file-upload.service';
import { MotorolaResource } from '@resources/motorola-resource.service';
import { DeprecatedMotorolaService } from './deprecated-motorola.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { getMotorolaScanUrl } from '@utils/scanning-util';

import { Scanner } from '@models/hardware/scanner';
import { GroupLoginService } from '@services/login/group-login.service';

const AEROSCOUT_PROXY_PSP: string = 'aeroscout_proxy_v2.psp';
const AEROSCOUT_MAX_RETRIES: number = 5;

interface Feature {
    name: string;
}

interface FileInfo {
    file: ArrayBuffer;
    fileName: string;
}

@Injectable()
export class MotorolaService {
    // service for using Motorola/fx9500/sirit model RFID readers

    private isUsingUpdatedPsp: boolean = true;

    constructor(
        private deprecated: DeprecatedMotorolaService,
        private bannerService: BannerService,
        private hospitalInfoService: HospitalInfoService,
        private groupLoginService: GroupLoginService,
        private scannerUpdatingService: ScannerUpdatingService,
        private fileUploadService: FileUploadService,
        private motorolaResource: MotorolaResource,
        private http: HttpClient,
        private kcMatSnackBarService: KCMatSnackBarService
    ) {
        if (this.hospitalInfoService.isFeatureEnabled('updated_fx9500')) {
            this.isUsingUpdatedPsp = false;
        }
    }

    scan(scanner: Scanner): Promise<any> {
        return this.groupLoginService.getRefreshedHospitalSettings().then((refreshedSettings) => {
            let result: Boolean;
            let features: Feature[] = refreshedSettings.features;
            if (!!features) {
                this.isUsingUpdatedPsp = !!features.find((feature) => feature.name === 'updated_fx9500');
            }
            if (this.isUsingUpdatedPsp) {
                return this.performScan(scanner);
            } else {
                return this.deprecated.scan(scanner);
            }
        });
    }

    checkStatus(device): Promise<any> {
        return this.deprecated.checkScannerStatus(device);
    }

    setupUpdateMessageAndAlert(scanner): void {
        this.bannerService.systemCaution =
            "Scanner update in progress. Please don't use the scanner or close the browser window until the update is complete.";
        this.bannerService.systemCautionNotDismissable = true;

        this.scannerUpdatingService.isUpdating = true;

        scanner.loading = true;
        scanner.updating = true;
    }

    resetUpdateIndicators(scanner): void {
        this.bannerService.systemCautionNotDismissable = false;
        scanner.loading = false;
        scanner.updating = false;
        this.scannerUpdatingService.isUpdating = false;
    }

    resetMessageAndAlert(scanner): void {
        this.resetUpdateIndicators(scanner);
        this.bannerService.systemCaution = undefined;
        this.scannerUpdatingService.updateProgress(1, 'Scanner update complete');
        this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, 'Scanner update complete and ready for use.');
        window.onbeforeunload = undefined;
    }

    async healthCheckOrInstall(scanner, fileName: string): Promise<any> {
        try {
            const response = await this.motorolaResource.healthCheckFile(scanner.value, fileName);
            if (!response) {
                const installResponse = await this.motorolaResource.installPspFile(scanner.value, fileName, true);
                await this.motorolaResource.pause();
            }
        } catch {
            throw { message: `Unable to install file: ${fileName}` };
        }
    }

    checkUploadFile(scanner): Promise<any> {
        return this.healthCheckOrInstall(scanner, 'upload_v2.psp');
    }

    checkKitcheckCommand(scanner): Promise<any> {
        return this.healthCheckOrInstall(scanner, 'kitcheck_command.psp');
    }

    // scanner must be in "standby" mode to successfully run firmware upgrade command
    // TODO: add this command to the kitcheck_command.psp upgrade_firmware function
    async setStandbyMode(scanner): Promise<any> {
        const response = await this.motorolaResource.runRawPspCommand(scanner.value, 'setup.operating_mode = standby');
        if (!response?.result) {
            console.log('Failed to executed command', response);
        }
    }

    private uploadSettingsPsp(url) {
        const fileName = 'settings_v3.psp';
        return this.motorolaResource.installPspFile(url, fileName);
    }

    async checkSettingsPsp(scanner): Promise<any> {
        let settingsPspPresent: boolean;
        try {
            const response = await this.deprecated.settingsV3Psp(scanner.value);
            settingsPspPresent = !!response;
        } catch {
            settingsPspPresent = false;
        }
        if (!settingsPspPresent) {
            this.scannerUpdatingService.updateProgress(0.12, 'Setting up scanner');
            return this.uploadSettingsPsp(scanner.value);
        }
    }

    async checkFiles(scanner) {
        await this.checkUploadFile(scanner);
        await this.checkKitcheckCommand(scanner);
        await this.checkSettingsPsp(scanner);
    }

    async downloadFile(fileKey: string): Promise<FileInfo> {
        let file;
        let fileName;
        try {
            const filesKey = (await this.motorolaResource.hardwareIndex()).scanner;
            const path = filesKey[fileKey].path;
            const response = await this.motorolaResource.downloadFile(path);
            file = response?.body;
            fileName = filesKey[fileKey].file_name;
        } catch {
        } finally {
            if (!file || !(file instanceof ArrayBuffer)) {
                throw { message: `${fileKey} file was not downloaded correctly.` };
            }
        }

        return {
            file,
            fileName,
        };
    }

    async uploadFile(scanner, fileInfo: FileInfo): Promise<void> {
        let uploaded: boolean;
        try {
            const formData = await this.fileUploadService.getFormData(fileInfo.file, fileInfo.fileName);
            const response = await this.motorolaResource.uploadFile(scanner.value, formData, true);
            uploaded = response?.result;
        } catch {
            uploaded = false;
        } finally {
            if (!uploaded) {
                throw { message: `Failed to upload ${fileInfo.fileName} to scanner.` };
            }
        }
    }

    async rebootScanner(scanner): Promise<void> {
        this.motorolaResource.runPspCommand(scanner.value, 'reboot');
    }

    async handleRawCommand(command: Promise<any>): Promise<boolean> {
        let commandRun: boolean;
        try {
            const response = await command;
            commandRun = response?.result === 'ok';
        } catch (err) {
            console.error(err);
            commandRun = false;
        }
        return commandRun;
    }

    async runCertUpgradeCommand(scanner, privateKey: FileInfo, cert: FileInfo) {
        const certUpdated = await this.handleRawCommand(
            this.motorolaResource.runCertUpgradeCommand(scanner.value, privateKey.fileName, cert.fileName)
        );
        if (!certUpdated) {
            console.log(`Failed certificate installation ${cert.fileName}`);
            throw { message: `Failed to install certificate on scanner` };
        }
    }

    async runFirmwareUpgradeCommand(scanner, firmware: FileInfo): Promise<void> {
        const firmwareUpdated = await this.handleRawCommand(
            this.motorolaResource.runFirmwareUpgradeCommand(scanner.value, firmware.fileName)
        );
        if (!firmwareUpdated) {
            console.log(`Failed firmware file installation ${firmware.fileName}`);
            throw { message: `Failed to install firmware package on scanner` };
        }
    }

    async update(scanner, updateFn: () => Promise<void>): Promise<void> {
        try {
            this.scannerUpdatingService.updateProgress(0.01, 'Starting update process');
            this.setupUpdateMessageAndAlert(scanner);
            this.scannerUpdatingService.updateProgress(0.05, 'Setting up Scanner');
            await this.checkFiles(scanner);
            await this.setStandbyMode(scanner);
            await updateFn();
            this.scannerUpdatingService.updateProgress(0.9, 'Rebooting scanner');
            await this.rebootScanner(scanner);
            await this.deprecated.waitForScannerOnline(scanner.value, 10, 10000);
            await this.deprecated.updateSettings(scanner);
            this.resetMessageAndAlert(scanner);
        } catch (error) {
            console.warn(error);
            this.scannerUpdatingService.updateProgress(1, 'Scanner Update Failed');
            this.bannerService.systemCaution =
                'We were unable to complete the update of your scanner. Please contact our 24/7 KitCheck Support department at 786-548-2432 ext 2 for assistance in completing the update process.';
            this.resetUpdateIndicators(scanner);
        }
    }

    updateFirmware(scanner): Promise<void> {
        const updateFn = async (): Promise<void> => {
            this.scannerUpdatingService.updateProgress(0.25, 'Download Firmware');
            const firmware = await this.downloadFile('firmware');
            this.motorolaResource.pause();
            this.scannerUpdatingService.updateProgress(0.4, 'Uploading Firmware');
            await this.uploadFile(scanner, firmware);
            await this.motorolaResource.pause();
            this.scannerUpdatingService.updateProgress(0.5, 'Installing firmware');
            this.bannerService.systemCaution = 'Scanner update in progress';
            await this.runFirmwareUpgradeCommand(scanner, firmware);
        };
        return this.update(scanner, updateFn);
    }

    updateSSL(scanner): Promise<void> {
        const updateFn = async (): Promise<void> => {
            this.scannerUpdatingService.updateProgress(0.25, 'Downloading Files');
            const privateKey = await this.downloadFile('private_key');
            const cert = await this.downloadFile('cert');
            this.scannerUpdatingService.updateProgress(0.4, 'Uploading Files');
            await this.uploadFile(scanner, privateKey);
            await this.motorolaResource.pause();
            await this.uploadFile(scanner, cert);
            await this.motorolaResource.pause();
            this.scannerUpdatingService.updateProgress(0.5, 'Installing certificates');
            this.bannerService.systemCaution = 'Scanner update in progress';
            await this.motorolaResource.runCertUpgradeCommand(scanner.value, privateKey.fileName, cert.fileName);
        };
        return this.update(scanner, updateFn);
    }

    updateHardwareConfig(scanner: Scanner) {
        return this.deprecated.updateSettings(scanner);
    }

    private performScan(scanner: Scanner): Promise<any> {
        let result: Promise<any>;
        result = this.scanMotorolaFirmware(scanner).catch((e: any) => {
            throw { msg: 'failed updated moto scan' };
        });
        return result;
    }

    private scanMotorolaFirmware(scanner: Scanner): Promise<any> {
        return this.motorolaResource.runScanRequest(scanner, this.isUsingUpdatedPsp);
    }

    // aeroscout functions
    aeroscoutLogin(scanner: Scanner, aeroscoutHostUrl: string, authorizationHeader: any, retriesCount = 0) {
        if (retriesCount > AEROSCOUT_MAX_RETRIES) {
            return Promise.reject();
        } else {
            return fetch(
                `${scanner.value}/user_apps/${AEROSCOUT_PROXY_PSP}?aeroscout_url=${encodeURIComponent(
                    aeroscoutHostUrl
                )}`,
                /* eslint-disable */
                { headers: { Authorization: authorizationHeader } }
                /* eslint-enable */
            )
                .then((result) => {
                    if (result.ok) {
                        return result;
                    } else {
                        return this.installAeroscoutPsps(scanner, result).then(() => {
                            return this.aeroscoutLogin(scanner, aeroscoutHostUrl, authorizationHeader, ++retriesCount);
                        });
                    }
                })
                .catch((error) => {
                    return this.installAeroscoutPsps(scanner, error).then(() => {
                        return this.aeroscoutLogin(scanner, aeroscoutHostUrl, authorizationHeader, ++retriesCount);
                    });
                });
        }
    }

    aeroscoutSearch(
        scanner: Scanner,
        aeroscoutHostUrl: string,
        authorizationHeader: any,
        assetName: string,
        retriesCount = 0
    ) {
        if (retriesCount > AEROSCOUT_MAX_RETRIES) {
            return Promise.reject();
        } else {
            return fetch(
                `${scanner.value}/user_apps/${AEROSCOUT_PROXY_PSP}?` +
                    `name=${encodeURIComponent(assetName)}&` +
                    `aeroscout_url=${encodeURIComponent(aeroscoutHostUrl)}`,
                /* eslint-disable */
                { headers: { Authorization: authorizationHeader } }
                /* eslint-enable */
            )
                .then((result) => {
                    if (result.ok) {
                        return result
                            .json()
                            .then((d) => {
                                return { data: d };
                            })
                            .catch(() => {
                                // ignore bad json (aeroscout returns random crap sometimes)
                                return { data: null };
                            });
                    } else {
                        return this.installAeroscoutPsps(scanner, result).then(() => {
                            return this.aeroscoutSearch(
                                scanner,
                                aeroscoutHostUrl,
                                assetName,
                                authorizationHeader,
                                ++retriesCount
                            );
                        });
                    }
                })
                .catch((error) => {
                    return this.installAeroscoutPsps(scanner, error).then(() => {
                        return this.aeroscoutSearch(
                            scanner,
                            aeroscoutHostUrl,
                            assetName,
                            authorizationHeader,
                            ++retriesCount
                        );
                    });
                });
        }
    }

    private installAeroscoutPsps(scanner: Scanner, error) {
        // if the file doesn't exist at all we will get dinged on the OPTIONS request and there will be no status code
        // if additional files are needed the response will be 404,
        // and we will have a header specifying the missing file
        let downloadFile = `${AEROSCOUT_PROXY_PSP}`;
        if (error.headers) {
            downloadFile = error.headers.get('x-additional-files') || downloadFile;
        }

        if (error.status && error.status !== 404) {
            return Promise.reject(error);
        } else {
            return this.motorolaResource.installPspFile(scanner.value, downloadFile);
        }
    }
}
