import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BannerService } from '@services/system/banner.service';
import { FileUploadService } from '@services/utils/file-upload.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { PrintResource } from '@resources/print-resource.service';
import { MotorolaResource } from '@resources/motorola-resource.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { Scanner } from '@models/hardware/scanner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    getMotorolaScanUrlDeprecated,
    getMotorolaScanUrlV1Deprecated,
    getMotorolaDownloadFileDeprecated,
} from '@utils/scanning-util';
import { ConfigurationProvider } from '../config/configuration';

import * as $ from 'jquery';

const COMMAND_PSP = 'kitcheck.psp';
const NEW_PSP_FILE_LIST = ['kitcheck_command.psp', 'kitcheck_dynamic.psp', 'kitcheck_static.psp', 'upload_v2.psp'];

@Injectable()
export class DeprecatedMotorolaService {
    // service that holds deprecated functionality for Motorola/fx9500/sirit model RFID readers
    // this service & all references should be removed once the "enhanced_moto_scan" feature flag has been rolled out to all customers

    constructor(
        private bannerService: BannerService,
        private configuration: ConfigurationProvider,
        private fileUploadService: FileUploadService,
        private hardwareResource: HardwareResource,
        private http: HttpClient,
        private kcMatSnackBarService: KCMatSnackBarService,
        private printResource: PrintResource,
        private motorolaResource: MotorolaResource,
        private scannerUpdatingService: ScannerUpdatingService
    ) {}

    public scan(scanner: Scanner): Promise<any> {
        let result: Promise<any>;

        if (scanner.hardware_settings.scan_filename === 'kitcheck.psp') {
            result = this.scanMotorolaFirmwareV1(scanner).catch((e: any) => {
                throw { msg: 'failed moto v1 scan' };
            });
        } else {
            result = this.scanMotorolaFirmware(scanner).catch((scannerDownloadFile: string) => {
                this.motorolaResource.installPspFile(scanner.value, scannerDownloadFile);
                throw { msg: 'failed moto scan' };
            });
        }

        return result;
    }

    public updateSettings(scanner) {
        return this.settingsPsp(scanner)
            .then((response) => {
                const data = {
                    firmware_version: response.firmware,
                    manufacturer: response.manufacturer,
                    make: response.make,
                    model: response.model,
                    sub_model: response.sub_model,
                    raw_ssl_cert: response.certificate,
                };

                _.extend(scanner, { sub_model: response.sub_model });

                return this.hardwareResource.hardwareConfig(scanner.id, data).then((r) => {
                    return this.hardwareResource.hardwareData(scanner.id).then((response) => {
                        _.extend(scanner, {
                            needs_ssl_update: response.hardware.needs_ssl_update,
                            needs_firmware_update: response.hardware.needs_firmware_update,
                        });

                        if (scanner.needs_firmware_update) {
                            scanner.online = 'UPGRADE';
                        } else if (scanner.needs_ssl_update) {
                            scanner.online = 'UPGRADE_SSL';
                        } else {
                            scanner.online = 'YES';
                        }
                    });
                });
            })
            .catch(() => {
                //ignore if scanner is offline
            });
    }

    public checkScannerStatus(device) {
        if (device.statusCheckPromise) {
            return device.statusCheckPromise;
        }

        device.loading = true;

        const processResponse = (data) => {
            if (_.includes(data, 'processForwardToPrinterResponse')) {
                if (device.needs_firmware_update) {
                    device.online = 'UPGRADE';
                } else if (device.needs_ssl_update) {
                    device.online = 'UPGRADE_SSL';
                } else {
                    device.online = 'YES';
                }
                this.healthCheckNewPspFiles(device);
                this.motorolaResource.installPspFile(device.value, 'fwd2prnt_v2_1.psp');
            } else {
                device.online = 'NO';
            }
        };

        device.statusCheckPromise = this.printResource
            .testTunnel(device)
            .then((data) => {
                return processResponse(data);
            })
            .then(() => {
                return this.updateSettings(device);
            })
            .finally(() => {
                device.loading = false;
                delete device.statusCheckPromise;
            });

        if (_.get(device, 'hardware_settings.ssh_enabled') || _.get(device, 'hardware_settings.ftp_enabled')) {
            this.lockDownScanner(device);
        }

        return device.statusCheckPromise;
    }

    public settingsV3Psp(url) {
        const params = this.addAllowOriginForNonProduction();
        const headers = {
            /* eslint-disable */
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            /* eslint-enable */
        };

        return this.http.get<any>(`${url}/user_apps/settings_v3.psp`, { params: params, headers: headers }).toPromise();
    }

    public waitForScannerOnline(scannerUrl, maxRetries = 3, waitingTime = 5000) {
        let retryCount = 0;
        return new Promise((resolve, reject) => {
            const recursiveRetryStrategy = (): Promise<any> => {
                const handleResponse = (response) => {
                    // if response is not null & has data, scanner is available, otherwise retry
                    if (!!response?.certificate) {
                        resolve(response); // resolve top-level promise on a valid response
                    }
                    retryCount++;
                    if (retryCount < maxRetries && !response) {
                        return new Promise(() =>
                            setTimeout(() => {
                                return recursiveRetryStrategy();
                            }, waitingTime)
                        );
                    } else {
                        return reject(response);
                    }
                };
                return this.settingsV3Psp(scannerUrl)
                    .then((response) => {
                        handleResponse(response);
                    })
                    .catch((response) => {
                        handleResponse(response);
                    });
            };
            recursiveRetryStrategy();
        });
    }

    // private functions

    private scanMotorolaFirmwareV1(scanner: Scanner) {
        const url = getMotorolaScanUrlV1Deprecated(scanner);
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                dataType: 'jsonp',
                jsonpCallback: 'JSON_CALLBACK',
                jsonp: 'callback',
            })
                .done((data) => {
                    resolve(data);
                })
                .fail((_jqXHR, textStatus) => {
                    // When this call succeeds, it seems to end up in the fail handler, but with a status of 200, so act like it succeeded
                    if (_jqXHR.status === 200) {
                        resolve();
                    } else {
                        reject(_jqXHR);
                    }
                });
        });
    }

    private scanMotorolaFirmware(scanner: Scanner) {
        return this.http
            .get(getMotorolaScanUrlDeprecated(scanner))
            .toPromise()
            .then((result) => {
                if (!result) {
                    // CORS errors don't trigger catch blocks, they set "result" to "null" when scan psp is missing
                    // so we simulate an error when we suspect a CORS error has occurred
                    throw {};
                }
                return result;
            })
            .catch((error: HttpErrorResponse) => {
                throw getMotorolaDownloadFileDeprecated(scanner, error);
            });
    }

    private async settingsPsp(scanner): Promise<any> {
        let settings;
        try {
            settings = await this.settingsV3Psp(scanner.value);
        } catch {
            settings = await this.settingsV2Psp(scanner.value);
        }
        return settings;
    }

    private shutOffSsh(scanner) {
        return this.runCommand(scanner, 'reader.stop_service(program=sshd, autostart=false)');
    }

    private shutOffFtp(scanner) {
        return this.runCommand(scanner, 'reader.stop_service(program=ftpd, autostart=false)');
    }

    private lockDownScanner(scanner) {
        return this.shutOffSsh(scanner)
            .catch(
                // Always returns a javascript error
                () => this.shutOffFtp(scanner)
            )
            .finally(() => {
                this.settingsPsp(scanner)
                    .then((response) => {
                        const data = {
                            firmware_version: response.firmware,
                            manufacturer: response.manufacturer,
                            make: response.make,
                            model: response.model,
                            sub_model: response.sub_model,
                            raw_ssl_cert: response.certificate,
                            ssh_enabled: false,
                            ftp_enabled: false,
                        };

                        this.hardwareResource.hardwareConfig(scanner.id, data);
                    })
                    .catch(() => {
                        //ignore if connection to the scanner cannot be obtained
                    });
            });
    }

    private healthCheckNewPspFiles(scanner) {
        NEW_PSP_FILE_LIST.forEach((fileName) => {
            this.motorolaResource.healthCheckFile(scanner.value, fileName).then((response) => {
                // due to CORS: response comes back as "null" if file is missing on scanner
                if (!response) {
                    this.motorolaResource.installPspFile(scanner.value, fileName).catch((err) => {
                        // ignore failed install so we dont interrupt work flow
                    });
                }
            });
        });
    }

    private installUploadPsp(scannerUrl, uploadPsp): Promise<any> {
        return this.motorolaResource
            .downloadPsPFile(uploadPsp, true)
            .then((response) => this.motorolaResource.setupUploadPsp(scannerUrl, response));
    }

    private runCommand(scanner, command) {
        const url = `${scanner.value}/user_apps/${COMMAND_PSP}`; // kitcheck.psp is the only deprecated version that allows for arbitrary commands
        let params = this.addAllowOriginForNonProduction();
        params['command'] = command;
        return this.http.get<any>(`${url}`, { params: params }).toPromise();
    }

    private addAllowOriginForNonProduction() {
        if (this.configuration.getConfiguration().environment !== 'production') {
            return { allowed_origin: '*' };
        } else {
            return {};
        }
    }

    private settingsV2Psp(url) {
        const params = this.addAllowOriginForNonProduction();
        return this.http.get<any>(`${url}/user_apps/settings_v2.psp`, { params: params }).toPromise();
    }
}
