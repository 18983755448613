import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class CartResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    cartData(cartId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}carts/${cartId}`).toPromise();
    }

    cartList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}carts`).toPromise();
    }

    addCart(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}carts`, data).toPromise();
    }

    updateCart(data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}carts/${data.id}`, data).toPromise();
    }

    updateCartLock(cartId: number, data: { dispatch_lock?: string; return_lock?: string }): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}carts/${cartId}/manual_lock`, data).toPromise();
    }

    removeCart(cartId: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}carts/${cartId}`).toPromise();
    }

    cartHistory(cartId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}carts/${cartId}/history`).toPromise();
    }

    adminCart(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}carts/admin`).toPromise();
    }

    dispatch(cart: any): Promise<any> {
        const data = {
            location_id: cart.location_id,
        };
        return this.http.post<any>(`${this.API_ENDPOINT}carts/${cart.id}/dispatch`, data).toPromise();
    }

    removeLocation(cart: any): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}carts/${cart.id}/dispatch/location`).toPromise();
    }

    getCartAllowedLocations(cartId: string): Promise<any> {
        return this.http.get(`${this.API_ENDPOINT}carts/${cartId}/allowed_locations`).toPromise();
    }

    updateCartAllowedLocations(args: { id: string; permittedIds: any[] }): Promise<any> {
        const data = {
            location_ids: args.permittedIds,
        };
        return this.http.put<any>(`${this.API_ENDPOINT}carts/${args.id}/allowed_locations`, data).toPromise();
    }
}
