import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { ReportResource } from '@resources/report-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import * as moment from 'moment';

export interface DateRange {
    start_date: string;
    end_date: string;
}

@Component({
    selector: 'credit-history',
    templateUrl: './credit-history.html',
    styleUrls: ['./credit-history.scss'],
})
export class CreditHistory {
    filters;
    initialDates: DateRange = null;
    isLoading: boolean = false;
    hasData: boolean = false;
    hasRun: boolean = false;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[];
    apiUrl: string = 'reports/not_prepaid_usage_report';
    chargeModel: string;
    dataPx: string;
    downloadDataPx: string;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        protected reportResource: ReportResource,
        protected HospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.chargeModel = this.HospitalInfoService.getHospitalSettings().charge_model_type?.name;
        if (this.chargeModel === ChargeModelTypeName.pay_as_you_go) {
            this.displayedColumns = ['month_year_name', 'usage_count', 'purchased', 'rolled_over'];
            this.dataPx = 'run-credit-hx-report-button';
            this.downloadDataPx = 'download-credit-hx-report-button';
        }
        const start_date = moment().startOf('year').format('YYYY-MM');
        const end_date = moment().format('YYYY-MM');
        this.initialDates = {
            start_date: start_date,
            end_date: end_date,
        };
    }

    refreshReport(filters) {
        const start_date = moment(filters.dateMonth.start_date.value).format('YYYY-MM');
        const end_date = moment(filters.dateMonth.end_date.value).format('YYYY-MM');
        this.filters = {
            start_date: start_date,
            end_date: end_date,
        };

        this.isLoading = true;
        this.reportResource.preTaggedUsageReport(this.filters).then((data) => {
            this.dataSource = new MatTableDataSource(data.reports);
            this.dataSource.paginator = this.paginator;
            this.isLoading = false;
            this.hasRun = true;
            this.hasData = !!this.dataSource.data.length;
        });
    }
}
