import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddCreditsForm } from '@models/core/add-credits-form';
import { PreTaggedMedicationsResource } from '@resources/pre-tagged-medications-resource.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-add-credits-dialog',
    templateUrl: './add-credits-dialog.component.html',
    styleUrls: ['./add-credits-dialog.component.scss'],
})
export class AddCreditsDialogComponent {
    confirmPurchase: boolean = false;
    formLoading: boolean = false;

    constructor(
        protected preTaggedMedicationsResource: PreTaggedMedicationsResource,
        protected stateService: StateService,
        public dialogRef: MatDialogRef<AddCreditsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { form: AddCreditsForm }
    ) {}

    get formattedAmount() {
        return Intl.NumberFormat().format(this.data.form.amount);
    }

    submit() {
        this.formLoading = true;

        this.preTaggedMedicationsResource
            .addCredits(this.data.form)
            .then(() => {
                this.stateService.go('pretagged-medications', {
                    successMessage: true,
                });

                this.closeModal(true);
            })
            .finally(() => {
                this.formLoading = false;
                return true;
            });
    }

    closeModal(success = false) {
        this.dialogRef.close(success);
    }
}
