import { Injectable } from '@angular/core';
import { KitResource } from '@resources/kit-resource.service';
import { KitSummary } from '@models/core/kit-summary';

interface DispatchData {
    is_kit_dispatch: boolean;
    cart_id?: string;
    location_id?: string;
    completed_dispatch_validations?: string[];
}

@Injectable()
export class KitService {
    constructor(private kitResource: KitResource) {}

    dispatchKit(kitToDispatch: KitSummary): Promise<any> {
        const postData: DispatchData = {
            is_kit_dispatch: true,
            completed_dispatch_validations: [],
        };

        if (kitToDispatch.complete) {
            postData.completed_dispatch_validations.push('kit_complete_scan');
        }
        if (kitToDispatch?.cart?.id) {
            postData.cart_id = `${kitToDispatch.cart.id}`;
        }
        if (kitToDispatch?.location?.id) {
            postData.location_id = `${kitToDispatch.location.id}`;
        }

        return this.kitResource.kitDispatch(kitToDispatch.id, postData);
    }
}
