import { Component, Input } from '@angular/core';
import { TableComponent } from '@components/common/table-component';

@Component({
    selector: 'bin-formulary-items',
    templateUrl: './bin-formulary-items.html',
})
export class BinFormularyItems extends TableComponent {
    constructor() {
        super();
    }

    sortFormularyItems(field: string): void {
        this.sortBy(field, this.items);
    }
}
