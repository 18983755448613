import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { GroupsResource } from '@resources/groups-resource.service';

@Injectable({ providedIn: 'root' })
export class GroupIdnMapActionViewUsersResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private groupsResource: GroupsResource
    ) {}

    resolve(): Promise<any> {
        let groupPromise;

        groupPromise = this.groupsResource.getGroupIdnMap('view_users').then((data) => {
            const cleanData = _.pick(data, ['groups_with_hospital', 'roots', 'groups_no_hospital_non_root']);
            const anyParents = _.get(data, 'any_parents', false);
            if ((Object as any).values(cleanData).flat().length > 1 || anyParents) {
                return cleanData;
            } else {
                return [];
            }
        });

        return this.loadingSpinnerService.spinnerifyPromise(groupPromise);
    }
}
