import { Component, OnDestroy, OnInit } from '@angular/core';
import { FreshDeskService } from '@services/vendor/fresh-desk.service';

@Component({
    selector: 'fresh-desk',
    template: '',
})
export class FreshDesk implements OnInit, OnDestroy {
    constructor(private freshDeskService: FreshDeskService) {}

    ngOnInit(): void {
        // Adding the event listener method inside a custom component since we created a Service to wrap the implementation for Fresh Desk initializer.
        // Doing this way is better than calling a service method directly inside of a HTML - it would create an undesirable dependency between view and service
        this.freshDeskService.initiateCall();
    }

    ngOnDestroy(): void {
        this.freshDeskService.destroy();
    }
}
