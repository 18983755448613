import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'memorial-hospital-west',
    templateUrl: './memorial-hospital-west.html',
})
export class MemorialHospitalWest implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }
}
