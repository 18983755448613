import { Component, Input, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';

import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

@Component({
    selector: 'reports-index',
    templateUrl: './reports-index.html',
    styleUrls: ['./reports-index.scss'],
})
export class ReportsIndex implements OnInit {
    // bindings
    @Input() reports;

    // props
    isSystem: boolean;
    reportCategory: string;

    constructor(
        private productModuleService: ProductModuleService,
        protected $stateParams: Transition
    ) {}

    ngOnInit() {
        this.productModuleService.setModule(ModuleTypes.REPORTING);
        this.isSystem = this.$stateParams.params().isSystem;

        if (this.isSystem) {
            this.reportCategory = 'system';
        } else {
            this.reportCategory = 'hospital';
        }
    }
}
