import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'stpatrick',
    templateUrl: './stpatrick.html',
})
export class Stpatrick implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }

    patientPlateText() {
        if ([5843, 5844].indexOf(this.chargeData.kit_master.id) !== -1) {
            return '<p>Please Place Patient</br> Label Here</p>';
        }
        return '<p>Please Addressograph<br />with Patient Plate</p>';
    }
}
