import { Input, OnInit, Component, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';

@Component({
    selector: 'tag-quantity-printer',
    templateUrl: './tag-quantity-printer.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagQuantityPrinter {
    @Input() tag;
    @Input() printers;

    @Output() tagChange = new EventEmitter();

    tagTypeError: boolean;

    constructor(private parseFormErrorsService: ParseFormErrorsService) {}

    ngOnInit() {}

    changePrinter(event) {
        this.tag.printer = event;
        this.tag.timeoutError = false;
        this.tagTypeError = this.tag.item.tag_type && this.tag.item.tag_type.id !== this.tag.printer.tag_type_id;
        this.tagChange.emit(this.tag);
    }

    parseErrors(errors) {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    comparePrinters(o1: any, o2: any): boolean {
        return o1.id === o2?.id;
    }
}
