import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    days_expiring_within: number;
    include_expired_items: number; // 1 if true
}

interface FilterData {
    daysExpiringIn?: number;
    includeExpired?: boolean;
}

@Component({
    selector: 'expiration-filter',
    templateUrl: './expiration-filter.html',
    providers: [{ provide: 'IFilter', useExisting: ExpirationFilter }],
})
export class ExpirationFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: FilterData; // hash from report-filter component
    @Input() reportSubscription: boolean;
    @Input() includeExpired: boolean = true;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeExpiration = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    minControl;
    prefilter: boolean = false;

    ngOnInit() {
        this.prefilter = !!this.filterData;

        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = {};
            this.filterData.daysExpiringIn = this.apiSubscriptionData.days_expiring_within;
            if (this.includeExpired) {
                this.filterData.includeExpired = this.apiSubscriptionData.include_expired_items === 1;
            }
        }

        if (!!this.reportSubscription || this.prefilter) {
            //for reportSubscription modal, we want the selected values to persist from report page
            this.emitFilters();
        } else {
            this.reset();
        }
        this.minControl = new FormControl('', Validators.min(0));
    }

    reset(): void {
        this.filterData = { includeExpired: false, daysExpiringIn: null };
        this.changeExpiration.emit(this.filterData);
        this.incomplete.emit({
            filter: 'expiration',
            incomplete: isEmpty(this.filterData.daysExpiringIn) && this.filterData.includeExpired === false,
        });
    }

    emitFilters(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeExpiration.emit(this.filterData);
        this.incomplete.emit({
            filter: 'expiration',
            incomplete: isEmpty(this.filterData.daysExpiringIn) && this.filterData.includeExpired === false,
        });
    }

    formatForApi(): Array<any> {
        if (this.includeExpired) {
            return [
                { days_expiring_within: this.filterData.daysExpiringIn },
                { include_expired_items: this.filterData.includeExpired },
            ];
        }
        return [{ days_expiring_within: this.filterData.daysExpiringIn }];
    }
}
