import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigurationProvider } from '../config/configuration';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class HttpAuthInterceptService implements HttpInterceptor {
    constructor(
        private configuration: ConfigurationProvider,
        private localStorageService: LocalStorageService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const PHI_AUTH_TOKEN: string = this.localStorageService.get('pat');
        const KC_AUTH_TOKEN: string = this.localStorageService.get('oat');
        const PHI_BASE_ENDPOINT: string = this.configuration.getConfiguration().phi_endpoint;
        const KC_BASE_ENDPOINT: string = this.configuration.getConfiguration().api_endpoint;

        if (req.url.includes(PHI_BASE_ENDPOINT) && !!PHI_AUTH_TOKEN) {
            const cloned = req.clone({
                headers: req.headers.set('authorization', PHI_AUTH_TOKEN),
            });

            return next.handle(cloned);
        } else if (req.url.includes(KC_BASE_ENDPOINT) && !!KC_AUTH_TOKEN) {
            const cloned = req.clone({
                headers: req.headers.set('authorization', KC_AUTH_TOKEN),
            });

            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}
