import { Injectable } from '@angular/core';
import { KCMatSnackBar } from '@components/common/kc-mat-snack-bar/kc-mat-snack-bar';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslationService } from '@services/utils/translation.service';

export enum SnackBarTypes {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

interface TranslationData {
    key: string;
    params?: any;
}

@Injectable()
export class KCMatSnackBarService {
    lastSnackBar: any;

    constructor(
        private snackBar: MatSnackBar,
        private translationService: TranslationService
    ) {}

    openWithTranslate(level: SnackBarTypes, translationData: TranslationData, action?: string, duration?: number): any {
        let translatedMessage = this.translationService.instant(translationData.key, translationData.params || {});
        return this.open(level, translatedMessage, action, duration);
    }

    open(level: SnackBarTypes, message: string, action?: string, duration?: number): any {
        let snackBarRef: any;
        let snackBarConfig: MatSnackBarConfig;

        switch (level) {
            case SnackBarTypes.SUCCESS:
                snackBarConfig = {
                    panelClass: 'kc-mat-snack-bar-success',
                    duration: duration || 5000,
                    data: {
                        action: action || 'buttons.dismiss',
                        message: message,
                        icon: 'check_circle',
                    },
                };
                break;
            case SnackBarTypes.ERROR:
                snackBarConfig = {
                    panelClass: 'kc-mat-snack-bar-error',
                    duration: duration,
                    data: {
                        action: action || 'buttons.dismiss',
                        message: message,
                        icon: 'cancel',
                    },
                };
                break;
            case SnackBarTypes.WARNING:
                snackBarConfig = {
                    panelClass: 'kc-mat-snack-bar-warning',
                    duration: duration || 5000,
                    data: {
                        action: action || 'buttons.dismiss',
                        message: message,
                        icon: 'warning',
                    },
                };
                break;
        }

        snackBarRef = this.snackBar.openFromComponent(KCMatSnackBar, snackBarConfig);
        // save off the handle to this snackbar so we can clear it if the user logs out
        this.lastSnackBar = snackBarRef;

        return snackBarRef;
    }

    clearAll(): void {
        if (!!this.lastSnackBar) {
            this.lastSnackBar.dismiss();
        }
    }
}
