import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ItemResource } from '@resources/item-resource.service';
import { ActionService as ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { TagAssociationBatchesResource } from '@resources/tag-association-batches-resource.service';

// description and detail can contain html
interface ParamData {
    item: any;
}

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'edit-lot-expiration-dialog',
    templateUrl: './edit-lot-expiration-dialog.html',
    styleUrls: ['./edit-lot-expiration-dialog.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class EditLotExpirationDialog {
    item: any;
    newItem: any;
    warningAccept;
    newExpiration;
    updateDisabled: boolean = false;
    noExpirationDate: string;

    fridgeExpirationOK: boolean = false;
    fridgeExpirationAllowed: boolean = false;

    constructor(
        private itemResource: ItemResource,
        private actionService: ActionService,
        private applicationService: ApplicationService,
        private loadingSpinnerService: LoadingSpinnerService,
        private tagAssociationBatchesResource: TagAssociationBatchesResource,
        private translationService: TranslationService,
        public dialogRef: MatDialogRef<EditLotExpirationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.item = this.data.item;
        this.newItem = Object.assign({}, this.item);
        this.fridgeExpirationOK = this.newItem.quantity_fridge_expiration === 1;
        this.fridgeExpirationAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'update_batch_refrigerated_expiration',
            'Update Refrigeration Expirations'
        );
        this.translationService
            .get('admin.update_lots.no_expiration_date')
            .then((translation) => (this.noExpirationDate = translation));
    }

    hasExpirationUpdateAction() {
        return this.actionService.isAllowAction('kits_inventory', 'update_item_expirations', 'Update Expirations');
    }

    editLotExp(batch) {
        let updating = false;
        this.newItem.lot_num = this.newItem.lot_num && this.newItem.lot_num.trim();
        if (this.newItem.lot_num !== this.item.lot_num && this.newItem.expiration !== this.item.expiration) {
            updating = true;
            this.updateLotAndExp();
        } else if (this.newItem.lot_num !== this.item.lot_num) {
            updating = true;
            this.updateOnlyLot(this.newItem, this.item);
        } else if (this.newItem.expiration !== this.item.expiration) {
            updating = true;
            this.updateOnlyDate(this.newItem, this.item);
        }
        if (this.newItem.expiration_fridge !== this.item.expiration_fridge) {
            updating = true;
            this.updateOnlyFridgeDate(this.newItem, this.item);
        }

        if (!updating) {
            this.onCancel();
        }
    }

    updateExpDate(event) {
        this.newItem.expiration = event;
    }

    updateFridgeExpDate(event) {
        this.newItem.expiration_fridge = event;
    }

    updateLotAndExp() {
        const updateNewLot = this.tagAssociationBatchesResource.updateTagAssociationBatches(
            this.item.tag_association_batch_id,
            this.newItem.lot_num,
            true
        );

        const newDateData = {
            ndc: this.item.ndc,
            lot_num: this.newItem.lot_num,
            expiration: moment(this.newItem.expiration).format('YYYY-MM-DD'),
        };

        const updateNewDate = () => this.itemResource.replaceItemDate(newDateData);

        const loadPromise = updateNewLot
            .then(() => updateNewDate())
            .then((response: any) => {
                this.save('lotExp');
                return response;
            })
            .catch((e) => {
                // this seems bad since it looks like we throw the same warning regardless of the error.
                // Probably should add some error checking.
                this.warningAccept = true;
                const data = {
                    ndc: this.item.ndc,
                    lot_num: this.newItem.lot_num,
                };

                this.itemResource.lookupItem(data).then((data: any) => {
                    this.newExpiration = _.first(data.item_expirations as any[]);
                });
            });

        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    showExpirationWarning() {
        return (
            (this.item.expiration || this.newItem.expiration) &&
            this.item.expiration !== moment(this.newItem.expiration).format('YYYY-MM-DD')
        );
    }

    //used after failure of updating to a lot number that already exists but with a different exp date.
    updateLotAndExpOverride(newItem, item) {
        let newExpiration = null;
        if (this.newExpiration && this.newExpiration.expiration_formatted) {
            newExpiration = moment(this.newExpiration.expiration_formatted).format('YYYY-MM-DD');
        }
        const newDateData = {
            ndc: this.item.ndc,
            lot_num: this.item.lot_num,
            expiration: this.newExpiration ? newExpiration : moment(this.newItem.expiration).format('YYYY-MM-DD'),
        };

        const updateNewDate = this.itemResource.replaceItemDate(newDateData);

        const updateNewLot = () =>
            this.tagAssociationBatchesResource.updateTagAssociationBatches(
                this.item.tag_association_batch_id,
                this.newItem.lot_num,
                false
            );

        const loadPromise = updateNewDate
            .then(() => updateNewLot())
            .then((response: any) => {
                if (this.newExpiration) {
                    this.newItem.expiration = newExpiration;
                }
                this.save('lot');
                return response;
            });
        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    updateOnlyLot(newItem, item) {
        this.applicationService.suppressError = true;
        let loadData = this.tagAssociationBatchesResource.updateTagAssociationBatches(
            this.item.tag_association_batch_id,
            this.newItem.lot_num,
            true
        );

        const loadPromise = loadData
            .then((response: any) => {
                this.save('lot');
                return response;
            })
            .catch((e) => {
                // feels like we should check for a specific error code here.
                this.warningAccept = true;
                const data = {
                    ndc: this.item.ndc,
                    lot_num: this.newItem.lot_num,
                };

                this.itemResource.lookupItem(data).then((data: any) => {
                    this.newExpiration = _.first(data.item_expirations as any[]);
                });
            })
            .finally(() => {
                this.applicationService.suppressError = false;
            });
        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    updateOnlyDate(newItem, item) {
        const newDateData = {
            ndc: this.item.ndc,
            lot_num: this.item.lot_num,
            expiration: this.newItem.expiration,
        };
        this.itemResource.replaceItemDate(newDateData).then(() => this.save('expiration'));
    }

    updateOnlyFridgeDate(newItem, item) {
        const newDateData = {
            batch_id: this.item.tag_association_batch_id,
            expiration: this.item.expiration,
            expiration_fridge: this.newItem.expiration_fridge,
        };
        this.itemResource.replaceItemFridgeDateForBatch(newDateData).then(() => this.save('expiration'));
    }

    submitDisabled() {
        //disable on empty lot (unless it started as an empty lot) and invalid date;
        const lotChanged = this.newItem.lot_num !== this.item.lot_num;
        const trimmedLotNum = this.newItem.lot_num && this.newItem.lot_num.trim();
        const dateValid = moment(this.newItem.expiration).isValid();
        return (lotChanged && !trimmedLotNum) || !dateValid;
    }

    save(type) {
        this.dialogRef.close({ newItem: this.newItem, type: type });
    }

    onCancel() {
        this.dialogRef.close();
    }
}
