import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-footer',
    templateUrl: './charge-sheet-template-footer.html',
})
export class ChargeSheetTemplateFooter {
    @Input() chargeData;
    @Input() showHospitalFooterLogo: boolean = false;
    @Input() hospitalLogoSource;

    constructor() {}

    ngOnInit() {}
}
