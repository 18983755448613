import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';
import { BarcodeImgComponent } from '../barcode-img/barcode-img';

@Component({
    selector: 'barcode-scan-field',
    templateUrl: './barcode-scan-field.html',
    styleUrls: ['./barcode-scan-field.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BarcodeScanField),
            multi: true,
        },
    ],
})
export class BarcodeScanField implements ControlValueAccessor {
    private innerValue: string;
    private propagateChange = (_: any) => {};

    @Input() ngFocus: Function;
    @Input() ngBlur: Function;
    @Input() ngEnter: Function;
    @Input() fnClear: Function;
    @Input() hideText: boolean;
    @Input() hideReady: boolean;
    @Input() hideClick: boolean;

    // The following 3 methods must be implemented for ControlValueAccessor
    // Put the value into the control - value is from the ngModel
    writeValue(value: string): void {
        this.innerValue = value;
    }

    // Propogates changes outside of this control
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // Used only for touch input, so left void
    registerOnTouched(fn: any): void {}

    set barcodeValue(value: string) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.propagateChange(this.innerValue);
        }
    }

    get barcodeValue(): string {
        return this.innerValue;
    }

    onFocus() {
        if (this.ngFocus) {
            setTimeout(this.ngFocus);
        }
    }

    onEnter(event) {
        event.preventDefault();
        $('#barcode-scan-field-input').blur();
        if (this.ngEnter) {
            setTimeout(this.ngEnter);
        }
    }

    onBlur() {
        if (this.ngBlur) {
            setTimeout(this.ngBlur);
        }
    }

    onClear() {
        this.barcodeValue = undefined;
    }
}
