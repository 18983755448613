import { Directive, ElementRef, AfterContentInit } from '@angular/core';
import * as $ from 'jquery';

require('./table-component-header.scss');
@Directive({
    selector: '[tableHeaderFixed]',
})
export class TableComponentFixedHeader implements AfterContentInit {
    constructor(private el: ElementRef) {}

    ngAfterContentInit() {
        setTimeout(() => {
            this.setFixedHeaderScroll(this.el);
        });
    }

    private setFixedHeaderScroll(el: ElementRef) {
        setTimeout(() => {
            // When affixed in normal position, set the original offset.
            ($(el.nativeElement) as any).affix({
                offset: {
                    top: $(el.nativeElement).offset().top,
                },
            });
            // When affixed to top, adjust the header width to account for incorrect width
            // calculation with 'postition: fixed'.
            $(el.nativeElement).on('affix.bs.affix', () => {
                let elWidth: number = $(el.nativeElement.offsetParent).outerWidth() + 1;
                $(el.nativeElement).on('affixed.bs.affix', () => {
                    $(el.nativeElement).width(elWidth);
                    $(el.nativeElement).css('width', `${elWidth}px`);
                    // Remove listener.
                    $(el.nativeElement).off('affixed.bs.affix');
                });
            });
        }, 0);
    }
}
