import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';
import { ReportResource } from '@resources/report-resource.service';

import FilterUtils from '@utils/filterUtils';

const reportNameCustom = 'item-recalls';

@Injectable()
export class ItemRecallReportService {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource,
        private reportResource: ReportResource
    ) {}

    itemDescription(recall, scope) {
        const strengthText = recall.item_strength ? ` ${FilterUtils.uomScalarFilter(recall.item_strength)}` : '';
        const packageText = recall.package_size ? ` ${FilterUtils.uomScalarFilter(recall.package_size)}` : '';

        recall.itemDescription = `${recall.item_name}${strengthText},${packageText} ${recall.package_description}`;
        recall.selected = _.includes(scope.filters.recall_ids, recall.id);
    }

    detectReport(reportName) {
        return reportName === reportNameCustom;
    }

    reportData(scope) {
        let reportData: any = {
            recall_ids: _(scope.filters.recalls)
                .select((recall) => recall.selected)
                .map('id')
                .value()
                .join('|'),
        };

        if (scope.reportsCategory === 'system') {
            reportData.group_ids = scope.filters.group_ids.join('|');
        }

        reportData.report_mode = scope.filters.report_mode;

        if (scope.filters.sort && scope.filters.sortOrder) {
            reportData.sort = scope.filters.sort;
            if (scope.filters.sortOrder === 'desc') {
                reportData.sort = '-' + reportData.sort;
            }
        }
        if (scope.filters.page) {
            reportData.page = scope.filters.page;
            reportData.per_page = scope.filters.per_page;
        }

        return reportData;
    }

    toggleRecalls(scope) {
        _.each(scope.filters.recalls, (recall) => {
            recall.selected = scope.filters.selectAllRecalls;
        });
    }

    idnFilterRecalls(groupedRecalls, groupIds, scope) {
        if (_.isUndefined(groupedRecalls)) return;

        let flatMappedRecalls = [];

        groupedRecalls.forEach((group) => {
            if (groupIds === 'all' || _.includes(groupIds, group.id)) {
                flatMappedRecalls = flatMappedRecalls.concat(
                    group.recalls.map((recall) => {
                        recall.hospitalName = group.name;
                        return recall;
                    })
                );
            }
        });

        _.each(flatMappedRecalls, (recall) => this.itemDescription(recall, scope));
        // scope.filters.recalls = scope.filters.recalls || flatMappedRecalls

        return flatMappedRecalls;
    }

    noRecallsSelected(scope) {
        _.every(scope.filters.recalls, { selected: false });
    }

    toggleSelectAllRecalls(scope, value) {
        scope.filters.selectAllRecalls = value;
        this.toggleRecalls(scope);
    }

    resetRecalls(scope) {
        scope.kits = (_ as any).emptyArray(scope.kits);
        scope.bins = (_ as any).emptyArray(scope.bins);
        scope.filters.selectAllRecalls = false;
        this.toggleRecalls(scope);
    }

    setFilterDefaults(scope) {
        scope.showItemRecall = true;
        scope.reportTemplate = 'item-recalls';
        scope.filters.selectAllRecalls = scope.filters.selectAllRecall || false;
        scope.filters.report_mode = scope.filters.report_mode || 'kit';

        let recallsPromise;

        if (scope.reportsCategory === 'system') {
            recallsPromise = this.recallResource.groupedRecallsList().then((data) => {
                scope.groupedRecalls = data.groups;
                scope.filters.recalls = this.idnFilterRecalls(scope.groupedRecalls, 'all', scope);
                scope.anyRecalls = this.idnFilterRecalls(scope.groupedRecalls, 'all', scope);
            });
        } else {
            recallsPromise = this.recallResource.recallsList().then((data) => {
                _.each(data.recalls, (recall) => this.itemDescription(recall, scope));
                scope.filters.recalls = scope.filters.recalls || data.recalls;
            });
        }
        this.loadingSpinnerService.spinnerifyPromise(recallsPromise);
    }

    refreshReport(scope, data) {
        if (!data.recall_ids && scope.filters.recall_ids) {
            data.recall_ids = scope.filters.recall_ids.join('|');
        }

        if (data.recall_ids) {
            return this.reportResource.itemsRecalledReport(data, scope.reportsCategory).then(function success(data) {
                scope.paginationData = {
                    totalRows: data.total_rows,
                    totalPages: data.total_pages,
                    rangeStart: (data.current_page - 1) * scope.PAGE_LIMIT + 1,
                    rangeEnd:
                        data.current_page * scope.PAGE_LIMIT > data.total_rows
                            ? data.total_rows
                            : data.current_page * scope.PAGE_LIMIT,
                };
                scope.currentPage = data.current_page;

                scope.ui.reportRunned = true;
                scope.kits = data.kits;
                scope.bins = data.bins;

                function createFullName(packageEvent) {
                    packageEvent.full_item_name = FilterUtils.packageLongName({
                        item_name: packageEvent.name,
                        item_strength_formatted: packageEvent.item_strength,
                        item_strength_uom: packageEvent.item_strength_uom,
                    });
                }

                _.each(scope.kits, function (kit) {
                    _.each(kit.package_events, createFullName);
                });

                _.each(scope.bins, (item) => {
                    _.each(item.package_events, createFullName);
                });

                scope.ui.reportHasData = (scope.kits && scope.kits.length) || (scope.bins && scope.bins.length);
                scope.reportDate = new Date();
            });
        } else {
            return Promise.resolve();
        }
    }
}
