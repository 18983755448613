import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { Package } from '@models/core/package';
import * as moment from 'moment';

export function isAppUnderGracePeriodState(lockoutDate: string, chargeModelType: ChargeModelTypeName) {
    return !!lockoutDate && chargeModelType === ChargeModelTypeName.opt_out;
}

export function isAppUnderPostLockoutState(lockoutDate: string, chargeModelType: ChargeModelTypeName) {
    return (
        isAppUnderGracePeriodState(lockoutDate, chargeModelType) &&
        moment(lockoutDate).isSameOrBefore(moment(new Date()))
    );
}

export function isPostLockoutStateWithItems(
    lockoutDate: string,
    numberEligibleItems: number,
    chargeModelType: ChargeModelTypeName
) {
    return isAppUnderPostLockoutState(lockoutDate, chargeModelType) && numberEligibleItems > 0;
}

export function isPostLockoutStateForASingleItem(lockoutDate: string, chargeModelType, item: Package) {
    return (
        item.subscription_required &&
        !!lockoutDate &&
        chargeModelType === ChargeModelTypeName.opt_out &&
        moment(lockoutDate).isSameOrBefore(moment(new Date()))
    );
}

export function isScannedAfterLockoutState(lockoutDate: string, scanDate: string) {
    return moment(lockoutDate).isSameOrBefore(moment(scanDate));
}
