import { Pipe, PipeTransform } from '@angular/core';
import { sortDeep, Direction } from '@utils/sort';

@Pipe({
    name: 'orderBy',
    pure: false,
})
export class OrderByPipe implements PipeTransform {
    transform(data, propName: string, direction = true) {
        return sortDeep(data, propName, direction ? Direction.ASC : Direction.DESC);
    }
}
