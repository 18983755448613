import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { TranslationService } from '@services/utils/translation.service';

@Directive({
    selector: '[dateValidator][formControlName],[dateValidator][formControl],[dateValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: KCDateValidatorDirective, multi: true }],
})
export class KCDateValidatorDirective implements Validator, OnInit {
    @Input('dateValidator') dateFormats: Array<string>; // Array of date format strings to compose validation message.
    private invalidDateMessage: string;

    constructor(
        private hostComponent: ElementRef<HTMLFormElement>,
        private translationService: TranslationService
    ) {}

    ngOnInit(): void {
        let formatStr: string = this.dateFormats.join(', ');
        this.invalidDateMessage = this.translationService.instant('errors.invalid_date_format', { formats: formatStr });
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        if (!!control.value && control.value._isAMomentObject) {
            return null;
        } else {
            const value = this.hostComponent.nativeElement.value; // Get the date input string value.
            const valid = this.validateDate(value);
            return !valid ? { invalidDate: this.invalidDateMessage } : null; // Populate the NgModel errors object.
        }
    }

    /**
     * Validate a date by creating a Moment object from the date input string,
     * and checking it's validity against the accepted date format strings.
     */
    validateDate = (value: string): boolean => {
        let mom: moment.Moment = moment(value, this.dateFormats, true);
        let valid: boolean = mom.isValid();
        return valid;
    };
}
