import { Component, Input, ViewChild } from '@angular/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { GroupInfoService } from '@services/core/group-info.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';
import { UserListService } from '@services/core/user-list.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { MatPaginator } from '@angular/material/paginator';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    DEFAULT_SHOW_FIRST_LAST_BUTTONS,
} from '@models/common/report-pagination';
import { group } from '@angular/animations';

//require('./report-user-activity.scss');
@Component({
    selector: 'report-user-activity',
    templateUrl: './report-user-activity.html',
})
export class ReportUserActivity {
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    @Input() users;

    report: Report;
    isDownloadable: boolean = true;
    filters: {
        start_date: string;
        end_date: string;
        user_ids?: number;
        action_names?: string;
    };
    reportDate: Date;
    hasData: boolean = false;
    hasRun: boolean = false;
    isLoading: boolean = false;
    groupId: number;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['action_name', 'user_name', 'user_role', 'created_at'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        protected userListService: UserListService,
        private hospitalInfoService: HospitalInfoService,
        private groupInfoService: GroupInfoService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.reportDate = new Date();
        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'activity'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'user_activity');
        this.groupId = this.groupInfoService.getGroupId();
    }

    refreshReport(event: any): void {
        this.filters = {
            start_date: event.date.start_date,
            end_date: event.date.end_date,
        };

        if (!!event.user) {
            this.filters.user_ids = event.user;
        }

        if (!!event.activity) {
            this.filters.action_names = event.activity;
        }

        this.isLoading = true;
        const promise = this.reportResource.userActivityReport(this.filters).then((rawReportData) => {
            rawReportData.reports.forEach((item) => {
                item.user_name = `${item.first_name} ${item.last_name}`;
                this.users.forEach((user) => {
                    if (user.id === item.id) {
                        // in the group level
                        user.roles_for_group.forEach((group_roles) => {
                            // check if in the same group and then assign roles
                            if (group_roles.group_id === this.groupId) {
                                group_roles.roles.forEach((roles) => {
                                    item.user_role = item.user_role
                                        ? item.user_role.concat(', ', roles.name)
                                        : roles.name;
                                });
                            }
                        });
                    }
                });
            });
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.reportDate = new Date();
            this.isLoading = false;
            this.hasRun = true;
            this.hasData = !!this.dataSource.data.length;
        });
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            start_date: event.date.start_date,
            activity: event.activity,
            user: event.user,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
