import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'auto-complete',
    templateUrl: './auto-complete.html',
})
export class AutoComplete {
    @Input() disabled;
    @Input() ndc;
    @Input() tokenize = true;
    @Input() customAutoCompleteForm;
    @Output() selectNdc = new EventEmitter();
    filteredNdcs;
    autocompleteForm;
    items;
    hasSelection;
    item;
    canadianHospital;
    hasFocus;
    ngClear;

    constructor(
        private formBuilder: FormBuilder,
        protected formularyItemsService: FormularyItemsService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.autocompleteForm =
            this.customAutoCompleteForm ||
            this.formBuilder.group({
                ndc: new FormControl(''),
            });

        //for report subscription modal
        if (this.ndc) {
            this.getNdcs(this.ndc).then((ndcs) => {
                this.items = ndcs.items;
                this.filteredNdcs = ndcs.items.map((item) => item.ndc);
                let item = this.items.find((item) => item.ndc === this.ndc);
                this.autocompleteForm.setValue({ ndc: this.ndc });
                this.pick(item);
            });
        }

        this.filteredNdcs = this.autocompleteForm
            .get('ndc')
            .valueChanges.pipe(
                debounceTime(300),
                tap((value) => (this.ndc = value)),
                switchMap((value) => this.getNdcs(value))
            )
            .subscribe((ndcs) => {
                this.items = ndcs.items;
                this.filteredNdcs = ndcs.items.map((item) => item.ndc);
                if (
                    this.filteredNdcs &&
                    this.ndc &&
                    this.filteredNdcs.length === 1 &&
                    this.ndc.length === this.filteredNdcs[0].length
                ) {
                    let item = this.items.find((item) => item.ndc === this.ndc);
                    this.pick(item);
                } else {
                    // if user enters NDC that doesn't match an option, we still want to emit value
                    this.ndc = this.autocompleteForm.value.ndc;
                    this.selectNdc.emit(this.ndc);
                }
            });

        this.canadianHospital = this.hospitalInfoService.canadianHospital();
    }

    getNdcs(value) {
        return this.formularyItemsService.getFormularyItemsByNDC(value, undefined);
    }

    highlightSearchText = (result) => {
        const search = this.ndc;

        if (search) {
            const match = result.match(new RegExp(search, 'gi'));
            const text = match ? match[0] : undefined;
            return result.replace(new RegExp(search, 'gi'), `<span class="regular">${text}</span>`);
        } else {
            return result;
        }
    };

    loseFocus = () => {
        this.hasFocus = false;
    };

    gainFocus = () => {
        this.hasFocus = true;
    };

    resetSelection = () => {
        this.filteredNdcs = [];
        this.hasSelection = false;
        this.hasFocus = true;
        this.ngClear = false;
        if (!!this.ndc) {
            this.autocompleteForm.setValue({ ndc: '' });
            this.ndc = null;
            this.selectNdc.emit(this.ndc);
        }
    };

    pick(item) {
        this.ndc = item.ndc;
        this.filteredNdcs = [];
        this.hasSelection = true;
        this.item = item;
        this.selectNdc.emit(this.item);
    }
}
