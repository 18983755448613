import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TableComponent } from '@components/common/table-component';
import { isEmpty } from '@utils/objects';
import { Package } from '@models/core/package';
import { ByCEQItem, ByFormularyItem } from '../../models/bin-scan-data';

@Component({
    selector: 'bin-scan-summary',
    templateUrl: './bin-scan-summary.html',
    styleUrls: ['./bin-scan-summary.scss'],
})
export class BinScanSummary extends TableComponent {
    @Input() wrong: Package[];
    @Input() generic: ByCEQItem[];
    @Input() custom: ByFormularyItem[];
    @Input() formulary: ByFormularyItem[];
    @Input() mostRecentScan: boolean;

    @Output() onAdd = new EventEmitter();

    itemsBy: string;
    items: ByCEQItem[] | ByFormularyItem[];

    constructor() {
        super();
    }

    ngOnInit() {
        this.itemsBy = 'generic';
        this.items = this.generic;
    }

    setItemsBy(type: string): void {
        this.itemsBy = type;

        if (type === 'generic') {
            this.items = this.generic;
        } else {
            this.items = this.formulary;
        }
    }

    addFormularyItem(formularyItem) {
        this.onAdd.emit({ item: formularyItem });
    }

    showCustom(): boolean {
        return !isEmpty(this.custom);
    }

    showGeneric(): boolean {
        return !isEmpty(this.generic);
    }

    sort(field): void {
        this.sortBy(field, this.items);
    }
}
