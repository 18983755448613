import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { KitResource } from '@resources/kit-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { PrintResource } from '@resources/print-resource.service';
import { Kit } from '@models/core/kit';
import { Printer } from '@models/core/printer';

interface ParamData {
    kit: Kit;
    printers: Printer[];
}

@Component({
    selector: 'replace-kit-tag-dialog',
    templateUrl: './replace-kit-tag-dialog.html',
    styleUrls: ['./replace-kit-tag-dialog.scss'],
})
export class ReplaceKitTagDialog {
    kit: Kit;
    printers: Printer[];
    printerless: boolean;
    step: string;
    secondsToPrint: number;
    max: number;
    value: number;
    hasBasicTags: boolean;
    newEpc: string;
    scanEpc: string;
    printerId: number;
    selectedPrinter: Printer;
    error: string;

    constructor(
        private barcodeScanService: BarcodeScanService,
        private kitResource: KitResource,
        private localStorageService: LocalStorageService,
        private printResource: PrintResource,
        public dialogRef: MatDialogRef<ReplaceKitTagDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.kit = this.data.kit;
        this.printers = this.data.printers;
        this.printerless = this.printers.length === 0;

        if (this.printerless) {
            this.newEpc = null;
            this.step = 'basic';
        } else {
            this.newEpc = this.kit.epc;
            this.step = 'first';
        }

        this.secondsToPrint = 2;
        this.max = 200;
        this.value = 0;
        this.hasBasicTags = this.localStorageService.get('hasBasicTags') === 'true';
        this.printerId = null;

        this.barcodeScanService.registerListener((scan) => {
            const scanValues = this.barcodeScanService.processBarcode(scan);
            if (scanValues) {
                this.newEpc = scanValues.epc;
            }
        }, 'replace-kit-tag');
    }

    progressBar() {
        this.gotoStep('second');

        const timePerTag = 2180;
        const step = 10;
        const timer = (timePerTag * step + (this.selectedPrinter.is_tunnel ? 4500 : 0)) / this.max;

        this.loop(step, timer);
    }

    loop(step, timer) {
        const tId = setTimeout(() => {
            if (this.value >= 200) {
                //this.cancel();
                this.gotoStep('third');
            } else {
                this.value += step;
                //this.cancel();
                this.loop(step, timer);
            }
        }, timer);
    }

    printZpl(data) {
        const zpl = data.zpl;
        this.newEpc = data.epc;
        this.printResource.printTag(this.selectedPrinter, zpl, 1, data.epc);
        this.progressBar();
    }

    printTag() {
        this.kitResource
            .replaceKitTag(this.kit.id, this.selectedPrinter.id)
            .then((data) => {
                this.value = 0;
                this.printZpl(data);
            })
            .catch((e) => {
                this.error = e.message;
            });
    }

    setPrinter() {
        this.selectedPrinter = this.printers.find((printer) => printer.id === this.printerId);
    }

    replaceTag() {
        this.kitResource
            .replaceKitTagBasic(this.kit.id, this.newEpc)
            .then(() => {
                this.gotoStep('third');
            })
            .catch((e) => {
                this.error = e.message;
            });
    }

    cancel() {
        this.barcodeScanService.removeListener('replace-kit-tag');
        this.dialogRef.close();
    }

    done() {
        this.barcodeScanService.removeListener('replace-kit-tag');
        this.dialogRef.close(this.newEpc);
    }

    basicTagFlow() {
        this.newEpc = null;
        this.gotoStep('basic');
    }

    gotoStep = (step) => {
        this.step = step;
    };

    lastFourEpc(epc) {
        if (epc) {
            return epc.slice(-4);
        }
        return '';
    }

    removeEpc() {
        this.newEpc = undefined;
        this.scanEpc = undefined;
    }
}
