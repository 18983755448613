import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'ummc',
    templateUrl: './ummc.html',
})
export class Ummc implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }

    customPatientPlateText() {
        if (
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32].indexOf(
                this.chargeData.kit_master.id
            ) !== -1
        ) {
            return 'Patient Name/MRN: _______________________<br /><br />Service date: ____________________________';
        }
        return null;
    }
}
