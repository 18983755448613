import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';

@Injectable({ providedIn: 'root' })
export class RecallResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource
    ) {}

    resolve(): Promise<any> {
        const recallPromise = this.recallResource.getRecall(this.$state.transition.params().recallId);
        return this.loadingSpinnerService.spinnerifyPromise(recallPromise);
    }
}
