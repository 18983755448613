import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PackageDescriptionsResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    packageDescriptionList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}package_descriptions`).toPromise();
    }
}
