import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitMasterService } from '@services/core/kit-master.service';

@Injectable({ providedIn: 'root' })
export class SingleKitMasterResolver {
    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitMasterService: KitMasterService
    ) {}

    resolve(): Promise<any> {
        let kitMasterPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kit_master', 'Load single Kit Masters data')) {
            kitMasterPromise = this.kitMasterService.getSingleKitMaster(this.$state.transition.params().kitMasterId);
        } else {
            kitMasterPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(kitMasterPromise);
    }
}
