import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'kit-decommissioned',
    templateUrl: './kit-decommissioned.html',
})
export class KitDecommissioned {
    @Input() decommissioned;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['epc', 'name', 'type'];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;

    constructor(private actionService: ActionService) {}

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.decommissioned);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }
}
