import { Injectable, NgZone } from '@angular/core';
import { StateService } from '@uirouter/angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { BarcodeService } from '@services/core/barcode.service';
import { CartResource } from '@resources/cart-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { KitResource } from '@resources/kit-resource.service';
import { KitSummaryService } from '@services/core/kit-summary.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { ScanActionService } from '@services/core/scan-action.service';
import { TranslationService } from '@services/utils/translation.service';

import * as Bark from 'bark-js';

declare const InstallTrigger: any;

@Injectable()
export class BarcodeScanService {
    listeningForBarcodes: boolean = false;
    scanListenerCallbacks: any = {};
    scanListenerIDs: Array<any> = [];
    noopListener: Function = () => {};
    selected: any;
    simulate: boolean = false;
    isFirefox: boolean = typeof InstallTrigger !== 'undefined';

    //forces scanning to skip the dispatch, and just process the scan
    processOnly: boolean = false;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private applicationService: ApplicationService,
        private barcodeResource: BarcodeResource,
        private barcodeService: BarcodeService,
        private cartResource: CartResource,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private kitResource: KitResource,
        private kitSummaryService: KitSummaryService,
        private loadingSpinnerService: LoadingSpinnerService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private scanActionService: ScanActionService,
        private zone: NgZone,
        private translationService: TranslationService
    ) {
        this.listenForSelectionEvent();
        window['simulateBarcode'] = this.simulateBarcode.bind(this);
    }

    listenForBarcode() {
        if (this.listeningForBarcodes) {
            return;
        }

        let timeout,
            inputString = '';

        this.listeningForBarcodes = true;

        let preventNextKeypressDefault = false;

        $(document).on('keypress.barcodescan', (e) => {
            // This breaks firefox's quicksearch keyboard shortcut
            if (e.key === '/' && !preventNextKeypressDefault && this.isFirefox) {
                e.preventDefault();
            }
            preventNextKeypressDefault = false;

            if (timeout) {
                clearTimeout(timeout);
            }

            const c = String.fromCharCode(e.which);
            // filter out control characters
            if (c.charCodeAt(0) > 16) {
                inputString += c;
            }

            timeout = setTimeout(
                $.proxy(() => {
                    // This puts back firefox's quicksearch keyboard shortcut
                    if (this.isFirefox && inputString.length === 1 && inputString === '/') {
                        preventNextKeypressDefault = true;
                        const e = new $.Event('keydown', {
                            keyCode: 191,
                        });
                        $('body').trigger(e);
                    }
                    if (inputString.length <= 3) {
                        inputString = '';
                        return;
                    }
                    this.executeBarcode(inputString);
                    inputString = '';
                }, this),
                50
            );
        });
    }

    simulateBarcode(inputString) {
        try {
            this.simulate = true;
            this.executeBarcode(inputString);
        } finally {
            this.simulate = false;
        }
    }

    executeBarcode(inputString) {
        this.zone.run(() => {
            if (!this.scanActionService.sendAction(inputString)) {
                this.doExecuteBarcode(inputString);
            }
        });
    }

    clearBarcodeListener() {
        if (this.listeningForBarcodes) {
            $(document).off('keypress.barcodescan');
            this.listeningForBarcodes = false;
        }
    }

    registerListener(callback, id, fresh?) {
        if (fresh) {
            this.clearListeners();
        }

        if (id !== 'noop') {
            this.unblockListeners();
        }

        this.scanListenerCallbacks[id] = callback;

        if (_.includes(this.scanListenerIDs, id)) {
            return;
        }

        this.scanListenerIDs.push(id);
    }
    //must be bound with an arrow function or it will not have access to BarcodeResource
    handleCart = (scanData: any): void => {
        const cartId = scanData.object['id'];
        this.$state.go('manage-cart', { cartId });
    };

    handleKit(scanData: any): void {
        let kitId = this.$state['toParams']['kitId'];
        this.kitResource.kitDispatch(kitId, { cart_id: scanData.object.id }).then(() => {
            this.kitResource.kitData(kitId).then((data) => {
                this.$state.reload().finally(() => {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `Dispatched "${data.kit.physical_label}" to "${data.kit.cart.name}"`
                    );
                });
            });
        });
    }

    inventoryAssignCart(scanData: any, selectedObject: any): void {
        let kitId;
        if (selectedObject.selectedItems.length > 0) {
            kitId = selectedObject.selectedItems[0].id;
        }
        const assignCartPromise = this.kitResource.kitDispatch(kitId, { cart_id: scanData.object.id }).then(() => {
            return this.kitResource.kitData(kitId).then((data) => {
                if (this.$state.current.url === '/inventory') {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `Dispatched "${data.kit.physical_label}" to "${data.kit.cart.name}"`
                    );
                } else {
                    //this will run when using the dispatch button or assignToCart dropdown in the kit details page
                    this.$state.reload().finally(() => {
                        setTimeout(() => {
                            let successMessage = this.translationService.instant(
                                'inventory.assigned_success.single.cart',
                                { cartName: data.kit.cart.name }
                            );
                            this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, successMessage);
                        }, 200);
                    });
                }
            });
        });

        this.loadingSpinnerService.spinnerifyPromise(assignCartPromise);
    }

    inventoryAssignCartOrLocation(scanData: any, selectedObject: any): void {
        let cartsToUpdate = [];
        let kitIds = [];
        selectedObject.selectedItems.forEach((kit) => {
            cartsToUpdate.push(kit.cart);
            kitIds.push(kit.id);
        });

        let dispatchData: any = { cartsToUpdate: cartsToUpdate, kit_ids: kitIds, cart_id: scanData.object.id };

        this.loadingSpinnerService.spinnerifyPromise(this.kitResource.kitDispatchBulk(dispatchData)).then(() => {
            this.cartResource.cartData(scanData.object.id);
        });
    }

    listenForSelectionEvent(): void {
        this.kitSummaryService.observeKitModal().subscribe((data: any) => {
            if (!!data) {
                this.selected = data;
            }
        });
    }

    setProcessOnly(processOnly) {
        this.processOnly = processOnly;
    }

    doExecuteBarcode(data: string): void {
        let location = this.$state.current.url;
        let processedBarcode = this.processBarcode(data);
        let processedBarcodeData = processedBarcode ? processedBarcode.epc : data;
        let barcode;
        if (_.get(processedBarcode, 'type')) {
            processedBarcodeData = processedBarcode;
            barcode = data;
        } else {
            barcode = processedBarcodeData;
        }

        this.applicationService.suppressError = true;
        this.barcodeService
            .barcodeObject(barcode)
            .then((data) => {
                this.applicationService.suppressError = false;
                if (data.object['class'].toLowerCase() === 'cart') {
                    // let's you scan from new assign kit modal dialog without doing any of this stuff (handled in kits-index.tx)
                    if (!this.processOnly) {
                        if (!this.selected) {
                            if (location === '/kit/:kitId/:scanId?breadcrumb') {
                                this.handleKit(data);
                            } else {
                                this.handleCart(data);
                            }
                        } else {
                            if (this.selected.targetType.toLowerCase() === 'cart') {
                                this.inventoryAssignCart(data, this.selected);
                            } else if (this.selected.targetType.toLowerCase() === 'cartlocation') {
                                this.inventoryAssignCartOrLocation(data, this.selected);
                            }
                            this.selected = null;
                        }
                    }
                }
            })
            .finally(() => {
                this.applicationService.suppressError = false;
                // we are passing on processedBarcodeData here because this has to pass the raw scan data to the listener and there it does a call to the backend
                if (this.scanListenerIDs.length > 0) {
                    this.scanListenerCallbacks[_.last(this.scanListenerIDs)](processedBarcodeData);
                }
            });
    }

    removeListener(id) {
        this.selected = null;
        if (_.includes(this.scanListenerIDs, id)) {
            delete this.scanListenerCallbacks[id];
            this.scanListenerIDs = _.without(this.scanListenerIDs, id);
        }
    }

    clearListeners() {
        this.selected = null;
        this.scanListenerCallbacks = {};
        this.scanListenerIDs = [];
    }

    blockListeners() {
        this.registerListener(this.noopListener, 'noop');
    }

    unblockListeners() {
        this.removeListener('noop');
    }

    /*
      The bark library can take a normal EPC and make it like kinda like a GS1
      A couple of clues are that our EPCs start with 8001/8002
      And the GTIN should have an NDC in characters 4-13...

      bad bark:
        0: {ai: '8001', title: 'DIMENSIONS', value: '00030000000000', raw: '00030000000000'}
        1: {ai: '01', title: 'GTIN', value: 'CCCA', raw: 'CCCA'}

      good bark:
        0: {ai: '01', title: 'GTIN', value: '10376329331612', raw: '10376329331612'}
        1: {ai: '21', title: 'SERIAL', value: '22008190', raw: '22008190~'}
        2: {ai: '17', title: 'USE BY OR EXPIRY', value: '2019-02-26', raw: '190226'}
        3: {ai: '10', title: 'BATCH/LOT', value: 'EN8005', raw: 'EN8005~'}
        4: {ai: '30', title: 'VAR. COUNT', value: '6', raw: '6'}
    */

    isGS1(bark_data) {
        const gtin = bark_data.elements.find((x) => x.title === 'GTIN');
        const kcEPC = bark_data.elements.find((x) => x.ai === '8001');
        const regEPC = bark_data.elements.find((x) => x.ai === '8002');

        return !!gtin && !kcEPC && !regEPC && gtin.value.length >= 13;
    }

    parseGS1(strInput) {
        const bark = Bark;
        let bark_data;
        try {
            if (!!this.simulate) {
                bark_data = bark(strInput, { fnc: '~' });
            } else {
                bark_data = bark(strInput);
            }
        } catch {
            return;
        }

        let values;
        if (!!bark_data && this.isGS1(bark_data)) {
            values = {
                ndc: bark_data.elements.find((x) => x.title === 'GTIN').value.substr(3, 10),
                lot: bark_data.elements.find((x) => x.title === 'BATCH/LOT')?.value,
                expiration: bark_data.elements.find((x) => x.title === 'USE BY OR EXPIRY')?.value,
                type: 'GS1-128',
            };
        }

        return values;
    }

    // process different types of barcodes
    processBarcode(strInput) {
        const regexMEBKM = /MEBKM:TITLE:(.+)URL:.*([0-9A-Fa-f]{24}).*/i;
        const regexPoptag = /http:\/\/poptag.co\/qr\/([0-9A-Fa-f]{24})/i;
        const regexEpcOnly = /([0-9A-Fa-f]{24})/i;
        const regexSGTIN198 = /^36[A-Z0-9]{50}$/;
        const regexSGTINPlus = /^F7[A-Z0-9]*/;
        let matches;
        let values;
        const gs1 = this.parseGS1(strInput);

        if (!!gs1) {
            values = gs1;
        } else if (regexMEBKM.test(strInput)) {
            matches = strInput.match(regexMEBKM);
            values = {
                label: matches[1],
                epc: matches[2].toUpperCase(),
            };
        } else if (regexPoptag.test(strInput)) {
            matches = strInput.match(regexPoptag);
            values = {
                epc: matches[1].toUpperCase(),
            };
        } else if (regexSGTIN198.test(strInput)) {
            matches = strInput.match(regexSGTIN198);
            values = {
                epc: matches[0].toUpperCase(),
            };
        } else if (regexSGTINPlus.test(strInput)) {
            matches = strInput.match(regexSGTINPlus);
            values = {
                epc: matches[0].toUpperCase(),
            };
        } else if (regexEpcOnly.test(strInput)) {
            matches = strInput.match(regexEpcOnly);
            values = {
                epc: matches[1].toUpperCase(),
            };
        }
        return values;
    }

    registerInventoryListener() {
        if (_.includes(this.scanListenerIDs, 'inventory')) {
            return;
        }

        this.registerListener((scanData) => {
            const currentState = this.$state.current.name;

            if (this.ndcScanUtilsService.extractNDCFromScan(scanData) !== scanData) {
                // Send the barcode data as an object in the $stateParams.
                this.$state.go('tagging', { type: 'item', barcodeObject: scanData });
                return;
            }

            const barcodePromise = this.barcodeResource
                .barcodeObject(scanData)
                .then((data) => {
                    if (!data || !data.object || !data.object['class']) {
                        return;
                    }
                    if (
                        data.object['class'] === 'Package' &&
                        this.actionService.isAllowAction(
                            'hospital_settings',
                            'view_formulary_item',
                            'Scan Inventory Resolver to manage items'
                        )
                    ) {
                        this.$state.go('inventory-manage-items', { epc: scanData });
                    }
                    if (data.object['decommissioned'] && data.object['decommissioned'] === true) {
                        let message = `This tag ${scanData} has been decommissioned and cannot be re-associated.</br>If you need assistance, please call our 24/7 support line at 786-548-2432 ext. 2.`;
                        this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                    } else {
                        if (
                            data.object['class'] === 'Kit' &&
                            this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kits_inventory',
                                'Scan Inventory Resolver to kit scan'
                            )
                        ) {
                            const kitId = data.object.id;
                            this.kitResource.transferKit(kitId);
                            this.$state.go('kit-scan', {
                                kitId: kitId,
                                scanId: 'latest',
                                barcodeScanFromHome: currentState === 'home',
                            });
                        }
                        if (data.object['class'] === 'Tag') {
                            this.$state.go('tagging', { barcode: scanData });
                        }
                        if (
                            data.object['class'] === 'Bin' &&
                            this.hospitalInfoService.allowShelvedInventory() &&
                            this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_bin',
                                'Scan Inventory Resolver to bin scan'
                            )
                        ) {
                            this.$state.go('bin-scan', { binId: data.object.id, scanId: 'latest' });
                        }
                    }
                })
                .catch((err) => {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                });

            this.loadingSpinnerService.spinnerifyPromise(barcodePromise);
        }, 'inventory');
    }
}
