import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { BinResource } from '@resources/bin-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { PrintResource } from '@resources/print-resource.service';

import { Bin } from '@models/core/bin';
import { Printer } from '@models/core/printer';

@Component({
    selector: 'replace-bin-tag-dialog',
    templateUrl: './replace-bin-tag-dialog.html',
    styleUrls: ['./replace-bin-tag-dialog.scss'],
})
export class ReplaceBinTagDialog {
    constructor(
        public dialogRef: MatDialogRef<ReplaceBinTagDialog>,
        private barcodeScanService: BarcodeScanService,
        private binResource: BinResource,
        private localStorageService: LocalStorageService,
        private printResource: PrintResource,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    bin: Bin;
    hasBasicTags: boolean;
    readonly max: number = 200;
    newEpc: any;
    printerless: boolean;
    printers: Printer[];
    scanEpc: any;
    secondsToPrint: {};
    selectedPrinterId: number = 0;
    selectedPrinter: Printer;
    step: any;
    value: number = 0;
    success: boolean = false;

    preview_step = 'preview';
    basic_tag_step = 'basic';
    progress_step = 'progress';
    finished_step = 'finished';

    ngOnInit() {
        this.bin = this.data.bin;
        this.printers = this.data.printers;
        this.printerless = !this.printers.length;

        this.step = this.printerless ? this.basic_tag_step : this.preview_step;
        this.secondsToPrint = { secondsToPrint: 2 };
        this.hasBasicTags = this.localStorageService.get('hasBasicTags') === true;

        this.barcodeScanService.registerListener((scan) => {
            const scanValues = this.barcodeScanService.processBarcode(scan);
            if (scanValues) {
                this.newEpc = scanValues.epc;
            }
        }, 'replace-bin-tag');
    }

    progressBar() {
        this.gotoStep(this.progress_step);

        const timePerTag = 2180;
        const step = 10;
        const timer = (timePerTag * step + (this.selectedPrinter.is_tunnel ? 4500 : 0)) / this.max;

        this.loop(step, timer);
    }

    loop(step, timer) {
        const tId = setTimeout(() => {
            if (this.value >= this.max) {
                clearTimeout(tId);
                this.gotoStep(this.finished_step);
            } else {
                this.value += step;
                clearTimeout(tId);
                this.loop(step, timer);
            }
        }, timer);
    }

    setCurrentPrinter(printerId) {
        this.selectedPrinter = this.printers.find((printer) => printer.id === printerId);
    }

    printZpl(data) {
        const zpl = data.zpl;
        this.newEpc = data.epc;
        this.printResource.printTag(this.selectedPrinter, zpl, 1, data.epc);
        this.progressBar();
    }

    printTag() {
        this.binResource.replaceBin(this.bin.id, this.selectedPrinterId).then((data) => {
            this.value = 0;
            this.printZpl(data);
        });
    }

    replaceTag() {
        this.binResource
            .replaceBinBasic(this.bin.id, this.newEpc)
            .then((results) => {
                this.success = true;
                this.gotoStep(this.finished_step);
            })
            .catch((error) => {
                // the existing modal code does not handle this error. It probably should
                // but keeping same behaviour for now.
                this.success = false;
                this.gotoStep(this.finished_step);
            });
    }

    cancel() {
        this.barcodeScanService.removeListener('replace-bin-tag');
        this.dialogRef.close();
    }

    done() {
        this.barcodeScanService.removeListener('replace-bin-tag');
        this.dialogRef.close(this.success);
    }

    basicTagFlow() {
        this.gotoStep(this.basic_tag_step);
    }

    gotoStep = (step) => {
        this.step = step;
    };

    lastFourEpc(epc) {
        return !!epc && epc.slice(-4);
    }

    removeEpc() {
        this.newEpc = undefined;
        this.scanEpc = undefined;
    }
}
