import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'longUsername' })
export class LongUsernamePipe implements PipeTransform {
    /**
     * Shortens usernames > 18 chars
     *
     * @remarks
     * Takes a firstname/lastname and compresses them into a display string
     *
     * @param firstName - First Name
     * @param lastName - Last Name
     * @returns John Smith, J. Smitheringsbergs, J. Smith-Longbo...
     *
     */
    transform(firstName: string, lastName: string): string {
        if (String(firstName).length && String(lastName).length) {
            const fullName = firstName + lastName,
                MAX_ALLOWED_CHARS = 18;
            if (fullName.length > MAX_ALLOWED_CHARS) {
                if (lastName.length > MAX_ALLOWED_CHARS - 2) {
                    return `${firstName[0].toUpperCase()}. ${lastName.substring(0, MAX_ALLOWED_CHARS - 3)}...`;
                } else {
                    return `${firstName[0].toUpperCase()}. ${lastName}`;
                }
            } else {
                return `${firstName} ${lastName}`;
            }
        }
    }
}
