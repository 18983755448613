import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { UserResource } from '@resources/user-resource.service';

@Injectable({ providedIn: 'root' })
export class UserResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private userResource: UserResource
    ) {}

    resolve(): Promise<any> {
        const userId = this.$state.transition.params().userId;
        return this.loadingSpinnerService.spinnerifyPromise(this.userResource.getUser(userId)).then(({ user }) => {
            return user;
        });
    }
}
