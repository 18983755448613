import { Injectable } from '@angular/core';

import { ActionService } from '@services/utils/action.service';
import { BroadcastMessageService } from '@services/login/broadcast-message.service';
import { ChargeModelService } from '@services/core/charge-model.service';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { RecallService } from '@services/core/recall.service.ts';

import { Message } from '@models/core/message';
import { prioritizeMessages, formatMessageForDisplay } from '@utils/message-util';

@Injectable()
export class DashboardService {
    constructor(
        private actionService: ActionService,
        private broadcastMessageService: BroadcastMessageService,
        private chargeModelService: ChargeModelService,
        private dashboardResource: DashboardResource,
        private recallService: RecallService
    ) {}

    getDashboardMessages(): Promise<any> {
        let userMsgPromise = this.broadcastMessageService.getUserMessages(true);
        let kitsArchiveMsgPromise = this.dashboardResource.getKitsNeedingArchiveMessage();
        let recallsPromise = null;
        let activeRecallsPromise = null;
        let lockoutMessagePromise = null;

        if (this.chargeModelService.isHospitalUnderGracePeriodState()) {
            lockoutMessagePromise = this.chargeModelService.buildLockoutGracePeriodNotification();
        }

        let showRecallNotice = this.actionService.isAllowAction(
            'administration',
            'view_recalls',
            'Load recalls list data'
        );
        if (showRecallNotice) {
            recallsPromise = this.recallService.buildRecallNotifications();
            activeRecallsPromise = this.recallService.buildActiveRecallNotifications();
        }

        let messagesPromise = Promise.all([
            userMsgPromise,
            recallsPromise,
            activeRecallsPromise,
            kitsArchiveMsgPromise,
            lockoutMessagePromise,
        ]).then((messages: any[]) => {
            messages = [].concat.apply([], messages).filter((x) => x !== null); // flatten array and remove nulls
            messages = prioritizeMessages(messages);
            messages.forEach((message: Message) => {
                formatMessageForDisplay(message);
            });
            return messages;
        });

        return messagesPromise;
    }
}
