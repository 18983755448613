import { Directive, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[onlyascii]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OnlyASCIIValidateDirective),
            multi: true,
        },
    ],
})
export class OnlyASCIIValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return onlyASCIIValidator()(control);
    }
}

export function onlyASCIIValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            const invalidChars = control.value
                .split('')
                .filter((letter) => letter.charCodeAt(0) >= 128)
                .join(' ');
            return invalidChars.length !== 0
                ? { onlyascii: { value: control.value, invalidChars: invalidChars } }
                : null;
        }
        return null;
    };
}
