import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class UserSettingsResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getUserSettings(userId: number): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}users/${userId}/settings/index_with_create`, {}).toPromise();
    }

    setUserSettings(userId: number, data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}users/${userId}/settings`, data).toPromise();
    }
}
