import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { CustomHttpParamEncoder } from '@services/config/custom-http-param-encoder.service';

@Injectable()
export class FormularyItemResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    addFormularyItem(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}formulary_items`, data).toPromise();
    }

    updateFormularyItem(data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}formulary_items/${data.id}`, data).toPromise();
    }

    formularyItem(id: number): Promise<any> {
        const params = {
            deleted: 'true',
        };

        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items/${id}`, { params: params }).toPromise();
    }

    bins(ndc: string, onBehalfOfHospitalId?: number): Promise<any> {
        let params: any;

        if (onBehalfOfHospitalId > 0) {
            params = {
                on_behalf_of_hospital_id: onBehalfOfHospitalId,
            };
        }

        return this.http
            .get<any>(`${this.API_ENDPOINT}formulary_items/bins/${encodeURIComponent(ndc)}`, { params: params })
            .toPromise();
    }

    formularyItemList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items`).toPromise();
    }

    archivedFormularyItemList(): Promise<any> {
        const params = {
            with_deleted: 'true',
        };

        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items`, { params: params }).toPromise();
    }

    archiveFormularyItem(id: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}formulary_items/${id}`).toPromise();
    }

    unarchiveFormularyItem(id: number): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}formulary_items/${id}/undelete`, {}).toPromise();
    }

    getFormularyItemActiveKitMasters(id: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items/${id}/kit_masters`).toPromise();
    }

    getFormularyItemKitBinCount(id: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items/${id}/kits_bins_count`).toPromise();
    }

    formularyItemWithCeq(ceq: any): Promise<any> {
        let params = new HttpParams();

        params = params
            .set('clinical_equivalence_id', ceq.clinical_equivalence_id)
            .set('capacity[scalar]', ceq.capacity.scalar)
            .set('capacity[uom]', ceq.capacity.uom);

        return this.http
            .get<any>(`${this.API_ENDPOINT}formulary_items/with_clinical_equivalence`, { params: params })
            .toPromise();
    }

    search(data: any): Promise<any> {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

        params = params.set('ndc', data.ndc).set('include_in_formulary', data.include_in_formulary);

        if (data.on_behalf_of_hospital_id > 0) {
            params = params.append('on_behalf_of_hospital_id', data.on_behalf_of_hospital_id);
        }

        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items/search`, { params: params }).toPromise();
    }

    expirations(data: any): Promise<any> {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

        params = params.set('ndc', data.ndc);

        if (data.on_behalf_of_hospital_id > 0) {
            params = params.append('on_behalf_of_hospital_id', data.on_behalf_of_hospital_id);
        }

        return this.http.get<any>(`${this.API_ENDPOINT}formulary_items/expirations`, { params: params }).toPromise();
    }

    recallNotification(): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}formulary_items/with_shared_recall_alerts`, {}).toPromise();
    }

    dismissRecallNotification(data: any): Promise<any> {
        let params = { formulary_item_ids: data };
        return this.http
            .put<any>(`${this.API_ENDPOINT}formulary_items/dismiss_shared_recall_alert`, params)
            .toPromise();
    }
}
