import { Component, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';

import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

import { KitMasterType } from '@models/core/kit-master';

import { deleteEmptyKeys } from '@utils/objects';
import { Subscription } from 'rxjs';
import { LocationResource } from '@resources/location-resource.service';
import { CartResource } from '@resources/cart-resource.service';

@Component({
    selector: 'inventory-kit-master-edit',
    templateUrl: './kit-master-edit.html',
    styleUrls: ['./kit-master-edit.scss'],
})
export class KitMasterEdit {
    @Input() kitMaster: any;
    @Input() kitMasterTypes: KitMasterType[];

    @ViewChild(MatSelect) select: MatSelect;
    @ViewChild('fileInput', null) fileInput: ElementRef;
    @ViewChild('kitMasterForm') kitMasterForm: NgForm;

    allowLegacyChargeSheet: boolean;
    creating: boolean;
    fileSizeError: boolean = false;
    fileTypeError: boolean = false;
    fileUploaderItem: FileItem;
    filteredKitMasterGroups: any;
    hasFile: boolean;
    hasNewFile: boolean = false;
    hospitalName: string;
    isSharingEnabled: boolean;
    kitMasterGroups: any;
    newFileName: string;
    original: any;
    pdfUploader = new FileUploader({});
    uploading: boolean = false;
    url: string;
    useNewChargeSheetCustomization: Subscription;
    kitCartLocationAllowlistsEnabled: boolean = false;
    tab: string = 'summary';
    locations: KC.ILocation[] = [];
    carts: KC.ICart[] = [];
    private readonly useNewChargeSheetCustomizationSubscription: Subscription;
    private readonly kitCartLocationAllowlistsEnabledSubscription: Subscription;

    private readonly MAX_PDF_FILE_SIZE = 5242881;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        protected configurationProvider: ConfigurationProvider,
        private dialog: MatDialog,
        private featureFlagService: FeatureFlagService,
        protected groupLoginService: GroupLoginService,
        private hospitalInfoService: HospitalInfoService,
        private kitMasterResource: KitMasterResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private productModuleService: ProductModuleService,
        private translationService: TranslationService,
        private locationResource: LocationResource,
        private cartResource: CartResource
    ) {
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
        this.useNewChargeSheetCustomization = undefined;

        this.useNewChargeSheetCustomizationSubscription = this.featureFlagService
            .getFeatureValue('use-new-charge-sheet-customization')
            .subscribe((flagValue) => {
                this.useNewChargeSheetCustomization = flagValue;
                this.setupUploader();
            });

        this.kitCartLocationAllowlistsEnabledSubscription = this.featureFlagService
            .getFeatureValue('kit-cart-location-allowlists-enabled')
            .subscribe((flagValue) => {
                this.kitCartLocationAllowlistsEnabled = flagValue;
            });
    }

    ngOnDestroy() {
        this.useNewChargeSheetCustomizationSubscription.unsubscribe();
        this.kitCartLocationAllowlistsEnabledSubscription.unsubscribe();
    }

    ngOnInit(): void {
        if (!this.kitMaster) {
            this.creating = true;
            this.kitMaster = {
                fridgeCheck: true,
                completeScanCheck: false,
                types: this.kitMasterTypes,
            };
        } else {
            this.prepareExistingKitMaster();
        }
        this.loadLocations();
        this.loadCarts();
        this.hasFile = this.kitMaster.custom_charge_sheet_filename;

        this.isSharingEnabled = this.hospitalInfoService.hospitalSetting('shared_kits_enabled');
        this.hospitalName = this.hospitalInfoService.getHospitalName();

        this.allowLegacyChargeSheet = this.hospitalInfoService.hospitalSetting('chargesheet_template') !== 'compact';

        let typeGroups = _.groupBy(this.kitMasterTypes, 'display_group_name');

        let groupedTypes = [];
        Object.keys(typeGroups).forEach((key) => {
            groupedTypes.push({ group_name: key, types: typeGroups[key] });
        });

        this.kitMasterGroups = groupedTypes;
        this.filteredKitMasterGroups = _.cloneDeep(this.kitMasterGroups);
    }

    setupUploader() {
        if (this.useNewChargeSheetCustomization) {
            this.url = `${this.configurationProvider.kcEndpointV1()}kit_masters/${this.kitMaster.id}`;
            this.pdfUploader = new FileUploader({
                url: this.url,
                itemAlias: 'custom_charge_sheet_pdf',
                authToken: this.groupLoginService.authToken(),
                allowedFileType: ['pdf'],
                maxFileSize: this.MAX_PDF_FILE_SIZE,
            });

            this.pdfUploader.onAfterAddingFile = async (item: FileItem) => {
                if (this.hasFile) {
                    this.confirmReplacementPDF(item);
                } else {
                    this.confirmPDF(item);
                }
            };

            this.pdfUploader.onWhenAddingFileFailed = async (item: FileLikeObject) => {
                this.fileTypeError = item?.type !== 'application/pdf';
                this.fileSizeError = item?.size > this.MAX_PDF_FILE_SIZE;
            };

            this.pdfUploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
                this.uploading = false;
            };

            this.pdfUploader.onErrorItem = (item: FileItem, response: any, status: any, headers: any) => {
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, JSON.parse(response)['message']);
                this.uploading = false;
            };
        }
    }

    cancel(): void {
        if (!!this.creating) {
            this.$state.go('inventory-kit-masters-list');
        } else {
            this.$state.go('inventory-single-kit-master', { kitMasterId: this.kitMaster.id });
        }
    }

    submit(): void {
        let data: any = {
            name: this.kitMaster.newName,
            chargesheet_extra_info: this.kitMaster.newChargeExtraInfo,
            refrigerated: !this.kitMaster.fridgeCheck, // We invert this so that the wording makes sense on the checkbox
            shared: !!this.kitMaster.shared,
            expiring_soon_days: this.kitMaster.expiring_soon_days && this.kitMaster.expiring_soon_days.toString(),
            dispatch_validations: {},
            allow_new_tags: !this.kitMaster.preventNewTags,
            persist_cart_enabled: this.kitMaster.persistCart,
            persist_location_enabled: this.kitMaster.persistLocation,
            print_charge_sheet_default: this.kitMaster.printChargeSheetDefault,
            mobile_scan_opens_dispatch: this.kitMaster.mobileScanOpensDispatch,
            charge_sheet_customization: this.kitMaster.chargeSheetCustomization,
        };

        if (!!this.kitMaster.completeScanCheck) {
            // always true because there is no way to make this enabled and optional
            data.dispatch_validations = {
                kit_complete_scan: true,
            };
        }

        if (this.kitMaster.kitCleanCheck) {
            data.dispatch_validations.kit_cleaned = !!this.kitMaster.kitCleanRequiredCheck;
        }

        if (this.kitMaster.type) {
            data.kit_master_type_id = (this.kitMaster.type && this.kitMaster.type.id) || null;
        }

        if (!!this.creating) {
            data = deleteEmptyKeys(data); // remove nils on create since they are not necessary

            this.kitMasterResource.addKitMaster(data).then((response) => {
                this.uploadFile();
                this.$state.go('inventory-single-kit-master', { kitMasterId: response.id });
            });
        } else {
            data.id = this.kitMaster.id;

            this.kitMasterResource.updateKitMaster(data).then(() => {
                this.uploadFile();
                this.$state.go('inventory-single-kit-master', { kitMasterId: this.kitMaster.id });
            });
        }
    }

    confirmPDF(item) {
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('kit_master_form.confirm_new_pdf'),
                description: this.translationService.instant('kit_master_form.pdf_verification_warning', {
                    hospital_name: this.hospitalName,
                }),
                okButton: this.translationService.instant('buttons.approve'),
                cancelButton: this.translationService.instant('buttons.disapprove'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.setFile(item);
            } else {
                this.clearUpload(item);
            }
        });
    }

    confirmReplacementPDF(item) {
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('kit_master_form.confirm_new_pdf'),
                description: this.translationService.instant('kit_master_form.pdf_exists_warning', {
                    kit_master_name: this.kitMaster.name,
                }),
                okButton: this.translationService.instant('buttons.approve'),
                cancelButton: this.translationService.instant('buttons.disapprove'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.confirmPDF(item);
            } else {
                this.clearUpload(item);
            }
        });
    }

    setFile(item) {
        this.fileTypeError = item.file?.type !== 'application/pdf';
        this.fileSizeError = item.file?.size > this.MAX_PDF_FILE_SIZE;
        item.method = 'PUT';
        item.withCredentials = false;
        this.hasNewFile = true;
        this.newFileName = item.file.name;
        this.fileUploaderItem = item;
        // have to clear out the input due to an issue with the control not being able to select the same file again, even if it's changed
        this.fileInput.nativeElement.value = '';
    }

    clearUpload(item) {
        this.pdfUploader.cancelAll();
        this.pdfUploader.clearQueue();
        // have to clear out the input due to an issue with the control not being able to select the same file again
        this.fileInput.nativeElement.value = '';
    }

    uploadFile() {
        this.uploading = true;

        if (this.hasNewFile) {
            this.fileUploaderItem.upload();
        }
    }

    disableSubmit(): boolean {
        return (
            !this.kitMaster.newName?.trim() ||
            !this.kitMasterForm ||
            this.kitMasterForm?.invalid ||
            (!this.creating && this.nothingChanged())
        );
    }

    prepareExistingKitMaster(): void {
        this.kitMaster.fridgeCheck = !this.kitMaster.refrigerated;
        this.kitMaster.preventNewTags = !this.kitMaster.allow_new_tags;
        this.kitMaster.persistCart = this.kitMaster.persist_cart_enabled;
        this.kitMaster.persistLocation = this.kitMaster.persist_location_enabled;
        this.kitMaster.printChargeSheetDefault = this.kitMaster.print_charge_sheet_default;
        this.kitMaster.chargeSheetCustomization = this.kitMaster.charge_sheet_customization;
        this.kitMaster.mobileScanOpensDispatch = this.kitMaster.mobile_scan_opens_dispatch;

        if (!!this.kitMaster.dispatch_validations) {
            this.kitMaster.completeScanCheck = !!this.kitMaster.dispatch_validations.find(
                (dv) => dv.validation_type === 'kit_complete_scan'
            );
            const kitClean = this.kitMaster.dispatch_validations.find((dv) => dv.validation_type === 'kit_cleaned');
            this.kitMaster.kitCleanCheck = !!kitClean;
            this.kitMaster.kitCleanRequiredCheck = !!kitClean && kitClean.required;
        }

        this.kitMaster.types = this.kitMasterTypes;
        this.kitMaster.type = this.kitMaster.kit_master_type;
        this.kitMaster.caseKit = this.kitMaster.case_kit;

        this.kitMaster.newName = this.kitMaster.name;
        this.kitMaster.newChargeExtraInfo = this.kitMaster.chargesheet_extra_info;

        this.original = {
            shared: !!this.kitMaster.shared,
            refrigerated: !!this.kitMaster.fridgeCheck,
            completeScanCheck: this.kitMaster.completeScanCheck,
            kitCleanCheck: this.kitMaster.kitCleanCheck,
            kitCleanRequiredCheck: this.kitMaster.kitCleanRequiredCheck,
            expiring_soon_days: this.kitMaster.expiring_soon_days,
            type: this.kitMaster.type,
            case_kit: this.kitMaster.case_kit,
            preventNewTags: this.kitMaster.preventNewTags,
            persistCart: this.kitMaster.persistCart,
            persistLocation: this.kitMaster.persistLocation,
            printChargeSheetDefault: this.kitMaster.printChargeSheetDefault,
            charge_sheet_customization: this.kitMaster.chargeSheetCustomization,
            mobileScanOpensDispatch: this.kitMaster.mobileScanOpensDispatch,
        };
    }

    nothingChanged(): boolean {
        return (
            this.kitMaster.name === this.kitMaster.newName &&
            this.kitMaster.chargesheet_extra_info === this.kitMaster.newChargeExtraInfo &&
            this.kitMaster.shared === this.original.shared &&
            this.kitMaster.fridgeCheck === this.original.refrigerated &&
            this.kitMaster.completeScanCheck === this.original.completeScanCheck &&
            this.kitMaster.kitCleanCheck === this.original.kitCleanCheck &&
            this.kitMaster.kitCleanRequiredCheck === this.original.kitCleanRequiredCheck &&
            this.kitMaster.expiring_soon_days === this.original.expiring_soon_days &&
            this.kitMaster.type === this.original.type &&
            this.kitMaster.caseKit === this.original.case_kit &&
            this.kitMaster.preventNewTags === this.original.preventNewTags &&
            this.kitMaster.persistCart === this.original.persistCart &&
            this.kitMaster.persistLocation === this.original.persistLocation &&
            this.kitMaster.mobileScanOpensDispatch === this.original.mobileScanOpensDispatch &&
            this.kitMaster.printChargeSheetDefault === this.original.printChargeSheetDefault &&
            (!this.useNewChargeSheetCustomization ||
                this.kitMaster.chargeSheetCustomization === this.original.charge_sheet_customization) &&
            !this.hasNewFile
        );
    }

    resetKitMasterTypes(event) {
        this.filteredKitMasterGroups = _.cloneDeep(this.kitMasterGroups);
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1?.name === o2?.name && o1?.id === o2?.id;
    }

    //make this case insensitive
    selectSearch(event) {
        this.filteredKitMasterGroups = _.cloneDeep(this.kitMasterGroups);
        this.filteredKitMasterGroups = this.filteredKitMasterGroups.filter((group) => {
            let types = group.types.filter((type) => {
                return type.name.includes(event);
            });
            group.types = types;
            return group;
        });
    }

    loadLocations() {
        this.locationResource.locationList(true).then((data) => {
            this.locations = data.locations;
        });
    }

    loadCarts() {
        this.cartResource.cartList().then((data) => {
            this.carts = data.carts;
        });
    }

    kitMasterAllowlistEnabled() {
        return this.kitCartLocationAllowlistsEnabled === true && !this.kitMaster.shared;
    }
}
