import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { MatTableDataSource } from '@angular/material/table';

@Injectable()
export class FormularyItemsService {
    constructor(private formularyItemResource: FormularyItemResource) {}

    loadCeqFormularyItems(ceq) {
        if (!ceq.formularyItems) {
            ceq.loading = true;
            const data = {
                clinical_equivalence_id: ceq.clinical_equivalence_id,
                capacity: {
                    scalar: ceq.package_size_formatted,
                    uom: ceq.package_size_uom,
                },
            };

            this.formularyItemResource
                .formularyItemWithCeq(data)
                .then(({ formulary_items }) => {
                    ceq.formularyItems = formulary_items;
                    ceq.formularyItemsDataSource = new MatTableDataSource(formulary_items);
                })
                .finally(() => {
                    ceq.loading = false;
                });
        }
    }

    getFormularyItems() {
        return this.formularyItemResource.formularyItemList().then((data) => {
            const formularyItems = data.formulary_items;

            _.each(formularyItems, (item) => {
                item.ndcSearch = item.ndc ? item.ndc.replace(/-/g, '') : null;
            });

            return data.formulary_items;
        });
    }

    getFormularyItemsByNDC(query, hospitalId?, includeInFormulary = true) {
        const data = {
            ndc: query,
            include_in_formulary: includeInFormulary,
            on_behalf_of_hospital_id: hospitalId,
        };

        return this.formularyItemResource.search(data).then((data) => {
            data.ndc = query;
            return data;
        });
    }

    getArchivedFormularyItems() {
        return this.formularyItemResource.archivedFormularyItemList().then((data) => {
            const formularyItems = data.formulary_items.filter((archivedItem) => archivedItem.deleted_at != null);

            _.each(formularyItems, (item) => {
                item.ndcSearch = item.ndc ? item.ndc.replace(/-/g, '') : null;
            });

            return formularyItems;
        });
    }

    createTagItem(obj, hospitalId) {
        const data: any = {
            tag_requirement: obj.tag_requirement === 'metal' ? 'metal' : null,
        };

        data.dictionary_entry_id = obj.dictionary_entry_id;

        if (obj.expiring_fridge_days) {
            data.expiring_fridge_days = parseInt(obj.expiring_fridge_days, 10);
        }

        if (obj.expiring_multidose_days) {
            data.expiring_multidose_days = parseInt(obj.expiring_multidose_days, 10);
        }

        if (obj.expiring_soon_days) {
            data.expiring_soon_days = parseInt(obj.expiring_soon_days, 10);
        }

        if (hospitalId && hospitalId !== -1) {
            data.on_behalf_of_hospital_id = hospitalId;
        }

        const preferredContent = ['preferred_item_name', 'preferred_item_strength', 'preferred_item_strength_uom'];
        preferredContent.map((prop) => {
            if (obj[prop]) {
                data[prop] = obj[prop];
            }
        });

        return this.formularyItemResource.addFormularyItem(data).then((data) => {
            return data;
        });
    }
}
