import { Component, Input, Output, OnInit, OnChanges, AfterViewInit, ViewChild, EventEmitter } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';

import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';

import { MatDialog } from '@angular/material/dialog';
import { AddFormularyItemToSegmentDialog } from '@dialogs/add-formulary-item/add-formulary-item-to-segment/add-formulary-item-to-segment-dialog';

import { Package } from '@models/core/package';
import { KitSummary, KitSummaryItem } from '@models/core/kit-summary';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import {
    isAppUnderPostLockoutState,
    isPostLockoutStateForASingleItem,
    isScannedAfterLockoutState,
} from '@utils/charge-model-util';
import { ChargeModelTypeName } from '@models/core/hospital-settings';

@Component({
    selector: 'kit-items-table',
    templateUrl: './kit-items-table.html',
})
export class KitItemsTable {
    @Input() items: Package[];
    @Input() kitSummary: KitSummary;
    @Input() scanId: number;
    @Input() tableButton: string;
    @Input() titleKey: string;
    @Input() e2eName: string;
    @Output() loadKitSummary = new EventEmitter();

    dataSource: MatTableDataSourceWithNaturalSort<any>;

    displayedColumns: string[] = [
        'name',
        'item_strength_formatted',
        'package_volume_formatted',
        'ndc',
        'epc_last_4',
        'lot_num',
        'expiration',
    ];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;
    updateKitSegment: boolean;
    lockedOutItems: Package[];
    subscription_items_lockout_date: string;
    charge_model_type: ChargeModelTypeName;
    postLockout: boolean = false;

    constructor(
        private clinicalEquivalenceResource: ClinicalEquivalenceResource,
        private dialog: MatDialog,
        private loadingSpinnerService: LoadingSpinnerService,
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        if (!!this.tableButton) {
            this.displayedColumns = [
                'name',
                'item_strength_formatted',
                'package_volume_formatted',
                'ndc',
                'epc_last_4',
                'lot_num',
                'expiration',
                'actions',
            ];
        }
        this.updateKitSegment = this.actionService.isAllowAction(
            'kits_inventory',
            'update_kit_segment',
            'Show Add Formulary Item to Segment button'
        );
        this.subscription_items_lockout_date =
            this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.charge_model_type = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;
        this.postLockout = isAppUnderPostLockoutState(this.subscription_items_lockout_date, this.charge_model_type);
    }

    ngAfterViewInit(): void {
        this.setFilteredDataSource();
    }

    ngOnChanges(): void {
        this.setFilteredDataSource();
    }

    setFilteredDataSource() {
        let filteredItems = this.items;
        if (
            this.postLockout &&
            isScannedAfterLockoutState(this.subscription_items_lockout_date, this.kitSummary.scan.created_at)
        ) {
            // filter out any required subscription items
            this.lockedOutItems = this.items.filter((item) => item.subscription_required);
            filteredItems = this.items.filter((item) => !item.subscription_required);
        }
        this.dataSource = new MatTableDataSourceWithNaturalSort(filteredItems);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    canUpdateKitSegment(): boolean {
        return (
            this.updateKitSegment && this.kitSummary.items.wrong.some((wrongItem) => this.showAddSegmentLink(wrongItem))
        );
    }

    itemRequiresPaymentPlan(item: KitSummaryItem) {
        const { subscription_items_lockout_date, charge_model_type } = this.hospitalInfoService.getHospitalSettings();

        return isPostLockoutStateForASingleItem(
            subscription_items_lockout_date,
            charge_model_type.name,
            item as Package
        );
    }

    showAddSegmentLink = (item): boolean =>
        this.kitSummary.most_recent_scan &&
        !this.kitSummary.kit_master_modified_since_last_scan &&
        item.formulary_item_id &&
        !item.from_another_hospital;

    assignToSegment(formularyItem): void {
        if (formularyItem.loading) {
            return;
        }
        formularyItem.loading = true;

        const loadPromise = this.clinicalEquivalenceResource.clinicalEquivalences();

        loadPromise.then((ceqs) => {
            formularyItem.loading = false;
            this.assignToSegmentDialog(ceqs, formularyItem);
        });
        this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.BACKDROP);
    }

    assignToSegmentDialog(ceqs, formularyItem): void {
        const dialog = this.dialog.open(AddFormularyItemToSegmentDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                kitMaster: this.kitSummary.kit_master,
                formularyItem: formularyItem,
                ceqs: ceqs.clinical_equivalences,
                scanId: this.scanId,
            },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.loadKitSummary.emit();
            }
        });
    }
}
