import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'base',
    templateUrl: './base.html',
    styleUrls: ['../templates.scss'],
})
export class Base implements OnInit {
    // Data
    @Input() chargeData;
    @Input() kitSummary;

    // The template refs for very specialized content
    @Input() extraHeaderTemplate: TemplateRef<any>;
    @Input() extraFooterTemplate: TemplateRef<any>;
    @Input() extraTemplate: TemplateRef<any>;
    @Input() preLockTemplate: TemplateRef<any>;
    @Input() preWarningTemplate: TemplateRef<any>;
    @Input() segmentTableTemplate: TemplateRef<any>;

    // Other customizations
    @Input() extraClass: string = null;
    @Input() hospitalLogoSource: string = null;
    @Input() largeFont: boolean = false;
    @Input() patientPlateClass: string = null;
    @Input() patientPlateText: string = null;
    @Input() returnLockHeader: string = null;
    @Input() showAllSegmentsTable: boolean = false;
    @Input() showBarCode: boolean = false;
    @Input() showBillingCode: boolean = true;
    @Input() showCartInHeader: boolean = false;
    @Input() showCleaned: boolean = true;
    @Input() showControlledSumary: boolean = false;
    @Input() showExpiringSoon: boolean = false;
    @Input() showFooter: boolean = true;
    @Input() showHospitalFooterLogo: boolean = false;
    @Input() showHospitalHeaderLogo: boolean = false;
    @Input() showHeader: boolean = true;
    @Input() showLocationInHeader: boolean = false;
    @Input() showLockNumberHeader: boolean = false;
    @Input() showLocks: boolean = true;
    @Input() showLot: boolean = false;
    @Input() showKitCheckLogo: boolean = true;
    @Input() showManualLocks: boolean = true;
    @Input() showNdc: boolean = false;
    @Input() showPatientPlate: boolean = true;
    @Input() showPharmSig: boolean = false;
    @Input() showPharmSigNoDate: boolean = false;
    @Input() showPharmTime: boolean = false;
    @Input() showPharmVerified: boolean = false;
    @Input() showPharmAndTCTVerified: boolean = false;
    @Input() showPharmVerifiedWithMedRecord: boolean = false;
    @Input() showScanId: boolean = false;
    @Input() showSegmentSummary: boolean = true;
    @Input() showSupplies: boolean = false;
    @Input() showUsed: boolean = true;
    @Input() showWarnings: boolean = true;
    @Input() showWrongItems: boolean = true;
    @Input() summaryHeaderKey: string = null;
    @Input() swapLogoAndPatientPlate: boolean = false;
    @Input() showAddendum: boolean = true;

    constructor() {}

    ngOnInit() {}
}
