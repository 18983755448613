/***
 * Barrel file to unburden app.module.ts imports
 * ***/

// Angular 2 Providers
import { DayMonthFormat, MonthFormat } from '../components/common/kc-datepicker/kc-datepicker';
import { DecimalValidatorDirective } from './decimal';
import { KCMaxValueValidateDirective } from './kc-max-value';
import { KCMinValueValidateDirective } from './kc-min-value';
import { MustMatchValidateDirective } from './must-match';
import { NdcValidateDirective } from './ndc';
import { NonBlankValidateDirective } from './non-blank';
import { NonPastDateValidateDirective } from './non-past-date';
import { OnlyASCIIValidateDirective } from './only-ascii';
import { OnlyDigitsValidateDirective } from './only-digits';
import { OverLengthValidateDirective } from './over-length';
import { RequiredTrueValidateDirective } from './required-true';
import { UnitOfSaleNdcValidateDirective } from './unit-of-sale-ndc';

const validators = [
    DecimalValidatorDirective,
    KCMaxValueValidateDirective,
    KCMinValueValidateDirective,
    MustMatchValidateDirective,
    NdcValidateDirective,
    NonBlankValidateDirective,
    NonPastDateValidateDirective,
    OnlyASCIIValidateDirective,
    OnlyDigitsValidateDirective,
    OverLengthValidateDirective,
    RequiredTrueValidateDirective,
    MonthFormat,
    DayMonthFormat,
    UnitOfSaleNdcValidateDirective,
];

export { validators };
