import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class PrintBatchVerificationResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    verifyPrintBatch(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}print_batch_verification`, data).toPromise();
    }
}
