import { Injectable } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { ApplicationService } from '@services/system/application.service';
import { KitSummary } from '@models/core/kit-summary';
import { ScanResult } from '@models/core/scan';
import { getScanUsername } from '@utils/kit-summary-util';

@Injectable()
export class MobileCartService {
    allowHomeNavigate: boolean = false;
    kitSummary: KitSummary;
    kitScannedByUser: string;
    showScanButton: boolean = false;
    showStandardSubheader: boolean = true;
    subheaderText: string = '';

    constructor(
        private applicationService: ApplicationService,
        protected $state: StateService,
        protected $transitions: TransitionService // the transition hooks dont work with different name
    ) {}

    setupMobileCart() {
        this.$transitions.onBefore({}, (transition) => {
            // prevent browser's back button from leaving mobile
            if (
                transition.to().name === 'home' &&
                !this.allowHomeNavigate &&
                transition.from().name !== 'switch-hospital'
            ) {
                return false;
            }
        });

        if (this.$state.$current.name === 'mobile-cart') {
            // route to landing page since home component would be blank otherwise
            this.$state.go('mobile-cart.landing');
        }

        // allow desktop-to-mobile navigation
        this.applicationService.isMobile = true;
    }

    showScanSummaryHeader(kitSummary: KitSummary) {
        this.showScanButton = true;
        this.showStandardSubheader = false;
        this.subheaderText = '';
        this.kitSummary = kitSummary;
        this.kitScannedByUser = getScanUsername(kitSummary.scan);
    }

    goToScanSummary(scanResult: ScanResult) {
        this.$state.go('mobile-cart.scan-summary', { kitId: scanResult.kit_ids[0], scanId: scanResult.id });
    }

    goToDesktop() {
        this.applicationService.isMobile = false;
        this.allowHomeNavigate = true;
        this.$state.go('home');
    }
}
