import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

@Injectable({ providedIn: 'root' })
export class FormularyItemsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService
    ) {}

    resolve(): Promise<any> {
        let formularyItemsPromise;

        if (this.actionService.isAllowAction('hospital_settings', 'view_formulary_item', 'Load formulary items data')) {
            formularyItemsPromise = this.formularyItemsService.getFormularyItems();
        } else {
            formularyItemsPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(formularyItemsPromise);
    }
}
