import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitMasterService } from '@services/core/kit-master.service';

@Injectable({ providedIn: 'root' })
export class KitMastersWithFromKitsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitMasterService: KitMasterService
    ) {}

    resolve(): Promise<any> {
        let kitMasterPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kit_master', 'Load Kit Masters data')) {
            kitMasterPromise = this.kitMasterService.getKitMasters(true);
        } else {
            kitMasterPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(kitMasterPromise);
    }
}
