// Third Party
import { Ng2StateDeclaration } from '@uirouter/angular';

// Our Angular 2 Components
import { PAGES } from 'components';

// Our Angular 2 Resolvers;
import { ActivityAnalyticsResolver } from '@resolvers/activity-analytics.resolver';
import { AdminCartsResolver } from '@resolvers/admin-carts.resolver';
import { AdvancedSegmentOptimizationResolver } from '@resolvers/advanced-segment-optimization.resolver';
import { ArchivedFormularyItemsResolver } from '@resolvers/archived-formulary-items.resolver';
import { BinScanDetailResolver } from '@resolvers/bin-scan-detail.resolver';
import { BinScanResolver } from '@resolvers/bin-scan.resolver';
import { BinsResolver } from '@resolvers/bins.resolver';
import { BlockedKitResolver } from '@resolvers/blocked-kit.resolver';
import { CartHistoryResolver } from '@resolvers/cart-history.resolver';
import { CartResolver } from '@resolvers/cart.resolver';
import { CartsResolver } from '@resolvers/carts.resolver';
import { CatalogsResolver } from '@resolvers/catalogs.resolver';
import { CurrentBinResolver } from '@resolvers/current-bin.resolver';
import { DetailScanResolver } from '@resolvers/detail-scan.resolver';
import { DictionaryEntryResolver } from '@resolvers/dictionary-entry.resolver';
import { ExpirationAnalyticsResolver } from '@resolvers/expiration-analytics.resolver';
import { FormularyItemArchiveKitMasterListResolver } from '@resolvers/formulary-item-archive-kit-master-list.resolver';
import { FormularyItemKitBinCountResolver } from '@resolvers/formulary-item-kit-bin-count.resolver';
import { FormularyItemResolver } from '@resolvers/formulary-item.resolver';
import { FormularyItemsResolver } from '@resolvers/formulary-items.resolver';
import { GroupIdnMapActionViewUsersResolver } from '@resolvers/group-idn-map-action-view-users.resolver';
import { GroupRolesResolver } from '@resolvers/group-roles.resolver';
import { HardwareResolver } from '@resolvers/hardware.resolver';
import { HospitalDataResolver } from '@resolvers/hospital-data.resolver';
import { InferredItemsConsumedRemovedResolver } from '@resolvers/inferred-items-consumed-removed.resolver';
import { KitMasterTypesResolver } from '@resolvers/kit-master-types.resolver';
import { KitMastersResolver } from '@resolvers/kit-masters.resolver';
import { KitMastersWithFromKitsResolver } from '@resolvers/kit-masters-with-from-kits.resolver';
import { KitScansResolver } from '@resolvers/kit-scans.resolver';
import { KitSummaryResolver } from '@resolvers/kit-summary.resolver';
import { KitsResolver } from '@resolvers/kits.resolver';
import { KittedInventoryWithCostResolver } from '@resolvers/kitted-inventory-with-cost.resolver';
import { LocationResolver } from '@resolvers/location.resolver';
import { LocationsAeroscoutResolver } from '@resolvers/locations-aeroscout.resolver';
import { LocationsResolver } from '@resolvers/locations.resolver';
import { LoginResolver } from '@resolvers/login.resolver';
import { ManufacturersResolver } from '@resolvers/manufacturers.resolver';
import { PackageDescriptionsResolver } from '@resolvers/package-descriptions.resolver';
import { PanelSpinnerResolver } from '@resolvers/panel-spinner.resolver';
import { PreTaggedMedicationsResolver } from '@resolvers/pre-tagged-medications.resolver';
import { PretaggedProblemsResolver } from '@resolvers/pretagged-problems.resolver';
import { PretaggedReportsResolver } from '@resolvers/pretagged-reports.resolver';
import { PrinterResolver } from '@resolvers/printer.resolver';
import { RecallResolver } from '@resolvers/recall.resolver';
import { RecallsNetworkResolver } from '@resolvers/recalls-network.resolver';
import { RecallsResolver } from '@resolvers/recalls.resolver';
import { ReportSubscriptionsResolver } from '@resolvers/report-subscriptions.resolver';
import { ReportsResolver } from '@resolvers/reports.resolver';
import { ScanInventoryResolver } from '@resolvers/scan-inventory.resolver';
import { SegmentsResolver } from '@resolvers/segments.resolver';
import { ShelvedInventorySettingResolver } from '@resolvers/shelved-inventory-setting.resolver';
import { ShortageSegmentsResolver } from '@resolvers/shortage-segments.resolver';
import { SingleDossierReportResolver } from '@resolvers/single-dossier-report.resolver';
import { SingleHardwareResolver } from '@resolvers/single-hardware.resolver';
import { SingleKitMasterResolver } from '@resolvers/single-kit-master.resolver';
import { SingleSegmentResolver } from '@resolvers/single-segment.resolver';
import { ScannerResolver } from '@resolvers/scanner.resolver';
import { TagTypesResolver } from '@resolvers/tag-types.resolver';
import { UserListResolver } from '@resolvers/user-list.resolver';
import { UserResolver } from '@resolvers/user.resolver';
import { MidmarkDeviceBatteryResolver } from '@resolvers/midmark-device-battery-resolver';

export interface RouteDeclaration extends Ng2StateDeclaration {
    titleKey?: string;
}

export function genericResolve(resolver: any): any {
    return resolver.resolve();
}

export const routes: RouteDeclaration[] = [
    {
        name: 'add-credits',
        url: '/add-credits',
        component: PAGES.AddCredits,
        resolve: [
            {
                token: 'usageSummary',
                deps: [PreTaggedMedicationsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'admin',
        url: '/admin',
        component: PAGES.Admin,
        redirectTo: 'admin.home',
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'admin-deeplink',
        url: '/admin',
        component: PAGES.Admin,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            targetState: { value: null, squash: true, dynamic: true },
            targetParams: { value: null, squash: true, dynamic: true },
        },
        titleKey: 'admin.title',
    },
    {
        name: 'admin.batch-lots',
        url: '/batch-lots',
        component: PAGES.AdminBatchLots,
        resolve: [
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.carts',
        url: '/carts',
        component: PAGES.AdminCartsList,
        resolve: [
            {
                token: 'carts',
                deps: [AdminCartsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.carts-create',
        url: '/carts/create',
        component: PAGES.AdminCartsCreate,
        resolve: [
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.carts-update',
        url: '/carts/:cartId/update',
        component: PAGES.AdminCartsCreate,
        resolve: [
            {
                token: 'cart',
                deps: [CartResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.hardware',
        url: '/hardware',
        component: PAGES.AdminHardwareList,
        resolve: [
            {
                token: 'hardware',
                deps: [HardwareResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.hardware-update',
        url: '/hardware/:hardwareId/update',
        component: PAGES.AdminHardwareUpdate,
        resolve: [
            {
                token: 'hardware',
                deps: [SingleHardwareResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.home',
        url: '/home',
        component: PAGES.AdminHome,
        titleKey: 'admin.title',
    },
    {
        name: 'admin.hospital',
        url: '/hospital',
        component: PAGES.AdminHospital,
        resolve: [
            {
                token: 'hospital',
                deps: [HospitalDataResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.item-expirations',
        url: '/item-expirations',
        component: PAGES.AdminItemExpirations,
        resolve: [
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.item-recalls',
        url: '/recalls?ids',
        component: PAGES.AdminRecallsList,
        resolve: [
            {
                token: 'recalls',
                deps: [RecallsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'recallsNetwork',
                deps: [RecallsNetworkResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            tab: null,
        },
        titleKey: 'admin.title',
    },
    {
        name: 'admin.locations',
        url: '/locations',
        component: PAGES.AdminLocationsList,
        resolve: [
            {
                token: 'locations',
                deps: [LocationsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.locations-create',
        url: '/locations',
        component: PAGES.AdminLocationsCreate,
        resolve: [
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.locations-update',
        url: '/locations/:locationId/update',
        component: PAGES.AdminLocationsCreate,
        resolve: [
            {
                token: 'location',
                deps: [LocationResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.locks',
        url: '/locks',
        component: PAGES.AdminLocks,
        titleKey: 'admin.title',
    },
    {
        name: 'admin.pretagged-quarantine',
        url: '/pretagged-quarantine',
        component: PAGES.AdminPretaggedQuarantine,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'quarantinedItems',
                deps: [PretaggedReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'problems',
                deps: [PretaggedProblemsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'packageDescriptions',
                deps: [PackageDescriptionsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'manufacturers',
                deps: [ManufacturersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            successMessage: null,
        },
        titleKey: 'admin.title',
    },
    {
        name: 'admin.item-recalls-create',
        url: '/recalls/create',
        component: PAGES.AdminRecallsCreate,
        resolve: [
            {
                token: 'formularyItems',
                deps: [FormularyItemsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            dashboard: null,
        },
        titleKey: 'admin.title',
    },
    {
        name: 'admin.item-recalls-update',
        url: '/recalls/:recallId/update',
        component: PAGES.AdminRecallsCreate,
        resolve: [
            {
                token: 'formularyItems',
                deps: [FormularyItemsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'recall',
                deps: [RecallResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.users',
        url: '/users',
        component: PAGES.AdminUsersList,
        resolve: [
            {
                token: 'idnGroup',
                deps: [GroupIdnMapActionViewUsersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.users-create',
        url: '/users/create/:selectedGroup?',
        component: PAGES.AdminUsersCreate,
        resolve: [
            {
                token: 'idnGroup',
                deps: [GroupIdnMapActionViewUsersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'groupRoles',
                deps: [GroupRolesResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.users-profile',
        url: '/users/:userId',
        component: PAGES.AdminUsersProfile,
        resolve: [
            {
                token: 'user',
                deps: [UserResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'admin.users-update',
        url: '/users/:userId/update/:selectedGroup?',
        component: PAGES.AdminUsersCreate,
        resolve: [
            {
                token: 'idnGroup',
                deps: [GroupIdnMapActionViewUsersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'groupRoles',
                deps: [GroupRolesResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'user',
                deps: [UserResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'panelSpinner',
                deps: [PanelSpinnerResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'admin.title',
    },
    {
        name: 'bin-edit',
        url: '/bin/:binId',
        component: PAGES.BinEdit,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'binScan',
                deps: [CurrentBinResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'change-tag-type',
        url: '/change-tag-type/:tagTypeId?',
        component: PAGES.ChangeTagType,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'printers',
                deps: [PrinterResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'tagTypes',
                deps: [TagTypesResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'tagging.titles.change_tag_type',
    },
    {
        name: 'credit-history',
        url: '/credit-history',
        component: PAGES.CreditHistory,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'usage_history.title',
    },
    {
        name: 'forgot-password',
        url: '/forgot-password',
        component: PAGES.NgxForgotPasswordComponent,
    },
    {
        name: 'formulary-archive-item',
        url: '/inventory/archive-formulary-item/:formularyItemId',
        component: PAGES.FormularyArchiveItem,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'formularyItem',
                deps: [FormularyItemResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitBinCount',
                deps: [FormularyItemKitBinCountResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_formulary.archive_title',
    },
    {
        name: 'formulary-index',
        url: '/inventory/manage-formulary',
        component: PAGES.FormularyIndex,
        params: {
            createSuccess: '',
            editSuccess: '',
            showArchivedItems: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'formularyItems',
                deps: [FormularyItemsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'archivedFormularyItems',
                deps: [ArchivedFormularyItemsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'inventory',
        url: '/inventory',
        component: PAGES.KitsIndex,
        params: {
            kitsNeedingArchive: false,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'scanInventory',
                deps: [ScanInventoryResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'inventory.title',
    },
    {
        name: 'containers-index',
        url: '/inventory/containers',
        component: PAGES.ContainersIndex,
        titleKey: 'containers.title',
        data: {
            parentState: 'home',
            breadcrumbs: true,
            forceHideHeader: true,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'scanners',
                deps: [ScannerResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'containers-detail-single',
        url: '/inventory/containers/:id',
        component: PAGES.ContainersDetail,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'containers.container_details.page_title',
        data: {
            parentState: 'containers-index',
            breadcrumbs: true,
            forceHideHeader: true,
        },
    },
    {
        name: 'containers-detail',
        url: '/inventory/containers/:id/:scanId',
        component: PAGES.ContainersDetail,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'containers.container_details.page_title',
        data: {
            parentState: 'containers-index',
            breadcrumbs: true,
            forceHideHeader: true,
        },
    },
    {
        name: 'inventory-manage-items',
        url: '/inventory/manage-items/:epc?',
        component: PAGES.ManageItems,
        reloadOnSearch: false,
        params: {
            binName: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'pretaggedProblems',
                deps: [PretaggedProblemsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'inventory-manage-items-print-error',
        url: '/inventory/manage-item-print-error',
        component: PAGES.ManageItemsPrintError,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_items.printer_error',
    },
    {
        name: 'inventory-manage-items-print-unconfirmed',
        url: '/inventory/manage-item-print-unconfirmed',
        component: PAGES.ManageItemsPrintUnconfirmed,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_items.printer_error',
    },
    {
        name: 'kit',
        url: '/kit/:kitId',
        component: PAGES.KitDetail,
        resolve: [
            {
                token: 'carts',
                deps: [CartsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitSummary',
                deps: [KitSummaryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'locations',
                deps: [LocationsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'usageSummary',
                deps: [PreTaggedMedicationsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'kit-scan',
        url: '/kit/:kitId/:scanId',
        component: PAGES.KitDetail,
        resolve: [
            {
                token: 'carts',
                deps: [CartsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitSummary',
                deps: [KitSummaryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'locations',
                deps: [LocationsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'usageSummary',
                deps: [PreTaggedMedicationsResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            barcodeScanFromHome: null,
        },
    },
    {
        name: 'kit-block',
        url: '/kit/block/:kitId/:scanId',
        component: PAGES.KitBlock,
        resolve: [
            {
                token: 'blockedKit',
                deps: [BlockedKitResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'kit_block.title',
    },
    {
        name: 'kit-scans',
        url: '/kit-scans/:kitId',
        component: PAGES.KitScans,
        resolve: [
            {
                token: 'kitScans',
                deps: [KitScansResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'scan_history.title',
    },
    {
        name: 'bins-index',
        url: '/inventory/bins',
        component: PAGES.BinsIndex,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'scanInventory',
                deps: [ScanInventoryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'shelvedInventorySetting',
                deps: [ShelvedInventorySettingResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'bins',
                deps: [BinsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'printers',
                deps: [PrinterResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'bins.title',
    },
    {
        name: 'bin-scan',
        url: '/bin/:binId/:scanId',
        component: PAGES.BinScan,
        resolve: [
            {
                token: 'binScan',
                deps: [BinScanResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'binScanDetail',
                deps: [BinScanDetailResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'scanInventory',
                deps: [ScanInventoryResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'home',
        url: '/',
        component: PAGES.Home,
        resolve: [
            {
                token: 'scanInventory',
                deps: [ScanInventoryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'printers',
                deps: [PrinterResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            transferredMessage: { value: null, squash: true },
            newVerification: { value: null, squash: true },
            isBrandNav: null,
        },
        data: {
            forceHideHeader: true,
        },
        titleKey: 'common.home',
    },
    {
        name: 'inventoryCarts',
        url: '/inventory/carts',
        component: PAGES.CartsIndex,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'carts',
                deps: [CartsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'locations',
                deps: [LocationsAeroscoutResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'carts.title',
    },
    {
        name: 'inventory-archive-formulary-item-kit-masters',
        url: '/inventory/archive-formulary-item/:formularyItemId/kit-masters',
        component: PAGES.KitMastersList,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [FormularyItemArchiveKitMasterListResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_kit_masters.title',
    },
    {
        name: 'inventory-create-formulary-item',
        url: '/inventory/create-formulary-item',
        component: PAGES.CreateEditFormularyItem,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'packageDescriptions',
                deps: [PackageDescriptionsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'hospitalCatalogs',
                deps: [CatalogsResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            tagItemInfo: null,
        },
        titleKey: 'manage_formulary.create_title',
    },
    {
        name: 'inventory-create-formulary-item-medispan',
        url: '/inventory/create-formulary-item/:ndc',
        component: PAGES.CreateEditFormularyItem,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'dictionaryEntry',
                deps: [DictionaryEntryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'hospitalCatalogs',
                deps: [CatalogsResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            medispan: true,
        },
        titleKey: 'manage_formulary.create_title',
    },
    {
        name: 'inventory-edit-formulary-item',
        url: '/inventory/edit-formulary-item/:formularyItemId',
        component: PAGES.CreateEditFormularyItem,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'formularyItem',
                deps: [FormularyItemResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'packageDescriptions',
                deps: [PackageDescriptionsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'hospitalCatalogs',
                deps: [CatalogsResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_formulary.edit_title',
    },
    {
        name: 'inventory-kit-master-create',
        url: '/inventory/kit-master-create',
        component: PAGES.KitMasterEdit,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasterTypes',
                deps: [KitMasterTypesResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'create_kit_master.title',
    },
    {
        name: 'inventory-kit-master-edit',
        url: '/inventory/manage-kit-masters/:kitMasterId/edit',
        component: PAGES.KitMasterEdit,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMaster',
                deps: [SingleKitMasterResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasterTypes',
                deps: [KitMasterTypesResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'edit_kit_master.title',
    },
    {
        name: 'inventory-kit-masters-list',
        url: '/inventory/manage-kit-masters',
        component: PAGES.KitMastersList,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_kit_masters.title',
    },
    {
        name: 'inventory-single-kit-master',
        url: '/inventory/manage-kit-masters/:kitMasterId',
        component: PAGES.SingleKitMaster,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'segments',
                deps: [SegmentsResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'single_kit_master.title',
    },
    {
        name: 'inventory-segment-edit',
        url: '/inventory/manage-kit-masters/:kitMasterId/segments/:segmentId',
        component: PAGES.SegmentEdit,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMaster',
                deps: [SingleKitMasterResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'segment',
                deps: [SingleSegmentResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'manage_segment.title',
    },
    {
        name: 'login',
        url: '/login',
        params: {
            passwordResetEmail: null,
        },
        component: PAGES.NgxLoginComponent,
    },
    {
        name: 'manage-cart',
        url: '/inventory/cart/:cartId',
        component: PAGES.CartManage,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'cart',
                deps: [CartResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'locations',
                deps: [LocationsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'history',
                deps: [CartHistoryResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'carts.manage.title',
    },
    {
        name: 'manage-subscriptions',
        url: '/manage-subscriptions',
        component: PAGES.ManageSubscriptions,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'subscriptions',
                deps: [ReportSubscriptionsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'midmark-low-battery-report',
        url: '/midmark-device-batteries/low/',
        component: PAGES.MidmarkLowBatteryReport,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'midmarkDeviceBatteries',
                deps: [MidmarkDeviceBatteryResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'mobile-cart',
        url: '/mobile',
        component: PAGES.MobileCartHomeComponent,
        titleKey: 'KitCheck Mobile Cart',
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'mobile-cart.landing',
        url: '/home',
        component: PAGES.MobileCartLandingPageComponent,
        titleKey: 'KitCheck Mobile Cart',
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'mobile-cart.scan-summary',
        url: '/scan-summary/:kitId/:scanId',
        component: PAGES.MobileCartScanSummaryComponent,
        titleKey: 'KitCheck Mobile Cart',
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitSummary',
                deps: [KitSummaryResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'mobile-cart.kit-block',
        url: '/kit/block/:kitId/:scanId',
        component: PAGES.MobileCartKitBlockComponent,
        titleKey: 'KitCheck Mobile Cart',
        resolve: [
            {
                token: 'blockedKit',
                deps: [BlockedKitResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'password-reset',
        url: '/password-reset?reset_token',
        params: {
            reset_token: { value: null, squash: true },
        },
        component: PAGES.NgxPasswordResetComponent,
    },
    {
        name: 'password-reset-expired',
        url: '/password-reset-expired',
        component: PAGES.NgxPasswordResetExpiredComponent,
    },
    {
        name: 'pretagged-medications',
        url: '/pre-tagged-medications',
        component: PAGES.PretaggedMedications,
        resolve: [
            {
                token: 'usageSummary',
                deps: [PreTaggedMedicationsResolver],
                resolveFn: genericResolve,
            },
        ],
        params: {
            successMessage: null,
        },
        titleKey: 'pre_tagged_medications.title',
    },
    {
        name: 'pre-tagged-credit-history',
        url: '/pre-tagged-credit-history',
        component: PAGES.PreTaggedUsageHistory,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'credit_history.title',
    },
    {
        name: 'pre-tagged-usage-history',
        url: '/pre-tagged-usage-history',
        component: PAGES.PreTaggedUsageHistory,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'usage_history.title',
    },
    {
        name: 'print-error',
        url: '/print-error',
        component: PAGES.PrintError,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'errors.print_error.title',
    },
    {
        name: 'report-activity-analytics',
        url: '/report/activity-analytics',
        component: PAGES.ReportActivityAnalytics,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'activityAnalytics',
                deps: [ActivityAnalyticsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-advanced-segment-optimization',
        url: '/report/advanced-segment-optimization',
        component: PAGES.ReportAdvancedSegmentOptimization,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'advancedSegmentOptimization',
                deps: [AdvancedSegmentOptimizationResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-aggregate-billing',
        url: '/report/aggregate-billing',
        component: PAGES.ReportBilling,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-expiration-analytics',
        url: '/report/expiration-analytics',
        component: PAGES.ReportExpirationAnalytics,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'expirationAnalytics',
                deps: [ExpirationAnalyticsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-hospital-activity',
        url: '/report/hospital',
        component: PAGES.ReportHospitalActivity,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-print-batch',
        url: '/report/print-batch',
        component: PAGES.ReportItemBatchSummary,
        params: {
            reportName: 'print-batch',
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'report',
                deps: [SingleDossierReportResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        // TODO CF - I can't find this one - I think it's print batch detail
        name: 'report-item-batch-by-hospital',
        url: '/report/item-batch-by-hospital',
        component: PAGES.ReportItemBatchByHospital,
        params: {
            reportName: 'item-batch-by-hospital',
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'report',
                deps: [SingleDossierReportResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-item-expiration-and-attributes-custom-item',
        url: '/report/custom',
        component: PAGES.ReportItemExpirations,
        params: {
            hideSpinner: null,
            prefilter: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-item-expiration-and-attributes-ndc-lot',
        url: '/report/customLot',
        component: PAGES.ReportItemsByNDC,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-inferred-items-consumed-removed',
        url: '/report/items-consumed-or-removed',
        component: PAGES.ReportInferredItemsConsumedRemoved,
        params: {
            prefilter: null,
            hideSpinner: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'inferredItemsConsumedRemoved',
                deps: [InferredItemsConsumedRemovedResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersWithFromKitsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-items-tagged',
        url: '/report/itemsTagged',
        component: PAGES.ReportItemsTagged,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-kit-activity',
        url: '/report/kit_activity',
        component: PAGES.ReportKitActivity,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-kits-needing-rework',
        url: '/report/rework',
        component: PAGES.ReportKitsNeedingRework,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-kitted-inventory-with-cost',
        url: '/report/kitted-inventory-with-cost',
        component: PAGES.ReportKittedInventoryWithCost,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kittedInventoryWithCost',
                deps: [KittedInventoryWithCostResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-kitted-items-by-ndc',
        url: '/report/byNDC',
        component: PAGES.ReportKittedItemInventory,
        params: {
            reportName: 'byNDC',
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'singleReport',
                deps: [SingleDossierReportResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-item-recalls',
        url: '/report/item-recalls',
        component: PAGES.ReportRecalls,
        params: {
            recall_ids: { value: null, squash: true },
            prefilter: null,
            hideSpinner: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-print-batch-detail',
        url: '/report-print-batch-detail?batchId&lotNum&createdAt&hospitalOwnership',
        component: PAGES.ReportTaggedItemBatchSummary,
        params: {
            batchId: { value: null, squash: true },
            formularyItemId: { value: null, squash: true },
            lotNum: { value: null, squash: true },
            createdAt: { value: null, squash: true },
            hospitalOwnership: { value: null, squash: true },
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'system-reports',
        url: '/system-reports',
        component: PAGES.ReportsIndex,
        params: {
            isSystem: { value: true, squash: true },
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'system-report-inferred-items-consumed-removed',
        url: '/system-reports/system_inferred_items_consumed_removed',
        component: PAGES.ReportInferredItemsConsumedRemoved,
        params: {
            isSystem: { value: true, squash: true },
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'inferredItemsConsumedRemoved',
                deps: [InferredItemsConsumedRemovedResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersWithFromKitsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'system-report-item-expiration-and-attributes-custom-item',
        url: '/system-reports/system_item_expiration_and_attributes_custom_item',
        component: PAGES.ReportItemExpirations,
        params: {
            isSystem: { value: true, squash: true },
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'system-report-item-recalls',
        url: '/system-reports/system_item_recalls',
        component: PAGES.ReportRecalls,
        params: {
            recall_ids: { value: null, squash: true },
            isSystem: { value: true, squash: true },
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-user-activity',
        url: '/report/recent',
        component: PAGES.ReportUserActivity,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'users',
                deps: [UserListResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'report-virginia-board-of-pharmacy',
        url: '/report/virginia-board-of-pharmacy',
        component: PAGES.ReportVirginiaBoardOfPharmacy,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'reports',
        url: '/reports',
        component: PAGES.ReportsIndex,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'reports-list',
        url: '/reports',
        component: PAGES.ReportsList,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'reports',
                deps: [ReportsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'scan-batch-verify',
        url: '/scan/batch-verify/:scanId',
        component: PAGES.ScanBatchVerify,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'batch_verify.title',
    },
    {
        name: 'scan-details',
        url: '/scan/details/:scanId',
        component: PAGES.ScanDetails,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'detailScan',
                deps: [DetailScanResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'pretaggedProblems',
                deps: [PretaggedProblemsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'settings',
        url: '/settings',
        component: PAGES.UserSettings,
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'settings.title',
    },
    {
        name: 'shortageManagement',
        url: '/inventory/shortage-management',
        component: PAGES.ShortageManagementPage,
        resolve: [
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'segments',
                deps: [SegmentsResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'shortage_report.title',
    },
    {
        name: 'shortageReports',
        url: '/inventory/shortage-report',
        component: PAGES.ShortageReportPage,
        resolve: [
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'shortageSegments',
                deps: [ShortageSegmentsResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'shortage_report.title',
    },
    {
        name: 'switch-hospital',
        url: '/switch-hospital',
        component: PAGES.NgxSwitchHospitalComponent,
    },
    {
        name: 'tagging',
        url: '/tagging',
        component: PAGES.Tagging,
        reloadOnSearch: false,
        params: {
            barcodeObject: null,
            barcode: null,
            type: null,
            newFormularyItem: null,
        },
        resolve: [
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kitMasters',
                deps: [KitMastersResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'printers',
                deps: [PrinterResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'hospitalCatalogs',
                deps: [CatalogsResolver],
                resolveFn: genericResolve,
            },
        ],
    },
    {
        name: 'charge-sheet-test',
        url: '/charge-sheet-test',
        component: PAGES.ChargeSheetTest,
        resolve: [
            {
                token: 'kitMasters',
                deps: [KitMastersWithFromKitsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'kits',
                deps: [KitsResolver],
                resolveFn: genericResolve,
            },
            {
                token: 'login',
                deps: [LoginResolver],
                resolveFn: genericResolve,
            },
        ],
        titleKey: 'charge sheet test',
    },
];
