import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';

import { DemoScan } from '@models/core/scan';

@Injectable()
export class ScanRedirectionService {
    constructor(
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private $state: StateService,
        private translationService: TranslationService
    ) {}

    demoRedirect(demoScan: DemoScan) {
        this.$state.go(demoScan.state, demoScan.params, { reload: true });
    }

    redirectScan(data) {
        if (data.view === 'batch_verification_scan') {
            this.$state.go('scan-batch-verify', { scanId: data.id });
        } else if (data.view === 'implicit_return_scan') {
            this.$state.go('kit-scan-ncp', {
                kitId: data.kit_ids[0],
                scanId: data.id,
            });
        } else if (data.view === 'kit_scan_summary') {
            this.$state.go('kit-scan', {
                kitId: data.kit_ids[0],
                scanId: data.id,
            });
        } else if (data.view === 'item_association') {
            this.$state.go('tagging', { type: 'batch', barcode: data.id });
        } else if (data.view === 'single_item_scan') {
            this.$state.go('inventory-manage-items', { epc: data.single_item_scan_epc });
        } else if (this.hospitalInfoService.allowShelvedInventory() && data.view === 'bin_scan_summary') {
            this.$state.go('bin-scan', {
                binId: data.bin_ids[0],
                scanId: data.id,
            });
        } else if (data.view === 'scan_details') {
            this.$state.go('scan-details', {
                scanId: data.id,
            });
        } else if (this.$state.current === 'home') {
            this.unrecognizedError(data);
        } else {
            this.$state.go('home').then(() => {
                this.unrecognizedError(data);
            });
        }
    }

    unrecognizedError(data) {
        let message =
            this.translationService.instant('scan.errors.unrecognized_banner') +
            `${data.payload_composition_type?.split('_').join(' ')}`;
        this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
    }
}
