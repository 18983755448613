import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { GroupInfoService } from '@services/core/group-info.service';
import { IFilter } from '@models/common/report-filter';
import { TranslationService } from '@services/utils/translation.service';
import { isEmpty } from '@utils/objects';
import { KCHospitalGroupSelect } from '@components/common/kc-hospital-group-select/kc-hospital-group-select';

interface ApiData {
    group_ids: string; // a pipe delimited list of hospital group ids
}

@Component({
    selector: 'hospital-group-filter',
    templateUrl: './hospital-group-filter.html',
    styleUrls: ['./hospital-group-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: HospitalGroupFilter }],
})
export class HospitalGroupFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string; // from report-filter
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeFilterData = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    @ViewChild('hospitalGroup') child: KCHospitalGroupSelect;

    selectedHospitalGroupIds: string;

    constructor(
        private groupInfoService: GroupInfoService,
        protected translationService: TranslationService
    ) {}

    ngOnInit() {
        if (this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.group_ids;
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            // filter hospital groups to current hospital if there are no groups initially selected
            if (!this.filterData) {
                this.filterData = this.groupInfoService.getGroupId().toString();
            }
            this.emitFilterData();
        }
    }

    onChangeFilterData(event): void {
        this.filterData = event;
        this.emitFilterData();
    }

    emitFilterData(): void {
        this.changeFilterData.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'hospitalGroup', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): Array<any> {
        return [{ group_ids: this.filterData }];
    }

    reset(): void {
        this.child.reset();
        this.emitFilterData();
    }
}
