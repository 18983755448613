import { Component, Input, Output, EventEmitter, ViewChildren, OnInit, AfterViewInit, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { HospitalInfoService } from '@services/core/hospital-info.service';

export interface FilterList {
    initialfilterData: any;
    reset: Function;
}

@Component({
    selector: 'report-subscription-filters',
    templateUrl: './report-subscription-filters.html',
    styleUrls: ['./report-subscription-filters.scss'],
})
export class ReportSubscriptionFilters {
    @Input() filters: any[]; //Filter[];
    @Input() filterData: any; // hash from report-filter
    @Input() apiSubscriptionData: any; // hash from subscription API
    @Input() isSystem: boolean = false;
    @Input() consumedKitMasters;
    @Input() reportSubscription;

    @Output() subscriptionData = new EventEmitter();
    @Output() incomplete = new EventEmitter();

    componentData: any;
    subscriptionFilterData: any = {};

    scheduledReportsEnabled: boolean;

    constructor(protected hospitalInfoService: HospitalInfoService) {}

    ngOnInit() {
        // used to prevent the filterData changes from being passed back to the report-filter header block
        // when the dialog is called from there.
        this.componentData = _.cloneDeep(this.filterData);
        this.consumedKitMasters = this.consumedKitMasters || [];

        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
    }

    setApiFilterData(filters): void {
        if (!!filters) {
            filters.forEach((filter) => {
                let key = Object.keys(filter)[0];
                this.subscriptionFilterData[key] = filter[key];
                this.subscriptionData.emit(this.subscriptionFilterData);
            });
        }
    }

    incompleteFilter(event): void {
        this.incomplete.emit(event);
    }
}
