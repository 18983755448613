import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-warning',
    templateUrl: './charge-sheet-template-warning.html',
})
export class ChargeSheetTemplateWarning {
    @Input() chargeData;
    @Input() kitSummary;
    latest_scan: boolean = true;

    constructor() {}

    ngOnInit() {
        this.latest_scan =
            this.kitSummary.recent_scans[0] &&
            this.kitSummary.scan &&
            this.kitSummary.recent_scans[0].id === this.kitSummary.scan.id;
    }
}
