import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as _ from 'lodash';
import { TagAssociationResource } from '@resources/tag-association-resource.service';
import { deleteEmptyKeys } from '@utils/objects';

@Injectable()
export class TagAssociationService {
    constructor(private tagAssociationResource: TagAssociationResource) {}

    associateTags(formularyItemId, tags, onBehalfOfHospitalId, expiration, lot, calculateFridgeDate, binId) {
        const data = deleteEmptyKeys({
            epcs: tags,
            expiration,
            formulary_item_id: formularyItemId,
            lot_num: lot,
            on_behalf_of_hospital_id: onBehalfOfHospitalId,
        });

        if (!_.isUndefined(calculateFridgeDate)) {
            data['calculate_fridge_date'] = calculateFridgeDate;
        }

        if (!_.isUndefined(binId)) {
            data['bin_id'] = binId;
        }

        return this.tagAssociationResource.associateTags(data);
    }

    getAssociationScan(id: number) {
        return this.tagAssociationResource.getAssociationScan(id).then((data) => data.item_association_scan);
    }
}
