import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'charge-sheet-template-all-segments',
    templateUrl: './charge-sheet-template-all-segments.html',
})
export class ChargeSheetTemplateAllSegments {
    @Input() chargeData;
    @Input() kitSummary;
    @Input() showBillingCode: boolean = true;
    @Input() showUsed: boolean = true;

    constructor() {}

    ngOnInit() {}

    expiredOrExpiringSoon(item) {
        if (!item) {
            return false;
        }

        const m = moment(item.expiration);

        return (
            m.isBefore() ||
            this.kitSummary.items.expiring_soon.find(
                (item_snapshot_id) => item_snapshot_id === item.item_snapshot_id
            ) !== undefined
        );
    }
}
