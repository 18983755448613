import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KitService } from '@services/core/kit.service';
import { KitSummary } from '@models/core/kit-summary';

@Component({
    selector: 'dispatch-kit-mobile',
    templateUrl: './dispatch-kit-mobile.html',
})
export class DispatchKitMobileDialog {
    showLoad: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DispatchKitMobileDialog>,
        private kitService: KitService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            kitSummary: KitSummary;
        }
    ) {}

    dispatch() {
        this.showLoad = true;
        this.kitService.dispatchKit(this.data.kitSummary).then(() => {
            this.dialogRef.close(true);
        });
    }
}
