import { Component, Input } from '@angular/core';

@Component({
    selector: 'recall-lot-numbers',
    templateUrl: './recall-lot-numbers.html',
    styleUrls: ['./recall-lot-numbers.scss'],
})
export class RecallLotNumbers {
    @Input() recall;
}
