import {
  Component,
  OnDestroy,
  TemplateRef,
  ContentChild,
  EmbeddedViewRef,
  ViewContainerRef,
  Input,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'c-item',
  template: ``
})
export class Item {

    @ContentChild(TemplateRef, {static: false}) template: TemplateRef<any>;
    constructor() {
    }

}

@Component({
  selector: 'c-badge',
  template: ``
})
export class Badge {

    @ContentChild(TemplateRef, {static: false}) template: TemplateRef<any>;
    constructor() {
    }

}

@Component({
  selector: 'c-search',
  template: ``
})
export class Search {

    @ContentChild(TemplateRef, {static: false}) template: TemplateRef<any>;
    constructor() {
    }

}
@Component({
  selector: 'c-templateRenderer',
  template: ``
})
export class TemplateRenderer implements AfterViewInit, OnDestroy {

    @Input() data: any;
    @Input() item: any;
    view: EmbeddedViewRef<any>;

    constructor(
        public viewContainer: ViewContainerRef
    ) {
    }

    ngAfterViewInit() {
        this.view = this.viewContainer.createEmbeddedView(this.data.template, {
            '\$implicit': this.data,
            'item': this.item
        });
    }

    ngOnDestroy() {
        this.view.destroy();
    }

}
