import { Injectable } from '@angular/core';

const cartsReportName: string = 'carts';

@Injectable()
export class CartsReportService {
    constructor() {}

    detectReport(reportName: string): boolean {
        return reportName === cartsReportName;
    }

    reportData(scope: any): any {
        return {};
    }

    setFilterDefaults(scope: any): void {
        scope.showMultiKitMasterFilter = true;
        scope.filters.kit_masters = scope.filters.selectedKitMasters || [];
        scope.filters.locations = scope.filters.locations || [];
        scope.filters.include_empty = !!scope.filters.emptyCarts || false;
        scope.filters.include_carts_with_no_location = !!scope.filters.noLocation || false;
        scope.filters.days_expiring_within = scope.filters.expiration;
        scope.showExpiresInFilter = true;
        scope.showLocationFilter = true;
        scope.hideCreatedDate = true;
        scope.showEmptyCartsFilter = true;
        scope.showKitMasterFilter = false;
        scope.showIncludeExpiredFilter = false;
        scope.showKitBinFilter = false;
        scope.showNDCFilter = false;
        scope.showLotNumberFilter = false;
        scope.hideClinicalEquivalence = true;
        scope.showDateFilter = false;
        scope.showClear = false;

        delete scope.filters.noLocation;
        delete scope.filters.emptyCarts;
        delete scope.filters.expiration;
        delete scope.filters.selectedKitMasters;
        delete scope.filters.start_date;
    }
}
