import { Component, Input } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { NgForm } from '@angular/forms';

import { ActionService } from '@services/utils/action.service';

import { AssignableForm } from '../assignable-form/assignable-form';
import { BarcodeImgComponent } from '@components/common/barcode-img/barcode-img';
import { BarcodeScanField } from '@components/common/barcode-scan-field/barcode-scan-field';
import { CommonCRUDAPIService } from '@services/core/common-crud-api.service';

import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { AeroscoutService } from '@services/hardware/aeroscout.service';
import { AeroscoutResource } from '@resources/aeroscout-resource.service';

import { TranslationService } from '@services/utils/translation.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'admin-carts-create',
    templateUrl: './admin-carts-create.html',
    styleUrls: ['./admin-carts-create.scss'],
})
export class AdminCartsCreate extends AssignableForm {
    @Input() cart: any;

    aeroscoutEnabled: boolean;
    aeroscoutAssetName: string;
    aeroscoutAssetId: string;
    canSubmitAsset: boolean;
    editMode: boolean;
    eventListeners: { (): void }[];

    constructor(
        protected $state: StateService,
        protected $stateParams: Transition,
        protected actionService: ActionService,
        protected barcodeScanService: BarcodeScanService,
        protected commonCRUDAPIService: CommonCRUDAPIService,
        private hospitalInfoService: HospitalInfoService,
        private aeroscoutResource: AeroscoutResource,
        private aeroscoutService: AeroscoutService,
        protected dialog: MatDialog,
        protected translationService: TranslationService
    ) {
        super($state, actionService, barcodeScanService, commonCRUDAPIService, dialog, translationService);
        this.assignType = 'cart';
        this.editMode = false;
        this.canSubmitAsset = true;
    }

    ngOnInit(): void {
        this.assignObject = this.cart;
        super.ngOnInit();
        this.editMode = !!this.$stateParams.params().cartId;
        this.aeroscoutAssetName = this.editMode && this.cart.aeroscout_asset_name ? this.cart.aeroscout_asset_name : '';
        this.aeroscoutAssetId = this.editMode && this.cart.aeroscout_asset_id ? this.cart.aeroscout_asset_id : '';
        this.aeroscoutEnabled = this.hospitalInfoService.getHospitalSettings().aeroscout_enabled;

        if (this.aeroscoutEnabled) {
            this.aeroscoutService.getAeroscoutUrl();
        }

        this.aeroscoutService.observeAssetSelection().subscribe((asset: any) => {
            if (!!asset) {
                this.assetWasSelected(asset.name, asset.id);
            }
        });

        this.aeroscoutService.observeAssetClear().subscribe((event) => {
            if (!!event) {
                this.canSubmitAsset = false;
            }
        });
    }

    save(form: NgForm): Promise<any> {
        if (this.aeroscoutEnabled && this.aeroscoutAssetName.length > 0) {
            return new Promise((resolve) => {
                super.save(form, { noRedirect: true }).then((result: any) => {
                    let cartId = (result && result.id) || this.$stateParams.params().cartId;
                    if (!!cartId) {
                        return this.aeroscoutResource
                            .postAeroscoutAsset(cartId, this.aeroscoutAssetName, this.aeroscoutAssetId)
                            .then(() => {
                                this.$state.go('admin.carts');
                                resolve();
                            })
                            .catch((e) => {
                                if (!this.editMode) {
                                    this.doCrud({ id: cartId }, 'remove', false).then(() => resolve(e));
                                }
                            });
                    }
                });
            });
        } else if (this.aeroscoutEnabled && this.editMode) {
            let cartId = this.$stateParams.params().cartId;
            return this.aeroscoutResource.deleteFromCart(cartId).then(() => super.save(form));
        } else {
            return super.save(form);
        }
    }

    remove(): void {
        this.confirmRemove(this.cart);
    }

    assetWasSelected(name: string, id: string): void {
        this.aeroscoutAssetName = name;
        this.aeroscoutAssetId = id;
        this.canSubmitAsset = true;
    }
}
