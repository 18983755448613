import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitScanResource } from '@resources/kit-scan-resource.service';

@Injectable({ providedIn: 'root' })
export class KitScansResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitScanResource: KitScanResource
    ) {}

    resolve(): Promise<any> {
        const kitId = this.$state.transition.params().kitId;
        const paging_params = {
            page_size: 25,
            page_index: 1,
        };
        const kitScanPromise = this.kitScanResource.kitScanListPaged(kitId, paging_params).then((data) => {
            return data;
        });

        return this.loadingSpinnerService.spinnerifyPromise(kitScanPromise);
    }
}
