import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { ReportResource } from '@resources/report-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import * as moment from 'moment';

@Component({
    selector: 'pre-tagged-usage-history',
    templateUrl: './pre-tagged-usage-history.html',
    styleUrls: ['./pre-tagged-usage-history.scss'],
})
export class PreTaggedUsageHistory {
    filters;
    isLoading: boolean = false;
    hasData: boolean = false;
    hasRun: boolean = false;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[];
    apiUrl: string = 'reports/not_prepaid_usage_report';
    chargeModel: string;
    dataPx: string;
    downloadDataPx: string;

    constructor(
        protected reportResource: ReportResource,
        protected HospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.chargeModel = this.HospitalInfoService.getHospitalSettings().charge_model_type?.name;
        if (this.chargeModel === 'pay_as_you_go') {
            this.displayedColumns = ['month_year_name', 'usage_count', 'white_space'];
            this.dataPx = 'run-credit-hx-report-button';
            this.downloadDataPx = 'download-credit-hx-report-button';
        } else if (this.chargeModel === ChargeModelTypeName.pay_as_you_go) {
            this.displayedColumns = ['month_year_name', 'usage_count', 'purchased', 'rolled_over'];
            this.dataPx = 'run-credit-hx-report-button';
            this.downloadDataPx = 'download-credit-hx-report-button';
        }
    }

    refreshReport(filters) {
        let start_date = moment(filters.dateMonth.start_date.value).format('YYYY-MM');
        let end_date = moment(filters.dateMonth.end_date.value).format('YYYY-MM');
        this.filters = {
            start_date: start_date,
            end_date: end_date,
        };

        this.isLoading = true;
        this.reportResource.preTaggedUsageReport(this.filters).then((data) => {
            this.dataSource = new MatTableDataSource(data.reports);
            this.isLoading = false;
            this.hasRun = true;
            this.hasData = !!this.dataSource.data.length;
        });
    }
}
