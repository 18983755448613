import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import * as _ from 'lodash';

import { isEmpty } from '@utils/objects';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';

import { FormularyItem } from '@models/core/formulary/formulary-item';

@Component({
    selector: 'item-select-alias',
    templateUrl: './item-select-alias.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ItemSelectAlias implements OnInit, OnChanges {
    @Input() item: FormularyItem;
    @Input() itemAliasSelect;
    @Input() tagItem;

    @ViewChild('preferredItemName') preferredItemName: NgModel;
    @ViewChild('preferredItemStrength') preferredItemStrength: NgModel;
    @ViewChild('preferredItemStrengthUom') preferredItemStrengthUom: NgModel;

    itemTypes: any[];
    selectedType: any;

    constructor(private parseFormErrorsService: ParseFormErrorsService) {}

    ngOnInit() {
        this.itemTypes = [{ name: this.item.type_name_formatted }, { name: 'Custom' }];

        this.item.preferred_item_strength = parseFloat(this.item.preferred_item_strength);

        this.setDefaultSelectType();

        if (this.hasPreferredContent(this.item) && !this.tagItem) {
            this.selectedType = { name: 'Custom' };
        }

        this.updateSelectedType();
    }

    // reset the select field when a new item is picked
    ngOnChanges(changeObj) {
        if (this.tagItem && changeObj.item) {
            this.setDefaultSelectType();
        }
    }

    setDefaultSelectType() {
        this.selectedType = { name: this.item.type_name_formatted };
    }

    updateSelectedType() {
        if (_.isUndefined(this.itemAliasSelect)) {
            this.itemAliasSelect = {};
        }
        this.itemAliasSelect.name = this.selectedType.name;
        this.updateItemData();
    }

    hasPreferredContent(item) {
        const itemPreferredProps = [
            item.preferred_item_name,
            item.preferred_item_strength,
            item.preferred_item_strength_uom,
        ];
        return _.some(itemPreferredProps, (i) => !isEmpty(i));
    }

    clearPreferredContent() {
        const itemPreferredContent: any = {
            preferred_item_name: null,
            preferred_item_strength: null,
            preferred_item_strength_formatted: null,
            preferred_item_strength_uom: null,
        };
        Object.assign(this.item, itemPreferredContent);
    }

    trackByFn(index: number, type: any): string {
        return type.name;
    }

    updateItemData() {
        if (this.itemAliasSelect.name === 'Custom') {
            const itemPreferredContent: any = {
                preferred_item_name: this.item.item_name,
                preferred_item_strength: parseFloat(this.item.item_strength),
                preferred_item_strength_formatted: this.item.item_strength_formatted,
                preferred_item_strength_uom: this.item.item_strength_uom,
            };
            Object.assign(this.item, itemPreferredContent);
        } else {
            const itemDictionaryContent: any = {
                item_name: this.item.dictionary_entry_item_trade_name,
                item_strength: this.item.dictionary_entry_item_strength,
                item_strength_formatted: this.item.dictionary_entry_item_strength_formatted,
                item_strength_uom: this.item.dictionary_entry_item_strength_uom,
            };
            Object.assign(this.item, itemDictionaryContent);
            this.clearPreferredContent();
        }
    }

    updateSummary(targetField) {
        if (this.tagItem) {
            this.item[targetField] = this.item['preferred_' + targetField];
        }
    }

    parseErrors(errors): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }
}
