import { Component, Input, Directive, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { TranslationService } from '@services/utils/translation.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const MONTH_DATE_FORMATS = {
    parse: {
        dateInput: ['MM/YYYY'],
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Directive({
    selector: '[monthFormat]',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_DATE_FORMATS }],
})
export class MonthFormat {}

@Directive({
    selector: '[dayMonthFormat]',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class DayMonthFormat {}

@Component({
    selector: 'kc-datepicker',
    templateUrl: './kc-datepicker.html',
    styleUrls: ['./kc-datepicker.scss'],
    providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }],
    styles: [':host {display: inline-block; width: 160px; min-width: 160px;}'],
})
export class KCDatePicker {
    @Input() monthYearOnly: boolean;
    @Input() _date: Date;
    @Input() disabled: boolean = false;
    @Input() calendarBelowInput;
    @Input() inputId: string;
    @Input() outlineStyle: boolean = false;
    @Output() onChange = new EventEmitter();
    @Output() onMonthChange = new EventEmitter();
    @Output() openCalendarBelowInput = new EventEmitter();
    placeholder: string;
    @ViewChild('dpContainer') dpContainer: ElementRef; // Datepicker container Element.
    @ViewChild('dateControl') dateControl: NgModel; // NgModel object containing validation data.
    @Input() monthDate: FormControl;
    @Input() futureOnly: boolean;
    Date_Formats = DATE_FORMATS;
    Month_Date_Formats = MONTH_DATE_FORMATS;
    today = new Date();

    constructor(private translationService: TranslationService) {
        this.placeholder = this.translationService.instant('formats.placeholder');
    }

    @Input() set date(date: Date) {
        this._date = date;
    }

    get date() {
        return this._date;
    }

    onOpenCalendarBelowInput(open) {
        this.openCalendarBelowInput.emit(open);
    }

    onDateChange(ev: MatDatepickerInputEvent<moment.Moment>) {
        // Check if the datepicker is being validated, and if so check validity before saving data.
        if (!this.dateControl || (!!this.dateControl && !!this.dateControl.valid)) {
            let date = ev.value;
            if (!!date) {
                this.date = date.toDate();
                this.onChange.emit(this.date);
            } else {
                this.date = null;
                this.onChange.emit(this.date);
            }
        } else if (!!this.dateControl && !this.dateControl.valid) {
            this.date = null;
            this.onChange.emit(this.date);
        }
    }

    chosenYearHandler(normalizedYear) {
        const ctrlValue = this.monthDate.value;
        ctrlValue.year(normalizedYear.year());
        this.monthDate.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth, datepicker: MatDatepicker<any>) {
        const ctrlValue = this.monthDate.value;
        ctrlValue.month(normalizedMonth.month());
        this.monthDate.setValue(ctrlValue);
        datepicker.close();
        this.onMonthChange.emit(this.monthDate);
    }

    onMonthDateChange(ev: MatDatepickerInputEvent<moment.Moment>) {
        this.onMonthChange.emit(this.monthDate);
    }
}
