import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationService } from '@services/system/application.service';
import { TranslationService } from '@services/utils/translation.service';

import { timer } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';

@Component({
    selector: 'system-refresh',
    templateUrl: './system-refresh.html',
    styleUrls: ['./system-refresh.scss'],
})
export class SystemRefresh implements OnInit {
    VERSION_TIMER_INTERVAL = 30000;
    REFRESH_TIMER_INTERVAL = 1000;
    REFRESH_COUNTDOWN_TIME = 60;

    private versionSubscription: ISubscription = null;
    private refreshTimerSubscription: ISubscription = null;
    countdownTimer: number;
    countdownString: string;
    nextVersion: string;

    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.startVersionTimer();
    }

    startVersionTimer() {
        if (!!this.versionSubscription) {
            return;
        }
        this.versionSubscription = timer(0, this.VERSION_TIMER_INTERVAL).subscribe(() => {
            this.checkVersion();
        });
    }

    stopVersionTimer() {
        this.versionSubscription?.unsubscribe();
        this.versionSubscription = null;
    }

    checkVersion() {
        this.http
            .get<any>('/assets/version.json')
            .toPromise()
            .then((data) => {
                if (data.version.toString() === 'outage') {
                    this.refresh();
                }
                this.applicationService.needsRefresh = data.version.toString() !== this.applicationService.version;
                if (this.applicationService.needsRefresh) {
                    this.nextVersion = data.version.toString();
                    this.stopVersionTimer();
                    this.doCountdown();
                }
            });
    }

    doCountdown() {
        this.countdownTimer = this.REFRESH_COUNTDOWN_TIME;
        this.refreshTimerSubscription = timer(0, this.REFRESH_TIMER_INTERVAL).subscribe(() => {
            this.countdownTimer = this.countdownTimer - 1;
            this.countdownString = this.translationService.inflect('common.second', this.countdownTimer);
            if (this.countdownTimer === 0) {
                this.refresh();
            }
        });
    }

    stopRefreshTimer() {
        this.refreshTimerSubscription?.unsubscribe();
        this.refreshTimerSubscription = null;
    }

    snooze() {
        this.countdownTimer = 300;
    }

    refresh() {
        this.stopVersionTimer();
        this.stopRefreshTimer();
        this.countdownTimer = 0;
        this.applicationService.version = this.nextVersion;
        window.location.reload();
    }
}
