import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'bronson',
    templateUrl: './bronson.html',
})
export class Bronson implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }

    filterSegments(segmentId) {
        return this.chargeData.segments.filter((segment) => segment.id === segmentId);
    }
}
