import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { GroupLoginService } from '@services/login/group-login.service';

export enum LoginStateTransitions {
    LOGGING_IN = 'loggingIn',
    LOGGING_OUT = 'loggingOut',
}

export interface LoginStateParams {
    transition: LoginStateTransitions;
    withoutBackend?: boolean;
    timedOut?: boolean;
}

@Injectable()
export class LoginStateService {
    private loginStateObserver: BehaviorSubject<any> = new BehaviorSubject(null);

    loggedIn: boolean;
    requirePassword: boolean;

    constructor(private groupLoginService: GroupLoginService) {
        this.loggedIn = this.groupLoginService.isLoggedIn();
        this.requirePassword = this.groupLoginService.requirePassword();
    }

    observeLoginState(): Observable<any> {
        return this.loginStateObserver;
    }

    notifyLogin(): void {
        this.loginStateObserver.next({ transition: LoginStateTransitions.LOGGING_IN });
        this.loggedIn = true;
    }

    notifyLogout(withoutBackend: boolean = false, timedOut: boolean = false): void {
        this.loginStateObserver.next({
            transition: LoginStateTransitions.LOGGING_OUT,
            withoutBackend: withoutBackend,
            timedOut: timedOut,
        });
    }
}
