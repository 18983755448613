import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mercy-labor-and-delivery-single-narc',
    templateUrl: './mercy-labor-and-delivery-single-narc.html',
})
export class MercyLaborAndDeliverySingleNarc implements OnInit {
    @Input() chargeData;
    @Input() kitSummary;

    constructor() {}

    ngOnInit() {}
}
