import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { BannerService } from '@services/system/banner.service';
import { FileUploadService } from '@services/utils/file-upload.service';
import { FirmwareRevisionsResource } from '@resources/firmware-revisions-resource.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { RetryService } from '@services/utils/retry.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { IFirmwareRevision } from '@models/hardware/firmware-revisions';
import { Scanner } from '@models/hardware/scanner';

@Injectable()
export class ThingMagicService {
    firmwareVersionMap = {
        apps_version: 'tmapp',
        firmware_loader_version: 'tmsafe',
        kc_sargas_version: 'kc-sargas',
        kc_sargas_safe_version: 'kc-sargas-safe',
        tmrfid_version: 'tmrfid',
        tm_version: 'sargas-core',
        tm_core_version: 'tmreader-core',
        web_ui_version: 'tmweb',
    };

    constructor(
        private http: HttpClient,
        private bannerService: BannerService,
        private fileUploadService: FileUploadService,
        private firmwareRevisionsResource: FirmwareRevisionsResource,
        private hardwareResource: HardwareResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService,
        private retryService: RetryService,
        private scannerUpdatingService: ScannerUpdatingService
    ) {}

    sargasToken() {
        return this.localStorageService.get('sat');
    }

    headers() {
        /* eslint-disable */
        return { Authorization: this.sargasToken() };
        /* eslint-enable */
    }

    callWithSafeFallback(requestConfig, scanner, path): Promise<any> {
        requestConfig.url = `${scanner.value}/${path}`;
        const safeUrl = `${scanner.safeValue}/${path}`;

        return this.retryService.callWithFallback(requestConfig, safeUrl);
    }

    scan(scanner) {
        const params = this.scanParameters(scanner.hardware_settings);
        const requestConfig = {
            method: 'GET',
            headers: this.headers(),
            params,
        };
        // we may want to reconsider auto-retrying a failed scan, this could be creating "longer than expected" scans for our users

        return this.callWithSafeFallback(requestConfig, scanner, scanner.hardware_settings.scan_endpoint);
    }

    // Aeroscout auth goes in the body, because the headers already contain auth for the sargas
    aeroscoutLogin(scanner, host, auth) {
        /* eslint-disable */
        let data = { aeroscout_url: host, Authorization: auth };
        /* eslint-enable */
        return this.callWithSafeFallback(
            { method: 'POST', headers: this.headers(), data },
            scanner,
            'api/aeroscout-login-proxy'
        );
    }

    aeroscoutSearch(scanner, host, auth, name) {
        /* eslint-disable */
        let data = { aeroscout_url: host, Authorization: auth, name };
        /* eslint-enable */
        return this.callWithSafeFallback(
            { method: 'POST', headers: this.headers(), data },
            scanner,
            'api/aeroscout-search-proxy'
        );
    }

    checkAlive(scanner, safe?: boolean) {
        console.log(`Check ${safe ? 'safe ' : ''}alive...`);
        return this.firmwareRevisions(scanner, safe).catch(() => {
            return new Promise((resolve) => setTimeout(resolve, 12500)).then(() => {
                return this.checkAlive(scanner, safe);
            });
        });
    }

    reboot(scanner) {
        let rebootResponse;
        console.log('Getting ready to reboot sargas: ', scanner);
        return this.checkAlive(scanner)
            .then(() => {
                console.log('Performing reboot!');

                const requestConfig = {
                    method: 'GET',
                    headers: this.headers(),
                };

                return this.callWithSafeFallback(requestConfig, scanner, 'api/reboot');
            })
            .then((data) => {
                rebootResponse = data;
                return new Promise((resolve) => setTimeout(resolve, 15000));
            })
            .then(() => this.checkAlive(scanner))
            .then(() => {
                console.log('Rebooted successfully!');
                return rebootResponse;
            });
    }

    firmwareRevisions(scanner, safe?: boolean): Promise<any> {
        const requestConfig: any = {
            method: 'GET',
            headers: this.headers(),
        };

        // Don't need to use fallback if we're already calling the safe URL for other reasons
        if (safe) {
            requestConfig.url = `${scanner.safeValue}/api/firmware`;
            return this.http.get(requestConfig.url, { headers: this.headers() }).toPromise();
        } else {
            return this.callWithSafeFallback(requestConfig, scanner, 'api/firmware');
        }
    }

    sendFirmwareUpgrade(scanner, file, fileName) {
        console.log('Sending upgrade for: ', fileName);
        let url = `${scanner.value}/api/upgrade`;
        return this.fileUploadService.uploadArrayBuffer(url, file, fileName, 'fw', this.headers()).catch((e) => {
            console.log(`Base install of ${fileName} failed, trying on safe version!`);
            return this.checkAlive(scanner, true).then((r) => {
                url = `${scanner.safeValue}/api/upgrade`;
                console.log('Sending SAFE upgrade for: ', fileName);
                return this.fileUploadService.uploadArrayBuffer(url, file, fileName, 'fw', this.headers()).then((r) => {
                    return r;
                });
            });
        });
    }

    updateHardwareConfig(scanner) {
        let url = `${scanner.value}/api/ssl`;
        return this.http
            .get(url, { headers: this.headers() })
            .toPromise()
            .then((data) => {
                // The response.data includes the manufacturer, make, model and submodel for the scanners (same as settings_v2,psp for the old scanners)
                // but the labels may be inaccurate
                // Currently mapped as:
                // manufacturer -> "JADAK"
                // make -> "ThingMagic"
                // model -> HW MODEL
                // submodel -> MODEL ID HEX
                return this.hardwareResource.hardwareConfig(scanner.id, data);
            });
    }

    updateSSL(scanner) {
        console.error('Scanner update SSL called on incompatible scanner type');
    }

    updateFirmware(scanner) {
        console.log('TIME TO UPDATE YOUR FIRMWARE: ', scanner);

        this.bannerService.systemCaution =
            "Scanner update in progress. Please don't use the scanner or close the browser window until the update is complete.";
        this.bannerService.systemCautionNotDismissable = true;
        window.onbeforeunload = () => {
            return 'The scanner is currently being updated. Please stay on this page until the update is complete. Are you sure you want to leave this page?';
        };

        this.scannerUpdatingService.isUpdating = true;

        scanner.loading = true;
        scanner.updating = true;

        const totalSteps = scanner.needsUpdate.length * 2; // Extra steps are for rebooting after each pkg install

        const progress = (stepPercentage, message) => {
            const remainingSteps = scanner.needsUpdate.length + (1 - stepPercentage) + 1; // Extra +1 is for rebooting
            const remainingPercentage = 1 - remainingSteps / totalSteps;
            this.scannerUpdatingService.updateProgress(remainingPercentage, message);
        };

        let errorEncountered = false;

        const installNextRevision = () => {
            if (scanner.needsUpdate.length) {
                const firmwareRevision = scanner.needsUpdate.pop();
                console.log('Installing firmware revision: ', firmwareRevision);
                progress(
                    0.1,
                    `Connecting to Update Service for ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                );
                return this.checkAlive(scanner)
                    .then(() => {
                        progress(
                            0.25,
                            `Downloading Revision File for ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                        );
                        return this.firmwareRevisionsResource.downloadFirmwareRevision(firmwareRevision.id);
                    })
                    .then((file) => {
                        console.log('Got revision file: ', file);
                        progress(
                            0.5,
                            `Installing firmware revision ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                        );
                        const fileName = `${firmwareRevision.firmware_revision_type}_${firmwareRevision.version}.deb`;
                        return this.sendFirmwareUpgrade(scanner, file, fileName);
                    })
                    .then((response) => {
                        console.log('Firmware upgrade status: ', response?.status, ' - message: ', response?.message);
                        progress(1, `Firmware upgrade status: ${response?.message}`);
                        if (!response?.status && !response?.message) {
                            return Promise.reject(response?.message);
                        } else if (scanner.needsUpdate.length > 0) {
                            return this.reboot(scanner).then(() => {
                                return installNextRevision();
                            });
                        } else {
                            return installNextRevision();
                        }
                    })
                    .catch((e) => {
                        errorEncountered = true;
                        console.log(
                            `Firmware upgrade error during install of ${firmwareRevision.firmware_revision_type}_${firmwareRevision.version}`,
                            e
                        );
                    });
            } else {
                return Promise.resolve();
            }
        };

        return installNextRevision()
            .then(() => {
                const rebootPercentage = 1 - 0.5 / totalSteps;
                this.scannerUpdatingService.updateProgress(rebootPercentage, 'Rebooting Scanner');
                return this.reboot(scanner);
            })
            .then(() => {
                return this.updateHardwareConfig(scanner).then(() => {
                    this.scannerUpdatingService.updateProgress(1, 'Scanner Rebooted Successfully');
                    return Promise.resolve();
                });
            })
            .catch((err) => {
                errorEncountered = true;
                console.warn('Update error: ', err);
            })
            .finally(() => {
                const refreshUpdateBanner = () => {
                    this.bannerService.systemCaution = undefined;
                    this.bannerService.systemCautionNotDismissable = undefined;
                    window.onbeforeunload = null;
                    this.scannerUpdatingService.isUpdating = false;
                    scanner.loading = false;
                    scanner.updating = false;
                };

                if (errorEncountered) {
                    refreshUpdateBanner();
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'modals.scanner_update.update_error',
                    });
                } else {
                    this.checkNeedsUpdate(scanner).finally(() => {
                        refreshUpdateBanner();
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'modals.scanner_update.update_success',
                        });
                        if (scanner.needsUpdate.length) {
                            scanner.online = 'UPGRADE';
                        } else {
                            scanner.online = 'YES';
                        }
                    });
                }
            });
    }

    readSargasFirmwareRevs(device): Promise<any> {
        let firmwareRevisions;
        return this.firmwareRevisions(device)
            .then(({ data }) => {
                if (!data) {
                    return false;
                }

                firmwareRevisions = _(data)
                    .map((version: string, versionType: string) => {
                        const versionMatch = version.match(/^(\d+(\.\d+)*)/);
                        if (versionMatch && versionMatch.length) {
                            version = versionMatch[0];
                        } else {
                            return;
                        }

                        const firmware_revision_type = this.firmwareVersionMap[versionType];

                        if (!firmware_revision_type) {
                            return;
                        }

                        return {
                            version,
                            firmware_revision_type,
                        } as IFirmwareRevision;
                    })
                    .compact()
                    .value();

                device.firmwareRevisions = firmwareRevisions;

                return firmwareRevisions.length
                    ? this.hardwareResource.setFirmwareRevisions(device.id, firmwareRevisions)
                    : Promise.resolve();
            })
            .then((): IFirmwareRevision[] => firmwareRevisions);
    }

    checkNeedsUpdate(device, firmwareRevisions?) {
        const getFirmwareRevisions = firmwareRevisions
            ? Promise.resolve(firmwareRevisions)
            : this.readSargasFirmwareRevs(device);

        return getFirmwareRevisions.then((revisions) => {
            firmwareRevisions = revisions;
            device.needsUpdate = [];
            _.each(device.hardware_type_firmware_revisions, (revisionForType: IFirmwareRevision) => {
                const revisionOnScanner: IFirmwareRevision = _.find(firmwareRevisions, {
                    firmware_revision_type: revisionForType.firmware_revision_type,
                }) as IFirmwareRevision;
                if (!revisionOnScanner || revisionForType.version !== revisionOnScanner.version) {
                    device.needsUpdate.push(revisionForType);
                }
            });
        });
    }

    checkStatus(scanner: Scanner) {
        if (scanner.statusCheckPromise) {
            return scanner.statusCheckPromise;
        }

        scanner.loading = true;

        scanner.statusCheckPromise = this.readSargasFirmwareRevs(scanner)
            .then((firmwareRevisions) => {
                scanner.online = 'YES';

                return this.checkNeedsUpdate(scanner, firmwareRevisions);
            })
            .then(() => {
                if (scanner.needsUpdate.length) {
                    scanner.online = 'UPGRADE';
                } else {
                    scanner.online = 'YES';
                }
            })
            .catch(() => {
                console.log(`${scanner.name}: device offline`);
                scanner.online = 'NO';
                scanner.needsUpdate = _.compact([
                    _.find(scanner.hardware_type_firmware_revisions, { name: 'kc-sargas' }),
                ]);
            })
            .finally(() => {
                scanner.loading = false;
                delete scanner.statusCheckPromise;
            });

        return scanner.statusCheckPromise;
    }

    scanParameters(hardwareSettings: any): any {
        let params = {};
        let scannerProfileSettings = hardwareSettings.scanner_settings_profile;
        params['duration'] = (!!hardwareSettings.scan_duration ? hardwareSettings.scan_duration : 4) * 1000.0;
        params['absolute_timeout'] =
            (!!hardwareSettings.absolute_timeout ? hardwareSettings.absolute_timeout : 20) * 1000.0;
        if (!!scannerProfileSettings.session) {
            params['session'] = scannerProfileSettings.session;
        }
        if (!!scannerProfileSettings.power) {
            // kc-api stores power in dBm, TM scanners want it in cdBm:
            params['power'] = scannerProfileSettings.power * 100.0;
        }
        if (!!scannerProfileSettings.link_frequency) {
            // kc-api stores this in kHz
            params['link_frequency'] = scannerProfileSettings.link_frequency;
        }
        if (!!scannerProfileSettings.encoding) {
            params['encoding'] = scannerProfileSettings.encoding;
        }
        if (!!scannerProfileSettings.q_value) {
            params['q_value'] = scannerProfileSettings.q_value;
        }
        if (!!scannerProfileSettings.target) {
            params['target'] = scannerProfileSettings.target;
        }
        if (!!scannerProfileSettings.tari) {
            params['tari'] = scannerProfileSettings.tari;
        }
        return params;
    }
}
