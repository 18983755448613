import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';
import { ItemResource } from '@resources/item-resource.service';
import * as moment from 'moment';

import { Package } from '@models/core/package';

interface ParamData {
    item: Package;
}

//require ('./mark-opened-dialog.scss');
@Component({
    selector: 'mark-opened-dialog',
    templateUrl: './mark-opened-dialog.html',
})
export class MarkOpenedDialog {
    item: Package;
    dateOpened: any;
    multidose_expired: string;
    multidose_opened: string;
    dateInTheFuture: boolean;
    dateFormat: string;

    constructor(
        protected translationService: TranslationService,
        protected itemResource: ItemResource,
        public dialogRef: MatDialogRef<MarkOpenedDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.item = this.data.item;
        this.dateFormat = this.translationService.instant('formats.medium_date');
        this.dateOpened = new Date();
        this.multidose_expired = moment().add('days', this.item.expiring_multidose_days).format(this.dateFormat);
        this.multidose_opened = moment().format(this.dateFormat);
        this.dateInTheFuture = false;
    }

    updateParsedDate(date): void {
        if (!date) {
            this.dateInTheFuture = false;
            return;
        }
        if (moment(date).toDate() < moment().toDate()) {
            this.multidose_expired = moment(date)
                .add('days', this.item.expiring_multidose_days)
                .format(this.dateFormat);
            this.multidose_opened = moment(date).format(this.dateFormat);
            this.dateInTheFuture = false;
        } else {
            this.dateInTheFuture = true;
            this.multidose_expired = null;
        }
    }

    onOk(): void {
        const data = {
            id: this.item.epc,
            expiration_multidose: this.multidose_expired,
            expiration_multidose_opened_on: this.multidose_opened,
        };

        this.itemResource.updateItem(data).then(() => {
            this.dialogRef.close(true);
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
