import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BarcodeResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    barcodeObject(barcode: string): Promise<any> {
        return this.http
            .post<any>(`${this.API_ENDPOINT}barcodes/objects/show_with_create`, { id: barcode })
            .toPromise();
    }
}
