import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'kc-date-input',
    templateUrl: './kc-date-input.html',
})
export class KCDateInput {
    // Bindings from component attr inputs
    @Input() variant: string = 'fill'; // Input style, defaults
    @Input() label: string;
    @Input() date: Date; // 2 Way binding
    @Output() dateChange = new EventEmitter<Date>(); // 2 Way binding

    placeholder: string;

    constructor(private translationService: TranslationService) {
        this.placeholder = this.translationService.instant('formats.placeholder');
    }

    dateChanged($event: MatDatepickerInputEvent<Date>): void {
        this.dateChange.emit($event.value);
    }
}
