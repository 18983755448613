import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ReportScopeToDataService {
    dateFilter: any;

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    typeExpiryAttributes($scope): any {
        const data: any = {};
        if (
            $scope.filters.kit_master_id &&
            $scope.filters.kit_master_id !== 'all' &&
            $scope.filters.report_mode !== 'bin'
        ) {
            data.kit_master_id = $scope.filters.kit_master_id;
        }

        if ($scope.filters.kit_masters && $scope.filters.report_mode !== 'bin') {
            data.kit_master_id = $scope.filters.kit_masters.map(({ id }) => id).join('|');
        }

        if ($scope.filters.ndc) {
            data.ndc = $scope.filters.ndc;
        }
        if ($scope.filters.lot_num) {
            data.lot_num = $scope.filters.lot_num;
        }
        if ($scope.filters.days_expiring_within) {
            data.days_expiring_within = $scope.filters.days_expiring_within;
        }
        if ($scope.filters.group_ids) {
            data.group_ids = $scope.filters.group_ids.join('|');
        }
        if ($scope.filters.report_mode) {
            data.report_mode = $scope.filters.report_mode;
        }
        if ($scope.filters.sort && $scope.filters.sortOrder) {
            data.sort = $scope.filters.sort;
            if ($scope.filters.sortOrder === 'desc') {
                data.sort = '-' + data.sort;
            }
        }
        if ($scope.filters.page) {
            data.page = $scope.filters.page;
            data.per_page = $scope.filters.per_page;
        }
        if ($scope.filters.clinical_equivalence) {
            data.clinical_equivalence = $scope.filters.clinical_equivalence;
            data.clinical_equivalence_id = $scope.filters.clinical_equivalence.clinical_equivalence_id;
        }
        return data;
    }

    filterIncludeExpiredItems($scope): any {
        return {
            include_expired_items: $scope.filters.include_expired_items ? 1 : 0,
        };
    }

    typeExpiry($scope): any {
        const data: any = {};
        _.merge(data, this.typeExpiryAttributes($scope));
        _.merge(data, this.filterIncludeExpiredItems($scope));
        return data;
    }

    typeAttributesNameCustom($scope): any {
        return this.typeExpiry($scope);
    }

    dateRange($scope): any {
        return {
            start_date: formatDate($scope.filters.start_date, 'yyyy-MM-dd', this.locale),
            end_date: formatDate($scope.filters.end_date, 'yyyy-MM-dd', this.locale),
        };
    }

    nameRecent($scope): any {
        const data: any = {};

        if ($scope.filters.user_ids !== 'all') {
            data.user_ids = $scope.filters.user_ids;
        }
        if ($scope.filters.action_names !== 'all') {
            data.action_names = $scope.filters.action_names;
        }

        return data;
    }

    keyUserActivity($scope): any {
        const data: any = this.dateRange($scope);
        _.merge(data, this.nameRecent($scope));

        if ($scope.filters.action_names === null) {
            data.action_names = 'all';
        }

        if ($scope.filters.user_ids === null) {
            data.user_ids = 'all';
        }

        return data;
    }

    nameRework($scope): any {
        const data: any = {
            scanned_in_last_interval: $scope.filters.scanned_in_last_interval,
            hide_completed: $scope.filters.hide_completed ? 1 : 0,
        };

        if ($scope.filters.kit_master_id !== 'all') {
            data.kit_master_id = $scope.filters.kit_master_id;
        }

        return data;
    }

    nameByNDC($scope): any {
        const data: any = {};
        if ($scope.filters.ndc) {
            data.ndc = $scope.filters.ndc;
        }
        return data;
    }

    nameDispatch($scope): any {
        const data: any = this.dateRange($scope);
        if ($scope.filters.kit_master_id !== 'all') {
            data.kit_master_id = $scope.filters.kit_master_id;
        }
        return data;
    }

    nameKitActivity($scope): any {
        const data: any = this.nameDispatch($scope);
        if ($scope.filters.activities.length === 0) {
            data.activities = $scope.filters.activities;
        } else {
            data.activities = _.map($scope.filters.activities, (a: any) => a.activity).join('|');
        }
        if (!!$scope.filters.kit_ids) {
            data.kit_ids = $scope.filters.kit_ids;
        }
        return data;
    }
}
