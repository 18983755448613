import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SegmentsService } from '@services/core/segments.service';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';
import { ClinicalEquivalence } from '@models/core/clinical-equivalence';
import { KitMaster } from '@models/core/kit-master';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { SegmentTemplate } from '@models/core/segment-template';
import { Segment } from '@models/core/segment';
import { isEmpty } from '@utils/objects';

type AddType = 'generic' | 'specific';

interface FormularyItem {
    package_volume: string;
    package_volume_uom: string;
    clinical_equivalence_id: number;
    formulary_item_id: number;
}

interface ParamData {
    titleType: string; // should be the translated value for bin
    kitMaster: KitMaster;
    formularyItem: FormularyItem;
    ceqs: ClinicalEquivalence[];
    scanId: number;
}

interface Assignment {
    segment: Segment;
}

interface SegmentTemplateWithSelected extends SegmentTemplate {
    selected: boolean;
    disabled: boolean;
}

@Component({
    selector: 'add-formulary-item-to-segment-dialog',
    templateUrl: './add-formulary-item-to-segment-dialog.html',
    styleUrls: ['./add-formulary-item-to-segment-dialog.scss', '../add-formulary-item.scss'],
})
export class AddFormularyItemToSegmentDialog {
    assignment: Assignment;
    kitMaster: KitMaster;
    ceq: ClinicalEquivalence;
    disabledGeneric: boolean;
    errorMessage: string;
    formularyItem: FormularyItem;
    loading: boolean;
    scanId: number;
    step: number;
    type: AddType;
    segments: Segment[];
    selectFormularyType: boolean;
    selectSegment: boolean;
    segmentWithItem: Segment;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentsService: SegmentsService,
        private segmentTemplateResource: SegmentTemplateResource,
        public dialogRef: MatDialogRef<AddFormularyItemToSegmentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.formularyItem = this.data.formularyItem;
        this.kitMaster = this.data.kitMaster;
        this.scanId = this.data.scanId;
        this.ceq = this.data.ceqs.find((ceq) => ceq.id === this.data.formularyItem.clinical_equivalence_id);
        this.type = !this.disableGeneric() ? 'generic' : 'specific';
        this.disabledGeneric = this.disableGeneric();

        // set steps
        this.selectFormularyType = true;
        this.selectSegment = false;

        let segmentPromise = this.segmentsService.getSegments(this.kitMaster.id).then((segments) => {
            this.segments = segments;
            this.segmentWithItem = this.segments.find((segment) => {
                return (
                    !!segment.segment_templates &&
                    segment.segment_templates.some((segmentTemplate) => {
                        return segmentTemplate.formulary_items.find((formulary_item) => {
                            return formulary_item.id === this.formularyItem.formulary_item_id;
                        });
                    })
                );
            });

            this.resetAssignment();
        });
        this.loadingSpinnerService.spinnerifyPromise(segmentPromise, LoadingSpinnerTypes.BACKDROP);
    }

    resetAssignment(): void {
        if (this.segmentWithItem) {
            this.segmentWithItem.segment_templates.forEach((segmentTemplate: SegmentTemplateWithSelected) => {
                if (
                    !!segmentTemplate.formulary_items.find((formulary_item) => {
                        return formulary_item.id === this.formularyItem.formulary_item_id;
                    })
                ) {
                    segmentTemplate.disabled = true;
                    segmentTemplate.selected = true;
                }
            });
        } else {
            this.segments.forEach((segment) => {
                segment.segment_templates.forEach((segmentTemplate: SegmentTemplateWithSelected) => {
                    segmentTemplate.disabled = false;
                    segmentTemplate.selected = false;
                });
            });
        }

        this.assignment = {
            segment: this.segmentWithItem || null,
        };

        this.clearErrorMessage();
    }

    clearErrorMessage(): void {
        this.errorMessage = undefined;
    }

    selectType(selectedType: AddType): void {
        if (selectedType === 'generic' && this.disableGeneric()) {
            return;
        }

        this.type = selectedType;
    }

    disableGeneric(): boolean {
        return !this.ceq || isEmpty(this.formularyItem.package_volume);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    back(): void {
        this.selectFormularyType = true;
        this.selectSegment = false;
    }

    next(): void {
        this.selectFormularyType = false;
        this.selectSegment = true;
    }

    showTemplateBoxes(): boolean {
        return !!this.assignment.segment && this.assignment.segment.segment_templates.length >= 1;
    }

    addToSegment(type: AddType): void {
        let promises;
        this.loading = true;
        switch (type) {
            case 'generic':
                promises = this.addSegmentGenericFormularyItem();
                break;
            case 'specific':
                promises = this.addSegmentFormularyItem();
                break;
        }
        Promise.all(promises)
            .then((results) => {
                this.dialogRef.close(true);
            })
            .catch((err) => {
                this.errorMessage = err.message;
                this.loading = false;
            });
    }

    addSegmentFormularyItem() {
        const segment = this.assignment.segment;
        const promises: any[] = [];

        segment.segment_templates.forEach((segmentTemplate: SegmentTemplateWithSelected) => {
            if (segment.segment_templates.length === 1 || segmentTemplate.selected) {
                const formularyItemIds = segmentTemplate.formulary_items.map((f: any) => f.formulary_item_id);
                const data = {
                    id: segmentTemplate.id,
                    scan_id: this.scanId,
                    formulary_item_ids: formularyItemIds.concat(this.formularyItem.formulary_item_id),
                };

                const promise = this.segmentTemplateResource.updateSegmentTemplate(data, true).then(() => {
                    this.segmentsService.clear(this.kitMaster.id);
                });

                promises.push(promise);
            }
        });
        return promises;
    }

    addSegmentGenericFormularyItem() {
        const segment = this.assignment.segment;
        const promises: any[] = [];

        segment.segment_templates.forEach((segmentTemplate: SegmentTemplateWithSelected) => {
            if (segment.segment_templates.length === 1 || segmentTemplate.selected) {
                const clinical_equivalence_hashes = segmentTemplate.clinical_equivalences.map((ceq) => {
                    return this.ceqHash(ceq);
                });

                clinical_equivalence_hashes.push({
                    clinical_equivalence_id: this.ceq.id,
                    capacity: {
                        scalar: this.formularyItem.package_volume,
                        uom: this.formularyItem.package_volume_uom,
                    },
                });

                const data = {
                    id: segmentTemplate.id,
                    scan_id: this.scanId,
                    clinical_equivalence_hashes,
                };

                const promise = this.segmentTemplateResource.updateSegmentTemplate(data, true).then(() => {
                    this.segmentsService.clear(this.kitMaster.id);
                });

                promises.push(promise);
            }
        });
        return promises;
    }

    ceqHash(obj) {
        return {
            clinical_equivalence_id: obj.clinical_equivalence_id,
            capacity: {
                scalar: obj.package_size_formatted || obj.package_volume,
                uom: obj.package_size_uom || obj.package_volume_uom,
            },
        };
    }
}
