import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';

@Injectable({
    providedIn: 'root',
})
export class CanadianPackageVerificationService {
    constructor(
        private formularyItemsService: FormularyItemsService,
        private kcMatSnackBarService: KCMatSnackBarService
    ) {}

    public debouncedSendSearchToAPI = _.debounce(this.sendSearchToAPI, 300);

    private sendSearchToAPI(scope: any): Promise<any> {
        if (scope.verification_item && scope.verification_item.ndc && scope.verification_item.ndc.length === 8) {
            let searchPromise = this.grabPackageInfo(scope.verification_item.ndc);
            searchPromise
                .then((packages) => {
                    scope.package_types = packages;
                    scope.item_package.model = packages[0];
                })
                .catch((err) => {
                    let message = err.message ? err.message : 'Server could not retrieve item package information';
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                });
            return searchPromise;
        } else if (scope.verification_item && scope.verification_item.ndc === '') {
            scope.item_package.model = null;
            scope.item_package.size = null;
        }
    }

    private grabPackageInfo(ndc) {
        return this.formularyItemsService.getFormularyItemsByNDC(ndc, null).then((data) => {
            const packages = _.chain(data.items)
                .map((item) => ({
                    description_id: item.package_description_id,
                    uom: item.package_size_uom,
                    sort_key: `${item.package_size_uom} ${item.package_description_name}`,
                }))
                .uniq((item) => item.sort_key)
                .value();
            return packages;
        });
    }
}
