import { Input, OnInit, Component, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { MatSnackBar } from '@angular/material/snack-bar';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { BarcodeService } from '@services/core/barcode.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'tag-add-tags',
    templateUrl: './tag-add-tags.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagAddTags {
    @Input() tag;

    @Output() tagChange = new EventEmitter();

    labelDuplicate: string = '';
    snackBarRef: MatSnackBar;
    tagError: string;
    boundClearLabel: Function;

    constructor(
        private barcodeScanService: BarcodeScanService,
        private barcodeService: BarcodeService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        if (this.tag.epc && this.tag.labels.length === 0) {
            this.tag.labels.push(this.tag.epc);
            this.tagChange.emit(this.tag);
        }

        this.boundClearLabel = this.clearLabel.bind(this);
    }

    addLabel(event) {
        this.labelDuplicate = '';
        this.tag.labelName = event;
        const scanValues = this.tag.labelName && this.barcodeScanService.processBarcode(this.tag.labelName);

        if (scanValues) {
            this.tagError = '';
            if (!_.includes(this.tag.labels, scanValues.epc)) {
                this.barcodeService
                    .barcodeObject(scanValues.epc)
                    .then((data) => {
                        if (data.object['class'] === 'Tag' && data.object['decommissioned'] === false) {
                            this.tag.labels.push(scanValues.epc);
                        } else {
                            let errorKey;
                            if (data.object['decommissioned'] === true) {
                                errorKey = 'tagging.tag_decommissioned';
                            } else if (data.object['class'] === 'Package') {
                                errorKey = 'tagging.tag_already_associated_with_package';
                            } else if (data.object['class'] === 'Kit') {
                                errorKey = 'tagging.tag_already_associated_with_kit';
                            } else if (data.object['class'] === 'Bin') {
                                errorKey = 'tagging.tag_already_associated_with_bin';
                            } else {
                                errorKey = 'tagging.tag_already_associated';
                            }

                            this.translationService.get(errorKey, { epc: scanValues.epc }).then((translation) => {
                                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, translation);
                            });
                        }
                    })
                    .catch((error) => {
                        this.tagError = error.message;
                    })
                    .finally(() => {
                        $('#barcode-scan-field-input').focus();
                    });
            } else {
                this.labelDuplicate = scanValues.epc;
                $('#barcode-scan-field-input').focus();
            }

            this.tag.labelName = '';
        }

        this.tagChange.emit(this.tag);
    }

    removeLabel(label) {
        if (this.labelDuplicate === label) {
            this.labelDuplicate = '';
        }

        this.tag.labels = _.without(this.tag.labels, label);
        this.tagChange.emit(this.tag);
    }

    clearLabel() {
        this.tag.labelName = '';
        this.tagChange.emit(this.tag);
    }
}
