import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'message-dialog',
    templateUrl: './message-dialog.html',
})
export class MessageDialog {
    constructor(
        public dialogRef: MatDialogRef<MessageDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    onClose(): void {
        this.dialogRef.close();
    }
}
