import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';

@Injectable({ providedIn: 'root' })
export class FormularyItemArchiveKitMasterListResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemResource: FormularyItemResource
    ) {}

    resolve(): Promise<any> {
        const formularyPromise = this.formularyItemResource
            .getFormularyItemActiveKitMasters(this.$state.transition.params().formularyItemId)
            .then((response) => response.kit_masters);

        return this.loadingSpinnerService.spinnerifyPromise(formularyPromise);
    }
}
