import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'epc' })
export class EpcPipe implements PipeTransform {
    /**
     * Formats an EPC
     *
     * @param str - epc string to format
     * @returns 'Any time' or '11am to 3pm'
     *
     */
    transform(str: string): string {
        if (!str) {
            return str;
        }

        const newVal = str.replace(/-/g, '');

        if (newVal.length !== 24) {
            return str;
        }

        return `${newVal.substr(0, 4)}-${newVal.substr(4, 4)}-${newVal.substr(8, 8)}-${newVal.substr(
            16,
            4
        )}-${newVal.substr(20, 4)}`;
    }
}
