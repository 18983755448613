import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

// description and detail can contain html
interface ConfirmData {
    title: string;
    intro: string;
    description: string;
    detail: string;
    okButton: string;
    cancelbutton: string;
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.html',
})
export class ConfirmDialog {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {}

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        this.dialogRef.close(true);
    }
}
