import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-locks',
    templateUrl: './charge-sheet-template-locks.html',
})
export class ChargeSheetTemplateLocks {
    @Input() chargeData;

    constructor() {}

    ngOnInit() {}
}
