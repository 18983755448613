import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { CatalogResource } from '@resources/catalog-resource.service';

@Injectable({ providedIn: 'root' })
export class CatalogsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private catalogResource: CatalogResource
    ) {}

    resolve(): Promise<any> {
        const promise = this.catalogResource.getHospitalCatalogs().then(({ catalogs }) => {
            return catalogs;
        });

        return this.loadingSpinnerService.spinnerifyPromise(promise);
    }
}
