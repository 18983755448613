import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DownloadHelperService {
    constructor(private http: HttpClient) {}

    simulateDownload(url: string, authToken: string): void {
        this.http.post(url, { authentication_token: authToken }).toPromise();
    }
}
