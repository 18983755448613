export default class FilterUtils {
    // Package filter.
    static packageLongName(pkg: any, skipEndSpace?: boolean) {
        return (
            stringToAdd(pkg.item_name) +
            stringToAdd(pkg.item_strength_formatted) +
            stringToAdd(pkg.item_strength_uom, skipEndSpace)
        );
    }

    // Unit of measure filter.
    static uomScalarFilter(
        attributeValue: any,
        zeroAsDash?: boolean,
        extraUomClasses?: string,
        extraScalarClasses?: string
    ) {
        if (attributeValue) {
            if (zeroAsDash && attributeValue.scalar === 0) {
                return '&#8211;';
            } else {
                const uomUnit = unit(attributeValue);
                const template = `<span class="scalar ${extraScalarClasses}">${uomUnit.scalar}</span>&nbsp;<span class="uom ${extraUomClasses}">${uomUnit.uom}</span>`;

                return template;
            }
        }
    }

    static packageLongDescription(pkg: any, skipEndSpace: boolean = false) {
        return (
            stringToAdd(pkg.package_size_formatted) +
            stringToAdd(pkg.package_size_uom) +
            stringToAdd(pkg.package_description_name, skipEndSpace)
        );
    }
}

/*
 Private functions
*/

// Package filter utils.
function stringToAdd(str: any, skipSpace?: boolean) {
    if (str === '0' || str === null || str === undefined || str === 0) {
        return '';
    } else {
        return str + (skipSpace ? '' : ' ');
    }
}

// Unit filter utils.
function fullUnit(a) {
    let newUnit = unit(a);

    if (newUnit) {
        return unit(newUnit.scalar_full, newUnit.uom);
    }
}

export function unit(a, b?) {
    if (a === undefined || a === null) {
        return null;
    }

    let scalar, scalar_full, uom;

    if (typeof a === 'object') {
        if (a.scalar_full !== undefined && a.scalar === undefined) {
            a.scalar_full = undefined; // guard against mis-matched units
        }
        scalar = a.scalar_formatted ? a.scalar_formatted : a.scalar;
        scalar_full = a.scalar_full ? a.scalar_full : a.scalar;
        uom = a.uom;
    } else {
        scalar = a;
        scalar_full = a;
        uom = b;
    }

    if (scalar === undefined || !String(scalar).length || uom === undefined) {
        return null;
    } else {
        return {
            scalar: String(scalar),
            scalar_full: String(scalar_full),
            uom: uom,
        };
    }
}
