import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    kit_masters: string; // | joined string of kit master ids
    include_empty: boolean;
}

interface FilterData {
    includeEmpty?: boolean;
    kitMasters?: Array<any>; // this may be an array of a hash with just ids, or an array of the full kit master data.
}

@Component({
    selector: 'cart-kit-masters-filter',
    templateUrl: './cart-kit-masters-filter.html',
})
export class CartKitMastersFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: any; // hash from report-filter component
    @Input() maxHeight: number;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeFilterData = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    @ViewChildren('IFilter') children: QueryList<IFilter>;

    ngOnInit() {
        if (!!this.apiSubscriptionData) {
            this.filterData = { kitMasters: [] };
            if (!!this.apiSubscriptionData.kit_masters) {
                this.filterData.kitMasters = this.apiSubscriptionData.kit_masters.split('|').map((km) => {
                    return { id: Number(km) };
                });
            }
            this.filterData.includeEmpty = this.apiSubscriptionData.include_empty;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected values to persist from report page
            this.apiFormattedData.emit(this.formatForApi());
            this.changeFilterData.emit(this.filterData);
        } else {
            this.filterData = { kitMasters: null };
        }
    }

    reset(): void {
        // this calls the reset function of each child component
        this.children.toArray().forEach((child) => {
            child.reset();
        });
    }

    changeKitMasters(data): void {
        this.filterData.kitMasters = data;
        this.emitFilterData();
    }

    emitFilterData(): void {
        this.changeFilterData.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'cartKitMasters', incomplete: isEmpty(this.filterData.kitMasters) });
    }

    formatForApi(): Array<any> {
        if (!!this.filterData.kitMasters) {
            let kitMasters = this.filterData.kitMasters
                .map((kitMaster) => kitMaster.id)
                .filter((n) => n)
                .join('|');
            return [{ kit_masters: kitMasters }, { include_empty: this.filterData.includeEmpty }];
        }
    }
}
