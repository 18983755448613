import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionResource } from '@resources/report-subscription-resource.service';

@Injectable({ providedIn: 'root' })
export class ReportSubscriptionsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private reportSubscriptionResource: ReportSubscriptionResource
    ) {}

    resolve(): Promise<any> {
        let subscriptionPromise = this.reportSubscriptionResource.subscriptionsList().then((data) => {
            return data.report_subscriptions;
        });

        return this.loadingSpinnerService.spinnerifyPromise(subscriptionPromise);
    }
}
