import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Injectable({ providedIn: 'root' })
export class ShelvedInventorySettingResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    resolve(): Promise<any> {
        let promise;

        if (!this.hospitalInfoService.allowShelvedInventory()) {
            promise = Promise.reject();
            this.$state.go('/');
        } else {
            promise = Promise.resolve();
        }

        return this.loadingSpinnerService.spinnerifyPromise(promise);
    }
}
