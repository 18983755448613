import { Directive, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[onlydigits]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OnlyDigitsValidateDirective),
            multi: true,
        },
    ],
})
export class OnlyDigitsValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return onlyDigitsValidator()(control);
    }
}

export function onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            return !RegExp(/^\d*$/).test(control.value) ? { onlydigits: true } : null;
        }
        return null;
    };
}
