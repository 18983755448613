import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'breakArray',
    pure: false,
})
export class BreakArrayPipe implements PipeTransform {
    transform(arr: string[]) {
        if (!arr || !arr.length) {
            return arr;
        }

        return arr.join('<br>');
    }
}
