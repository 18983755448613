import { Component, Inject } from '@angular/core';
import * as _ from 'lodash';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionService } from '@services/utils/action.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KitService } from '@services/core/kit.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BarcodeResource } from '@resources/barcode-resource.service';

import { Kit } from '@models/core/kit';
import { Cart } from '@models/core/cart';
import { Location } from '@models/core/location';
import { AllowlistResource } from '@resources/allowlist-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

@Component({
    selector: 'dispatch-kit-dialog',
    templateUrl: './dispatch-kit-dialog.html',
    styleUrls: ['./dispatch-kit-dialog.scss'],
})
export class DispatchKitDialog {
    aeroscoutEnabled: boolean;
    assignKitCartPermission: boolean;
    assignKitLocationPermission: boolean;
    allowCartToLocation: boolean;
    alwaysShowCart: boolean;
    alwaysShowLocation: boolean;
    carts: Cart[] = [];
    chargeWasteText: string;
    currentCart: Cart;
    currentCartLocation: Location;
    currentCartLocationId: number;
    currentLocation: Location;
    hasKitCartLocationOverride: boolean;
    kit: any;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    kitCleanValidation: any;
    locations: Location[] = [];
    locks;
    name: string;
    newLocation: Location;
    newCart: Cart;
    newCartLocation: Location;
    originalIds: any;
    selectedCart;
    selectedLocation;
    showChargeSheetCheckbox: boolean;
    showKits: boolean;
    showNone: boolean;
    displayedColumns: String[] = ['physical_label', 'location_name', 'cart_name'];
    locationsLoading: boolean = false;
    cartsLoading: boolean = false;
    //
    target: string;
    useRadio: boolean;

    constructor(
        private actionService: ActionService,
        protected barcodeResource: BarcodeResource,
        protected barcodeScanService: BarcodeScanService,
        protected hospitalInfoService: HospitalInfoService,
        public dialogRef: MatDialogRef<DispatchKitDialog>,
        private kitService: KitService,
        private allowlistService: AllowlistResource,
        protected loadingSpinnerService: LoadingSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        // Inputs
        this.kit = this.data.kit;
        this.dataSource = new MatTableDataSourceWithNaturalSort([this.kit]);
        this.initializeAllowlists().then(() => {
            this.initializeKitCart();
            this.initializeCurrentCartLocation();
            this.initializeDispatchToTarget();
            this.initializePermissions();
        });
        this.locks = this.data.locks;
        this.chargeWasteText = this.data.chargeWasteText;
        const allowPrint = this.actionService.isAllowAction(
            'kits_inventory',
            'print_charge_sheet',
            'Print charge sheet after dispatch modal'
        );

        if (allowPrint) {
            this.kit.print = this.kit.kit_master.print_charge_sheet_default;
        } else {
            this.kit.print = false;
        }

        this.kit.dispatchLock = this.kit.manual_locks?.dispatch_lock;
        this.kit.returnLock = this.kit.manual_locks?.return_lock;
        this.kit.dispatchValidations = this.kit.dispatch_validations;
        this.kit.kitWasCleaned = false;

        this.originalIds = {
            location_id: this.kit.location_id,
            cart_id: this.kit.cart_id,
        };

        this.kitCleanValidation = this.kit.dispatchValidations.find((dv) => dv.validation_type === 'kit_cleaned');
        this.registerScanner();
    }

    manualLocks(): boolean {
        return this.hospitalInfoService.manualLocks();
    }

    registerScanner() {
        this.barcodeScanService.registerListener((scanData) => {
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object.class === 'Location') {
                    let selectedLocation = this.locations.find((location) => location.id === data.object.id);
                    this.kit.location = selectedLocation;
                    this.kit.location_id = selectedLocation.id;
                    this.kit.location_name = selectedLocation.name;
                    this.kit.print = false;
                    this.target = 'location';
                    this.dispatch();
                }
                if (data && data.object.class === 'Cart') {
                    let selectedCart = this.carts.find((cart) => cart.id === data.object.id);
                    this.kit.cart = selectedCart;
                    this.kit.cart_id = selectedCart.id;
                    this.kit.cart_name = selectedCart.name;
                    this.kit.print = false;
                    this.target = 'cart';
                    this.dispatch();
                }
            });
        }, 'assignToLocation');
    }

    newCartName() {
        return this.carts.filter((cart) => cart.id === this.kit.cart_id).map((cart) => cart.name)[0];
    }

    isAeroscoutCart(cart) {
        return cart && cart.aeroscout_asset_name && this.hospitalInfoService.getHospitalSettings().aeroscout_enabled;
    }

    reloadLocation = () => {
        this.newCart = this.carts.find((cart) => cart.id === parseInt(this.kit.cart_id));
        this.aeroscoutEnabled = this.isAeroscoutCart(this.newCart);
        this.newCartLocation = null;

        this.loadLocationAllowlist(true).then(() => {
            if (this.newCart && this.newCart.location) {
                this.newCartLocation = _.find(this.locations, {
                    id: this.newCart.location.id,
                });
            }
            this.kit.cart_location_id = _.get(this.newCartLocation, 'id', null);
        });
    };

    radiosChanged() {
        this.kit.cart_id = this.kit.cart?.id;
        this.kit.cart_location_id = this.kit.cart?.location?.id;
        this.reloadLocation();
    }

    showCartWarning = () => {
        this.selectedCart = this.carts.find((cart) => cart.id === parseInt(this.kit.cart_id));
        return (
            (this.useRadio &&
                this.kit.cart_location_id &&
                this.kit.cart_id &&
                this.kit.cart_location_id !== this.hasLocation()) ||
            (!this.kit.cart_location_id && this.hasLocation())
        );
    };

    hasLocation = () => {
        if (this.kit.cart_id && this.selectedCart?.location) {
            return this.selectedCart.location.id;
        } else {
            return null;
        }
    };

    showLocationWarning = () => {
        return (
            this.useRadio &&
            this.kit.location_id &&
            this.kit.cart_id &&
            this.kit.location_id !== this.originalIds.location_id
        );
    };

    kitCleanValidationPassed = () => {
        if (!!this.kitCleanValidation) {
            return !this.kitCleanValidation.required || this.kit.kitWasCleaned;
        } else {
            return true;
        }
    };

    dispatch() {
        if (this.useRadio) {
            this.kit['useRadio'] = true;
            if (this.target === 'cart') {
                delete this.kit.location_id;
                delete this.kit.location_name;
            } else if (this.target === 'location') {
                delete this.kit.cart_id;
                delete this.kit.cart_name;
                delete this.kit.cart_location_id;
            } else if (this.target === 'none') {
                delete this.kit.location_id;
                delete this.kit.location_name;
                delete this.kit.cart_id;
                delete this.kit.cart_name;
                delete this.kit.cart_location_id;
            }
        }

        if (!this.kit.cart_id) {
            this.kit.cart_location_id = null;
        } else if (!!this.kit.location_id) {
            // if we have a cart set and the location_id is set, then set the cart location id as well.
            this.kit.cart_location_id = this.kit.location_id;
        }

        // if it's an aeroscout cart, we don't send location
        if (this.aeroscoutEnabled) {
            delete this.kit.cart_location_id;
        }

        this.dialogRef.close(this.kit);
    }

    initializeDispatchToTarget() {
        if (this.kit.cart_id) {
            this.target = 'cart';
        } else if (this.kit.location_id) {
            this.target = 'location';
        } else {
            if (this.carts.length > 0) {
                this.target = 'cart';
            } else if (this.locations.length > 0) {
                this.target = 'location';
            }
        }
    }

    initializeCurrentCartLocation() {
        this.currentCart = this.carts.find((cart) => cart.id === parseInt(this.kit.cart_id));
        this.currentLocation = this.locations.find((location) => location.id === parseInt(this.kit.location_id));
        this.aeroscoutEnabled = this.isAeroscoutCart(this.currentCart);

        if (this.currentCart && this.currentCart.location) {
            this.currentCartLocation = this.locations.find((location) => location.id === this.currentCart.location.id);
            if (this.currentCartLocation) {
                this.currentCartLocationId = this.currentCartLocation.id;
            }
        } else {
            this.currentCartLocation = null;
        }
    }

    initializeKitCart() {
        this.kit.cart_id = this.kit.cart?.id;
        this.selectedCart = this.carts.find((cart) => {
            return cart.id === this.kit.cart_id;
        });
        this.kit.location_id = this.kit.location?.id;
        this.kit.cart_location_id = this.selectedCart?.location?.id;
    }

    initializePermissions() {
        this.assignKitCartPermission =
            this.actionService.isAllowAction(
                'kits_inventory',
                'assign_kit_cart',
                'Show Assign to Cart in Dispatch modal'
            ) && this.carts.length > 0;
        this.useRadio =
            this.hospitalInfoService.hospitalSetting('cart_to_location_enabled') &&
            !this.hospitalInfoService.hospitalSetting('kit_cart_location_override');
        this.assignKitLocationPermission =
            this.actionService.isAllowAction(
                'kits_inventory',
                'assign_kit_location',
                'Show Assign to Location in Dispatch modal'
            ) && this.locations.length > 0;
        this.hasKitCartLocationOverride = this.hospitalInfoService.hospitalSetting('kit_cart_location_override');
        this.allowCartToLocation = this.hospitalInfoService.hospitalSetting('cart_to_location_enabled');

        this.alwaysShowCart = !this.useRadio && this.assignKitCartPermission;
        this.alwaysShowLocation = !this.useRadio && this.assignKitLocationPermission;
        this.showNone = this.assignKitCartPermission || this.assignKitLocationPermission;
        this.showChargeSheetCheckbox = this.actionService.isAllowAction(
            'kits_inventory',
            'print_charge_sheet',
            'Show Print Charge Sheet checkbox in Dispatch modal'
        );
    }

    initializeAllowlists(): Promise<any> {
        return this.loadCartAllowlist().then(() => {
            return this.loadLocationAllowlist();
        });
    }

    loadLocationAllowlist(ignoreKitRelatedCarts = false): Promise<any> {
        const cart_ids = [this.kit.cart_id];
        const kit_ids = [this.kit.id];
        this.locationsLoading = true;
        return this.allowlistService
            .allowlistComputedLocations({
                kit_ids: kit_ids,
                cart_ids: cart_ids,
                ignore_kit_related_carts: ignoreKitRelatedCarts,
            })
            .then(({ allowed_locations }) => {
                this.locations = allowed_locations;
                this.locationsLoading = false;
            });
    }

    loadCartAllowlist(): Promise<any> {
        const kit_ids = [this.kit.id];
        this.cartsLoading = true;
        return this.allowlistService.allowlistComputedCarts({ kit_ids: kit_ids }).then(({ allowed_carts }) => {
            this.carts = allowed_carts;
            this.cartsLoading = false;
        });
    }

    allowlistLoading(): Boolean {
        return this.cartsLoading || this.locationsLoading;
    }
}
