import { Component, OnInit } from '@angular/core';
import { MobileCartService } from '@services/utils/mobile-cart.service';
import { ScanResult } from '@models/core/scan';

@Component({
    selector: 'mobile-cart-landing-page',
    templateUrl: './landing-page.html',
    styleUrls: ['./landing-page.scss'],
})
export class MobileCartLandingPageComponent implements OnInit {
    constructor(private mobileCartService: MobileCartService) {}

    ngOnInit() {
        this.mobileCartService.showScanButton = false;
        this.mobileCartService.showStandardSubheader = true;
        this.mobileCartService.subheaderText = '';
    }

    routeToDesktop() {
        this.mobileCartService.goToDesktop();
    }

    redirectScan(scanResult: ScanResult) {
        this.mobileCartService.goToScanSummary(scanResult);
    }
}
