import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { stringify } from 'query-string';
import { AllowedCartsParams, AllowedLocationsParams } from '@models/core/allowlist_params';

@Injectable()
export class AllowlistResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    allowlistComputedLocations(params: AllowedLocationsParams): Promise<any> {
        const stringifiedParams = stringify(params, { arrayFormat: 'bracket' });
        return this.http.get<any>(`${this.API_ENDPOINT}allowlist/locations?${stringifiedParams}`).toPromise();
    }

    allowlistComputedCarts(params: AllowedCartsParams): Promise<any> {
        const stringifiedParams = stringify(params, { arrayFormat: 'bracket' });
        return this.http.get<any>(`${this.API_ENDPOINT}allowlist/carts?${stringifiedParams}`).toPromise();
    }
}
