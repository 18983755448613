import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class SegmentResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    // converting these from subresource, but not sure if they are corrrect...
    // Nothing is using these yet.
    segmentList(kitMasterId) {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}kit_masters/${kitMasterId}/segments`).toPromise();
    }

    addSegment(kitMasterId, segment) {
        return this.http.post<any>(`${this.API_V2_ENDPOINT}kit_masters/${kitMasterId}/segments`, segment).toPromise();
    }

    updateSegment(segmentId, data) {
        return this.http.put<any>(`${this.API_V2_ENDPOINT}segments/${segmentId}/`, data).toPromise();
    }

    removeSegment(segmentId) {
        return this.http.delete<any>(`${this.API_V2_ENDPOINT}segments/${segmentId}`).toPromise();
    }

    getSingleSegment(segmentId, scanId?) {
        const scanParameter = scanId ? `?scan_id=${scanId}` : '';
        return this.http.get<any>(`${this.API_V2_ENDPOINT}segments/${segmentId}${scanParameter}`).toPromise();
    }

    getAllSegments(shortage?) {
        const shortageParameter = shortage ? '?shortage=true' : '';
        return this.http.get<any>(`${this.API_V2_ENDPOINT}segments/index_all${shortageParameter}`).toPromise();
    }

    bulkUpdateSegments(data): Promise<any> {
        return this.http.put<any>(`${this.API_V2_ENDPOINT}segments/update_bulk`, data).toPromise();
    }
}
