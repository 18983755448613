import { Directive, Input, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[mustmatch]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MustMatchValidateDirective),
            multi: true,
        },
    ],
})
export class MustMatchValidateDirective implements Validator {
    @Input('mustmatch') field: string;
    validate(control: AbstractControl): { [key: string]: any } | null {
        return mustMatchValidator(this.field)(control);
    }
}

export function mustMatchValidator(field: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            let control_string;
            let field_string;

            if (moment.isMoment(control.value)) {
                control_string = control.value.format('YYYY-MM-DD');
            } else {
                control_string = String(control.value);
            }

            if (moment.isMoment(field)) {
                field_string = field.format('YYYY-MM-DD');
            } else {
                field_string = String(field);
            }

            return control_string !== field_string ? { mustmatch: true } : null;
        }
        return null;
    };
}
