import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';
import { PrintBatchVerificationResource } from '@resources/print-batch-verification-resource.service';

// description and detail can contain html
interface ParamData {
    batches: Array<any>;
    okButton: string;
}

@Component({
    selector: 'remote-batch-verification-dialog',
    templateUrl: './remote-batch-verification-dialog.html',
    styleUrls: ['./remote-batch-verification-dialog.scss'],
})
export class RemoteBatchVerificationDialog {
    batches: any;

    constructor(
        private printBatchVerificationResource: PrintBatchVerificationResource,
        public dialogRef: MatDialogRef<RemoteBatchVerificationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.batches = this.data.batches;
    }

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        let promises: Promise<any>[] = [];
        let batchPromise: Promise<any>;

        this.batches.forEach((batch) => {
            if (batch.tag_association_batch_id) {
                batchPromise = this.printBatchVerificationResource.verifyPrintBatch({
                    tag_association_batch_id: batch.tag_association_batch_id,
                });
                promises.push(batchPromise);
            }
        });

        Promise.all(promises)
            .then(() => {
                this.dialogRef.close(true);
            })
            .catch(() => {
                // no real error handling here (the old version did not handle)
                this.dialogRef.close();
            });
    }
}
