import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

// description and detail can contain html
interface ParamData {
    bins: Array<any>;
}

@Component({
    selector: 'choose-bin-dialog',
    templateUrl: './choose-bin-dialog.html',
    styleUrls: ['./choose-bin-dialog.scss'],
})
export class ChooseBinDialog {
    bins: Array<any>;
    selectedBin: number;

    constructor(
        public dialogRef: MatDialogRef<ChooseBinDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.bins = this.data.bins;
        this.selectedBin = this.bins.length - 1 === 1 ? this.bins[0].id : null;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close({ bin_id: this.selectedBin });
    }
}
