import { Component, Input, Output, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActionService } from '@services/utils/action.service';
import { ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'reports-list',
    templateUrl: './reports-list.html',
    styleUrls: ['./report-list.scss'],
})
export class ReportsList {
    // bindings
    @Input() reports: {
        report_categories: ReportCategory[];
    };
    @Input() reportsCategory: ReportCategory;
    @Input() isSystem: boolean;
    @Output() closeModal;
    reportsExpiry: ReportCategory[];
    reportsAttributes: ReportCategory[];
    reportsActivity: ReportCategory[];
    reportsAnalytics: ReportCategory[];
    systemReportsAttributes: ReportCategory[];
    lowBatteryReportEnabled: boolean = false;

    constructor(
        protected actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        // hospital reports
        this.reportsExpiry = this.findReports('item_expiration');
        this.reportsAttributes = this.findReports('item_attribute');
        this.reportsActivity = this.findReports('activity');
        this.reportsAnalytics = this.findReports('analytics');
        // system reports
        this.systemReportsAttributes = this.findReports('system_item_attribute');
        // other
        this.lowBatteryReportEnabled = this.hospitalInfoService.getHospitalSettings().midmark_rtls_enabled;
    }

    // helper functions
    findReports(category: String): ReportCategory[] {
        let reportsToReturn: any[] = [];
        const reportsForCategory = this.reports.report_categories.find((report) => report.name === category);

        if (!reportsForCategory) {
            return [];
        }

        reportsToReturn = reportsToReturn.concat(
            reportsForCategory.reports.map((report) => {
                let route;
                if (reportsForCategory.name === 'system_item_attribute') {
                    route = report.name.replace('system', 'system-report').replace(/_/g, '-');
                } else {
                    route = `report-${report.name}`.replace(/_/g, '-');
                }
                return {
                    name: report.name,
                    display_name: report.display_name,
                    route: route,
                    isNew: report.is_new,
                };
            })
        );

        return _.chain(reportsToReturn)
            .reject((report) => report.name === 'print_batch_detail')
            .sortBy('display_name')
            .value();
    }
}
