import { Component, Input, ViewChild } from '@angular/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

@Component({
    selector: 'report-kits-needing-rework',
    templateUrl: './report-kits-needing-rework.html',
    styleUrls: ['./report-kits-needing-rework.scss'],
})
export class ReportKitsNeedingRework {
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    report: Report;
    isDownloadable: boolean = true;
    filters: any;
    hasData: boolean;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'kit_master_name',
        'kit_physical_label',
        'expired_count',
        'expiring_soon_count',
        'missing_count',
        'extra_count',
    ];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'item_attribute'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'kits_needing_rework');
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(event: any): void {
        this.filters = {};
        if (!!event.timeframe) {
            this.filters.scanned_in_last_interval = event.timeframe;
        }

        if (!!event.kitMaster) {
            this.filters.kit_master_id = event.kitMaster;
        }

        if (!!event.kitsNeedingRework) {
            this.filters.hide_completed = event.kitsNeedingRework;
        }

        const promise = this.reportResource.kitsNeedingReworkReport(this.filters).then((rawReportData) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            kitMaster: event.kitMaster,
            timeframe: event.timeframe,
            kitsNeedingRework: event.kitsNeedingRework,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
