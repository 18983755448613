import { Input, OnInit, Component, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { FormControl, NgForm, NgModel } from '@angular/forms';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { titleize } from '@utils/strings';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { ActionService } from '@services/utils/action.service';
import { BarcodeService } from '@services/core/barcode.service';
import { BinTagPackageResource } from '@resources/bin-tag-package-resource.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { ItemResource } from '@resources/item-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { PretaggedProblemResource } from '@resources/pretagged-problem-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

import { DecommissionPackagesDialog } from '@dialogs/decommission-packages/decommission-packages-dialog';
import { ExpirationDialog } from '@dialogs/expiration/expiration-dialog';
import { VerifyItemDialog } from '@dialogs/verify-item/verify-item-dialog';
import { PretaggedIssueDialog } from '@dialogs/pretagged-issue/pretagged-issue-dialog';

import { Bin } from '@models/core/bin';
import { Package } from '@models/core/package';
import { ApplicationService } from '@services/system/application.service';
import { isAppUnderPostLockoutState } from '@utils/charge-model-util';

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

interface Item {
    epc?: string;
    item_ndc?: string;
    lot_number?: string;
    item_expiration?: string;
    item_formulary_type_string?: string;
    print_encoding_fail?: boolean;
    print_encoding_unconfirmed?: boolean;
    label?: string;
    trusted?: boolean;
    verified?: boolean;
    created_by?: any;
    created_by_role?: any;
    created_by_role_formatted?: string;
    creator?: string;
    item_bin?: any;
    trust?: any;
    item_expiration_fridge?: any;
    confirm_expiration_fridge?: any;
    item_expiration_multidose?: any;
    confirm_expiration_multidose?: any;
    requires_refrigeration?: boolean;
    multidose?: boolean;
    recalled?: boolean;
    id?: number;
    item_manufacturer_expiration?: any;
    tag?: any;
    verification?: any;
    fk_ignored?: any;
    fk_lot_number_denylist_string?: string;
}

interface ItemPackage {
    size?: number;
    model?: {
        uom?: string;
        description_id?: string;
    };
}

@Component({
    selector: 'manage-items',
    templateUrl: './manage-items.html',
    styleUrls: ['./manage-items.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class ManageItems {
    @Input() pretaggedProblems;

    bins: Bin[];
    binsLoaded: boolean = false;
    boundSearchInput: Function;
    currentBinId: number;
    canAddToBin: boolean = false;
    canEditFridgeExp: boolean = false;
    canEditMultidoseExp: boolean = false;
    eligibleItems: Array<Item> = [];
    enteredEPC: {
        second?: string;
        third?: string;
        fourth?: string;
    };
    isCanadianHospital: boolean;
    isLoading: boolean;
    inventorySelectDisabled: boolean;
    item: Item;
    item_package: ItemPackage;
    mediumDateFormat: string;
    prefillBatchVerificationEnabled: boolean;
    registryItem: boolean;
    showItemNotEditable: boolean;
    showNextSteps: number;
    showPreTaggedIssueNextSteps: boolean = false;
    snackBarRef: MatSnackBar;
    tag: string;
    updatingBins: boolean;
    useBothEntryMethods: boolean;
    dateFormat: string;
    isPostLockoutPeriod: boolean;

    /* eslint-disable */
    use: {
        QRScanning: boolean;
        EPCEntering: boolean;
    };
    /* eslint-enable */

    verification_item: {
        ndc?: string;
        lot_num?: string;
        expiration_date?: any;
        lotDisabled?: boolean;
        expireDisabled?: boolean;
    };

    @ViewChild('editItem') editItem: NgForm;
    @ViewChild('fridgeExp') fridgeExp: NgModel;
    @ViewChild('multiExp') multiExp: NgModel;
    @ViewChild('confirmFridgeExp') confirmFridgeExp: NgModel;
    @ViewChild('confirmMultiExp') confirmMultiExp: NgModel;

    constructor(
        private $state: StateService,
        public actionService: ActionService,
        public applicationService: ApplicationService,
        private barcodeScanService: BarcodeScanService,
        private barcodeService: BarcodeService,
        private binTagPackageResource: BinTagPackageResource,
        private itemResource: ItemResource,
        private formularyItemResource: FormularyItemResource,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService,
        private loadingSpinnerService: LoadingSpinnerService,
        private parseFormErrorsService: ParseFormErrorsService,
        private pretaggedProblemResource: PretaggedProblemResource,
        private productModuleService: ProductModuleService,
        private translationService: TranslationService,
        private dialog: MatDialog
    ) {
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
    }

    ngOnInit() {
        this.isLoading = true;
        this.showNextSteps = 1;
        this.tag = '';
        this.item = {};
        this.item_package = {};
        this.dateFormat = this.translationService.instant('formats.medium_date');
        this.canAddToBin = this.actionService.isAllowAction('kits_inventory', 'scan_kit', 'Update an item');
        this.canEditFridgeExp = this.actionService.isAllowAction(
            'kits_inventory',
            'update_fridge_expirations',
            'Show fridge edit'
        );
        this.canEditMultidoseExp = this.actionService.isAllowAction(
            'kits_inventory',
            'update_multidose_expirations',
            'Show multidose edit'
        );
        this.prefillBatchVerificationEnabled =
            this.localStorageService.get('hospitalSettings').prefill_batch_verification_enabled;
        this.useBothEntryMethods = !this.hospitalInfoService.hospitalSetting('qr_code_on_tag');

        /* eslint-disable */
        this.use = {
            QRScanning: false,
            EPCEntering: false,
        };
        /* eslint-enable */

        this.mediumDateFormat = this.translationService.instant('formats.medium_date');

        this.isCanadianHospital = this.hospitalInfoService.canadianHospital();

        this.barcodeScanService.registerListener((scan) => {
            const scanValues = this.barcodeScanService.processBarcode(scan);
            if (_.get(scanValues, 'epc')) {
                this.tag = scanValues.epc;
                this.textboxEntry(this.tag);
                this.$state.go('inventory-manage-items', { epc: _.get(scanValues, 'epc') }, { reload: true });
            }
        }, 'manage-items');

        this.boundSearchInput = this.searchInput.bind(this);

        this.resetScan();

        if (this.$state.params.epc) {
            const epc = this.$state.params.epc;
            this.tag = this.formatEpc(epc);
            this.lookupBarcode({ epc });
        } else {
            this.isLoading = false;
        }
    }

    formatEpc(epc: string): string {
        const chunks = epc.replace(/-/g, '').match(/.{1,4}/g);
        if (chunks.length > 3) {
            chunks[2] = chunks[2] + chunks[3];
            chunks.splice(3, 1);
        }
        return chunks.join('-');
    }

    textboxEntry(tag: string): void {
        if (tag) {
            this.tag = this.formatEpc(tag);
        }
    }

    searchInput(): void {
        const scanValues = this.barcodeScanService.processBarcode(this.tag.replace(/-/g, ''));
        if (scanValues) {
            this.$state.go('inventory-manage-items', { epc: _.get(scanValues, 'epc') }, { reload: true });
        }
    }

    lookupEnabled(): boolean {
        const regexSGTIN198 = /^36[A-Z0-9]{50}$/;
        const regexSGTINPlus = /^F7[A-Z0-9]*/;
        if (regexSGTIN198.test(this.tag) || regexSGTINPlus.test(this.tag)) {
            return true;
        } else {
            return this.tag && this.tag.replace(/-/g, '').length === 24;
        }
    }

    useBins(): boolean {
        return (
            this.hospitalInfoService.allowShelvedInventory() &&
            this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Scan Inventory Resolver to bin scan')
        );
    }

    hasBins(): boolean {
        return !!this.bins && this.bins.length > 0;
    }

    reportPretaggedIssue(): void {
        const pretaggedIssueDialog = this.dialog.open(PretaggedIssueDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                problems: this.pretaggedProblems,
            },
        });

        let result = pretaggedIssueDialog.afterClosed().subscribe((result) => {
            if (!!result) {
                this.showPreTaggedIssueNextSteps = true;
                let params: any = {
                    problem_names: [],
                    epcs: [this.item.epc],
                };

                if (result.notes) {
                    params.notes = result.notes;
                }
                for (let problem in result.checkboxes) {
                    if (
                        result.checkboxes.hasOwnProperty(problem) &&
                        result.checkboxes[problem] &&
                        problem !== 'notes'
                    ) {
                        params.problem_names.push(problem);
                    }
                }
                if (params.problem_names.length === 0) {
                    delete params.problem_names;
                }
                this.pretaggedProblemResource.quarantineTags(params).catch(() => {});
            }
        });
    }

    // TODO - where does this go now?
    createTags(): void {
        let data = {
            type: 'item',
            ndc: this.item.item_ndc,
            lot: this.item.lot_number,
            expiration: this.item.item_expiration,
        };
        this.$state.go('tagging', { type: 'item', barcodeObject: data });
    }

    // TODO - where does this go now?
    showPretaggedButton(): boolean {
        return (
            this.showNextSteps === 1 &&
            this.item.item_formulary_type_string === 'registry' &&
            this.actionService.isAllowAction('kits_tagging', 'quarantine_tag', 'Show pre-tagged button')
        );
    }

    // TODO belongs with pretagged stuff?
    hideNextSteps(): void {
        this.$state.go('home');
    }

    lookupBarcode(scanValues: any): void {
        if (!!scanValues.epc) {
            this.item = {};
            const barcodePromise = this.barcodeService
                .barcodeObject(scanValues.epc)
                .then((data) => {
                    if (data.object['class'] === 'Package') {
                        return this.processScanValues(scanValues);
                    } else if (data.object['class'] === 'Kit') {
                        this.$state.go('kit', { kitId: data.object.id });
                    } else if (data.object['class'] === 'Bin') {
                        this.$state.go('bin-scan', { binId: data.object.id, scanId: 'latest' });
                    } else if (data.object['class'] === 'Tag') {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'manage_items.not_associated',
                        });
                    } else {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'manage_items.cant_find_epc',
                            params: { epc: this.formatEpc(scanValues.epc) },
                        });
                    }
                })
                .catch(() => {
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'manage_items.cant_find_epc',
                        params: { epc: this.formatEpc(scanValues.epc) },
                    });
                });

            this.loadingSpinnerService.spinnerifyPromise(barcodePromise).finally(() => {
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }

    processScanValues(scanValues: any): Promise<any> {
        const managePromise = this.itemResource.itemManageView(scanValues.epc).then((data) => {
            Object.assign(this.item, data.manage_items_view);

            if (this.item.print_encoding_fail === true) {
                this.$state.go('inventory-manage-items-print-error');
            }

            if (this.item.print_encoding_unconfirmed === true) {
                this.$state.go('inventory-manage-items-print-unconfirmed');
            }

            this.item.label = scanValues.label;
            this.item.epc = scanValues.epc;
            this.item.trusted = this.item.trust && this.item.trust.trusted_at;
            this.registryItem = this.item.item_formulary_type_string === 'registry';

            this.showItemNotEditable =
                this.item.epc &&
                ((!this.item.requires_refrigeration && !this.item.multidose) ||
                    !this.actionService.isAllowAction('kits_tagging', 'create_item_tag', 'Show item not editable'));

            this.item.verified = this.item.verification && this.item.verification.verified_at;

            // reject bluesight roles and make a string
            this.item.created_by_role_formatted = this.item.created_by_role
                ?.filter((role) => !role.includes('bluesight'))
                .join(', ');

            let creator: any = this.item.created_by;
            this.item.creator = [creator.first_name, creator.last_name].filter((name) => name).join(' ');

            if (
                this.hospitalInfoService.allowShelvedInventory() &&
                this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Scan Inventory Resolver to bin scan')
            ) {
                this.formularyItemResource.bins(this.item.item_ndc).then((response: any) => {
                    let bins: Array<any> = response.bins;
                    this.bins = bins;
                    this.binsLoaded = true;
                });
            }
            if (this.item.trusted) {
                this.item.trust.trust_reason = titleize(this.item.trust.trust_reason.split('_').join(' '));
            }

            if (this.item.verified) {
                this.item.verification.verification_method = titleize(
                    this.item.verification.verification_method.split('_').join(' ')
                );
            }

            if (this.item.item_expiration_fridge) {
                this.item.confirm_expiration_fridge = moment(this.item.item_expiration_fridge);
            } else {
                this.item.item_expiration_fridge = '';
                this.item.confirm_expiration_fridge = '';
            }

            if (this.item.item_expiration_multidose) {
                this.item.confirm_expiration_multidose = moment(this.item.item_expiration_multidose);
            } else {
                this.item.item_expiration_multidose = '';
                this.item.confirm_expiration_multidose = '';
            }

            if (!!this.item.item_bin) {
                this.currentBinId = this.item.item_bin.id;
            } else {
                this.currentBinId = 0;
            }

            this.verification_item = {
                expiration_date: '',
            };

            this.updateVerificationIfPrefillBatch();

            const lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
            this.isPostLockoutPeriod =
                isAppUnderPostLockoutState(
                    lockoutDate,
                    this.hospitalInfoService.getHospitalSettings().charge_model_type.name
                ) && this.item.tag.subscription_required;
        });
        return this.loadingSpinnerService.spinnerifyPromise(managePromise);
    }

    buildEPC(): void {
        const epc = `8001${this.enteredEPC.second.toUpperCase()}00000000${this.enteredEPC.third.toUpperCase()}${this.enteredEPC.fourth.toUpperCase()}`;
        this.$state.go('inventory-manage-items', { epc: epc }, { reload: true });
    }

    useQRScanning(): void {
        this.use.QRScanning = true;
        this.use.EPCEntering = false;
        $('#qr-scan-entry .scanner-input').focus();
    }

    useEPCEntering(): void {
        this.use.QRScanning = false;
        this.use.EPCEntering = true;
        this.enteredEPC = {};
        $('#epc1').focus();
    }

    jumpTo(value: string, target: string): void {
        if (this.isEPCReadyToSubmit()) {
            this.buildEPC();
        } else if (value && value.length >= 4) {
            $(`#${target}`).focus();
        }
    }

    finishEntry(): void {
        if (this.isEPCReadyToSubmit()) {
            this.buildEPC();
        }
    }

    isEPCReadyToSubmit(): boolean {
        return (
            this.enteredEPC &&
            this.enteredEPC.second &&
            this.enteredEPC.second.length === 4 &&
            this.enteredEPC.third &&
            this.enteredEPC.third.length === 4 &&
            this.enteredEPC.fourth &&
            this.enteredEPC.fourth.length === 4
        );
    }

    entryMethodSelected(): boolean {
        return this.use.QRScanning || this.use.EPCEntering || !this.useBothEntryMethods;
    }

    usingQRScanning(): boolean {
        return !this.useBothEntryMethods || this.use.QRScanning;
    }

    usingEPCEntering(): boolean {
        return this.useBothEntryMethods && this.use.EPCEntering;
    }

    showFridgeEdit(): boolean {
        return this.item.requires_refrigeration;
    }

    showMultidoseEdit(): boolean {
        return this.item.multidose;
    }

    showRefrigExpDialog() {
        const exp = 'item_expiration_fridge';
        const title = this.translationService.instant('manage_items.fridge_edit.header');
        const label = this.translationService.instant('manage_items.fridge_edit.labels.fridge_expiration');
        const verifyLabel = this.translationService.instant('manage_items.fridge_edit.labels.verify_fridge_expiration');
        const today = new Date().toISOString().slice(0, 10);
        const minDate = new Date(today);
        const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

        this.showExpDialog(exp, title, label, verifyLabel, minDate, maxDate);
    }

    showMultidoseExpDialog() {
        const exp = 'item_expiration_multidose';
        const title = this.translationService.instant('manage_items.multidose_edit.header');
        const label = this.translationService.instant('manage_items.multidose_edit.labels.multidose_expiration');
        const verifyLabel = this.translationService.instant(
            'manage_items.multidose_edit.labels.verify_multidose_expiration'
        );
        const today = new Date().toISOString().slice(0, 10);
        const minDate = new Date(today);
        const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

        this.showExpDialog(exp, title, label, verifyLabel, minDate, maxDate);
    }

    showExpDialog(exp, title, label, verifyLabel, minDate, maxDate): void {
        const modalDialog = this.dialog.open(ExpirationDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                expDate: new Date(this.item[exp]),
                dialogTitle: title,
                dateLabel: label,
                verifyLabel: verifyLabel,
                minDate: minDate,
                maxDate: maxDate,
            },
        });

        modalDialog.afterClosed().subscribe((exp_date) => {
            if (exp_date !== undefined) {
                this.item[exp] = exp_date;
                this.sendItemUpdate();

                if (this.item[exp] === null) {
                    // moment doesn't like null dates
                    this.item[exp] = '';
                }
            }
        });
    }

    sendItemUpdate(): void {
        if (!this.item.epc) {
            return;
        }

        const data: any = {
            id: this.item.epc,
        };

        if (this.item.requires_refrigeration) {
            if (this.item.item_expiration_fridge) {
                data.expiration_fridge = moment(this.item.item_expiration_fridge).format(this.mediumDateFormat);
            } else {
                data.expiration_fridge = '';
            }
        }

        if (this.item.multidose) {
            if (this.item.item_expiration_multidose) {
                data.expiration_multidose = moment(this.item.item_expiration_multidose).format(this.mediumDateFormat);
                data.expiration_multidose_opened_on = moment().format(this.dateFormat);
            } else {
                data.expiration_multidose = '';
            }
        }

        this.itemResource.updateItem(data).then(() => {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'manage_items.expiration_date_set',
            });
        });
    }

    // things to do in verification step

    showVerificationButton(): boolean {
        return (
            this.item.epc &&
            this.verification_item &&
            this.trustedItemsEnabled() &&
            this.actionService.isAllowAction('kits_tagging', 'verify_single_item', 'Show Verify button')
        );
    }

    trustedItemsEnabled(): boolean {
        return this.hospitalInfoService.hospitalSetting('trusted_items_enabled');
    }

    showSoloCancel(): boolean {
        return (
            this.showVerificationButton() &&
            !this.actionService.isAllowAction('kits_tagging', 'verify_single_item', 'Show Scan Another button')
        );
    }

    resetScan(): void {
        this.verification_item = null;

        this.updateVerificationIfPrefillBatch();

        this.item = {};
        this.tag = '';
        if (this.useBothEntryMethods) {
            this.useEPCEntering();
        } else {
            this.useQRScanning();
        }
    }

    verify(): void {
        const modalDialog = this.dialog.open(VerifyItemDialog, {
            width: '1000px',
            height: 'max-content',
            data: {
                isCanadian: this.isCanadianHospital,
                item: this.item,
                item_package: this.item_package,
                prefillBatchVerificationEnabled: this.prefillBatchVerificationEnabled,
                verification_item: this.verification_item,
            },
        });

        modalDialog.afterClosed().subscribe((results) => {
            if (results?.verification_item) {
                this.submitVerification(results.verification_item, results.item_package);
            }
        });
    }

    submitVerification(verification_item, item_package): void {
        if (verification_item.lotDisabled) {
            verification_item.lot_num = null;
        }

        if (verification_item.expireDisabled) {
            verification_item.expiration_date = null;
        } else {
            verification_item.expiration_date = moment(verification_item.expiration_date).format('YYYY-MM-DD');
        }

        const data = _.pick(verification_item, 'lot_num', 'expiration_date', 'ndc');

        if (this.isCanadianHospital) {
            _.defaults(data, {
                package_size: item_package.size,
                package_size_uom: item_package.model.uom,
                package_description_id: item_package.model.description_id,
            });
        }

        this.itemResource
            .itemVerification(this.item.epc, data)
            .then(() => {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'manage_items.verification_success',
                });

                // reload so that we get the verification info from server
                let epc = this.$state.params.epc;
                this.lookupBarcode({ epc });
            })
            .catch((response) => {
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, `${response.message}`);
            });
    }

    allowDecommission(): boolean {
        return this.actionService.isAllowAction('kits_inventory', 'decommission_tag', 'Show Decommission option');
    }

    decommission(): void {
        let reason;
        this.itemResource
            .itemDataV2(this.item.id)
            .then((data) => {
                return data.windowed_package;
            })
            .then((pkg) => {
                const modalDialog = this.dialog.open(DecommissionPackagesDialog, {
                    width: '1000px',
                    height: 'max-content',
                    data: { packages: [pkg], reason: undefined },
                });

                modalDialog.afterClosed().subscribe((reason) => {
                    if (!reason) {
                        return;
                    } else {
                        const myReason = reason;
                        let msgKey;
                        if (reason.name === 'used_outside') {
                            msgKey = 'batch_verify.decommission_success_msg_one';
                        } else {
                            msgKey = 'batch_verify.decommission_success_msg_two';
                        }
                        let msg = this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: msgKey,
                            params: { reason: myReason.display_name },
                        });
                        msg.afterOpened().subscribe(() => {
                            this.$state.go('home');
                        });
                    }
                });
            });
    }

    addToBin(e): void {
        let binPromise: Promise<any>;
        const binId = e.value;
        this.updatingBins = true;

        if (binId === 0 && this.item.item_bin?.id) {
            binPromise = this.removeFromBin(this.item.item_bin.id);
        } else {
            binPromise = Promise.resolve();
            if (!!binId) {
                const newBin = this.bins.find((bin) => bin.id === binId);
                binPromise = binPromise
                    .then(() => {
                        return this.binTagPackageResource.addBinTagPackage(binId, [this.item.epc]);
                    })
                    .then(() => {
                        this.item.item_bin = newBin;
                        const translation = this.translationService.instant('add_to_bin.items_were_added_to_bin', {
                            bin: newBin.name,
                        });
                        this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
                    })
                    .catch(() => {
                        const translation = this.translationService.instant('add_to_bin.items_not_added_to_bin', {
                            bin: newBin.name,
                        });
                        this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, translation);
                    });
            }
        }

        binPromise.finally(() => {
            this.currentBinId = binId;
            this.updatingBins = false;
        });
    }

    removeFromBin(binId: any): Promise<any> {
        const oldBin = this.bins.find((bin) => bin.id === binId);
        return this.binTagPackageResource
            .removeBinTagPackage(binId, this.item.tag.id)
            .then(() => {
                delete this.item.item_bin;
                this.inventorySelectDisabled = true;
                const translation = this.translationService.instant('remove_from_bin.items_were_removed_from_bin', {
                    bin: oldBin.name,
                });
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
            })
            .catch(() => {
                const translation = this.translationService.instant('remove_from_bin.items_not_removed_from_bin', {
                    bin: oldBin.name,
                });
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, translation);
            });
    }

    updateVerificationIfPrefillBatch(): void {
        if (this.prefillBatchVerificationEnabled) {
            this.verification_item = {};
            this.verification_item.lot_num = this.item.lot_number;
            // prefill with the manufacturer's expiration (not the computed earliest expiration)
            // since that's what we use for verification:
            this.verification_item.expiration_date = this.item.item_manufacturer_expiration;
            this.verification_item.ndc = this.item.item_ndc;
            if (this.verification_item.expiration_date === null) {
                this.verification_item.expireDisabled = true;
            }
            if (this.verification_item.lot_num === null) {
                this.verification_item.lotDisabled = true;
            }
        }
    }
}
