import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';

@Injectable({ providedIn: 'root' })
export class RecallsResolver {
    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource
    ) {}

    resolve(): Promise<any> {
        let recallsPromise;
        if (this.actionService.isAllowAction('administration', 'view_recalls', 'Load recalls network list data')) {
            const get_counts = this.$state.transition.to().name === 'admin.item-recalls';

            recallsPromise = this.recallResource.recallsList(get_counts).then((data) => {
                return data.recalls;
            });
        } else {
            recallsPromise = Promise.resolve([]);
        }
        return this.loadingSpinnerService.spinnerifyPromise(recallsPromise);
    }
}
