import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class LocationResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    locationData(locationId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}locations/${locationId}`).toPromise();
    }

    locationList(includeAeroscout?: any): Promise<any> {
        const data = {
            include_aeroscout: includeAeroscout,
        };

        return this.http.get<any>(`${this.API_ENDPOINT}locations`, { params: data }).toPromise();
    }

    addLocation(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}locations`, data).toPromise();
    }

    updateLocation(data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}locations/${data.id}`, data).toPromise();
    }

    removeLocation(locationId: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}locations/${locationId}`).toPromise();
    }
}
