import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
    selector: '[max]',
    providers: [{ provide: NG_VALIDATORS, useExisting: KCMaxValueValidateDirective, multi: true }],
})
export class KCMaxValueValidateDirective implements Validator {
    @Input() max: number;

    validate(control: AbstractControl): { [key: string]: any } {
        // Use the Angular built-in 'max' Validator; exposing as attribute directive.
        return Validators.max(this.max)(control);
    }
}
