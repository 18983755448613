import { Component } from '@angular/core';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { RecallResource } from '@resources/recall-resource.service';
import { getNestedValue } from '@utils/objects';
import { ActionService } from '@services/utils/action.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { StateService, Transition } from '@uirouter/core';
import { TranslationService } from '@services/utils/translation.service';
import * as moment from 'moment';

interface ParamData {
    total_expired: number;
    percent_of_total_consumed_removed: number;
    vials: number;
    syringes: number;
    supplies: number;
    other: number;
    vial_percent: number;
    syringe_percent: number;
    supply_percent: number;
    other_percent: number;
    price_of_total_expired: number;
    vial_price: number;
    syringe_price: number;
    supply_price: number;
    other_price: number;
    items_without_price: number;
}

interface PriceTypeLabels {
    name: string;
    label: string;
}

@Component({
    selector: 'pharmacy-management',
    templateUrl: './pharmacy-management.html',
    styleUrls: ['./pharmacy-management.scss'],
})
export class PharmacyManagement {
    loading: boolean = true;
    timeFrame: string = 'month';
    expiredItemsData: ParamData;
    expiredItemsMonthData: ParamData;
    expiredItemsQuarterData: ParamData;
    expiredItemsYearData: ParamData;
    ignoredRecalls: number;
    sharedRecalls: number;
    addedRecalls: number;
    removedRecalls: number;
    fdaReason1: number = 0;
    fdaReason2: number = 0;
    fdaReason3: number = 0;
    manufacturerReason: number = 0;
    otherReason: number = 0;
    itemRecallsPermission: boolean;
    priceType: string;
    priceTypeLabel: string;
    priceTypeLabels: PriceTypeLabels[] = [];
    priceTooltip: string;
    monthLoaded: boolean = false;
    quarterLoaded: boolean = false;
    yearLoaded: boolean = false;

    constructor(
        private dashboardService: DashboardResource,
        private hospitalInfoService: HospitalInfoService,
        private recallResource: RecallResource,
        private actionService: ActionService,
        private translationService: TranslationService,
        private $state: StateService,
        private $stateParams: Transition
    ) {}

    ngOnInit() {
        // default priceType
        this.priceType = 'wac';
        this.getExpiredItemsData();

        this.priceTypeLabel = this.translationService.instant('manage_formulary.form.wac_pricing');
        this.priceTypeLabels = [];
        this.priceTypeLabels.push({ name: 'wac', label: this.priceTypeLabel });
        this.priceTypeLabels.push({ name: 'gpo', label: this.translationService.instant('manage_formulary.form.gpo') });
        this.priceTypeLabels.push({
            name: '340B',
            label: this.translationService.instant('manage_formulary.form.cost_340B'),
        });

        this.itemRecallsPermission = this.actionService.isAllowAction(
            'administration',
            'view_recalls',
            'Load recalls network list data'
        );
        this.getRecalls();
        this.getSharedRecalls();
    }

    setData(timeFrame, timeFrameloaded) {
        this.timeFrame = timeFrame;
        if (timeFrameloaded) {
            if (timeFrame === 'month') {
                this.expiredItemsData = this.expiredItemsMonthData;
            } else if (timeFrame === 'quarter') {
                this.expiredItemsData = this.expiredItemsQuarterData;
            } else {
                this.expiredItemsData = this.expiredItemsYearData;
            }
            this.setTooltip();
        }
    }

    showLoading() {
        switch (this.timeFrame) {
            case 'month':
                this.loading = !this.monthLoaded;
                return !this.monthLoaded;
                break;
            case 'quarter':
                this.loading = !this.quarterLoaded;
                return !this.quarterLoaded;
                break;
            case 'year':
                this.loading = !this.yearLoaded;
                return !this.yearLoaded;
                break;
            default:
                this.loading = false;
                return false;
        }
    }

    getExpiredItemsData() {
        this.monthLoaded = false;
        this.quarterLoaded = false;
        this.yearLoaded = false;

        const priceType = this.priceType.toUpperCase();

        this.dashboardService.getExpiredItems('month', priceType).then((data) => {
            this.monthLoaded = true;
            this.expiredItemsMonthData = data;
            this.expiredItemsData = this.expiredItemsMonthData;
            if (this.timeFrame === 'month') {
                this.setData(this.timeFrame, true);
            }
        });

        this.dashboardService.getExpiredItems('quarter', priceType).then((data) => {
            this.quarterLoaded = true;
            this.expiredItemsQuarterData = data;
            if (this.timeFrame === 'quarter') {
                this.setData(this.timeFrame, true);
            }
        });

        this.dashboardService.getExpiredItems('year', priceType).then((data) => {
            this.yearLoaded = true;
            this.expiredItemsYearData = data;
            if (this.timeFrame === 'year') {
                this.setData(this.timeFrame, true);
            }
        });
    }

    getRecalls() {
        this.recallResource.recallsList(true).then((data) => {
            let recalls = data.recalls;

            this.ignoredRecalls = recalls.filter((recall) => !!recall.ignored_at).length;
            this.addedRecalls = recalls.filter((recall) => !recall.ignored_at && !recall.removed_at).length;
            this.removedRecalls = recalls.filter((recall) => !recall.ignored_at && recall.removed_at).length;

            recalls.forEach((recall) => {
                let reason = (recall.reason_display_name = getNestedValue(recall.recall_reason, 'display_name'));
                switch (reason) {
                    case 'Manufacturer recall':
                        this.manufacturerReason += 1;
                        break;
                    case 'FDA Issued Recall Class I':
                        this.fdaReason1 += 1;
                        break;
                    case 'FDA Issued Recall Class II':
                        this.fdaReason2 += 1;
                        break;
                    case 'FDA Issued Recall Class III':
                        this.fdaReason3 += 1;
                        break;
                    case 'Other recall reason':
                        this.otherReason += 1;
                        break;
                }
            });
        });
    }

    getSharedRecalls() {
        this.recallResource.recallsNetworkList().then((data) => {
            this.sharedRecalls = data.shared_recalls.length;
        });
    }

    goToItemRecalls(tab) {
        if (this.itemRecallsPermission) {
            this.$state.go('admin-deeplink', { targetState: 'item-recalls', targetParams: { tab: tab } });
        }
    }

    goToItemConsumedRemovedReport() {
        let startDate;
        let endDate;
        switch (this.timeFrame) {
            case 'month':
                startDate = moment().subtract('1', 'months').startOf('month').format('YYYY-MM-DD');
                endDate = moment().subtract('1', 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case 'quarter':
                moment().startOf('quarter').format('YYYY-MM-DD');
                startDate = moment().subtract('1', 'quarter').startOf('quarter').format('YYYY-MM-DD');
                endDate = moment().subtract('1', 'quarter').endOf('quarter').format('YYYY-MM-DD');
                this.fdaReason1 += 1;
                break;
            case 'year':
                startDate = moment().subtract('1', 'year').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
        }
        this.$state.go('report-inferred-items-consumed-removed', {
            hideSpinner: true,
            prefilter: {
                itemTypeGroup: {
                    genericNdcItemType: 'ndc_pkg_str',
                    kitDrugGroup: 'drug',
                },
                date: {
                    start_date: startDate,
                    end_date: endDate,
                },
                reasons: ['expired', 'expiring_soon'],
            },
        });
    }

    setPriceType(priceType) {
        this.loading = true;
        this.priceType = priceType;
        let selectedPT = this.priceTypeLabels.find((priceLabel) => priceLabel.name === priceType);
        this.priceTypeLabel = selectedPT.label;
        this.getExpiredItemsData();
    }

    setTooltip() {
        if (this.expiredItemsData.items_without_price > 0) {
            this.priceTooltip = this.translationService.instant('tooltips.pharmacy_operations.price_warning');
        } else {
            this.priceTooltip = this.translationService.instant('tooltips.pharmacy_operations.price_catalog_warning');
        }
    }
}
