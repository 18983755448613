import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { ConfigurationProvider } from '../config/configuration';
import { FileUploadService } from '@services/utils/file-upload.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { MotorolaResource } from '@resources/motorola-resource.service';

@Injectable()
export class PrintResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    isIE: boolean = /*@cc_on!@*/ false || !!document['documentMode'];

    constructor(
        private configuration: ConfigurationProvider,
        private fileUploadService: FileUploadService,
        private hardwareResource: HardwareResource,
        private http: HttpClient,
        private motorolaResource: MotorolaResource
    ) {
        window['processForwardToPrinterResource'] = this.processForwardToPrinterResponse.bind(this);
    }

    // ***********
    // resource endpoint methods
    // ***********

    itemZpl(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}items/tags`, data).toPromise();
    }

    kitZpl(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kits/tags`, data).toPromise();
    }

    tagReceipt(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}tag_receipts`, data).toPromise();
    }

    // ***********
    // printing methods
    // ***********

    printTag(printer, zpl, quantity, epcs) {
        epcs = typeof epcs === 'string' ? [epcs] : epcs;
        return this.sendPrintJob(printer, zpl, quantity, epcs);
    }

    printSampleTag(printer, quantity) {
        return new Promise((resolve, reject) => {
            this.hardwareResource
                .sampleZpl(printer.id)
                .then((sampleData) => {
                    const zpl = encodeURIComponent(sampleData.zpl);
                    // resolve right away because the tunnel doesn't return properly off of the sample zpl
                    resolve();
                    this.sendPrintJob(printer, zpl, quantity);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    resetPrinter(printer) {
        return new Promise((resolve, reject) => {
            this.hardwareResource
                .resetZpl(printer.id)
                .then((resetData) => {
                    const zpl = encodeURIComponent(resetData.zpl);
                    // resolve right away because the tunnel doesn't return properly off of resets
                    resolve();

                    this.sendPrintJob(printer, zpl, 3);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    restorePrinter(printer) {
        return new Promise((resolve, reject) => {
            this.hardwareResource
                .restoreZpl(printer.id)
                .then((resetData) => {
                    const zpl = encodeURIComponent(resetData.zpl);
                    // resolve right away because the tunnel doesn't return properly off of resets
                    resolve();

                    this.sendPrintJob(printer, zpl, 3);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    // ***********
    // printing helpers
    // ***********

    urlForPrinting(printer, timeout, quantity) {
        let url;

        timeout = Math.round(timeout / 1000);

        if (printer.is_tunnel) {
            url = `${printer.tunnel_url}?host=${printer.hostname}&port=${printer.ip4_port}&allowed_origin=*&timeout=${timeout}&quantity=${quantity}`;
        } else {
            url = `${printer.value}/zpl`;
        }

        return url;
    }

    sendPrintJob(printer, zpl, quantity, epcs?) {
        return new Promise((resolve, reject) => {
            this.wrappedPrintJob(printer, zpl, quantity, epcs)
                .then((data) => {
                    return resolve(data);
                })
                .catch((data) => {
                    return reject(data);
                });
        });
    }

    wrappedPrintJob(printer, zpl, quantity, epcs): Promise<any> {
        const timePerTag = 2180 * 4,
            buffer = 4000,
            printTimeout = (quantity || 1) * timePerTag + buffer,
            requestTimeout = printTimeout + buffer * 2,
            url = this.urlForPrinting(printer, printTimeout, quantity),
            isTunnel = printer.is_tunnel,
            printZpl = isTunnel ? this.prepareTunnelData(zpl) : zpl,
            printData = `data=${printZpl}&dev=R&oname=UNKNOWN&otype=ZPL&print=Print+Label&pw=`,
            printerId = printer.id;

        if (this.isIE && window.XDomainRequest) {
            return new Promise((resolve, reject) => {
                return this.iePrint(url, printData, requestTimeout, epcs, printerId).catch((e) => {
                    if (isTunnel) {
                        reject(e);
                    } else {
                        resolve(); // Always resolve if it isn't a tunnel
                    }
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url,
                    data: printData,
                    dataType: 'html',
                    timeout: requestTimeout,
                })
                    .done((data) => {
                        if (isTunnel) {
                            this.finishTunnelPrint(data, epcs, printerId)
                                .then(() => {
                                    resolve();
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                        } else {
                            resolve(); // Always resolve if it isn't a tunnel
                        }
                    })
                    .fail((_jqXHR, textStatus) => {
                        if (isTunnel) {
                            const isHostMotorola = printer.tunnel_url.indexOf('user_apps') > 0;
                            if (textStatus === 'error' && isHostMotorola) {
                                // no way to identify errors caused by missing .psp but no harm in extra uploads
                                this.uploadPrintFwdPsp(printer);
                            }
                            let reason;
                            if (textStatus === 'timeout' || textStatus === 'error') {
                                reason = 'timeout';
                            } else {
                                reason = 'non-tunnel-error';
                            }
                            reject({ reason: reason });
                        } else {
                            resolve(); // Always resolve if it isn't a tunnel
                        }
                    });
            });
        }
    }

    iePrint(url, printData, timeout, epcs, printerId): Promise<any> {
        // Use Microsoft XDR
        const xdr = new XDomainRequest();

        xdr.open('post', url);

        xdr.onload = () => {
            return new Promise((resolve, reject) => {
                this.finishTunnelPrint(xdr.responseText, epcs, printerId)
                    .then(() => {
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        };

        xdr.onerror = () => {
            return Promise.reject({
                reason: 'error',
                message: xdr.responseText,
            });
        };
        xdr.ontimeout = () => {
            return Promise.reject({
                reason: 'timeout',
            });
        };
        xdr.onprogress = () => {};

        xdr.timeout = timeout;

        return xdr.send(printData);
    }

    prepareTunnelData(zpl) {
        zpl = zpl.replace(/(\r\n|\n|\r)/gm, '');
        zpl = decodeURIComponent(zpl);

        return zpl;
    }

    finishTunnelPrint(data, epcs, printerId) {
        return new Promise((resolve, reject) => {
            // use eval here in a safe way but still error in other situations
            /* eslint-disable */
            const safeEval = eval;
            /* eslint-enable */
            try {
                let response;

                if (_.includes(data, '"data":')) {
                    data = JSON.parse(data.trim().replace(/\\n/gm, '^'));
                    response = { success: data.success, message: data.message, data: data.data };
                } else {
                    data = data.trim().replace(/\s+/gm, '^');
                    response = safeEval(data);
                }

                if (response.success) {
                    this.checkPrintEncoding(response, epcs, printerId)
                        .then(() => {
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                } else {
                    if (response.message === 'Timeout') {
                        reject({
                            reason: 'timeout',
                        });
                    } else {
                        reject({
                            reason: 'unknown error',
                        });
                    }
                }
            } catch (e) {
                resolve();
            }
        });
    }

    checkPrintEncoding(printResponse, epcs, printerId) {
        return new Promise((resolve, reject) => {
            if (printResponse.data && printResponse.data.length && epcs.length) {
                let rawResponse = printResponse.data.trim().replace(/\s/gm, '');
                rawResponse = encodeURIComponent(rawResponse).replace('%03', '').replace('%02', '');
                rawResponse = decodeURIComponent(rawResponse);

                const receiptData = {
                    epcs,
                    hardware_id: printerId,
                    raw_response: rawResponse,
                };

                this.tagReceipt(receiptData)
                    .then((receiptResponse) => {
                        if (receiptResponse.encoding_confirmed) {
                            resolve();
                        } else {
                            reject({
                                reason: 'encoding',
                            });
                        }
                    })
                    .catch(() => {
                        resolve(); // resolve on 500's since it is less invasive
                    });
            } else {
                resolve();
            }
        });
    }

    ieSendZpl(url, data?) {
        const xdr = new XDomainRequest();
        xdr.open('post', url);
        xdr.onload = () => {
            return Promise.resolve(xdr.responseText);
        };
        xdr.onerror = () => {
            return Promise.reject(xdr.responseText);
        };
        xdr.ontimeout = () => {
            return Promise.reject(xdr.responseText);
        };
        xdr.onprogress = () => {};
        xdr.timeout = 10000;
        return xdr.send(data);
    }

    // used externally - needs to be a promise
    sendZpl(printer, zpl): Promise<any> {
        const url = this.urlForPrinting(printer, 2000, 1);
        const data = `data=${zpl}&dev=R&oname=UNKNOWN&otype=ZPL&print=Print+Label&pw=`;

        if (this.isIE && window.XDomainRequest) {
            return this.ieSendZpl(url, data);
        } else {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url,
                    data,
                    contentType: 'application/x-www-form-urlencoded',
                    dataType: 'html',
                    crossDomain: true,
                    success(response) {
                        resolve(response);
                    },
                    error(response) {
                        reject(response);
                    },
                });
            });
        }
    }

    // used externally, needs to be a promise
    testTunnel(scanner): Promise<any> {
        const tunnelEndpoint = 'user_apps/fwd2prnt_v2.psp';
        const url = `${scanner.value}/${tunnelEndpoint}?host=*&allowed_origin=*`;

        if (this.isIE && window.XDomainRequest) {
            return this.ieSendZpl(url);
        } else {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url,
                    dataType: 'text',
                    jsonp: false,
                    crossDomain: true,
                    success(data: any, _textStatus: string, _jqXHR: JQueryXHR) {
                        resolve(data);
                    },
                    error(jqXHR: JQueryXHR, _textStatus: string, _errorThrown: string) {
                        resolve(jqXHR.responseText || _textStatus);
                    },
                    timeout: 10000,
                });
            });
        }
    }

    uploadPrintFwdPsp(printer) {
        let downloadFile = printer.tunnel_url.substring(
            printer.tunnel_url.lastIndexOf('/') + 1,
            printer.tunnel_url.length
        );
        const scannerUrl = `${printer.tunnel_protocol}://${printer.tunnel_host}`;

        this.motorolaResource.installPspFile(scannerUrl, downloadFile);
    }

    // ***********
    // tunnel print callback
    // ***********

    processForwardToPrinterResponse(success, message, data) {
        return { success, message, data };
    }
}
