import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';
import { LocalStorageService } from '@services/storage/local-storage.service';

interface FCInitParams {
    token: string;
    widgetUuid: string;
    host: string;
    tags: string[];
    config: {
        fullscreen?: boolean;
        headerProperty?: { hideChatButton: boolean };
        hideFAQ?: boolean;
        showFAQOnOpen?: boolean;
    };
    faqTags: {
        tags: string[];
        filterType: string;
    };
}

declare global {
    interface Window {
        fcWidget: {
            init: (params: FCInitParams) => void;
            destroy: () => void;
            open: () => void;
            isInitialized: () => boolean;
            isOpen: () => boolean;
            on: (eventName: String, callback: () => void) => void;
            off: (eventName: String) => void;
        };
    }
}

const FC_WIDGET_RESTORE_ID_KEY = 'fcWidget:restoreId';

@Injectable()
export class FreshDeskService {
    constructor(
        private localStorageService: LocalStorageService,
        private configuration: ConfigurationProvider
    ) {}

    private initParams = {
        token: this.configuration.getConfiguration().fresh_chat_token,
        widgetUuid: this.configuration.getConfiguration().fresh_chat_uuid,
        host: this.configuration.getConfiguration().fresh_chat_host,
        tags: ['KitCheck'],
        faqTags: {
            tags: ['KitCheck'],
            filterType: 'category',
        },
        open: true,
    };

    private initConfig = {
        fullscreen: true,
        headerProperty: { hideChatButton: true },
    };

    private chatConfig = {
        ...this.initConfig,
        hideFAQ: true,
        showFAQOnOpen: false,
    };

    private FAQConfig = {
        ...this.initConfig,
        hideFAQ: false,
    };

    private reinitializeFreshChat(initParams: FCInitParams) {
        window.fcWidget.destroy();
        window.fcWidget.on('widget:destroyed', () => {
            window.fcWidget.init(initParams);
            window.fcWidget.off('widget:destroyed');
        });
    }

    private initFreshChat(initParams: FCInitParams) {
        if (window.fcWidget.isInitialized()) {
            this.reinitializeFreshChat(initParams);
        } else {
            window.fcWidget.init(initParams);
        }
    }

    private createFreshDeskElement(app, elementId) {
        const freshDeskElement = app.createElement('script');
        freshDeskElement.id = elementId;
        freshDeskElement.async = !0;
        freshDeskElement.src = 'https://bluesight-support.freshchat.com/js/widget.js';
        app.head.appendChild(freshDeskElement);
    }

    initialize(app, elementId) {
        if (!app.getElementById(elementId)) {
            return this.createFreshDeskElement(app, elementId);
        }
    }

    initiateCall() {
        this.initialize(document, 'Freshchat-js-sdk');
    }

    destroy() {
        window.fcWidget.destroy();
        this.localStorageService.remove(FC_WIDGET_RESTORE_ID_KEY);
    }

    initChatWidget() {
        this.initFreshChat({
            ...this.initParams,
            config: this.chatConfig,
        });
    }

    initFAQWidget() {
        this.initFreshChat({
            ...this.initParams,
            config: this.FAQConfig,
        });
    }

    isWidgetOpen() {
        return window?.fcWidget?.isOpen();
    }
}
