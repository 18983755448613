import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';
import { datadogRum } from '@datadog/browser-rum';
import { ApplicationService } from '@services/system/application.service';
import { GroupLoginService } from '@services/login/group-login.service';

@Injectable()
export class DatadogService {
    private activateRum: boolean = false;

    constructor(
        private applicationService: ApplicationService,
        private groupLoginService: GroupLoginService,
        private configuration: ConfigurationProvider
    ) {}

    load() {
        const configVars = this.configuration.getConfiguration();
        this.activateRum = ['qa', 'integration', 'staging', 'production'].includes(configVars.environment);
        if (this.activateRum) {
            const datadogInitValue = {
                applicationId: configVars.datadog_app_id,
                clientToken: configVars.datadog_client_token,
                site: 'datadoghq.com',
                service: 'kitcheck-ui',
                env: configVars.environment,
                version: this.applicationService.version,
                sessionSampleRate: 25,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackFrustrations: true,
                trackResources: true,
                trackLongTasks: true,
            };
            datadogRum.init(datadogInitValue);
        }
    }

    onLogin() {
        if (this.activateRum) {
            // only record sessions for successfully authenticated users
            const user = this.groupLoginService.getUser();
            datadogRum.setUser({
                id: user.id,
                email: user.email,
                name: user.firstName + ' ' + user.lastName,
                hospital_id: user.hospitalId,
            });
            datadogRum.startSessionReplayRecording();
        }
    }
}
