import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KitResource } from '@resources/kit-resource.service';
import { Kit } from '@models/core/kit';

interface ParamData {
    kits: Kit[];
    total: number;
}

@Component({
    selector: 'delete-kits',
    templateUrl: './delete-kits-dialog.html',
    styleUrls: ['./delete-kits-dialog.scss'],
})
export class DeleteKitsDialog {
    kits: Kit[];
    styles: any;
    dataLoading: boolean = false;
    confirmed: boolean = false;
    total: number;
    selected: number;

    constructor(
        public dialogRef: MatDialogRef<DeleteKitsDialog>,
        private kitResource: KitResource,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.kits = this.data.kits;
        this.styles = {};
        this.total = this.data.total;
        if (this.kits.length > 10) {
            /* eslint-disable */
            this.styles = {
                height: '325px',
                'margin-bottom': '15px',
                overflow: 'auto',
                padding: '0 10px',
            };
            /* eslint-enable */
        }
    }

    dismiss(): void {
        this.dialogRef.close();
    }

    deleteKits(): void {
        this.dataLoading = true;
        let data = { kit_ids: this.kits.map((kit) => kit.id) };
        this.kitResource
            .removeKits(data)
            .then(() => {
                this.dataLoading = false;
                this.dialogRef.close('success');
            })
            .catch(() => {
                this.dataLoading = false;
                this.dialogRef.close();
            });
    }

    unconfirmed(): boolean {
        return this.selected !== this.kits.length;
    }
}
