import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { UserResource } from '../resources/user-resource.service';

import { User } from '@models/core/user';

@Injectable()
export class UserListService {
    private cachedUsers: User[];

    constructor(
        private localStorageService: LocalStorageService,
        private userResource: UserResource
    ) {}

    private addFullName(users) {
        users.forEach((user) => {
            user.full_name = user.first_name + ' ' + user.last_name;
        });

        return users;
    }

    clear(): void {
        this.cachedUsers = undefined;
    }

    getUsers(reload?: boolean, excludeEmployees?: boolean): Promise<any> {
        if (this.cachedUsers && !reload) {
            return Promise.resolve(this.cachedUsers);
        } else {
            const currentGroup = this.localStorageService.get('currentGroup').id;
            return this.userResource
                .showUsers(currentGroup, excludeEmployees)
                .then((data) => {
                    this.cachedUsers = this.addFullName(data.users);
                    return data.users;
                })
                .catch((data) => {
                    return Promise.reject(data);
                });
        }
    }

    getUsersExcludeEmployees(exclude: boolean, reload?: boolean): Promise<any> {
        return this.getUsers(reload, exclude);
    }
}
