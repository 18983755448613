import { Injectable } from '@angular/core';
import { CartResource } from '@resources/cart-resource.service';
import { LocationResource } from '@resources/location-resource.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class CommonCRUDAPIService {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private cartResource: CartResource,
        private locationResource: LocationResource,
        private http: HttpClient,
        private configuration: ConfigurationProvider
    ) {
        const apis = [cartResource, locationResource];

        apis.forEach((api) => {
            Object.getOwnPropertyNames(Object.getPrototypeOf(api)).map((method) => {
                this[method] = api[method].bind(this);
            });
        });
    }
}
