import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';
import { isEmpty } from '@utils/objects';

interface ApiData {
    action_names: string; // oddly named as this is should be a single action name string
}

interface Activities {
    id: number;
    name: string;
    action_name: string;
}

@Component({
    selector: 'activity-filter',
    templateUrl: './activity-filter.html',
    providers: [{ provide: 'IFilter', useExisting: ActivityFilter }],
})
export class ActivityFilter implements IFilter {
    @Input() activitiesList: Activities[];
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeActivity = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    activities: Activities[];

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportResource: ReportResource
    ) {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.action_names;
        }

        this.getUserActivityList();
        if (this.reportSubscription) {
            //for reportSubscription modal, we want the selected activity to persist from report page
            this.filterActivity();
        } else {
            this.reset();
        }
    }

    filterActivity(): void {
        this.changeActivity.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'activity', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = null;
        this.changeActivity.emit(this.filterData);
    }

    getUserActivityList(): void {
        this.loadingSpinnerService.spinnerifyPromise(
            this.reportResource.userActivitiesList().then((data) => {
                this.activities = data.actions;
            })
        );
    }

    formatForApi(): ApiData[] {
        return [{ action_names: this.filterData }];
    }
}
