import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { CartResource } from '@resources/cart-resource.service';

@Injectable({ providedIn: 'root' })
export class AdminCartsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private cartResource: CartResource
    ) {}

    resolve(): Promise<any> {
        let cartPromise;
        if (this.actionService.isAllowAction('hospital_settings', 'view_cart', 'Load cart list data')) {
            cartPromise = this.cartResource.adminCart().then((data) => {
                return data.carts;
            });
        } else {
            cartPromise = Promise.resolve([]);
        }
        return this.loadingSpinnerService.spinnerifyPromise(cartPromise);
    }
}
