import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { UnexpectedItem } from '@models/core/kit-summary';
import { populateMissingItemsText, buildExpectedTextForUnexpectedItems } from '@utils/kit-summary-util';

@Component({
    selector: 'mobile-cart-unexpected-items',
    templateUrl: './unexpected-items.html',
    styleUrls: ['./unexpected-items.scss'],
})
export class MobileCartUnexpectedItemsComponent implements OnInit, AfterViewInit {
    @Input() missingItems: UnexpectedItem[] = null;
    @Input() extraItems: UnexpectedItem[] = null;
    @Input() deltaOnRight: boolean;

    itemsToDisplay: UnexpectedItem[];
    tableName: string = 'kit_page.summary.missing_items';

    dataSource: MatTableDataSourceWithNaturalSort<UnexpectedItem>;
    displayedColumns: string[] = ['segment_name', 'determinedExpectedText', 'actual'];
    @ViewChild(MatSort) sort: MatSort;

    constructor() {}

    ngOnInit() {
        let variedColumn: string;
        if (this.missingItems !== null) {
            variedColumn = 'determinedMissingText';
            this.itemsToDisplay = populateMissingItemsText(this.missingItems);
        } else {
            variedColumn = 'extra';
            this.tableName = 'kit_page.summary.extra_items';
            this.itemsToDisplay = this.extraItems.map((item) => {
                item.determinedExpectedText = buildExpectedTextForUnexpectedItems(item);
                return item;
            });
        }

        if (this.deltaOnRight) {
            this.displayedColumns.push(variedColumn);
        } else {
            this.displayedColumns.unshift(variedColumn);
        }
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.itemsToDisplay);
        this.dataSource.sort = this.sort;
    }
}
