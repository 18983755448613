import { Component } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoginStateService } from '@services/login/login-state.service';
import { MobileCartService } from '@services/utils/mobile-cart.service';
import { ScanResult } from '@models/core/scan';

@Component({
    selector: 'mobile-cart-header',
    templateUrl: './header.html',
    styleUrls: ['./header.scss'],
})
export class MobileCartHeaderComponent {
    hospitalName: string;
    user: {
        firstName: string;
        lastName: string;
        userName: string;
        formatted_name: string;
    };

    constructor(
        private applicationService: ApplicationService,
        private actionService: ActionService,
        private loginStateService: LoginStateService,
        private hospitalInfoService: HospitalInfoService,
        public mobileCartService: MobileCartService
    ) {}

    ngOnInit() {
        this.hospitalName = this.hospitalInfoService.getHospitalName();
        this.user = this.applicationService.user;
    }

    logout() {
        this.loginStateService.notifyLogout(
            !this.actionService.isAllowAction('security', 'logout', 'NOT - Prevent sending logout request to backend')
        );
    }

    redirectScan(scanResult: ScanResult) {
        this.mobileCartService.goToScanSummary(scanResult);
    }
}
