import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { UserSession } from '@models/core/user-session';

@Injectable()
export class SessionResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    verifyLoginSession(): Promise<{ user: UserSession }> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}sessions/me`).toPromise();
    }
}
