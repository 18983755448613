import { Injectable } from '@angular/core';

import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { RecallResource } from '@resources/recall-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { buildRecallMessageText, buildActiveRecallMessageText } from '@utils/recall-util';

@Injectable()
export class RecallService {
    showRecallNotice: boolean;

    constructor(
        private formularyItemResource: FormularyItemResource,
        private recallResource: RecallResource,
        private translationService: TranslationService
    ) {}

    buildRecallNotifications(): Promise<any> {
        return this.formularyItemResource.recallNotification().then((resp) => {
            if (resp.formulary_items.length > 0) {
                let recallMsg = this.translationService.inflect('network_recalls.message', resp.formulary_items.length);
                let newNetworkMsg = this.translationService.inflect(
                    'network_recalls.new_network',
                    resp.formulary_items.length
                );

                return buildRecallMessageText(resp.formulary_items, recallMsg, newNetworkMsg);
            } else {
                return null;
            }
        });
    }

    buildActiveRecallNotifications(): Promise<any> {
        return this.recallResource.recallMessages().then((resp) => {
            let messages = [];
            resp.recalls.forEach((recall) => {
                let recallMsg = this.translationService.inflect('active_recalls.message', 1);
                let newActiveMsg = this.translationService.inflect('active_recalls.new_recall_added', 1);
                let moreText = this.translationService.inflect('active_recalls.manage_recall', 1);
                messages.push(buildActiveRecallMessageText(recall, recallMsg, newActiveMsg, moreText));
            });
            return messages;
        });
    }

    dismissRecallAlert(formulary_item_ids): Promise<any> {
        return this.formularyItemResource.dismissRecallNotification(formulary_item_ids);
    }

    dismissActiveRecallAlert(recall_id): Promise<any> {
        return this.recallResource.dismissRecallMessage(recall_id);
    }
}
