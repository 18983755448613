import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ParamData {
    formularyItem: {
        item_name: string;
        ndc: string;
    };
}

@Component({
    selector: 'activate-formulary-item-dialog',
    templateUrl: './activate-formulary-item-dialog.html',
})
export class ActivateFormularyItemDialog {
    formularyItem;

    constructor(
        public dialogRef: MatDialogRef<ActivateFormularyItemDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.formularyItem = this.data.formularyItem;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close(true);
    }
}
