import { OnInit, Component, Inject, Input } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';

import { ActionService } from '@services/utils/action.service';
import { AeroscoutLoginDialog } from './aeroscout-login/aeroscout-login-dialog';
import { AeroscoutService } from '@services/hardware/aeroscout.service';
import { AeroscoutResource } from '@resources/aeroscout-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'aeroscout-asset-select',
    templateUrl: './aeroscout-asset-select.html',
    styleUrls: ['./aeroscout-asset-select.scss'],
})
export class AeroscoutAssetSelect implements OnInit {
    @Input() assetName: string;
    @Input() assetId: string;
    private assetSelected: boolean;
    private searching: boolean;
    private noMatchingAssets: boolean;
    private authorizedToEdit: boolean;
    private remoteAssets: any[];
    private scannerAvailable: boolean;
    private originalSelection: {};
    private subscription: Subscription;

    loginCancelledMessage: string;

    constructor(
        @Inject(LOCAL_STORAGE) private localStorageService: WebStorageService,
        private actionService: ActionService,
        private aeroscoutResource: AeroscoutResource,
        private aeroscoutService: AeroscoutService,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private translationService: TranslationService
    ) {
        this.remoteAssets = [];
    }

    ngOnInit() {
        this.assetSelected = !!this.assetName;
        this.originalSelection = { name: this.assetName, id: this.assetId };
        this.authorizedToEdit = this.actionService.isAllowAction(
            'hospital_settings',
            'update_cart',
            'Aeroscout Select: update cart'
        );

        this.aeroscoutService.getOnlineScanners().then((scanners) => {
            this.scannerAvailable = scanners.length > 0;
        });

        this.loginCancelledMessage = this.translationService.instant('modals.aeroscout.login_cancelled');

        this.subscription = this.aeroscoutService.observeAssetSelectReset().subscribe((event) => {
            if (!!event) {
                this.assetSelected = false;
                this.assetName = '';
                this.assetId = '';
            }
        });
    }

    deregisterResetEvent() {
        this.subscription.unsubscribe();
    }

    ngOnDestroy() {
        this.deregisterResetEvent();
    }

    selectionWasClicked() {
        if (this.scannerAvailable) {
            this.assetSelected = false;
            $('#aeroscout-input').focus();
            this.nameWasChanged(this.assetName, 0);
        }
    }

    loginToMobileView() {
        if (!this.loggedIn()) {
            this.aeroscoutLogin().then(() => {});
        }
    }

    loggedIn() {
        return !!this.localStorageService.get('kcui.aeroscoutCredentials');
    }

    nameWasChanged(thisSearch: string, timeout = 500) {
        this.assetName = thisSearch;
        this.assetSelected = false;
        this.remoteAssets = [];
        this.searching = false;

        if (this.assetName.length > 0) {
            this.aeroscoutService.emitAssetClearEvent();
            setTimeout(() => {
                if (thisSearch === this.assetName) {
                    if (!this.loggedIn() || !this.aeroscoutService.validScanner) {
                        this.aeroscoutLogin()
                            .then(() => {
                                this.nameWasChanged(thisSearch, 0);
                            })
                            .catch(() => {
                                this.assetSelected = !!this.assetId && !!this.assetName;
                            });
                    } else {
                        this.searching = true;
                        this.noMatchingAssets = false;
                        this.aeroscoutService
                            .findAeroscoutAsset(this.aeroscoutService.validScanner, thisSearch)
                            .then((remoteAssets) => {
                                if (thisSearch === this.assetName) {
                                    this.searching = false;
                                    if (remoteAssets.length === 0) {
                                        this.remoteAssets = [];
                                        this.noMatchingAssets = true;
                                    } else {
                                        this.remoteAssets = remoteAssets;
                                    }
                                }
                            })
                            .catch((e) => {
                                if (e.status === 401) {
                                    this.aeroscoutLogin().then(() => {
                                        this.nameWasChanged(thisSearch, 0);
                                    });
                                } else {
                                    //scanner is probably disconnected - undefine it
                                    this.scannerAvailable = false;
                                    this.selectAsset(this.originalSelection);
                                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                                        key: 'aeroscout.disconnected_scanner',
                                    });
                                }
                                this.searching = false;
                            });
                    }
                }
            }, timeout);
        } else {
            this.aeroscoutService.emitAssetSelectionEvent({ name: '' });
            this.noMatchingAssets = false;
        }
    }

    async aeroscoutLogin(): Promise<any> {
        const loginDialog = this.dialog.open(AeroscoutLoginDialog, {
            width: '600px',
            height: 'max-content',
            data: {},
        });

        let result = loginDialog
            .afterClosed()
            .toPromise()
            .then((confirmed) => {
                if (confirmed) {
                    return Promise.resolve();
                } else {
                    return Promise.reject({ message: this.loginCancelledMessage });
                }
            });
        return result;
    }

    selectAsset(asset: any): void {
        if (asset) {
            this.assetName = asset.name;
            this.assetId = asset.id;
            this.assetSelected = true;
            this.remoteAssets = [];
            this.aeroscoutService.emitAssetSelectionEvent(asset);
        }
    }
}
