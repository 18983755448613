import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export function isPhoneNumberValid(phone: string) {
    try {
        // If user doesn't type the country code, we're assuming +1
        const formPhoneNumber = phone.startsWith('+') ? phone : `+1${phone}`;

        const phoneNumber = phoneUtil.parseAndKeepRawInput(formPhoneNumber);

        if (!phoneUtil.isValidNumber(phoneNumber)) {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }
}
