import { Directive, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[requiredTrue]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RequiredTrueValidateDirective),
            multi: true,
        },
    ],
})
export class RequiredTrueValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return requiredTrueValidator()(control);
    }
}

export function requiredTrueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return !control.value ? { requiredTrue: false } : null;
    };
}
