import { Component } from '@angular/core';

import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'manage-items-print-error',
    templateUrl: './manage-items-print-error.html',
})
export class ManageItemsPrintError {
    errorAlert: string;
    errorMessage: string;

    constructor(private translationService: TranslationService) {
        this.errorAlert = this.translationService.instant('manage_items.print_error.alert');
        this.errorMessage = this.translationService.instant('manage_items.print_error.message');
    }
}
