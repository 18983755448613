import { Component, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { MatPaginator } from '@angular/material/paginator';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    DEFAULT_SHOW_FIRST_LAST_BUTTONS,
} from '@models/common/report-pagination';

@Component({
    selector: 'report-kit-activity',
    templateUrl: './report-kit-activity.html',
})
export class ReportKitActivity {
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    report: Report;
    isDownloadable: boolean = true;
    filters: {
        start_date: string;
        end_date: string;
        kit_master_id?: number;
        kit_ids?: number;
        activities?: string;
    };
    reportDate: Date;
    customDates: {
        start_date: Date;
        end_date: Date;
    };
    hasData: boolean = false;
    hasRun: boolean = false;
    isLoading: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'created_at',
        'kit_master_name',
        'physical_label',
        'deleted_date',
        'activities',
        'locks',
        'places',
        'user_full_name',
    ];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.reportDate = new Date();

        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'activity'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'kit_activity');

        this.customDates = {
            start_date: moment(new Date()).subtract('months', 1).startOf('month').toDate(),
            end_date: moment(new Date()).subtract('months', 1).endOf('month').toDate(),
        };
    }

    refreshReport(event: any): void {
        this.filters = {
            start_date: event.date.start_date,
            end_date: event.date.end_date,
        };

        if (!!event.kitInfo) {
            if (!!event.kitInfo.kitMaster) {
                this.filters.kit_master_id = event.kitInfo.kitMaster;
            }
            if (!!event.kitInfo.kitLabel) {
                this.filters.kit_ids = event.kitInfo.kitLabel;
            }
        }
        if (!!event.activities) {
            this.filters.activities = event.activities.join('|');
        }

        this.isLoading = true;
        const promise = this.reportResource.kitActivity(this.filters).then((rawReportData) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.reportDate = new Date();
            this.isLoading = false;
            this.hasRun = true;
            this.hasData = !!rawReportData.reports.length;
        });
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            start_date: event.date.start_date,
            kitInfo: event.kitInfo,
            activities: event.activities,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: 'max-content',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
