import { KitSummary, ExpirationDate, UnexpectedItem, ScanWarning } from '@models/core/kit-summary';
import { Scan } from '@models/core/scan';
import { difference } from '@utils/objects';

export const disallowDispatchIncompleteKit = (kitSummary: KitSummary): boolean => {
    return (
        !kitSummary.complete &&
        !!kitSummary.kit_master.dispatch_validations.find((dv) => dv.validation_type === 'kit_complete_scan')
    );
};

export const getSoonestExpirations = (kitSummary: KitSummary): ExpirationDate => {
    let result = null;
    if (kitSummary.expirations && kitSummary.expirations.soonest) {
        result = kitSummary.expirations.dates.find(
            (expirationDate) => expirationDate.expiration === kitSummary.expirations.soonest
        );
    }
    return result;
};

export const getScanUsername = (scanData: Scan) => {
    let result = scanData.created_by;
    if (scanData.is_pharmacist) {
        result += ', Pharmacist';
    }
    return result;
};

export const populateMissingItemsText = (missingItems: UnexpectedItem[]): UnexpectedItem[] => {
    missingItems = missingItems.map((item) => {
        item.determinedMissingText = buildMissingItemsText(item);
        item.determinedExpectedText = buildExpectedTextForUnexpectedItems(item);
        return item;
    });
    return missingItems;
};

export const buildMissingItemsText = (item: UnexpectedItem): string => {
    const missingMin = item?.missing_min ? item.missing_min.toString() : '';
    const missingMax = item?.missing_max ? item.missing_max.toString() : '';

    if (missingMax === missingMin) {
        return missingMin;
    }
    return `${missingMin} - ${missingMax}`;
};

export const buildExpectedTextForUnexpectedItems = (item: UnexpectedItem): string => {
    const shortage = item?.shortage;
    const expectedMax = item?.expected_max ? item.expected_max.toString() : '';
    const expectedMin = item?.expected_min ? item.expected_min.toString() : '';

    if (shortage) {
        if (expectedMax === expectedMin) {
            return expectedMin;
        }
        return `${expectedMin} - ${expectedMax}`;
    } else {
        return item?.expected ? item.expected.toString() : '';
    }
};

export const determineShortageRange = (item: UnexpectedItem): string => {
    if (item.expected_max === item.expected_min) {
        return item.expected_max.toString();
    }
    return `${item.expected_min} - ${item.expected_max}`;
};

export const buildScanWarningList = (kitSummary: KitSummary, isAllowedDeleteFormularyItem: boolean): ScanWarning[] => {
    let result: ScanWarning[] = [];

    if (kitSummary?.items?.missing?.length && kitSummary?.items?.wrong?.length) {
        result.push({
            primary: 'kit_page.alerts.detected_missing_and_wrong_items',
        });
    }
    if (kitSummary?.counts?.unknown) {
        result.push({
            primary: 'kit_page.alerts.detected_unknown_tags',
            secondary: 'kit_page.alerts.could_impact_accuracy',
        });
    }
    if (kitSummary?.contents_up_to_date === false) {
        result.push({
            primary: 'kit_page.alerts.subsequently_seen',
            secondary: 'kit_page.alerts.pls_rescan',
        });
    }
    if (kitSummary?.exceptions) {
        let unassociatedTags = difference([
            kitSummary.exceptions.unassociated_tags,
            kitSummary.exceptions.decommissioned_tags.map((t) => t.tag_id_formatted),
        ]);
        if (unassociatedTags?.length > 0) {
            result.push({
                primary: 'kit_page.alerts.unused_or_unassociated_tags',
                secondary: 'kit_page.epc',
                itemList: unassociatedTags,
            });
        }
    }
    if (kitSummary?.items?.wrong?.some((item) => !!item.formulary_item_deleted_at)) {
        result.push({
            primary: 'kit_page.alerts.detected_archived_items',
            secondary: isAllowedDeleteFormularyItem
                ? 'kit_page.alerts.please_see_archived_items_manager'
                : 'kit_page.alerts.please_see_archived_items_non_manager',
        });
    }
    if (kitSummary?.items?.recalled_items?.length) {
        result.push({
            primary: 'kit_page.alerts.detected_recalled_items',
            secondary: 'kit_page.alerts.pls_see_recalled_items',
        });
    }

    return result;
};
