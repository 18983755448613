import { Component, Input, Output, EventEmitter, ViewChildren, OnInit, AfterViewInit, QueryList } from '@angular/core';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { ReportResource } from '@resources/report-resource.service';
import { IFilter } from '@models/common/report-filter';
import { DateFilterV2 } from '../date-filter/date-filter';
import { DateMonthFilterV2 } from '../date-month-filter/date-month-filter';

@Component({
    selector: 'report-filters',
    templateUrl: './report-filters.html',
    styleUrls: ['./report-filters.scss'],
})
export class ReportFilters implements OnInit, AfterViewInit {
    @Input() filters: Array<
        | 'date'
        | 'dateMonth'
        | 'kitMasters'
        | 'kitMaster'
        | 'binKitMasters'
        | 'timeframe'
        | 'kitsNeedingRework'
        | 'itemTypeGroup'
        | 'binKit'
        | 'kitDrugGroup'
        | 'genericNdcLotSearch'
        | 'ndcLotSearch'
        | 'genericNdcItemType'
        | 'expiration'
        | 'timeRange'
        | 'includeKits'
        | 'recalls'
        | 'activities'
        | 'activity'
        | 'user'
        | 'reasons'
        | 'batches'
        | 'kitInfo'
        | 'priceType'
        | 'removedReasons'
        | 'hospitalGroups'
    >;
    @Input() isLoading: boolean = false;
    @Input() reportDownloadFilters: any;
    @Input() reportApiUrl: string;
    @Input() reportIsDownloadable: boolean;
    @Input() reportIsSubscribable: boolean = true;
    @Input() hasAnyData;
    @Input() reportSubscription: boolean;
    @Input() consumedKitMasters;
    @Input() filterData: any = {};
    @Input() isSystem: boolean = false;
    @Input() customDates;
    @Input() noInitialRun: boolean;
    @Input() disableRunReport: boolean;
    @Input() kits: Array<any>;
    @Input() kitMasters: Array<any>;
    @Input() kitActivityList: Array<any>;
    @Input() scheduledReportsEnabled;
    @Input() dataPx: string;
    @Input() downloadDataPx: string;

    @Output() runReport = new EventEmitter();
    @Output() subscribe = new EventEmitter();
    @Output() sendData = new EventEmitter();

    @ViewChildren('IFilter') children: QueryList<IFilter>;
    private dateFilter: DateFilterV2;
    private dateMonthFilter: DateMonthFilterV2;

    topFilters: string[] = [];

    constructor(
        protected hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected kitMasterResource: KitMasterResource,
        protected reportResource: ReportResource
    ) {}

    ngOnInit() {
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
        if (!this.reportSubscription) {
            this.setDefaults();
        } else if (!!this.filterData.kit_masters) {
            //Manage Subscriptions passes the parameter kit_masters to report-subscription
            this.filterData.kitMasters = this.filterData.kit_masters;
        }

        // remove the top filter from the filters list so we don't get an extra border.
        if (this.filters && this.filters.includes('hospitalGroups')) {
            this.topFilters = ['hospitalGroups'];
            //lmao.
            this.filters = this.filters.filter((filter) => filter !== 'hospitalGroups');
        }
    }

    ngAfterViewInit() {
        let dateFilter: IFilter = this.children.find((filter) => {
            return filter instanceof DateFilterV2;
        });
        let dateMonthFilter: IFilter = this.children.find((filter) => {
            return filter instanceof DateMonthFilterV2;
        });
        this.dateFilter = dateFilter as DateFilterV2;
        this.dateMonthFilter = dateMonthFilter as DateMonthFilterV2;
    }

    setDefaults(): void {
        this.filters.forEach((filter) => {
            //We need to add the filter types as keys on filterData, so that the filter components can bind to them.
            if (filter !== 'recalls' && !this.filterData[filter]) {
                // preserve any prefilter data
                this.filterData[filter] = null;
            }
        });

        let networkPromises = [];
        if (
            this.filters.includes('kitMaster') ||
            this.filters.includes('kitMasters') ||
            this.filters.includes('binKitMasters') ||
            this.filters.includes('kitInfo')
        ) {
            networkPromises.push(
                this.kitMasterResource.kitMasterList(true).then((data: any) => {
                    if (this.filters.includes('kitMasters')) {
                        //Item Expirations Report doesn't need kitMasters added to filterData because it has no initial run
                        this.filterData.kitMasters = data.kit_masters;
                        if (this.consumedKitMasters) {
                            this.concatConsumedKitMasters();
                        }
                    }
                })
            );
        }

        this.loadingSpinnerService.spinnerifyPromise(
            Promise.all(networkPromises).then(() => {
                if (this.noInitialRun) {
                    this.sendData.emit(this.filterData); //report without initial filtering, still need access to filterData initially
                } else {
                    this.filterReport();
                }
            })
        );
    }

    concatConsumedKitMasters(): void {
        let kitMastersIds = this.filterData.kitMasters.map((kitMaster) => kitMaster.id);
        let unique = this.consumedKitMasters.filter((kitMaster) => {
            return !kitMastersIds.includes(kitMaster.kit_master_id);
        });
        this.filterData.kitMasters = this.filterData.kitMasters.concat(
            unique.map((kitMaster) => {
                if (!kitMaster.kit_master_name) {
                    return {
                        name: '— Items decommissioned outside of kits',
                        id: kitMaster.kit_master_id,
                    };
                } else {
                    return {
                        name: kitMaster.kit_master_name,
                        id: kitMaster.kit_master_id,
                    };
                }
            })
        );
    }

    resetFilters(): void {
        this.children.toArray().forEach((child: any) => {
            child.reset();
        });
    }

    subscribeModal(): void {
        this.subscribe.emit(this.filterData);
    }
    // let's a filter disable the run report button
    // if not complete
    incompleteFilter(event): void {
        this.disableRunReport = event.incomplete;
    }

    filterReport(): void {
        //emits an event to the report. Upon receiving the runReport event, the report runs its filtering function.
        this.runReport.emit(this.filterData);
    }

    // these functions are necessary since now we are only giving each subcomponent access
    // to one property of filterData, and it is necessary to emit an event from subcomponents. cannot use two way binding anymore.

    // note: we should change this and have the emitter send both the filterdata key (ie, 'genericNdcItemType, kitDrugGroup, etc') and the data
    // then we can just use a single callback instead of this list...

    onChangeGenericNdcItemType(data): void {
        this.filterData.genericNdcItemType = data;
    }

    onChangeGroup(data): void {
        this.filterData.kitDrugGroup = data;
    }

    onChangeReasons(data): void {
        this.filterData.reasons = data;
    }

    onChangeKitMasters(data): void {
        this.filterData.kitMasters = data;
    }

    onChangeKitMaster(data): void {
        this.filterData.kitMaster = data;
    }

    onChangeKitsNeedingRework(data): void {
        this.filterData.kitsNeedingRework = data;
    }

    onChangeItemTypeGroup(data): void {
        this.filterData.itemTypeGroup = data;
    }

    onChangeDate(data): void {
        this.filterData.date = data;
    }

    onChangeDateMonth(data): void {
        this.filterData.dateMonth = data;
    }

    onChangeTimeframe(data): void {
        this.filterData.timeframe = data;
    }

    onChangeExpiration(data): void {
        this.filterData.expiration = data;
    }

    onChangeBinKit(data): void {
        this.filterData.binKit = data;
    }

    onChangeGenericNdcLotSearch(data): void {
        this.filterData.genericNdcLotSearch = data;
    }

    onChangeNdcLotSearch(data): void {
        this.filterData.ndcLotSearch = data;
    }

    onChangeBinKitMasters(data): void {
        this.filterData.binKitMasters = data;
    }

    onChangeKitLabel(data): void {
        this.filterData.kitInfo = data;
    }

    onChangeActivities(data): void {
        this.filterData.activities = data;
    }

    onChangeActivity(data): void {
        this.filterData.activity = data;
    }

    onChangeUser(data): void {
        this.filterData.user = data;
    }

    onChangePriceType(data): void {
        this.filterData.priceType = data;
    }

    onChangeRemovedReasons(data): void {
        this.filterData.removedReasons = data;
    }

    onChangeRecalls(data): void {
        this.filterData.recalls = data;
    }

    onChangeHospitalGroup(data): void {
        this.filterData.groupIds = data;
    }

    onChangeWholesalerPriceType(data): void {
        this.filterData.wholesalerPriceType = data;
    }

    isValid(): boolean {
        if (this.dateFilter) {
            return this.dateFilter.isValid();
        } else if (this.dateMonthFilter) {
            return this.dateMonthFilter.isValid();
        } else {
            return true;
        }
    }
}
