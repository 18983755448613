import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { UserListService } from '@services/core/user-list.service';
import { User } from '@models/core/user';
import { isEmpty } from '@utils/objects';

interface ApiData {
    user_ids: number;
}

@Component({
    selector: 'user-filter',
    templateUrl: './user-filter.html',
    providers: [{ provide: 'IFilter', useExisting: UserFilter }],
})
export class UserFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: number | null;
    @Input() reportSubscription: boolean;
    @Input() usersList: User[];

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeUser = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    users: User[];

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected userListService: UserListService
    ) {}

    ngOnInit() {
        this.getUsers();

        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.user_ids;
        }

        if (this.reportSubscription) {
            //for reportSubscription modal, we want the selected user to persist from report page
            this.changeUser.emit(this.filterData);
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            this.reset();
        }
    }

    filterUsers(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeUser.emit(this.filterData);
        this.incomplete.emit({ filter: 'users', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = null;
        this.changeUser.emit(this.filterData);
    }

    getUsers(): void {
        this.loadingSpinnerService.spinnerifyPromise(
            this.userListService.getUsers(true, true).then((data) => {
                this.users = data;
            })
        );
    }

    formatForApi(): ApiData[] {
        return [{ user_ids: this.filterData }];
    }
}
