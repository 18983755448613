import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormularyItemsService } from '@services/core/formulary-items.service';

import { Package } from '@models/core/package';

interface ParamData {
    segment;
}

@Component({
    selector: 'fill-options-dialog',
    templateUrl: './fill-options-dialog.html',
})
export class FillOptionsDialog {
    segment;

    constructor(
        public dialogRef: MatDialogRef<FillOptionsDialog>,
        protected formularyItemsService: FormularyItemsService,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.segment = this.data.segment;
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
