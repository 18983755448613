import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, NgForm, NgModel } from '@angular/forms';

import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { TranslationService } from '@services/utils/translation.service';

interface DialogData {
    expDate: Date;
    dialogTitle: string;
    dateLabel: string;
    verifyLabel: string;
    maxDate?: Date;
    minDate?: Date;
}

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'expiration-dialog',
    templateUrl: './expiration-dialog.html',
    styleUrls: ['./expiration-dialog.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class ExpirationDialog {
    exp_date: Date;
    min_date: Date;
    max_date: Date;
    max_date_formatted: string;
    ver_exp_date: Date;

    @ViewChild('exp') exp: NgModel;

    constructor(
        public dialogRef: MatDialogRef<ExpirationDialog>,
        private parseFormErrorsService: ParseFormErrorsService,
        private translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.exp_date = this.data.expDate;
        this.ver_exp_date = this.data.expDate;
        this.min_date = this.data.minDate;
        this.max_date = this.data.maxDate;
        this.max_date_formatted = moment(this.max_date).format('MMM D, yyyy');
    }

    completed() {
        return (
            !this.exp?.errors &&
            moment(this.exp_date).format('YYYY-MM-DD') === moment(this.ver_exp_date).format('YYYY-MM-DD')
        );
    }

    clear() {
        this.exp_date = null;
        this.ver_exp_date = null;
    }

    customErrors(control: NgModel): string {
        let errString = this.parseErrors(control.errors);
        if (control.errors.mustmatch) {
            errString = this.translationService.instant('errors.dates_must_match');
        }

        if (control.errors.matDatepickerMin) {
            errString = this.translationService.instant('errors.non_past_date');
        }
        if (control.errors.matDatepickerMax) {
            errString = this.translationService.instant('errors.max_date', { max: this.max_date_formatted });
        }
        if (control.errors.matDatepickerParse) {
            errString = this.translationService.instant('errors.valid_date');
        }

        return errString;
    }

    parseErrors(errors: any): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    onCancel() {
        this.dialogRef.close(undefined);
    }

    onOk() {
        this.dialogRef.close(this.exp_date);
    }
}
