import { Component, Input, Output, EventEmitter } from '@angular/core';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { IFilter } from '@models/common/report-filter';
import { KitMaster } from '@models/core/kit-master';
import { isEmpty } from '@utils/objects';

interface ApiData {
    kit_master_id: number; // a single kit master id
}

@Component({
    selector: 'kit-master-filter',
    templateUrl: './kit-master-filter.html',
    providers: [{ provide: 'IFilter', useExisting: KitMasterFilter }],
})
export class KitMasterFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: number | null; // from report-filter
    @Input() includeSharedKitMasters: boolean = false;
    @Input() kitMasters: KitMaster[];
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeKitMasters = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    kitMastersList: KitMaster[];

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private kitMasterResource: KitMasterResource
    ) {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.kit_master_id;
        }

        // need to set to null if undefined for the select control
        if (!this.filterData) {
            this.filterData = null;
        }

        if (this.kitMasters) {
            //kitMasters passed in to reportSubscsription modal for Kit Activity report
            this.kitMastersList = this.kitMasters;
            this.filterKitMasters();
        } else {
            this.getKitMasterList();
        }
    }

    getKitMasterList(): void {
        this.loadingSpinnerService.spinnerifyPromise(
            this.kitMasterResource.kitMasterList(this.includeSharedKitMasters).then((data) => {
                this.kitMastersList = data.kit_masters;
                if (!!!this.reportSubscription) {
                    //for reportSubscription modal, we want the selected kitMasters to persist from report page
                    this.filterData = null;
                }
                this.filterKitMasters();
            })
        );
    }

    filterKitMasters(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeKitMasters.emit(this.filterData);
        this.incomplete.emit({ filter: 'kitMaster', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = null;
        this.changeKitMasters.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        return [{ kit_master_id: this.filterData }];
    }
}
