import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'texas-childrens-main',
    templateUrl: './texas-childrens-main.html',
})
export class TexasChildrensMain implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }
}
