import { Component, Input, OnInit, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { HttpClient } from '@angular/common/http';
import { StateService, Transition } from '@uirouter/core';
import { BinTagPackageResource } from '@resources/bin-tag-package-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ItemResource } from '@resources/item-resource.service';
import { PretaggedProblemResource } from '@resources/pretagged-problem-resource.service';
import { ScanResource } from '@resources/scan-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { ChooseBinDialog } from '@dialogs/choose-bin/choose-bin-dialog';
import { ConfirmDialog } from '@dialogs/confirm/confirm-dialog';
import { DecommissionPackagesDialog } from '@dialogs/decommission-packages/decommission-packages-dialog';
import { PretaggedIssueDialog } from '@dialogs/pretagged-issue/pretagged-issue-dialog';
import * as moment from 'moment';
import { Package } from '@models/core/package';
import { isPostLockoutStateWithItems } from '@utils/charge-model-util';

@Component({
    selector: 'scan-details',
    templateUrl: './scan-details.html',
    styleUrls: ['./scan-details.scss'],
})
export class ScanDetails implements OnInit {
    @Input() detailScan: any;
    @Input() scanId;
    expiredPackages: any[];
    packages: any[];
    referrer: string;
    showTrust: boolean;
    packageIds: any[];
    ceqs: any[];
    epcs: any[];
    ndcs: any[];
    lots: any[];
    associated_tags: any[];
    expirations: any[];
    bins;
    binId;
    binName;
    transferredMessage;
    payloadCompositionType;
    translationValues;
    tooltip;
    decommissionTooltip;
    anyExpired;
    pretaggedReportId;
    showNextSteps: number = 1;
    @Input() pretaggedProblems;
    itemTypes: any[];
    requiredSubscriptionSegmentItems: Package[];
    showRemoveFromFridge: boolean;
    sameItem: boolean;
    fk_ignored_tags: any[];
    fk_lot_number_denylist_string: string;
    private currentBins: Array<any>;
    private decommissionedItems: Array<any>;

    constructor(
        private actionService: ActionService,
        protected binTagPackageResource: BinTagPackageResource,
        protected configuration: ConfigurationProvider,
        private dialog: MatDialog,
        protected formularyItemResource: FormularyItemResource,
        protected http: HttpClient,
        private hospitalInfoService: HospitalInfoService,
        private itemResource: ItemResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected pretaggedProblemResource: PretaggedProblemResource,
        protected scanResource: ScanResource,
        protected translationService: TranslationService,
        protected $state: StateService
    ) {}

    ngOnInit() {
        this.showTrust = true;
        this.anyExpired = null;
        // @ts-ignore
        // TODO: we should change the target compiler to es2019 so we have more methods available like flatMap
        this.requiredSubscriptionSegmentItems = this.detailScan.packages.filter((item) => item.subscription_required);

        const lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        const chargeModelType = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;

        if (isPostLockoutStateWithItems(lockoutDate, this.requiredSubscriptionSegmentItems?.length, chargeModelType)) {
            this.packages = this.detailScan.packages.filter(
                (item) => !item.subscription_required || item.subscription_required === undefined
            );
        } else {
            this.packages = this.detailScan.packages;
        }

        this.payloadCompositionType = this.detailScan.payload_composition_type.name; //accept single NDC/mixed lots and single NDC/single lot
        this.packageIds = this.detailScan.packages.map((p) => p.id);
        this.epcs = this.detailScan.packages.map((p) => p.epc);
        this.ndcs = this.detailScan.packages.map((p) => p.ndc);
        this.ceqs = this.detailScan.packages.map((p) => p.clinical_equivalence_id);
        this.lots = this.detailScan.packages.map((p) => p.lot_num);
        this.expirations = this.packages.map((p) => p.expiration);
        this.itemTypes = this.detailScan.packages.map((p) => p.item_formulary_type_string);
        this.sameItem =
            this.ndcs.every((n) => n === this.ndcs[0]) &&
            this.lots.every((l) => l === this.lots[0]) &&
            this.expirations.every((e) => e === this.expirations[0]);
        this.currentBins = [];
        this.expiredPackages = _.filter(this.packages, (p) => {
            return p.days_to_expiration && p.days_to_expiration <= 0;
        });
        this.anyExpired = _.some(this.expirations, (date) => new Date() > new Date(date));
        this.associated_tags = [];
        this.decommissionedItems = this.packages.filter((packageItem: any) => {
            return !!packageItem.decommissioned;
        });

        let unstartedFridgePackages = _.filter(this.packages, (p) => {
            return p.fridge && !p.expiration_fridge_started;
        });

        this.showRemoveFromFridge = !!this.packages && unstartedFridgePackages.length === this.packages.length;

        this.getDecommissionTooltip();
        const scanPromise = this.scanResource.scanData(this.detailScan.id).then((data) => {
            this.fk_ignored_tags = data.fk_ignored_tags;
            this.fk_lot_number_denylist_string = data.fk_lot_number_denylist_string;
            const associated_tags = data.associated_tags;
            associated_tags.forEach((tag: any) => {
                this.associated_tags[tag.epc] = tag.id;
            });
            if (this.hospitalAllowsBins()) {
                return this.formularyItemResource.bins(this.packages[0].ndc, 0).then((binsData) => {
                    this.bins = binsData;
                    this.tooltip = this.tooltipText();
                    return this.getCurrentBinsForItem(this.epcs).then((result) => {
                        let binsObject = {};
                        const currentBinItems = result.items;
                        currentBinItems.forEach((item) => {
                            if (!!item && !!item.current_bin) {
                                let foundBin = binsObject[item.current_bin.id];
                                if (!!foundBin) {
                                    Array.prototype.push.apply(foundBin.epcs, item.epcs);
                                } else {
                                    binsObject[item.current_bin.id] = { bin: item.current_bin, epcs: item.epcs };
                                }
                            }
                        });
                        let bins: Array<any> = (Object as any).values(binsObject);
                        this.currentBins = bins;
                    });
                });
            } else {
                return Promise.resolve();
            }
        });

        this.loadingSpinnerService.spinnerifyPromise(scanPromise);
    }

    getCurrentBinsForItem(epcs: Array<string>): Promise<any> {
        let url: string = this.configuration.kcEndpointV1() + 'items/search/';

        return this.http.post(url, { epcs: epcs }).toPromise();
    }

    removeFromBins(): void {
        let promiseArray: Array<any> = [];
        let errors: Array<string> = [];

        this.currentBins.forEach((currentBin: any) => {
            const binId: number = currentBin.bin.id;
            const epcs: Array<string> = currentBin.epcs;
            const tagIds = epcs.map((epc) => this.associated_tags[epc]);
            const tagIdsParam = tagIds.join('|');

            let promise = this.binTagPackageResource.removeBinTagPackage(binId, tagIdsParam).catch((err) => {
                errors.push(currentBin.bin.name);
            });

            promiseArray.push(promise);
        });

        this.translationService
            .get(['remove_from_bin.items_were_removed_from_bin', 'remove_from_bin.items_not_removed_from_bin'])
            .then((translations) => {
                let binName = this.currentBins.length > 1 ? 'their bins' : this.currentBins[0].bin.name;
                this.translationValues = { bin: binName, translations };
                return binName;
            })
            .then((binName) => {
                Promise.all(promiseArray).then((data) => {
                    if (errors.length > 0) {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'remove_from_bin.items_not_removed_from_bin',
                            params: this.translationValues,
                        });
                    } else {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'remove_from_bin.items_were_removed_from_bin',
                            params: this.translationValues,
                        });
                        this.$state.go('home', {
                            transferredMessage: binName,
                            newVerification: 'scan-details-remove',
                        });
                    }
                });
            });
    }

    allowDecommission() {
        return this.actionService.isAllowAction('kits_inventory', 'decommission_tag', 'Show Decommission option');
    }

    decommission() {
        const modalDialog = this.dialog.open(DecommissionPackagesDialog, {
            width: '1000px',
            height: 'max-content',
            data: { packages: this.packages, reason: undefined },
        });

        modalDialog.afterClosed().subscribe((reason) => {
            if (!reason) {
                return;
            } else {
                const myReason = reason;
                let msgKey;
                if (reason.name === 'used_outside') {
                    msgKey = 'scan_details.success_msg_one';
                } else {
                    msgKey = 'scan_details.success_msg_two';
                }
                let msg = this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: msgKey,
                    params: { reason: myReason.display_name },
                });
                msg.afterOpened().subscribe(() => {
                    this.$state.go('home');
                });
            }
        });
    }

    hospitalAllowsBins() {
        return this.hospitalInfoService.allowShelvedInventory();
    }

    payloadCompositionAllowed() {
        const nUniqueNdcs = _.uniq(this.ndcs).length;
        const nUniqueCeqs = _.uniq(this.ceqs).length;
        if (nUniqueNdcs === 1 || nUniqueCeqs) {
            return (
                [
                    'uniform_item_lot_trusted',
                    'multiple_item_lots',
                    'multiple_lots',
                    'registry_and_unassociated_tags',
                ].indexOf(this.payloadCompositionType) >= 0
            );
        } else {
            return false;
        }
    }

    itemsExpiredSameNdc() {
        let unique = _.uniq(this.ndcs);
        return unique.length === 1 && this.anyExpired;
    }

    tooltipText = () => {
        if (!this.hasBins()) {
            return 'There are no bins that accept this formulary item';
        } else if (this.anyExpired) {
            return 'These items cannot be added to a bin because some or all are expired';
        }
    };

    getDecommissionTooltip() {
        this.translationService.get('scan_details.decommission_tooptip').then((t) => {
            this.decommissionTooltip = t;
        });
    }

    addToBinsButton() {
        return this.hospitalAllowsBins() && (this.payloadCompositionAllowed() || this.itemsExpiredSameNdc());
    }

    chooseBin = (): void => {
        const chooseBinDialog = this.dialog.open(ChooseBinDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                bins: this.bins.bins,
            },
        });

        let result = chooseBinDialog.afterClosed().subscribe((data) => {
            if (!!data) {
                this.binId = data.bin_id;
                this.addItemToBin(this.binId);
            }
        });
    };

    reportPretaggedIssue = (): void => {
        const pretaggedIssueDialog = this.dialog.open(PretaggedIssueDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                problems: this.pretaggedProblems,
            },
        });

        let result = pretaggedIssueDialog.afterClosed().subscribe((result) => {
            if (!!result) {
                this.showNextSteps = 2;
                let uniqueEpcs = Array.from(new Set(this.epcs));
                let params: any = {
                    problem_names: [],
                    epcs: uniqueEpcs,
                };

                if (result.notes) {
                    params.notes = result.notes;
                }
                for (let problem in result.checkboxes) {
                    if (
                        result.checkboxes.hasOwnProperty(problem) &&
                        result.checkboxes[problem] &&
                        problem !== 'notes'
                    ) {
                        params.problem_names.push(problem);
                    }
                }
                if (params.problem_names.length === 0) {
                    delete params.problem_names;
                }
                this.pretaggedProblemResource.quarantineTags(params);
            }
        });
    };

    createTags = () => {
        let data = {
            type: 'item',
            ndc: this.packages[0].ndc,
            lot: this.packages[0].lot_num,
            expiration: this.packages[0].expiration,
        };
        this.$state.go('tagging', { type: 'item', barcodeObject: data });
    };

    showPretaggedButton = () => {
        return (
            this.itemTypes.every((type) => type === 'registry') &&
            this.sameItem &&
            this.actionService.isAllowAction('kits_tagging', 'quarantine_tag', 'Show pre-tagged button') &&
            this.showNextSteps === 1
        );
    };

    goHome = () => {
        this.$state.go('home');
    };

    binsDataLoading = () => {
        return this.packages.length > 0 && !this.bins;
    };

    hasBins() {
        if (this.bins) {
            return this.bins.bins.length > 0;
        }
    }

    disableBinsButton() {
        return this.binsDataLoading() || !this.hasBins() || this.itemsExpiredSameNdc();
    }

    addItemToBin(binId) {
        if (this.binId) {
            let bin = this.bins.bins.filter((bin) => bin.id === this.binId);
            this.binName = bin[0].name;
        }
        this.translationService
            .get(['add_to_bin.items_were_added_to_bin', 'add_to_bin.items_not_added_to_bin'])
            .then((translations) => {
                this.translationValues = { bin: this.binName, translations };
            })
            .then((data) => {
                this.binTagPackageResource
                    .addBinTagPackage(binId, this.epcs)
                    .then((data) => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'add_to_bin.items_were_added_to_bin',
                            params: this.translationValues,
                        });
                        this.$state.go('home', {
                            transferredMessage: this.binName,
                            newVerification: 'scan-details',
                        });
                    })
                    .catch((err) => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'add_to_bin.items_not_added_to_bin',
                            params: this.translationValues,
                        });
                    });
            });
    }

    removeFromFridge() {
        const title = this.translationService.instant('modals.start_expiration.remove_from_fridge');
        const detail = this.translationService.instant('modals.start_expiration.confirm_remove_from');

        const modalDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: { title: title, detail: detail, okButton: title },
        });

        modalDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                let updatePromises = [];
                this.packages.forEach((p) => {
                    let data: any = {
                        id: p.epc,
                    };

                    data.expiration_fridge = moment().add(p.expiring_fridge_days, 'days');
                    updatePromises.push(this.itemResource.updateItem(data));
                });

                Promise.all(updatePromises).then(() => {
                    let msg = this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'modals.start_expiration.expiration_date_set',
                    });
                    msg.afterOpened().subscribe(() => {
                        window.location.reload();
                    });
                });
                return;
            }
        });
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    goBack() {
        this.$state.go('home');
    }
}
