import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-mat-stepper-next',
    templateUrl: './kc-mat-stepper-next.html',
    styleUrls: ['./kc-mat-stepper-next.scss'],
})
export class KCMatStepperNext {
    @Input() disabled: boolean;
    @Input() label: string;

    constructor() {}

    ngOnInit() {
        this.label = this.label || 'buttons.next';
    }
}
