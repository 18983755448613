import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HardwareResource } from '@resources/hardware-resource.service';

@Injectable({ providedIn: 'root' })
export class SingleHardwareResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareResource: HardwareResource
    ) {}

    resolve(): Promise<any> {
        const hardwarePromise = this.hardwareResource
            .hardwareData(this.$state.transition.params().hardwareId)
            .then(({ hardware }) => {
                return hardware;
            });
        return this.loadingSpinnerService.spinnerifyPromise(hardwarePromise);
    }
}
