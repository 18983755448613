import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';
import { HospitalInfoService } from '@services/core/hospital-info.service';

interface ApiData {
    price_type: string; // wac or awp
}

@Component({
    selector: 'price-type-filter',
    templateUrl: './price-type-filter.html',
    styleUrls: ['./price-type-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: PriceTypeFilter }],
})
export class PriceTypeFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changePriceType = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    protected PRICE_TYPES: string[] = ['wac', 'awp'];

    constructor(private hospitalInfoService: HospitalInfoService) {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.price_type;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterPriceType(this.filterData);
        } else {
            this.reset();
        }
    }

    filterPriceType(event): void {
        this.filterData = event;
        if (!!this.reportSubscription) {
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            this.changePriceType.emit(this.filterData);
        }
        this.incomplete.emit({ filter: 'priceType', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = 'wac';
        this.changePriceType.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        return [{ price_type: this.filterData }];
    }
}
