import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { PreTaggedMedicationsResource } from '@resources/pre-tagged-medications-resource.service';

@Injectable({ providedIn: 'root' })
export class PreTaggedMedicationsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private preTaggedMedicationsResource: PreTaggedMedicationsResource
    ) {}

    resolve(): Promise<any> {
        const preTaggedMedicationsPromise = this.preTaggedMedicationsResource.pretaggedUsageSummary();

        return this.loadingSpinnerService.spinnerifyPromise(preTaggedMedicationsPromise);
    }
}
