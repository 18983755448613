import { Injectable } from '@angular/core';
import { MotorolaService } from '@services/hardware/motorola.service';
import { ThingMagicService } from '@services/hardware/thing-magic.service';
import { ZebraService } from '@services/hardware/zebra.service';
import { Scanner } from '@models/hardware/scanner';
import { UniversalRfidReaderInterfaceService } from './universal-rfid-reader-interface.service';

export const ZEBRA_FX_9600 = '9600';
export const IMPINJ_R420 = 'R420';
export const THING_MAGIC_MAKE = 'ThingMagic';
const ZEBRA_FX_7500 = '7500';
const MOTO_FX9500 = '9500';

@Injectable()
export class ScannerService {
    constructor(
        private thingMagicService: ThingMagicService,
        private motorolaService: MotorolaService,
        private zebraService: ZebraService,
        private universalRfidReaderInterfaceService: UniversalRfidReaderInterfaceService
    ) {}

    aeroscoutLogin(scanner: Scanner, aeroscoutHostUrl: string, authorizationHeader: any): Promise<any> {
        return this.getModelService(scanner).aeroscoutLogin(scanner, aeroscoutHostUrl, authorizationHeader);
    }

    aeroscoutSearch(
        scanner: Scanner,
        aeroscoutHostUrl: string,
        authorizationHeader: any,
        assetName: string
    ): Promise<any> {
        return this.getModelService(scanner).aeroscoutSearch(scanner, aeroscoutHostUrl, authorizationHeader, assetName);
    }

    checkStatus(scanner: Scanner): Promise<any> {
        return this.getModelService(scanner).checkStatus(scanner);
    }

    scan(scanner: Scanner): Promise<any> {
        const service: any = this.getModelService(scanner);
        return service.scan(scanner).then((scanResult) => {
            service.updateHardwareConfig(scanner).catch((e) => {
                // ignore any errors from scanner settings sync
                // so that we dont disrupt the scan
            });
            return scanResult;
        });
    }

    updateFirmware(scanner: Scanner): Promise<any> {
        return this.getModelService(scanner).updateFirmware(scanner);
    }

    updateSSL(scanner: Scanner): Promise<any> {
        return this.getModelService(scanner).updateSSL(scanner);
    }

    isThingMagic(scanner: Scanner) {
        return scanner?.make === THING_MAGIC_MAKE;
    }

    isZebraFx(scanner: Scanner) {
        // the fx9500 uses different OS
        // so we treat the 9600 & 7500 differently
        return scanner?.model === ZEBRA_FX_9600 || scanner?.model === ZEBRA_FX_7500;
    }

    isURRI(scanner: Scanner) {
        return scanner?.model === IMPINJ_R420;
    }

    private getModelService(scanner: Scanner): any {
        if (this.isThingMagic(scanner)) {
            return this.thingMagicService;
        } else if (this.isZebraFx(scanner)) {
            return this.zebraService;
        } else if (this.isURRI(scanner)) {
            return this.universalRfidReaderInterfaceService;
        } else {
            return this.motorolaService;
        }
    }
}
