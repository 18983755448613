import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';
import { ScanData } from '@models/core/scan';

@Injectable()
export class ScanningResource {
    private API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    sendScanData(scanData: ScanData): Promise<any> {
        return this.http.post(this.API_ENDPOINT + 'scans', scanData).toPromise();
    }
}
