import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { NaturalSortService, SortDirection } from '@services/utils/natural-sort.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { SegmentResource } from '@resources/segment-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    DEFAULT_SHOW_FIRST_LAST_BUTTONS,
} from '@models/common/report-pagination';

@Component({
    selector: 'shortage-management-page',
    templateUrl: './shortage-management-page.html',
    styleUrls: ['./shortage-management-page.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ShortageManagementPage implements OnInit, AfterViewInit {
    // Bindings from UI-Router Resolvers
    @Input() kitMasters;
    @Input() segments;

    // Member Vars
    filterOptionsKitMasters;
    filteredSegments;
    links: Array<[string, string]> = [
        ['shortage_report.shortage_summary', 'shortageReports'],
        ['shortage_report.shortage_management', 'shortageManagement'],
    ];
    activeLink: string[];

    filter = {
        segmentSearch: '',
        selectedKitMasters: [],
    };

    // translations added in the ngOnInit
    dropdownSettings = {
        singleSelection: false,
        text: 'Select Kit Masters',
        selectAllText: 'Select Shown Kit Masters',
        unSelectAllText: 'Clear All Kit Masters',
        enableSearchFilter: true,
        classes: 'kit-master-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Kit Masters Selected',
        objectTypeName: 'Kit Master',
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name', 'kit_master_name', 'shortage'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        private segmentResource: SegmentResource,
        private stateService: StateService,
        private transition: Transition,
        private translationService: TranslationService
    ) {
        productModuleService.setModule(ModuleTypes.INVENTORY);

        this.activeLink = this.links[1];

        this.translationService
            .get([
                'carts.multiselect.kitmaster_text',
                'carts.multiselect.kitmaster_selectAllText',
                'carts.multiselect.kitmaster_unSelectAllText',
                'carts.multiselect.kitmaster_searchPlaceholderText',
            ])
            .then((translations) => {
                this.dropdownSettings.text = translations['carts.multiselect.kitmaster_text'];
                this.dropdownSettings.selectAllText = translations['carts.multiselect.kitmaster_selectAllText'];
                this.dropdownSettings.unSelectAllText = translations['carts.multiselect.kitmaster_unSelectAllText'];
                this.dropdownSettings.searchPlaceholderText =
                    translations['carts.multiselect.kitmaster_searchPlaceholderText'];
            });
    }

    ngOnInit() {
        this.filterOptionsKitMasters = NaturalSortService.sort(this.kitMasters, 'name', SortDirection.asc);
        this.filter.selectedKitMasters = _.cloneDeep(this.filterOptionsKitMasters);
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.filterSegments();
    }

    filterSegmentName(value: string): void {
        this.dataSource.filter = value;
    }

    filterSegments() {
        const selectedKitMasterIds = this.filter.selectedKitMasters.map((kitMaster) => kitMaster.id);
        this.filteredSegments = this.segments.filter((segment) => selectedKitMasterIds.includes(segment.kit_master_id));
        this.dataSource.data = this.filteredSegments;
    }

    toggle(segment: any): void {
        segment.expanded = !segment.expanded;
    }

    toggleShortage(segment: any): void {
        segment.shortage = !segment.shortage;
        this.segmentResource.updateSegment(segment.id, { shortage: segment.shortage }).catch(() => {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                key: 'shortage_management.failure_msg',
            });
            segment.shortage = !segment.shortage;
        });
    }
}
