import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { NaturalSortService, SortDirection } from '@services/utils/natural-sort.service';
import { KitResource } from '@resources/kit-resource.service';
import { Kit } from '@models/core/kit';
import { isEmpty } from '@utils/objects';

interface ApiData {
    kit_ids: number; // 1 kit id (called kitLabel in the report filter)
    kit_master_id: number; // 1 kit master id
}

interface FilterData {
    kitLabel?: number;
    kitMaster?: string | number;
}

@Component({
    selector: 'kit-label-filter',
    templateUrl: './kit-label-filter.html',
    styleUrls: ['./kit-label-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: KitLabelFilter }],
})
export class KitLabelFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: FilterData; // hash from report-filter
    @Input() kits: Kit[];
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeKitInfo = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    kitsByPhysicalLabel: Array<any>;
    kitsList: Kit[];
    loading: boolean = false; // loading spinner for kits list display

    constructor(protected kitResource: KitResource) {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = {
                kitLabel: this.apiSubscriptionData.kit_ids,
                kitMaster: this.apiSubscriptionData.kit_master_id,
            };
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected data to persist from report page
            this.emitFilters();
        } else {
            this.reset();
        }
        if (isEmpty(this.kits)) {
            this.kitsList = [];
            this.getKitIdsList();
        } else {
            //for reportSusbcription modal, kits list may be passed in
            this.kitsList = this.kits;
            this.filterKitIds();
        }
    }

    getKitIdsList(): void {
        // don't display loading spinner on report-filter header
        if (!!this.reportSubscription) {
            this.loading = true;
        }
        this.kitResource.kitList().then((data: any) => {
            this.kitsList = data.kits;
            this.kitsByPhysicalLabel = [];
            this.filterKitIds();
            this.loading = false;
        });
    }

    filterKitIds(kitMasterId?: number): void {
        if (!!this.filterData.kitMaster) {
            let kitsForKitMaster = this.kitsList.filter((kit) => kit.kit_master.id === this.filterData.kitMaster);
            let nonUnique = kitsForKitMaster.map((kit) => kit.physical_label);
            let physicalLabels = Array.from(new Set(nonUnique));

            this.kitsByPhysicalLabel = physicalLabels.map((physicalLabel) => {
                let kits = kitsForKitMaster.filter((kit) => kit.physical_label === physicalLabel); //for each physical label for the selected kitMaster, we want to find the list of kits
                let kitIds = kits.map((kit) => kit.id).join('|');
                return { physical_label: physicalLabel, kit_ids: kitIds };
            });
            this.kitsByPhysicalLabel = NaturalSortService.sort(
                this.kitsByPhysicalLabel,
                'physical_label',
                SortDirection.asc
            );
        } else {
            this.filterData.kitLabel = null; //Reset kit label dropdown to 'All' when changing kitMaster dropdown to 'All'
            this.kitsByPhysicalLabel = [];
        }
    }

    isKitLabelDisabled(): boolean {
        return this.filterData.kitMaster === 'all' || !this.filterData.kitMaster;
    }

    filterKitLabel(): void {
        this.emitFilters();
    }

    reset(): void {
        this.filterData = { kitMaster: null, kitLabel: null };
        this.changeKitInfo.emit(this.filterData);
    }

    filterKitMaster(data): void {
        this.filterData.kitMaster = data;
        this.filterKitIds();
        this.emitFilters();
    }

    emitFilters(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeKitInfo.emit(this.filterData);
        this.incomplete.emit({
            filter: 'kitLabel',
            incomplete: isEmpty(this.filterData.kitLabel) || isEmpty(this.filterData.kitMaster),
        });
    }

    formatForApi(): any {
        return [{ kit_ids: this.filterData.kitLabel }, { kit_master_id: this.filterData.kitMaster }];
    }
}
