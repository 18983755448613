import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class ReportingResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    bedCounts(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bed_counts`).toPromise();
    }

    advancedSegmentOptimization(data): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}reports/advanced_segment_optimization`, { params: data })
            .toPromise();
    }

    expirationAnalytics(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/expiration_analytics`, { params: data }).toPromise();
    }

    kittedInventoryWithCost(data = {}): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}reports/kitted_inventory_with_cost`, { params: data })
            .toPromise();
    }

    customerSnapshot(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/customer_snapshot`, { params: data }).toPromise();
    }

    activityAnalytics(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/activity_analytics`, { params: data }).toPromise();
    }

    itemsConsumedRemoved(data, isSystem = false): Promise<any> {
        if (isSystem) {
            return this.http
                .get<any>(`${this.API_ENDPOINT}reports/system_inferred_items_consumed_removed`, { params: data })
                .toPromise();
        } else {
            return this.http
                .get<any>(`${this.API_ENDPOINT}reports/inferred_items_consumed_removed`, { params: data })
                .toPromise();
        }
    }
}
