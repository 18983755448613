import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DecommissionResource } from '@resources/decommission-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

import { Package } from '@models/core/package';

interface ParamData {
    packages: Package[];
    reason?: Reason;
}

interface Reason {
    id: number;
    name: string;
    display_name: string;
}

@Component({
    selector: 'decommission-packages-dialog',
    templateUrl: './decommission-packages-dialog.html',
    styleUrls: ['./decommission-packages-dialog.scss'],
})
export class DecommissionPackagesDialog {
    packages: Package[];
    reason: Reason;
    reasons: Reason[];
    modalInstance: any;

    prepopulated: boolean;
    submitting: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DecommissionPackagesDialog>,
        protected decommissionResource: DecommissionResource,
        private loadingSpinnerService: LoadingSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.packages = this.data.packages;
        this.reason = this.data.reason;
        this.prepopulated = !!this.reason;

        if (!this.prepopulated) {
            let promise = this.decommissionResource
                .getDecommissionReasons()
                .then((data) => (this.reasons = data.decommission_reasons));
            this.loadingSpinnerService.spinnerifyPromise(promise);
        }
    }

    submit(): void {
        this.submitting = true;
        const packageIds = this.packages.map((pkg) => pkg.id);
        this.decommissionResource
            .decommissionPackages(packageIds, this.reason.id)
            .then(() => {
                this.dialogRef.close(this.reason);
            })
            .catch(() => {
                this.submitting = false;
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    invalidReason(): boolean {
        return !this.reason || (!this.prepopulated && !this.reasons.find((reason) => reason.id === this.reason.id));
    }
}
