import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-logo',
    templateUrl: './kc-logo.html',
})
export class KCLogo {
    @Input() width: number = 150;
    @Input() height: number = 24;
}
