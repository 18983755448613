import { Component, Input } from '@angular/core';
import { TableComponent } from '@components/common/table-component';

@Component({
    selector: 'bin-custom-items',
    templateUrl: './bin-custom-items.html',
})
export class BinCustomItems extends TableComponent {
    constructor() {
        super();
    }

    sortCustomItems(field: string): void {
        this.sortBy(field, this.items);
    }
}
