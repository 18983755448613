import { getNestedValue } from './objects';

/**
 * Sorting Utilities
 **/

export enum Direction {
    ASC,
    DESC,
}

/**
 * Sorts an array of objects with string values used by the orderBy pipe.
 * should probably add ability to sort by non-string values as well.
 * @param data Array of deep Objects
 * @param propName String of properties, concatenated with '.'
 * @param direction True for Asc?
 */
export function sortDeep(data: any[], propName: string, direction: Direction = Direction.ASC) {
    if (!data) return [];

    return data.sort((a, b) => {
        let aProp = getNestedValue(a, propName) || '';
        let bProp = getNestedValue(b, propName) || '';

        if (typeof aProp === 'string' && typeof bProp === 'string') {
            aProp = aProp.trim().toLowerCase();
            bProp = bProp.trim().toLowerCase();
        }

        if (aProp === bProp) {
            return 0;
        }
        const val = direction === Direction.ASC ? 1 : -1;

        return aProp > bProp ? val : -val;
    });
}

/**
 * Replacement for _.sortBy
 */

const sortFunc = (key) => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export function sortObjArray(objects: any[], key: string) {
    return objects.concat().sort(sortFunc(key));
}
