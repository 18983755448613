import { Component, Input, OnInit } from '@angular/core';
import { arrayUnique } from '@utils/objects';

@Component({
    selector: 'charge-sheet-template-segment-summary',
    templateUrl: './charge-sheet-template-segment-summary.html',
})
export class ChargeSheetTemplateSegmentSummary {
    @Input() chargeData;
    @Input() kitSummary;
    @Input() largeFont: boolean = false;
    @Input() showBarCode: boolean = false;
    @Input() showBillingCode: boolean = true;
    @Input() showControlledSumary: boolean = false;
    @Input() showExpiringSoon: boolean = false;
    @Input() showLot: boolean = false;
    @Input() showNdc: boolean = false;
    @Input() showSupplies: boolean = false;
    @Input() showUsed: boolean = true;
    @Input() summaryHeaderKey: string = 'charge_sheets.segment';

    constructor() {}

    ngOnInit() {
        if (this.showLot) {
            this.chargeData.segments.forEach((segment) => {
                let lot_numbers = [];
                lot_numbers.push(segment.firstItem?.lot_num);

                segment.items.forEach((item) => {
                    lot_numbers.push(item.lot_num);
                });
                segment.extra.forEach((extra) => {
                    lot_numbers.push(extra.lot_num);
                });
                segment['lot_numbers'] = _.uniq(lot_numbers).join(', ');
            });
        }
    }

    showSegmentInSummary(segment) {
        return (
            (!this.showSupplies && !this.showControlledSumary) ||
            (this.showSupplies && (segment.formulary_segment || segment.expected)) ||
            (this.showControlledSumary && segment.name.lastIndexOf('(CS)') === -1)
        );
    }

    formatSegmentName(name) {
        return name.substring(name.lastIndexOf('(') + 1, name.lastIndexOf(')'));
    }
}
