import { Component, Input } from '@angular/core';

@Component({
    selector: 'idn-group-dropdown',
    templateUrl: './idn-group-dropdown.html',
})
export class IdnGroupDropdown {
    @Input() selectedGroup: string;
    @Input() idnGroup: any;
    @Input() toggleGroup: Function;

    constructor() {}

    idnGroupHas(groupType: string): boolean {
        if (!!this.idnGroup) {
            return !!this.idnGroup[groupType];
        }
        return false;
    }
}
