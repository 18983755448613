import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BannerService {
    //attributes
    systemCaution: string;
    systemCautionNotDismissable: boolean;
    systemCautionNephron: string;

    constructor() {}
}
