import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable({ providedIn: 'root' })
export class TagTypesResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    tagTypeList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}tag_types`).toPromise();
    }
}
