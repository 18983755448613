import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';

@Injectable({ providedIn: 'root' })
export class LocationsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource
    ) {}

    resolve(): Promise<any> {
        let locationsPromise;

        if (this.actionService.isAllowAction('hospital_settings', 'view_location', 'Load location list data')) {
            locationsPromise = this.locationResource.locationList(false).then((data) => {
                return data.locations;
            });
        } else {
            locationsPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(locationsPromise);
    }
}
