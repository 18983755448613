import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ISubscription } from 'rxjs/Subscription';

import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BadgeAuthorizationDialog } from '@dialogs/badge-authorization/badge-authorization-dialog';
import { BadgeInfo, BadgeAuthStatus } from '@models/core/badge-authorization';

const BADGE_TIMEOUT: number = 60000;

@Injectable()
export class BadgeAuthService {
    private badgeAuthStatusObserver: BehaviorSubject<BadgeAuthStatus> = new BehaviorSubject(null);
    private badgeInfo: BadgeInfo;

    private badgeAuthModal: any = null;
    private timeoutSubscription: ISubscription = null;

    constructor(
        private barcodeScanService: BarcodeScanService,
        private dialog: MatDialog,
        private zone: NgZone
    ) {}

    observeBadgeAuth(): Observable<BadgeAuthStatus> {
        return this.badgeAuthStatusObserver;
    }

    updateBadgeAuthStatus(badgeStatus: BadgeAuthStatus): void {
        this.badgeAuthStatusObserver.next(badgeStatus);
    }

    getBadgeCredentials(error: HttpErrorResponse): Observable<BadgeInfo> {
        if (!!this.badgeInfo) {
            return of(this.badgeInfo);
        }
        return this.openBadgeAuthorizationDialog(error);
    }

    getBadgeAuthHttpHeader(): string {
        return `Badge-Pin ${this.badgeInfo?.epc}:${this.badgeInfo?.pin}`;
    }

    clearAll(): void {
        this.badgeInfo = null;
        this.timeoutSubscription?.unsubscribe();
        this.timeoutSubscription = null;
        this.badgeAuthModal = null;
        this.barcodeScanService.removeListener('verify-badge-pin');
    }

    private setBadgeInfo(verifiedBadge: BadgeInfo): void {
        this.badgeInfo = verifiedBadge;
        this.startBadgeTimeout();
    }

    private startBadgeTimeout(): void {
        if (!!this.timeoutSubscription) {
            return;
        }
        this.timeoutSubscription = of(this.badgeInfo)
            .pipe(delay(BADGE_TIMEOUT))
            .subscribe(() => {
                this.clearAll();
            });
    }

    private openBadgeAuthorizationDialog(error: HttpErrorResponse): Observable<BadgeInfo> {
        if (!this.badgeAuthModal) {
            this.zone.run(() => {
                // explicitly run in ngZone so modal displays properly for motorola v1 scans
                window.scroll(0, 0); // scroll page to top to prevent blank screen behind modal
                this.updateBadgeAuthStatus({ isUserVerified: false, clearScanningDialog: false });
                this.badgeAuthModal = this.dialog.open(BadgeAuthorizationDialog, {
                    width: 'max-content',
                    height: 'max-content',
                    autoFocus: false,
                    disableClose: true,
                    closeOnNavigation: true,
                    data: {
                        httpRejection: error.error,
                    },
                });
            });
        }

        return this.badgeAuthModal.afterClosed().pipe(
            switchMap((result: BadgeInfo) => {
                if (!!result) {
                    this.setBadgeInfo(result);
                }
                this.updateBadgeAuthStatus({ isUserVerified: !!result, clearScanningDialog: true });
                this.badgeAuthModal = null;
                return of(result);
            })
        );
    }
}
