import { Component, Input, OnInit } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { KitSummary, ScanWarning } from '@models/core/kit-summary';
import { buildScanWarningList } from '@utils/kit-summary-util';

@Component({
    selector: 'mobile-cart-scan-warnings',
    templateUrl: './scan-warnings.html',
    styleUrls: ['./scan-warnings.scss'],
})
export class MobileCartScanWarningsComponent implements OnInit {
    @Input() kitSummary: KitSummary;

    warningList: ScanWarning[] = [];

    unassociatedTags: any[] = [];

    constructor(private actionService: ActionService) {}

    ngOnInit() {
        this.warningList = buildScanWarningList(
            this.kitSummary,
            this.actionService.isAllowAction('hospital_settings', 'delete_formulary_item', 'Unarchive an item')
        );
    }
}
