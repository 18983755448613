import { Component, OnInit } from '@angular/core';
import { ProductModuleService } from '@services/core/product-module.service';

@Component({
    selector: 'nav-menu',
    templateUrl: './nav-menu.html',
    styleUrls: ['./nav-menu.scss'],
})
export class NavMenu implements OnInit {
    appModules: any;

    constructor(private productModuleService: ProductModuleService) {}

    ngOnInit() {
        this.productModuleService.observeModules().subscribe((modules) => {
            this.appModules = modules || [];
        });
    }
}
