import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Scanner } from '@models/hardware/scanner';
import { FileUploadService } from '@services/utils/file-upload.service';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable()
export class ZebraResource {
    constructor(
        private fileUploadService: FileUploadService,
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    private fx9600Token() {
        return this.localStorageService.get('zat');
    }

    private headers() {
        /* eslint-disable */
        return { Authorization: `${this.fx9600Token()}` };
        /* eslint-enable */
    }

    scan(scanner: Scanner): Promise<any> {
        let scanUrl = `${scanner.value}/${scanner.hardware_settings.scan_endpoint}`;
        let scanParams = this.scanParameters(scanner.hardware_settings);
        return this.http.get(scanUrl, { headers: this.headers(), params: scanParams }).toPromise();
    }

    firmwareVersionInfo(scannerUrl: String): Promise<any> {
        let fwInfoUrlExt = 'api/firmware/info';
        return this.http.get(`${scannerUrl}/${fwInfoUrlExt}`, { headers: this.headers() }).toPromise();
    }

    sendFirmwareUpdateFile(scannerUrl: string, file, fileName, scannerIp: string): Promise<any> {
        let url = `${scannerUrl}/api/firmware/update`;
        return this.fileUploadService.uploadArrayBuffer(url, file, fileName, 'fw', this.headers(), {
            name: 'scannerIp',
            value: scannerIp,
        });
    }

    getFirmwareConfig(scanner: Scanner) {
        let url = `${scanner.value}/api/firmware/config`;
        return this.http.get(url, { headers: this.headers() }).toPromise();
    }

    private scanParameters(hardwareSettings: any): any {
        let params = {};
        let scannerProfileSettings = hardwareSettings.scanner_settings_profile;
        params['duration'] = (!!hardwareSettings.scan_duration ? hardwareSettings.scan_duration : 4) * 1000.0;
        params['absolute_timeout'] =
            (!!hardwareSettings.absolute_timeout ? hardwareSettings.absolute_timeout : 20) * 1000.0;
        if (!!scannerProfileSettings.session) {
            params['session'] = scannerProfileSettings.session;
        }
        if (!!scannerProfileSettings.power) {
            // kc-api stores power in dBm, TM scanners want it in cdBm:
            params['power'] = scannerProfileSettings.power * 100.0;
        }
        if (!!scannerProfileSettings.link_frequency) {
            // kc-api stores this in kHz
            params['link_frequency'] = scannerProfileSettings.link_frequency;
        }
        if (!!scannerProfileSettings.encoding) {
            params['encoding'] = scannerProfileSettings.encoding;
        }
        if (!!scannerProfileSettings.q_value) {
            params['q_value'] = scannerProfileSettings.q_value;
        }
        if (!!scannerProfileSettings.target) {
            params['target'] = scannerProfileSettings.target;
        }
        if (!!scannerProfileSettings.tari) {
            params['tari'] = scannerProfileSettings.tari;
        }
        return params;
    }
}
