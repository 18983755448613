import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { AutoComplete } from '@components/common/auto-complete/auto-complete';
import { isEmpty } from '@utils/objects';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export interface GenericNdcLotFilterData {
    generic: any;
    lot: string;
    ndc: string;
    type: string;
}

interface ApiData {
    clinical_equivalence: any;
    clinical_equivalence_id: number;
    ndc: string;
    lot_num: string;
}

const BLANK_FILTER_DATA: GenericNdcLotFilterData = Object.freeze({
    generic: {},
    lot: null,
    ndc: null,
    type: 'generic',
});

@Component({
    selector: 'generic-ndc-lot-search-filter',
    templateUrl: './generic-ndc-lot-search-filter.html',
    styleUrls: ['./generic-ndc-lot-search-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: GenericNdcLotSearchFilter }],
})
export class GenericNdcLotSearchFilter implements IFilter, AfterViewInit, OnInit {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: GenericNdcLotFilterData; // hash from report-filter
    @Input() lotSearchFilterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeGenericNdcLotSearch = new EventEmitter();
    @Output() changeLotSearch = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    @ViewChild(AutoComplete) autoComplete: AutoComplete;
    formattedNdc: string;
    customAutoCompleteForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private barcodeScanService: BarcodeScanService,
        private ndcScanUtilsService: NdcScanUtilsService
    ) {
        this.customAutoCompleteForm = this.formBuilder.group({
            ndc: new FormControl(''),
        });
    }

    ngOnInit() {
        this.setupListeners();

        if (!this.filterData) {
            this.filterData = Object.assign({}, BLANK_FILTER_DATA);
        }

        if (!!this.apiSubscriptionData) {
            this.filterData.lot = this.apiSubscriptionData.lot_num;
            if (!!this.apiSubscriptionData.clinical_equivalence_id) {
                this.filterData.generic = this.apiSubscriptionData.clinical_equivalence;
                this.filterData.type = 'generic';
                this.filterData.ndc = null;
            } else {
                this.filterData.generic = {};
                this.filterData.type = 'ndc';
                this.filterData.ndc = this.apiSubscriptionData.ndc;
            }
        }

        this.customAutoCompleteForm.setValue({ ndc: this.filterData.ndc });
    }

    ngAfterViewInit(): void {
        if (!!this.reportSubscription) {
            this.emitFilters();
        } else {
            this.reset();
        }
    }

    setupListeners() {
        this.barcodeScanService.registerListener((scanData) => {
            const ndcInput = document.getElementById('ndc-field');
            if (document.activeElement === ndcInput) {
                const formattedNdc = this.ndcScanUtilsService.extractNDCFromScan(scanData);
                this.customAutoCompleteForm.setValue({ ndc: formattedNdc });
            }
        }, 'setupNdcForGenericNdcLotReport');
    }

    reset(): void {
        this.filterData = Object.assign({}, BLANK_FILTER_DATA);
        this.changeGenericNdcLotSearch.emit(this.filterData);
        this.autoComplete.resetSelection();
    }

    filterNdc(data?): void {
        if (typeof data === 'string') {
            //in the case of typed NDC not from dropdown, when NDC is invalid
            this.filterData.ndc = data;
        } else {
            this.filterData.ndc = !!data && data.ndc; //in the case of NDC selected from dropdown
        }
        this.filterGenericNdcLot();
    }

    filterGeneric(data?): void {
        this.filterData.generic = data;
        this.filterGenericNdcLot();
    }

    filterGenericNdcLot(): void {
        if (this.filterData.type === 'ndc') {
            this.filterData.generic = {};
        } else {
            this.autoComplete.resetSelection();
            this.filterData.ndc = null;
        }
        this.emitFilters();
    }

    emitFilters(): void {
        this.changeGenericNdcLotSearch.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());

        if (this.filterData.type === 'ndc') {
            this.incomplete.emit({
                filter: 'genericNdcLotSearch',
                incomplete: isEmpty(this.filterData.ndc) || isEmpty(this.filterData.lot),
            });
        } else {
            this.incomplete.emit({
                filter: 'genericNdcLotSearch',
                incomplete: isEmpty(this.filterData.generic) || isEmpty(this.filterData.lot),
            });
        }
    }

    formatForApi(): Array<any> {
        if (this.filterData.type === 'ndc') {
            return [{ ndc: this.filterData.ndc }, { lot_num: this.filterData.lot }, { clinical_equivalence_id: null }];
        } else {
            return [
                { clinical_equivalence_id: this.filterData.generic.clinical_equivalence_id },
                { lot_num: this.filterData.lot },
            ];
        }
    }
}
