import { Injectable } from '@angular/core';

import { TranslationService } from '@services/utils/translation.service';
@Injectable()
export class ParseFormErrorsService {
    constructor(private translationService: TranslationService) {}

    parseErrors(errors): string {
        return Object.keys(errors)
            .map((err) => {
                let result: string;
                switch (err) {
                    case 'required':
                        result = this.translationService.instant('errors.required');
                        break;
                    case 'minlength':
                        result = this.translationService.instant('errors.min_characters', {
                            min: errors[err].requiredLength,
                        });
                        break;
                    case 'min':
                        result = this.translationService.instant('errors.min_number', { min: errors[err].min });
                        break;
                    case 'max':
                        result = this.translationService.instant('errors.max_number', { max: errors[err].max });
                        break;
                    case 'onlydigits':
                        result = this.translationService.instant('errors.onlydigits');
                        break;
                    case 'nonpastdate':
                        result = this.translationService.instant('errors.non_past_date');
                        break;
                    case 'unique':
                        result = this.translationService.instant('errors.must_be_unique', { type: errors[err] });
                        break;
                    case 'nonblank':
                        result = this.translationService.instant('errors.nonblank', { type: errors[err] });
                        break;
                    case 'matDatepickerMin':
                        result = this.translationService.instant('errors.non_past_date', { type: errors[err] });
                        break;
                    case 'matDatepickerParse':
                        result = this.translationService.instant('errors.required');
                        break;
                    default:
                        result = err;
                }

                return result;
            })
            .join(' ');
    }
}
