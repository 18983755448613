import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PreTaggedMedicationsResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    pretaggedUsageSummary(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}charge_model/usage_summary`).toPromise();
    }

    addCredits(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}charge_model/credits`, data).toPromise();
    }

    setThreshold(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}charge_model/credits/set_threshold`, data).toPromise();
    }
}
