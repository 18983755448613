import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-wrong-items',
    templateUrl: './charge-sheet-template-wrong-items.html',
})
export class ChargeSheetTemplateWrongItems {
    @Input() chargeData;
    @Input() kitSummary;

    constructor() {}

    ngOnInit() {}
}
