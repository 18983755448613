import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';
import { LocalStorageService as NgLocalStorageService } from 'angular-2-local-storage';
import * as CryptoJS from 'crypto-js';

/**
 * Tie over class dealing with upgrade transition so calls are all in one place
 */
@Injectable()
export class LocalStorageService {
    static readonly PREFIX = 'kcui';

    constructor(
        private ngLocalStorageService: NgLocalStorageService,
        private configuration: ConfigurationProvider
    ) {}

    get(itemName: string) {
        const value = localStorage.getItem(`${LocalStorageService.PREFIX}.${itemName}`);
        if (!value) {
            return value;
        }

        if (value === 'null') {
            return null;
        }

        let decryptedValue;
        let parsedValue;

        try {
            decryptedValue = CryptoJS.AES.decrypt(
                value,
                this.configuration.getConfiguration().local_storage_encryption_code
            ).toString(CryptoJS.enc.Utf8);
        } catch (e) {
            decryptedValue = value;
        }

        try {
            if (['"', '{', '['].includes(decryptedValue.charAt(0))) {
                return JSON.parse(decryptedValue);
            }

            parsedValue = decryptedValue;
        } catch (e) {
            parsedValue = decryptedValue;
        }

        return parsedValue;
    }

    set(itemName: string, value: any) {
        try {
            const stringifiedValue =
                typeof value === 'string' || value instanceof String ? value : JSON.stringify(value);

            const encryptedValue = CryptoJS.AES.encrypt(
                stringifiedValue,
                this.configuration.getConfiguration().local_storage_encryption_code
            );
            localStorage.setItem(`${LocalStorageService.PREFIX}.${itemName}`, encryptedValue.toString());
        } catch (e) {
            throw new Error(`Error when setting key: ${e}`);
        }
    }

    remove(itemName: string) {
        localStorage.removeItem(`${LocalStorageService.PREFIX}.${itemName}`);
    }

    clearAll(itemMatch: string) {
        this.ngLocalStorageService.clearAll(itemMatch);
    }
}
