import { AbsoluteVal } from './absolute-val.pipe';
import { BreakArrayPipe } from './break-array.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { DayDiffPipe } from './day-diff.pipe';
import { EpcPipe } from './epc.pipe';
import { LastPipe } from './last.pipe';
import { LongUsernamePipe } from './long-username.pipe';
import { MomentFormatPipe } from './moment-format.pipe';
import { OrderByPipe } from './order-by.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { PackageLongName } from './package-long-name';
import { PackageLongDescription } from './package-long-description';
import { UomScalarPipe } from './uom-scalar.pipe';

export const Pipes = [
    AbsoluteVal,
    BreakArrayPipe,
    CapitalizePipe,
    DayDiffPipe,
    EpcPipe,
    LastPipe,
    LongUsernamePipe,
    MomentFormatPipe,
    OrderByPipe,
    OrdinalPipe,
    PackageLongName,
    PackageLongDescription,
    UomScalarPipe,
];
