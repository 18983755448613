import { Component, Input, OnInit } from '@angular/core';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { Package } from '@models/core/package';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { TranslationService } from '@services/utils/translation.service';
import { isAppUnderGracePeriodState, isAppUnderPostLockoutState } from '@utils/charge-model-util';

import * as moment from 'moment';

export interface EligibleItem {
    itemName: string;
    manufacturerName: string;
    epc: string;
}

@Component({
    selector: 'kc-lockout-date-banner',
    templateUrl: './lockout-date-banner.component.html',
    styleUrls: ['./lockout-date-banner.component.scss'],
})
export class LockoutDateBannerComponent implements OnInit {
    readonly displayedColumns: string[] = ['name', 'epc'];
    chargeModelType: ChargeModelTypeName;
    lockoutDate: string;
    lockoutBannerTitle: string;
    lockoutBannerDescription: string;

    @Input() eligibleItems: Package[];
    @Input() singleItemBanner: boolean;

    constructor(
        private hospitalInfoService: HospitalInfoService,
        private translationService: TranslationService
    ) {}

    get shouldShowLockoutBanner() {
        return (
            isAppUnderGracePeriodState(this.lockoutDate, this.chargeModelType) &&
            (this.eligibleItems?.length > 0 || this.singleItemBanner)
        );
    }

    get isPostLockoutAppState() {
        return isAppUnderPostLockoutState(this.lockoutDate, this.chargeModelType);
    }

    get formattedLockoutDate() {
        return moment(this.lockoutDate).format('MM/DD/YYYY');
    }

    get remainingDays() {
        if (this.isPostLockoutAppState) {
            return;
        }

        const eventDate = moment(this.lockoutDate);
        const todaysDate = moment();
        return eventDate.diff(todaysDate, 'days');
    }

    get lowRemainingDays() {
        return this.remainingDays <= 5;
    }

    get multiItems() {
        return this.eligibleItems?.length > 0;
    }

    ngOnInit(): void {
        this.lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.chargeModelType = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;

        this.setupBannerTemplate();
    }

    setupBannerTemplate() {
        if (this.multiItems) {
            return this.setupMultipleItemsBanner();
        }

        return this.setupSingleItemBanner();
    }

    setupMultipleItemsBanner() {
        if (this.isPostLockoutAppState) {
            this.lockoutBannerTitle = this.translationService.instant('post_lockout_date_banner.banner_title', {
                items: this.eligibleItems.length,
            });
            this.lockoutBannerDescription = this.translationService.instant('post_lockout_date_banner.description', {
                date: this.formattedLockoutDate,
            });
        } else {
            this.lockoutBannerTitle = this.translationService.instant('lockout_date_banner.banner_title', {
                items: this.eligibleItems.length,
            });
            this.lockoutBannerDescription = this.translationService.instant('lockout_date_banner.description', {
                date: this.formattedLockoutDate,
            });
        }
    }

    setupSingleItemBanner() {
        if (this.isPostLockoutAppState) {
            this.lockoutBannerTitle = this.translationService.instant('post_lockout_date_banner.banner_title_single');
            this.lockoutBannerDescription = this.translationService.instant(
                'post_lockout_date_banner.description_single',
                { date: this.formattedLockoutDate }
            );
        } else {
            this.lockoutBannerTitle = this.translationService.instant('lockout_date_banner.banner_title_single');
            this.lockoutBannerDescription = this.translationService.instant('lockout_date_banner.description_single', {
                date: this.formattedLockoutDate,
            });
        }
    }
}
