import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class NdcScanUtilsService {
    extractNDCFromScan(input: any): string {
        if (_.get(input, 'type')) {
            return _.get(input, 'ndc');
        }

        // 0100 3 2068001234 5
        const matches = `${input}`.match(/^(?:0100|0110)?3(\d{10})(\d)$/);
        if (matches) {
            return matches[1];
        } else {
            return input;
        }
    }
}
