import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-header',
    templateUrl: './charge-sheet-template-header.html',
    styleUrls: ['./charge-sheet-template-header.scss'],
})
export class ChargeSheetTemplateHeader {
    @Input() chargeData;
    @Input() kitSummary;
    @Input() hospitalLogoSource;
    @Input() patientPlateClass: string = null;
    @Input() showKitCheckLogo: boolean = true;
    @Input() showCartInHeader: boolean = false;
    @Input() showLocationInHeader: boolean = false;
    @Input() showHospitalHeaderLogo: boolean = false;
    @Input() swapLogoAndPatientPlate: boolean = false;

    useHospitalLogo: boolean = false;

    constructor() {}

    ngOnInit() {
        // use the logo on the hospital if they have one
        if (!!this.kitSummary.kit_master.hospital_logo) {
            this.showHospitalHeaderLogo = true;
            this.showKitCheckLogo = false;
            this.hospitalLogoSource = this.kitSummary.kit_master.hospital_logo;
        }
    }
}
