import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';
import { isEmpty } from '@utils/objects';
import { sortObjArray } from '@utils/sort';

interface ApiData {
    locations: string; // a | delimited list of locations
    include_carts_with_no_location: boolean;
}

interface FilterData {
    includeEmpty?: boolean;
    locations?: Location[]; // this may be an array of a hash with just ids, or an array of the locations.
}

interface Location {
    barcode?: string;
    deleted_at?: string;
    id: number;
    name?: string;
    type?: string;
}

@Component({
    selector: 'cart-locations-filter',
    templateUrl: './cart-locations-filter.html',
    styleUrls: ['./cart-locations-filter.scss'],
})
export class CartLocationsFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: FilterData; // hash from report-filter component
    @Input() maxHeight: number;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeFilterData = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    locationsList: Location[];

    dropdownSettings: any = {
        singleSelection: false,
        text: this.translationService.instant('carts.multiselect.location_text'),
        selectAllText: this.translationService.instant('carts.multiselect.location_selectAllText'),
        unSelectAllText: this.translationService.instant('carts.multiselect.location_unSelectAllText'),
        enableSearchFilter: true,
        classes: this.translationService.instant('carts.multiselect.classes'),
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: this.translationService.instant('carts.multiselect.location_searchPlaceholderText'),
        objectTypeName: 'Location',
    };

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.locationsList = [];
        if (!!this.apiSubscriptionData) {
            this.filterData = {};
            if (!!this.apiSubscriptionData.locations) {
                this.filterData.locations = this.apiSubscriptionData.locations.split('|').map((location) => {
                    return { id: Number(location) };
                });
            }
            this.filterData.includeEmpty = this.apiSubscriptionData.include_carts_with_no_location;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected values to persist from report page
            this.apiFormattedData.emit(this.formatForApi());
            this.changeFilterData.emit(this.filterData);
        } else {
            this.filterData = { locations: null };
        }

        this.getLocationsList(this.filterData.locations);
    }

    reset(): void {
        // reset to all
        this.filterData.locations = [...this.locationsList];
        this.changeFilterData.emit(this.filterData);
    }

    getLocationsList(listData): void {
        this.loadingSpinnerService.spinnerifyPromise(
            // Don't cache when getting shared kits
            this.locationResource.locationList(false).then((data) => {
                this.locationsList = sortObjArray(data.locations, 'name');
                if (isEmpty(this.filterData.locations)) {
                    this.filterData.locations = [...this.locationsList];
                } else {
                    this.filterData.locations = sortObjArray(listData, 'name');
                }
            })
        );
    }

    emitFilterData(): void {
        this.changeFilterData.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'cartLocations', incomplete: isEmpty(this.filterData.locations) });
    }

    formatForApi(): Array<any> {
        if (!!this.filterData.locations) {
            let locations = this.filterData.locations
                .map((location) => location.id)
                .filter((n) => n)
                .join('|');
            return [{ locations: locations }, { include_carts_with_no_location: this.filterData.includeEmpty }];
        }
    }
}
