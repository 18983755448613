import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { StateService } from '@uirouter/core';
import { MobileCartService } from '@services/utils/mobile-cart.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { BlockedKit } from '@models/core/kit';

@Component({
    selector: 'mobile-cart-kit-block',
    templateUrl: './kit-block.html',
    styleUrls: ['./kit-block.scss'],
})
export class MobileCartKitBlockComponent implements OnInit, AfterViewInit {
    @Input() blockedKit: BlockedKit;

    showEpcHelp: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['item_name', 'epc', 'lot_num'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private mobileCartService: MobileCartService,
        private $state: StateService
    ) {}

    ngOnInit() {
        if (!this.blockedKit || !this.blockedKit.kit || !this.blockedKit.untrusted_items) {
            this.$state.go('mobile-cart.landing');
        }
        this.mobileCartService.subheaderText = 'kit_block.title';
        this.mobileCartService.showScanButton = true;
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.blockedKit.untrusted_items);
        this.dataSource.sort = this.sort;
    }

    toggleEpcHelp(): void {
        this.showEpcHelp = !this.showEpcHelp;
    }
}
