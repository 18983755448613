export interface ReportFilters {}

export const getReportFilters = (reportType: string) => {
    // yeah. this sucks.
    // but need a way to get the list of filters to display in the subscription dialog
    // the filterData names do not necessarily match with the report filter component names or data structures
    // and for some reports, a full list of available filters are not returned if they are empty
    // (for example the ItemExpirations report does not return days_expiring_within unless it has been set)

    // this also lets us order the filters for display per report.

    // this list may get long, so please keep in alphabetical order

    // some helpful mapping info:
    // report_mode and kit_master_id == binKit
    // ndc and lot (separately) == ndcLotSearch or genericNDCLotSearch
    // kit_master_id == KitMaster

    // use kitMaster if you need a single select, kitMasters if you need multi-select

    let filters: string[] = [];
    switch (reportType) {
        case 'advanced_segment_optimization':
            filters = ['kitMasters'];
            break;

        case 'aggregate_billing':
            // No filters
            filters = ['kitMaster'];
            break;

        case 'carts':
            filters = ['expiringNoExpired', 'cartKitMasters', 'cartLocations'];
            break;

        case 'bins_report':
            // No Filters
            filters = [];
            break;

        case 'expiration_analytics':
            filters = ['priceType', 'removedReasons'];
            break;

        case 'hospital_activity':
            // No Filters
            filters = [];
            break;

        case 'item_expiration_and_attributes_custom_item':
            filters = ['expiration', 'binKitMasters', 'genericNdcLotSearch'];
            break;

        case 'item_expiration_and_attributes_ndc_lot':
            filters = ['binKit', 'ndcLotSearch'];
            break;

        case 'inferred_items_consumed_removed':
            filters = ['kitMasters', 'reasons'];
            break;

        case 'item_recalls':
            filters = ['binKit', 'recalls'];
            break;

        case 'items_tagged':
            // No Filters
            filters = [];
            break;

        case 'kit_activity':
            filters = ['kitInfo', 'activities'];
            break;

        case 'kitted_items_by_ndc':
            // No Filters
            filters = [];
            break;

        case 'kits_needing_return':
            //TODO add filters - this is a hidden report and we can't find it.
            filters = [];
            break;

        case 'kits_needing_rework':
            filters = ['kitMaster', 'timeframe', 'kitsNeedingRework'];
            break;

        case 'kitted_inventory_with_cost':
            filters = ['priceType', 'kitMasters'];
            break;

        case 'print_batch':
            filters = ['hospital_ownership'];
            break;

        case 'system_item_expiration_and_attributes_custom_item':
            filters = ['hospitalGroups', 'expiration', 'binKitMasters', 'genericNdcLotSearch'];
            break;

        case 'system_item_recalls':
            // currently does not have hospital group filter in subscribe. Should it?
            filters = ['kitMasters', 'reasons'];
            break;

        case 'user_activity':
            filters = ['activity', 'user'];
            break;

        default:
            filters = [];
            break;
    }

    return filters;
};
