import { Component } from '@angular/core';

import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'print-error',
    templateUrl: './print-error.html',
})
export class PrintError {
    errorAlert: string;
    errorMessage: string;

    constructor(private translationService: TranslationService) {
        this.errorAlert = this.translationService.instant('errors.print_error.alert');
        this.errorMessage = this.translationService.instant('errors.print_error.message');
    }
}
