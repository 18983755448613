import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';

import { StateService } from '@uirouter/core';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';

import { ConfigurationProvider } from '@services/config/configuration';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'lib-ngx-login',
    templateUrl: './ngx-login.component.html',
    styleUrls: ['./ngx-login.component.scss'],
})
export class NgxLoginComponent implements OnInit {
    attemptingLogIn: boolean = false;
    attemptingCognitoLogIn: boolean = false;
    showPasswordField: boolean = false;
    emailLocked: boolean = false;
    forgotPasswordEmail: string;
    cognitoLoginCode: string;
    newBlueSightUrl: string;
    demoToast: string;
    demoBlockLogin: boolean = false;
    viewState: string;
    intervalId;
    timeLeft: number = 10;
    interval;

    @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
    @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;

    constructor(
        private loginService: NgxLoginService,
        private stateService: StateService,
        private ngxLoginEventService: NgxLoginEventService,
        private configuration: ConfigurationProvider,
        private featureFlagService: FeatureFlagService,
        private zone: NgZone,
        private deviceService: DeviceDetectorService,
        private dialog: MatDialog,
        private translationService: TranslationService
    ) {
        this.forgotPasswordEmail = this.stateService.params.passwordResetEmail;
        this.cognitoLoginCode = window.location.search.split('?code=')[1];

        // @ts-ignore
        if (window.Cypress) {
            // @ts-ignore
            window.CypressLogin = this;
        }
    }

    ngDoCheck(): void {
        if (this.loginService.demoToast !== this.demoToast) {
            this.demoToast = this.loginService.demoToast;
            if (this.demoToast === 'resetting') {
                this.demoBlockLogin = true;
                this.loginService.showToast('Resetting Database, please wait to login', 180000);
            } else if (this.demoToast === 'done') {
                this.demoBlockLogin = false;
                this.loginService.showToast('Reset complete', 2000);
            } else if (this.demoToast === 'failed') {
                this.loginService.showToast('Reset failed');
            }
        }
    }

    ngOnInit(): void {
        // show users still using old kitcheck.com urls a message
        if (document.domain.indexOf('kitcheck.com') > 0) {
            this.viewState = 'old_domain';
            const oldDomain = window.location.href.split('?')[0];
            const newDomain = this.configuration.bluesightDomain();
            this.newBlueSightUrl = window.location.href.replace(oldDomain, newDomain);

            this.intervalId = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft === 0) {
                    window.clearInterval(this.intervalId);
                    window.location.href = this.newBlueSightUrl;
                }
            }, 1000);
            return;
        }
        // we're on bluesight.com :-)

        this.loginService.showPanel = true;
        if (this.forgotPasswordEmail) {
            setTimeout(() => (this.emailInput.nativeElement.value = this.forgotPasswordEmail));
        }

        if (this.cognitoLoginCode) {
            this.attemptingCognitoLogIn = true;
            this.attemptingLogIn = true;
            this.emailLocked = true;
            setTimeout(() => {
                this.loginService
                    .cognitoLoginRequest(this.cognitoLoginCode)
                    .subscribe(({ user }) => {
                        if (!user.current_hospital) {
                            this.loginService.storeDataForSwitch(user);
                            this.stateService.go('switch-hospital');
                        } else {
                            this.loginService.initiateLogin(user);
                            this.ngxLoginEventService.triggerHospitalSelect();
                        }
                    })
                    .add(() => {
                        setTimeout(() => {
                            this.attemptingCognitoLogIn = false;
                            this.attemptingLogIn = false;
                            this.emailLocked = false;
                        }, 3000);
                    });
            }, 1000);
        } else {
            setTimeout(() => this.emailInput.nativeElement.focus());
        }
    }

    checkLoginMethod(email: string): void {
        this.attemptingLogIn = true;
        this.emailLocked = true;

        this.loginService
            .checkLoginMethod(email)
            .subscribe(({ login_method, idp_identifier = null }) => {
                if (login_method === 'password_auth') {
                    this.showPasswordField = true;

                    setTimeout(() => {
                        this.passwordInput.nativeElement.focus();
                    });
                } else if (login_method === 'idp_auth') {
                    this.loginService.cognitoHandshake(idp_identifier);
                }
            })
            .add(() => {
                this.zone.run(() => {
                    // explicitly run in ngZone so if statements refresh
                    this.attemptingLogIn = false;
                });
            });
    }

    login(email: string, password: string): void {
        this.attemptingLogIn = true;
        this.loginService.setLocalStorageItem('idpLogin', false);

        this.loginService
            .loginRequest(email, password)
            .subscribe(({ user }) => {
                if (user.password_reset_required) {
                    this.loginService.showToast('Password reset required!');
                    this.stateService.go('password-reset', { reset_token: user.password_reset_token });

                    return;
                }

                this.ngxLoginEventService.triggerLogin();
                this.loginService.showToast(`${user.first_name} ${user.last_name} logged in successfully.`, 3000);
                this.featureFlagService.initialize(user);

                if (!user.current_hospital) {
                    this.loginService.storeDataForSwitch(user);
                    this.zone.run(() => {
                        // explicitly run in ngZone so if statements refresh
                        this.stateService.go('switch-hospital');
                    });

                    return;
                }

                this.zone.run(() => {
                    // explicitly run in ngZone so if statements refresh
                    this.featureFlagService.setHospital(
                        { hospitalContext: user.current_hospital },
                        null,
                        async (_, flags) => {
                            const isMobileDevice = this.deviceService.isTablet() || this.deviceService.isMobile();

                            if (!flags['redirect-to-mobile'] || !isMobileDevice) {
                                this.startUserEntering(user);
                                return;
                            }

                            const authToken = user.authentication_token;
                            const usingMobile = await this.translationService.get('switch_hospital.using_mobile');
                            const usingMobileDescription = await this.translationService.get(
                                'switch_hospital.using_mobile_description'
                            );
                            const confirmGoMobile = await this.translationService.get(
                                'switch_hospital.confirm_go_mobile'
                            );
                            const cancelGoMobile = await this.translationService.get(
                                'switch_hospital.cancel_go_mobile'
                            );

                            const confirmDialog = this.dialog.open(ConfirmDialog, {
                                width: '600px',
                                height: 'max-content',
                                data: {
                                    title: usingMobile,
                                    description: usingMobileDescription,
                                    okButton: confirmGoMobile,
                                    cancelButton: cancelGoMobile,
                                },
                            });

                            confirmDialog.afterClosed().subscribe((confirmation) => {
                                if (confirmation) {
                                    // Temporarily added until we get all of the other environments in place
                                    this.loginService.storeLoginData(user);
                                    window.location.href = `${
                                        this.configuration.getConfiguration().mobile_app_url
                                    }?auth=${authToken}`;
                                    return;
                                }

                                this.startUserEntering(user);
                            });
                        }
                    );
                });
            })
            .add(() => {
                this.attemptingLogIn = false;
            });
    }

    startUserEntering(user) {
        this.loginService.initiateLogin(user);
        this.ngxLoginEventService.triggerHospitalSelect();
    }

    forgotPasswordClicked(): void {
        this.stateService.go('forgot-password');
    }

    resetLogin(): void {
        this.emailLocked = false;
        this.showPasswordField = false;
        this.passwordInput.nativeElement.value = '';
        setTimeout(() => this.emailInput.nativeElement.focus());
    }
}
