import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportingResource } from '@resources/reporting-resource.service';

@Injectable({ providedIn: 'root' })
export class KittedInventoryWithCostResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private reportingResource: ReportingResource
    ) {}

    resolve(): Promise<any> {
        return this.loadingSpinnerService.spinnerifyPromise(this.reportingResource.kittedInventoryWithCost());
    }
}
