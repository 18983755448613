import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class GroupsResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getGroupIdnMap(action_name: string): Promise<any> {
        const params: any = {
            action_name,
        };

        return this.http.get<any>(`${this.API_ENDPOINT}/groups/idn_map`, { params: params }).toPromise();
    }
}
