import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';

@Injectable({ providedIn: 'root' })
export class ReportsResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private reportResource: ReportResource
    ) {}

    resolve(): Promise<any> {
        const hideSpinner = this.$state.transition.params().hideSpinner;
        const reportsPromise = this.reportResource.reportListV2().then((data) => {
            return data;
        });
        if (hideSpinner) {
            // don't show spinner if we are prefiltering to prevent double spinner
            return reportsPromise;
        } else {
            return this.loadingSpinnerService.spinnerifyPromise(reportsPromise);
        }
    }
}
