import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'absoluteVal' })
export class AbsoluteVal implements PipeTransform {
    /**
     * Formats a number as absolute value
     *
     * @param num - epc string to format
     *
     */
    transform(num: number): number {
        return Math.abs(num);
    }
}
