import { Component, Inject, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { ReportService } from '@services/report/report.service';
import { ReportSubscriptionResource } from '@resources/report-subscription-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { Subscription, Timeframe, SubscriptionSchedule, Frequency } from '@models/core/subscription';
import { getReportFilters } from './report-subscription-filters/models/report-subscription-filters';
import { isEmpty } from '@utils/objects';

@Component({
    selector: 'report-subscription-dialog',
    templateUrl: './report-subscription-dialog.html',
    styleUrls: ['./report-subscription-dialog.scss'],
})
export class ReportSubscriptionDialog {
    attempted: boolean = false;
    dayOptionsForFrequency: any;
    deliveryOptionsForFrequency: string[];
    disablePreview: boolean = false;
    filters: any;
    filterData: any;
    apiSubscriptionData: any;
    frequencies: Frequency[];
    isSystem: boolean = false;
    reportName: string;
    selectedTimeframeId: number;
    showCheck: boolean = false;
    showSuccessMsg: boolean = false;
    subscription: Subscription;

    subscriptionParams: {
        name: string;
        send_if_empty: boolean;
        frequency: string;
        on_the: number;
        in_the: string;
        timeframe?: Timeframe;
    };
    timeframes: Timeframe[] = [
        {
            id: 1,
            scalar: 1,
            unit: 'days',
            name: 'Previous Day',
        },
        {
            id: 2,
            scalar: 7,
            unit: 'days',
            name: 'Previous 7 Days',
        },
        {
            id: 3,
            scalar: 1,
            unit: 'months',
            name: 'Previous Month',
        },
    ];
    title: string = '';
    updateMode: boolean = false;
    hasFilters: boolean = false;
    showFilters: boolean = false;
    reportParams: any;
    allFiltersComplete: any = {};
    filtersComplete: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ReportSubscriptionDialog>,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        protected reportService: ReportService,
        protected translationService: TranslationService,
        private kcMatSnackBarService: KCMatSnackBarService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.reportName = this.data.reportName;
        this.isSystem = this.data.isSystem;
        this.frequencies = this.data.frequencies;
        this.filters = getReportFilters(this.reportName);
        this.apiSubscriptionData = this.data.apiSubscriptionData;
        this.filterData = this.data.filterData;
        this.subscription = this.data.subscription;
        this.hasFilters = !isEmpty(this.filters);
        this.showFilters = this.hasFilters;

        if (!!this.subscription && this.subscription.id) {
            this.updateMode = true;
            this.translationService.get('report_subscriptions.update_subscription').then((translation) => {
                this.title = translation;
            });
            this.subscriptionParams = {
                name: this.subscription.name,
                send_if_empty: this.subscription.send_if_empty || false,
                frequency: this.subscription.schedule.type,
                on_the: this.subscription.schedule.days[0].id,
                in_the: this.subscription.schedule.delivery_window,
                timeframe: this.subscription.timeframe,
            };

            if (!!this.subscription.timeframe && !!this.subscription.timeframe.scalar) {
                this.selectedTimeframeId = this.selectedTimeframe();
            }
        } else {
            //new subscription
            this.translationService.get('report_subscriptions.create_subscription').then((translation) => {
                this.title = translation;
            });
            this.subscriptionParams = {
                name: '',
                send_if_empty: false,
                frequency: this.frequencies[0].type,
                on_the: this.selectedFrequency(this.frequencies[0].type)['days'][0].id,
                in_the: this.selectedFrequency(this.frequencies[0].type)['delivery_windows'][0],
                timeframe: undefined,
            };

            // used for calls where there is no filter ui, but filters are passed in
            // such as in the carts manage subscription case
            if (this.data.setFilters) {
                this.setFilters(this.filterData);
            }
        }

        if (!this.updateMode && this.filterData.start_date) {
            this.subscriptionParams.timeframe = this.timeframes[0];
            this.selectedTimeframeId = this.subscriptionParams.timeframe.id;
        }

        this.setDaysForFrequency();
        this.setDeliveryWindowsForFrequency();
    }

    ngAfterViewInit(): void {
        /* have to disable the material dialog autofocus
         ** as it selects the first focusable element (in this dialog, the delete button)
         ** so setting focus manually instead.
         */

        if (!this.showFilters) {
            let element = document.getElementById('subName');

            setTimeout(() => {
                element.focus();
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close(true);
    }

    sendPreview() {
        this.setAttempted();
        this.previewSubscription();
    }

    setAttempted(): void {
        this.attempted = true;
    }

    selectedFrequency(subParam: string): Frequency {
        return this.frequencies.find((frequency) => frequency.type === (subParam || this.subscriptionParams.frequency));
    }

    setDaysForFrequency() {
        const freq = this.selectedFrequency(this.subscriptionParams.frequency);
        if (!!freq) {
            this.dayOptionsForFrequency = freq.days;
        } else {
            this.dayOptionsForFrequency = [];
        }
    }

    setDeliveryWindowsForFrequency() {
        const freq = this.selectedFrequency(this.subscriptionParams.frequency);
        if (!!freq) {
            this.deliveryOptionsForFrequency = freq.delivery_windows;
            // this.deliveryOptionsForFrequency = [];
        } else {
            this.deliveryOptionsForFrequency = [];
        }
    }

    resetSubDay(): void {
        const freq = this.selectedFrequency(this.subscriptionParams.frequency);
        this.setDaysForFrequency();
        this.setDeliveryWindowsForFrequency();

        this.subscriptionParams.in_the = freq.delivery_windows[0];
        this.subscriptionParams.on_the = freq.days[0].id;
    }

    getSubData() {
        let finalSubParams = _.clone(this.subscriptionParams);
        finalSubParams.timeframe = this.getTimeFrame();

        const submitData = {
            report_name: this.reportName,
            name: this.subscriptionParams.name,
            report_params: this.reportParams,
            subscription_params: finalSubParams,
        };
        return this.reportParams?.report_mode === 'bin'
            ? _.omit(submitData, ['report_params.kit_master_id'])
            : submitData;
    }

    setFilters(event): void {
        // need to set the API subscription data
        // so that other filters can pick up changes if needed
        // mostly used for the hospital group selector and kitmaster selector
        this.apiSubscriptionData = event;
        this.reportParams = event;
    }

    // report filter components emit whether they are complete
    // if we care (most we don't), we need to capture that per filter
    // so that we can enable/disable the next button independently
    setFiltersIncomplete(event): void {
        this.allFiltersComplete[event.filter] = !event.incomplete;
        this.filtersComplete = true;
        Object.keys(this.allFiltersComplete).forEach((key) => {
            if (!this.allFiltersComplete[key]) {
                this.filtersComplete = false;
                return;
            }
        });
    }

    selectedTimeframe(): number {
        if (!!this.subscriptionParams.timeframe && !!this.subscriptionParams.timeframe.scalar) {
            let selectedTimeframe = this.timeframes.find(
                (timeframe) =>
                    timeframe.unit === this.subscriptionParams.timeframe.unit &&
                    timeframe.scalar === this.subscriptionParams.timeframe.scalar
            );
            return selectedTimeframe.id;
        }
        // Default to previous day
        return 1;
    }

    getTimeFrame(): Timeframe {
        if (!!this.filterData.start_date || this.selectedTimeframeId) {
            let timeframe = this.timeframes.find((timeframe) => timeframe.id === this.selectedTimeframeId);
            return { scalar: timeframe.scalar, unit: timeframe.unit };
        }
        return this.subscriptionParams.timeframe;
    }

    deleteSubscription(): void {
        this.reportSubscriptionResource
            .deleteSubscription(this.subscription.id)
            .then(() => {
                this.dialogRef.close('deleted');
            })
            .catch(() => {
                this.dialogRef.close();
            });
    }

    previewSubscription(): void {
        this.disablePreview = true;
        const submitData = this.getSubData();

        this.reportSubscriptionResource
            .runSubscription(submitData)
            .then(() => {
                this.showCheck = true;

                setTimeout(() => {
                    this.showCheck = false;
                    this.disablePreview = false;
                }, 3500);
            })
            .catch(() => {
                this.dialogRef.close();
            });
    }

    updateSubscription(): void {
        const submitData = this.getSubData();
        this.reportSubscriptionResource
            .editSubscription(this.subscription.id, submitData)
            .then(() => {
                // manage subscription takes care of the success message.
                this.dialogRef.close('updated');
            })
            .catch(() => {
                this.dialogRef.close();
            });
    }

    submitSubscription(): void {
        const submitData = this.getSubData();

        this.reportSubscriptionResource
            .createSubscription(submitData)
            .then(() => {
                this.dialogRef.close('created');
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'report_subscriptions.success.created',
                });
            })
            .catch(() => {
                this.dialogRef.close();
            });
    }
}
