import * as moment from 'moment';

/**
 * Utilities for dealing with Objects
 **/

/**
 * Allows soft-failure of accessing Object properties
 */
export const getValue = (object, propertyName: string) =>
    typeof object === 'undefined' ? undefined : object[propertyName];

/**
 * Fetches nested properties via '.'-concatenated strings
 */
export function getNestedValue(object, propertyName: string) {
    return propertyName.split('.').reduce(getValue, object);
}

/**
 * Replacement for _.isEmpty()
 */
export const isEmpty = (obj) =>
    [Object, Array].includes((obj || {}).constructor) && !(<any>Object).entries(obj || {}).length;

/**
 * Replacement for _.difference()
 */
export function difference(arrays): any[] {
    return arrays.reduce((a, b) => {
        return a.filter((value) => {
            return !b.includes(value);
        });
    });
}

/**
 * Evaluate if two arrays of simple objects are equal.
 * Order affects equality. 2 complex objects of the same design will never be equal.
 */
export function areEqualArrays(a: any[], b: any[]): boolean {
    if (a === null || b === null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0, l = a.length; i < l; i++) {
        if (Array.isArray(a[i]) && Array.isArray(b[i])) {
            if (!areEqualArrays(a[i], b[i])) {
                return false;
            }
        } else if (!areDeepEqual(a[i], b[i])) {
            return false;
        }
    }
    return true;
}

/**
 * Evaluate if the passed parameter is a complex object.
 */
export const isComplexObject = (obj: any): boolean => !!obj && typeof obj === 'object';

/**
 * Evaluate if two objects are equal in value.
 */
export function areDeepEqual(a: any, b: any): boolean {
    if (a === b) {
        return true;
    } else if (
        (isComplexObject(a) && !isComplexObject(b)) ||
        (!isComplexObject(a) && isComplexObject(b)) ||
        (!isComplexObject(a) && !isComplexObject(b))
    ) {
        return false;
    }

    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = a[key];
        const val2 = b[key];
        const areComplexObjects = isComplexObject(val1) && isComplexObject(val2);

        if ((areComplexObjects && !areDeepEqual(val1, val2)) || (!areComplexObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

/**
 * Remove empty keys from objects
 */

export function deleteEmptyKeys(data): any {
    Object.keys(data).forEach((key) => {
        if (!data[key] || isEmpty(data[key])) {
            delete data[key];
        }
    });
    return data;
}

export function arrayUnique(array): Array<any> {
    if (!array) return [];
    let a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i].id === a[j].id) a.splice(j--, 1);
        }
    }
    return a;
}

export function nullIfBlank(value): any {
    return value === '' || value === undefined ? null : value;
}

export function makeNumber(n): number {
    const parsedN = parseFloat(n);
    return typeof parsedN === 'number' ? parsedN : null;
}

export function isValidNumber(value): boolean {
    return typeof value === 'number' && value % 1 === 0 && value >= 0;
}

export function cleanDate(dateString: string): Date {
    const momentDate = moment(dateString);
    if (!momentDate.isValid()) {
        return null;
    } else {
        return momentDate.toDate();
    }
}
