import { Component, Input, Inject, HostListener, Renderer2 } from '@angular/core';
import { ReportCategory } from '@models/core/report';
import { ApplicationService } from '@services/system/application.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

@Component({
    selector: 'report-header',
    templateUrl: './report-header.html',
    styleUrls: ['./report-header.scss'],
})
export class ReportHeader {
    @Input() reports: {
        report_categories: ReportCategory[];
    };
    @Input() reportsCategory: ReportCategory;
    @Input() isSystem: boolean = false;

    title: string = 'reports.title';
    allReports: string = 'reports.all_reports';
    showReports: boolean = false;

    category: string;

    // for report list overlay behavior
    private wasInside = false;

    @HostListener('click', ['$event'])
    clickInside($event) {
        if (this.showReports) {
            this.wasInside = true;
            if ($event.target.className.includes('ng2-report-modal')) {
                this.toggleReports();
            }

            if ($event.target.className.includes('reportLink')) {
                this.toggleReports();
            }
        }
    }

    @HostListener('document:click')
    clickout() {
        if (this.showReports) {
            if (!this.wasInside) {
                this.toggleReports();
            }
            this.wasInside = false;
        }
    }

    constructor(
        private renderer: Renderer2,
        private applicationService: ApplicationService,
        private loadingSpinnerService: LoadingSpinnerService
    ) {}

    toggleReports() {
        this.showReports = !this.showReports;
        this.toggleBackgroundScrolling();
    }

    toggleBackgroundScrolling() {
        if (this.showReports) {
            this.renderer.addClass(document.body, 'no-scroll');
        } else {
            this.renderer.removeClass(document.body, 'no-scroll');
        }
    }
}
