import { Component, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DashboardService } from '@services/core/dashboard.service';
import { Message } from '@models/core/message';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.scss'],
})
export class Dashboard {
    dashboardMessages: Message[] = [];
    hasMessages: boolean = false;
    isMessageLoading: boolean = true;
    messageBoardHeight: number = 500;

    @ViewChildren('reportWidgets') reportWidgets: QueryList<ElementRef>;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.loadMessages();
    }

    loadMessages() {
        this.isMessageLoading = true;
        this.dashboardService.getDashboardMessages().then((messages: Message[]) => {
            this.dashboardMessages = messages;
            this.hasMessages = this.dashboardMessages?.length > 0;
            this.isMessageLoading = false;
        });
    }

    ngAfterViewInit() {
        this.reportWidgets?.changes.subscribe((elements: QueryList<ElementRef>) => {
            setTimeout(() => {
                this.updateMessageBoardHeight(elements);
            }, 1500);
        });
    }

    ngAfterViewChecked() {
        setTimeout(() => {
            this.updateMessageBoardHeight(this.reportWidgets);
        }, 1500);
    }

    updateMessageBoardHeight(elements) {
        this.messageBoardHeight = elements?.first?.nativeElement.clientHeight;
    }
}
