import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { StateService } from '@uirouter/core';
import { CartResource } from '@resources/cart-resource.service';

@Injectable({ providedIn: 'root' })
export class CartResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private $state: StateService,
        private cartResource: CartResource
    ) {}

    resolve(): Promise<any> {
        const cartId = this.$state.transition.params().cartId;
        return this.loadingSpinnerService.spinnerifyPromise(this.cartResource.cartData(cartId)).then(({ cart }) => {
            return cart;
        });
    }
}
