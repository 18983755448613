import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable()
export class AeroscoutResource {
    constructor(
        private http: HttpClient,
        private configuration: ConfigurationProvider,
        private localStorageService: LocalStorageService
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    getMobileViewAuthHeader() {
        let credentials = this.localStorageService.get('aeroscoutCredentials');
        if (credentials) {
            let { username, password } = credentials;
            /* eslint-disable */
            return { Authorization: 'Basic ' + btoa(username + ':' + password) };
            /* eslint-enable */
        } else {
            return {};
        }
    }

    getAeroscoutUrl(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}aeroscout_url`).toPromise();
    }

    postAeroscoutUrl(url: string): Promise<any> {
        const data = {
            url,
        };

        return this.http.post<any>(`${this.API_ENDPOINT}aeroscout_url`, data).toPromise();
    }

    deleteFromCart(cartId: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}carts/${cartId}/aeroscout`).toPromise();
    }

    postAeroscoutAsset(cartId: number, asset_name: string, asset_id: string, location?: any): Promise<any> {
        const data = {
            asset_name,
            asset_id,
            location,
        };

        return this.http.post<any>(`${this.API_ENDPOINT}carts/${cartId}/aeroscout`, data).toPromise();
    }
}
