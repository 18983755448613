import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Container } from '@models/core/container';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

export type ContainerLocationForm = {
    container_location_id: number;
};

@Component({
    selector: 'edit-container-location-dialog',
    templateUrl: './edit-container-location-dialog.html',
    styleUrls: ['./edit-container-location-dialog.scss'],
})
export class EditContainerLocationDialog {
    locationId: number;
    containerData: MatTableDataSourceWithNaturalSort<Container>;
    displayedColumns: string[] = ['name', 'type', 'location'];

    constructor(
        public dialogRef: MatDialogRef<EditContainerLocationDialog, ContainerLocationForm>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngAfterViewInit() {
        this.containerData = new MatTableDataSourceWithNaturalSort(this.data.containerTable);
        this.locationId = this.data.containerTable[0].location.id;
    }

    submit() {
        const containerData: ContainerLocationForm = {
            container_location_id: this.locationId,
        };

        this.dialogRef.close(containerData);
    }

    onCancel() {
        this.dialogRef.close();
    }
}
