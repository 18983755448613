/***
 * Barrel file to unburden app.module.ts imports
 * ***/

// Angular 2 Providers

// config
import { ConfigurationProvider } from './config/configuration';
import { CustomHttpParamEncoder } from './config/custom-http-param-encoder.service';

// core - kitcheck business logic type things
import { AssignKitService } from './core/assign-kit.service';
import { BadgeAuthService } from './core/badge-auth.service';
import { BarcodeService } from './core/barcode.service';
import { BarcodeScanService } from './core/barcode-scan.service';
import { ChargeModelService } from './core/charge-model.service';
import { CommonCRUDAPIService } from './core/common-crud-api.service';
import { DashboardService } from './core/dashboard.service';
import { FormularyItemsService } from './core/formulary-items.service';
import { GroupInfoService } from './core/group-info.service';
import { HospitalInfoService } from './core/hospital-info.service';
import { KitMasterService } from './core/kit-master.service';
import { KitSummaryService } from './core/kit-summary.service';
import { KitService } from './core/kit.service';
import { ProductModuleService } from './core/product-module.service';
import { RecallService } from './core/recall.service';
import { ScanActionService } from './core/scan-action.service';
import { ScanningService } from './core/scanning.service';
import { ScanRedirectionService } from './core/scan-redirection.service';
import { SegmentsService } from './core/segments.service';
import { TagAssociationService } from './core/tag-association.service';
import { UserListService } from './core/user-list.service';

// hardware
import { AeroscoutService } from './hardware/aeroscout.service';
import { HardwareService } from './hardware/hardware.service';
import { MotorolaService } from './hardware/motorola.service';
import { PrintService } from './hardware/print.service';
import { DeprecatedMotorolaService } from './hardware/deprecated-motorola.service';
import { ScannerService } from './hardware/scanner.service';
import { ThingMagicService } from './hardware/thing-magic.service';
import { UniversalRfidReaderInterfaceService } from './hardware/universal-rfid-reader-interface.service';
import { ZebraService } from './hardware/zebra.service';

// interceptors
import { HttpInterceptorProviders } from './interceptors';

// login
import { BroadcastMessageService } from './login/broadcast-message.service';
import { GroupLoginService } from './login/group-login.service';
import { LoginService } from './login/login.service';
import { LoginStateService } from './login/login-state.service';
import { LogoutService } from './login/logout.service';
import { UserInfoService } from './login/user-info.service';
import { UserSessionTimeoutService } from './login/user-session-timeout.service';

// ngx-login
import { NgxLoginService } from './ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from './ngx-login/ngx-login-event/ngx-login-event.service';

// report
import { CartsReportService } from './report/carts-report.service';
import { ChartingService } from './report/charting.service';
import { HospitalReportService } from './report/hospital-report.service';
import { ItemRecallReportService } from './report/item-recall-report.service';
import { ItemsConsumedReportService } from './report/items-consumed-report.service';
import { ItemsTaggedReportService } from './report/items-tagged-report.service';
import { KitActivityReportService } from './report/kit-activity-report.service';
import { ReportService } from './report/report.service';
import { ReportScopeToDataService } from './report/report-scope-to-data.service';

// resources - http requests
import { ActivityResource } from '@resources/activity-resource.service';
import { AeroscoutResource } from '@resources/aeroscout-resource.service';
import { AllowlistResource } from '@resources/allowlist-resource.service';
import { BadgeVerificationResource } from '@resources/badge-verification-resource.service';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { BillingLabelResource } from '@resources/billing-label-resource.service';
import { BinResource } from '@resources/bin-resource.service';
import { BinTagPackageResource } from '@resources/bin-tag-package-resource.service';
import { CartResource } from '@resources/cart-resource.service';
import { CatalogResource } from '@resources/catalog-resource.service';
import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { ContainerResource } from '@resources/container-resource.service';
import { DecommissionResource } from '@resources/decommission-resource.service';
import { BenchmarkResource } from '@resources/benchmark-resource.service';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { FirmwareRevisionsResource } from '@resources/firmware-revisions-resource.service';
import { GroupLoginResource } from '@resources/group-login-resource.service';
import { GroupRolesResource } from '@resources/group-roles-resource.service';
import { GroupsResource } from '@resources/groups-resource.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { ItemResource } from '@resources/item-resource.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { KitResource } from '@resources/kit-resource.service';
import { KitScanResource } from '@resources/kit-scan-resource.service';
import { LocationResource } from '@resources/location-resource.service';
import { MessageResource } from '@resources/message-resource.service';
import { PackageDescriptionsResource } from '@resources/package-descriptions-resource.service';
import { PreTaggedMedicationsResource } from '@resources/pre-tagged-medications-resource.service';
import { PretaggedProblemResource } from '@resources/pretagged-problem-resource.service';
import { PrintBatchVerificationResource } from '@resources/print-batch-verification-resource.service';
import { PrintResource } from '@resources/print-resource.service';
import { RecallResource } from '@resources/recall-resource.service';
import { ReportSubscriptionResource } from '@resources/report-subscription-resource.service';
import { ReportingResource } from '@resources/reporting-resource.service';
import { ReportResource } from '@resources/report-resource.service';
import { ScanResource } from '@resources/scan-resource.service';
import { ScanningResource } from '@resources/scanning-resource.service';
import { MotorolaResource } from '@resources/motorola-resource.service';
import { SegmentResource } from '@resources/segment-resource.service';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';
import { SessionResource } from '@resources/session-resource.service';
import { SnapshotResetResource } from '@resources/snapshot-reset-resource.service';
import { TagResource } from '@resources/tag-resource.service';
import { TagAssociationResource } from '@resources/tag-association-resource.service';
import { TagAssociationBatchesResource } from '@resources/tag-association-batches-resource.service';
import { TagTypesResource } from '@resources/tag-types-resource.service';
import { UserResource } from '@resources/user-resource.service';
import { UserSettingsResource } from '@resources/user-settings-resource.service';
import { UniversalRfidReaderInterfaceResource } from '@resources/universal-rfid-reader-interface-resource.service';
import { ZebraResource } from '@resources/zebra-resource.service';

// system - things that used to be in rootScope
import { ApplicationService } from '@services/system/application.service';
import { BannerService } from '@services/system/banner.service';
import { DynamicScriptLoaderService } from '@services/system/dynamic-script-loader.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { FeatureFlagService } from './system/feature-flag/feature-flag.service';

// storage
import { LocalStorageService } from './storage/local-storage.service';

// utils
import { ActionService } from './utils/action.service';
import { CanadianPackageVerificationService } from './utils/canadian-package-verification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DownloadHelperService } from './utils/download-helper.service';
import { FileHandler } from './utils/file-streamer.service';
import { FileUploadService } from './utils/file-upload.service';
import { KCMatSnackBarService } from './utils/kc-mat-snack-bar.service';
import { MobileCartService } from './utils/mobile-cart.service';
import { NaturalSortService } from './utils/natural-sort.service';
import { ParseFormErrorsService } from './utils/parse-form-errors.service';
import { RetryService } from './utils/retry.service';
import { TranslationService } from './utils/translation.service';

// vendor
import { ChurnZeroService } from './vendor/churn-zero.service';
import { DatadogService } from './vendor/datadog.service';
import { GainsightService } from './vendor/gainsight.service';
import { FreshDeskService } from './vendor/fresh-desk.service';
import { LaunchDarklyService } from './vendor/launch-darkly.service';
import { MidmarkDeviceBatteryResource } from '@resources/midmark-device-battery-resource.service';

const services = [
    ActivityResource,
    AeroscoutResource,
    AeroscoutService,
    AssignKitService,
    AllowlistResource,
    BadgeVerificationResource,
    BarcodeService,
    BannerService,
    BillingLabelResource,
    BinResource,
    BinTagPackageResource,
    BroadcastMessageService,
    ChargeModelService,
    ChartingService,
    ChurnZeroService,
    ClinicalEquivalenceResource,
    CommonCRUDAPIService,
    ContainerResource,
    CustomHttpParamEncoder,
    DashboardService,
    DashboardResource,
    DatadogService,
    DecommissionResource,
    DynamicScriptLoaderService,
    FeatureFlagService,
    FileUploadService,
    FirmwareRevisionsResource,
    FreshDeskService,
    GroupInfoService,
    ItemResource,
    KCMatSnackBarService,
    LocalStorageService,
    LocationResource,
    MessageResource,
    MotorolaService,
    ParseFormErrorsService,
    PreTaggedMedicationsResource,
    PrintBatchVerificationResource,
    PrintService,
    ProductModuleService,
    ReportingResource,
    ReportResource,
    RetryService,
    ScanActionService,
    MotorolaResource,
    NgxLoginService,
    NgxLoginEventService,
    ScanningResource,
    ScanningService,
    ScanRedirectionService,
    SegmentResource,
    SegmentTemplateResource,
    SessionResource,
    SnapshotResetResource,
    ScannerUpdatingService,
    TagResource,
    UserSessionTimeoutService,
    UserSettingsResource,
    ActionService,
    ApplicationService,
    BadgeAuthService,
    BarcodeScanService,
    BarcodeResource,
    BinResource,
    CanadianPackageVerificationService,
    CartResource,
    CartsReportService,
    CatalogResource,
    BenchmarkResource,
    DownloadHelperService,
    DeviceDetectorService,
    FileHandler,
    FormularyItemResource,
    FormularyItemsService,
    GainsightService,
    GroupLoginResource,
    GroupLoginService,
    GroupRolesResource,
    GroupsResource,
    HardwareResource,
    HardwareService,
    HospitalInfoService,
    HospitalReportService,
    HospitalResource,
    ItemRecallReportService,
    ItemResource,
    ItemsConsumedReportService,
    ItemsTaggedReportService,
    KitActivityReportService,
    KitMasterResource,
    KitMasterService,
    KitResource,
    KitScanResource,
    KitService,
    KitSummaryService,
    LaunchDarklyService,
    LoadingSpinnerService,
    LocationResource,
    LoginService,
    LoginStateService,
    LogoutService,
    MidmarkDeviceBatteryResource,
    MobileCartService,
    NaturalSortService,
    PackageDescriptionsResource,
    PretaggedProblemResource,
    PreTaggedMedicationsResource,
    PrintResource,
    RecallResource,
    RecallService,
    ReportingResource,
    ReportResource,
    ReportService,
    ReportScopeToDataService,
    ReportSubscriptionResource,
    DeprecatedMotorolaService,
    ScanResource,
    ScannerService,
    SegmentsService,
    TagAssociationBatchesResource,
    TagAssociationResource,
    TagAssociationService,
    TagTypesResource,
    ThingMagicService,
    TranslationService,
    UniversalRfidReaderInterfaceResource,
    UniversalRfidReaderInterfaceService,
    UserInfoService,
    UserListService,
    UserResource,
    ZebraResource,
    ZebraService,
];

export { ConfigurationProvider } from './config/configuration';

export { HttpInterceptorProviders, services };
