import { Component } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TableComponent } from '@components/common/table-component';

@Component({
    selector: 'cart-history',
    templateUrl: './cart-history.html',
})
export class CartHistory extends TableComponent {
    constructor(private loadingSpinnerService: LoadingSpinnerService) {
        super();
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    trackByFn(index, item) {
        return item.id;
    }

    sortHistory(field: string): void {
        this.sortBy(field, this.items);
    }
}
