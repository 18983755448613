import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';

@Component({
    selector: 'lib-ngx-password-reset-expired',
    templateUrl: './ngx-password-reset-expired.component.html',
    styleUrls: ['./ngx-password-reset-expired.component.scss'],
})
export class NgxPasswordResetExpiredComponent implements OnInit {
    passwordSettings: any;

    constructor(
        private loginService: NgxLoginService,
        private stateService: StateService,
        private ngxLoginEventService: NgxLoginEventService
    ) {}

    ngOnInit(): void {}

    cancelSwitch(): void {
        this.stateService.go('login');
    }
}
