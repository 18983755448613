import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'item-count',
    templateUrl: './item-count.html',
})
export class ItemCount {
    @Input() segment;

    constructor() {}

    ngOnInit() {}
}
