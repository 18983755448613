import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

/*
 **  ReportResource is for standard API_ENDPOINT calls.
 **  ReportingResource uses the API_REPORTING_ENDPOINT endpoint.
 */

@Injectable()
export class ReportResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    itemExpirationReport(data): Promise<any> {
        let url = 'item_expiration_and_attributes_custom_expiration';

        if (data.days_expiring_within === 1) {
            url = 'expired_items';
        } else {
            url = `item_expiration_and_attributes_${data.days_expiring_within}d`;
        }

        return this.http.get<any>(`${this.API_ENDPOINT}reports/${url}`, { params: data }).toPromise();
    }

    customNDCOrLotReport(data): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}reports/item_expiration_and_attributes_ndc_lot`, { params: data })
            .toPromise();
    }

    customItemReport(data, isSystem?): Promise<any> {
        return this.http
            .get<any>(
                `${this.API_ENDPOINT}reports/${isSystem ? 'system_' : ''}item_expiration_and_attributes_custom_item`,
                { params: data }
            )
            .toPromise();
    }

    itemsRecalledReport(data, isSystem?): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}reports/${isSystem ? 'system_' : ''}item_recalls`, { params: data })
            .toPromise();
    }

    looseItemsReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/loose_items`, { params: data }).toPromise();
    }

    hospitalActivityReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/hospital_activity`, { params: data }).toPromise();
    }

    printBatchReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/print_batch`, { params: data }).toPromise();
    }

    printBatchDetailReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/print_batch_detail`, { params: data }).toPromise();
    }

    itemBatchByHospitalReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/item_batch_by_hospital`, { params: data }).toPromise();
    }

    itemsTaggedReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/items_tagged`, { params: data }).toPromise();
    }

    kitsNeedingReworkReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/kits_needing_rework`, { params: data }).toPromise();
    }

    kittedItems_byNDCReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/kitted_items_by_ndc`, { params: data }).toPromise();
    }

    itemsConsumedReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/items_consumed_removed`, { params: data }).toPromise();
    }

    preTaggedUsageReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/not_prepaid_usage_report`, { params: data }).toPromise();
    }

    userActivitiesList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/filters/actions`).toPromise();
    }

    userActivityReport(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/user_activity`, { params: data }).toPromise();
    }

    virginiaBoardOfPharmacyReport(data): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}reports/virginia_board_of_pharmacy`, { params: data })
            .toPromise();
    }

    reportListV2(): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}reports`).toPromise();
    }

    // deprecated?
    dossierReport(name: string): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}reports/` + name).toPromise();
    }

    kitActivity(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/kit_activity`, { params: data }).toPromise();
    }

    kitActivityList(): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}reports/filters/kit_activities`).toPromise();
    }

    aggregateBilling(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}reports/aggregate_billing`, { params: data }).toPromise();
    }
}
