import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';

import { ActionService } from '@services/utils/action.service';
import { AeroscoutResource } from '@resources/aeroscout-resource.service';
import { AeroscoutService } from '@services/hardware/aeroscout.service';
import { AssignCartToLocationDialog } from '@dialogs/assign-cart-to-location/assign-cart-to-location-dialog';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { CartResource } from '@resources/cart-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { SortDirection } from '@services/utils/natural-sort.service';
import { TableComponent } from '@components/common/table-component';
import { Subscription } from 'rxjs';
import { AssignLockDialog } from '@components/dialogs/assign-lock/assign-lock-dialog';
import { TranslationService } from '@services/utils/translation.service';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';

@Component({
    selector: 'cart-manage',
    templateUrl: './cart-manage.html',
    styleUrls: ['./cart-manage.scss'],
})
export class CartManage extends TableComponent {
    @Input() cart: KC.ICart;
    @Input() locations: KC.ILocation[];
    @Input() history;
    @Input() manualExpirationDate: Date;
    downloadFilters: any;
    scheduledReportsEnabled: boolean;
    report: any;
    aeroscoutEnabled: boolean;
    canEditAeroscout: boolean;
    subscription: Subscription;

    settingCartExpiration: boolean = false;
    tab = 'summary';
    assignLocksToCartsEnabled: boolean;
    kitCartLocationAllowlistsEnabled: boolean = false;

    constructor(
        private actionService: ActionService,
        private aeroscoutResource: AeroscoutResource,
        private aeroscoutService: AeroscoutService,
        private barcodeResource: BarcodeResource,
        private barcodeScanService: BarcodeScanService,
        private cartResource: CartResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private hospitalInfoService: HospitalInfoService,
        private reportSubscriptionResource: ReportSubscriptionResource,
        private dialog: MatDialog,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
        this.assignLocksToCartsEnabled = this.hospitalInfoService.getHospitalSettings().assign_locks_to_carts;
        let url = '/reports/cart_history';
        this.report = { url: url.toString(), name: 'cart_history' };
    }

    ngOnInit() {
        this.items = this.cart.kits;
        this.defaultSort = { field: 'name', direction: SortDirection.asc };
        if (this.cart.expiration_manual) {
            this.manualExpirationDate = new Date(this.cart.expiration_manual);
        }

        this.aeroscoutEnabled = this.hospitalInfoService.getHospitalSettings().aeroscout_enabled;
        this.canEditAeroscout = this.actionService.isAllowAction(
            'hospital_settings',
            'update_cart',
            'Cart manage: update cart'
        );

        this.subscription = this.aeroscoutService.observeAssetSelection().subscribe((asset: any) => {
            if (!!asset?.name) {
                const prevAsset = { name: this.cart.aeroscout_asset_name, id: this.cart.aeroscout_asset_id };
                this.cart.aeroscout_asset_name = asset.name;
                this.cart.aeroscout_asset_id = asset.id;
                this.loadingSpinnerService.spinnerifyPromise(
                    this.aeroscoutResource.postAeroscoutAsset(Number(this.cart.id), asset.name, asset.id).catch(() => {
                        this.cart.aeroscout_asset_name = prevAsset.name;
                        this.cart.aeroscout_asset_id = prevAsset.id;
                        if (!prevAsset.name) {
                            this.aeroscoutService.emitAssetSelectResetEvent();
                        }
                    })
                );
            }
        });

        this.barcodeScanService.registerListener((scanData) => {
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object) {
                    if (data.object['class'] === 'Location') {
                        this.finishCartDispatch(this.cart, data.object.id);
                    }
                }
            });
        }, 'assignToLocation');
        this.downloadFilters = { cart_id: this.cart.id };
        this.featureFlagService.getFeatureValue('kit-cart-location-allowlists-enabled').subscribe((flagValue) => {
            this.kitCartLocationAllowlistsEnabled = flagValue;
        });
    }

    deregisterAeroscoutSelectEvent() {
        this.subscription.unsubscribe();
    }

    sortKits(field: string) {
        this.sortBy(field, this.items);
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    onManualDateChange = (result: Date): void => {
        if (!result) {
            return;
        }

        if (!!this.cart.expiration_manual) {
            let cartExp = new Date(this.cart.expiration_manual);
            if (cartExp.getTime() === result.getTime()) {
                return;
            }
        }
        this.cart.expiration_manual = result.toISOString();
        this.setCartExpiration(this.cart);
    };

    setCartLock(cart) {
        const cartLockDialog = this.dialog.open(AssignLockDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                item: cart,
                editDispatchLockText: this.translationService.instant('modals.cart.edit_dispatch_cart_locks'),
                editReturnLockText: this.translationService.instant('modals.cart.edit_return_cart_locks'),
            },
        });

        cartLockDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            const manualLocks = {
                dispatch_lock: result.dispatchLock || null,
                return_lock: result.returnLock || null,
            };
            this.cartResource.updateCartLock(cart.id, manualLocks).then(() => {
                if (manualLocks.dispatch_lock === null && manualLocks.return_lock === null) {
                    cart.manual_locks = null;
                    return;
                }

                cart.manual_locks = manualLocks;
            });
        });
    }

    setCartExpiration(cart: KC.ICart): void {
        const updateCartExpPromise = this.cartResource.updateCart(cart);
        updateCartExpPromise
            .then(() => {
                return this.cartResource.cartData(this.cart.id);
            })
            .then((data: any) => {
                this.cart = data.cart;
                this.manualExpirationDate = !!this.cart.expiration_manual
                    ? new Date(this.cart.expiration_manual)
                    : null;
                this.items = this.cart.kits;
            });

        this.loadingSpinnerService.spinnerifyPromise(updateCartExpPromise);
    }

    clearAeroscoutAsset() {
        this.cart.aeroscout_asset_name = null;
        this.cart.aeroscout_asset_id = null;
        this.aeroscoutService.emitAssetSelectResetEvent();
        this.loadingSpinnerService.spinnerifyPromise(this.aeroscoutResource.deleteFromCart(Number(this.cart.id)));
    }

    clearCartExpiration(cart: KC.ICart) {
        this.cart.expiration_manual = null;
        this.setCartExpiration(cart);
    }

    finishCartDispatch(cart, locationId) {
        const new_location = _.find(this.locations, {
            id: locationId,
        });
        cart.location = {};
        cart.location.id = new_location.id;
        cart.location.name = new_location.name;
        cart.location_id = new_location.id;
        cart.location_name = new_location.name;
        this.cartResource
            .dispatch(cart)
            .then(() => {
                this.cartResource.cartHistory(cart.id).then((data) => {
                    this.history = data;
                });
            })
            .then(() => {
                this.cartResource.cartData(this.cart.id).then((data) => {
                    this.cart = data.cart;
                    cart.location.id = data.cart.location.id;
                    cart.location.name = data.cart.location.name;
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `${data.cart.name} has been dispatched to ${data.cart.location.name}`
                    );
                });
            });
    }

    trackByFn(index, item) {
        return item.id;
    }

    setCartLocation(cart): void {
        if (!cart.location) {
            cart.location = {};
        }

        const locationAssignDialog = this.dialog.open(AssignCartToLocationDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                cart: cart,
                locations: this.locations,
            },
        });

        locationAssignDialog.afterClosed().subscribe((cart) => {
            if (cart) {
                if (cart.location?.id) {
                    this.finishCartDispatch(cart, cart.location.id);
                } else {
                    this.cart.location = null;
                    cart.location = null;
                    cart.location_id = null;
                    cart.location_name = null;
                    this.cartResource.removeLocation(cart);
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `${cart.name} has been removed from its location`
                    );
                }
            }
            this.barcodeScanService.removeListener('assignToLocation');
        });
    }

    subscribeModal(): void {
        const subscribeFilters = {
            cart_id: this.cart.id,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    setFilters: true,
                },
            });
        });
    }

    getCartAllowedLocations() {
        return this.cartResource.getCartAllowedLocations.bind(this.cartResource);
    }

    ngOnDestroy() {
        this.deregisterAeroscoutSelectEvent();
    }
}
