import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';

import { PretaggedProblemResource } from '@resources/pretagged-problem-resource.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { ActionService } from '@services/utils/action.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnquarantinePretaggedDialog } from '@dialogs/unquarantine-pretagged/unquarantine-pretagged-dialog';

@Component({
    selector: 'admin-pretagged-quarantine',
    templateUrl: './admin-pretagged-quarantine.html',
    styleUrls: ['./admin-pretagged-quarantine.scss'],
})
export class AdminPretaggedQuarantine {
    @Input() quarantinedItems;
    @Input() packageDescriptions;
    @Input() problems;
    @Input() manufacturers;

    step: number = 1;
    formStep: number = 1;
    manufacturer;
    itemInfo: any = {
        drug_name: '',
        ndc: '',
        lot_number: '',
        expiration: '',
        number_of_items: '',
        package_size: '',
        package_size_uom: '',
        package_description_id: null,
    };
    checkboxes: { [key: string]: boolean } = {};
    notes = '';
    problemKeys: string[];
    selectedItems;
    showNextSteps: boolean = false;
    hints = {};
    allowUnquarantine: boolean;

    isLoading: boolean = true;
    hasData: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'unquarantine_checkbox',
        'drug_name',
        'ndc',
        'lot_number',
        'package_size',
        'created_at_formatted',
        'created_by_name',
    ];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private pretaggedProblemResource: PretaggedProblemResource,
        private $state: StateService,
        private $stateParams: Transition,
        private translationService: TranslationService
    ) {
        this.translationService
            .get([
                'tooltips.item.manufacturers_name',
                'tooltips.item.ndc_hri_upc',
                'tooltips.item.item_name',
                'tooltips.package.package_size',
                'tooltips.package.package_size_uom',
                'tooltips.package.package_description',
            ])
            .then((translations) => {
                this.hints = translations;
            });
    }

    ngOnInit() {
        if (this.$stateParams.params().successMessage) {
            this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, this.$stateParams.params().successMessage);
        }

        this.manufacturer = this.manufacturers[0];
        this.problems.forEach((problem) => {
            this.checkboxes[problem.name] = false;
        });
        this.problemKeys = Object.keys(this.checkboxes);

        this.quarantinedItems = this.quarantinedItems.map((item) => {
            return { ...item, checked: false };
        });
        this.selectedItems = Object.assign({}, this.quarantinedItems);

        this.allowUnquarantine = this.actionService.isAllowAction(
            'kits_tagging',
            'unquarantine_tag',
            'Unquarantine a pre-tagged item'
        );
    }

    ngAfterViewInit(): void {
        if (!this.allowUnquarantine) {
            this.displayedColumns = [
                'drug_name',
                'ndc',
                'lot_number',
                'package_size',
                'created_at_formatted',
                'created_by_name',
            ];
        }

        this.dataSource = new MatTableDataSourceWithNaturalSort(this.quarantinedItems);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.hasData = !!this.quarantinedItems.length;
    }

    reportPretaggedIssue(): void {
        this.step = 2;
    }

    toggleCheck(item) {
        item.checked = !item.checked;
        this.selectedItems = this.quarantinedItems.filter((item) => item.checked);
    }

    isFilledStep(step) {
        return this.formStep > step;
    }

    goTo(formStep) {
        if (formStep === this.formStep) {
            return;
        }
        this.formStep = formStep;
    }

    back() {
        this.$state.go('admin.pretagged-quarantine', undefined, { reload: true, inherit: false });
    }

    submit() {
        this.showNextSteps = true;
        this.formStep = 4;
        let params: any = {
            problem_names: [],
            drug_name: this.itemInfo.drug_name,
            ndc: this.itemInfo.ndc,
            lot_number: this.itemInfo.lot_number,
            expiration: this.itemInfo.expiration,
            number_of_items: this.itemInfo.number_of_items,
            package_size_scalar: this.itemInfo.package_size,
            package_size_uom: this.itemInfo.package_size_uom,
            package_description_id: this.itemInfo.package_description_id,
        };

        if (this.manufacturer) {
            params.pre_tagged_catalog_id = this.manufacturer.id.toString();
        }
        this.notes = this.notes.trim();
        if (this.notes) {
            params.notes = this.notes;
        }
        for (let problem in this.checkboxes) {
            if (this.checkboxes.hasOwnProperty(problem) && this.checkboxes[problem]) {
                params.problem_names.push(problem);
            }
        }
        if (params.problem_names.length === 0) {
            delete params.problem_names;
        }
        this.pretaggedProblemResource.reportPretaggedIssue(params).then((data) => {});
    }

    createTags() {
        let data = {
            type: 'item',
            ndc: this.itemInfo.ndc,
            lot: this.itemInfo.lot_number,
            expiration: this.itemInfo.expiration,
        };
        this.$state.go('tagging', { type: 'item', barcodeObject: data });
    }

    unquarantineTags() {
        const confirmDialog = this.dialog.open(UnquarantinePretaggedDialog, {
            width: '600px',
            height: 'max-content',
            data: { items: this.selectedItems },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.selectedItems.forEach((item) => {
                    this.pretaggedProblemResource.deletePretaggedReport(item.id).then((data) => {
                        if (this.selectedItems.length === 1) {
                            let item = this.selectedItems[0].drug_name;
                            this.translationService
                                .get('admin.pretagged_quarantine.unquarantine_item_success', { item: item })
                                .then((translation) => {
                                    this.$state.go(
                                        'admin.pretagged-quarantine',
                                        { successMessage: translation },
                                        { reload: true, inherit: false }
                                    );
                                });
                        } else {
                            let items = this.selectedItems.map((item) => item.drug_name).join(', ');
                            this.translationService
                                .get('admin.pretagged_quarantine.unquarantine_items_success', { items: items })
                                .then((translation) => {
                                    this.$state.go(
                                        'admin.pretagged-quarantine',
                                        { successMessage: translation },
                                        { reload: true, inherit: false }
                                    );
                                });
                        }
                    });
                });
            }
        });
    }

    continueDisabled() {
        for (let info in this.itemInfo) {
            if (
                info !== 'package_description_id' &&
                info !== 'package_size' &&
                info !== 'number_of_items' &&
                this.itemInfo.hasOwnProperty(info) &&
                !this.itemInfo[info].trim()
            ) {
                return true;
            }
        }
        return !this.itemInfo.package_description_id || !this.itemInfo.package_size || !this.itemInfo.number_of_items;
    }

    submitDisabled() {
        if (this.notes.trim() && this.checkboxes['other']) {
            return false;
        }
        for (let problem in this.checkboxes) {
            if (this.checkboxes.hasOwnProperty(problem) && this.checkboxes[problem] && problem !== 'other') {
                return false;
            }
        }
        return true;
    }

    setExpiration(result: Date): void {
        if (!result) {
            return;
        }
        this.itemInfo.expiration = moment(result).format('YYYY-MM-DD');
    }
}
