import { Component, Input, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { GroupMap } from '@models/admin/admin-group-map';

interface EventParams {
    role: any[];
    node: GroupMap;
    inherited?: boolean;
}

@Component({
    selector: '[role-row]',
    templateUrl: './role-row.html',
    styleUrls: ['./role-row.scss'],
})
export class RoleRow {
    @Input() group: GroupMap;
    @Input() isChild: boolean;
    @Input() showChildren: boolean;
    @Input() nodeLevel: number;

    @Output() remove: EventEmitter<EventParams> = new EventEmitter<EventParams>();
    @Output() add: EventEmitter<EventParams> = new EventEmitter<EventParams>();

    bluesightRoleName: string = 'bluesight';
    // material tag attributes
    removable: boolean = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    constructor() {}

    ngOnInit() {
        if (this.group.expandAvailable) {
            this.showAvailableRoles(this.group);
        }
    }

    expand(group): void {
        group.expanded = !group.expanded;
        if (!group.expanded) {
            this.collapseChildren(group.children);
        }
    }

    collapseChildren(children): void {
        children.forEach((child) => {
            child.expanded = false;
            this.collapseChildren(child.children);
        });
    }

    showAvailableRoles(group): void {
        if (!group.showAvailable) {
            // only show non-selected and non-inherited roles
            group.possibleRoles = group.roles.filter(
                (role) => !role.selected && !role.inherited && this.roleIsNotBCSRole(role)
            );
        }
        // toggle the whether we are showing available roles
        group.showAvailable = !group.showAvailable;
    }

    addRole(role, group): void {
        role.selected = true;
        this.add.emit({ role: role, node: group, inherited: false });
    }

    removeRole(role, group): void {
        role.selected = false;
        role.inherited = false;
        this.remove.emit({ role: role, node: group });
    }

    roleIsNotBCSRole(role): boolean {
        return !role.name.toLowerCase().includes(this.bluesightRoleName);
    }
}
