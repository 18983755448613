import { Component, Input, Output, ViewChild, OnInit } from '@angular/core';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';

import { ItemResource } from '@resources/item-resource.service';
import { titleize } from '@utils/strings';

@Component({
    selector: 'tag-history',
    templateUrl: './tag-history.html',
})
export class TagHistory implements OnInit {
    @Input() item;

    dataSource: MatTableDataSourceWithNaturalSort<any>;

    displayedColumns: string[] = [
        'created_at',
        'scan_type',
        'scan_id',
        'scanned_by',
        'kit_or_bin_id',
        'physical_label',
        'scanner',
    ];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;
    hasData: boolean;
    scanHistory: any;

    constructor(private itemResource: ItemResource) {}

    ngOnInit() {
        this.scanHistory = [];

        this.itemResource.getScanHistory(this.item.tag.id).then((results) => {
            this.scanHistory = results.tag_scans;

            this.scanHistory.forEach((scan) => {
                if (scan.bins?.length > 0) {
                    scan.isBin = true;
                    scan.isKit = false;
                    scan.kit_or_bin_id = scan.bins[0].id;
                } else if (scan.kits?.length > 0) {
                    scan.isBin = false;
                    scan.isKit = true;
                    scan.kit_or_bin_id = scan.kits[0].id;
                    scan.physical_label = scan.kits[0].physical_label;
                } else {
                    // it's something else
                    scan.isBin = false;
                    scan.isKit = false;
                    scan.kit_or_bin_id = null;
                }
                scan.scan_type_formatted = titleize(scan.scan_type.replace('_', ' '));
            });
            this.setDatasource();
        });
    }

    setDatasource(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.scanHistory);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }
}
