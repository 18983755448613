import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'last' })
export class LastPipe implements PipeTransform {
    /**
     * Returns the last N chars of a string
     *
     * @remarks
     * epc | last:4
     * will return the last 4 digits of the string
     *
     * @param value - string
     * @param args - int length
     *
     * @returns string
     *
     */
    constructor() {}

    transform(value: string, ...args: any[]): string {
        const end = value.length - args[0];
        return value.substring(end);
    }
}
