import { Component, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportSubscriptionDialog } from '@components/dialogs/report-subscription/report-subscription-dialog';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportingResource } from '@services/resources/reporting-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { Report, ReportCategory } from '@models/core/report';
import { Activity, ActivityAnalytics, UserInfo } from './activity-analytics.model';

@Component({
    selector: 'activity-analytics',
    templateUrl: './report-activity-analytics.html',
    styleUrls: ['./report-activity-analytics.scss'],
})
export class ReportActivityAnalytics {
    @Input() reports: {
        report_categories: Array<ReportCategory>;
    };
    @Input() activityAnalytics: ActivityAnalytics;
    @ViewChildren('activityContainer', { read: ElementRef }) activityContainer: QueryList<ElementRef>;

    //attributes
    report: Report;
    downloadFilters: {
        start_date: string;
        end_date: string;
    };

    filters: {
        start_date: Date;
        end_date: Date;
    };

    filterType: string;
    users: UserInfo[];
    userActions: any[];
    selectedUser: string;
    selectedAction: string;
    currentUserActions: Activity[];
    usersWithActivity: number;
    sortAction: boolean = true;
    sortScan: boolean = true;
    sortTagsPrinted: boolean = true;
    scheduledReportsEnabled: boolean;
    futureDateError: boolean = false;

    SCAN_ACTIONS = ['Scan Kit'];
    TAG_ACTIONS = ['Create Item Tag'];

    FILTER_DAY = 'DAY';
    FILTER_WEEK = 'WEEK';
    FILTER_MONTH = 'MONTH';

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected translationService: TranslationService,
        protected reportingResource: ReportingResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.filters = {
            start_date: moment(new Date()).subtract('weeks', 4).toDate(),
            end_date: moment(new Date()).toDate(),
        };

        const reportCategory = this.reports.report_categories.find((category) => category.name === 'analytics');
        this.report = reportCategory.reports.find((report) => report.name === 'activity_analytics');
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
        this.processData();
    }

    ngAfterViewInit() {
        this.activityContainer.changes.subscribe((next: QueryList<ElementRef>) => {
            if (!this.showSpinner()) {
                this.renderCharts();
            }
        });
    }

    private activityTotal(user: UserInfo, activity: string) {
        return user.activities.reduce((sum, activityData) => {
            if (activityData.name === activity) {
                return sum + activityData.activity_count;
            } else {
                return sum;
            }
        }, 0);
    }

    private processData(): void {
        this.filterType = this.activityAnalytics.filter;

        this.users = _(this.activityAnalytics.users)
            .map((user: any, name: string) => {
                const activityNames = _(user.activities).map('name').uniq().value();
                const activitiesMap = {};
                let flatActivities = activityNames.map((activityName: string) => {
                    activitiesMap[activityName] = this.activityTotal(user, activityName);
                    return {
                        name: activityName,
                        activity_count: activitiesMap[activityName],
                    };
                });

                flatActivities = flatActivities.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

                return {
                    name,
                    activities: user.activities,
                    activitiesMap,
                    flatActivities,
                };
            })
            .filter((user: any) => !!user.flatActivities.length)
            .sortBy('name')
            .value();

        this.usersWithActivity = this.users.length;
        this.userActions = _(this.users)
            .map('activities')
            .flatten()
            .map('name')
            .uniq()
            .without(...this.SCAN_ACTIONS)
            .without(...this.TAG_ACTIONS)
            .sort()
            .value();

        //initalize first user for user activity breakdown dropdown
        this.selectedUser = this.users.length ? this.users[0].name : undefined;
        this.currentUserActions = this.users.length ? this.users[0].flatActivities : [];

        this.selectedAction = this.userActions.length ? this.userActions[0] : undefined;

        this.updateDownloadFilters();
        this.updateUser(this.selectedUser);
    }

    private updateDownloadFilters(): void {
        this.downloadFilters = {
            start_date: moment(this.filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.filters.end_date).format('YYYY-MM-DD'),
        };
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(): void {
        this.futureDateError = false;
        const data = {
            start_date: moment(this.filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.filters.end_date).format('YYYY-MM-DD'),
        };
        if (moment().isBefore(data.start_date)) {
            this.futureDateError = true;
            return;
        }
        const promise = this.reportingResource.activityAnalytics(data).then((activityAnalytics) => {
            this.activityAnalytics = activityAnalytics;
            this.processData();
            this.renderCharts();
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal() {
        const subscribeFilters = this.filters;
        const subscriptionFrequenciesPromise = this.reportSubscriptionResource.frequenciesList();
        const subscription = undefined;

        Promise.all([subscriptionFrequenciesPromise]).then((response) => {
            const frequencies = response[0].frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    subscription: subscription,
                },
            });
        });
    }

    generateElementId(username: string, actionNames: Array<string>): string {
        const actionsSlug: string = _.kebabCase(actionNames.join('-'));
        return `user-${_.kebabCase(username)}-${actionsSlug}`;
    }

    renderUsers(category: string, id: string, actionNames: any[], color: string, sort: boolean): void {
        const categoryId: string = `#${id}`;
        const actionsTotal = (user) => {
            return actionNames.reduce((sum, actionName) => {
                return sum + user.activitiesMap[actionName] || 0;
            }, 0);
        };

        const mappedValues: any[] = _(this.users).map(actionsTotal).flatten().value();

        const max: any = d3.max(mappedValues);
        const tickCount: number = 4;
        const rangeScale = d3
            .scaleLinear()
            .domain([0, Math.floor(max) + Math.floor(max) / (tickCount * 2)])
            .range([0, 198.66 - 80]);

        const sorter = () => {
            return _.sortBy(this.users, actionsTotal);
        };

        // if sort exists use descending (sort().reverse) else use ascending (sort())
        this[category] = sort ? sorter().reverse() : sorter();

        d3.select(categoryId).html('');

        //render Bar Chart Data
        this[category].forEach((user: any, index) => {
            const userTotal = actionsTotal(user);

            d3.select(categoryId)
                .classed('bar-chart', true)
                .append('div')
                .style('margin-bottom', '5px')
                .attr('id', (): string => {
                    return this.generateElementId(`${user.name}${index}`, actionNames);
                })
                .attr('class', (): string => {
                    return 'users row';
                })
                .append('div')
                .attr('class', (): string => {
                    return 'col-sm-4';
                })
                .append('p')
                .html(user.name);

            d3.select(`#${this.generateElementId(user.name + index, actionNames)}`)
                .append('div')
                .attr('class', (): string => {
                    return 'col-sm-8';
                })
                .style('height', '20px')
                .append('div')
                .attr('class', () => {
                    return 'row';
                })
                .append('div')
                .attr('class', (): string => {
                    return 'col-sm-9';
                })
                .style('padding-right', '0')
                .style('padding-left', '0')
                .append('svg')
                .attr('width', () => {
                    return 147.5;
                })
                .attr('height', 20)
                .append('g')
                .classed('bar-container', true)
                .append('rect')
                .attr('width', () => {
                    return rangeScale(userTotal);
                })
                .attr('height', 20)
                .attr('fill', color);

            d3.select(`#${this.generateElementId(user.name + index, actionNames)} svg .bar-container`)
                .append('text')
                .attr('x', () => {
                    return rangeScale(userTotal) + 10;
                })
                .attr('dy', '1em')
                .text(() => {
                    return userTotal;
                });
        });

        // add in vertical divider
        d3.select(categoryId)
            .append('div')
            .classed('divider', true)
            .style('height', () => {
                return this.users.length + 196; // calculate dynamic height for divider
            });

        const rangeAxis = d3.axisBottom(rangeScale).ticks(tickCount, 's');

        d3.select(`${categoryId}-scale`).html('');
        d3.select(`${categoryId}-scale`)
            .style('font-size', '10px')
            .append('svg')
            .attr('width', 300)
            .attr('height', 40)
            .append('g')
            .classed('axis', true)
            .attr('transform', 'translate(99.33, 0)')
            .call(rangeAxis);
    }

    renderLineGraph() {
        //date parser
        const parseTime: any = this.filterType === this.FILTER_MONTH ? d3.timeParse('%Y-%m') : d3.timeParse('%Y-%m-%d');

        const dates: any = _(this.users)
            .map((user: any) => user.activities)
            .flatten()
            .map('date')
            .uniq()
            .sort()
            .value();

        const parsedDates: any[] = _(dates)
            .map((date: string) => {
                return parseTime(date);
            })
            .sort()
            .value();

        const combineUserActions = (userActions: string[]) => {
            return _(this.users)
                .map((user: any) => user.activities)
                .flatten()
                .filter((action: any) => {
                    return !!_.includes(userActions, action.name);
                })
                .groupBy('date')
                .map((actions: any[], date: string) => {
                    const activity_count = actions.reduce((sum: number, action: any) => {
                        return sum + action.activity_count;
                    }, 0);

                    const { name } = actions[0];

                    return {
                        activity_count,
                        name,
                        date,
                    };
                })
                .value();
        };

        let scans: any = combineUserActions(this.SCAN_ACTIONS);
        let tags_printed: any = combineUserActions(this.TAG_ACTIONS);
        let actions: any = combineUserActions([this.selectedAction]);

        [scans, tags_printed, actions].forEach((activities: any[]) => {
            if (!activities.length) {
                return;
            }
            const { name } = activities[0];
            dates.forEach((date) => {
                if (!activities.find((act) => act.date === date)) {
                    activities.push({
                        name,
                        date,
                        activity_count: 0,
                    });
                }
            });

            activities.sort((a, b) => {
                return parseTime(a.date) > parseTime(b.date) ? 1 : 0;
            });
        });

        const sortByTime: any = (activity: any) => {
            return parseTime(activity.date);
        };

        const sum = (activities: any) => {
            return activities.reduce((sum, activity) => {
                return sum + activity.activity_count;
            }, 0);
        };

        scans = _.sortBy(scans, sortByTime);
        tags_printed = _.sortBy(tags_printed, sortByTime);
        actions = _.sortBy(actions, sortByTime);

        const getTotals: any = (activities: any) => {
            return _(activities)
                .map((activity: any) => {
                    const activityDates = activity.map((activita) => activita.date);
                    const activityData = [];

                    activityDates.forEach((date: string) => {
                        const associatedMatches: any = {};
                        associatedMatches.name = date;
                        associatedMatches.matches = activity.filter((act: any) => {
                            return act.date === date;
                        });
                        activityData.push(associatedMatches);
                    });

                    let activityTotals: number[] = activityData.map((groupOfActivity: any) => {
                        // sum up numbers for each group scans (organized by date)
                        return sum(groupOfActivity.matches);
                    });

                    return activityTotals;
                })
                .flatten()
                .value();
        };

        const maxNumOfActions: number = d3.max(getTotals([scans, tags_printed, actions]));

        const svg = d3.select('#line-chart svg');
        //clear out old data
        svg.selectAll('*').remove();
        const yScale = (max: number) => {
            return d3.scaleLinear().domain([0, max]).range([200, 0]);
        };
        //width minus padding on right side
        const xScale = d3.scaleTime().range([0, 800 - 100]);
        const tickCount = 5;
        const tickValues = parsedDates;

        let xAxis;

        if (this.filterType === this.FILTER_DAY) {
            xAxis = d3.axisBottom(xScale).tickPadding(10).tickValues(tickValues).tickFormat(d3.timeFormat('%m/%d/%y'));
        } else {
            xAxis = d3.axisBottom(xScale).tickPadding(10).ticks(10, d3.timeFormat('%m/%d/%y'));
        }

        const yAxis = d3.axisLeft(yScale(maxNumOfActions)).ticks(tickCount, 's');

        const make_x_gridlines: any = () => {
            return d3.axisBottom(xScale).tickValues(tickValues);
        };

        const make_y_gridlines: any = () => {
            return d3.axisLeft(yScale(maxNumOfActions)).ticks(5);
        };

        const line = (max: number) => {
            return d3
                .line()
                .x((d: any) => {
                    return xScale(parseTime(d.date));
                })
                .y((d: any) => {
                    return yScale(max)(d.activity_count);
                });
        };

        const renderLine = (userCategory: any, maxLineValue: number, color: string) => {
            return svg
                .append('g')
                .attr('transform', () => {
                    return 'translate(50,10)';
                })
                .append('path')
                .datum(userCategory)
                .classed('chart-line', true)
                .attr('d', line(maxLineValue))
                .style('stroke', color);
        };

        const lines: any = [
            {
                type: tags_printed,
                color: '#52C7EA',
            },
            {
                type: scans,
                color: '#044A82',
            },
            {
                type: actions,
                color: '#9CA6F1',
            },
        ];

        const renderLines: any = (categories: any, max: number) => {
            categories.forEach((category: any) => {
                renderLine(category.type, max, category.color);
            });
        };

        xScale.domain(d3.extent(parsedDates));

        renderLines(lines, maxNumOfActions);

        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(50, 210)')
            .call(xAxis)
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-.8em')
            .attr('dy', '-1em')
            .attr('transform', 'rotate(-60)');

        svg.append('g')
            .attr('class', 'grid')
            .attr('transform', 'translate(50, 210)')
            .call(make_x_gridlines().tickSize(-200).tickFormat(''));

        svg.append('g').attr('class', 'y-axis').attr('transform', 'translate(50,10)').call(yAxis);

        svg.append('g')
            .attr('class', 'grid')
            .attr('transform', 'translate(50,10)')
            .call(make_y_gridlines().tickSize(-890).tickFormat(''));

        svg.selectAll('.x-axis text').style('font-size', '10px');

        this.translationService.get('reports.activity_analytics.number_of_actions').then((translation) => {
            //append y axis label
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0)
                .attr('x', 0 - 200 / 2)
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .style('font-size', '12px')
                .text(translation);
        });
    }

    renderCharts(): void {
        this.renderUsers('usersByScan', 'top-users-by-scan', this.SCAN_ACTIONS, '#044A82', this.sortScan);
        this.renderUsers(
            'usersByTagsPrinted',
            'top-users-by-printed-tags',
            this.TAG_ACTIONS,
            '#52C7EA',
            this.sortTagsPrinted
        );
        this.renderUsers('usersByAction', 'top-users-by-action', [this.selectedAction], '#9CA6F1', this.sortAction);
        this.renderLineGraph();
    }

    toggleSort(name: string): void {
        this[name] = !this[name];
        if (name === 'sortScan') {
            this.renderUsers('usersByScan', 'top-users-by-scan', this.SCAN_ACTIONS, '#044A82', this[name]);
        }
        if (name === 'sortTagsPrinted') {
            this.renderUsers(
                'usersByTagsPrinted',
                'top-users-by-printed-tags',
                this.TAG_ACTIONS,
                '#52C7EA',
                this[name]
            );
        }

        if (name === 'sortAction') {
            this.renderUsers('usersByAction', 'top-users-by-action', [this.selectedAction], '#9CA6F1', this[name]);
        }
    }

    // Update actions object to reflect information from selected users data
    updateAction(action: string): void {
        // update chart views
        this.renderUsers('usersByAction', 'top-users-by-action', [action], '#9CA6F1', this.sortAction);
        this.renderLineGraph();
    }

    // select User and display list that user's actions with totals
    updateUser(selectedUser: string): void {
        //find selectedUser Object
        const user = this.users.find((user: any) => {
            return user.name === selectedUser; // should change to user.id once backend gets started
        });
        this.currentUserActions = user ? user.flatActivities : [];
    }
}
