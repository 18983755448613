import { Message } from '@models/core/message';

export const prioritizeMessages = (messages: Message[]): Message[] => {
    const pinnedMessages = sortMessages(messages.filter((m) => m.pin_to_top));
    const dismissables = sortMessages(messages.filter((m) => !m.pin_to_top && m.can_dismiss && !m.recall_message));
    const notDismissables = sortMessages(messages.filter((m) => !m.pin_to_top && !m.can_dismiss && !m.recall_message));
    const recallMessages = sortMessages(messages.filter((m) => !m.pin_to_top && m.recall_message));

    return pinnedMessages.concat(recallMessages.concat(notDismissables.concat(dismissables)));
};

const sortMessages = (messages: Message[]): Message[] => {
    messages.sort((a: Message, b: Message) => {
        const missingPublishDate = !a.published_at || !b.published_at;
        if (missingPublishDate) return 0;

        const same = a.published_at === b.published_at;
        if (same) return 0;

        if (new Date(a.published_at) > new Date(b.published_at)) return -1;

        if (new Date(a.published_at) < new Date(b.published_at)) return 1;
    });
    return messages;
};

export const formatMessageForDisplay = (message: Message): Message => {
    if (message.body.length > 160 && !message.recall_message && !message.active_recall_message) {
        message.display = message.body.substring(0, 160);
    } else {
        message.display = message.body;
    }
    message.published_at = new Date(message.published_at).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    return message;
};
