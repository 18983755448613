import { Component, Input } from '@angular/core';
import { TableComponent } from '@components/common/table-component';
import { ByCEQItem, ByFormularyItem } from '../../../models/bin-scan-data';

@Component({
    selector: 'bin-scan-items-table',
    templateUrl: './bin-scan-items-table.html',
})
export class BinScanItemsTable extends TableComponent {
    @Input() items: ByCEQItem[] | ByFormularyItem[];
    @Input() showNDC: boolean;

    constructor() {
        super();
    }

    sort(field): void {
        this.sortBy(field, this.items);
    }
}
