import { Component, OnInit, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { ActionService } from '@services/utils/action.service';
import { CommonCRUDAPIService } from '@services/core/common-crud-api.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { TranslationService } from '@services/utils/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

// TODO CF require('../admin.scss');
// @Component({
// selector: 'assignable-form',
// template: '<ng-content></ng-content>'
// })

@Directive()
export abstract class AssignableForm {
    assignObject: any;
    assignType: string;
    actionAllow: any;
    attempted: boolean = false;
    assignClass: string;
    barcodeSelected: boolean;
    formError: boolean;
    badgeScanError: boolean;
    barcodeErrorText: string;
    boundFocus: Function;
    boundBlur: Function;
    saving: boolean;
    invalidChars: string;
    invalidBarcode: boolean = false;

    constructor(
        protected $state: StateService,
        protected actionService: ActionService,
        protected barcodeScanService: BarcodeScanService,
        protected commonCRUDAPIService: CommonCRUDAPIService,
        protected dialog: MatDialog,
        protected translationService: TranslationService
    ) {}

    ngOnInit() {
        this.assignObject = this.assignObject || {};
        this.actionAllow = this.actionService.allowModule('hospital_settings');
        this.assignType = this.assignType.toLowerCase();
        this.assignClass = _.capitalize(this.assignType);
        this.boundFocus = this.onFocus.bind(this);
        this.boundBlur = this.onBlur.bind(this);

        this.barcodeScanService.registerListener(this.scanBarcode.bind(this), this.assignType);
    }

    doCrud(data, action, redirect = true): Promise<any> {
        this.saving = true;
        const crudPromise = this.commonCRUDAPIService[action + this.assignClass](data)
            .then((result) => {
                if (redirect) {
                    this.$state.go(`admin.${this.assignType}s`);
                }
                return result;
            })
            .finally(() => {
                this.saving = false;
            });

        return crudPromise;
    }

    async confirmRemove(data: any) {
        const description = await this.translationService.get('modals.confirm_delete.confirm', { objName: data.name });
        const objType = await this.translationService.get(this.assignClass);
        const title = await this.translationService.get('modals.confirm_delete.header', { objType: objType });

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: title,
                description: description,
                okButton: this.translationService.instant('buttons.delete'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.doCrud(data.id, 'remove');
            }
        });
    }

    clearErrors(): void {
        this.invalidBarcode = false;
        this.invalidChars = '';
    }

    validateBarcode = (scanData: String): boolean => {
        if (!scanData) {
            this.clearErrors();
            return true;
        }
        this.invalidChars = scanData
            .split('')
            .filter((letter) => letter.charCodeAt(0) >= 128)
            .join(' ');
        this.invalidBarcode = this.invalidChars.length !== 0 || scanData.length > 44;
        return !this.invalidBarcode;
    };

    save(form: NgForm, options: any = {}): Promise<any> {
        if (!this.validateBarcode(this.assignObject.barcode)) {
            return;
        }

        return new Promise((resolve, reject) => {
            if (form.valid) {
                this.attempted = true;
                const data: any = _.pick(this.assignObject, 'id', 'name', 'barcode');
                const action = !!this.assignObject.id ? 'update' : 'add';

                if (!data.barcode) {
                    data.barcode = null;
                }

                this.doCrud(data, action, !options.noRedirect)
                    .then(resolve)
                    .catch(resolve)
                    .finally(() => {
                        this.attempted = false;
                    });
            } else {
                this.formError = true;
                resolve();
            }
        });
    }

    cancel(): void {
        this.$state.go(`admin.${this.assignType}s`);
    }

    onFocus(): void {
        this.clearErrors();
        this.barcodeSelected = true;
    }

    onBlur(): void {
        this.barcodeSelected = false;
        this.validateBarcode(this.assignObject.barcode);
    }

    captureBarcode(): void {
        this.clearErrors();
        this.barcodeSelected = true;
    }

    editableBarcode(): boolean {
        return !this.assignObject.barcode || this.barcodeSelected || this.invalidBarcode;
    }

    clearBarcode(): void {
        this.assignObject.barcode = undefined;
        this.clearErrors();
    }

    showBarcodeMessage(): boolean {
        return this.assignObject.barcode && !this.barcodeSelected;
    }

    scanBarcode(scanData): void {
        if (!this.barcodeSelected) {
            return;
        }

        this.assignObject.barcode = scanData;
        $('.scanner-input').blur();

        if (!this.validateBarcode(scanData)) {
            return;
        }

        if (!this.assignObject.name || !this.assignObject.name.length) {
            this.assignObject.name = scanData;
        }
    }

    ngOnDestroy(): void {
        this.barcodeScanService.removeListener(this.assignType);
    }
}
