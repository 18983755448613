import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { HardwareService } from '@services/hardware/hardware.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService } from '@services/utils/kc-mat-snack-bar.service';
import { LoginStateService } from '@services/login/login-state.service';
import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { FreshDeskService } from '@services/vendor/fresh-desk.service';
import { SnapshotResetResource } from '@resources/snapshot-reset-resource.service';

@Component({
    selector: 'kc-nameplate',
    templateUrl: './kc-nameplate.html',
    styleUrls: ['./kc-nameplate.scss'],
})
export class KCNameplate {
    allowResetButton: boolean;
    canSetHospitalSettings: boolean;
    canSetUserSettings: boolean;
    canSwitchHospitals: boolean;
    hospitalName: string;
    resetMessage;
    userName: string;
    withoutBackend: boolean;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private applicationService: ApplicationService,
        private configuration: ConfigurationProvider,
        private hardwareService: HardwareService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loginStateService: LoginStateService,
        private ngxLoginService: NgxLoginService,
        private snapshotResetResource: SnapshotResetResource,
        private freshDeskService: FreshDeskService
    ) {}

    ngOnInit() {
        this.userName = this.applicationService.user.formatted_name;
        this.setHospitalName();
        this.allowResetButton = this.configuration.allowResetButton();
        this.canSetHospitalSettings = this.actionService.isAllowAction(
            'hospital_settings',
            'view_hospital_settings',
            'Hospital settings in header'
        );
        this.canSwitchHospitals = this.hospitalInfoService.getHospitalAccess().length > 1;
        this.canSetUserSettings = this.actionService.isAllowAction(
            'user_settings',
            'view_user_settings',
            'User Settings in header'
        );
        this.withoutBackend = !this.actionService.isAllowAction(
            'security',
            'logout',
            'NOT - Prevent sending logout request to backend'
        );
        this.resetMessage = null;
    }

    navigate(location, event) {
        this.$state.go(location, event);
    }

    resetDB(): void {
        setTimeout(() => {
            this.startReset(this.snapshotResetResource);
        }, 1000);
        setTimeout(() => {
            this.setResetMessage();
        }, 500);
        setTimeout(() => {
            this.loginStateService.notifyLogout(true);
        }, 1500);
    }

    startReset(snapshotResetResource): void {
        snapshotResetResource
            .reset()
            .then(() => {
                this.ngxLoginService.demoToast = 'done';
            })
            .catch(() => {
                this.ngxLoginService.demoToast = 'failed';
            });
    }

    showHelpContent() {
        this.freshDeskService.initFAQWidget();
    }

    /**
     * Navigate to Bluesight's Contact Support page and open in a new window tab.
     */
    navigateToContactSupport() {
        const url = 'https://bluesight.com/contact-us/';
        window.open(url, '_blank');
    }

    /**
     * Navigate to Bluesight's Community page and open in a new window tab.
     */
    navigateToCommunity() {
        const url = 'https://community.bluesight.com/categories/products';
        window.open(url, '_blank');
    }

    setResetMessage(): void {
        this.ngxLoginService.demoToast = 'resetting';
    }

    switchHospital(event) {
        this.kcMatSnackBarService.clearAll();
        this.hardwareService.clear();
        this.$state.go('switch-hospital', event);
    }

    logout(withoutBackend) {
        this.loginStateService.notifyLogout(withoutBackend);
    }

    setHospitalName() {
        if (this.configuration.getConfiguration().environment !== 'production') {
            this.hospitalName = `${this.hospitalInfoService.getHospitalName()} (${this.hospitalInfoService.getHospitalId()})`;
        } else {
            this.hospitalName = this.hospitalInfoService.getHospitalName();
        }
    }
}
