import { Injectable } from '@angular/core';
import { HospitalSettings } from '@models/core/hospital-settings';
import { LocalStorageService } from '@services/storage/local-storage.service';

interface Feature {
    name: string;
}

@Injectable()
export class HospitalInfoService {
    constructor(private localStorageService: LocalStorageService) {}

    getHospitalId() {
        return (
            (this.localStorageService.get('currentHospital')
                ? this.localStorageService.get('currentHospital').id
                : this.localStorageService.get('hospitalId')) || 0
        );
    }

    getHospitalName() {
        return this.localStorageService.get('currentHospital')
            ? this.localStorageService.get('currentHospital').name
            : '';
    }

    getHospitalSettings(): HospitalSettings {
        return this.localStorageService.get('hospitalSettings') || {};
    }

    hospitalSetting(settingName: keyof HospitalSettings) {
        const settings = this.getHospitalSettings();
        return settings[settingName];
    }

    allowShelvedInventory(): boolean {
        return this.hospitalSetting('shelved_inventory_enabled');
    }

    manualLocks(): boolean {
        return this.hospitalSetting('manual_locks_enabled');
    }

    canadianHospital(): boolean {
        return this.hospitalSetting('locale') === 'en-CA';
    }

    // updates a single key on hospital settings cache
    setHospitalSettings(key, value) {
        let hospitalSettings = this.getHospitalSettings();
        hospitalSettings[key] = value;
        return this.localStorageService.set('hospitalSettings', hospitalSettings);
    }

    getShelvedInventoryEnabled() {
        return this.getHospitalSettings().shelved_inventory_enabled;
    }

    getOnBehalfOfHospitalSettings() {
        return this.localStorageService.get('onBehalfOfHospitalSettings') || {};
    }

    hasIdnRoot() {
        return this.localStorageService.get('rootIds') ? !!this.localStorageService.get('rootIds').length : false;
    }

    isAcheckBillingEnabled() {
        return this.getHospitalSettings().acheck_billing_enabled;
    }

    getHospitalAccess() {
        return this.localStorageService.get('hospitalAccess') || [];
    }

    isFeatureEnabled(featureName: string): boolean {
        let result = false;
        let features: Feature[] = this.getHospitalSettings().features;
        if (!!features) {
            result = !!features.find((feature) => feature.name === featureName);
        }
        return result;
    }
}
