import { Injectable } from '@angular/core';

@Injectable()
export class FileHandler {
    convertBufferToDownload(data: any, filename: string, mime_type: string) {
        let blob = new Blob([data], { type: mime_type });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.setAttribute('download', filename);
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }
}
