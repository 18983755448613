import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { ReportCategory } from '@models/core/report';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { printPage } from '@utils/print';

interface IMidmarkDeviceBattery {
    kit_master_name: string;
    kit_physical_label: string;
    last_location_update: string;
    last_known_location: string;
}

@Component({
    selector: 'midmark-low-battery-report',
    templateUrl: './midmark-low-battery-report.html',
    styleUrls: ['./midmark-low-battery-report.scss'],
})
export class MidmarkLowBatteryReport {
    @Input() midmarkDeviceBatteries: IMidmarkDeviceBattery[];
    @Input() reports: {
        report_categories: Array<ReportCategory>;
    };
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    itemCount: number;
    hasData: boolean = false;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns = ['kit_master_name', 'kit_physical_label', 'last_location_update', 'last_known_location'];
    collectionSize: number;
    pageIndex: number = 0;
    pageSize: number = 25;
    sortInitialized = false;

    constructor(private loadingSpinnerService: LoadingSpinnerService) {}

    ngAfterViewInit(): void {
        this.setupTable();
    }

    ngAfterViewChecked() {
        if (this.sort !== undefined && !this.sortInitialized) {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.sortInitialized = true;
        }
    }

    setupTable() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.midmarkDeviceBatteries);
        this.collectionSize = this.midmarkDeviceBatteries.length;
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    printLowBattery() {
        printPage('.midmark-low-battery-report');
    }
}
