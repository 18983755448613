import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmData {
    segmentName: string;
}

@Component({
    selector: 'remove-segment-dialog',
    templateUrl: './remove-segment-dialog.html',
    styleUrls: ['./remove-segment-dialog.scss'],
})
export class RemoveSegmentDialog {
    constructor(
        public dialogRef: MatDialogRef<RemoveSegmentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {}

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        this.dialogRef.close(true);
    }
}
