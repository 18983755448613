import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[decimal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DecimalValidatorDirective, multi: true }],
})
export class DecimalValidatorDirective implements Validator {
    constructor(private el: ElementRef) {}

    validate(control: AbstractControl): { [key: string]: any } | null {
        const inputValue: string = this.el.nativeElement.value;

        if (inputValue && !/^\d+(\.\d{1,2})?$/.test(inputValue)) {
            return { invalidDecimal: true };
        }

        return null;
    }

    @HostListener('input', ['$event']) onInput(event: any) {
        const value: string = event.target.value;

        if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
            event.preventDefault();
            event.stopPropagation();
            this.el.nativeElement.value = parseFloat(value).toFixed(2);
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }
    }
}
