import * as LDClient from 'launchdarkly-js-client-sdk';
import { Injectable } from '@angular/core';
import { FeatureFlagKey } from '@models/common/feature-flags';
import { HospitalAccess, UserSession } from '@models/core/user-session';
import { LaunchDarklyService } from '@services/vendor/launch-darkly.service';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

interface InitializeHospitalPayload {
    userContext?: UserSession;
    hospitalContext: HospitalAccess;
}
@Injectable()
export class FeatureFlagService {
    constructor(private launchDarklyService: LaunchDarklyService) {}

    initialize(userSession: UserSession) {
        this.launchDarklyService.createInstance(userSession);
    }

    setHospital(
        context: InitializeHospitalPayload,
        hash?: string,
        onDone?: (err: null | Error, flags: null | LDFlagSet) => void
    ) {
        if (context.userContext) {
            const multiContext: LDClient.LDContext = {
                kind: 'multi',
                user: {
                    key: `user-${context.userContext.id}`,
                    firstName: context.userContext.first_name,
                    lastName: context.userContext.last_name,
                    email: context.userContext.email,
                },
                organization: {
                    key: `hospital-${context.hospitalContext.id}`,
                    name: context.hospitalContext.name,
                },
            };

            this.launchDarklyService.setContext(multiContext, hash, onDone);
            return;
        }

        const hospitalContext: LDClient.LDContext = {
            kind: 'multi',
            organization: {
                key: `hospital-${context.hospitalContext.id}`,
                name: context.hospitalContext.name,
            },
        };

        this.launchDarklyService.setContext(hospitalContext, hash, onDone);
    }

    getFeatureValue(key: FeatureFlagKey) {
        return this.launchDarklyService.getFlag(key);
    }
}
