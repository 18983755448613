import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Idle } from '@ng-idle/core';
import { ISubscription } from 'rxjs-compat/Subscription';

interface SessionTimeoutData {
    idle: Idle;
}

@Component({
    selector: 'session-timeout-warning-dialog',
    templateUrl: './session-timeout-warning-dialog.html',
})
export class SessionTimeoutWarningDialog implements OnInit {
    idle: Idle;
    msgText: string = 'seconds';
    timeToForcedLogout: number;
    private timeToForcedLogoutSubscription: ISubscription;
    private forceLogoutSubscription: ISubscription;

    constructor(
        public dialogRef: MatDialogRef<SessionTimeoutWarningDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SessionTimeoutData
    ) {}

    ngOnInit() {
        this.idle = this.data?.idle;
        this.timeToForcedLogoutSubscription = this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.timeToForcedLogout = countdown;
        });

        this.forceLogoutSubscription = this.idle.onTimeout.subscribe(() => {
            this.logout();
        });
    }

    extend() {
        this.removeSubscriptions();
        this.dialogRef.close({ extendSession: true });
    }

    logout(forcedLogout: boolean = true) {
        this.removeSubscriptions();
        this.dialogRef.close({ extendSession: false, forcedLogout: forcedLogout });
    }

    private removeSubscriptions() {
        this.timeToForcedLogoutSubscription?.unsubscribe();
        this.timeToForcedLogoutSubscription = null;
        this.forceLogoutSubscription?.unsubscribe();
        this.forceLogoutSubscription = null;
    }
}
