import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
    selector: '[min]',
    providers: [{ provide: NG_VALIDATORS, useExisting: KCMinValueValidateDirective, multi: true }],
})
export class KCMinValueValidateDirective implements Validator {
    @Input() min: number;

    validate(control: AbstractControl): { [key: string]: any } {
        // Use the Angular built-in 'min' Validator; exposing as attribute directive.
        return Validators.min(this.min)(control);
    }
}
