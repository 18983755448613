import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class KitMasterResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    kitMasterTypes(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kit_master_types`).toPromise();
    }

    kitMasterList(includeFromKits?: boolean, onBehalfOfHospitalId?: number): Promise<any> {
        let params: any = {};

        if (includeFromKits) {
            params.include_from_kits = includeFromKits;
        }

        if (onBehalfOfHospitalId && onBehalfOfHospitalId > -1) {
            params.on_behalf_of_hospital_id = onBehalfOfHospitalId;
        }

        return this.http.get<any>(`${this.API_ENDPOINT}kit_masters`, { params: params }).toPromise();
    }

    groupedKitMasterList(): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}kit_masters`).toPromise();
    }

    addKitMaster(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kit_masters`, data).toPromise();
    }

    cloneKitMaster(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kit_masters/${data.id}/clone`, data).toPromise();
    }

    updateKitMaster(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}kit_masters/${data.id}`, data).toPromise();
    }

    downloadSegments(kitMasterId: number, template?: boolean): Promise<any> {
        let options: any = { responseType: 'text' as 'json' };
        if (!!template) {
            options.params = {
                template: template,
            };
        }
        return this.http.get<any>(`${this.API_ENDPOINT}kit_masters/${kitMasterId}/download.csv`, options).toPromise();
    }

    archiveKitMaster(data): Promise<any> {
        return this.http.delete<any>(`${this.API_V2_ENDPOINT}kit_masters/${data.id}`).toPromise();
    }

    getChargeSheetPdf(kitMasterId: number) {
        const options: any = { responseType: 'blob' as 'json' };
        return this.http
            .get<any>(`${this.API_ENDPOINT}kit_masters/${kitMasterId}/charge_sheet_pdf`, options)
            .toPromise();
    }

    getChargeSheetPng(kitMasterId: number) {
        const options: any = { responseType: 'blob' as 'json' };
        return this.http
            .get<any>(`${this.API_ENDPOINT}kit_masters/${kitMasterId}/charge_sheet_png`, options)
            .toPromise();
    }

    getKitMasterAllowedLocations(kitMasterId: string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kit_masters/${kitMasterId}/allowed_locations`).toPromise();
    }

    getKitMasterAllowedCarts(kitMasterId: string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kit_masters/${kitMasterId}/allowed_carts`).toPromise();
    }

    updateKitMasterAllowedLocations(args: { id: string; permittedIds: any[] }): Promise<any> {
        const data = {
            location_ids: args.permittedIds,
        };
        return this.http.put<any>(`${this.API_ENDPOINT}kit_masters/${args.id}/allowed_locations`, data).toPromise();
    }

    updateKitMasterAllowedCarts(args: { id: string; permittedIds: any[] }): Promise<any> {
        const data = {
            cart_ids: args.permittedIds,
        };
        return this.http.put<any>(`${this.API_ENDPOINT}kit_masters/${args.id}/allowed_carts`, data).toPromise();
    }
}
