import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { GroupRolesResource } from '@resources/group-roles-resource.service';

@Injectable({ providedIn: 'root' })
export class GroupRolesResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private groupRolesResource: GroupRolesResource
    ) {}

    resolve(): Promise<any> {
        let groupRolesPromise = this.groupRolesResource.getGroupRoles().then((data) => {
            return data.group_roles;
        });

        return this.loadingSpinnerService.spinnerifyPromise(groupRolesPromise);
    }
}
