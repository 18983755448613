import { Component } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TableComponent } from '../table-component';
import { SortDirection } from '@services/utils/natural-sort.service';

@Component({
    selector: 'cart-expiring-soon',
    templateUrl: './cart-expiring-soon.html',
})
export class CartExpiringSoon extends TableComponent {
    constructor(private loadingSpinnerService: LoadingSpinnerService) {
        super();
    }

    ngOnInit() {
        this.defaultSort = { field: 'item_expiration', direction: SortDirection.asc };
        this.items = this.getExpiringSoonItems();
    }

    //had to make a similar copy to the one in cart-expirations because I can not pass down the one from cart-expirations otherwise the sorting will try to sort both the expired and expiring soon table
    getExpiringSoonItems(): Array<any> {
        let expiringSoonArray = [];
        this.items.map((kit) => {
            if (kit.expiring_soon_packages.length > 0) {
                kit.expiring_soon_packages.map((expiring_package) => {
                    expiring_package['kit_info'] = {
                        kit_master_name: kit.kit_master.name,
                        kit_physical_label: kit.physical_label,
                    };
                    expiringSoonArray.push(expiring_package);
                });
            }
        });
        return expiringSoonArray;
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    trackByFn(index: number, item: any): number {
        return item.id;
    }

    sortExpiringSoon(field: string): void {
        this.sortBy(field, this.items);
    }
}
