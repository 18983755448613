import { Pipe, PipeTransform } from '@angular/core';
import { unit } from '@utils/filterUtils';

@Pipe({
    name: 'uomScalar',
    pure: false,
})
export class UomScalarPipe implements PipeTransform {
    transform(attributeValue: any, zeroAsDash?: boolean, extraUomClasses?: string, extraScalarClasses?: string) {
        if (attributeValue) {
            if (zeroAsDash && attributeValue.scalar === 0) {
                return '&#8211;';
            } else {
                const uomUnit = unit(attributeValue);
                const template = `<span class="scalar ${extraScalarClasses}">${uomUnit.scalar}</span>&nbsp;<span class="uom ${extraUomClasses}">${uomUnit.uom}</span>`;
                return template;
            }
        }
    }
}
