import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

@Injectable({ providedIn: 'root' })
export class PanelSpinnerResolver {
    constructor(private loadingSpinnerService: LoadingSpinnerService) {}

    resolve(): Promise<any> {
        return Promise.resolve(this.loadingSpinnerService.useSpinnerType('panel'));
    }
}
