import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MidmarkDeviceBatteryResource } from '@resources/midmark-device-battery-resource.service';

@Injectable({ providedIn: 'root' })
export class MidmarkDeviceBatteryResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private midmarkDeviceBatteryResource: MidmarkDeviceBatteryResource
    ) {}

    resolve(): Promise<any> {
        return this.loadingSpinnerService
            .spinnerifyPromise(this.midmarkDeviceBatteryResource.lowBatteryStates())
            .then(({ midmark_device_batteries }) => {
                return midmark_device_batteries;
            });
    }
}
