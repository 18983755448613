import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { BinResource } from '@resources/bin-resource.service';

@Injectable({ providedIn: 'root' })
export class BinScanResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private binResource: BinResource
    ) {}

    resolve(): Promise<any> {
        const { binId, scanId } = this.$state.transition.params();
        return this.loadingSpinnerService.spinnerifyPromise(this.binResource.showBinScan(binId, scanId));
    }
}
