import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HardwareService } from '@services/hardware/hardware.service';

@Injectable({ providedIn: 'root' })
export class PrinterResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareService: HardwareService
    ) {}

    resolve(): Promise<any> {
        return this.loadingSpinnerService.spinnerifyPromise(this.hardwareService.getPrinters());
    }
}
