import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class PretaggedProblemResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    reportPretaggedIssue(params: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}pre_tagged_problems`, params).toPromise();
    }

    quarantineTags(params: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}pre_tagged_problems/tags`, params).toPromise();
    }

    getPretaggedProblems(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}pre_tagged_problems/problems`).toPromise();
    }

    getPretaggedReports(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}pre_tagged_problems`).toPromise();
    }

    getManufacturers() {
        return this.http.get<any>(`${this.API_ENDPOINT}catalogs/pre_tagged`).toPromise();
    }

    deletePretaggedReport(id: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}pre_tagged_problems/${id}`).toPromise();
    }
}
