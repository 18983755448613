import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'base-landscape',
    templateUrl: './base-landscape.html',
    styleUrls: ['../templates.scss'],
})
export class BaseLandscape implements OnInit {
    // Data
    @Input() chargeData;
    @Input() kitSummary;

    // The template refs for very specialized content
    @Input() customTemplate: TemplateRef<any>;

    // Other customizations
    @Input() showKitCheckLogo: boolean = true;

    @Input() showComments: boolean = true;
    @Input() showDateOfUnused: boolean = true;
    @Input() showDocumentWarning: boolean = false;
    @Input() showFooter: boolean = true;
    @Input() showPatientPlate: boolean = false;
    @Input() showYellowSeal: boolean = true;
    @Input() numberSignInRows: number;
    @Input() titleType: string = null;

    constructor() {}

    ngOnInit() {}
}
