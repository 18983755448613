import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

@Injectable({ providedIn: 'root' })
export class ArchivedFormularyItemsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService
    ) {}

    resolve(): Promise<any> {
        let archivedFormularyItemsPromise;

        archivedFormularyItemsPromise = this.formularyItemsService.getArchivedFormularyItems();

        return this.loadingSpinnerService.spinnerifyPromise(archivedFormularyItemsPromise);
    }
}
