import { Injectable } from '@angular/core';

import { BarcodeResource } from '@resources/barcode-resource.service';
import { TagResource } from '@resources/tag-resource.service';

@Injectable()
export class BarcodeService {
    constructor(
        private barcodeResource: BarcodeResource,
        private tagResource: TagResource
    ) {}

    barcodeObject(barcode) {
        let barcodeObject;
        return this.barcodeResource
            .barcodeObject(barcode)
            .then((data: any) => {
                barcodeObject = data;
            })
            .then(() => {
                const hasATag = ['Tag', 'Kit', 'Bin', 'Package'];
                if (hasATag.indexOf(barcodeObject.object.class) !== -1) {
                    return this.tagResource.getTag(barcode);
                }
            })
            .then((data: any) => {
                if (data) {
                    barcodeObject.object.decommissioned = data.tag.decommissioned;
                }
                return barcodeObject;
            });
    }
}
