import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

@Injectable({ providedIn: 'root' })
export class DictionaryEntryResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService
    ) {}

    resolve(): Promise<any> {
        const formularyPromise = this.formularyItemsService
            .getFormularyItemsByNDC(this.$state.transition.params().ndc)
            .then(({ items }) => items[0]);

        return this.loadingSpinnerService.spinnerifyPromise(formularyPromise);
    }
}
