import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[ndc]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NdcValidateDirective),
            multi: true,
        },
    ],
})
export class NdcValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return NdcValidator()(control);
    }
}

export function NdcValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            const cleanedNDC = control.value.replaceAll('-', '');
            if (control.value.length - cleanedNDC.length >= 3) {
                // too many dashes
                return { ndc: { value: control.value } };
            }

            if ((cleanedNDC.length !== 10 && cleanedNDC.length !== 11) || !cleanedNDC.match(/^[0-9\s\-]+$/)) {
                return { ndc: { value: control.value } };
            } else {
                return null;
            }
        }
        return null;
    };
}
