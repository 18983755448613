import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    hospital_ownership: string;
}

@Component({
    selector: 'hospital-ownership-filter',
    templateUrl: './hospital-ownership-filter.html',
    styleUrls: ['./hospital-ownership-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: HospitalOwnershipFilter }],
})
export class HospitalOwnershipFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null; // from report-filter
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeHospitalOwnership = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.hospital_ownership;
        }

        this.reset();
    }

    reset(): void {
        this.filterData = 'in_stock';
        this.apiFormattedData.emit(this.formatForApi());
        this.changeHospitalOwnership.emit(this.filterData);
    }

    filterHospitalOwnershipType(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeHospitalOwnership.emit(this.filterData);
        this.incomplete.emit({ filter: 'hospital_ownership', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): any {
        return [{ hospital_ownership: this.filterData }];
    }
}
