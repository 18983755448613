import { Component, Input } from '@angular/core';

@Component({
    selector: 'error-message',
    templateUrl: './error-message.html',
})
export class ErrorMessage {
    @Input() errorAlert: string;
    @Input() errorMessage: string;

    constructor() {}
}
