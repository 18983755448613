import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export enum LoadingSpinnerActions {
    SHOW = 'show',
    HIDE = 'hide',
    USE = 'use',
    KILL = 'kill',
}

export enum LoadingSpinnerTypes {
    DEFAULT = '',
    FULL = 'full', // doesnt appear to be used
    PANEL = 'panel',
    BACKDROP = 'backdrop',
}

export interface LoadingSpinnerData {
    action: LoadingSpinnerActions;
    type: LoadingSpinnerTypes;
}

@Injectable({
    providedIn: 'root',
})
export class LoadingSpinnerService {
    private loadingSpinnerObserver: BehaviorSubject<LoadingSpinnerData> = new BehaviorSubject(null);

    showSpinner: boolean = false;
    showFullSpinner: boolean = false;
    showPanelSpinner: boolean = false;
    showBackdropSpinner: boolean = false;

    constructor() {}

    observeLoadingSpinner(): Observable<LoadingSpinnerData> {
        return this.loadingSpinnerObserver;
    }

    kill() {
        this.loadingSpinnerObserver.next({ action: LoadingSpinnerActions.KILL, type: LoadingSpinnerTypes.DEFAULT });
    }

    useSpinnerType(type) {
        const converted = type as LoadingSpinnerTypes;
        this.loadingSpinnerObserver.next({ action: LoadingSpinnerActions.USE, type: converted });
    }

    spinnerifyPromise = (promise, type?: LoadingSpinnerTypes) => {
        this.loadingSpinnerObserver.next({
            action: LoadingSpinnerActions.SHOW,
            type: type ? type : LoadingSpinnerTypes.DEFAULT,
        });
        promise.finally(() => {
            setTimeout(() => {
                this.loadingSpinnerObserver.next({
                    action: LoadingSpinnerActions.HIDE,
                    type: LoadingSpinnerTypes.DEFAULT,
                });
            });
        });
        return promise;
    };

    spinnerStyles = () => {
        /* eslint-disable */
        const styles = {
            'show-spinner': this.showSpinner,
            'full-spinner': this.showFullSpinner,
            'panel-spinner': this.showPanelSpinner,
            'backdrop-spinner': this.showBackdropSpinner,
        };
        /* eslint-enable */
        return styles;
    };
}
