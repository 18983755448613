import { Directive, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[nonblank]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NonBlankValidateDirective),
            multi: true,
        },
    ],
})
export class NonBlankValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return nonBlankValidator()(control);
    }
}

export function nonBlankValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            return String(control.value).trim().length === 0 ? { nonblank: { value: control.value } } : null;
        }
        return null;
    };
}
