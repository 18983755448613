import { Injectable } from '@angular/core';

@Injectable()
export class ChartingService {
    constructor() {}

    addExtras(shape: any, data: KC.ISVGShape, tip?: any): any {
        if (data.class) {
            shape.classed(data.class, true);
        }

        if (data.transform) {
            shape.attr('transform', data.transform);
        }

        if (data.clipPath) {
            shape.attr('clip-path', `url(#${data.clipPath})`);
        }

        if (tip) {
            shape.on('mouseout', tip.hide);
            shape.on('mouseover', tip.show);
        }

        return shape;
    }

    addRect(container: any, data: KC.ISVGRect, tip?: any): any {
        const rect: any = container
            .append('rect')
            .attr('x', data.x)
            .attr('y', data.y)
            .attr('width', data.width)
            .attr('height', data.height);

        return this.addExtras(rect, data, tip);
    }

    addCircle(container: any, data: KC.ISVGCircle, tip?: any): any {
        const circle: any = container.append('circle').attr('cx', data.x).attr('cy', data.y).attr('r', data.radius);

        return this.addExtras(circle, data, tip);
    }

    addText(container: any, data: KC.ISVGText, tip?: any): any {
        const text: any = container.append('text').attr('x', data.x).attr('y', data.y).text(data.text);

        return this.addExtras(text, data, tip);
    }
}
