import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AeroscoutService } from '@services/hardware/aeroscout.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { TranslationService } from '@services/utils/translation.service';

// description and detail can contain html
interface ParamData {}

@Component({
    selector: 'aeroscout-login-dialog',
    templateUrl: './aeroscout-login-dialog.html',
})
export class AeroscoutLoginDialog {
    aeroscoutUrl: string;
    invalidCredentialsError: boolean = false;
    password: string;
    scannerError: boolean = false;
    spinning: boolean = true;
    urlError: boolean = false;
    username: string;

    constructor(
        private aeroscoutService: AeroscoutService,
        private localStorageService: LocalStorageService,
        public dialogRef: MatDialogRef<AeroscoutLoginDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        let networkPromises = [
            this.aeroscoutService.getAeroscoutUrl().then((url: string) => {
                this.aeroscoutUrl = url;
            }),
        ];

        Promise.all(networkPromises).then(() => (this.spinning = false));
    }

    login = () => {
        this.invalidCredentialsError = false;
        this.urlError = false;
        this.scannerError = false;
        this.spinning = true;
        this.localStorageService.set('aeroscoutCredentials', { username: this.username, password: this.password });
        this.aeroscoutService
            .aeroscoutLogin(this.aeroscoutUrl)
            .then((res) => {
                this.dialogRef.close(true);
            })
            .catch((e) => {
                this.localStorageService.remove('aeroscoutCredentials');
                if (e?.status === 401) {
                    this.invalidCredentialsError = true;
                } else if (e?.status === 404) {
                    this.urlError = true;
                } else {
                    this.scannerError = true;
                }
                this.spinning = false;
            });
    };

    skip = () => {
        this.username = '';
        this.password = '';
        this.login();
    };

    onCancel() {
        this.dialogRef.close();
    }
}
