import { UserRolesParam } from '@models/core/user-role-param';

export function getHighestHierarchyRole(user_roles: UserRolesParam[]): string {
    const userRolesSet = new Set(user_roles.map((role) => role.name));
    let roleName;
    for (roleName of Object.values(SortedRoleNames)) {
        if (userRolesSet.has(roleName)) {
            return roleName;
        }
    }
    // prevent code is not updated in sync with the backend
    return user_roles.length > 0 ? user_roles[0].name : '';
}

// the role names is sorted by corresponding hierarchy
const SortedRoleNames = {
    MANAGER: 'manager',
    PHARMACIST: 'pharmacist',
    PHARMACY_TECHNICIAN: 'pharmacy technician',
    REPORTER: 'reporter',
};

export { SortedRoleNames };
