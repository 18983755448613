import { Component, Inject } from '@angular/core';
import { FormControl, NgForm, NgModel } from '@angular/forms';

import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CanadianPackageVerificationService } from '@services/utils/canadian-package-verification.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { TranslationService } from '@services/utils/translation.service';

interface DialogData {
    isCanadian;
    item;
    item_package;
    prefillBatchVerificationEnabled;
    verification_item;
}

interface Item {
    epc?: string;
    item_ndc?: string;
    lot_number?: string;
    item_expiration?: string;
    item_formulary_type_string?: string;
    print_encoding_fail?: boolean;
    print_encoding_unconfirmed?: boolean;
    label?: string;
    trusted?: boolean;
    verified?: boolean;
    created_by?: any;
    created_by_role?: any;
    created_by_role_formatted?: string;
    creator?: string;
    item_bin?: any;
    trust?: any;
    item_expiration_fridge?: any;
    confirm_expiration_fridge?: any;
    item_expiration_multidose?: any;
    confirm_expiration_multidose?: any;
    requires_refrigeration?: boolean;
    multidose?: boolean;
    recalled?: boolean;
    id?: number;
    item_manufacturer_expiration?: any;
    tag?: any;
    verification?: any;
}

interface ItemPackage {
    size?: number;
    model?: {
        uom?: string;
        description_id?: string;
    };
}

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'verify-item-dialog',
    templateUrl: './verify-item-dialog.html',
    styleUrls: ['./verify-item-dialog.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class VerifyItemDialog {
    isCanadian: boolean;
    item: Item;
    item_package: ItemPackage;
    minDate: Date;
    ndcMaxLength: number;
    package_types: [];
    packageTypesLoading: boolean = false;
    prefillBatchVerificationEnabled: boolean;
    recalled: boolean;
    verification_item: {
        expireDisabled?: boolean;
        expiration_date?: Date;
        lot_num?: string;
        lotDisabled?: boolean;
        ndc?: string;
    };

    constructor(
        public dialogRef: MatDialogRef<VerifyItemDialog>,
        private canadianPackageVerificationService: CanadianPackageVerificationService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private parseFormErrorsService: ParseFormErrorsService,
        private translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.isCanadian = this.data.isCanadian;
        this.item = this.data.item;
        this.item_package = this.data.item_package;
        this.ndcMaxLength = this.isCanadian ? 8 : 25;
        this.prefillBatchVerificationEnabled = this.data.prefillBatchVerificationEnabled;
        this.recalled = this.item.recalled;
        this.verification_item = this.data.verification_item;

        const today = new Date().toISOString().slice(0, 10);
        this.minDate = new Date(today);

        this.package_types = null;
        if (!!this.verification_item.ndc) {
            this.checkVerificationNdc(this.verification_item.ndc);
        }
    }

    completed() {
        if (this.isCanadian) {
            return (
                !!this.verification_item.ndc &&
                (!!this.verification_item.lot_num || this.verification_item.lotDisabled) &&
                (!!this.verification_item.expiration_date || this.verification_item.expireDisabled) &&
                !!this.item_package.size &&
                !!this.item_package.model
            );
        } else {
            return (
                !!this.verification_item.ndc &&
                (!!this.verification_item.lot_num || this.verification_item.lotDisabled) &&
                (!!this.verification_item.expiration_date || this.verification_item.expireDisabled)
            );
        }
    }

    checkVerificationNdc(value): void {
        if (value) {
            const newVal = this.ndcScanUtilsService.extractNDCFromScan(value);

            if (newVal !== value) {
                this.verification_item.ndc = newVal;
            } else {
                this.verification_item.ndc = value;
            }
            if (!!this.isCanadian) {
                this.package_types = null;
                this.canadianPackageVerificationService.debouncedSendSearchToAPI(this);
            }
        }
    }

    customErrors(control: NgModel): string {
        let errString = this.parseErrors(control.errors);
        if (control.errors.mustmatch) {
            errString = this.translationService.instant('errors.dates_must_match');
        }

        if (control.errors.matDatepickerMin) {
            errString = this.translationService.instant('errors.non_past_date');
        }
        return errString;
    }

    parseErrors(errors: any): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        this.dialogRef.close({ verification_item: this.verification_item, item_package: this.item_package });
    }
}
