import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'kingwood',
    templateUrl: './kingwood.html',
})
export class Kingwood implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }
}
