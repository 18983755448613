import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class BinTagPackageResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    removeBinTagPackage(binId: number | string, tagId: number | string): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}bin_tag_packages/bins/${binId}/tags/${tagId}`).toPromise();
    }

    addBinTagPackage(binId: number | string, tagEpcs: string[]): Promise<any> {
        const data = {
            tag_epcs: tagEpcs,
        };

        return this.http.post<any>(`${this.API_ENDPOINT}bin_tag_packages/bins/${binId}/tags`, data).toPromise();
    }
}
