import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '@models/core/message';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialog } from '@dialogs/message/message-dialog';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'message-display',
    templateUrl: './message-display.html',
    styleUrls: ['./message-display.scss'],
})
export class MessageDisplay implements OnInit {
    @Input() message: Message;

    @Output() dismiss: EventEmitter<Message> = new EventEmitter<Message>();

    messageType: string;

    constructor(
        protected dialog: MatDialog,
        private $state: StateService
    ) {}

    ngOnInit() {
        this.setMessageType();
    }

    setMessageType() {
        switch (this.message.message_type.name) {
            case 'block':
                this.messageType = 'warn';
                break;
            case 'warn':
                this.messageType = 'info'; // maps to mat-info class in message-display.scss
                break;
            default:
                this.messageType = 'primary';
                break;
        }
    }

    setDismissBtnId(message) {
        if (message.recall_message) {
            return 'dismiss-recall-msg';
        } else if (message.active_recall_message) {
            return 'dismiss-active-recall-msg';
        } else if (message.kits_archive_message) {
            return 'dismiss-kits-archive-msg';
        } else {
            return `dismiss-msg-${message.id}`;
        }
    }

    dismissMessage() {
        this.dismiss.emit(this.message);
    }

    readMore() {
        this.dialog.open(MessageDialog, {
            width: '600px',
            height: 'max-content',
            data: { message: this.message, messageType: this.messageType },
        });
    }

    showKitsNeedingArchive() {
        this.$state.go('inventory', {
            kitsNeedingArchive: true,
        });
    }

    adminRecall() {
        this.$state.go('admin-deeplink', {
            targetState: 'item-recalls',
            targetParams: { ids: this.message.formulary_item_ids.join(',') },
        });
    }

    activeRecall(message) {
        this.$state.go('report-item-recalls', { recall_ids: String(message.recall_id) });
    }
}
