import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FirmwareRevisionsResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    downloadFirmwareRevision(id: number): Promise<any> {
        return this.http
            .get(`${this.API_ENDPOINT}firmware_revisions/${id}/download`, { responseType: 'arraybuffer' })
            .toPromise();
    }
}
