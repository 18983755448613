import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

interface ExtraFormData {
    name: string;
    value: any;
}

@Injectable()
export class FileUploadService {
    constructor(private http: HttpClient) {}

    getFormData(data, fileName, name?, extra?: ExtraFormData) {
        const fd = new FormData();
        const blob = new Blob([data]);
        name = name ? name : 'file';

        if (/*@cc_on!@*/ false || !!document['documentMode'] || !!window.StyleMedia) {
            fd.append(name, blob, fileName);
        } else {
            const fileOfBlob = new File([blob], fileName);
            fd.append(name, fileOfBlob);
        }

        if (extra) {
            fd.append(extra.name, extra.value);
        }

        return fd;
    }

    uploadArrayBuffer(url, arrayBuffer, fileName, name?, headers?, extra?: ExtraFormData): Promise<any> {
        return this.uploadFile(url, this.getFormData(arrayBuffer, fileName, name, extra), headers);
    }

    uploadFileHttp(url, file) {
        return this.http.post<any>(`${url}`, file).toPromise();
    }

    uploadFile(url, file, headers?): Promise<any> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = resolveXHR;

            function resolveXHR() {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr);
                    }
                }
            }

            xhr.open('POST', url, true);

            if (headers) {
                _.each(headers, (value, header) => {
                    xhr.setRequestHeader(header, value);
                });
            }

            xhr.send(file);
        });
    }
}
