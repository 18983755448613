import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';

import { HardwareService } from '@services/hardware/hardware.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { isEmpty } from '@utils/objects';

@Component({
    selector: 'admin-hardware-update',
    templateUrl: './admin-hardware-update.html',
})
export class AdminHardwareUpdate {
    //bindings
    @Input() hardware: any;

    originalHardware: any;

    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareService: HardwareService
    ) {}

    ngOnInit() {
        this.originalHardware = _.cloneDeep(this.hardware);
    }

    save(): void {
        //send only diff
        const updatedHardware: any = {};
        if (this.hardware.name !== this.originalHardware.name) {
            updatedHardware.name = this.hardware.name;
        }

        if (!isEmpty(updatedHardware)) {
            updatedHardware.id = this.hardware.id;
            const updatePromise = this.hardwareService.updateHardware(updatedHardware).then(() => {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.$state.go('admin.hardware'),
                    LoadingSpinnerTypes.PANEL
                );
            });
            this.loadingSpinnerService.spinnerifyPromise(updatePromise, LoadingSpinnerTypes.PANEL);
        }
    }
}
