import { Component } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'admin-home',
    templateUrl: './admin-home.html',
})
export class AdminHome {
    constructor() {}
}
