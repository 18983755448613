import { Component, Input } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TableComponent } from '@components/common/table-component';
import { SortDirection } from '@services/utils/natural-sort.service';
import { CartExpiringSoon } from '@components/common/cart-expiring-soon-table/cart-expiring-soon';

@Component({
    selector: 'cart-expirations',
    templateUrl: './cart-expirations.html',
})
export class CartExpirations extends TableComponent {
    expiringSoonData: Array<any>;

    constructor(private loadingSpinnerService: LoadingSpinnerService) {
        super();
    }

    ngOnInit() {
        this.defaultSort = { field: 'item_expiration', direction: SortDirection.desc };
        this.items = this.getExpiredItems();
    }

    //had to create this function because the table component is not able to sort keys nested through multiple objects and arrays
    //Ex: kits{[0.{expired_packages:[0.{keys needing sorting, kit_master: {name: 'blah blah'}},1.{}]}, 1.{}, 2.{}]}

    getExpiredItems(): Array<any> {
        let expiredItemsArray = [];
        this.expiringSoonData = this.items.slice(0);

        this.items.map((kit) => {
            if (kit.expired_packages.length > 0) {
                kit.expired_packages.map((expired_package) => {
                    expired_package['kit_info'] = {
                        kit_master_name: kit.kit_master.name,
                        kit_physical_label: kit.physical_label,
                    };
                    expiredItemsArray.push(expired_package);
                });
            }
        });
        return expiredItemsArray;
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    trackByFn(index: number, item: any): number {
        return item.id;
    }

    sortExpired(field: string): void {
        this.sortBy(field, this.items);
    }
}
