import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-fk-alert-banner',
    templateUrl: './fk-alert-banner.component.html',
    styleUrls: ['./fk-alert-banner.component.scss'],
})
export class FkAlertBannerComponent {
    @Input() fkTagsCount?: number;
    @Input() fkIgnoredTagsFlag?: boolean;
    @Input() fkIgnoredLots?: string = '';
}
