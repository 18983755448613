import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'remove-outer-package-dialog',
    templateUrl: './remove-outer-package-dialog.html',
})
export class RemoveOuterPackageDialog {
    problems: Array<any>;
    checkboxes: { [key: string]: boolean } = {};
    notes: String = '';

    constructor(
        public dialogRef: MatDialogRef<RemoveOuterPackageDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    remove() {
        this.dialogRef.close({ remove: true });
    }

    onCancel(): void {
        this.dialogRef.close({ remove: false });
    }
}
