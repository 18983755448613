import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { isAppUnderGracePeriodState, isAppUnderPostLockoutState } from '@utils/charge-model-util';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { HospitalResource } from '@resources/hospital-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { Message } from '@models/core/message';

interface SubscriptionTags {
    tags: Array<SubscriptionTag>;
}

interface SubscriptionTag {
    decomissioned: Boolean;
    epc: string;
    item_name: string;
    item_name_formatted: string;
    item_ndc: string;
    last_scan_date: string;
    subscription_required: Boolean;
    tag_id_formatted: string;
}

@Injectable()
export class ChargeModelService {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient,
        private hospitalInfoService: HospitalInfoService,
        private hospitalResource: HospitalResource,
        private translationService: TranslationService
    ) {}

    isHospitalUnderGracePeriodState() {
        const lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        const chargeModel = this.hospitalInfoService.getHospitalSettings().charge_model_type?.name;
        return (
            isAppUnderGracePeriodState(lockoutDate, chargeModel) &&
            !isAppUnderPostLockoutState(lockoutDate, chargeModel)
        );
    }

    buildLockoutGracePeriodNotification(): Promise<any> {
        return this.hospitalResource.getGracePeriodSubscriptionTags().then((response: SubscriptionTags) => {
            const lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
            let daysRemaining = moment(lockoutDate).diff(moment(), 'days');

            if (daysRemaining < 0) {
                daysRemaining = 0;
            }

            if (!response.tags || response.tags.length === 0) {
                return null;
            }

            const tagDescriptions = response.tags.map((tag) => {
                return tag.item_name_formatted || tag.item_ndc;
            });
            // only return unique item names
            const uniqueTags = [...new Set(tagDescriptions)];
            const extraItemCount = uniqueTags.length - 3;

            const daysRemainingText = this.translationService.instant(
                'lockout_dashboard_message.days_remaining_with_days',
                {
                    days: daysRemaining,
                }
            );
            const headerText = this.translationService.instant('lockout_dashboard_message.message_title', {
                items: response.tags.length,
            });

            let bodyText = '';
            if (extraItemCount > 0) {
                bodyText = this.translationService.instant('lockout_dashboard_message.description_with_items_plus', {
                    date: moment(lockoutDate).format('MM/DD/YYYY'),
                    items: uniqueTags.slice(0, 3).join(', '),
                    moreItems: extraItemCount,
                });
            } else {
                bodyText = this.translationService.instant('lockout_dashboard_message.description_with_items', {
                    date: moment(lockoutDate).format('MM/DD/YYYY'),
                    items: uniqueTags.slice(0, 3).join(', '),
                });
            }

            const lockoutMessage: Message = {
                chip_content: '',
                header: `${daysRemainingText} <br>${headerText}`,
                body: bodyText,
                can_dismiss: false,
                recall_message: false,
                kits_archive_message: false,
                published_at: moment().format('lll'),
                message_type: { name: 'info' },
                pin_to_top: true,
            };

            return lockoutMessage;
        });
    }
}
