import { Component, Input } from '@angular/core';
import { NaturalSortService } from '@services/utils/natural-sort.service';

@Component({
    selector: 'multi-kit-pick-list',
    templateUrl: './multi-kit-pick-list.html',
})
export class MultiKitPickList {
    @Input() kitData;
    @Input() hospitalLogo;

    constructor() {}
    naturalSort(segments) {
        return NaturalSortService.sort(segments, 'name');
    }
}
