import { Directive, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[nonpastdate]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NonPastDateValidateDirective),
            multi: true,
        },
    ],
})
export class NonPastDateValidateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return nonPastDateValidator()(control);
    }
}

export function nonPastDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            return moment().diff(control.value, 'days') > 0 ? { nonpastdate: { value: control.value } } : null;
        }
        return null;
    };
}
