import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { CartResource } from '@resources/cart-resource.service';
import * as _ from 'lodash';
import { KitMaster } from '@models/core/kit-master';
import { KitMasterResource } from '@resources/kit-master-resource.service';

@Component({
    selector: 'allowlist',
    templateUrl: './allowlist.html',
    styleUrls: ['./allowlist.scss'],
})
export class Allowlist {
    @Input() baseModel: KC.ICart | KitMaster;
    @Input() toAllowItems: KC.ILocation[] | KC.ICart[];
    @Input() translateKeyMap: string;
    @Input() resourceAttributeKey: string;

    denylistEntries: Array<any> = [];
    allowlistEntries: Array<any> = [];
    staticAllowlistEntries: Array<any> = []; // this will be the allowlist from the database, unaffected by searching
    staticDenylistEntries: Array<any> = []; // this will be the denylist from the database, unaffected by searching
    denylistToAllowCheckboxes: { [key: string]: boolean } = {};
    allowlistToDenyCheckboxes: { [key: string]: boolean } = {};
    useAllowlistToggle: boolean;
    selectAllToAllow: boolean = false;
    selectAllToExclude: boolean = false;
    moveToPermittedDisabled: boolean = true;
    moveToExcludedDisabled: boolean = true;
    searchToExclude: string = '';
    searchToAllow: string = '';

    resourceAttributeMap: any;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private cartResource: CartResource,
        private kitMasterResource: KitMasterResource
    ) {
        this.setupResourceMap();
    }
    ngOnInit() {
        this.initToggle();
        this.setupAllowlist();
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    setupAllowlist() {
        const getAllowedFn = this.getAllowed();
        return this.loadingSpinnerService.spinnerifyPromise(getAllowedFn(String(this.baseModel.id))).then((data) => {
            this.allowlistEntries =
                data[this.resolvedAllowlistKey()].filter((i) => {
                    const itemKey = Object.keys(i)[0]; // can be either location or cart
                    return i[itemKey] !== null;
                }) || [];
            this.staticAllowlistEntries = this.allowlistEntries;
            this.staticDenylistEntries = this.denylistEntries;
            const allowed_ids = this.allowlistEntriesIds();
            this.toAllowItems.forEach((item) => {
                if (allowed_ids.includes(item.id)) {
                    return;
                } else this.denylistEntries.push(item);
            });
            this.resetCheckboxes();
        });
    }

    resetCheckboxes() {
        this.denylistToAllowCheckboxes = {};
        this.allowlistToDenyCheckboxes = {};
        this.denylistEntries.forEach((item) => {
            this.denylistToAllowCheckboxes[item.id] = false;
        });
        this.allowlistEntries.forEach((item) => {
            this.allowlistToDenyCheckboxes[item[this.objectSelector()].id] = false;
        });
        this.refreshActions();
    }

    initToggle() {
        this.useAllowlistToggle = this.baseModel[this.allowlistToggleKey()];
    }

    enableAllowlist() {
        const resourceUpdate = this.updateBaseResource();
        this.baseModel[this.allowlistToggleKey()] = this.useAllowlistToggle;
        resourceUpdate(this.baseModelUpdateObject());
    }

    toggleSelectAllItemsToAllow(event) {
        const boolValue = this.selectAllToAllow;
        for (const key in this.denylistToAllowCheckboxes) {
            this.denylistToAllowCheckboxes[key] = boolValue;
        }
        this.refreshActions();
    }

    toggleSelectAllItemsToExclude(event) {
        const boolValue = this.selectAllToExclude;
        for (const key in this.allowlistToDenyCheckboxes) {
            this.allowlistToDenyCheckboxes[key] = boolValue;
        }
        this.refreshActions();
    }

    refreshActions() {
        if (this.itemsToAllowChecked()) {
            this.moveToPermittedDisabled = false;
        } else {
            this.moveToPermittedDisabled = true;
        }

        if (this.itemsToExcludeChecked()) {
            this.moveToExcludedDisabled = false;
        } else {
            this.moveToExcludedDisabled = true;
        }
    }

    moveItemToExcluded() {
        const updateAllowedFn = this.updateAllowed();
        if (this.itemsToExcludeChecked()) {
            const permittedIds = this.allowlistEntriesIds().filter(
                (n) => !this.checkedLocationsToExcludeIds().includes(n)
            );
            return this.loadingSpinnerService
                .spinnerifyPromise(updateAllowedFn({ id: String(this.baseModel.id), permittedIds: permittedIds }))
                .then(() => {
                    this.setDefaults();
                    this.setupAllowlist();
                });
        }
    }

    moveItemToPermitted() {
        const updateAllowedFn = this.updateAllowed();
        if (this.itemsToAllowChecked()) {
            const permittedIds = this.checkedLocationsToAllowIds().concat(this.allowlistEntriesIds());
            return this.loadingSpinnerService
                .spinnerifyPromise(updateAllowedFn({ id: String(this.baseModel.id), permittedIds: permittedIds }))
                .then(() => {
                    this.setDefaults();
                    this.setupAllowlist();
                });
        }
    }

    setDefaults() {
        this.denylistEntries = [];
        this.allowlistEntries = [];
        this.denylistToAllowCheckboxes = {};
        this.allowlistToDenyCheckboxes = {};
        this.selectAllToAllow = false;
        this.selectAllToExclude = false;
        this.searchToExclude = '';
        this.searchToAllow = '';
    }

    checkedLocationsToAllowIds(): Array<any> {
        let checked = [];
        for (const key in this.denylistToAllowCheckboxes) {
            if (this.denylistToAllowCheckboxes[key] === true) {
                checked.push(key);
            }
        }
        return checked;
    }

    checkedLocationsToExcludeIds(): Array<any> {
        let checked = [];
        for (const key in this.allowlistToDenyCheckboxes) {
            if (this.allowlistToDenyCheckboxes[key] === true) {
                checked.push(Number(key));
            }
        }
        return checked;
    }

    allowlistEntriesIds() {
        return this.staticAllowlistEntries.map((obj) => obj[this.objectSelector()].id);
    }

    itemsToAllowChecked() {
        return this.checkedLocationsToAllowIds().length > 0;
    }

    itemsToExcludeChecked() {
        return this.checkedLocationsToExcludeIds().length > 0;
    }

    applyToExcludeFilter() {
        this.uncheckAll();
        if (this.searchToExclude === '') {
            this.allowlistEntries = this.staticAllowlistEntries;
        } else {
            this.allowlistEntries = this.staticAllowlistEntries.filter(
                (n) => n[this.objectSelector()].name.toLowerCase().indexOf(this.searchToExclude.toLowerCase()) > -1
            );
        }
        this.resetCheckboxes();
    }

    clearSearchToExclude() {
        this.searchToExclude = '';
        this.applyToExcludeFilter();
    }

    applyToAllowFilter() {
        this.uncheckAll();
        if (this.searchToAllow === '') {
            this.denylistEntries = this.staticDenylistEntries;
        } else {
            this.denylistEntries = this.staticDenylistEntries.filter(
                (n) => n.name.toLowerCase().indexOf(this.searchToAllow.toLowerCase()) > -1
            );
        }
        this.resetCheckboxes();
    }

    clearSearchToAllow() {
        this.searchToAllow = '';
        this.applyToAllowFilter();
    }

    uncheckAll() {
        for (const key in this.denylistToAllowCheckboxes) {
            this.denylistToAllowCheckboxes[key] = false;
        }
        for (const key in this.allowlistToDenyCheckboxes) {
            this.allowlistToDenyCheckboxes[key] = false;
        }
        this.selectAllToAllow = false;
        this.selectAllToExclude = false;
    }

    translateWithKeyMap(property) {
        return `${this.translateKeyMap}.${property}`;
    }

    getAllowed() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['getAllowed'];
    }

    updateAllowed() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['updateAllowed'];
    }

    updateBaseResource() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['updateBaseResource'];
    }

    objectSelector() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['objectSelector'];
    }

    allowlistToggleKey() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['allowlistToggleKey'];
    }

    resolvedAllowlistKey() {
        return this.resourceAttributeMap[this.resourceAttributeKey]['resolvedAllowlistKey'];
    }

    baseModelUpdateObject() {
        const toUpdate = {};
        toUpdate[this.allowlistToggleKey()] = this.baseModel[this.allowlistToggleKey()];
        toUpdate['id'] = this.baseModel.id;
        return toUpdate;
    }

    setupResourceMap() {
        this.resourceAttributeMap = {
            cartLocationAllowlist: {
                getAllowed: this.cartResource.getCartAllowedLocations.bind(this.cartResource),
                updateAllowed: this.cartResource.updateCartAllowedLocations.bind(this.cartResource),
                updateBaseResource: this.cartResource.updateCart.bind(this.cartResource),
                objectSelector: 'location',
                allowlistToggleKey: 'use_location_allowlist',
                resolvedAllowlistKey: 'cart_location_allowlist',
                stripBaseModelForUpdate: [],
            },
            kitMasterLocationAllowlist: {
                getAllowed: this.kitMasterResource.getKitMasterAllowedLocations.bind(this.kitMasterResource),
                updateAllowed: this.kitMasterResource.updateKitMasterAllowedLocations.bind(this.kitMasterResource),
                updateBaseResource: this.kitMasterResource.updateKitMaster.bind(this.kitMasterResource),
                objectSelector: 'location',
                allowlistToggleKey: 'use_location_allowlist',
                resolvedAllowlistKey: 'kit_master_location_allowlist',
                stripBaseModelForUpdate: ['expiring_soon_days'],
            },
            kitMasterCartAllowlist: {
                getAllowed: this.kitMasterResource.getKitMasterAllowedCarts.bind(this.kitMasterResource),
                updateAllowed: this.kitMasterResource.updateKitMasterAllowedCarts.bind(this.kitMasterResource),
                updateBaseResource: this.kitMasterResource.updateKitMaster.bind(this.kitMasterResource),
                objectSelector: 'cart',
                allowlistToggleKey: 'use_cart_allowlist',
                resolvedAllowlistKey: 'kit_master_cart_allowlist',
                stripBaseModelForUpdate: ['expiring_soon_days'],
            },
        };
    }
}
