import { Component, Input, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'kc-mat-snack-bar',
    templateUrl: './kc-mat-snack-bar.html',
    styleUrls: ['./kc-mat-snack-bar.scss'],
})
export class KCMatSnackBar {
    constructor(
        public snackBarRef: MatSnackBarRef<KCMatSnackBar>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
