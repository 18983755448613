import { Injectable } from '@angular/core';

import { ApplicationService } from '@services/system/application.service';
import { BannerService } from '@services/system/banner.service';
import { FirmwareRevisionsResource } from '@resources/firmware-revisions-resource.service';
import { HardwareResource } from '@resources/hardware-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { ZebraResource } from '@resources/zebra-resource.service';

import { IFirmwareRevision } from '@models/hardware/firmware-revisions';
import { Scanner } from '@models/hardware/scanner';

@Injectable()
export class ZebraService {
    // service for using fx9600 & fx7500 Zebra scanner models
    // THIS IS NOT FOR THE fx9500 - see motorola.service.ts for fx9500

    constructor(
        private applicationService: ApplicationService,
        private bannerService: BannerService,
        private hardwareResource: HardwareResource,
        private firmwareRevisionsResource: FirmwareRevisionsResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private scannerUpdatingService: ScannerUpdatingService,
        private zebraResource: ZebraResource
    ) {}

    scan(scanner: Scanner): Promise<any> {
        return this.zebraResource.scan(scanner).then((result) => {
            // pass just the tags array back for compatibility
            return result.data.tags;
        });
    }

    checkStatus(scanner: Scanner): Promise<any> {
        if (scanner.statusCheckPromise) {
            return scanner.statusCheckPromise;
        }

        scanner.loading = true;

        scanner.statusCheckPromise = this.setFirmwareRevisionsForScanner(scanner)
            .then((currentFirmwareRevisionsOnScanner: IFirmwareRevision[]) => {
                scanner.online = 'YES';
                return this.checkNeedsUpdate(scanner, currentFirmwareRevisionsOnScanner);
            })
            .then(() => {
                if (scanner.needsUpdate.length) {
                    scanner.online = 'UPGRADE';
                } else {
                    scanner.online = 'YES';
                }
            })
            .catch(() => {
                console.log(`${scanner.name}: device offline`);
                scanner.online = 'NO';
                scanner.needsUpdate = _.compact([
                    _.find(scanner.hardware_type_firmware_revisions, { name: 'kc-sargas' }),
                ]);
            })
            .finally(() => {
                scanner.loading = false;
                delete scanner.statusCheckPromise;
            });

        return scanner.statusCheckPromise;
    }

    updateSSL(scanner: Scanner) {
        console.error('Scanner update SSL called on incompatible scanner type');
    }

    updateFirmware(scanner: Scanner) {
        console.log('TIME TO UPDATE YOUR FIRMWARE: ', scanner);

        this.bannerService.systemCaution =
            "Scanner update in progress. Please don't use the scanner or close the browser window until the update is complete.";
        this.bannerService.systemCautionNotDismissable = true;
        window.onbeforeunload = () => {
            return 'The scanner is currently being updated. Please stay on this page until the update is complete. Are you sure you want to leave this page?';
        };

        this.scannerUpdatingService.isUpdating = true;

        scanner.loading = true;
        scanner.updating = true;

        const totalSteps = scanner.needsUpdate.length * 2;

        const progress = (stepPercentage, message) => {
            const remainingSteps = scanner.needsUpdate.length + (1 - stepPercentage);
            const remainingPercentage = 1 - remainingSteps / totalSteps;
            this.scannerUpdatingService.updateProgress(remainingPercentage, message);
        };

        let errorEncountered = false;

        const installNextRevision = () => {
            if (scanner.needsUpdate.length) {
                const firmwareRevision = scanner.needsUpdate.pop();
                const targetUrl =
                    firmwareRevision.firmware_revision_type.indexOf('safe') !== -1 ? scanner.value : scanner.safeValue; // each pkg can only update its counterpart
                console.log('Installing firmware revision: ', firmwareRevision);
                progress(
                    0.1,
                    `Connecting to Update Service for ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                );
                return this.checkAlive(targetUrl)
                    .then(() => {
                        progress(
                            0.25,
                            `Downloading Revision File for ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                        );
                        return this.firmwareRevisionsResource.downloadFirmwareRevision(firmwareRevision.id);
                    })
                    .then((file) => {
                        console.log('Got revision file: ', file);
                        progress(
                            0.5,
                            `Installing firmware revision ${firmwareRevision.firmware_revision_type} ${firmwareRevision.version}`
                        );
                        const fileName = `${firmwareRevision.firmware_revision_type}_${firmwareRevision.version}.deb`;
                        return this.sendFirmwareUpdate(targetUrl, file, fileName, scanner.dynamic_ips[0]);
                    })
                    .then((response) => {
                        console.log('Firmware upgrade success: ', response?.success, ' - message: ', response?.message);
                        progress(1, `Firmware upgrade status: ${response?.message}`);
                        if (!response?.success) {
                            return Promise.reject(response?.message);
                        } else {
                            return installNextRevision();
                        }
                    })
                    .catch((e) => {
                        errorEncountered = true;
                        console.log(
                            `Firmware upgrade error during install of ${firmwareRevision.firmware_revision_type}_${firmwareRevision.version}`,
                            e
                        );
                        return Promise.reject();
                    });
            } else {
                return Promise.resolve();
            }
        };

        return installNextRevision()
            .catch((err) => {
                errorEncountered = true;
                console.warn('Update error: ', err);
            })
            .finally(() => {
                const refreshUpdateBanner = () => {
                    this.bannerService.systemCaution = undefined;
                    this.bannerService.systemCautionNotDismissable = undefined;
                    window.onbeforeunload = null;
                    this.scannerUpdatingService.isUpdating = false;
                    scanner.loading = false;
                    scanner.updating = false;
                };

                if (errorEncountered) {
                    refreshUpdateBanner();
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'modals.scanner_update.update_error',
                    });
                } else {
                    this.checkNeedsUpdate(scanner).finally(() => {
                        refreshUpdateBanner();
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'modals.scanner_update.update_success',
                        });
                        if (scanner.needsUpdate.length) {
                            scanner.online = 'UPGRADE';
                        } else {
                            scanner.online = 'YES';
                        }
                    });
                }
            });
    }

    updateHardwareConfig(scanner: Scanner) {
        return this.zebraResource
            .getFirmwareConfig(scanner)
            .then((data) => {
                if (data) {
                    return this.hardwareResource.hardwareConfig(scanner.id, data);
                }
                return Promise.reject();
            })
            .catch((err) => {
                Promise.reject(err);
            });
    }

    private sendFirmwareUpdate(scannerUrl, file, fileName, scannerIp) {
        console.log('Sending upgrade for: ', fileName);
        return this.zebraResource.sendFirmwareUpdateFile(scannerUrl, file, fileName, scannerIp).catch((e) => {
            console.log(`First upgrade attempt of ${fileName} failed, trying again.`);
            return new Promise((resolve) => setTimeout(resolve, 10000)).then(() => {
                return this.zebraResource.sendFirmwareUpdateFile(scannerUrl, file, fileName, scannerIp);
            });
        });
    }

    private checkAlive(scannerUrl: string) {
        console.log(`Check ${scannerUrl} alive...`);
        return this.zebraResource
            .firmwareVersionInfo(scannerUrl)
            .then((result) => {
                if (result) {
                    Promise.resolve(result);
                } else {
                    // if result is null
                    // CORS likely blocked error info
                    // so assume the scanner endpoint is not online
                    Promise.reject();
                }
            })
            .catch(() => {
                return new Promise((resolve) => setTimeout(resolve, 12500)).then(() => {
                    return this.checkAlive(scannerUrl);
                });
            });
    }

    private checkFirmwareVersions(scanner: Scanner): Promise<IFirmwareRevision[]> {
        // check versions of both pkgs
        let firmwareVersionPromises = [
            this.zebraResource.firmwareVersionInfo(scanner.value),
            this.zebraResource.firmwareVersionInfo(scanner.safeValue),
        ];

        return Promise.all(firmwareVersionPromises).then((firmwareVersionsFromScanner) => {
            let mappedFirmwareVersions = firmwareVersionsFromScanner.map((firmwareVs) => {
                return {
                    version: firmwareVs.version,
                    firmware_revision_type: firmwareVs.name,
                } as IFirmwareRevision;
            });
            return mappedFirmwareVersions;
        });
    }

    private setFirmwareRevisionsForScanner(scanner: Scanner): Promise<IFirmwareRevision[]> {
        let firmwareRevisions;
        return this.checkFirmwareVersions(scanner)
            .then((result) => {
                firmwareRevisions = result;
                // unfortunately these identical yet differently named arrays tie into existing scanner upgrade functionality
                scanner.firmware_revisions = result;
                scanner.firmwareRevisions = result;

                this.applicationService.suppressError = true; // suppress benign errors from user display
                return firmwareRevisions.length
                    ? this.hardwareResource.setFirmwareRevisions(scanner.id, result)
                    : Promise.resolve();
            })
            .then((): IFirmwareRevision[] => firmwareRevisions)
            .finally(() => {
                this.applicationService.suppressError = false;
            });
    }

    private checkNeedsUpdate(scanner, firmwareRevisions?) {
        const getFirmwareRevisions = firmwareRevisions
            ? Promise.resolve(firmwareRevisions)
            : this.setFirmwareRevisionsForScanner(scanner);

        return getFirmwareRevisions.then((revisions) => {
            firmwareRevisions = revisions;
            scanner.needsUpdate = [];
            _.each(scanner.hardware_type_firmware_revisions, (revisionForType: IFirmwareRevision) => {
                const revisionOnScanner: IFirmwareRevision = _.find(firmwareRevisions, {
                    firmware_revision_type: revisionForType.firmware_revision_type,
                }) as IFirmwareRevision;
                if (!revisionOnScanner || revisionForType.version !== revisionOnScanner.version) {
                    scanner.needsUpdate.push(revisionForType);
                }
            });
        });
    }

    // functions below will be utilized when kc-zebra-fx v2.x.x is available
    // aeroscout functions
    aeroscoutLogin() {
        // these are not being implemented for the fx9600 & fx7500 at this time
    }

    aeroscoutSearch() {
        // these are not being implemented for the fx9600 & fx7500 at this time
    }
}
