import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ActivityResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    activity(secondsSinceActivity: string): Promise<any> {
        return this.http
            .post<any>(`${this.API_V2_ENDPOINT}activity`, { seconds_since_activity: secondsSinceActivity })
            .toPromise();
    }
}
