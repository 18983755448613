import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

// description and detail can contain html
interface DialogData {
    items: Array<any>;
}

@Component({
    selector: 'unquarantine-pretagged-dialog',
    templateUrl: './unquarantine-pretagged-dialog.html',
})
export class UnquarantinePretaggedDialog {
    constructor(
        public dialogRef: MatDialogRef<UnquarantinePretaggedDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        this.dialogRef.close(true);
    }
}
