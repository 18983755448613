import { Injectable } from '@angular/core';

import { BannerService } from '@services/system/banner.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { ScanningResource } from '@resources/scanning-resource.service';
import { ScannerService } from '@services/hardware/scanner.service';
import { TranslationService } from '@services/utils/translation.service';

import { processScanResult, getScanError } from '@utils/scanning-util';
import { ScanData, ScanResult, ScanError } from '@models/core/scan';
import { Scanner } from '@models/hardware/scanner';

@Injectable()
export class ScanningService {
    constructor(
        private bannerService: BannerService,
        private hospitalInfoService: HospitalInfoService,
        private scannerService: ScannerService,
        private scanningResource: ScanningResource,
        private translationService: TranslationService
    ) {}

    initiateScan(scanner: Scanner): Promise<any> {
        return this.scannerService.scan(scanner).catch(() => {
            throw this.getFailedScanError();
        });
    }

    sendScanData(scanData, hardwareId: number): Promise<any> {
        return this.scanningResource
            .sendScanData(this.getProcessedScanData(scanData, hardwareId))
            .then((scanResult: ScanResult) => {
                this.bannerService.systemCautionNephron = '';
                scanResult.scanError = null;

                if (!!scanResult.contains_restricted_nephron_tags) {
                    this.translationService.get(['scan.nephron_alert']).then((translation) => {
                        this.bannerService.systemCautionNephron = translation['scan.nephron_alert'];
                    });
                }

                if (
                    scanResult.view === 'scan_error' ||
                    (scanResult.view === 'bin_scan_summary' && !this.hospitalInfoService.allowShelvedInventory())
                ) {
                    scanResult.scanError = getScanError(
                        scanResult.payload_composition_type,
                        scanResult.associated_tags,
                        scanResult.unassociated_tags
                    );
                    this.translationService.get([scanResult.scanError.errorMessage]).then((translation: string[]) => {
                        scanResult.scanError.errorTranslated = translation[scanResult.scanError.errorMessage];
                    });
                }

                return scanResult;
            })
            .catch((error: any) => {
                if (error.status === 429) {
                    const concurrentError: ScanError = getScanError('scan_currently_processing');
                    this.translationService.get([concurrentError.errorMessage]).then((translation: string[]) => {
                        concurrentError.errorTranslated = translation[concurrentError.errorMessage];
                    });
                    throw concurrentError;
                } else if (error.__status === 422 && error.message) {
                    let scanError = new ScanError();
                    scanError.errorMessage = error.message;
                    scanError.closeScanModal = true;
                    throw scanError;
                } else {
                    throw this.getFailedScanError();
                }
            });
    }

    private getProcessedScanData(scanData, hardwareId: number): ScanData {
        try {
            return processScanResult(scanData, hardwareId);
        } catch (e) {
            let error = this.getFailedScanError();
            error.dataProcessError = true;
            throw error;
        }
    }

    private getFailedScanError(): ScanError {
        const failedScan = getScanError('scan_failed');
        this.translationService.get([failedScan.errorMessage]).then((translation: string[]) => {
            failedScan.errorTranslated = translation[failedScan.errorMessage];
        });
        return failedScan;
    }
}
