import { Input, OnInit, Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs/Subject';

import { MatSnackBar } from '@angular/material/snack-bar';

import FilterUtils from '@utils/filterUtils';

import { ApplicationService } from '@services/system/application.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TagAssociationService } from '@services/core/tag-association.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'tag-association',
    templateUrl: './tag-association.html',
    styleUrls: ['./tag-association.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagAssociation {
    @Input() tag;
    @Input() type;
    @Input() triggerAssociate: Subject<boolean>;
    @Input() associateForm: NgForm;

    savingLabels: boolean = false;
    snackBarRef: MatSnackBar;
    systemErrorTranslated: string;

    constructor(
        private applicationService: ApplicationService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private tagAssociationService: TagAssociationService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.systemErrorTranslated = this.translationService.instant('tagging.system_error');
        this.triggerAssociate.subscribe((proxyTag) => {
            this.saveLabels();
        });
    }

    associateTags() {
        const formularyItemId = this.tag.item.formulary_item_id;
        const labels = this.tag.labels;
        const onBehalfOfHospital = this.tag.onBehalfOfHospital ? this.tag.onBehalfOfHospital.id : null;
        const expiration = this.tag.expireDisabled ? null : this.tag.expire;
        const lot = this.tag.lotDisabled ? null : this.tag.lot;

        let calculateFridgeDate;
        if (this.tag.item.expiring_fridge_days) {
            calculateFridgeDate = this.tag.calculate_fridge_date;
        }

        let binId;
        if (this.tag.addToBin && !isNaN(parseInt(this.tag.addToBin))) {
            binId = parseInt(this.tag.addToBin);
        }

        return this.tagAssociationService.associateTags(
            formularyItemId,
            labels,
            onBehalfOfHospital,
            expiration,
            lot,
            calculateFridgeDate,
            binId
        );
    }

    associateValidator(): boolean {
        return this.canSave();
    }

    canSave() {
        // disable add button if pretagged and not overridden
        let preTagStateOK = true;
        if (this.tag.isPreTagged && !this.tag.forcePreTagReprint) {
            preTagStateOK = false;
        }
        return !this.savingLabels && this.tag.labels.length > 0 && preTagStateOK;
    }

    saveLabels() {
        if (this.savingLabels) {
            const alreadySaving = this.translationService.instant('tagging_batch.saving_labels');
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, alreadySaving);
            return;
        }

        this.applicationService.suppressError = true;
        this.savingLabels = true;
        const associateTagsSuccess = () => {
            const longName = FilterUtils.packageLongName(this.tag.item, true);
            this.translationService
                .get('print_item.has_been_added_to_kit_check', {
                    length: this.tag.labels.length,
                    longName: longName,
                    formatted: this.tag.item.package_size_formatted,
                    uom: this.tag.item.package_size_uom,
                    descriptionName: this.tag.item.package_description_name,
                })
                .then((translation) => {
                    this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
                });
        };

        const assocateTagsError = (data) => {
            const status = data.__status;
            let msg;
            if (status === 406 || status === 422) {
                msg = data.errors[0].message;
            } else {
                msg = this.systemErrorTranslated;
            }
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, msg);
        };

        const noLongerSavingLabels = () => {
            this.applicationService.suppressError = false;
            this.savingLabels = false;
        };

        this.associateTags().then(associateTagsSuccess).catch(assocateTagsError).finally(noLongerSavingLabels);
    }
}
