import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { BinResource } from '@resources/bin-resource.service';
import { Bin } from '@models/core/bin';
import { ClinicalEquivalence } from '@models/core/clinical-equivalence';
import { isEmpty } from '@utils/objects';

type AddType = 'generic' | 'specific';

interface FormularyItem {
    package_volume: string;
    package_volume_uom: string;
    clinical_equivalence_id: number;
    formulary_item_id: number;
}

interface ParamData {
    titleType: string; // should be the translated value for bin
    bin: Bin;
    formularyItem: FormularyItem;
    ceqs: ClinicalEquivalence[];
}

@Component({
    selector: 'add-formulary-item-bin-dialog',
    templateUrl: './add-formulary-item-bin-dialog.html',
    styleUrls: ['../add-formulary-item.scss'],
})

/*
 ** This dialog replaces the Modal Service dialog that could add to
 ** BinScans or Segments. The new dialog only adds to Bins
 */
export class AddFormularyItemBinDialog {
    bin: Bin;
    ceq: ClinicalEquivalence;
    disabledGeneric: boolean;
    errorMessage: string;
    formularyItem: FormularyItem;
    loading: boolean;
    scanId: number;
    step: number;
    type: AddType;

    constructor(
        public dialogRef: MatDialogRef<AddFormularyItemBinDialog>,
        private binResource: BinResource,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.formularyItem = this.data.formularyItem;
        this.bin = this.data.bin;
        this.ceq = this.data.ceqs.find((ceq) => ceq.id === this.data.formularyItem.clinical_equivalence_id);
        this.type = !this.disableGeneric() ? 'generic' : 'specific';
        this.disabledGeneric = this.disableGeneric();
    }

    clearErrorMessage() {
        this.errorMessage = undefined;
    }

    selectType(selectedType: AddType) {
        if (selectedType === 'generic' && this.disableGeneric()) {
            return;
        }

        this.type = selectedType;
    }

    disableGeneric() {
        return !this.ceq || isEmpty(this.formularyItem.package_volume);
    }

    onCancel() {
        this.dialogRef.close();
    }

    addToBin(type: AddType) {
        let data;
        this.loading = true;
        switch (type) {
            case 'generic':
                data = this.addBinGenericFormularyItem();
                break;
            case 'specific':
                data = this.addBinFormularyItem();
                break;
        }

        this.binResource
            .createBinSegment(this.bin.id, data)
            .then(() => {
                this.dialogRef.close(true);
            })
            .catch((err) => {
                this.errorMessage = err.message;
                this.loading = false;
            });
    }

    private ceqHash(obj) {
        return {
            clinical_equivalence_id: obj.clinical_equivalence_id,
            capacity: {
                scalar: obj.package_size_formatted || obj.package_volume,
                uom: obj.package_size_uom || obj.package_volume_uom,
            },
        };
    }

    private addBinGenericFormularyItem() {
        let clinical_equivalence_hashes = this.bin.segment_template_clinical_equivalences.map((segmentTemplateCeq) => {
            return this.ceqHash(segmentTemplateCeq);
        });

        let newCeq = this.ceqHash(this.formularyItem);

        if (
            !_.find(clinical_equivalence_hashes, {
                clinical_equivalence_id: newCeq.clinical_equivalence_id,
                package_volume_formatted: newCeq.capacity.scalar,
                package_volume_uom: newCeq.capacity.uom,
            })
        ) {
            clinical_equivalence_hashes.push(newCeq);
        }

        return {
            clinical_equivalence_hashes,
        };
    }

    private addBinFormularyItem() {
        let formulary_item_ids = this.bin.segment_template_formulary_items.map((item) => item.formulary_item_id);
        let requestedFormularyItem = this.formularyItem.formulary_item_id;

        if (formulary_item_ids.indexOf(requestedFormularyItem) === -1) {
            formulary_item_ids.push(requestedFormularyItem);
        }

        return {
            formulary_item_ids,
        };
    }
}
