import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'shelved-inventory',
    templateUrl: './shelved-inventory.html',
    styleUrls: ['./shelved-inventory.scss'],
})
export class ShelvedInventory {
    @Input() dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = [
        'bin_name',
        'par_level',
        'low_alert_level',
        'qty_on_hand',
        'last_scan_date',
        'navigate',
    ];
    constructor() {}
}
