import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { TableComponent } from '@components/common/table-component';
import { SortDirection } from '@services/utils/natural-sort.service';

@Component({
    selector: 'bin-details',
    templateUrl: './bin-details.html',
    styleUrls: ['./bin-details.scss'],
})
export class BinDetails extends TableComponent {
    @Input() wrongItems: Array<any>;
    @Input() expiringSoonDetails: any;

    constructor() {
        super();
    }

    ngOnInit() {
        this.sortBy('name', this.items);
        this.defaultSort = { field: 'name', direction: SortDirection.asc };
    }

    sortAllItems(field: string): void {
        this.sortBy(field, this.items);
    }

    expiredOrExpiringSoonClass = (item) => {
        let now = new Date();
        let expirationDate = new Date(item.expiration);

        if (now.getTime() > expirationDate.getTime()) {
            return 'expired';
        } else if (
            _.find(this.expiringSoonDetails.expiring_soon, {
                item_snapshot_id: item.item_snapshot_id,
            }) !== undefined
        ) {
            return 'expiring';
        }
    };
}
