import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ApplicationService } from '@services/system/application.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BroadcastMessageService } from '@services/login/broadcast-message.service';
import { ChurnZeroService } from '@services/vendor/churn-zero.service';
import { DatadogService } from '@services/vendor/datadog.service';
import { GainsightService } from '@services/vendor/gainsight.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { LoginStateService, LoginStateTransitions, LoginStateParams } from '@services/login/login-state.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';
import { SessionResource } from '@resources/session-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserInfoService } from '@services/login/user-info.service';
import { UserSessionTimeoutService } from '@services/login/user-session-timeout.service';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { Subscription } from 'rxjs';

@Injectable()
export class LoginService {
    initialized: boolean;
    loginSubscription: Subscription;
    private listenerId: number;

    constructor(
        private applicationService: ApplicationService,
        private barcodeScanService: BarcodeScanService,
        private broadcastMessageService: BroadcastMessageService,
        private churnZeroService: ChurnZeroService,
        private datadogService: DatadogService,
        private gainsightService: GainsightService,
        private groupLoginService: GroupLoginService,
        private hardwareService: HardwareService,
        private featureFlagService: FeatureFlagService,
        private loginStateService: LoginStateService,
        private ngxLoginEventService: NgxLoginEventService,
        private sessionResource: SessionResource,
        private $state: StateService,
        private translationService: TranslationService,
        private userInfoService: UserInfoService,
        private userSessionTimeoutService: UserSessionTimeoutService
    ) {
        this.loginStateService.observeLoginState().subscribe((loginState: LoginStateParams) => {
            // On first init, it's possible that they are already logged in
            if (!loginState && !!this.groupLoginService.isLoggedIn()) {
                this.onLogin();
            } else if (loginState?.transition === LoginStateTransitions.LOGGING_IN) {
                this.login();
            } else if (loginState?.transition === LoginStateTransitions.LOGGING_OUT) {
                this.onLogout();
            }
        });
    }

    onLogout() {
        this.initialized = false;
        this.ngxLoginEventService.deregisterOnHospitalSelectListener(this.listenerId);
    }

    login() {
        if (!!this.applicationService.user.id && !this.initialized) {
            this.onLogin();
        }

        if (!this.initialized) {
            this.listenerId = this.ngxLoginEventService.registerOnHospitalSelectListener(async () => {
                if (this.applicationService.isMobile) {
                    // force route to mobile only after hospital selection
                    this.$state.go('mobile-cart.landing');
                }
                this.onLogin();
            });
        }

        this.initialized = true;
    }

    onLogin() {
        this.sessionResource
            .verifyLoginSession()
            .then(({ user }) => {
                this.featureFlagService.initialize(user);
                this.translationService.setToHospitalLocale();
                this.gainsightService.onLogin();
                this.barcodeScanService.listenForBarcode();
                this.broadcastMessageService.getUserMessages();
                this.userInfoService.setUserInfo();
                this.userSessionTimeoutService.start();
                this.churnZeroService.onLogin();
                this.datadogService.onLogin();
                this.hardwareService.onLogin();
            })
            .catch(() => {
                this.loginStateService.notifyLogout();
            });
    }
}
