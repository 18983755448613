import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ActionService } from '@services/utils/action.service';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserListService } from '@services/core/user-list.service';
import { UserResource } from '@resources/user-resource.service';

import { GroupRole } from '@models/core/group';

@Component({
    selector: 'admin-users-profile',
    templateUrl: './admin-users-profile.html',
})
export class AdminUsersProfile {
    //bindings
    @Input() user: any;

    actionAllow: Function;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService,
        private userListService: UserListService,
        private userResource: UserResource
    ) {}

    ngOnInit() {
        this.actionAllow = this.actionService.allowModule('user_settings');
        this.user.role_names_for_group = this.userRoleNames(this.user.roles_for_group);
    }

    userRoleNames(groupRoles: GroupRole[]): string {
        return groupRoles[0].roles
            .sort((a, b) => {
                return Number(a.inherited) - Number(b.inherited) || a.name.localeCompare(b.name);
            })
            .map((role) => {
                if (role.inherited) {
                    return `<i>${role.name}</i>`;
                } else {
                    return `<b>${role.name}</b>`;
                }
            })
            .join(', ');
    }

    async remove() {
        const description = await this.translationService.get('modals.confirm_delete.confirm', {
            objName: this.user.full_name,
        });
        const objType = await this.translationService.get('admin.users.user');
        const title = await this.translationService.get('modals.confirm_delete.header', { objType: objType });

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: title,
                description: description,
                okButton: this.translationService.instant('buttons.delete'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const removePromise = this.userResource
                    .removeUser(this.user)
                    .then(() => {
                        this.userListService.clear();
                        return this.loadingSpinnerService.spinnerifyPromise(
                            this.$state.go('admin.users'),
                            LoadingSpinnerTypes.PANEL
                        );
                    })
                    .then(() => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'admin.users.deleted_user',
                            params: { firstName: this.user.first_name, lastName: this.user.last_name },
                        });
                    })
                    .catch((err) => {
                        this.translationService.get('admin.users.errors.could_not_remove_user').then((translation) => {
                            let message = err.message ? err.message : translation;
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                        });
                    });

                this.loadingSpinnerService.spinnerifyPromise(removePromise, LoadingSpinnerTypes.PANEL);
            }
        });
    }
}
