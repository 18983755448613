import { OnInit, Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { BannerService } from '@services/system/banner.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { UserInfoService } from '@services/login/user-info.service';

import { titleize } from '@utils/strings';

@Component({
    selector: 'header',
    templateUrl: './header.html',
    styleUrls: ['./header.scss'],
})
export class Header {
    demoLinks: any;
    scanners: any;
    isDemo: boolean;
    showScanButton: boolean;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private applicationService: ApplicationService,
        private bannerService: BannerService,
        private configuration: ConfigurationProvider,
        private groupLoginService: GroupLoginService,
        private hardwareService: HardwareService,
        private scannerUpdatingService: ScannerUpdatingService,
        private userInfoService: UserInfoService
    ) {}

    ngOnInit() {
        this.isDemo = this.configuration.isDemo();
        this.userInfoService.setUserInfo();
        // workaround for old bower component
        // so that we don't redirect to login for password-reset
        // this should change when the old bower component is removed
        if (this.$state.current.url !== '/password-reset') {
            this.groupLoginService.checkAuth();
        }

        this.demoLinks = this.configuration.getConfiguration().demo_scan_links;

        this.hardwareService.getScanners().then((data) => {
            this.scanners = data;
        });

        this.setupPermissions();
    }

    setupPermissions() {
        this.showScanButton = this.actionService.isAllowAction('kits_inventory', 'scan_kit', 'Show scan button');
    }

    subheaderTitle() {
        return titleize(this.applicationService.subheaderTitle);
    }
}
