import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { FormularyItem } from '@models/core/formulary/formulary-item';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { ItemKitBinCount } from '@models/core/formulary/item-kit-bin-count';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { prepareItemName } from '@utils/formulary-item-util';

@Component({
    selector: 'formulary-archive-item',
    templateUrl: './formulary-archive-item.html',
    styleUrls: ['./formulary-archive-item.scss'],
})
export class FormularyArchiveItem {
    @Input() formularyItem: FormularyItem;
    @Input() kitBinCount: ItemKitBinCount;

    public itemId;
    public ui: any;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private formularyItemResource: FormularyItemResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService
    ) {}

    ngOnInit() {
        this.itemId = this.$state.params.formularyItemId;

        if (this.formularyItem.deleted_at != null) {
            this.redirectToManageFormulary(
                `Formulary Item "${prepareItemName(this.formularyItem)}" has already been archived.`
            );
        } else if (
            !this.formularyItem.editable_formulary_items ||
            !this.actionService.isAllowAction(
                'hospital_settings',
                'update_formulary_item',
                'Manage Formulary update section'
            )
        ) {
            this.redirectToManageFormulary(
                `Formulary Item "${prepareItemName(this.formularyItem)}" cannot be archived at this time.`
            );
        }
    }

    redirectToManageFormulary(systemMessage: string) {
        const redirectPromise = this.$state.go('formulary-index', { reload: false, inherit: false });

        return this.loadingSpinnerService.spinnerifyPromise(redirectPromise).then(() => {
            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, systemMessage);
        });
    }

    confirmArchive() {
        const archiveFormularyPromise = this.formularyItemResource.archiveFormularyItem(this.itemId);

        return this.loadingSpinnerService
            .spinnerifyPromise(archiveFormularyPromise)
            .then(() => {
                return this.$state.go('formulary-index', { showArchivedItems: true }, { reload: true, inherit: false });
            })
            .then(() => {
                setTimeout(() => {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `Formulary Item "${prepareItemName(this.formularyItem)}" has been archived.`
                    );
                }, 250);
            });
    }
}
