import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { ScanningDialog } from '@components/dialogs/scanner/scanning-dialog/scanning-dialog';
import { Container } from '@models/core/container';
import { ContainerScanSummary } from '@models/core/container-scan';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'containers-detail',
    templateUrl: './containers-detail.html',
    styleUrls: ['./containers-detail.scss'],
})
export class ContainersDetail extends TableComponent {
    @ViewChild(MatSort) sort: MatSort;
    protected tab = 'summary';
    container: Container;
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;
    scanningDialog: MatDialogRef<any>;
    containerScan: ContainerScanSummary;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private $state: StateService,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.fetchContainerData();
    }

    fetchContainerData() {
        const containerId = this.$state.params?.id;
        const scanId = this.$state.params?.scanId;
        const promises = [];
        if (!containerId) {
            return;
        }

        promises.push(
            this.containerResource.getContainer(containerId).then(({ container }) => {
                this.container = container;
            })
        );

        if (scanId) {
            promises.push(
                this.containerResource.getContainerScanSummary(containerId, scanId).then((data) => {
                    this.containerScan = data;
                })
            );
        }

        this.loadingSpinnerService.spinnerifyPromise(Promise.all(promises));
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    handleOnSegmentDelete() {
        this.fetchContainerData();
    }

    handleOnSegmentSave() {
        this.fetchContainerData();
    }

    startScan() {
        this.scanningDialog = this.dialog.open(ScanningDialog, {
            width: 'max-content',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                scanner: this.container.hardware,
                stayAfterScan: true,
            },
        });

        this.scanningDialog.afterClosed().subscribe((scanResult) => {
            if (scanResult.id) {
                this.$state.go('containers-detail', { id: this.$state.params?.id, scanId: scanResult.id });
            }
        });
    }
}
