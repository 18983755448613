import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';

@Pipe({ name: 'dayDiff' })
export class DayDiffPipe implements PipeTransform {
    constructor(private translationService: TranslationService) {}

    transform(value: string): string {
        if (isNaN(parseInt(value))) {
            return value;
        } else {
            let dayStr: string;
            let absDays: number;
            let strArr: Array<any>;

            const days = parseInt(value);
            absDays = Math.abs(days);

            if (days === 0) {
                return this.translationService.instant('bins.today');
            }

            if (absDays === 1) {
                dayStr = this.translationService.instant('bins.day');
            } else {
                dayStr = this.translationService.instant('bins.days');
            }

            strArr = [absDays, dayStr];

            let expStr;
            if (days >= 0) {
                expStr = this.translationService.instant('bins.expiring');
                strArr.unshift(expStr);
            } else {
                expStr = this.translationService.instant('bins.expired');
                strArr.push(expStr);
            }
            return strArr.join(' ');
        }
    }
}
