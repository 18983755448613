import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'segment-col',
    templateUrl: './segment-col.html',
})
export class SegmentCol {
    @Input() segment;
    @Input() largeFont: boolean = false;
    @Input() showExpiringSoon: boolean = false;
    @Input() expiringSoonDays: number = null;

    expiresSoon: boolean = false;

    constructor() {}

    ngOnInit() {
        this.expiresSoon = this.isExpiringSoon();
    }

    isExpiringSoon() {
        if (!!this.expiringSoonDays) {
            if (this.segment.itemCount === 1) {
                return this.segment.firstItem.days_to_expiration <= this.expiringSoonDays;
            } else {
                return this.segment.items.some((item) => {
                    return item.days_to_expiration <= this.expiringSoonDays;
                });
            }
        }
        return false;
    }
}
