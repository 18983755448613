import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { AllowlistResource } from '@resources/allowlist-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

// description and detail can contain html
interface DialogData {
    locations: string[];
    cart: any;
}

@Component({
    selector: 'assign-cart-to-location-dialog',
    templateUrl: './assign-cart-to-location-dialog.html',
    styleUrls: ['./assign-cart-to-location-dialog.scss'],
})
export class AssignCartToLocationDialog {
    cart: any;
    currentLocation: any;
    location_id: number;
    editLocations: string[] = [];
    new_location = null;
    locations;
    locationsLoading: boolean = false;

    constructor(
        protected barcodeResource: BarcodeResource,
        protected barcodeScanService: BarcodeScanService,
        public dialogRef: MatDialogRef<AssignCartToLocationDialog>,
        private kcMatSnackBarService: KCMatSnackBarService,
        private allowlistService: AllowlistResource,
        protected loadingSpinnerService: LoadingSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.cart = _.cloneDeep(this.data.cart);
        this.locations = this.data.locations;
        this.location_id = this.cart.location.id || null;
        this.setCartAllowlistLocations();
        this.currentLocation = this.locations.find((location) => location.id === parseInt(this.cart.location.id));
        this.registerScanner();
    }

    registerScanner() {
        this.barcodeScanService.registerListener((scanData) => {
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object && data.object['class'] === 'Location') {
                    this.cart.location = this.locations.find((location) => location.id === data.object.id);
                    this.onOk();
                }
            });
        }, 'assignToLocation');
    }

    setNewLocation() {
        this.cart.location = this.locations.find((location) => location.id === this.new_location);
    }

    remove() {
        this.cart.location_id = null;
        this.cart.location_name = null;
        this.cart.location = null;
        this.onOk();
    }

    onCancel() {
        this.cart.location_id = null;
        this.cart.location_name = null;
        this.cart.location = null;
        this.dialogRef.close();
    }

    onOk() {
        this.data.cart = this.cart;
        if (!!this.cart.location) {
            this.kcMatSnackBarService.open(
                SnackBarTypes.SUCCESS,
                `Dispatched ${this.cart.name} to ${this.cart.location.name}`
            );
        }
        this.dialogRef.close(this.cart);
    }

    setCartAllowlistLocations() {
        this.locationsLoading = true;
        return this.loadingSpinnerService
            .spinnerifyPromise(this.allowlistService.allowlistComputedLocations({ cart_ids: [this.cart.id] }))
            .then(({ allowed_locations }) => {
                this.locationsLoading = false;
                this.editLocations = allowed_locations.filter((location) => location.id !== this.cart.location.id);
            });
    }
}
