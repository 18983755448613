import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { UserSession } from '@models/core/user-session';

@Injectable()
export class GroupLoginResource {
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    logout(): Promise<any> {
        return this.http.post<any>(`${this.API_V2_ENDPOINT}logout`, {}).toPromise();
    }

    sessionHospital(hospitalId: number): Promise<{ user: UserSession }> {
        const data = {
            current_hospital_id: hospitalId,
        };

        return this.http.put<any>(`${this.API_V2_ENDPOINT}sessions/me`, data).toPromise();
    }
}
