import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'packageLongName',
})
export class PackageLongName implements PipeTransform {
    transform(pkg, skipEndSpace) {
        return (
            stringToAdd(pkg.item_name) +
            stringToAdd(pkg.item_strength_formatted) +
            stringToAdd(pkg.item_strength_uom, skipEndSpace)
        );
    }
}

function stringToAdd(str, skipSpace?) {
    if (str === '0' || str === null || str === undefined || str === 0) {
        return '';
    } else {
        return str + (skipSpace ? '' : ' ');
    }
}
