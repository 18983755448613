import { Component } from '@angular/core';

import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'manage-items-print-unconfirmed',
    templateUrl: './manage-items-print-unconfirmed.html',
})
export class ManageItemsPrintUnconfirmed {
    errorAlert: string;
    errorMessage: string;

    constructor(private translationService: TranslationService) {
        this.errorAlert = this.translationService.instant('manage_items.print_unconfirmed.alert');
        this.errorMessage = this.translationService.instant('manage_items.print_unconfirmed.message');
    }
}
