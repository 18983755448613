/**
 * Utilities for dealing with Strings
 **/

/**
 * Replacement for _.titleize()
 */
export function titleize(str: string, onlyFirst = true): string {
    if (str == null) return '';
    if (onlyFirst) {
        str = String(str).toLowerCase();
    }
    return str.replace(/(?:^|\s|-)\S/g, function (c) {
        return c.toUpperCase();
    });
}

/**
 * Replacement for _.underscored()
 */
export function underscored(str: string): string {
    return str
        .trim()
        .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
        .replace(/[-\s]+/g, '_')
        .toLowerCase();
}
