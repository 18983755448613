import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    wholesaler_price_type: string; // wac, gpo or 340b
}

@Component({
    selector: 'wholesaler-price-type-filter',
    templateUrl: './wholesaler-price-type-filter.html',
    styleUrls: ['./wholesaler-price-type-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: WholesalerPriceTypeFilter }],
})
export class WholesalerPriceTypeFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeWholesalerPriceType = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscription report if required and empty

    protected WHOLESALER_PRICE_TYPES: string[] = ['WAC', 'GPO', '340B'];

    constructor() {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.wholesaler_price_type;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterWholesalerPriceType(this.filterData);
        } else {
            this.reset();
        }
    }

    filterWholesalerPriceType(event): void {
        this.filterData = event;
        if (!!this.reportSubscription) {
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            this.changeWholesalerPriceType.emit(this.filterData);
        }
        this.incomplete.emit({ filter: 'wholesalerPriceType', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = 'WAC';
        this.changeWholesalerPriceType.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        return [{ wholesaler_price_type: this.filterData }];
    }
}
