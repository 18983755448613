import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class BenchmarkResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getLocations(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}tile_locations`).toPromise();
    }

    tileGroups(): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}tile_groups/index_with_create`, {}).toPromise();
    }

    tile(id: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}tiles/${id}`).toPromise();
    }
}
