import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    hide_completed: string; // 1 if true
}

@Component({
    selector: 'kits-needing-rework-filter',
    templateUrl: './kits-needing-rework-filter.html',
    providers: [{ provide: 'IFilter', useExisting: KitsNeedingReworkFilter }],
})
export class KitsNeedingReworkFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null; // from report-filter
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeKitsNeedingRework = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.hide_completed;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterKitsType();
        } else {
            this.reset();
        }
    }

    reset(): void {
        this.filterData = '1';
        this.changeKitsNeedingRework.emit(this.filterData);
    }

    filterKitsType(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeKitsNeedingRework.emit(this.filterData);
        this.incomplete.emit({ filter: 'kitsNeedingRework', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): any {
        return [{ hide_completed: this.filterData }];
    }
}
