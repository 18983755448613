import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { KitMasterResource } from '@resources/kit-master-resource.service';

@Injectable()
export class KitMasterService {
    selectedKitMaster: any;

    constructor(private kitMasterResource: KitMasterResource) {}

    getKitMasters(includeFromKits?: boolean, onBehalfOfHospitalId?: number) {
        return this.kitMasterResource.kitMasterList(includeFromKits, onBehalfOfHospitalId).then((data) => {
            return data.kit_masters;
        });
    }

    getSingleKitMaster(kitMasterId) {
        return this.getKitMasters()
            .then((kitMasters) => {
                return _.find(kitMasters, {
                    id: parseInt(kitMasterId),
                });
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    }
}
