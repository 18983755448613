import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { Scanner } from '@models/hardware/scanner';

@Injectable({ providedIn: 'root' })
export class ScannerResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareService: HardwareService
    ) {}

    resolve(): Promise<Scanner[]> {
        return this.loadingSpinnerService.spinnerifyPromise(this.hardwareService.getScanners());
    }
}
