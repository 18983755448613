import { Component, Input, ViewChild } from '@angular/core';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

@Component({
    selector: 'kit-shortage',
    templateUrl: './kit-shortage.html',
})
export class KitShortage {
    @Input() items;

    dataSource: MatTableDataSourceWithNaturalSort<any>;

    displayedColumns: string[] = ['summary', 'shortageRange', 'actual', 'expected'];
    hasData: boolean;

    constructor() {}

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.items);
        this.hasData = !!this.dataSource.data.length;
    }

    segmentSummary(item) {
        return `${item.actual} of ${item.expected} ${item.segment_name}`;
    }

    determineShortageRange(item) {
        if (item.expected_max === item.expected_min) {
            return item.expected_max;
        }
        return `${item.expected_min} - ${item.expected_max}`;
    }
}
