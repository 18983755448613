import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportingResource } from '@resources/reporting-resource.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ActivityAnalyticsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private reportingResource: ReportingResource
    ) {}

    resolve(): Promise<any> {
        const data = {
            start_date: moment(new Date()).subtract('weeks', 4).format('YYYY-MM-DD'),
            end_date: moment(new Date()).format('YYYY-MM-DD'),
        };

        const activityAnalyticsPromise = this.reportingResource.activityAnalytics(data);

        return this.loadingSpinnerService.spinnerifyPromise(activityAnalyticsPromise);
    }
}
