import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, switchMap } from 'rxjs/operators';
import { BadgeAuthService } from '@services/core/badge-auth.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';

@Injectable({
    providedIn: 'root',
})
export class HttpBadgeAuthInterceptor implements HttpInterceptor {
    badgeEpc: string;
    badgePin: string;

    // These snackBars should be removed if/when the overall system errors are converted to using a snackbar instead of the header messages
    snackBarRef: MatSnackBar;

    constructor(
        private badgeAuthService: BadgeAuthService,
        private configuration: ConfigurationProvider,
        private kcMatSnackBarService: KCMatSnackBarService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const obs = next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !!error.error.extra_authentication_required) {
                    if (error.error.reason_code === 'cancel_pin' || error.error.reason_code === 'no_badge_set') {
                        this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, error.error.message);
                        throw error;
                    } else {
                        return this.badgeAuthService.getBadgeCredentials(error).pipe(
                            switchMap(() => {
                                return next.handle(this.addExtraAuthentication(req));
                            })
                        );
                    }
                } else if (error.status === 403 && error.url.match(this.configuration.getConfiguration().api_regex)) {
                    this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, error.error.message);
                    throw error;
                } else {
                    throw error;
                }
            })
        );
        return obs;
    }

    addExtraAuthentication(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('X-Extra-Authentication', this.badgeAuthService.getBadgeAuthHttpHeader()),
        });
    }
}
