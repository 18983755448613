import { Component, Input, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
    selector: 'kc-multiselect-menu',
    templateUrl: './kc-multiselect-menu.html',
    styleUrls: ['./kc-multiselect-menu.scss'],
})
export class KCMultiselectMenu {
    @Input() optionTitle: string;
    @Input() allOptions: string[];
    @Input() selectedOptions: string[];
    @Input() keyPrefix: string;
    @Input() translate: boolean = false;
    @Input() show: boolean;
    @Input() trigger: string;
    @Input() showSelectAll: boolean = true;
    @Input() showClearAll: boolean = true;

    @Output() selections: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();

    private wasInside = false;

    @HostListener('click', ['$event'])
    clickInside($event) {
        this.wasInside = true;
    }

    @HostListener('document:click', ['$event'])
    clickout($event) {
        if (this.show) {
            let trigger = $event.target.className.includes(this.trigger);
            if (!this.wasInside && !trigger) {
                this.close.emit(true);
            }
            this.wasInside = false;
        }
    }

    onSelection($event, selected) {
        this.selections.emit(selected.map((x) => x.value));
    }

    getFullKey(option) {
        if (!!this.keyPrefix) {
            return `${this.keyPrefix}.${option}`;
        }
    }

    selectAll() {
        this.selections.emit(this.allOptions);
    }

    clearAll() {
        this.selections.emit([]);
    }
}
