import { Injectable } from '@angular/core';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HospitalResource } from '@resources/hospital-resource.service';

@Injectable({ providedIn: 'root' })
export class HospitalDataResolver {
    constructor(
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private hospitalResource: HospitalResource
    ) {}

    resolve(): Promise<any> {
        const promise = this.hospitalResource.hospitalData(this.hospitalInfoService.getHospitalId()).then((data) => {
            return data.hospital;
        });

        return this.loadingSpinnerService.spinnerifyPromise(promise);
    }
}
