import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HardwareService } from '@services/hardware/hardware.service';

@Injectable({ providedIn: 'root' })
export class HardwareResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareService: HardwareService
    ) {}

    resolve(): Promise<any> {
        const hardwarePromise = this.hardwareService.getHardware();

        return this.loadingSpinnerService.spinnerifyPromise(hardwarePromise);
    }
}
