import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';

@Injectable({ providedIn: 'root' })
export class SingleDossierReportResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private reportResource: ReportResource
    ) {}

    resolve(): Promise<any> {
        const dossierPromise = this.reportResource
            .dossierReport(this.$state.transition.params().reportName)
            .then((data) => {
                return data;
            });

        return this.loadingSpinnerService.spinnerifyPromise(dossierPromise);
    }
}
