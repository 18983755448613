import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { GroupMap } from '@models/admin/admin-group-map';
import { GroupRole } from '@models/core/group';

@Component({
    selector: 'role-section',
    templateUrl: './role-section.html',
    styleUrls: ['./role-section.scss'],
})
export class RoleSection {
    @Input() groupMap: GroupMap;
    @Input() selectedGroupRoles: GroupRole[];
    @Input() selectedGroup: number;
    @Output() selectedGroupRolesChange = new EventEmitter();

    hasMultipleGroups: boolean;
    flatGroupMap: GroupMap[] = [];

    constructor() {}

    ngOnInit() {
        this.selectedGroupRoles = this.selectedGroupRoles || [];
        this.hasMultipleGroups = this.groupMap.children.length > 0;
        this.populateGroupMap(this.groupMap, null);
    }

    populateGroupMap(node, parent) {
        node.parent = parent;

        if (!node.parent) {
            node.nodeLevel = 0;
        } else {
            node.nodeLevel = parent.nodeLevel + 1;
        }

        // default to expand the selected hospital node, and it's parents (recursively).
        if (node.group_id === this.selectedGroup) {
            node.expanded = true;
            node.expandAvailable = true;
            if (!!node.parent) {
                this.expandParents(node);
            }
        } else {
            node.expanded = false;
        }

        this.addUserRolesToNode(node);
        this.flatGroupMap.push(node);
        node.children.forEach((child) => this.populateGroupMap(child, node));
    }

    addUserRolesToNode(node) {
        // If the user has a role at this node, grab it and fill it in here
        let userInfo = this.selectedGroupRoles.find((groupRole) => groupRole.group_id === node.group_id);

        node.roles.forEach((role) => {
            let userRole;

            if (!!userInfo) {
                userRole = userInfo.roles.find((uRole) => uRole.id === role.id);
            }

            if (!!userRole) {
                role.inherited = userRole.inherited;
                role.selected = !userRole.inherited;
            } else {
                role.selected = false;
                role.inherited = false;
            }
        });
    }

    expandParents(node) {
        node.parent.expanded = true;

        // if there is a grandparent, need to expand that too.
        if (!!node.parent.parent) {
            this.expandParents(node.parent);
        }
    }

    roleChange(type, event) {
        if (type === 'remove') {
            this.removeRole(event);
        } else {
            this.addRole(event);
        }
        this.updateSelectedGroupRoles();
    }

    updateSelectedGroupRoles() {
        let newGroupRoles = [];

        this.extractSelectedGroupRoles(this.groupMap, newGroupRoles);

        this.selectedGroupRoles = newGroupRoles;
        this.selectedGroupRolesChange.emit(this.selectedGroupRoles);
    }

    extractSelectedGroupRoles(node, groupRoles) {
        const role_ids_at_node = node.roles.filter((role) => role.selected).map((role) => role.id);
        if (!_.isEmpty(role_ids_at_node)) {
            groupRoles.push({
                group_id: node.group_id,
                role_ids: role_ids_at_node,
            });
        }
        node.children.forEach((child) => this.extractSelectedGroupRoles(child, groupRoles));
    }

    removeRole(event) {
        // event is { role object, groupMap object }
        let updatedGroupRole = event.node.roles.find((existingRole) => existingRole.id === event.role.id);

        updatedGroupRole.selected = false;
        updatedGroupRole.inherited = false;

        let selectedGroup = this.findGroup(this.groupMap, event.node.group_id);
        selectedGroup.roles = event.node.roles;
        selectedGroup.possibleRoles = selectedGroup.roles.filter((role) => !role.selected && !role.inherited);

        if (!!event.node.children) {
            event.node.children.forEach((child) => {
                this.removeRole({ role: event.role, node: child });
            });
        }
    }

    addRole(event) {
        // event is { role object, groupMap object, inherited: boolean }
        // find the role on this node
        let updatedGroupRole = event.node.roles.find((existingRole) => existingRole.id === event.role.id);

        // set the appropriate properties
        if (event.inherited) {
            updatedGroupRole.inherited = true;
            updatedGroupRole.selected = false;
        } else {
            updatedGroupRole.selected = true;
        }

        // find the group object
        let selectedGroup = this.findGroup(this.groupMap, event.node.group_id);

        // update the roles object
        selectedGroup.roles = event.node.roles;
        selectedGroup.possibleRoles = selectedGroup.roles.filter((role) => !role.selected && !role.inherited);

        // add inherited roles if appropriate
        if (!!event.node.children) {
            event.node.children.forEach((child) => {
                this.addRole({ role: event.role, node: child, inherited: true });
            });
        }
    }

    findGroup(node, groupId) {
        let thisNode;
        if (node.group_id === groupId) {
            return node;
        }

        node.children.forEach((node) => {
            if (!thisNode) {
                thisNode = this.findGroup(node, groupId);
            } else {
                return;
            }
        });
        return thisNode;
    }
}
