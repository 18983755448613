import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitSummaryService } from '@services/core/kit-summary.service';

@Injectable({ providedIn: 'root' })
export class BlockedKitResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitSummaryService: KitSummaryService
    ) {}

    resolve(): Promise<any> {
        const blockedKitPromise = this.kitSummaryService.loadBlockedKit(
            this.$state.transition.params().kitId,
            this.$state.transition.params().scanId
        );

        return this.loadingSpinnerService.spinnerifyPromise(blockedKitPromise);
    }
}
