import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { KitSummaryItem } from '@models/core/kit-summary';

@Component({
    selector: 'mobile-cart-kit-summary-items',
    templateUrl: './kit-summary-items.html',
    styleUrls: ['./kit-summary-items.scss'],
})
export class MobileCartKitSummaryItemsComponent implements AfterViewInit {
    @Input() displayItems: KitSummaryItem[];
    @Input() tableName: string;

    dataSource: MatTableDataSourceWithNaturalSort<KitSummaryItem>;
    displayedColumns: string[] = [
        'name',
        'item_strength_formatted',
        'package_volume_formatted',
        'ndc',
        'epc_last_4',
        'lot_num',
        'expiration',
    ];
    @ViewChild(MatSort) sort: MatSort;

    constructor() {}

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.displayItems);
        this.dataSource.sort = this.sort;
    }
}
