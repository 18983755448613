import { Component } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';
import { StateDeclaration, StateService, TransitionService } from '@uirouter/core';
import { RouteDeclaration } from 'routes';

@Component({
    selector: 'kc-breadcrumbs',
    templateUrl: './kc-breadcrumbs.component.html',
    styleUrls: ['./kc-breadcrumbs.component.scss'],
})
export class KCBreadcrumbsComponent {
    protected currentState: StateDeclaration;
    protected breadcrumbState: Array<{ name: string; state: string }> = [];

    constructor(
        private $transitions: TransitionService,
        private $state: StateService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.$transitions.onStart({}, () => this.onTransitionStart());
        this.$transitions.onSuccess({}, () => this.onTransitionSuccess());
    }

    onTransitionStart() {
        this.currentState = undefined;
        this.breadcrumbState = [];
    }

    onTransitionSuccess() {
        this.currentState = this.$state.current;
        this.build();
    }

    private getParent(state: RouteDeclaration) {
        if (!state.data?.parentState) {
            this.breadcrumbState = [
                {
                    name: state.titleKey ? this.translationService.instant(state.titleKey) : '',
                    state: state.name,
                },
                ...this.breadcrumbState,
            ];

            return;
        }

        this.breadcrumbState = [
            {
                name: this.translationService.instant(state.titleKey),
                state: state.name,
            },
            ...this.breadcrumbState,
        ];

        this.getParent(this.$state.get(state.data?.parentState));
    }

    private build() {
        this.getParent(this.currentState);
    }
}
