import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-mat-stepper-previous',
    templateUrl: './kc-mat-stepper-previous.html',
    styleUrls: ['./kc-mat-stepper-previous.scss'],
})
export class KCMatStepperPrevious {
    @Input() disabled: boolean;
    @Input() label: string;

    constructor() {}

    ngOnInit() {
        this.label = this.label || 'buttons.previous';
    }
}
