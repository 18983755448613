import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';

import { BinResource } from '@resources/bin-resource.service';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { ReportSubscriptionResource } from '@resources/report-subscription-resource.service';

import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import { ReportSubscriptionDialog } from '@components/dialogs/report-subscription/report-subscription-dialog';
import { UploadCsvDialog } from '@components/dialogs/upload-csv/upload-csv-dialog';
import { ReplaceBinTagDialog } from '../replace-bin-tag/replace-bin-tag-dialog';

import { Bin } from '@models/core/bin';
import { Printer } from '@models/core/printer';

import { TableComponent } from '@components/common/table-component';
import { DownloadHelperService } from '@services/utils/download-helper.service';

interface Filter {
    selectedKitMasters: Array<any>;
    emptyCarts: boolean;
    locations: Array<any>;
    noLocation: boolean;
    expiration: number | null;
    report_mode?: string;
}

@Component({
    selector: 'bins-index',
    templateUrl: './bins-index.html',
    styleUrls: ['./bins-index.scss'],
})
export class BinsIndex extends TableComponent {
    // bindings
    @Input() bins: Bin[];
    @Input() printers: Printer[];

    // properties
    canDeleteTag: boolean;
    canReplaceTag: boolean;
    canImportBin: boolean;
    onlyShowBinsBelowPar: boolean;
    scheduledReportsEnabled: boolean;
    filteredBins: Bin[];

    // attributes
    filter = {
        query: '',
        result: null,
    };

    constructor(
        protected actionService: ActionService,
        protected binResource: BinResource,
        protected configurationProvider: ConfigurationProvider,
        protected groupLoginService: GroupLoginService,
        protected hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        protected translationService: TranslationService,
        private dialog: MatDialog,
        private downloadHelper: DownloadHelperService
    ) {
        super();
    }

    ngOnInit() {
        this.canReplaceTag = this.actionService.isAllowAction('kits_inventory', 'replace_bin', 'Allow replace bin');
        this.canDeleteTag = this.actionService.isAllowAction('kits_inventory', 'delete_bin', 'Show delete bin');
        this.canImportBin = this.actionService.isAllowAction('kits_inventory', 'import_bin', 'Importing bins');
        this.onlyShowBinsBelowPar = false;
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
        this.filterBins();
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    authToken() {
        return this.groupLoginService.authToken();
    }

    sort(field: string): void {
        this.sortBy(field, this.filteredBins);
    }

    canReplaceOrDeleteTag() {
        return this.canDeleteTag || this.canReplaceTag;
    }

    lastFourEpc(bin) {
        return !!bin.epc && bin.epc.slice(-4);
    }

    // do a case-insensitive search on specific fields: bin name, formulary item name, formulary item ndc, and ceq name
    searchFilter(bin): void {
        const query = new RegExp(this.filter.query, 'i');
        return (
            bin.name.match(query) ||
            bin.segment_template_formulary_items.map((item) => item.item_name).some((prop) => prop.match(query)) ||
            bin.segment_template_formulary_items.map((item) => item.ndc).some((prop) => prop.match(query)) ||
            bin.segment_template_clinical_equivalences.map((item) => item.item_name).some((name) => name.match(query))
        );
    }

    filterBins(): void {
        let filteredBins = this.bins.filter((bin) => this.belowParFilter(bin));
        this.filteredBins = filteredBins.filter((bin) => this.searchFilter(bin));
    }

    belowParFilter(bin): boolean {
        if (this.onlyShowBinsBelowPar) {
            return bin.below_par;
        } else {
            return true;
        }
    }

    checkTestRun(event) {
        if (window['Cypress']) {
            event.preventDefault();
            this.downloadHelper.simulateDownload(this.downloadUrl(), this.authToken());
        }
    }

    downloadUrl() {
        return `${this.configurationProvider.kcEndpointV1()}bins/report.xlsx`;
    }

    uploadUrl() {
        return `${this.configurationProvider.kcEndpointV1()}bins/import`;
    }

    replaceBin(bin) {
        const confirmDialog = this.dialog.open(ReplaceBinTagDialog, {
            width: '600px',
            height: 'max-content',
            data: { bin: bin, printers: this.printers },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'modals.replace_bin_tag.bin_replaced_success_msg',
                    params: { binName: bin.name },
                });
            }
        });
    }

    deleteBin(bin): void {
        const bin_detail = `<div class="group"><span class="kit-label">${this.translationService.instant(
            'print_bin.bin_name'
        )}</span><span>${bin.name}</span></div>`;
        const epc_detail = `<div class="group"><span class="kit-label">${this.translationService.instant(
            'headers.epc'
        )}</span><span>${this.lastFourEpc(bin)}</span></div>`;
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('modals.kit.delete_tag'),
                description: this.translationService.instant('modals.delete_bin_tag.par_2'),
                detail: `<div class="bin-info">${bin_detail}${epc_detail}</div>`,
                okButton: this.translationService.instant('buttons.delete'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const promise = this.binResource.deleteBin(bin.id).then(() => {
                    this.bins = _.without(this.bins, bin);
                    this.filteredBins = _.without(this.filteredBins, bin);
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'modals.delete_bin_tag.bin_deleted_success_msg',
                        params: { binName: bin.name, epc: bin.epc.slice(-4) },
                    });
                });
                this.loadingSpinnerService.spinnerifyPromise(promise);
            }
        });
    }

    subscribeModal(): void {
        const subscribeFilters = {};
        const subscription = undefined;

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: 'bins_report',
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    subscription: subscription,
                },
            });
        });
    }

    showUploadModal(): void {
        let binTranslation = this.translationService.instant('common.bin');
        const uploadDialog = this.dialog.open(UploadCsvDialog, {
            width: '600px',
            height: 'max-content',
            data: { noun: binTranslation, url: this.uploadUrl(), authToken: this.authToken() },
        });

        uploadDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const promise = this.binResource
                    .binsIndex()
                    .then((response) => {
                        this.bins = response.bins;
                        this.filterBins();
                    })
                    .catch((err) => {
                        if (!!err.message) {
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                    });
                this.loadingSpinnerService.spinnerifyPromise(promise);
            }
        });
    }
}
