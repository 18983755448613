import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    // todo
}

interface FilterData {
    genericNdcItemType?: string;
    kitDrugGroup?: any;
    kitMasters?: any;
}

@Component({
    selector: 'item-type-group-filter',
    templateUrl: './item-type-group-filter.html',
    styleUrls: ['./item-type-group-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: ItemTypeGroupFilter }],
})
export class ItemTypeGroupFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: FilterData; // hash from report-filter
    @Input() reportSubscription: boolean;
    @Input() kitMasters: any;
    @Input() isSystem: boolean;
    @Input() groupIds: string;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeItemTypeGroup = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    prefilter: boolean = false;

    @ViewChildren('IFilter') children: QueryList<IFilter>;

    ngOnInit() {
        this.prefilter = !!this.filterData?.kitDrugGroup || !!this.filterData?.genericNdcItemType;

        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = {};
            //TODO: add conversion from filterData here if this is ever used in the subscription dialog
        } else if (!this.prefilter) {
            this.filterData = { genericNdcItemType: null, kitDrugGroup: null, kitMasters: this.kitMasters };
        }
        this.changeItemTypeGroup.emit(this.filterData);
    }

    reset(): void {
        this.children.toArray().forEach((child) => {
            child.reset();
        });
    }

    onChangeGenericNdcItemType(data): void {
        this.filterData.genericNdcItemType = data;
        this.apiFormattedData.emit(this.formatForApi());
        this.changeItemTypeGroup.emit(this.filterData);
        this.incomplete.emit({ filter: 'itemTypeGroup', incomplete: isEmpty(this.filterData.genericNdcItemType) });
    }

    onChangeKitMasters(data): void {
        this.filterData.kitMasters = data;
        this.changeItemTypeGroup.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'kitMasters', incomplete: isEmpty(this.filterData.kitMasters) });
    }

    onChangeGroup(data): void {
        this.filterData.kitDrugGroup = data;
        this.apiFormattedData.emit(this.formatForApi());
        this.changeItemTypeGroup.emit(this.filterData);
        this.incomplete.emit({ filter: 'itemTypeGroup', incomplete: isEmpty(this.filterData.kitDrugGroup) });
    }

    formatForApi(): Array<any> {
        // TODO: add conversion from filterData to api data here.
        return [];
    }
}
