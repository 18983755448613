import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

@Injectable()
export class ReportService {
    constructor(private $state: StateService) {}

    /* eslint-disable */
    // TODO: CF - not sure we use this any more
    stateReportNameMap: Object = {
        'report-items-expirations': 'custom',
        'system-report-items-expirations': 'custom',
        'report-items-by-ndc': 'customLot',
        'report-kitted-item-inventory': 'byNDC',
        'report-item-recalls': 'item-recalls',
        'system-report-item-recalls': 'item-recalls',
    };
    /* eslint-enable */

    stateToReportName = () => {
        return this.stateReportNameMap[this.$state.transition.to().name];
    };

    cleanFilters = (filters) => {
        if (!!filters.binKitMasters) {
            if (!!filters.binKitMasters.kitMasters && filters.binKitMasters.binKit === 'kit') {
                filters.kit_master_id = filters.binKitMasters.kitMasters.join('|');
                filters.kit_master_id = filters.binKitMasters.kitMasters.map(({ id }) => id).join('|');
            }
            filters.report_mode = filters.binKitMasters.binKit;
            delete filters.binKitMasters;
        }
        if (!!filters.expiration) {
            filters.include_expired_items = filters.expiration.includeExpired ? 1 : 0;
            if (!!filters.expiration.daysExpiringIn) {
                filters.days_expiring_within = filters.expiration.daysExpiringIn;
            }
            delete filters.expiration;
        }
        if (!!filters.genericNdcLotSearch) {
            if (!!filters.genericNdcLotSearch.lot) {
                filters.lot_num = filters.genericNdcLotSearch.lot;
            }
            if (!!filters.genericNdcLotSearch.ndc) {
                filters.ndc = filters.genericNdcLotSearch.ndc;
            }
            if (!!filters.genericNdcLotSearch.generic && Object.keys(filters.genericNdcLotSearch.generic).length) {
                filters.clinical_equivalence_id = filters.genericNdcLotSearch.generic.clinical_equivalence_id;
            }
            delete filters.genericNdcLotSearch;
        }
        if (!!filters.kitInfo) {
            if (!!filters.kitInfo.kitMaster) {
                //we don't want to send null kit_master_id to download
                filters.kit_master_id = filters.kitInfo.kitMaster;
            }
            if (!!filters.kitInfo.kitLabel) {
                filters.kit_ids = filters.kitInfo.kitLabel;
            }
            delete filters.kitInfo;
        }
        if (Object.keys(filters).includes('activities')) {
            if (filters.activities.length === 0) {
                filters.activities = filters.activities;
            } else {
                filters.activities = filters.activities.join('|');
            }
        }

        /* these were in the older version of cleanFilters, but missing
        ** in this version. Not sure yet if they are needed.
        if (Object.keys(filters).includes('activity')) {
            if (filters.activity) {
                filters.action_names = filters.activity;
            }
            delete filters.activity;
        }
        if (Object.keys(filters).includes('user')) {
            if (filters.user) {
                filters.user_ids = filters.user;
            }
            delete filters.user;
        }

        */

        return filters;
    };
}
