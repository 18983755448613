import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    /**
     * Capitalizes the first character in a string
     *
     * @param str - string to capitalize
     * @returns 'Any time' or '11am to 3pm'
     *
     */
    transform(str: string): string {
        if (!!str) {
            return `${str[0].toUpperCase() + str.slice(1)}`;
        } else {
            return str;
        }
    }
}
