import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[overLength]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OverLengthValidateDirective),
            multi: true,
        },
    ],
})
export class OverLengthValidateDirective implements Validator {
    @Input('overLength') max: number;
    validate(control: AbstractControl): { [key: string]: any } | null {
        return overLengthValidator(this.max)(control);
    }
}

export function overLengthValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            if (typeof control.value !== 'string') {
                control.setValue(control.value.toString());
            }
            return control.value.length > max ? { overLength: { value: control.value } } : null;
        }
        return null;
    };
}
