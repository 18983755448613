import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface LoadStatus {
    script: string;
    loaded: boolean;
    status: string;
}

declare let document: any;

@Injectable({ providedIn: 'root' })
export class DynamicScriptLoaderService {
    private renderer: Renderer2;
    public scripts: any = {};

    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    loadScript(name: string, src: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name]) {
                //Create the script element
                let script = this.renderer.createElement('script');
                //Set async to true, so this doesn't block other things from happening
                script.async = true;
                script.type = 'text/javascript';
                script.src = src;
                //Set callbacks so the individual service calling this can handle when this resolves
                script.onload = () => {
                    this.scripts[name] = true;
                    resolve({ script: name, loaded: true, status: 'Loaded' });
                };
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Failed' });
                //Use the renderer to append the script to the document's <head>
                this.renderer.appendChild(this.document.head, script);
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }
}
