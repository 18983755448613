import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HospitalInfoService } from '@services/core/hospital-info.service';

import { sortObjArray } from '@utils/sort';
import { isAppUnderPostLockoutState, isScannedAfterLockoutState } from '@utils/charge-model-util';
import { ChargeModelTypeName } from '@models/core/hospital-settings';

@Component({
    selector: 'kit-all-items-by-segment',
    templateUrl: './kit-all-items-by-segment.html',
    styleUrls: ['./kit-all-items-by-segment.scss'],
})
export class KitAllItemsBySegment implements OnInit {
    @Input() kitSummary;
    @Input() segments;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['segmentName', 'manufacturer', 'epc', 'strength', 'package', 'lot', 'expiration'];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;
    flatSegments: Array<any> = [];
    lockedOutItems: Array<any> = [];
    subscription_items_lockout_date: string;
    postLockout: boolean = false;
    filterSubscriptionItems: boolean = false;
    charge_model_type: ChargeModelTypeName;

    constructor(
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit(): void {
        this.subscription_items_lockout_date =
            this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.charge_model_type = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;
        this.postLockout = isAppUnderPostLockoutState(this.subscription_items_lockout_date, this.charge_model_type);
        this.filterSubscriptionItems =
            this.postLockout &&
            isScannedAfterLockoutState(this.subscription_items_lockout_date, this.kitSummary.scan.created_at);
    }

    ngAfterViewInit(): void {
        // flatten the segment list items so we can display it properly in the table
        const sortedSegments = sortObjArray(this.segments, 'name');
        sortedSegments.forEach((segment) => {
            let items = segment.items.concat(segment.extra);
            if (items.length > 0) {
                items.forEach((item, index) => {
                    // skip any subscription required items if post lockout
                    if (!this.filterSubscriptionItems || !item.subscription_required) {
                        let merged = { ...segment, ...item };
                        merged['show'] = index === 0;
                        merged['segmentName'] = segment.name;
                        merged['segmentChildCount'] = items.length;
                        merged['itemName'] = item.name;
                        this.flatSegments.push(merged);
                    } else {
                        this.lockedOutItems.push(item);
                    }
                });
            } else {
                // segment with no items still needs to display
                let item = segment;
                item['show'] = true;
                item['segmentName'] = segment.name;
                item['segmentChildCount'] = 0;
                this.flatSegments.push(item);
            }
        });

        this.dataSource = new MatTableDataSourceWithNaturalSort(this.flatSegments);

        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    expiredOrExpiringSoon(item) {
        if (!item) {
            return false;
        }

        const m = moment(item.expiration);
        return (
            m.isBefore() ||
            _.find(this.kitSummary.items.expiring_soon, {
                item_snapshot_id: item.item_snapshot_id,
            }) !== undefined
        );
    }

    expiredOrExpiringSoonClass(item) {
        const m = moment(item.expiration);

        if (m.isBefore()) {
            return 'expired';
        } else if (
            _.find(this.kitSummary.items.expiring_soon, {
                item_snapshot_id: item.item_snapshot_id,
            }) !== undefined
        ) {
            return 'expiring';
        }
    }
}
