import { Injectable } from '@angular/core';
import { ApplicationService } from '@services/system/application.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LongUsernamePipe } from '@pipes/long-username.pipe';

@Injectable()
export class UserInfoService {
    constructor(
        private applicationService: ApplicationService,
        private localStorageService: LocalStorageService
    ) {}

    setUserInfo() {
        this.applicationService.user = {
            id: parseInt(this.localStorageService.get('userId')),
            userName: this.localStorageService.get('userName'),
            firstName: this.localStorageService.get('firstName'),
            lastName: this.localStorageService.get('lastName'),
            formatted_name: new LongUsernamePipe().transform(
                this.localStorageService.get('firstName'),
                this.localStorageService.get('lastName')
            ),
        };
    }
}
