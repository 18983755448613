import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class UserResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V3_ENDPOINT = this.configuration.kcEndpointV3();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getUser(userId: number, thin?: boolean): Promise<any> {
        let params: any;

        if (thin) {
            params = { thin };
        }

        return this.http.get<any>(`${this.API_V3_ENDPOINT}users/${userId}`, { params: params }).toPromise();
    }

    updateUser(data: any): Promise<any> {
        return this.http.put<any>(`${this.API_V3_ENDPOINT}users/${data.id}`, data).toPromise();
    }

    createUser(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_V3_ENDPOINT}users`, data).toPromise();
    }

    removeUser(data: any): Promise<any> {
        return this.http.delete(`${this.API_V3_ENDPOINT}users/${data.id}`).toPromise();
    }

    showUsers(groupId?: number, excludeEmployees?: boolean): Promise<any> {
        let params: any = {};
        if (!!groupId) {
            params.group_id = groupId;
        } else {
            params.all_roots = true;
        }

        if (!!excludeEmployees) {
            params.exclude_kitcheck_employees = true;
        }
        return this.http.get<any>(`${this.API_V3_ENDPOINT}users`, { params: params }).toPromise();
    }

    removeUserBadge(userId: number): Promise<any> {
        return this.http.delete(`${this.API_ENDPOINT}users/${userId}/remove_tag`).toPromise();
    }

    addOrUpdateUserBadge(userId: number, epc: string): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}users/${userId}/tags`, { epc }).toPromise();
    }
}
