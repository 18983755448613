import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { IFilter } from '@models/common/report-filter';
import { TranslationService } from '@services/utils/translation.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { isEmpty, arrayUnique } from '@utils/objects';
import { NaturalSortService } from '@services/utils/natural-sort.service';

interface ApiData {
    kit_masters: string; // a | joined list of kit master ids
}

@Component({
    selector: 'kit-masters-filter',
    templateUrl: './kit-masters-filter.html',
    providers: [{ provide: 'IFilter', useExisting: KitMastersFilter }],
})
export class KitMastersFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() binKitMasters: boolean;
    @Input() consumedKitMasters: Array<any>;
    @Input() customLabel: string;
    @Input() includeSharedKitMasters: boolean = false;
    @Input() isSystem: boolean = false;
    @Input() filterData: Array<any> | null; // hash from report-filter component
    @Input() prefilter: boolean = false;
    @Input() noneIsAll: boolean; // handle cases where we want no results to mean all results
    @Input() reportSubscription: boolean;

    private _groupIds: string;

    @Input() set groupIds(value: string) {
        this._groupIds = value;
        if (this.isSystem) {
            this.kitMastersList = arrayUnique(this.idnFilterKitMasters(this.groupedKitMastersList, this._groupIds));
            this.filterKitMasters();

            //save off our last group ids so we know what hospitals have been added and can auto select their kitmasters
            this.lastGroupIds = value;
        }
    }

    get groupIds(): string {
        return this._groupIds;
    }

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeKitMasters = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    kitMastersList: Array<any> = [];
    groupedKitMastersList: Array<any> = [];

    constructor(
        private translationService: TranslationService,
        private kitMasterResource: KitMasterResource
    ) {}

    allSelected = this.translationService.instant('carts.multiselect.kitmaster_allKitmastersSelected');
    lastGroupIds: string = '';

    dropdownSettings: any = {
        singleSelection: false,
        text: this.translationService.instant('carts.multiselect.kitmaster_text'),
        selectAllText: this.translationService.instant('carts.multiselect.kitmaster_selectAllText'),
        unSelectAllText: this.translationService.instant('carts.multiselect.kitmaster_unSelectAllText'),
        enableSearchFilter: true,
        classes: this.translationService.instant('carts.multiselect.classes'),
        labelKey: this.translationService.instant('carts.multiselect.labelKey'),
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: this.translationService.instant('carts.multiselect.kitmaster_searchPlaceholderText'),
        objectTypeName: 'Kit Master',
    };

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = [{ id: undefined }];
            this.kitMastersList = [{ id: undefined }];
            if (!!this.apiSubscriptionData.kit_masters) {
                this.filterData = this.apiSubscriptionData.kit_masters.split('|').map((km) => {
                    return { id: Number(km) };
                });
            }
        }

        this.getKitMasterList(this.filterData);
    }

    getKitMasterList(listData): void {
        let promises = [];

        if (!this.isSystem) {
            let kmPromise = this.kitMasterResource.kitMasterList(this.includeSharedKitMasters).then((data) => {
                this.kitMastersList = data.kit_masters;
            });
            promises.push(kmPromise);
        } else {
            // get the grouped kitmasters list instead of the km list for just this hospital
            let grpKmPromise = this.kitMasterResource.groupedKitMasterList().then((data) => {
                //flatmap all of the group recalls
                this.groupedKitMastersList = [];
                data.groups.forEach((group) => {
                    this.groupedKitMastersList = this.groupedKitMastersList.concat(
                        group.kit_masters.map((km) => {
                            km.name = `${km.name} -- ${group.name}`;
                            km.hospitalName = group.name;
                            km.groupId = group.id;
                            return km;
                        })
                    );
                });

                if (!isEmpty(this.groupedKitMastersList)) {
                    // sort groupedKitMastersList by hospitalName then Name and assign to this.kitMastersList
                    this.kitMastersList = NaturalSortService.sortOnTwoFields(
                        this.groupedKitMastersList,
                        'hospitalName',
                        'name'
                    );
                    // filter down to selected groups
                    this.kitMastersList = this.idnFilterKitMasters(this.kitMastersList, this.groupIds);
                } else {
                    this.kitMastersList = [];
                }
            });
            promises.push(grpKmPromise);
        }

        // then converge the data as needed
        Promise.all(promises).then((response) => {
            if (!isEmpty(this.consumedKitMasters)) {
                //specific to Items Consumed Removed Report
                this.concatConsumedKitMasters();
            }
            if (!!this.reportSubscription || this.prefilter) {
                // for reportSubscription modal, we want the selected kitMasters to persist from report page
                // all selected. Need to do this, or the select control will show # selected vs All selected.
                // when we change the multiselect control to the material multiselect, we can probalby
                // clean this up.
                if (listData.length !== this.kitMastersList.length) {
                    let km_ids = listData.map((km) => km.id);
                    this.filterData = this.kitMastersList.filter((km) => km_ids.includes(km.id));
                } else {
                    this.filterData = [...this.kitMastersList];
                }

                this.filterKitMasters();
            } else {
                this.filterData = [...this.kitMastersList];
                this.changeKitMasters.emit(this.filterData);
            }
        });
    }

    concatConsumedKitMasters(): void {
        let kitMastersIds = this.kitMastersList.map((kitMaster) => kitMaster.id);
        let unique = this.consumedKitMasters.filter((kitMaster) => {
            return !kitMastersIds.includes(kitMaster.kit_master_id);
        });
        this.kitMastersList = this.kitMastersList.concat(
            unique.map((kitMaster) => {
                if (!kitMaster.kit_master_name) {
                    return {
                        name: '— Items decommissioned outside of kits',
                        id: kitMaster.kit_master_id,
                    };
                } else {
                    return {
                        name: kitMaster.kit_master_name,
                        id: kitMaster.kit_master_id,
                    };
                }
            })
        );
    }

    // only show kitMasters from the selected hospital group
    idnFilterKitMasters(groupedKitMasters, groupIds) {
        if (isEmpty(groupedKitMasters)) return;

        // covert the group Ids list (which is a string delimited by |)
        // to an array so we can compare group Ids properly
        // (we don't want group ID 29 returning true for group ID 129)
        let groupIdsArray = [];
        if (!!groupIds) {
            groupIdsArray = groupIds.split('|').map(Number);
        }

        // clean out selected kitMastersList that no longer have a selected group
        if (!!this.filterData) {
            this.filterData = this.filterData.filter((km) => groupIdsArray.includes(km.groupId));
        }

        /*  A little explanation here:
            if we've changed groupIds, need to see which hospitals are new so we can auto select their KMs
            We want to preserve the previous KM selections (assuming the hospital/group is still selected),
            so can't just assign all of the KMs for all of the selected groups. For example, if you've
            previously only selected a single KM at one hospital, and then add a new hospital, we don't
            want to now select all of the KMs at the first hospital.
        */

        // only need to do this if the hospital/groups have changed
        if (this.lastGroupIds !== groupIds) {
            let lastGroupIdsArray = this.lastGroupIds.split('|').map(Number);
            let newHospitals = groupIdsArray.filter((groupID) => !lastGroupIdsArray.includes(groupID));

            if (!!newHospitals) {
                // find the KM for the new hospital/groups
                let newHospitalKMs = groupedKitMasters.filter((kitMaster) => {
                    return newHospitals.includes(kitMaster.groupId);
                });
                // now, we select all of the KMs for the new groups.
                this.filterData = this.filterData.concat(newHospitalKMs);
            }
        }

        // then, filter the list of kitmasters to the selected group.
        // This does not select kitmasters, it just changes the list of options in the km control.
        let kitMasterFromSelectedGroups = groupedKitMasters.filter((kitMaster) => {
            return groupIdsArray.includes(kitMaster.groupId);
        });
        // sort kitMasterFromSelectedGroups based on hospitalName then Name
        kitMasterFromSelectedGroups = NaturalSortService.sortOnTwoFields(
            kitMasterFromSelectedGroups,
            'hospitalName',
            'name'
        );
        return kitMasterFromSelectedGroups;
    }

    selectAll(): void {
        this.dropdownSettings.text = this.allSelected;
        this.filterKitMasters();
    }

    filterKitMasters(): void {
        this.changeKitMasters.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'kitMasters', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): ApiData[] {
        if (!!this.filterData) {
            return [
                {
                    kit_masters: this.filterData
                        .map((kitMaster) => kitMaster.id)
                        .filter((n) => n)
                        .join('|'),
                },
            ];
        }
        return [{ kit_masters: '' }];
    }

    reset(): void {
        this.filterData = [...this.kitMastersList];
        this.changeKitMasters.emit(this.filterData);
    }
}
