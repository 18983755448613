import * as $ from 'jquery';

export function printPage(bodyClass: string) {
    $('body').addClass(bodyClass);
    toggleExtraneousPageElements((resolve) => {
        window.print();
        setTimeout(() => {
            $('body').removeClass(bodyClass);
            resolve();
        }, 2000);
    });
}

export function toggleExtraneousPageElements(printCallback: (resolve: () => void) => void) {
    /* Before */
    // help agent
    $('#apt-widget').addClass('hidden');
    $('.mat-snack-bar-container').addClass('hidden');
    $('iframe').addClass('hidden');
    // fresh desk
    const elements = document.getElementsByClassName('fc-chat-button');
    const display = {};
    for (let i = 0; i < elements.length; i++) {
        const element = elements.item(i) as HTMLElement;
        display[i] = element.style.display;
        element.style.display = 'none';
    }

    /* After */
    const resolver = () => {
        // fresh desk
        for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i) as HTMLElement;
            element.style.display = display[i];
        }
        // help agent
        $('#apt-widget').removeClass('hidden');
        $('.mat-snack-bar-container').removeClass('hidden');
        $('iframe').removeClass('hidden');
    };

    printCallback(resolver);
}
