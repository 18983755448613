import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RecallResource } from '@resources/recall-resource.service';
import { AdminRecall } from '@models/admin/admin-recall';

interface ParamData {
    recall: AdminRecall;
}

@Component({
    selector: 'ignore-recall-dialog',
    templateUrl: './ignore-recall-dialog.html',
    styleUrls: ['./ignore-recall-dialog.scss'],
})
export class IgnoreRecallDialog {
    formularyItem;
    ignoredReasons: string[];
    ignored_reason: number;
    ignored_reason_id: number;
    ignored_reason_detail: string;
    otherReason: {
        name: string;
        id: number;
    };

    constructor(
        private recallResource: RecallResource,
        public dialogRef: MatDialogRef<IgnoreRecallDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.recallResource.getRecallIgnoredReasonIds().then((results) => {
            this.ignoredReasons = results.recall_ignored_reasons
                .filter((reason) => reason.name !== 'already_pulled')
                .map((reason) => ({ name: `admin.item_recalls.${reason.name}`, id: reason.id }));

            this.otherReason = results.recall_ignored_reasons.find((reason) => reason.name === 'other');
        });
        this.ignored_reason_detail = '';
    }

    isDisabled() {
        if (!this.ignored_reason_id) {
            return true;
        }
        if (this.ignored_reason_id === this.otherReason.id && this.ignored_reason_detail.length < 1) {
            return true;
        }

        if (this.ignored_reason_id === this.otherReason.id && this.ignored_reason_detail.length > 100) {
            return true;
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close({
            ignored_reason_id: this.ignored_reason_id,
            ignored_reason_detail: this.ignored_reason_detail,
        });
    }
}
