import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { PretaggedProblemResource } from '@resources/pretagged-problem-resource.service';

@Injectable({ providedIn: 'root' })
export class ManufacturersResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private pretaggedProblemResource: PretaggedProblemResource
    ) {}

    resolve(): Promise<any> {
        const promise = this.pretaggedProblemResource.getManufacturers().then((data) => {
            return data.catalogs;
        });

        return this.loadingSpinnerService.spinnerifyPromise(promise);
    }
}
