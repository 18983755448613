import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionTimeoutWarningDialog } from '@dialogs/session-timeout-warning/session-timeout-warning-dialog';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { ISubscription } from 'rxjs/Subscription';

import { ActivityResource } from '@resources/activity-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LoginStateService } from '@services/login/login-state.service';
import { LogoutService } from '@services/login/logout.service';
import { TranslationService } from '@services/utils/translation.service';

import * as moment from 'moment';

const ONE_MINUTE_WARNING_TIME = 60;
const UPDATE_ACTIVITY_POLLER_TIME = 60;

interface TimeoutDialogResult {
    extendSession: boolean;
    forcedLogout: boolean;
}

@Injectable()
export class UserSessionTimeoutService {
    secondsLeftUntilTimeout: number;

    private timeoutWarningModal: any = null;
    private idleEndSubscription: ISubscription = null;
    private idleStartSubscription: ISubscription = null;
    private interruptSubscription: ISubscription = null;
    private keepaliveSubscription: ISubscription = null;
    private lastActivity: moment.Moment = null;

    constructor(
        private activityResource: ActivityResource,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService,
        private loginStateService: LoginStateService,
        private logoutService: LogoutService,
        private translationService: TranslationService,
        private idle: Idle,
        private keepalive: Keepalive
    ) {
        this.logoutService.observeLogout().subscribe((logout) => {
            if (!!logout) {
                this.stop();
            }
        });
    }

    start() {
        const idleTime = this.hospitalInfoService.hospitalSetting('session_inactivity_timeout');
        if (!!idleTime && idleTime > 0) {
            this.idle.setIdle(idleTime - ONE_MINUTE_WARNING_TIME);
            this.keepalive.interval(UPDATE_ACTIVITY_POLLER_TIME);

            this.startSubscriptions();
            this.reset();
        }
    }

    startSubscriptions() {
        this.startIdleStartSubscription();
        this.startIdleEndSubscription();
        this.startInterruptSubscription();
        this.startKeepaliveSubscription();
    }

    startIdleEndSubscription() {
        if (!this.idleEndSubscription) {
            this.idleEndSubscription = this.idle.onIdleEnd.subscribe(() => {
                this.lastActivity = moment();
            });
        }
    }

    startIdleStartSubscription() {
        if (!this.idleStartSubscription) {
            this.idleStartSubscription = this.idle.onIdleStart.subscribe(() => {
                this.openSessionTimeoutWarningDialog();
            });
        }
    }

    startInterruptSubscription() {
        if (!this.interruptSubscription) {
            this.interruptSubscription = this.idle.onInterrupt.subscribe(() => {
                this.lastActivity = moment();
            });
        }
    }

    startKeepaliveSubscription() {
        if (!this.keepaliveSubscription) {
            this.keepaliveSubscription = this.keepalive.onPing.subscribe(() => this.updateSessionActivity());
        }
    }

    reset() {
        this.lastActivity = moment();
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
    }

    stop() {
        this.idle.stop();
        this.stopSubscriptions();
        this.timeoutWarningModal = null;
    }

    stopSubscriptions() {
        this.stopIdleStartSubscription();
        this.stopIdleEndSubscription();
        this.stopInterruptSubscription();
        this.stopKeepaliveSubscription();
    }

    stopIdleStartSubscription() {
        this.idleStartSubscription?.unsubscribe();
        this.idleStartSubscription = null;
    }

    stopIdleEndSubscription() {
        this.idleEndSubscription?.unsubscribe();
        this.idleEndSubscription = null;
    }

    stopInterruptSubscription() {
        this.interruptSubscription?.unsubscribe();
        this.interruptSubscription = null;
    }

    stopKeepaliveSubscription() {
        this.keepaliveSubscription?.unsubscribe();
        this.keepaliveSubscription = null;
    }

    private updateSessionActivity() {
        const secondsSinceActivity = this.timeSinceActivity();

        if (this.loginStateService.loggedIn) {
            this.activityResource.activity(`${secondsSinceActivity}`);
        }
    }

    private openSessionTimeoutWarningDialog() {
        if (!this.timeoutWarningModal) {
            this.idle.setInterrupts([]); // Don't let user interaction with this dialog reset the timeout
            this.timeoutWarningModal = this.dialog.open(SessionTimeoutWarningDialog, {
                minWidth: 500,
                autoFocus: false,
                disableClose: true,
                closeOnNavigation: false,
                data: {
                    idle: this.idle,
                },
            });

            this.timeoutWarningModal.afterClosed().subscribe((result: TimeoutDialogResult) => {
                if (result.extendSession) {
                    this.reset();
                } else {
                    this.performLogout(result.forcedLogout);
                }
                this.timeoutWarningModal = null;
            });
        }
    }

    private performLogout(showLogoutMessage: boolean = true) {
        this.stop();
        this.loginStateService.notifyLogout(false, showLogoutMessage);
    }

    private timeSinceActivity(): number {
        const now = moment();
        return Math.floor(now.diff(this.lastActivity) / 1000);
    }
}
