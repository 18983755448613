import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { Scanner } from '@models/hardware/scanner';

import { AeroscoutResource } from '@resources/aeroscout-resource.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ScannerService } from './scanner.service';

@Injectable()
export class AeroscoutService {
    private assetClearObserverable: Subject<any> = new Subject();
    private assetSelectionObserverable: Subject<any> = new Subject();
    private assetSelectResetObserverable: Subject<any> = new Subject();

    aeroscoutUrl: string;
    onlineScanners: Scanner[] = [];
    validScanner: Scanner;

    constructor(
        private aeroscoutResource: AeroscoutResource,
        private hardwareService: HardwareService,
        private localStorageService: LocalStorageService,
        private loadingSpinnerService: LoadingSpinnerService,
        private scannerService: ScannerService
    ) {}

    // Observables
    observeAssetClear(): Observable<any> {
        return this.assetClearObserverable;
    }
    observeAssetSelection(): Observable<any> {
        return this.assetSelectionObserverable;
    }
    observeAssetSelectReset(): Observable<any> {
        return this.assetSelectResetObserverable;
    }

    // Triggers
    emitAssetClearEvent(): void {
        this.assetClearObserverable.next('aeroscoutAssetClear');
    }
    emitAssetSelectionEvent(asset: any): void {
        this.assetSelectionObserverable.next(asset);
    }

    emitAssetSelectResetEvent(): void {
        this.assetSelectResetObserverable.next('aeroscoutSelectReset');
    }

    getAeroscoutUrl() {
        if (!!this.aeroscoutUrl) {
            return Promise.resolve(this.aeroscoutUrl);
        } else {
            return new Promise((resolve) => {
                let getUrlProm = this.aeroscoutResource.getAeroscoutUrl().then((data) => {
                    if (!!data && !!data.aeroscout_url) {
                        this.aeroscoutUrl = data.aeroscout_url.url;
                        resolve(this.aeroscoutUrl);
                    } else {
                        resolve('');
                    }
                });
                this.loadingSpinnerService.spinnerifyPromise(getUrlProm);
            });
        }
    }

    clearAeroscoutUrl() {
        this.aeroscoutUrl = undefined;
    }

    getAeroscoutAuthHeader() {
        let credentials = this.localStorageService.get('aeroscoutCredentials');
        if (credentials) {
            let { username, password } = credentials;
            return 'Basic ' + btoa(username + ':' + password);
        } else {
            return '';
        }
    }

    aeroscoutLogin(url): Promise<any> {
        return this.getOnlineScanners().then((scanners) => {
            return this.attemptLogin(scanners, url);
        });
    }

    async attemptLogin(scanners: Scanner[], url) {
        let loginSucess = false;
        let errorInfo = false;
        let promiseResult;
        for (let i = 0; i < scanners.length && !loginSucess && !errorInfo; i++) {
            await this.scannerService
                .aeroscoutLogin(scanners[i], url, this.getAeroscoutAuthHeader())
                .then((result) => {
                    this.validScanner = scanners[i];
                    loginSucess = true;
                    promiseResult = result;
                })
                .catch((err) => {
                    // ThingMagic scanners throw 404s for improper reasons so we ignore them
                    if (
                        err?.status === 401 ||
                        (err?.status === 404 && !this.scannerService.isThingMagic(scanners[i])) ||
                        scanners.length === i + 1
                    ) {
                        errorInfo = true;
                        throw err;
                    }
                });
        }
        return promiseResult;
    }

    findAeroscoutAsset(scanner, name) {
        return this.getAeroscoutUrl().then((url: any) => {
            return this.scannerService
                .aeroscoutSearch(scanner, url, this.getAeroscoutAuthHeader(), name)
                .then((result) => {
                    if (result.data && result.data.assets) {
                        return Promise.resolve(
                            result.data.assets
                                .map(({ name, applicationId }) => {
                                    return { name, id: applicationId };
                                })
                                .filter((asset) => !!asset.name)
                        );
                    } else {
                        return Promise.resolve([]);
                    }
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        });
    }

    getOnlineScanners(): Promise<Scanner[]> {
        return new Promise((resolve) => {
            if (!!this.validScanner) {
                resolve([this.validScanner]);
            } else if (this.onlineScanners.length > 0) {
                resolve(this.onlineScanners);
            } else {
                this.hardwareService.getAllOnlineScanners().then((scanners: Scanner[]) => {
                    this.onlineScanners = scanners;
                    resolve(this.onlineScanners);
                });
            }
        });
    }
}
