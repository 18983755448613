import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable()
export class GroupInfoService {
    constructor(private localStorageService: LocalStorageService) {}

    getGroupId(): number {
        return this.localStorageService.get('currentGroup') ? this.localStorageService.get('currentGroup').id : 0;
    }
}
