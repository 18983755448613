import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, OnInit, AfterViewInit } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import * as moment from 'moment';
import { KCDatePicker } from '@components/common/kc-datepicker/kc-datepicker';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'date-filter',
    templateUrl: './date-filter.html',
    styleUrls: ['./date-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: DateFilter }],
})
export class DateFilter implements IFilter, OnInit, AfterViewInit {
    @Input() filterData: any;
    @Input() customDates: any;
    @Output() changeDate = new EventEmitter();
    opened: boolean;
    set: boolean = false;
    prefilter: boolean = false;

    @ViewChildren(KCDatePicker) datePickers: QueryList<KCDatePicker>;
    private startDateControl: NgModel;
    private endDateControl: NgModel;

    ngOnInit() {
        this.prefilter = !!this.filterData;
        if (!this.prefilter) {
            this.reset();
        }
    }

    ngDoCheck() {
        //work-around for issue with DOM structure of 3rd party datepicker, may be resolved in future
        if (!this.set && this.opened && document.querySelector('.calendar-below-input')) {
            document.querySelector('.calendar-below-input').parentElement.parentElement.style.marginBottom = '-380px';
            this.set = true;
        }
    }

    setStyle(open) {
        if (open) {
            this.opened = true;
        } else {
            this.opened = false;
        }
    }

    ngAfterViewInit() {
        this.startDateControl = this.datePickers.first.dateControl; // Start date
        this.endDateControl = this.datePickers.last.dateControl; // End date
    }

    reset(): void {
        this.filterData = {};
        let start;
        let end;
        if (!!this.customDates) {
            start = this.customDates.start_date;
            end = this.customDates.end_date;
        } else {
            start = moment().subtract('weeks', 4).toDate();
            end = moment().toDate();
        }
        this.filterData.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        this.filterData.end_date = moment(new Date(end)).format('YYYY-MM-DD');
        this.changeDate.emit(this.filterData);
    }

    filterStartDate = (result: Date): void => {
        if (!result) {
            return;
        }
        this.filterData.start_date = moment(result).format('YYYY-MM-DD');
        this.changeDate.emit(this.filterData);
    };

    filterEndDate = (result: Date): void => {
        if (!result) {
            return;
        }
        this.filterData.end_date = moment(result).format('YYYY-MM-DD');
        this.changeDate.emit(this.filterData);
    };

    isValid(): boolean {
        return this.startDateControl.valid && this.endDateControl.valid;
    }
}
