import { Component, Input } from '@angular/core';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import * as moment from 'moment';

@Component({
    selector: 'expiring-chip',
    templateUrl: './expiring-chip.html',
    styleUrls: ['./expiring-chip.scss'],
})
export class ExpiringChip {
    @Input() expiration: string;
    @Input() expired?: boolean;
    @Input() itemExpiringSoon?: boolean;
    expiringSoon: boolean;
    hasExpired: boolean;

    constructor(private hospitalInfoService: HospitalInfoService) {}

    ngOnInit() {
        const expirationDateObj = moment(this.expiration);
        const today = moment(new Date());
        const expiringSoonHospitalSettings = this.hospitalInfoService.hospitalSetting('expiring_soon_days');

        this.hasExpired = this.expired || expirationDateObj.diff(today, 'days') < 0;
        this.expiringSoon = this.expiringSoon || expirationDateObj.diff(today, 'days') < expiringSoonHospitalSettings;
    }

    getFormattedExpiration(expirationDate: string) {
        if (!moment(expirationDate).isValid()) {
            return '-';
        }

        const formattedDate = moment(expirationDate).format('MMM DD, YYYY');

        return formattedDate;
    }
}
