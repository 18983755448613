import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { TranslationService } from '@services/utils/translation.service';
import { isEmpty } from '@utils/objects';

interface ApiData {
    removed_reason: string; // a | joined list of reasons
}

@Component({
    selector: 'removed-reasons-filter',
    templateUrl: './removed-reasons-filter.html',
    providers: [{ provide: 'IFilter', useExisting: RemovedReasonsFilter }],
})
export class RemovedReasonsFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: any[] | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeRemovedReasons = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    dropdownSettings: any = {
        singleSelection: false,
        text: this.translationService.instant('reports.select_removed_state'),
        selectAllText: this.translationService.instant('common.select_all'),
        unSelectAllText: this.translationService.instant('common.clear_all'),
        enableSearchFilter: true,
        classes: this.translationService.instant('carts.multiselect.classes'),
        labelKey: this.translationService.instant('carts.multiselect.labelKey'),
        width: 250,
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: this.translationService.instant('reports.select_removed_state'),
        objectTypeName: 'Remove Reason',
    };

    reasonExpired = this.translationService.instant('report_filters.removed_reason.expired');
    reasonExpiringSoon = this.translationService.instant('report_filters.removed_reason.expiring_soon');

    REMOVED_REASONS = [
        { id: this.reasonExpired.toLowerCase(), name: this.reasonExpired },
        { id: this.reasonExpiringSoon.toLowerCase(), name: this.reasonExpiringSoon },
    ];

    constructor(private translationService: TranslationService) {}

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.removed_reason
                .split('|')
                .map((reason) => {
                    return { id: reason };
                })
                .filter((n) => !!n.id);
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterRemovedReason();
        } else {
            this.reset();
        }
    }

    filterRemovedReason(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeRemovedReasons.emit(this.filterData);
        this.incomplete.emit({ filter: 'removedReasons', incomplete: isEmpty(this.filterData) });
    }

    reset() {
        this.filterData = [...this.REMOVED_REASONS];
        this.changeRemovedReasons.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        return [
            {
                removed_reason: this.filterData
                    .map((reason) => reason.id)
                    .filter((n) => n)
                    .join('|'),
            },
        ];
    }
}
