import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
    selector: 'kit-scan-summary',
    templateUrl: './kit-scan-summary.html',
})
export class KitScanSummary {
    @Input() kitId;
    @Input() kitSummary;
    @Input() allowMultidose: boolean;
    @Input() happyScan: boolean;
    @Input() scanId;

    @Output() loadKitSummary = new EventEmitter();

    updateKitSegment: boolean;

    constructor() {}

    ngOnInit() {}

    refresh(spinnerType?) {
        this.loadKitSummary.emit(spinnerType);
    }
}
