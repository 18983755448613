import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';

@Injectable({ providedIn: 'root' })
export class RecallsNetworkResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource
    ) {}

    resolve(): Promise<any> {
        let recallsNetworkPromise;
        if (this.actionService.isAllowAction('administration', 'view_recalls', 'Load recalls network list data')) {
            recallsNetworkPromise = this.recallResource.recallsNetworkList().then((data) => {
                return data.shared_recalls;
            });
        } else {
            recallsNetworkPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(recallsNetworkPromise);
    }
}
