import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'category-info',
    templateUrl: './category-info.html',
    styleUrls: ['./category-info.scss'],
})
export class CategoryInfo implements OnInit {
    @Input() numberUnits: number;
    @Input() percentage: number;
    @Input() price: number;
    @Input() titleKey: string;

    constructor() {}

    ngOnInit() {}
}
