import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitResource } from '@resources/kit-resource.service';

@Injectable({ providedIn: 'root' })
export class KitsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitResource: KitResource
    ) {}

    resolve(): Promise<any> {
        let kitPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kits_inventory', 'Load kits list data')) {
            kitPromise = this.kitResource.kitList().then((data) => {
                return data.kits;
            });
        } else {
            kitPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(kitPromise);
    }
}
