import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createCustomInputControlValueAccessor } from '@utils/component-util';
import { InputComponent } from '../custom-input/custom-input.component';

@Component({
    selector: 'kc-number-stepper',
    templateUrl: './kc-number-stepper.component.html',
    styleUrls: ['./kc-number-stepper.component.scss'],
    providers: [createCustomInputControlValueAccessor(NumberStepperComponent)],
})
export class NumberStepperComponent extends InputComponent {
    @Input() model: number = 0;
    @Input() max: number = 999;
    @Input() min: number = 0;
    @Input() name: string;
    @Output() valueChange = new EventEmitter<number>();

    dec() {
        if (this.model > 0) {
            this.model--;
        }
        this.dispatchChange(this.model);
    }

    inc() {
        if (!this.model) {
            this.model = 0;
        }

        if (this.model < this.max) {
            this.model++;
        }

        this.dispatchChange(this.model);
    }

    dispatchChange(value: number) {
        this.valueChange.emit(value);
    }
}
