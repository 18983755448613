import { Component, ElementRef, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';

@Component({
    selector: 'lib-ngx-forgot-password',
    templateUrl: './ngx-forgot-password.component.html',
    styleUrls: ['./ngx-forgot-password.component.scss'],
})
export class NgxForgotPasswordComponent {
    attemptingSendEmail: boolean = false;

    @ViewChild('email', { static: true }) emailInput: ElementRef;

    constructor(
        private loginService: NgxLoginService,
        private stateService: StateService
    ) {
        setTimeout(() => this.emailInput.nativeElement.focus());
    }

    sendResetPwEmail(email: string): void {
        this.attemptingSendEmail = true;

        this.loginService
            .forgotPassword(email)
            .subscribe(() => this.stateService.go('login', { passwordResetEmail: email }));
    }

    backToLoginClicked(): void {
        this.stateService.go('login');
    }
}
