import { Input, OnInit, Component } from '@angular/core';
import { CookieService } from 'ngx-cookie';

import { ActionService } from '@services/utils/action.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { TranslationService } from '@services/utils/translation.service';
import * as moment from 'moment';

@Component({
    selector: 'item-attributes',
    templateUrl: './item-attributes.html',
    styleUrls: ['./item-attributes.scss'],
})
export class ItemAttributes implements OnInit {
    @Input() tag;
    @Input() stepper;
    @Input() type;

    showAvailablePreTaggedMessage: boolean = true;
    userId: number;

    constructor(
        private actionService: ActionService,
        private cookieService: CookieService,
        private formularyItemResource: FormularyItemResource,
        private localStorageService: LocalStorageService,
        private parseFormErrorsService: ParseFormErrorsService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        //getting the userID so that we can use it in the optOut of message cookie
        this.userId = parseInt(this.localStorageService.get('userId'));
    }

    expirationProvided(): boolean {
        return !(this.tag.expire === undefined || this.tag.expire === '' || this.tag.expire === null);
    }

    step(): number {
        return this.stepper.selectedIndex + 1;
    }

    tagExpirationMessage(): string {
        if (!this.tag) {
            return '';
        }

        const expirationTranslated = this.translationService.instant('headers.expiration');

        const fridgeExpirationWantedOnTag = this.tag.calculate_fridge_date;

        if (
            this.tag.item.expiring_fridge_days &&
            this.tag.hospitalSettings &&
            this.tag.hospitalSettings.print_item_fridge_date_custom_msg
        ) {
            return this.tag.hospitalSettings.print_item_fridge_date_custom_msg;
        } else if (this.tag.item.expiring_fridge_days && fridgeExpirationWantedOnTag) {
            let fridge_expiration: any = new Date();
            const days = this.tag.item.expiring_fridge_days;
            fridge_expiration.setTime(fridge_expiration.getTime() + days * 86400000);
            fridge_expiration = moment(fridge_expiration).format('YYYY-MM-DD');
            if (fridge_expiration < this.tag.item.expire_formatted || (fridge_expiration && this.tag.expireDisabled)) {
                return `${expirationTranslated}: ${fridge_expiration}`;
            } else if (this.expirationProvided() && !this.tag.expireDisabled) {
                return `${expirationTranslated}: ${this.tag.item.expire_formatted}`;
            } else {
                return `${expirationTranslated}:`;
            }
        } else if (this.expirationProvided() && !this.tag.expireDisabled) {
            return `${expirationTranslated}: ${this.tag.item.expire_formatted}`;
        } else {
            return `${expirationTranslated}:`;
        }
    }

    closeAvailablePreTagged() {
        this.showAvailablePreTaggedMessage = false;
    }

    optOutAvailabilityNotice() {
        let exp = new Date();
        exp.setFullYear(exp.getFullYear() + 1);
        this.cookieService.put(`${this.userId}${this.tag.item.ndc}`, 'true', { expires: exp });
        this.showAvailablePreTaggedMessage = false;
    }

    isAvailablePreTagged() {
        if (this.showAvailablePreTaggedMessage) {
            if (this.tag.item.formulary_type_string === 'registry') {
                // don't show if this is already a pre-tagged item
                this.showAvailablePreTaggedMessage = false;
                return this.showAvailablePreTaggedMessage;
            }

            let optedOut = this.cookieService.get(`${this.userId}${this.tag.item.ndc}`) === 'true';
            if (optedOut) {
                // don't show if the user has opted out for this ndc
                this.showAvailablePreTaggedMessage = false;
                return this.showAvailablePreTaggedMessage;
            }

            // get show for this ndc
            if (this.tag.item.available_pretagged !== undefined) {
                this.showAvailablePreTaggedMessage = this.tag.item.available_pretagged;
            }
        }

        return this.showAvailablePreTaggedMessage;
    }

    fridgeTimerWillStart(): boolean {
        return this.tag.hospitalSettings && this.tag.item.expiring_fridge_days && this.tag.calculate_fridge_date;
    }
}
