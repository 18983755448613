import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionService } from '@services/utils/action.service';
import { TranslateService } from '@ngx-translate/core';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { StateService, Transition } from '@uirouter/core';

@Component({
    selector: 'pre-tagged-medications',
    templateUrl: './pre-tagged-medications.html',
    styleUrls: ['./pre-tagged-medications.scss'],
})
export class PretaggedMedications implements OnInit {
    // Bindings from UI-Router Resolvers
    @Input() usageSummary;

    // Member Vars
    chargeModel: string;
    successMessage: string;
    threshold: number;
    dismiss: string;
    canAddCredits: boolean = false;

    constructor(
        public actionService: ActionService,
        protected hospitalInfoService: HospitalInfoService,
        private toast: MatSnackBar,
        private $stateParams: Transition,
        private $state: StateService,
        private translate: TranslateService
    ) {
        this.translate
            .get(['pre_tagged_medications.add_credits_success', 'pre_tagged_medications.dismiss'])
            .subscribe((translation) => {
                this.successMessage = translation['pre_tagged_medications.add_credits_success'];
                this.dismiss = translation['pre_tagged_medications.dismiss'];
            });
    }

    ngOnInit() {
        this.chargeModel = this.hospitalInfoService.getHospitalSettings().charge_model_type?.name;
        this.canAddCredits = this.actionService.isAllowAction('charge_model', 'add_credits_from_banner', 'Add credits');
    }

    goToHospitalSettings() {
        this.$state.go('admin.hospital');
    }

    goToUserSettings() {
        this.$state.go('settings');
    }
}
