import { Injectable } from '@angular/core';
import { BarcodeScanService } from '@services/core/barcode-scan.service';

@Injectable({ providedIn: 'root' })
export class ScanInventoryResolver {
    constructor(private barcodeScanService: BarcodeScanService) {}

    resolve(): Promise<any> {
        this.barcodeScanService.registerInventoryListener();
        return Promise.resolve();
    }
}
