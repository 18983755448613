import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mercy-ect-brevital',
    templateUrl: './mercy-ect-brevital.html',
})
export class MercyEctBrevital implements OnInit {
    @Input() chargeData;
    @Input() kitSummary;

    constructor() {}

    ngOnInit() {}
}
