import * as LDClient from 'launchdarkly-js-client-sdk';
import { Injectable } from '@angular/core';
import { UserSession } from '@models/core/user-session';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigurationProvider } from '@services/config/configuration';
import { FEATURE_FLAGS_DEFAULTS } from '@models/common/feature-flags';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { GroupLoginService } from '@services/login/group-login.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LaunchDarklyService {
    client: LDClient.LDClient;
    TIMEOUT = 10000; // ms
    clientInitialization$: Subject<Object> = new Subject<Object>();
    flags: Object = {};

    constructor(
        private configuration: ConfigurationProvider,
        private groupLoginService: GroupLoginService
    ) {}

    private initializeListeners() {
        this.client.on('ready', () => {
            this.setupFlags();
        });
    }

    private setupFlags() {
        this.flags = this.client.allFlags();
        this.clientInitialization$.next();
    }

    private createLaunchDarklyInstance(context: LDClient.LDContext) {
        const launchDarklyClientSideId = this.configuration.getConfiguration().launch_darkly_client_side_id;
        this.client = LDClient.initialize(launchDarklyClientSideId, context);
        this.initializeListeners();
    }

    createInstance(userSession: UserSession) {
        const userContext: LDClient.LDContext = {
            kind: 'user',
            key: `user-${userSession.id}`,
            firstName: userSession.first_name,
            lastName: userSession.last_name,
            email: userSession.email,
        };

        const hospitalContext: LDClient.LDContext = {
            kind: 'organization',
            key: `hospital-${userSession.current_hospital?.id}`,
            name: userSession.current_hospital?.name,
        };

        const multiContext: LDClient.LDContext = {
            kind: 'multi',
            user: userContext,
            organization: hospitalContext,
        };

        if (this.client) {
            this.setContext(multiContext);
            return;
        }

        this.createLaunchDarklyInstance(multiContext);
    }

    setContext(
        context: LDClient.LDContext,
        hash?: string,
        onDone?: (err: null | Error, flags: null | LDFlagSet) => void
    ) {
        /*
            This if condition covers an edge case scenario. If user inputs the correct credentials and has access to multiple hospitals,
            they will be redirected to the hospital selection screen. If the user refresh the page at that point,
            the app will be in a weird state in which the LD client wouldn't be instantiated yet.
        */
        if (!this.client) {
            this.createLaunchDarklyInstance(context);
        }

        this.client.identify(context, hash, onDone);
    }

    getFlag(flagKey: string): Observable<LDClient.LDFlagValue> {
        const flagChanged$ = new Subject<LDClient.LDFlagValue>();
        if (this.client) {
            this.client.waitUntilReady().then(() => {
                this.handleFeatureValueCheck(flagKey, flagChanged$);
            });
        }

        // Fallback in case the function to get the feature value is called and the client isn't initialized yet
        this.clientInitialization$.subscribe(() => {
            this.handleFeatureValueCheck(flagKey, flagChanged$);
        });

        return flagChanged$.pipe(
            map(() => {
                return this.client.variation(flagKey, FEATURE_FLAGS_DEFAULTS[flagKey]);
            })
        );
    }

    handleFeatureValueCheck(flagKey: string, observable$: Subject<LDClient.LDFlagValue>) {
        this.client.on(`change:${flagKey}`, () => {
            observable$.next();
        });
        this.client.waitUntilReady().then(() => {
            observable$.next();
        });
    }

    setFlag(flagKey, observable$: Subject<LDClient.LDFlagValue>) {
        observable$.next(this.client.variation(flagKey, FEATURE_FLAGS_DEFAULTS[flagKey]));
    }
}
