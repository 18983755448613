import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import * as moment from 'moment';

import { ActionService } from '@services/utils/action.service';
import { RecallResource } from '@resources/recall-resource.service';
import { IFilter } from '@models/common/report-filter';
import { isEmpty, arrayUnique } from '@utils/objects';
import { UomScalarPipe } from '@pipes/uom-scalar.pipe';

interface ApiData {
    recall_ids: string;
}

interface RecallData {
    date_modified?: number | string;
    formattedLotNumbers?: string;
    formulary_item_deleted_at?: string;
    formulary_item_id?: number;
    groupId?: number;
    hospitalName?: string;
    id: number;
    itemDescription?: string;
    item_name?: string;
    item_strength?: {
        scalar: string;
        scalar_formatted: string;
        uom: string;
    };
    lot_numbers?: string[];
    manufacturer_name?: string;
    ndc?: string;
    package_description?: string;
    package_size?: {
        scalar: number;
        scalar_formatted: string;
        uom: string;
    };
    reason_detail?: string;
    reason_formatted?: string;
    recall_reason?: {
        id: number;
        name: string;
        display_name: string;
    };
    selected?: boolean;
    updated_at?: string;
}

@Component({
    selector: 'recalls-filter',
    templateUrl: './recalls-filter.html',
    styleUrls: ['./recalls-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: RecallsFilter }],
})
export class RecallsFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: RecallData[] | null;
    @Input() reportSubscription: boolean;
    @Input() isSystem: boolean = false;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeFilterData = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton or run report button if required and empty
    prefilter: boolean = false;

    private _groupIds: string;

    @Input() set groupIds(value: string) {
        this._groupIds = value;
        if (this.isSystem) {
            this.recallsList = arrayUnique(this.idnFilterRecallsList(this.groupedRecallsList, this._groupIds));
            this.recallDataSource = new MatTableDataSourceWithNaturalSort(this.recallsList);
            this.recallDataSource.sort = this.sort;
            this.emitFilterData();
        }
    }

    get groupIds(): string {
        return this._groupIds;
    }

    allowedToAdmin: boolean;
    recallDataSource: MatTableDataSourceWithNaturalSort<any>;
    recallsList: RecallData[];
    groupedRecallsList: RecallData[];
    allRecallsSelected: boolean = false;
    someRecallsSelected: boolean = false;

    displayedColumns: string[];

    recallDisplayedColumns: string[] = [
        'selector',
        'ndc',
        'itemDescription',
        'lot_numbers',
        'reason_formatted',
        'date_modified',
    ];

    groupDisplayedColumns: string[] = [
        'selector',
        'hospitalName',
        'ndc',
        'itemDescription',
        'lot_numbers',
        'reason_formatted',
        'date_modified',
    ];

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        protected recallResource: RecallResource
    ) {}

    ngOnInit() {
        this.prefilter = !!this.filterData;
        this.allowedToAdmin = this.actionService.isAllowAction(
            'administration',
            'view_recalls',
            'Display no-recalls message on Recalls report'
        );

        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.recall_ids.split('|').map((recall) => {
                return { id: Number(recall), selected: true };
            });
        }

        if (this.isSystem) {
            this.displayedColumns = this.groupDisplayedColumns;
        } else {
            this.displayedColumns = this.recallDisplayedColumns;
        }

        this.emitFilterData();
        this.getRecallsList(this.filterData);
    }

    getRecallsList(listData) {
        let promises = [];
        if (!this.isSystem) {
            let recallPromise = this.recallResource.recallsList().then((data: any) => {
                this.recallsList = data.recalls;
                this.setRecallsTableData(listData);
            });
            promises.push(recallPromise);
        } else {
            // get the grouped recalls list instead of the recalls list for just this hospital
            let grpRecallPromise = this.recallResource.groupedRecallsList().then((data) => {
                //flatmap all of the group recalls
                this.groupedRecallsList = [];
                data.groups.forEach((group) => {
                    this.groupedRecallsList = this.groupedRecallsList.concat(
                        group.recalls.map((recall) => {
                            recall.hospitalName = group.name;
                            recall.groupId = group.id;
                            return recall;
                        })
                    );
                });
                this.recallsList = this.groupedRecallsList;
                this.setRecallsTableData(listData);
                // filter down to selected groups
                this.recallsList = this.idnFilterRecallsList(this.recallsList, this.groupIds);
            });
            promises.push(grpRecallPromise);
        }

        // then filter/emit the data as needed
        Promise.all(promises).then(() => {
            if (!!this.reportSubscription || this.prefilter) {
                this.filterData = listData;
            }

            this.recallDataSource = new MatTableDataSourceWithNaturalSort(this.recallsList);
            this.recallDataSource.sort = this.sort;
        });
    }

    // so we can sort and display properly, need to set a few more keys
    setRecallsTableData(listData) {
        this.recallsList.forEach((recall, i) => {
            recall.itemDescription = this.itemDescription(recall);
            recall.date_modified = Date.parse(recall.updated_at);
            recall.reason_formatted = recall.recall_reason.display_name;
            recall.formattedLotNumbers = recall.lot_numbers.join('<br>');
            if (!!listData) {
                recall.selected = !!listData.find((selected_recall) => selected_recall.id === recall.id);
            }
        });
    }

    // only show recalls from the selected hospital group
    idnFilterRecallsList(groupedRecalls, groupIds) {
        if (isEmpty(groupedRecalls)) return;

        // covert the group Ids list (which is a string delimited by |)
        // to an array so we can compare group Ids properly
        // (we don't want group ID 29 returning true for group ID 129)
        let groupIdsArray = [];
        if (!!groupIds) {
            groupIdsArray = groupIds.split('|').map(Number);
        }

        // clean out selected recalls that no longer have a selected group
        this.recallsList.forEach((recall) => {
            if (!groupIdsArray.includes(recall.groupId)) {
                recall.selected = false;
            }
        });

        // then, filter the list of recalls to the selected group.
        let recallsFromSelectedGroups = groupedRecalls.filter((recall) => {
            return groupIdsArray.includes(recall.groupId);
        });

        return recallsFromSelectedGroups;
    }

    itemDescription(recall) {
        let strengthText = '';
        let packageText = '';

        if (!!recall.item_strength) {
            strengthText = [recall.item_strength.scalar_formatted, recall.item_strength.uom].filter((x) => x).join(' ');
        }

        if (!!recall.package_size) {
            packageText = [recall.package_size.scalar_formatted, recall.package_size.uom].filter((x) => x).join(' ');
        }

        return (recall.itemDescription = `${recall.item_name} ${strengthText}<br>${packageText} ${recall.package_description}`);
    }

    toggleAll(event) {
        this.allRecallsSelected = event;
        if (!!this.recallsList) {
            this.recallsList.forEach((item) => {
                item.selected = event;
            });
            this.filterData = this.recallsList.filter((recall) => recall.selected);
            this.emitFilterData();
        }
    }

    allSelected(): boolean {
        if (!!this.recallsList) {
            return this.recallsList.every((recall) => recall.selected);
        }
        return false;
    }

    someSelected(): boolean {
        if (!!this.recallsList && !this.allSelected()) {
            return this.recallsList.some((recall) => recall.selected);
        }
        return false;
    }

    filterDataChanged(checked, recall) {
        // it seems like this should be updated via ngModel but it hasn't
        // when this is called on the change event, so setting manually
        // so we can emit the change
        recall.selected = checked;
        this.filterData = this.recallsList.filter((recall) => recall.selected);
        this.emitFilterData();
    }

    emitFilterData(): void {
        if (!!this.reportSubscription) {
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            this.changeFilterData.emit(this.filterData);
        }
        this.incomplete.emit({ filter: 'recalls', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.toggleAll(false);
        this.changeFilterData.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        let recall_ids = this.filterData
            .map((recall) => recall.id)
            .filter((n) => n)
            .join('|');
        return [{ recall_ids: recall_ids }];
    }
}
