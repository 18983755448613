import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    kit_master_id: string; // | joined string of kit master ids
    report_mode: string; // string that should be bin or kit
}

interface FilterData {
    binKit: string;
    kitMasters?: Array<any>; // this may be an array of a hash with just ids, or an array of the full kit master data.
}

@Component({
    selector: 'bin-kit-masters-filter',
    templateUrl: './bin-kit-masters-filter.html',
    providers: [{ provide: 'IFilter', useExisting: BinKitMastersFilter }],
})
export class BinKitMastersFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: FilterData; // hash from report-filter component
    @Input() maxHeight: number;
    @Input() reportSubscription: boolean;
    @Input() groupIds: string;
    @Input() isSystem: boolean = false;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeBinKitMasters = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    @ViewChildren('IFilter') children: QueryList<IFilter>;

    prefilter: boolean = false;

    ngOnInit() {
        this.prefilter = !!this.filterData?.binKit && !!this.filterData?.kitMasters;

        if (!!this.apiSubscriptionData) {
            this.filterData = { binKit: this.apiSubscriptionData.report_mode, kitMasters: null };
            if (!!this.apiSubscriptionData.kit_master_id) {
                this.filterData.kitMasters = this.apiSubscriptionData.kit_master_id.split('|').map((km) => {
                    return { id: Number(km) };
                });
            }
        }

        if (!!this.reportSubscription || this.prefilter) {
            //for reportSubscription modal, we want the selected values to persist from report page
            this.apiFormattedData.emit(this.formatForApi());
            this.changeBinKitMasters.emit(this.filterData);
        } else {
            this.filterData = { binKit: null, kitMasters: null };
        }
    }

    reset(): void {
        this.children.toArray().forEach((child) => {
            child.reset();
        });
    }

    onChangeBinKit(data): void {
        this.filterData.binKit = data;
        this.changeBinKitMasters.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'binKitMasters', incomplete: isEmpty(this.filterData.binKit) });
    }

    onChangeKitMasters(data): void {
        this.filterData.kitMasters = data;
        this.changeBinKitMasters.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'binKitMasters', incomplete: isEmpty(this.filterData.kitMasters) });
    }

    formatForApi(): Array<any> {
        if (!!this.filterData.kitMasters) {
            let kitMasters = this.filterData.kitMasters
                .map((kitMaster) => kitMaster.id)
                .filter((n) => n)
                .join('|');
            return [{ kit_master_id: kitMasters }, { report_mode: this.filterData.binKit }];
        }
    }
}
