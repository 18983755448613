import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { TranslationService } from '@services/utils/translation.service';
import { BarcodeResource } from '@resources/barcode-resource.service';

import { Kit } from '@models/core/kit';
import { Cart } from '@models/core/cart';
import { Location } from '@models/core/location';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { AllowlistResource } from '@resources/allowlist-resource.service';

// description and detail can contain html
interface DialogData {
    locations: string[];
    kits: Array<any>;
    name: string;
    carts: Array<any>;
    target: 'cart' | 'location' | 'cart_location';
    showKits: boolean;
}

interface DialogResults {
    cart_id?: number;
    cart_location_id?: number;
    location_id?: number;
    kit_ids: number[];
    removeLocations?: boolean;
    removeCarts?: boolean;
}

@Component({
    selector: 'assign-kits-to-location-dialog',
    templateUrl: './assign-kits-to-location-dialog.html',
    styleUrls: ['./assign-kits-to-location-dialog.scss'],
})
export class AssignKitsToLocationDialog {
    aeroscout: boolean = false;
    allowCartToLocation: boolean;
    allowEditLocation: boolean;
    detailTitle: string;
    dialogTitle: string;
    carts: Cart[];
    hasKitCartLocationOverride: boolean;
    kits: Kit[];
    kitsWithLocations: Kit[];
    kitsWithCarts: Kit[];
    locations: Array<any>;
    new_location: number = null;
    new_cart: number = null;
    new_cart_location: number = null;
    selectedCart: Cart;
    selectedCartLocation: Location;
    selectedCartsNames: string;
    selectedLocation: Location;
    showKits: boolean = true;
    target: string;
    uniqueCarts: Array<string>;
    uniqueLocations: number[];
    allowedCartsLoading: boolean = false;
    allowedLocationsLoading: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['kit_master_name', 'physical_label', 'location_name', 'cart_name'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        protected barcodeResource: BarcodeResource,
        protected barcodeScanService: BarcodeScanService,
        private hospitalInfoService: HospitalInfoService,
        public dialogRef: MatDialogRef<AssignKitsToLocationDialog>,
        protected loadingSpinnerService: LoadingSpinnerService,
        private allowlistService: AllowlistResource,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.loadingSpinnerService.useSpinnerType('panel');
        this.kits = _.cloneDeep(this.data.kits);
        this.target = this.data.target;
        this.showKits = this.data.showKits;
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.kits);
        this.dialogTitle = `modals.kit.edit_${this.target}_title`;
        this.detailTitle = `modals.kit.${this.target}_info_for_kits`;
        this.kitsWithLocations = this.kits.filter((kit) => !!kit.location?.id);
        this.uniqueLocations = _.uniq(this.kitsWithLocations.map((kit) => kit.location.id));
        this.kitsWithCarts = this.kits.filter((kit) => !!kit.cart?.id);
        this.uniqueCarts = _.uniq(this.kitsWithCarts.map((kit) => kit.cart).map((cart) => cart.name));
        this.selectedCartsNames = this.uniqueCarts.join(', ');
        this.configurePermissions();

        this.locations = this.data.locations;
        this.carts = this.data.carts;
        this.initializeAllowlists().then(() => {
            this.configureTarget();
            this.registerScanner();
        });
    }

    registerScanner() {
        this.barcodeScanService.registerListener((scanData) => {
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object.class === 'Location') {
                    this.new_location = data.object.id;
                    this.setCartLocation();
                    this.onOk();
                }
                if (data && data.object.class === 'Cart') {
                    this.new_cart = data.object.id;
                    this.getCartLocation(this.new_cart);
                    this.onOk();
                }
            });
        }, 'assignToLocation');
    }

    cartsWithDifferentLocations = () => {
        if (this.showKits) {
            const fullCartData = _.uniq(
                this.kitsWithCarts.map((kit) => this.carts.filter((cart) => cart.id === kit.cart.id)),
                'id'
            );
            const locationChangingCarts = _.filter(
                fullCartData[0],
                (cart: any) => _.get(this.new_location, 'id') !== _.get(cart.location, 'id')
            );
            return locationChangingCarts.map((cart: any) => cart.name).join(', ');
        } else {
            return this.kits[0].cart_name;
        }
    };

    showCartLocationUpdateWarning() {
        this.selectedCart = this.carts.find((cart) => cart.id === this.new_cart);
        return (
            (this.allowEditLocation &&
                this.new_cart_location &&
                this.new_cart &&
                this.new_cart_location !== this.hasLocation()) ||
            (!this.new_cart_location && this.hasLocation())
        );
    }

    hasLocation = () => {
        if (!!this.new_cart && this.selectedCart?.location) {
            return this.selectedCart.location.id;
        } else {
            return null;
        }
    };

    setNewLocation() {
        this.selectedLocation = this.locations.find((location) => location.id === this.new_location);

        if (!this.selectedLocation) {
            this.new_location = null;
        }
        this.setCartLocation();
    }

    setCartLocation() {
        if (this.target === 'cart_location' && !!this.new_cart) {
            this.new_cart_location = this.new_location;
        } else {
            this.new_cart_location = null;
        }
    }

    setNewCart() {
        this.selectedCart = this.carts.find((cart) => cart.id === this.new_cart);
        if (!this.selectedCart) {
            this.new_cart = null;
            this.new_cart_location = null;
        } else {
            this.getCartLocation(this.new_cart);
        }
    }

    getCartLocation(cartId) {
        let cart = this.carts.find((cart) => cart.id === cartId);
        this.aeroscout = !!cart?.aeroscout_asset_id;

        if (!!cart.location) {
            this.selectedCartLocation = this.locations.find((location) => location.id === cart.location.id);
            this.new_cart_location = this.selectedCartLocation?.id;
        } else {
            this.new_cart_location = null;
        }
    }

    setNewCartLocation() {
        this.selectedCartLocation = this.locations.find((location) => location.id === this.new_cart_location);
        // this is the case where they change the dropdown to Select a Location
        if (!this.selectedCartLocation) {
            this.new_cart_location = null;
        }
    }

    removeLocations() {
        this.dialogRef.close({ removeLocations: true });
    }

    removeCarts() {
        this.dialogRef.close({ removeCarts: true });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        let data: DialogResults = {
            kit_ids: this.kits.map((kit) => kit.id),
        };
        if (this.new_cart) {
            data.cart_id = this.new_cart;
        }
        if (this.new_location) {
            data.location_id = this.new_location;
        }
        if (this.new_cart_location) {
            data.cart_location_id = this.new_cart_location;
        }

        // if it's an aeroscout cart, we don't send location
        if (this.aeroscout) {
            delete data.cart_location_id;
        }
        this.dialogRef.close(data);
    }

    configureTarget() {
        // preset the selects if we only have 1 location or cart coming in.
        if (this.target === 'location') {
            if (this.uniqueLocations.length === 1) {
                this.new_location = this.kits[0].location?.id || null;
            } else {
                this.new_location = null;
            }
        } else if (this.target === 'cart_location') {
            if (this.uniqueCarts.length === 1) {
                this.new_cart = this.kits[0].cart?.id || null;
                let cart = this.carts.find((cart) => cart.id === this.new_cart);
                if (!!cart) {
                    this.aeroscout = !!cart.aeroscout_asset_id;
                } else {
                    this.new_cart = null;
                }
            } else {
                this.new_cart = null;
            }

            if (this.uniqueLocations.length === 1) {
                this.new_cart_location = this.kits[0].location?.id || null;
                this.new_location = this.kits[0].location?.id || null;
            } else {
                this.new_location = null;
            }
        } else if (this.target === 'cart') {
            if (this.uniqueCarts.length === 1) {
                this.new_cart = this.kits[0].cart?.id || null;

                let cart = this.carts.find((cart) => cart.id === this.new_cart);
                this.aeroscout = !!cart?.aeroscout_asset_id;
                this.new_cart_location = cart?.location?.id || null;
                this.new_location = null;
            } else {
                this.new_cart = null;
                this.new_cart_location = null;
            }
        }
    }

    configurePermissions() {
        this.hasKitCartLocationOverride = this.hospitalInfoService.hospitalSetting('kit_cart_location_override');
        this.allowCartToLocation = this.hospitalInfoService.hospitalSetting('cart_to_location_enabled');
        this.allowEditLocation = this.allowCartToLocation && !this.hasKitCartLocationOverride;
    }

    initializeAllowlists(): Promise<any> {
        return this.loadCartAllowlist().then(() => {
            return this.loadLocationAllowlist();
        });
    }

    loadLocationAllowlist(ignoreKitRelatedCarts = false): Promise<any> {
        this.allowedLocationsLoading = true;
        const cart_ids = this.new_cart ? [this.new_cart] : [];
        const kit_ids = this.kits.map((kit) => kit.id);
        return this.allowlistService
            .allowlistComputedLocations({
                kit_ids: kit_ids,
                cart_ids: cart_ids,
                ignore_kit_related_carts: ignoreKitRelatedCarts,
            })
            .then(({ allowed_locations }) => {
                this.locations = allowed_locations;
                this.allowedLocationsLoading = false;
            });
    }

    loadCartAllowlist(): Promise<any> {
        this.allowedCartsLoading = true;
        const kit_ids = this.kits.map((kit) => kit.id);
        return this.allowlistService.allowlistComputedCarts({ kit_ids: kit_ids }).then(({ allowed_carts }) => {
            this.carts = allowed_carts;
            this.allowedCartsLoading = false;
        });
    }

    cartSelectionChanged() {
        this.loadLocationAllowlist(true);
    }

    editCartDisabled() {
        return !!this.new_location && this.allowEditLocation;
    }

    editLocationDisabled() {
        return !!this.new_cart && this.allowEditLocation;
    }

    editCartLocationDisabled() {
        return this.aeroscout || !this.new_cart;
    }

    allowlistLoading() {
        return this.allowedCartsLoading || this.allowedLocationsLoading;
    }
}
