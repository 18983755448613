/* eslint-disable @typescript-eslint/naming-convention */

export const FEATURE_FLAGS_DEFAULTS = {
    'use-new-charge-sheet-customization': false,
    'redirect-to-mobile': false,
    'kit-cart-location-allowlists-enabled': false,
    'use-containers': false,
};

export type FeatureFlagKey = keyof typeof FEATURE_FLAGS_DEFAULTS;
