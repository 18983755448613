import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class TagAssociationResource {
    constructor(
        private http: HttpClient,
        private configuration: ConfigurationProvider
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    associateTags(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}items/tag_associations`, data).toPromise();
    }

    associateKitTag(epc, kitMasterId, physicalLabel, epcAndLabels): Promise<any> {
        const data: any = {
            kit_master_id: kitMasterId.toString(),
        };

        if (epcAndLabels) {
            data.epc_and_labels = _.map(epcAndLabels, (epcAndLabel) => {
                return _.pick(epcAndLabel, ['physical_label', 'epc']);
            });
        } else {
            _.merge(data, {
                epc,
                physical_label: physicalLabel,
            });
        }

        return this.http.post<any>(`${this.API_ENDPOINT}kits/tag_associations`, data).toPromise();
    }

    getAssociationScan(id): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}views/kit_check/item_association_scans/${id}`).toPromise();
    }
}
