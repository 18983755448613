import { Component, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BadgeAuthService } from '@services/core/badge-auth.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { ScanRedirectionService } from '@services/core/scan-redirection.service';

import { DefaultScannerDialog } from '@dialogs/scanner/default-scanner-dialog/default-scanner-dialog';
import { MissingScannerDialog } from '@dialogs/scanner/missing-scanner-dialog/missing-scanner-dialog';
import { ScanningDialog } from '@dialogs/scanner/scanning-dialog/scanning-dialog';

import { Scanner } from '@models/hardware/scanner';
import { ScanResult, DemoScan } from '@models/core/scan';
import { BadgeAuthStatus } from '@models/core/badge-authorization';

@Component({
    selector: 'scan-button',
    templateUrl: './scan-button.html',
    styleUrls: ['./scan-button.scss'],
})
export class ScanButton implements OnInit, OnDestroy {
    @Input() isdemo: boolean = false;
    @Input() demolinks: DemoScan[];

    @Output() scanCompleted: EventEmitter<ScanResult> = new EventEmitter();

    scanners: Scanner[];
    defaultScanner: Scanner;
    showDropdown: boolean = false;

    buttonValue: string = '';

    private scanningDialog: any = null;

    constructor(
        private badgeAuthService: BadgeAuthService,
        private dialog: MatDialog,
        private groupLoginService: GroupLoginService,
        private hardwareService: HardwareService,
        private scanRedirectionService: ScanRedirectionService
    ) {}

    ngOnInit() {
        if (this.groupLoginService.isLoggedIn() && !this.groupLoginService.requirePassword()) {
            this.setupScanners();
        }
        this.badgeAuthService.observeBadgeAuth().subscribe((badgeStatus: BadgeAuthStatus) => {
            if (!badgeStatus?.isUserVerified && !!this.scanningDialog) {
                // force-close scanningDialog for positive ID badge authorization
                this.scanningDialog.close({ incompleteScan: true });
            }
            if (badgeStatus?.clearScanningDialog) {
                this.scanningDialog = null;
            }
        });
    }

    ngOnDestroy() {
        this.dialog.closeAll();
        this.scanningDialog = null;
    }

    setupScanners() {
        return this.hardwareService.getScanners().then((scanners: Scanner[]) => {
            this.scanners = scanners;
            this.showDropdown = scanners.length > 1 || this.isdemo;
            if (scanners.length === 1) {
                this.defaultScanner = scanners[0];
                this.hardwareService.setDefaultScanner(this.defaultScanner.id);
            } else {
                return this.loadDefaultScanner();
            }
        });
    }

    startScan() {
        this.resetButtonValue();
        this.setupScanners().then(() => {
            if (!this.scanners || this.scanners.length === 0) {
                this.openMissingScannerDialog();
            } else {
                if (!this.defaultScanner) {
                    this.openDefaultScannerDialog();
                } else {
                    this.openScanningDialog();
                }
            }
        });
    }

    openScanningDialog() {
        if (!this.scanningDialog) {
            this.scanningDialog = this.dialog.open(ScanningDialog, {
                width: 'max-content',
                height: 'max-content',
                autoFocus: false,
                closeOnNavigation: true,
                data: {
                    scanner: this.defaultScanner,
                },
            });

            this.scanningDialog.beforeClosed().subscribe((scanResult: any) => {
                // skip completed scan flow when scanningDialog is force-closed for positive ID badge authorization
                if (!scanResult?.incompleteScan) {
                    this.scanCompleted.emit(scanResult);
                    this.scanningDialog = null;
                }
            });
        }
    }

    openDefaultScannerDialog() {
        const defaultScannerDialog = this.dialog.open(DefaultScannerDialog, {
            width: 'max-content',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                scanners: this.scanners,
                currentDefault: this.defaultScanner?.id,
            },
        });

        defaultScannerDialog.afterClosed().subscribe((defaultId: string) => {
            this.resetButtonValue();
            if (!!defaultId) {
                this.hardwareService.setDefaultScanner(defaultId);
                this.loadDefaultScanner().then(() => {
                    this.openScanningDialog();
                });
            }
        });
    }

    openMissingScannerDialog() {
        this.dialog.open(MissingScannerDialog, {
            width: '350px',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
        });
    }

    resetButtonValue() {
        // set to empty value so toggle button loses highlight
        this.buttonValue = '';
    }

    handleDemoLink(demoLink: DemoScan) {
        this.scanRedirectionService.demoRedirect(demoLink);
    }

    private loadDefaultScanner(): Promise<void> {
        return this.hardwareService.getDefaultScanner().then((defaultScanner: Scanner) => {
            this.defaultScanner = defaultScanner;
        });
    }
}
