import { Component, Input } from '@angular/core';
import { TableComponent } from '@components/common/table-component';
import { isEmpty } from '@utils/objects';
import { BinScanData } from '../../models/bin-scan-data';
import { BinScanDetail } from '../../models/bin-scan-detail';
import { ByCEQItem, ByFormularyItem } from '../../models/bin-scan-data';
import { Package } from '@models/core/package';

@Component({
    selector: 'bin-scan-details',
    templateUrl: './bin-scan-details.html',
    styleUrls: ['./bin-scan-details.scss'],
})
export class BinScanDetails extends TableComponent {
    @Input() binScan: BinScanData;
    @Input() binScanDetail: BinScanDetail;
    @Input() wrongItems: Package[];

    items: Package[];

    constructor() {
        super();
    }

    ngOnInit() {
        this.items = this.binScanDetail.segment.items;
    }

    sort(field: string): void {
        this.sortBy(field, this.items);
    }

    expiredOrExpiringSoonClass(item): string {
        let now = new Date();
        let expirationDate = new Date(item.expiration);
        if (now.getTime() > expirationDate.getTime()) {
            return 'expired';
        } else if (!isEmpty(this.getExpiringSoon(item))) {
            return 'expiring';
        }
        return 'hidden';
    }

    getExpiringSoon(item): any {
        return this.binScan.items.expiring_soon.find((expiresSoon) => {
            return expiresSoon.item_snapshot_id === item.item_snapshot_id;
        });
    }
}
