import { Component, OnInit } from '@angular/core';
import { MobileCartService } from '@services/utils/mobile-cart.service';

@Component({
    selector: 'mobile-cart-home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss'],
})
export class MobileCartHomeComponent implements OnInit {
    constructor(private mobileCartService: MobileCartService) {}

    ngOnInit() {
        this.mobileCartService.setupMobileCart();
    }
}
