// Polyfills
import 'core-js/es';
import 'core-js/web';
import 'whatwg-fetch';
import 'zone.js/dist/zone';
import 'hammerjs';

// Official Angular Libraries

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// 3rd Party Liraries
import { AngularMultiSelectModule } from '../../vendor/modules/angular2-multiselect-dropdown';
import { FileUploadModule } from 'ng2-file-upload';
import { CookieModule } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageModule } from 'angular-2-local-storage';
import { MomentDateModule, MomentDateAdapter, MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TransitionService } from '@uirouter/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UIView, UIRouter, UIRouterModule, StateService } from '@uirouter/angular';

// Material Components Module
import { MaterialModule } from './modules/material.module';

// Custom Angular 2 Services
import { services, HttpInterceptorProviders } from './services';
import { resolvers } from './resolvers';

// Custom Angular 2 Validators
import { validators } from './validators';

// Angular 2 Components
import { ChargeSheetTemplates, ChargeSheetTemplatePartials } from './components/charge-sheet-templates';
import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';
import { PageComponents, DialogComponents, UiComponents, OtherEntryComponents } from './components';
import { Pipes } from './pipes';

// Import Page Route Components Barrel and Convert to NGX Pages Array
import { routes } from './routes';
import { AppComponent } from './components/app-component/app-component';

import { LoginService } from './services/login/login.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function config(uiRouter: UIRouter) {
    uiRouter.urlService.rules.otherwise('/');
    uiRouter.urlService.rules.initial({ state: 'home' });
}

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        MatMomentDateModule,
        ReactiveFormsModule,
        SatDatepickerModule,
        SatNativeDateModule,
        FileUploadModule,
        FormsModule,
        UIRouterModule.forRoot({ config: config, states: routes }),
        HttpClientModule,
        AngularMultiSelectModule,
        LocalStorageModule.forRoot({
            prefix: 'kcui',
            storageType: 'localStorage',
        }),
        MaterialModule,
        NgIdleKeepaliveModule.forRoot(),
        NgxSliderModule,
        SatPopoverModule,
        BrowserAnimationsModule,
        CookieModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [...HttpInterceptorProviders, ...resolvers, ...services],
    bootstrap: [AppComponent],
    declarations: [
        ...Pipes,
        ...ChargeSheetTemplates,
        ...ChargeSheetTemplatePartials,
        ...DialogComponents,
        ...OtherEntryComponents,
        ...PageComponents,
        ...UiComponents,
        ...validators,
    ],
    entryComponents: [
        ...ChargeSheetTemplates,
        ...ChargeSheetTemplatePartials,
        ...PageComponents,
        ...DialogComponents,
        ...OtherEntryComponents,
    ],
})
export class AppModule {
    constructor(private loginService: LoginService) {}
}
