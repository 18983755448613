import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'billing-sheet',
    templateUrl: './billing-sheet.html',
})
export class BillingSheet {
    @Input() billingSheet;
    @Input() latestScan;

    constructor() {}

    ngOnInit() {}
}
