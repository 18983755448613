import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { CartResource } from '@resources/cart-resource.service';

@Injectable({ providedIn: 'root' })
export class CartsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private cartResource: CartResource
    ) {}

    resolve(): Promise<any> {
        let cartsPromise;

        if (this.actionService.isAllowAction('hospital_settings', 'view_cart', 'Load cart list data')) {
            cartsPromise = this.cartResource.cartList().then((data) => {
                return data.carts;
            });
        } else {
            cartsPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(cartsPromise);
    }
}
