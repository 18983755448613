import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    report_mode: string; // string that should be bin or kit
}

@Component({
    selector: 'bin-kit-filter',
    templateUrl: './bin-kit-filter.html',
    providers: [{ provide: 'IFilter', useExisting: BinKitFilter }],
})
export class BinKitFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null;
    @Input() prefilter: boolean = false;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeBinKit = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    shelvedInventoryEnabled: boolean;

    constructor(protected hospitalInfoService: HospitalInfoService) {
        this.shelvedInventoryEnabled = this.hospitalInfoService.allowShelvedInventory();
    }

    ngOnInit() {
        // prefilter can either come from the @Input (in the case of binKit used from binKitMasters), or prefilter set here if binKit is used directly from report-filter
        if (!this.prefilter) {
            this.prefilter = !!this.filterData;
        }
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.report_mode;
        }

        if (!!this.reportSubscription || this.prefilter) {
            this.filterType();
        } else {
            this.reset();
        }
    }

    reset() {
        this.filterData = 'kit';
        this.changeBinKit.emit(this.filterData);
    }

    filterType(): void {
        this.changeBinKit.emit(this.filterData);
        this.apiFormattedData.emit(this.formatForApi());
        this.incomplete.emit({ filter: 'binKit', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): ApiData[] {
        return [{ report_mode: this.filterData }];
    }
}
