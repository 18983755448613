import { Component, Input, EventEmitter, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';

import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { SegmentsService } from '@services/core/segments.service';
import { TranslationService } from '@services/utils/translation.service';

import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

@Component({
    selector: 'inventory-segment-edit',
    templateUrl: './segment-edit.html',
})
export class SegmentEdit {
    @Input() kitMaster;
    @Input() segment;

    savingRename: boolean;
    renameSuccess: boolean;

    constructor(
        private dialog: MatDialog,
        private $state: StateService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        private segmentsService: SegmentsService,
        private translationService: TranslationService
    ) {
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
    }

    ngOnInit() {
        this.setupSegment();
    }

    setupSegment() {
        this.segment.newName = this.segment.name;
        this.segment.onShortage = this.segment.shortage;
    }

    disableRename() {
        return (
            this.segment.newName === this.segment.name ||
            !this.segment.newName ||
            !this.segment.newName.length ||
            this.savingRename
        );
    }

    saveRename() {
        if (this.disableRename()) {
            return;
        }

        const newName = this.segment.newName;
        this.savingRename = true;
        this.segmentsService
            .updateSegment({
                id: this.segment.id,
                name: newName,
            })
            .then(() => {
                this.segmentsService.clear(this.kitMaster.id);
                this.segment.name = this.segment.newName = newName;
                this.renameSuccess = true;
            })
            .catch(() => {
                this.renameSuccess = false;
            })
            .finally(() => {
                this.savingRename = false;
            });
    }

    setSegmentShortage(event) {
        this.segment.onShortage = event.target.checked;
        const updateSegmentPromise = this.segmentsService.updateSegment({
            id: this.segment.id,
            shortage: this.segment.onShortage,
        });

        this.loadingSpinnerService.spinnerifyPromise(updateSegmentPromise);
    }

    finishEdit() {
        this.$state.go('inventory-single-kit-master', { kitMasterId: this.kitMaster.id });
    }

    deleteSegment() {
        const remove = this.translationService.instant('buttons.remove');
        const segment = this.translationService.instant('manage_segment.segment');

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: { title: `${remove} ${segment}`, description: this.segment.name, okButton: remove },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.segmentsService.removeSegment(this.segment.id).then(() => {
                    this.segmentsService.clear(this.kitMaster.id);
                    this.$state.go('inventory-single-kit-master', { kitMasterId: this.kitMaster.id });
                });
            }
        });
    }
}
