import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TableComponent } from '@components/common/table-component';
import { SortDirection } from '@services/utils/natural-sort.service';

@Component({
    selector: 'items-list',
    templateUrl: './items-list.html',
})
export class ItemsList extends TableComponent {
    @Input() packages: any[];
    @Input() title: string;
    @Input() showTrust: boolean;

    ngOnInit() {
        if (this.showTrust) {
            _.each(this.packages, (pack) => {
                pack.status = pack.trusted === true ? 'Unrestricted' : 'Restricted';
            });
        }
    }

    sortAllItems(field: string): void {
        this.sortBy(field, this.packages);
    }
}
