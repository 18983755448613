import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { UserListService } from '@services/core/user-list.service';

@Injectable({ providedIn: 'root' })
export class UserListResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private userListService: UserListService
    ) {}

    resolve(): Promise<any> {
        const usersPromise = this.userListService.getUsers();
        return this.loadingSpinnerService.spinnerifyPromise(usersPromise);
    }
}
