import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Item } from '../item';

@Component({
    selector: 'batch-select',
    templateUrl: './batch-select.html',
    styleUrls: ['./batch-select.scss'],
})
export class BatchSelect {
    private _items = [];
    @Input() set items(items: Item[]) {
        this._items = items;
        this.filterItems();
    }

    get items(): Item[] {
        return this._items;
    }

    @Input() date: string;
    @Output() updateDate: EventEmitter<String> = new EventEmitter();
    @Output() updatePick: EventEmitter<any> = new EventEmitter();

    //attributess
    pickedItem: any = { id: null };
    searchByNDC: string;
    filteredItems: any[];

    constructor() {}

    ngOnInit() {}

    // filter by the ndc entered in the searchbox
    filterItems() {
        this.filteredItems = this.items;

        if (!!this.searchByNDC && !!this.items) {
            let noDashNDCRegEx = new RegExp(this.searchByNDC.replace(/-/g, ''), 'mi');
            let nameRegEx = new RegExp(this.searchByNDC, 'mi');

            this.filteredItems = this.items.filter((item) => {
                return item.formattedNDC.match(noDashNDCRegEx) || item.item_name.match(nameRegEx);
            });
        }
    }

    pick(item) {
        this.pickedItem = _.cloneDeep(item);
        this.updatePick.emit({ newPick: this.pickedItem });
    }

    setDate(object: Date): void {
        const newDate = this.formatDate(object);
        if (this.date !== newDate && !!newDate) {
            this.updateDate.emit(newDate);
        }
    }

    private formatDate(date) {
        let newDate = moment(date);

        if (!newDate.isValid()) {
            return null;
        }
        return newDate.format('YYYY-MM-DD');
    }
}
