import { Message } from '@models/core/message';
import { RecallNotification, ActiveRecallNotification } from '@models/core/recall';

export const buildRecallMessageText = (
    itemList: RecallNotification[],
    recallMessage: string,
    newNetworkMessage: string
): Message => {
    let recallShortList = buildRecallShortList(itemList);
    let listDiff = itemList.length - 2;
    let listDiffStr = '';

    if (listDiff > 0) {
        listDiffStr = `, and ${listDiff} more.`;
    }

    let recallIds = itemList.map((item) => item.formulary_item.formulary_item_id);

    let publishedAtDates = itemList.map((item) => item.shared_recall.created_at);
    let earliestDate = publishedAtDates.reduce(function (pre, cur) {
        return Date.parse(pre) > Date.parse(cur) ? cur : pre;
    });

    let result: Message = {
        formulary_item_ids: recallIds,
        chip_content: newNetworkMessage,
        header: 'Network Recall Alert',
        body: `<p>${recallMessage}</br><div class="message-med">${recallShortList.join(', ')}${listDiffStr}</div></p>`,
        can_dismiss: true,
        recall_message: true,
        published_at: earliestDate,
        message_type: { name: 'block' },
    };
    return result;
};

export const buildActiveRecallMessageText = (
    item: ActiveRecallNotification,
    recallMessage: string,
    newMessage: string,
    moreText: string = ''
): Message => {
    let recallList = buildRecallList([item]);
    let recallIds = [item.formulary_item_id];

    let result: Message = {
        formulary_item_ids: recallIds,
        recall_id: item.id,
        chip_content: newMessage,
        header: newMessage,
        body: `<p><div class="message-recall-item">${recallList.join(
            '<br>'
        )}</div></p><p><div class="message-med">${recallMessage}</div></p>`,
        can_dismiss: true,
        active_recall_message: true,
        published_at: item.updated_at,
        message_type: { name: 'block' },
        icon: 'warning',
        more_text: moreText,
    };
    return result;
};

export const buildRecallList = (itemList: ActiveRecallNotification[]): string[] => {
    let result = [];
    itemList.forEach((item) => {
        let itemName = item.item_name || '';
        let itemStrength = item.item_strength?.scalar_formatted || '';
        let itemStrengthUom = item.item_strength?.uom || '';
        let packageSizeFormatted = item.package_size?.scalar_formatted || '';
        let packageSizeUom = item.package_size?.uom || '';
        let packageDescriptionName = item.package_description?.toLowerCase() || '';

        let packageDetails = `${packageSizeFormatted} ${packageSizeUom} `.trimLeft();
        let packageStr = `${packageDetails}${packageDescriptionName}`.trim();

        if (packageStr.length > 0) {
            packageStr = `(${packageStr})`;
        }

        let resultString =
            `${itemName}, ${itemStrength} ${itemStrengthUom} ${packageStr}, Lot(s) ${item.lot_numbers.join(
                ','
            )}`.trim();

        result.push(resultString);
    });

    return result;
};

export const buildRecallShortList = (itemList: RecallNotification[]): string[] => {
    let result = [];

    let shortList = itemList.slice(0, 2);

    shortList.forEach((item) => {
        let itemName = item.formulary_item.item_name || '';
        let itemStrength = item.formulary_item.item_strength_formatted || '';
        let itemStrengthUom = item.formulary_item.item_strength_uom || '';
        let packageSizeFormatted = item.formulary_item.package_size_formatted || '';
        let packageSizeUom = item.formulary_item.package_size_uom || '';
        let packageDescriptionName = item.formulary_item.package_description_name?.toLowerCase() || '';

        let packageDetails = `${packageSizeFormatted} ${packageSizeUom} `.trimLeft();
        let packageStr = `${packageDetails}${packageDescriptionName}`.trim();

        if (packageStr.length > 0) {
            packageStr = `(${packageStr})`;
        }

        let shortResult = `${itemName} ${itemStrength} ${itemStrengthUom} ${packageStr}`.trim();

        result.push(shortResult);
    });

    return result;
};
