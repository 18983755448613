import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    scanned_in_last_interval: string; // 24h, 2d, 3d, etc.
}

@Component({
    selector: 'timeframe-filter',
    templateUrl: './timeframe-filter.html',
    providers: [{ provide: 'IFilter', useExisting: TimeframeFilter }],
})
export class TimeframeFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeTimeframe = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    reworkDates: any = [
        {
            titleKey: 'twenty_four_h',
            value: '24h',
        },
        {
            titleKey: 'two_d',
            value: '2d',
        },
        {
            titleKey: 'three_d',
            value: '3d',
        },
        {
            titleKey: 'four_d',
            value: '4d',
        },
        {
            titleKey: 'five_d',
            value: '5d',
        },
        {
            titleKey: 'six_d',
            value: '6d',
        },
        {
            titleKey: 'one_w',
            value: '1w',
        },
        {
            titleKey: 'two_w',
            value: '2w',
        },
        {
            titleKey: 'three_w',
            value: '3w',
        },
        {
            titleKey: 'four_w',
            value: '4w',
        },
    ];

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.scanned_in_last_interval;
        }

        if (!!this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterTimeframe();
        } else {
            this.reset();
        }
    }

    filterTimeframe(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeTimeframe.emit(this.filterData);
        this.incomplete.emit({ filter: 'timeframe', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = '24h';
        this.changeTimeframe.emit(this.filterData);
    }

    formatForApi(): ApiData[] {
        return [{ scanned_in_last_interval: this.filterData }];
    }
}
