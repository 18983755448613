import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';
import { DynamicScriptLoaderService, LoadStatus } from '@services/system/dynamic-script-loader.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { GroupLoginService } from '@services/login/group-login.service';

@Injectable()
export class ChurnZeroService {
    constructor(
        private configuration: ConfigurationProvider,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private groupLoginService: GroupLoginService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    load() {
        const configVars = this.configuration.getConfiguration();
        const name = 'churn-zero';
        const src = 'https://kitcheck.us1app.churnzero.net/churnzero.js';

        this.dynamicScriptLoaderService.loadScript(name, src).then((res: LoadStatus) => {
            if (!!res.loaded && res.status === 'Loaded') {
                ChurnZero.push(['setAppKey', configVars.churn_zero_app_key]);
            }
        });
    }

    onLogin() {
        try {
            let hospitalId = this.hospitalInfoService.getHospitalId();
            let user = this.groupLoginService.getUser();
            // if (!user.email.includes('@bluesight.com')) {
            ChurnZero.push(['setContact', hospitalId, user.email]);
            // }
        } catch {
            // ignore failures from ChurnZero library
        }
    }
}
