import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { BinResource } from '@resources/bin-resource.service';

@Injectable({ providedIn: 'root' })
export class BinsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private binResource: BinResource
    ) {}

    resolve(): Promise<any> {
        let binsPromise;
        if (this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Load bins list data')) {
            binsPromise = this.binResource.binsIndex().then((data) => {
                return data.bins;
            });
        } else {
            binsPromise = Promise.resolve([]);
        }
        return this.loadingSpinnerService.spinnerifyPromise(binsPromise);
    }
}
