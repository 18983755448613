import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'packageLongDescription',
})
export class PackageLongDescription implements PipeTransform {
    transform(pkg) {
        return (
            stringToAdd(pkg.package_size_formatted) +
            stringToAdd(pkg.package_size_uom) +
            stringToAdd(pkg.package_description_name)
        );
    }
}

function stringToAdd(str) {
    if (str === '0' || str === null || str === undefined || str === 0) {
        return '';
    } else {
        return `${str} `;
    }
}
