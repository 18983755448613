import { Component, Input, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'supplies-pharm-sig',
    templateUrl: './supplies-pharm-sig.html',
})

// weird casing here because that's how it's set in the data.
export class Suppliespharmsig implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }
}
