import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';
import { isEmpty } from '@utils/objects';

interface ApiData {
    activities: string; // | joined string of activities
}

interface Activities {
    activity: string;
}

@Component({
    selector: 'activities-filter',
    templateUrl: './activities-filter.html',
    providers: [{ provide: 'IFilter', useExisting: ActivitiesFilter }],
})
export class ActivitiesFilter implements IFilter {
    @Input() activitiesList: Activities[];
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: Array<string> | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeActivities = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscription report if required and empty

    activities: Activities[];
    componentData;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportResource: ReportResource
    ) {}

    ngOnInit() {
        if (!!this.activitiesList) {
            //for reportSusbcription modal, activities list may be passed in
            this.activities = this.activitiesList;
        } else {
            this.getKitActivityList();
        }
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = this.apiSubscriptionData.activities.split('|');
        }

        if (this.reportSubscription) {
            //for reportSubscription modal, we want the selected reasons to persist from report page
            this.filterActivities(this.filterData);
        }
    }

    filterActivities(event): void {
        this.filterData = event;
        this.apiFormattedData.emit(this.formatForApi());
        this.changeActivities.emit(this.filterData);
        this.incomplete.emit({ filter: 'activities', incomplete: isEmpty(this.filterData) });
    }

    reset(): void {
        this.filterData = this.activities
            .filter((activity) => activity.activity !== 'scan')
            .map((reason) => reason.activity);
        this.changeActivities.emit(this.filterData);
    }

    getKitActivityList(): void {
        const doReset = !!!this.reportSubscription;
        this.loadingSpinnerService.spinnerifyPromise(
            this.reportResource.kitActivityList().then((data) => {
                this.activities = data.kit_activities;
                if (doReset) {
                    this.reset();
                }
            })
        );
    }

    formatForApi(): ApiData[] {
        return [{ activities: this.filterData.join('|') }];
    }
}
