import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { RecallResource } from '@resources/recall-resource.service';

@Component({
    selector: 'expired-and-recalled',
    templateUrl: './expired-and-recalled.html',
    styleUrls: ['./expired-and-recalled.scss'],
})
export class ExpiredAndRecalled {
    expiredBins;
    recalledBins;
    expiredKits;
    recalledKits;
    expiredKitMasterIds;
    shelvedInventoryEnabled: boolean;
    loading: boolean = true;
    recalls;
    allowAddRecallFromDashboard: boolean;

    constructor(
        private actionService: ActionService,
        private dashboardResource: DashboardResource,
        private $state: StateService,
        private hospitalInfoService: HospitalInfoService,
        protected recallResource: RecallResource
    ) {}

    ngOnInit() {
        this.shelvedInventoryEnabled = this.hospitalInfoService.allowShelvedInventory();
        this.getCounts();
        this.getRecalls();
        this.setupPermissions();
    }

    setupPermissions() {
        this.allowAddRecallFromDashboard = this.actionService.isAllowAction(
            'hospital_settings',
            'view_hospital_settings',
            'Add recall from Dashboard'
        );
    }

    getCounts() {
        this.dashboardResource.getCounts().then((data) => {
            this.loading = false;
            this.recalledKits = data.counts.kits_with_recalled_count;
            this.recalledBins = data.counts.bins_with_recalled_count;
            this.expiredKits = data.counts.kits_with_expired_count;
            this.expiredBins = data.counts.bins_with_expired_count;
            this.expiredKitMasterIds = data.data.kit_master_ids_with_expired;
        });
    }

    getRecalls() {
        this.recallResource.recallsList().then((data: any) => {
            this.recalls = data.recalls;
        });
    }

    goToExpirationsReport(binKit: string) {
        // in the case of 'bin' an empty array of kit master ids is all that is needed
        let kitMasterIds;
        if (binKit === 'kit') {
            kitMasterIds = this.expiredKitMasterIds.map((id) => {
                return { id: id };
            });
        } else {
            kitMasterIds = [];
        }
        this.$state.go('report-item-expiration-and-attributes-custom-item', {
            hideSpinner: true,
            prefilter: {
                expiration: {
                    daysExpiringIn: 1,
                    includeExpired: true,
                },
                genericNdcLotSearch: {
                    generic: {},
                    lot: null,
                    ndc: null,
                    type: 'generic',
                },
                binKitMasters: {
                    binKit: binKit,
                    kitMasters: kitMasterIds,
                },
            },
        });
    }

    goToRecallsReport(binKit: string) {
        this.$state.go('report-item-recalls', {
            hideSpinner: true,
            prefilter: {
                binKit: binKit,
                recalls: this.recalls,
            },
        });
    }
}
