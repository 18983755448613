import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@services/utils/translation.service';
import * as moment from 'moment';

@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {
    /**
     * Formats time or date strings
     *
     * @remarks
     * date | momentFormat:'formats.medium_date'
     * will convert the date to the format specified the supplied key
     * key definitions are located in i18n/*.yml
     *
     * @param value - Moment Date/Time object
     * @param args - format key from i18n/*.yml
     *
     * @returns formatted string
     *
     */
    constructor(private translationService: TranslationService) {}

    transform(value: Date | moment.Moment, ...args: any[]): string {
        let format = this.translationService.instant(args[0]);
        return moment(value).format(format);
    }
}
