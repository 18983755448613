import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class DecommissionResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getDecommissionReasons(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}decommission_reasons`).toPromise();
    }

    decommissionPackages(packageIds: number[], reasonId: number): Promise<any> {
        const packagesIdsString = packageIds.join('|');

        return this.http
            .delete<any>(
                `${this.API_ENDPOINT}items/decommission?package_ids=${packagesIdsString}&decommission_reason_id=${reasonId}`
            )
            .toPromise();
    }
}
