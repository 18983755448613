import { Component, Input } from '@angular/core';

@Component({
    selector: 'report-no-data',
    templateUrl: './report-no-data.html',
})
export class ReportNoData {
    @Input() hasData: boolean = false;
    @Input() hasRun: boolean = false;
    @Input() isLoading: boolean = false;
}
