import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class CatalogResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getHospitalCatalogs(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}catalogs`).toPromise();
    }
}
