import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

import { AdminRecall, AdminNetworkRecall } from '@models/admin/admin-recall';

@Injectable()
export class RecallResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    recallsList(get_counts = false): Promise<any> {
        if (get_counts) {
            let params = {
                get_counts: 'true',
            };
            return this.http.get<any>(`${this.API_ENDPOINT}recalls`, { params: params }).toPromise();
        } else {
            return this.http.get<any>(`${this.API_ENDPOINT}recalls`).toPromise();
        }
    }

    groupedRecallsList() {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}recalls`).toPromise();
    }

    getRecall(recallId: number): Promise<AdminRecall> {
        return this.http.get<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}`).toPromise();
    }

    recallSearch(searchParams = {}) {
        return this.http.get<any>(`${this.API_ENDPOINT}recalls/search`, { params: searchParams }).toPromise();
    }

    createRecall(recallData): Promise<AdminRecall> {
        return this.http.post<AdminRecall>(`${this.API_ENDPOINT}recalls`, recallData).toPromise();
    }

    updateRecall(recallId: number, recallData): Promise<AdminRecall> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}`, recallData).toPromise();
    }

    deleteRecall(recallId) {
        return this.http.delete<any>(`${this.API_ENDPOINT}recalls/${recallId}`).toPromise();
    }

    recallsNetworkList() {
        return this.http.get<any>(`${this.API_ENDPOINT}recalls/shared`).toPromise();
    }

    sharedRecalls(formulary_item_id) {
        return this.http.get<any>(`${this.API_ENDPOINT}shared_recalls/formulary_item/${formulary_item_id}`).toPromise();
    }

    addRecalls(recallData) {
        return this.http.post<any>(`${this.API_ENDPOINT}recalls/multiple`, recallData).toPromise();
    }

    getRecallReasonIds() {
        return this.http.get<any>(`${this.API_ENDPOINT}recall_reasons`).toPromise();
    }

    getRecallIgnoredReasonIds() {
        return this.http.get<any>(`${this.API_ENDPOINT}recall_ignored_reasons`).toPromise();
    }

    ignoredRecalls(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}recalls/ignored`).toPromise();
    }

    ignoreRecall(recallId: number, recallData): Promise<AdminRecall> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}/ignore`, recallData).toPromise();
    }

    unignoreRecall(recallId: number): Promise<AdminRecall> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}/unignore`, {}).toPromise();
    }

    removeRecall(recallId: number): Promise<AdminRecall> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}/remove`, {}).toPromise();
    }

    unremoveRecall(recallId: number): Promise<AdminRecall> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}/unremove`, {}).toPromise();
    }

    recallMessages(): Promise<any> {
        return this.http.get<AdminRecall>(`${this.API_ENDPOINT}recalls/messages`).toPromise();
    }

    dismissRecallMessage(recallId: number): Promise<any> {
        return this.http.put<AdminRecall>(`${this.API_ENDPOINT}recalls/${recallId}/dismiss_message`, {}).toPromise();
    }
}
