import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'good-sam',
    templateUrl: './good-sam.html',
})

// casing on component name is intentional to match the old template name set on the hospital.
export class Goodsam implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
    }
}
