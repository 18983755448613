import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpAuthInterceptService } from './http-auth-intercept.service';
import { HttpBadgeAuthInterceptor } from './http-badge-auth-intercept.service';
import { HttpErrorResponseInterceptor } from './http-error-response-interceptor.service';

// Order here matters - requests are filtered in this order, responses in the reverse order
// So don't make them alphabetical
export const HttpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpBadgeAuthInterceptor, multi: true },
];
