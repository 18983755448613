import { Injectable } from '@angular/core';

interface CallbackMap {
    [key: number]: () => void;
}

interface CallbacksMap {
    [key: string]: CallbackMap;
}

enum LoginEventType {
    EVENT_HOSPITAL = 'hospital',
    EVENT_LOGIN = 'login',
}

/**
 * Manages listeners on login events
 */
@Injectable({
    providedIn: 'root',
})
export class NgxLoginEventService {
    $config: any;
    private static ID: number = 0;
    // Creates a unique callback id for deregistering
    protected static nextId(): number {
        return ++NgxLoginEventService.ID;
    }
    public static EVENT_HOSPITAL: string = 'hospital';
    public static EVENT_LOGIN: string = 'login';
    private listeners: CallbacksMap = {
        hospital: {},
        login: {},
    };

    constructor() {
        this.$config = window['config'];
    }

    private deregisterListener(type: LoginEventType, id: number): void {
        const type_str = type as any as string;
        if (this.listeners.hasOwnProperty(type_str) && this.listeners[type_str].hasOwnProperty(id)) {
            delete this.listeners[type_str][id];
        }
    }

    public deregisterOnHospitalSelectListener(id: number): void {
        this.deregisterListener(LoginEventType.EVENT_HOSPITAL, id);
    }

    private registerListener(type: LoginEventType, listener: () => void): number {
        const type_str = type as any as string;
        if (this.listeners.hasOwnProperty(type_str)) {
            const id = NgxLoginEventService.nextId();
            this.listeners[type_str][id] = listener;
            return id;
        }

        return null;
    }

    public registerOnHospitalSelectListener(listener: () => void): number {
        return this.registerListener(LoginEventType.EVENT_HOSPITAL, listener);
    }

    private trigger(type: LoginEventType): void {
        const type_str = type as any as string;
        if (this.listeners.hasOwnProperty(type_str)) {
            for (const id in this.listeners[type_str]) {
                this.listeners[type_str][id]();
            }
        }
    }

    public triggerHospitalSelect(): void {
        this.trigger(LoginEventType.EVENT_HOSPITAL);
    }

    public triggerLogin(): void {
        this.trigger(LoginEventType.EVENT_LOGIN);
    }
}
