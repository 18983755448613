import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { GroupRolesResource } from '@resources/group-roles-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { isEmpty } from '@utils/objects';

interface HospitalNode {
    group_id: number;
    group_name: string;
    children?: HospitalNode[];
    selected: boolean;
    partial_selected: boolean;
}

@Component({
    selector: 'kc-hospital-group-select',
    templateUrl: './kc-hospital-group-select.html',
    styleUrls: ['./kc-hospital-group-select.scss'],
})

// this could probably be re-written to be a generic tree selector that you pass in a bunch of settings to
// right now though, we only need it for selecting hospital groups.
export class KCHospitalGroupSelect {
    @Input() selectedHospitalGroupIds: string; // a pipe-delimited list of selected ids;
    @Input() selectAll: boolean = false; //set to true to start with all selected
    @Output() emitSelectedHospitalGroups = new EventEmitter(); // returns a pipe-delimited list of selected Ids

    treeControl = new NestedTreeControl<HospitalNode>((node) => node.children);
    hospitalGroups = new MatTreeNestedDataSource<HospitalNode>();

    displayTree: boolean = false;
    selectedText: string = '';
    selectedItems: any[] = [];
    selectedCount: number = 0; // only count the leaf nodes

    // handle click outside to close
    private wasInside = false;

    @HostListener('click', ['$event'])
    clickInside($event) {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.displayTree = false;
        }
        this.wasInside = false;
    }

    constructor(
        protected groupsRolesResource: GroupRolesResource,
        protected translationService: TranslationService
    ) {}

    dropdownSettings: any = {
        text: this.translationService.instant('report_filters.select_hospital_groups'),
        allSelectedText: this.translationService.instant('report_filters.all_selected'),
        nSelectedText: this.translationService.instant('report_filters.hospital_groups_selected'),
        selectionShowLimit: 2,
    };

    ngOnInit() {
        this.hospitalGroups.data = [];
        this.getHospitalGroups();
        this.updateDisplay();
    }

    getHospitalGroups(): void {
        this.groupsRolesResource.getGroupRoles().then((data) => {
            this.hospitalGroups.data = [data.group_roles];

            if (this.selectedHospitalGroupIds) {
                const selectedIds = this.selectedHospitalGroupIds.split('|');

                this.hospitalGroups.data.forEach((item) => {
                    this.setSelected(item, selectedIds);
                });
            } else {
                if (this.selectAll) {
                    this.hospitalGroups.data.forEach((item) => {
                        this.selectionToggle(item, true);
                    });
                    this.emitSelectedHospitalGroups.emit(this.getGroupIds());
                }
            }
        });
    }

    /** recursively set the selected items if we have them **/
    setSelected(node, selectedIds) {
        node.selected = selectedIds.includes(node.group_id.toString());
        this.updateSelected(node);
        node.children.forEach((child) => {
            this.setSelected(child, selectedIds);
        });
    }

    /** Whether all the descendants of the node are selected */
    descendantsAllSelected(node: HospitalNode): boolean {
        node.selected = node.children.every((child) => child.selected);
        return node.selected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: HospitalNode): boolean {
        node.partial_selected =
            node.children.some((child) => child.selected) || node.children.some((child) => child.partial_selected);
        return node.partial_selected && !this.descendantsAllSelected(node);
    }

    updateGroups(node: HospitalNode): void {
        this.selectionToggle(node, !node.selected);
        this.emitSelectedHospitalGroups.emit(this.getGroupIds());
    }

    /** Toggle the item selection, and recurse through the children */
    selectionToggle(node: HospitalNode, selected): void {
        node.selected = selected;
        this.updateSelected(node);

        if (node.children.length) {
            node.children.forEach((child) => {
                this.selectionToggle(child, selected);
            });
        }
    }

    updateSelected(node): void {
        if (node.selected) {
            // only push node to selected list if it isn't already in there.
            const selected_node = this.selectedItems.find((item) => item.group_id === node.group_id);
            if (!selected_node) {
                this.selectedItems.push({
                    group_name: node.group_name,
                    group_id: node.group_id,
                    hasChildren: node.children.length > 0,
                });
            }
        } else {
            this.selectedItems = this.selectedItems.filter((item) => {
                return item.group_id !== node.group_id;
            });
        }

        // the count we display should only include the leaf nodes
        this.selectedCount = this.selectedItems.filter((item) => !item.hasChildren).length;
        this.updateDisplay();
    }

    updateDisplay(): void {
        if (this.selectedCount === 0) {
            this.selectedText = this.dropdownSettings.text;
            return;
        }

        if (this.selectedCount < this.dropdownSettings.selectionShowLimit) {
            this.selectedText = this.selectedItems.map((item) => item.group_name).join(', ');
            return;
        }

        this.selectedText = `${this.selectedCount} ${this.dropdownSettings.nSelectedText}`;
    }

    // this prevents the toggles from submitting the form and closing the dialog.
    doNotSubmit(): boolean {
        return false;
    }

    hasChild(index: number, node: HospitalNode): boolean {
        return !isEmpty(node.children);
    }

    getGroupIds(): any {
        return this.selectedItems
            .map((item) => item.group_id)
            .filter((n) => n)
            .join('|');
    }

    reset(): void {
        this.selectionToggle(this.hospitalGroups.data[0], false);
        this.emitSelectedHospitalGroups.emit('');
    }
}
