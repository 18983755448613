import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupLoginService } from '@services/login/group-login.service';
import { Scanner } from '@models/hardware/scanner';

@Component({
    selector: 'scanner-update-dialog',
    templateUrl: './scanner-update-dialog.html',
})
export class ScannerUpdateDialog {
    scanners: Scanner[];
    selectedScanner: Scanner;
    isAdmin: boolean;

    constructor(
        private groupLoginService: GroupLoginService,
        public dialogRef: MatDialogRef<ScannerUpdateDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            scanners: Scanner[];
        }
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.scanners = this.data.scanners;
        if (this.scanners.length === 1) {
            this.selectedScanner = this.scanners[0];
        }
        this.isAdmin = this.groupLoginService.getUser().isAdminUser;
    }
}
