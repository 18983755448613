import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { MatDialog } from '@angular/material/dialog';

import { ConfigurationProvider } from '@services/config/configuration';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { isValidNumber } from '@utils/objects';
import { GroupLoginResource } from '@resources/group-login-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';

import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

import { ChargeModelType, ChargeModelTypeName } from '@models/core/hospital-settings';
import { Subscription } from 'rxjs';

@Component({
    selector: 'admin-hospital',
    templateUrl: './admin-hospital.html',
    styleUrls: ['./admin-hospital.scss'],
})
export class AdminHospital {
    //bindings
    @Input() hospital: any;
    @ViewChild('fileInput', null) fileInput: ElementRef;

    oldData: any;
    attempted: boolean;
    maxDays = 4000;
    saving: boolean;
    chargeModelTypes: Array<ChargeModelType> = [];
    chargeModelReadOnly: string = '';
    isAdmin: boolean = false;
    readonly minLockoutDate = new Date();
    maxLockoutDate: string | Date;
    private readonly plansEnabledToLockout = [ChargeModelTypeName.opt_out, ChargeModelTypeName.all_you_need];
    chargeModelTypesIdHash: { [id: number]: ChargeModelTypeName };

    fileSizeError: boolean = false;
    fileTypeError: boolean = false;
    fileUploaderItem: FileItem;
    hasFile: boolean;
    hasNewFile: boolean = false;
    newFileName: string;
    pngUploader = new FileUploader({});
    uploading: boolean = false;
    url: string;
    useNewChargeSheetCustomization: Subscription;

    private readonly MAX_PNG_FILE_SIZE = 5242881;

    constructor(
        protected configurationProvider: ConfigurationProvider,
        private dialog: MatDialog,
        private featureFlagService: FeatureFlagService,
        private groupLoginService: GroupLoginService,
        private hospitalInfoService: HospitalInfoService,
        private hospitalResource: HospitalResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private groupLoginResource: GroupLoginResource,
        private localStorageService: LocalStorageService,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService
    ) {}

    get formattedMaxLockoutDate() {
        if (this.maxLockoutDate) {
            return moment(this.maxLockoutDate).format('MM/DD/YYYY');
        }

        return '';
    }

    get shouldShowLockoutDate() {
        // Prevents UI error rendering while we wait for the request to complete
        if (!this.chargeModelTypesIdHash) {
            return;
        }

        return this.plansEnabledToLockout.includes(this.chargeModelTypesIdHash[this.hospital.charge_model_type_id]);
    }

    ngOnInit() {
        this.oldData = _.cloneDeep(this.hospital);

        this.isAdmin = this.groupLoginService.getUser().isAdminUser;
        this.hospitalResource.getChargeModelTypes().then((results) => {
            this.chargeModelTypes = results['charge_model_types'];
            this.chargeModelTypesIdHash = this.chargeModelTypes.reduce(
                (acc, { name, id }) => ({ ...acc, [id]: name }),
                {}
            );
            this.chargeModelReadOnly = `admin.account.${
                this.chargeModelTypes[this.hospital.charge_model_type_id - 1 || 0]['name']
            }`;
        });

        this.hasFile = this.hospital.logo_filename;

        this.featureFlagService.getFeatureValue('use-new-charge-sheet-customization').subscribe((flagValue) => {
            this.useNewChargeSheetCustomization = flagValue;
            this.setupUploader();
        });

        this.setupMaxLockoutDate();
    }

    setupMaxLockoutDate() {
        if (this.oldData.subscription_items_lockout_date) {
            this.maxLockoutDate = moment(this.oldData.subscription_items_lockout_date).add(365, 'days').toISOString();

            return;
        }

        this.maxLockoutDate = moment(new Date()).add(365, 'days').toISOString();
    }

    setupUploader() {
        if (!this.useNewChargeSheetCustomization) {
            return;
        }
        this.url = `${this.configurationProvider.kcEndpointV1()}hospitals/${this.hospital.id}`;
        this.pngUploader = new FileUploader({
            url: this.url,
            itemAlias: 'logo',
            authToken: this.groupLoginService.authToken(),
            allowedFileType: ['image'],
            maxFileSize: this.MAX_PNG_FILE_SIZE,
        });

        this.pngUploader.onAfterAddingFile = async (item: FileItem) => {
            if (this.hasFile) {
                this.confirmReplacementPNG(item);
            } else {
                this.confirmPNG(item);
            }
        };

        this.pngUploader.onWhenAddingFileFailed = async (item) => {
            this.fileTypeError = item?.type !== 'image/png';
            this.fileSizeError = item?.size > this.MAX_PNG_FILE_SIZE;
        };

        this.pngUploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
            this.uploading = false;
        };

        this.pngUploader.onErrorItem = (item: FileItem, response: any, status: any, headers: any) => {
            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, JSON.parse(response)['message']);
            this.uploading = false;
        };
    }

    setupHospitalData(data) {
        this.hospital = data.hospital;
        this.oldData = _.cloneDeep(this.hospital);
        this.hospital.id = this.hospitalInfoService.getHospitalId();
    }

    update(): Promise<any> {
        this.saving = true;
        const data: any = {};

        if (this.hospital.kitsummary_delta_on_right !== this.oldData.kitsummary_delta_on_right) {
            data.kitsummary_delta_on_right = this.hospital.kitsummary_delta_on_right;
            this.oldData.kitsummary_delta_on_right = this.hospital.kitsummary_delta_on_right;
        }

        if (this.hospital.expiring_soon_days !== this.oldData.expiring_soon_days) {
            data.expiring_soon_days = this.hospital.expiring_soon_days;
            this.oldData.expiring_soon_days = this.hospital.expiring_soon_days;
            data.expiring_soon_days += '';
        }

        if (this.hospital.print_item_fridge_date_custom_msg !== this.oldData.print_item_fridge_date_custom_msg) {
            data.print_item_fridge_date_custom_msg = this.hospital.print_item_fridge_date_custom_msg;
            this.oldData.print_item_fridge_date_custom_msg = this.hospital.print_item_fridge_date_custom_msg;
        }

        if (this.hospital.use_generic_item_name !== this.oldData.use_generic_item_name) {
            data.use_generic_item_name = this.hospital.use_generic_item_name;
        }

        if (this.hospital.charge_model_type_id !== this.oldData.charge_model_type_id) {
            data.charge_model_type_id = this.hospital.charge_model_type_id;
        }

        if (this.hospital.subscription_items_lockout_date !== this.oldData.subscription_items_lockout_date) {
            data.subscription_items_lockout_date = this.hospital.subscription_items_lockout_date;
        }

        if (this.hospital.low_credit_threshold !== this.oldData.low_credit_threshold) {
            data.low_credit_threshold = this.hospital.low_credit_threshold;
        }

        data.id = this.hospital.id;

        this.kcMatSnackBarService.clearAll();

        const updatePromise = this.hospitalResource
            .updateHospital(this.hospitalInfoService.getHospitalId(), data)
            .then(() => {
                this.updateLocalHospitalSettings(this.hospitalInfoService.getHospitalId());
                this.uploadFile();
                return this.hospitalResource.hospitalData(this.hospitalInfoService.getHospitalId());
            })
            .then((data) => {
                this.setupHospitalData(data);
                this.setupMaxLockoutDate();
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'hospital_settings.updated_message',
                });
            })
            .finally(() => {
                this.saving = false;
            });
        return updatePromise;
    }

    async updateLocalHospitalSettings(hospitalId: number) {
        const hospitalData = await this.groupLoginResource.sessionHospital(hospitalId);

        this.localStorageService.set('hospitalSettings', hospitalData.user.hospital_settings);
    }

    isValidDays(value) {
        return isValidNumber(value) && this.hospital.expiring_soon_days <= this.maxDays;
    }

    isValidLockoutDate() {
        const lockoutDate = moment(this.hospital.subscription_items_lockout_date).toDate();

        if (!this.hospital.subscription_items_lockout_date) {
            return true;
        }

        return (
            lockoutDate > moment(new Date()).subtract(1, 'days').toDate() &&
            lockoutDate < moment(new Date()).add(365, 'days').toDate()
        );
    }

    shouldDisableUpdateBasedOnLockoutCombination() {
        // Prevents UI error rendering while we wait for the request to complete
        if (!this.chargeModelTypesIdHash) {
            return;
        }

        // If the lockout date hasn't been changed, then let the user update any other setting on the page
        if (this.hospital.subscription_items_lockout_date === this.oldData.subscription_items_lockout_date) {
            return false;
        }

        const selectedPaymentPlan = this.chargeModelTypesIdHash[this.hospital.charge_model_type_id];
        const isCurrentPlanEnabledForLockout = this.plansEnabledToLockout.includes(selectedPaymentPlan);

        if (!this.isValidLockoutDate() && !isCurrentPlanEnabledForLockout) {
            return false;
        }

        if (!this.isValidLockoutDate() && isCurrentPlanEnabledForLockout) {
            return true;
        }

        return false;
    }

    confirmPNG(item: FileItem) {
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('admin.hospital.confirm_new_logo'),
                description: this.translationService.instant('admin.hospital.logo_verification_warning', {
                    hospital_name: this.hospital.name,
                }),
                okButton: this.translationService.instant('buttons.approve'),
                cancelButton: this.translationService.instant('buttons.disapprove'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.setFile(item);
            } else {
                this.clearUpload(item);
            }
        });
    }

    confirmReplacementPNG(item: FileItem) {
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('admin.hospital.confirm_new_logo'),
                description: this.translationService.instant('admin.hospital.logo_exists_warning', {
                    hospital_name: this.hospital.name,
                }),
                okButton: this.translationService.instant('buttons.approve'),
                cancelButton: this.translationService.instant('buttons.disapprove'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.confirmPNG(item);
            } else {
                this.clearUpload(item);
            }
        });
    }

    setFile(item: FileItem) {
        this.fileTypeError = item.file?.type !== 'image/png';
        this.fileSizeError = item.file?.size > this.MAX_PNG_FILE_SIZE;
        item.method = 'PUT';
        item.withCredentials = false;
        this.hasNewFile = true;
        this.newFileName = item.file.name;
        this.fileUploaderItem = item;
        // have to clear out the input due to an issue with the control not being able to select the same file again, even if it's changed
        this.fileInput.nativeElement.value = '';
    }

    clearUpload(item: FileItem) {
        this.pngUploader.cancelAll();
        this.pngUploader.clearQueue();
        // have to clear out the input due to an issue with the control not being able to select the same file again
        this.fileInput.nativeElement.value = '';
    }

    uploadFile() {
        this.uploading = true;
        if (this.hasNewFile) {
            this.fileUploaderItem.upload();
        }
    }

    submitForm() {
        this.attempted = true;
        this.update();
    }
}
