import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { BinResource } from '@resources/bin-resource.service';
import { BinTagPackageResource } from '@resources/bin-tag-package-resource.service';
import { PrintResource } from '@resources/print-resource.service';
import { TranslationService } from '@services/utils/translation.service';

@Injectable()
export class PrintService {
    constructor(
        private binResource: BinResource,
        private binTagPackageResource: BinTagPackageResource,
        private printResource: PrintResource,
        private translationService: TranslationService
    ) {}

    activePrintJob = false;

    zplError(data) {
        return Promise.reject(_.extend(data, { reason: 'zpl' }));
    }

    startPrintJob() {
        this.activePrintJob = true;
        const alreadyPrinting = this.translationService.instant('tagging.already_printing');
        window.onbeforeunload = () => alreadyPrinting;
    }

    markPrintJobComplete(promise) {
        promise.finally(() => {
            this.activePrintJob = false;
            window.onbeforeunload = null;
        });
        return promise;
    }

    getItemTagZpl(tag) {
        const data: any = {
            formulary_item_id: tag.item.formulary_item_id,
            quantity: tag.quantity,
            printer_hardware_id: tag.printer.id,
        };

        if (tag.hospitalSettings && tag.item.expiring_fridge_days) {
            data.calculate_fridge_date = tag.calculate_fridge_date;
        }

        if (!tag.expireDisabled) {
            data.expiration = tag.expire_formatted;
        }
        if (!tag.lotDisabled) {
            data.lot_num = tag.lot;
        }

        if (tag.onBehalfOfHospitalId) {
            data.on_behalf_of_hospital_id = tag.onBehalfOfHospitalId;
        }

        if (!!tag.batch) {
            data.tag_batch_value = tag.batch;
        }

        if (!tag.compoundDisabled) {
            data.compound = tag.compound_formatted;
        }

        if (tag.addToBin && !isNaN(parseInt(tag.addToBin))) {
            data.bin_id = parseInt(tag.addToBin);
        }

        return this.printResource.itemZpl(data);
    }

    getKitTagZpl(kitData) {
        const data = {
            kit_master_id: kitData.master.id.toString(),
            physical_labels: _.map(kitData.kits, 'id').join('|'),
            printer_hardware_id: kitData.printer.id,
        };

        return this.printResource.kitZpl(data);
    }

    getBinTagZpl(binData) {
        return this.binResource.printBinTag(binData);
    }

    printing() {
        return this.activePrintJob;
    }

    printItemTags(tag) {
        this.startPrintJob();

        const afterGetZpl = (data) => {
            const epcs = data.items.map((item) => item.epc);
            const zpl = data.items.map((item) => item.zpl_string).join('');
            const tagIds = data.items.map((item) => item.tag_id);

            return this.printResource
                .printTag(tag.printer, zpl, tag.quantity, epcs)
                .then(() => {
                    return Promise.resolve();
                })
                .catch((err) => {
                    if (tag.addToBin && !isNaN(parseInt(tag.addToBin))) {
                        this.binTagPackageResource.removeBinTagPackage(tag.addToBin, tagIds.join('|'));
                    }
                    return Promise.reject(err);
                });
        };

        return this.markPrintJobComplete(this.getItemTagZpl(tag).catch(this.zplError).then(afterGetZpl));
    }

    printKitTag(kit) {
        this.startPrintJob();

        const afterGetZpl = (data) => {
            const epcs = data.kits.map((kit) => kit.epc);
            const zpl = data.kits.map((kit) => kit.zpl).join('');
            return this.printResource.printTag(kit.printer, zpl, data.kits.length, epcs);
        };
        return this.markPrintJobComplete(this.getKitTagZpl(kit).catch(this.zplError).then(afterGetZpl));
    }

    printBinTag(binData) {
        const { printer } = binData;
        const bin = _.omit(binData, 'printer');
        this.startPrintJob();

        const afterGetZpl = ({ zpl, epc }) => {
            return this.printResource.printTag(printer, zpl, 1, [epc]);
        };
        const promise = this.getBinTagZpl(bin).catch(this.zplError).then(afterGetZpl);
        return this.markPrintJobComplete(promise);
    }

    overrideActivePrint() {
        this.activePrintJob = false;
    }
}
