import { Component, OnInit, Input } from '@angular/core';
import { TagAssociationBatchesResource } from '@resources/tag-association-batches-resource.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { ActionService as ActionService } from '@services/utils/action.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';
import { ItemResource } from '@resources/item-resource.service';
import { Item } from './item';
import * as moment from 'moment';

@Component({
    selector: 'admin-batch-lots',
    templateUrl: './admin-batch-lots.html',
    styleUrls: ['./admin-batch-lots.scss'],
})
export class AdminBatchLots {
    //attributes
    form: {
        step: number;
    };
    newLot: string;
    confirmNewLot: string;
    items: Item[];
    baseItems: Item[];
    date: any;
    defaultDate: any;
    selectedItem: Item;
    showLot: boolean;
    warningAccept: boolean;
    warningDetailed: boolean;
    newExpiration: any;
    hasExpirationUpdateAction: boolean;

    constructor(
        private actionService: ActionService,
        private itemResource: ItemResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private tagAssociationBatchesResource: TagAssociationBatchesResource,
        private translationService: TranslationService
    ) {
        this.setupPermissions();
    }

    ngOnInit() {
        this.reset();
    }

    protected setupPermissions() {
        this.hasExpirationUpdateAction = this.actionService.isAllowAction(
            'kits_inventory',
            'update_item_expirations',
            'Update Expirations'
        );
    }

    back() {
        this.form.step = 1;
        this.sharedReset();
        this.items = this.formatItems(this.baseItems);
        if (this.date !== this.defaultDate) {
            this.date = this.defaultDate;
            this.items = this.formatItems(this.baseItems);
        }
    }

    reset() {
        this.sharedReset();
        this.form = {
            step: 1,
        };
        this.defaultDate = new Date();
        this.defaultDate.setDate(this.defaultDate.getDate() - 7);
        this.defaultDate = this.formatDate(this.defaultDate);
        this.date = this.defaultDate;
        this.loadBatchList(this.defaultDate).then((data: any[]) => {
            setTimeout(() => {
                this.items = this.formatItems(data);
                this.baseItems = this.formatItems(data);
            });
        });
    }

    private sharedReset() {
        this.selectedItem = null;
        this.newLot = '';
        this.confirmNewLot = '';
        this.showLot = true;
        this.warningAccept = false;
        this.warningDetailed = false;
        this.newExpiration = null;
    }

    loadBatchList(date) {
        let loadData = this.tagAssociationBatchesResource.indexTagAssociationBatches(date);

        const loadPromise = loadData.then((response: any) => {
            return response.tag_association_batches;
        });
        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    pressContinue() {
        this.form.step = 2;
    }

    setDate(object) {
        this.defaultDate = this.formatDate(object.model);
    }

    setSelectedItem(item) {
        this.selectedItem = item.newPick;
        this.warningDetailed = this.selectedItem?.detailed_tag;
    }

    rebuildBatchIndex(newDate: string): void {
        if (newDate !== this.date) {
            if (newDate === this.defaultDate) {
                this.date = this.defaultDate;
                this.items = this.formatItems(this.baseItems);
            } else {
                this.date = newDate;
                this.loadBatchList(this.date).then((data: any[]) => {
                    setTimeout(() => {
                        this.items = this.formatItems(data);
                    });
                });
            }
        }
    }

    // normalize the ndc and name for searching
    formatItems(items: Item[]) {
        items.forEach((item) => {
            if (!!item.ndc) {
                item.formattedNDC = item.ndc.replace(/-/g, '');
            } else {
                item.formattedNDC = '';
            }

            if (!!!item.item_name) {
                item.item_name = '';
            }
        });

        return items;
    }

    updateNewLot(newLot) {
        this.newLot = newLot;
        this.warningAccept = false;
    }

    resetConfirmNewLot() {
        this.confirmNewLot = '';
        this.warningAccept = false;
        this.newExpiration = null;
    }

    obscureNewLot() {
        this.showLot = false;
    }

    revealPassword() {
        this.showLot = true;
    }

    updateDisabled() {
        return !(this.showLot === false && this.newLot === this.confirmNewLot && this.newLot.trim() !== '');
    }

    noSelectionMade() {
        return !!(this.selectedItem === null);
    }

    updateOnlyLot() {
        this.newLot = this.newLot.trim();
        let loadData = this.tagAssociationBatchesResource.updateTagAssociationBatches(
            this.selectedItem.id,
            this.newLot,
            true
        );
        const loadPromise = loadData
            .then((response: any) => {
                this.setSuccessMessage();
                this.reset();
                return response;
            })
            .catch(() => {
                this.warningAccept = true;
                const data = {
                    ndc: this.selectedItem.ndc,
                    lot_num: this.newLot,
                };

                this.itemResource.lookupItem(data).then((data: any) => {
                    if (!!data) {
                        this.newExpiration = data.item_expirations[0];
                    } else {
                        this.newExpiration = null;
                    }
                });
            });
        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    updateLotAndExp() {
        this.newLot = this.newLot.trim();
        let expiration = this.newExpiration ? this.formatDate(this.newExpiration.expiration_formatted) : null;
        const newDateData = {
            ndc: this.selectedItem.ndc,
            lot_num: this.selectedItem.lot_number,
            expiration: expiration,
        };

        const updateNewDate = this.itemResource.replaceItemDate(newDateData);

        const updateNewLot = () =>
            this.tagAssociationBatchesResource.updateTagAssociationBatches(this.selectedItem.id, this.newLot, false);

        const loadPromise = updateNewDate.then(updateNewLot).then((response: any) => {
            this.setSuccessMessage();
            this.reset();
            return response;
        });
        return this.loadingSpinnerService.spinnerifyPromise(loadPromise, LoadingSpinnerTypes.PANEL);
    }

    private formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    private setSuccessMessage() {
        const translationValues = {
            packageCount: this.selectedItem.package_count,
            packageText: this.translationService
                .inflect('common.package', this.selectedItem.package_count)
                .toLowerCase(),
            itemName: this.selectedItem.item_name,
            newLot: this.newLot,
        };

        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
            key: 'admin.update_lots.success',
            params: translationValues,
        });
    }
}
