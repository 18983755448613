import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TagTypesResource } from '@resources/tag-types-resource.service';

@Injectable({ providedIn: 'root' })
export class TagTypesResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private tagTypesResource: TagTypesResource
    ) {}

    resolve(): Promise<any> {
        let tagTypesPromise;
        if (this.actionService.isAllowAction('hospital_settings', 'view_hardware', 'Load tag type list')) {
            tagTypesPromise = this.tagTypesResource.tagTypeList().then((data) => {
                return data.tag_types;
            });
        } else {
            tagTypesPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(tagTypesPromise);
    }
}
