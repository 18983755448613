import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScannerUpdatingService {
    //attributes
    isUpdating: boolean;
    updatePercentage: number;
    updateMessage: string;

    constructor() {}

    updateProgress(percentage, message) {
        this.updatePercentage = Math.ceil(percentage * 100);
        this.updateMessage = message;

        console.log(`Updating: ${this.updatePercentage}%: ${this.updateMessage}`);
    }
}
