/***
 * Barrel file for template imports
 * ***/

/* Common Partials */
import { ChargeSheetTemplateAllSegments } from './charge-sheet-template-all-segments/charge-sheet-template-all-segments';
import { ChargeSheetTemplateHeader } from './charge-sheet-template-header/charge-sheet-template-header';
import { ChargeSheetTemplateFooter } from './charge-sheet-template-footer/charge-sheet-template-footer';
import { ChargeSheetTemplateLocks } from './charge-sheet-template-locks/charge-sheet-template-locks';
import { ChargeSheetTemplateManualLocks } from './charge-sheet-template-manual-locks/charge-sheet-template-manual-locks';
import { ChargeSheetTemplateSegmentSummary } from './charge-sheet-template-segment-summary/charge-sheet-template-segment-summary';
import { ChargeSheetTemplateWarning } from './charge-sheet-template-warning/charge-sheet-template-warning';
import { ChargeSheetTemplateWrongItems } from './charge-sheet-template-wrong-items/charge-sheet-template-wrong-items';
import { EarliestExpiration } from './segments-templates/earliest-expiration/earliest-expiration';
import { ItemCount } from './segments-templates/item-count/item-count';
import { SegmentCol } from './segments-templates/segment-col/segment-col';

/* Main Templates */

/* Template bases */
import { Base } from './templates/base/base';
import { BaseLandscape } from './templates/base-landscape/base-landscape';
import { BaseLabel } from './templates/base-label/base-label';

/* Common Templates (custom but not hospital specific ) */
import { Compact } from './templates/common/compact/compact';
import { CompactLabel } from './templates/common/compact-label/compact-label';
import { CompactLgFont } from './templates/common/compact-lg-font/compact-lg-font';
import { CompactNdc } from './templates/common/compact-ndc/compact-ndc';
import { LockTechDate } from './templates/common/lock-tech-date/lock-tech-date';
import { Supplies } from './templates/common/supplies/supplies';
import { SupplyLgFont } from './templates/common/supply-lg-font/supply-lg-font';
import { Suppliespharmsig } from './templates/common/supplies-pharm-sig/supplies-pharm-sig';
import { PharmDateBot } from './templates/common/pharm-date-bot/pharm-date-bot';
import { PharmVerified } from './templates/common/pharm-verified/pharm-verified';
import { TwoLockLines } from './templates/common/two-lock-lines/two-lock-lines';
import { TwoLocksSig } from './templates/common/two-locks-sig/two-locks-sig';

/* Custom for a hospital */
import { AdventistPortland } from './templates/custom/adventist-portland/adventist-portland';
import { Arkchild } from './templates/custom/ark-child/ark-child';
import { Aultman } from './templates/custom/aultman/aultman';
import { Benefis } from './templates/custom/benefis/benefis';
import { Bernardine } from './templates/custom/bernardine/bernardine';
import { BonSec } from './templates/custom/bon-sec/bon-sec';
import { Bridgeport } from './templates/custom/bridgeport/bridgeport';
import { Bronson } from './templates/custom/bronson/bronson';
import { Bronxlebanon } from './templates/custom/bronx-lebanon/bronx-lebanon';
import { Castle } from './templates/custom/castle/castle';
import { CentralBaptist } from './templates/custom/central-baptist/central-baptist';
import { CincyChildrens } from './templates/custom/cincy-childrens/cincy-childrens';
import { CitrusIc } from './templates/custom/citrus-ic/citrus-ic';
import { CityOfHope } from './templates/custom/city-of-hope/city-of-hope';
import { CoralGables } from './templates/custom/coral-gables/coral-gables';
import { Corona } from './templates/custom/corona/corona';
import { DchsStvincent } from './templates/custom/dchs-stvincent/dchs-stvincent';
import { DellChildrens } from './templates/custom/dell-childrens/dell-childrens';
import { Denton } from './templates/custom/denton/denton';
import { DesertRegional } from './templates/custom/desert-regional/desert-regional';
import { Edward } from './templates/custom/edward/edward';
import { Emerson } from './templates/custom/emerson/emerson';
import { Goodsam } from './templates/custom/good-sam/good-sam';
import { GreatRiver } from './templates/custom/great-river/great-river';
import { JacksonMemorialHospital } from './templates/custom/jackson-memorial-hospital/jackson-memorial-hospital';
import { Kingwood } from './templates/custom/kingwood/kingwood';
import { Lynwood } from './templates/custom/lynwood/lynwood';
import { Measecountryside } from './templates/custom/mease-countryside/mease-countryside';
import { MemorialHospitalWest } from './templates/custom/memorial-hospital-west/memorial-hospital-west';
import { MercyAneMajor } from './templates/custom/mercy-springfield/mercy-ane-major/mercy-ane-major';
import { MercyAneSingle } from './templates/custom/mercy-springfield/mercy-ane-single/mercy-ane-single';
import { MercyAneSingleKetamine } from './templates/custom/mercy-springfield/mercy-ane-single-ketamine/mercy-ane-single-ketamine';
import { Mercybakersfield } from './templates/custom/mercy-bakersfield/mercy-bakersfield';
import { MercyDefault } from './templates/custom/mercy-springfield/mercy-default/mercy-default';
import { MercyEctBrevital } from './templates/custom/mercy-springfield/mercy-ect-brevital/mercy-ect-brevital';
import { MercyErasKit } from './templates/custom/mercy-springfield/mercy-eras-kit/mercy-eras-kit';
import { MercyKetamine } from './templates/custom/mercy-springfield/mercy-ketamine/mercy-ketamine';
import { MercyLaborAndDeliverySingleNarc } from './templates/custom/mercy-springfield/mercy-labor-and-delivery-single-narc/mercy-labor-and-delivery-single-narc';
import { MercyNarcoticIntubation } from './templates/custom/mercy-springfield/mercy-narcotic-intubation/mercy-narcotic-intubation';
import { Mercyspringfield } from './templates/custom/mercy-springfield/mercy-springfield';
import { Mercystlouis } from './templates/custom/mercy-stlouis/mercy-stlouis';
import { Mercytiffin } from './templates/custom/mercy-tiffin/mercy-tiffin';
import { MissionHospital } from './templates/custom/mission-hospital/mission-hospital';
import { Mrmc } from './templates/custom/mrmc/mrmc';
import { Musc } from './templates/custom/musc/musc';
import { NorthShore } from './templates/custom/north-shore/north-shore';
import { Nymethodist } from './templates/custom/ny-methodist/ny-methodist';
import { NyuLutheran } from './templates/custom/nyu-lutheran/nyu-lutheran';
import { OhGrant } from './templates/custom/oh-grant/oh-grant';
import { Orangecoast } from './templates/custom/orange-coast/orange-coast';
import { Palmdale } from './templates/custom/palmdale/palmdale';
import { Piedmont } from './templates/custom/piedmont/piedmont';
import { PiedmontNewnan } from './templates/custom/piedmont-newnan/piedmont-newnan';
import { SaintAgnes } from './templates/custom/saint-agnes/saint-agnes';
import { SanLeandro } from './templates/custom/san-leandro/san-leandro';
import { SantaRosa } from './templates/custom/santa-rosa/santa-rosa';
import { ScrippsCv } from './templates/custom/scripps-cv/scripps-cv';
import { ScrippsGreen } from './templates/custom/scripps-green/scripps-green';
import { ScrippsMhsd } from './templates/custom/scripps-mhsd/scripps-mhsd';
import { Sentaranova } from './templates/custom/sentara-nova/sentara-nova';
import { SinaiBeth } from './templates/custom/sinai-beth/sinai-beth';
import { SouthMiami } from './templates/custom/south-miami/south-miami';
import { Southcoast } from './templates/custom/southcoast/southcoast';
import { Stbarnabas } from './templates/custom/stbarnabas/stbarnabas';
import { Stmarys } from './templates/custom/stmarys/stmarys';
import { Stmarysmedical } from './templates/custom/stmarys-medical/stmarys-medical';
import { Stpatrick } from './templates/custom/stpatrick/stpatrick';
import { Suburban } from './templates/custom/suburban/suburban';
import { Suttereden } from './templates/custom/sutter-eden/sutter-eden';
import { Suttertracy } from './templates/custom/sutter-tracy/sutter-tracy';
import { Temecula } from './templates/custom/temecula/temecula';
import { TexasChildrensMain } from './templates/custom/texas-childrens-main/texas-childrens-main';
import { Ucla } from './templates/custom/ucla/ucla';
import { UclaReagan } from './templates/custom/ucla-reagan/ucla-reagan';
import { UhsSanAntonio } from './templates/custom/uhs-san-antonio/uhs-san-antonio';
import { Ummc } from './templates/custom/ummc/ummc';
import { UpmcAltoona } from './templates/custom/upmc-altoona/upmc-altoona';
import { Vcu } from './templates/custom/vcu/vcu';
import { Wayne } from './templates/custom/wayne/wayne';
import { WheatonFranciscanWaterloo } from './templates/custom/wheaton-franciscan-waterloo/wheaton-franciscan-waterloo';
import { WilkesCompact } from './templates/custom/wilkes-compact/wilkes-compact';
import { Wyoming } from './templates/custom/wyoming/wyoming';
import { Yale } from './templates/custom/yale/yale';

/* IMPORTANT:
If you are adding a new charge sheet type you will also need to update the list in xcheck in the
edit-hospital-settings.ts file so that the charge sheet can be selected for the hospital!
*/

// TODO: CF - we could clean up the lint and remove some code by using the exact strings as keys here
/* eslint-disable */
export const ChargeSheetMappings = {
    AdventistPortland: AdventistPortland,
    Arkchild: Arkchild,
    Aultman: Aultman,
    Base: Base,
    BaseLabel: BaseLabel,
    BaseLandscape: BaseLandscape,
    Benefis: Benefis,
    Bernardine: Bernardine,
    BonSec: BonSec,
    Bridgeport: Bridgeport,
    Bronson: Bronson,
    Bronxlebanon: Bronxlebanon,
    Castle: Castle,
    CentralBaptist: CentralBaptist,
    CincyChildrens: CincyChildrens,
    CitrusIc: CitrusIc,
    CityOfHope: CityOfHope,
    Compact: Compact,
    CompactLabel: CompactLabel,
    CompactLgFont: CompactLgFont,
    CompactNdc: CompactNdc,
    CoralGables: CoralGables,
    Corona: Corona,
    DchsStvincent: DchsStvincent,
    DellChildrens: DellChildrens,
    Denton: Denton,
    DesertRegional: DesertRegional,
    Edward: Edward,
    Emerson: Emerson,
    Goodsam: Goodsam,
    GreatRiver: GreatRiver,
    JacksonMemorialHospital: JacksonMemorialHospital,
    Kingwood: Kingwood,
    LockTechDate: LockTechDate,
    Lynwood: Lynwood,
    Measecountryside: Measecountryside,
    MemorialHospitalWest: MemorialHospitalWest,
    MercyAneMajor: MercyAneMajor,
    MercyAneSingle: MercyAneSingle,
    MercyAneSingleKetamine: MercyAneSingleKetamine,
    Mercybakersfield: Mercybakersfield,
    MercyDefault: MercyDefault,
    MercyEctBrevital: MercyEctBrevital,
    MercyErasKit: MercyErasKit,
    MercyKetamine: MercyKetamine,
    MercyLaborAndDeliverySingleNarc: MercyLaborAndDeliverySingleNarc,
    MercyNarcoticIntubation: MercyNarcoticIntubation,
    Mercyspringfield: Mercyspringfield,
    Mercystlouis: Mercystlouis,
    Mercytiffin: Mercytiffin,
    MissionHospital: MissionHospital,
    Mrmc: Mrmc,
    Musc: Musc,
    NorthShore: NorthShore,
    Nymethodist: Nymethodist,
    NyuLutheran: NyuLutheran,
    OhGrant: OhGrant,
    Orangecoast: Orangecoast,
    Palmdale: Palmdale,
    PharmDateBot: PharmDateBot,
    PharmVerified: PharmVerified,
    Piedmont: Piedmont,
    PiedmontNewnan: PiedmontNewnan,
    SaintAgnes: SaintAgnes,
    SanLeandro: SanLeandro,
    SantaRosa: SantaRosa,
    ScrippsCv: ScrippsCv,
    ScrippsGreen: ScrippsGreen,
    ScrippsMhsd: ScrippsMhsd,
    Sentaranova: Sentaranova,
    SinaiBeth: SinaiBeth,
    SouthMiami: SouthMiami,
    Southcoast: Southcoast,
    Stbarnabas: Stbarnabas,
    Stmarys: Stmarys,
    Stmarysmedical: Stmarysmedical,
    Stpatrick: Stpatrick,
    Suburban: Suburban,
    Supplies: Supplies,
    Suppliespharmsig: Suppliespharmsig,
    SupplyLgFont: SupplyLgFont,
    Suttereden: Suttereden,
    Suttertracy: Suttertracy,
    Temecula: Temecula,
    TexasChildrensMain: TexasChildrensMain,
    TwoLockLines: TwoLockLines,
    TwoLocksSig: TwoLocksSig,
    Ucla: Ucla,
    UclaReagan: UclaReagan,
    UhsSanAntonio: UhsSanAntonio,
    Ummc: Ummc,
    UpmcAltoona: UpmcAltoona,
    Vcu: Vcu,
    Wayne: Wayne,
    WheatonFranciscanWaterloo: WheatonFranciscanWaterloo,
    WilkesCompact: WilkesCompact,
    Wyoming: Wyoming,
    Yale: Yale,
};
/* eslint-enable */

export const ChargeSheetTemplates = [
    AdventistPortland,
    Arkchild,
    Aultman,
    Base,
    BaseLabel,
    BaseLandscape,
    Benefis,
    Bernardine,
    BonSec,
    Bridgeport,
    Bronson,
    Bronxlebanon,
    Castle,
    CentralBaptist,
    CincyChildrens,
    CitrusIc,
    CityOfHope,
    Compact,
    CompactLabel,
    CompactLgFont,
    CompactNdc,
    CoralGables,
    Corona,
    DchsStvincent,
    DellChildrens,
    Denton,
    DesertRegional,
    Edward,
    Emerson,
    Goodsam,
    GreatRiver,
    JacksonMemorialHospital,
    Kingwood,
    LockTechDate,
    Lynwood,
    Measecountryside,
    MemorialHospitalWest,
    MercyAneMajor,
    MercyAneSingle,
    MercyAneSingleKetamine,
    Mercybakersfield,
    MercyDefault,
    MercyEctBrevital,
    MercyErasKit,
    MercyKetamine,
    MercyLaborAndDeliverySingleNarc,
    MercyNarcoticIntubation,
    Mercyspringfield,
    Mercystlouis,
    Mercytiffin,
    MissionHospital,
    Mrmc,
    Musc,
    NorthShore,
    Nymethodist,
    NyuLutheran,
    OhGrant,
    Orangecoast,
    Palmdale,
    PharmDateBot,
    PharmVerified,
    Piedmont,
    PiedmontNewnan,
    SaintAgnes,
    SanLeandro,
    SantaRosa,
    ScrippsCv,
    ScrippsGreen,
    ScrippsMhsd,
    Sentaranova,
    SinaiBeth,
    SouthMiami,
    Southcoast,
    Stbarnabas,
    Stmarys,
    Stmarysmedical,
    Stpatrick,
    Suburban,
    Supplies,
    Suppliespharmsig,
    SupplyLgFont,
    Suttereden,
    Suttertracy,
    Temecula,
    TexasChildrensMain,
    TwoLockLines,
    TwoLocksSig,
    Ucla,
    UclaReagan,
    UhsSanAntonio,
    Ummc,
    UpmcAltoona,
    Vcu,
    Wayne,
    WheatonFranciscanWaterloo,
    WilkesCompact,
    Wyoming,
    Yale,
];

export const ChargeSheetTemplatePartials = [
    ChargeSheetTemplateAllSegments,
    ChargeSheetTemplateHeader,
    ChargeSheetTemplateFooter,
    ChargeSheetTemplateLocks,
    ChargeSheetTemplateManualLocks,
    ChargeSheetTemplateSegmentSummary,
    ChargeSheetTemplateWarning,
    ChargeSheetTemplateWrongItems,
    EarliestExpiration,
    ItemCount,
    SegmentCol,
];
