import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';

@Component({
    selector: 'ceq-search-typeahead',
    templateUrl: './ceq-search-typeahead.html',
    styleUrls: ['./ceq-search-typeahead.scss'],
})
export class CeqSearchTypeahead {
    @Input() item: any;
    @Input() tag: any;
    @Input() inputFormType: string;
    @Input() isEditable: any;
    @Input() disabled: boolean;
    @Input() report: any;
    @Input() grouped: boolean;
    @Input() design: string;
    @Output() select: EventEmitter<any> = new EventEmitter();

    ceqSearch: Function;
    inGenericItems: boolean;
    items: any[];
    itemType: string;
    searchByName: string;
    searchByNameFocus: boolean = false;
    searching: boolean = false;
    tokenizedFormularyName: string;
    keys: string[];
    total: number;

    MAT_DESIGN = 'matDesign';

    constructor(private clinicalEquivalenceResource: ClinicalEquivalenceResource) {}

    ngOnInit() {
        this.ceqSearch = _.debounce(this.sendSearchToAPI, 300);
        this.tokenizedFormularyName = this.getGenericItemNameStrength();
        this.itemType = this.decideObjType();
    }

    private decideObjType(): string {
        if (this.tag) {
            return 'tag.item';
        } else {
            return 'item';
        }
    }

    private setDefaultObj(item): void {
        if (item === undefined) {
            item = {};
        }
    }

    private categorize(data) {
        if (data.clinical_equivalences) {
            //group by name
            let result = data.clinical_equivalences.reduce(function (r, a) {
                r[a.item_name] = r[a.item_name] || [];
                r[a.item_name].push(a);
                return r;
            }, Object.create(null));

            //sorting is broken up within each drugName into parts, ascending sorting within each unit of measure, then drugs with no uom at end
            for (let drugName in result) {
                result[drugName].forEach((ceq) => {
                    ceq.item_strength_scalar_number = ceq.item_strength_scalar
                        ? parseFloat(ceq.item_strength_scalar)
                        : null;
                });
                result[drugName] = _.sortBy(result[drugName], ['item_strength_uom', 'item_strength_scalar_number']);
            }
            this.keys = Object.keys(result);
            this.total = (<any>Object).values(result).flat().length;
            this.items = result;
        } else {
            this.items = Object.create(null);
            this.keys = [];
        }
    }

    private sendSearchToAPI(name): void {
        if (name && name.length > 4) {
            this.searching = true;
            const encodedName = encodeURIComponent(name).replace(/\./g, '%2E');

            this.clinicalEquivalenceResource
                .clinicalEquivalencesSearch(encodedName)
                .then((data) => {
                    if (this.grouped) {
                        this.categorize(data);
                    } else {
                        if (data.clinical_equivalences) {
                            this.items = data.clinical_equivalences;
                        } else {
                            this.items = [];
                        }
                    }
                })
                .finally(() => {
                    this.searching = false;
                });

            this.searching = false;
        } else {
            if (this.grouped) {
                this.items = Object.create(null);
                this.keys = [];
            } else {
                this.items = [];
            }
        }
    }

    isGenericSelected(): boolean {
        return !!this.itemType && !!this[this.itemType].generic_selected;
    }

    searchFocused(): boolean {
        return this.searchByNameFocus;
    }

    isItemEditable(): boolean {
        return this.isEditable;
    }

    getGenericItemName(): string {
        if (!!this.itemType && !!this[this.itemType].generic_item_name) {
            return this[this.itemType].generic_item_name;
        }
    }

    getGenericItemNameStrength(): string {
        if (this.inputFormType === 'reportHeaderInput' && this.item.generic_item_name) {
            const name = this.item.generic_item_name || '';
            const itemStrengthInt = this.item.item_strength_formatted || '';
            const itemStrengthUOM = this.item.item_strength_uom || '';

            return `${name} ${itemStrengthInt} ${itemStrengthUOM}`;
        }
    }

    setEditForm(genericItem): void {
        this.setDefaultObj(this[this.itemType]);

        const item_strength_formatted = genericItem.item_strength_scalar;

        const genItem = {
            clinical_equivalence_id: genericItem.id,
            generic_item_name: genericItem.item_name,
            item_strength_formatted: item_strength_formatted,
            item_strength_uom: genericItem.item_strength_uom,
            generic_selected: true,
        };

        _.assign(this[this.itemType], genItem);
        this.tokenizedFormularyName = this.getGenericItemNameStrength();
        this.searchByName = '';
        this.items = [];
        this.select.emit(this.item);
    }

    clearGeneric(): void {
        const genItem = {
            clinical_equivalence_id: '',
            generic_item_name: '',
            item_strength_formatted: '',
            item_strength_uom: '',
            generic_selected: false,
        };

        _.assign(this[this.itemType], genItem);
        this.tokenizedFormularyName = '';
        if (this.design === this.MAT_DESIGN) {
            $('input#mat-generic-product').focus();
        } else {
            $('input#generic-product').focus();
        }
        this.inGenericItems = false;
        this.items = undefined;
        this.select.emit({});
    }

    isSearchByNameValid(searchByName, focusState): boolean {
        // Show message if clicking outside the results list
        if (searchByName && focusState !== true) {
            return false;
        } else {
            return true;
        }
    }
}
