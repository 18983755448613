import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, OnInit } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import * as moment from 'moment';
import { KCDatePicker } from '@components/common/kc-datepicker/kc-datepicker';
import { FormControl } from '@angular/forms';

export interface DateStringRange {
    start_date: string;
    end_date: string;
}

@Component({
    selector: 'date-month-filter-v2',
    templateUrl: './date-month-filter.html',
    styleUrls: ['./date-month-filter.scss'],
    providers: [{ provide: 'IFilter', useExisting: DateMonthFilterV2 }],
})
export class DateMonthFilterV2 implements IFilter, OnInit {
    @Input() customDates: DateStringRange;
    @Input() filterData: any;
    @Output() disableRunReport = new EventEmitter();
    @Output() changeDate = new EventEmitter();
    opened: boolean;
    set: boolean = false;

    @ViewChildren(KCDatePicker) datePickers: QueryList<KCDatePicker>;

    ngOnInit() {
        this.reset();
    }

    ngDoCheck() {
        //work-around for issue with DOM structure of 3rd party datepicker, may be resolved in future
        if (!this.set && this.opened && document.querySelector('.calendar-below-input')) {
            document.querySelector('.calendar-below-input').parentElement.parentElement.style.marginBottom = '-380px';
            this.set = true;
        }
        if (this.opened && document.querySelector('.margin')) {
            document.querySelector('.margin').parentElement.parentElement.style.marginTop = '-10px';
            if (
                document.querySelector('.mat-calendar-period-button') &&
                document.querySelector('.mat-calendar-arrow')
            ) {
                (<HTMLInputElement>document.querySelector('.mat-calendar-period-button')).disabled = true;
                (<HTMLInputElement>document.querySelector('.mat-calendar-arrow')).style.display = 'none';
            }
        }
    }

    setStyle(open) {
        if (open) {
            this.opened = true;
        } else {
            this.opened = false;
        }
    }

    reset(): void {
        this.filterData = {};
        let start = moment().subtract('months', 2);
        let end = moment().subtract('months', 1);

        if (!!this.customDates) {
            start = moment(this.customDates.start_date);
            end = moment(this.customDates.end_date);
        }
        this.filterData.start_date = new FormControl(moment(start));
        this.filterData.end_date = new FormControl(moment(end));
        this.changeDate.emit(this.filterData);
    }

    filterStartDate = (result): void => {
        if (!result) {
            return;
        }
        this.changeDate.emit(this.filterData);
    };

    filterEndDate = (result): void => {
        if (!result) {
            return;
        }
        this.changeDate.emit(this.filterData);
    };

    isValid(): boolean {
        return this.filterData.start_date.valid && this.filterData.end_date.valid;
    }
}
