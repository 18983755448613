import { Component, Input } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';

@Component({
    selector: '[barcode-img]',
    templateUrl: './barcode-img.html',
})
export class BarcodeImgComponent {
    @Input() ngType;
    @Input() code: string;

    type: string;
    barcode: string;
    endpoint: string;

    constructor(private configuration: ConfigurationProvider) {}

    ngOnInit(): void {
        this.link();
    }

    link(): void {
        this.endpoint = this.configuration.kcEndpointV1();
        this.type = this.ngType || 'code128';
        this.barcode = encodeURI(this.code);
    }
}
