import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class ItemResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    itemVerification(epc, data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}items/${epc}/verifications`, data).toPromise();
    }

    itemManageView(epc: string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}views/kit_check/items/${epc}/manage`).toPromise();
    }

    itemDataV2(itemId: number): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}items/${itemId}`).toPromise();
    }

    lookupItem(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}items/expirations`, { params: data }).toPromise();
    }

    lookupExpirationsHistory(data): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}items/expirations/history`, { params: data }).toPromise();
    }

    replaceItemDate(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}items/expirations`, data).toPromise();
    }

    replaceItemFridgeDateForBatch(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}items/expirations/fridge_expirations`, data).toPromise();
    }

    updateItem(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}items/${data.id}`, data).toPromise();
    }

    getScanHistory(tag_id: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}tags/${tag_id}/scans`).toPromise();
    }
}
