import { Component, Input, ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { formatCurrency } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';
import * as _ from 'lodash';

import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportingResource } from '@services/resources/reporting-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { sortObjArray } from '@utils/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { Report, ReportCategory } from '@models/core/report';
import {
    DownloadFilters,
    ItemsData,
    KittedInventoryWithCost,
    KitMasterDetail,
} from './kitted-inventory-with-cost.model';

interface Filters {
    costType: string;
    drugs: ItemsData[];
    kitMasters: KittedInventoryWithCost['kit_masters'];
}

@Component({
    selector: 'kitted-inventory-with-cost',
    templateUrl: './report-kitted-inventory-with-cost.html',
    styleUrls: ['./report-kitted-inventory-with-cost.scss'],
})
export class ReportKittedInventoryWithCost {
    // bindings
    @Input() kittedInventoryWithCost: KittedInventoryWithCost;
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    // attributes
    kitMasters: KittedInventoryWithCost['kit_masters'];
    drugs: ItemsData[];
    currentKitMasters: KitMasterDetail[];
    report: Report;
    isDownloadable: boolean;
    downloadFilters: DownloadFilters;
    currentSegment: ItemsData;
    filters: Filters;
    segments: ItemsData[];
    scheduledReportsEnabled: boolean;

    filterOptionsKitMasters: KittedInventoryWithCost['kit_masters'];
    dropdownSettingsKitMasters = {
        singleSelection: false,
        text: 'Select Kit Masters',
        selectAllText: 'Select All Kit Masters',
        unSelectAllText: 'Clear All Kit Masters',
        enableSearchFilter: true,
        classes: 'kit-master-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Kit Masters Selected',
        objectTypeName: 'Kit Master',
    };

    chartHeight: number = 0;
    chartWidth: number = 900;
    paddingH: number = 190;
    paddingV: number = 30;
    barHeight = 15;
    top5Package: ItemsData[] = [];
    top5Kitted: ItemsData[] = [];
    totalKittedCost: number;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'name',
        'strength',
        'package',
        'ndc',
        'manufacturer',
        'qty_kitted',
        'unit_cost',
        'kitted_cost',
    ];
    @ViewChildren('kittedInventoryContainer', { read: ElementRef }) kittedInventoryContainer: QueryList<ElementRef>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        protected translationService: TranslationService,
        protected reportingResource: ReportingResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog
    ) {}

    private processData(kittedInventoryWithCostData: KittedInventoryWithCost) {
        this.kittedInventoryWithCost = kittedInventoryWithCostData;
        this.kitMasters = this.kittedInventoryWithCost.kit_masters;
        this.filterOptionsKitMasters = this.kitMasters.map((kitMaster) => kitMaster);
        this.filters.kitMasters = _.cloneDeep(this.kitMasters);
        this.updateDownloadFilters();
    }

    private updateDownloadFilters() {
        const kit_masters = this.filters.kitMasters.map((km) => km.id).join('|');

        this.downloadFilters = {
            price_type: this.filters.costType,
            kit_masters,
        };
    }

    ngOnInit(): void {
        this.kittedInventoryWithCost.items.forEach((item) => {
            item.id = item.ndc;
        });
        this.kitMasters = sortObjArray(this.kittedInventoryWithCost.kit_masters, 'name');
        this.filterOptionsKitMasters = this.kitMasters.map((kitMaster) => kitMaster);
        this.drugs = this.kittedInventoryWithCost.items;

        this.filters = {
            costType: 'wac',
            kitMasters: _.cloneDeep(this.kitMasters),
            drugs: _.cloneDeep(this.drugs),
        };

        const reportCategory = this.reports.report_categories.find((category) => category.name === 'analytics');
        this.report = reportCategory.reports.find((report) => report.name === 'kitted_inventory_with_cost');
        this.isDownloadable = this.report.is_downloadable;
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
    }

    ngAfterViewInit(): void {
        this.kittedInventoryContainer.changes.subscribe((next: QueryList<ElementRef>) => {
            if (!this.showSpinner()) {
                this.updateDownloadFilters();
                this.renderChart();
                this.refreshSegments();
            }
        });
    }

    refreshSegments(segments: ItemsData[] = this.kittedInventoryWithCost.items): void {
        this.segments = sortObjArray(segments, 'name');
        //calculates and creates qty_kitted key on each segment
        const kitMasterIds = this.filters.kitMasters.map((kitMaster) => kitMaster.id);
        this.segments.forEach((segment) => {
            segment.qty_kitted = _.reduce(
                segment.kit_master,
                (total: any, qty: any, kmId: string) => {
                    if (kitMasterIds.includes(parseInt(kmId))) {
                        total += qty;
                    }
                    return total;
                },
                0
            );
            segment.kitted_cost = segment.qty_kitted * segment[this.filters.costType];
            segment.unit_cost = segment[this.filters.costType];
        });
        const segmentsByKitted = sortObjArray(this.segments, 'kitted_cost').reverse();
        const segmentsByPackage = sortObjArray(this.segments, 'unit_cost').reverse();

        this.top5Kitted = segmentsByKitted.slice(0, 5);
        this.top5Package = segmentsByPackage.slice(0, 5);
        this.dataSource = new MatTableDataSourceWithNaturalSort(segments);
        this.dataSource.sort = this.sort;
    }

    refreshReport(): void {
        const reportPromise = this.reportingResource.kittedInventoryWithCost(this.filters).then((kittedInventory) => {
            this.processData(kittedInventory);
            this.renderChart();
        });
        this.loadingSpinnerService.spinnerifyPromise(reportPromise);
    }

    subscribeModal(): void {
        const subscribeFilters = {
            priceType: this.filters.costType,
            kitMasters: this.filters.kitMasters,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }

    onSelect(newPriceType): void {
        if (!!newPriceType) {
            this.filters.costType = newPriceType;
        }
        this.updateDownloadFilters();
        this.renderChart();
    }

    kitMasterFilter(filters: Filters) {
        return (segment) => {
            const segmentKitMasters = Object.keys(segment.kit_master);
            const kitMasterIds = filters.kitMasters.map((kitMaster) => kitMaster.id);
            return segmentKitMasters.some((id: string) => {
                return kitMasterIds.includes(parseInt(id));
            });
        };
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    selectSegment(segment: ItemsData): void {
        this.currentSegment = this.currentSegment === segment ? undefined : segment;
        this.renderChart();
    }

    // Chart Rendering
    renderRangeAxis(svg, data, rangeScale): void {
        this.chartHeight = this.barHeight + 20 + data.length + 10 + this.currentKitMasters.length;
        svg.selectAll('.chart')
            .data(data)
            .enter()
            .append('text')
            .classed('range-text', true)
            .attr('x', (_d, i) => rangeScale(i) - this.paddingH)
            .attr('y', () => this.chartHeight - this.paddingV + 20);
    }

    labelClass(label: string, dot: boolean = true): string {
        return `${dot ? '.' : ''}label-${_.kebabCase(label)}`;
    }

    renderChart(): void {
        const segments = this.currentSegment ? [this.currentSegment] : this.segments;
        this.refreshSegments();
        const segmentKitMasterIds = _(segments)
            .map((segment: any) => {
                return _.keys(segment.kit_master);
            })
            .flatten()
            .map((kitMasterId: string) => {
                return parseInt(kitMasterId);
            })
            .uniq()
            .value();

        this.currentKitMasters = _(this.filters.kitMasters)
            .cloneDeep()
            .filter((kitMaster: any) => {
                return _.includes(segmentKitMasterIds, kitMaster.id);
            });

        this.chartHeight = this.currentKitMasters.length / 20;

        //add items to corresponding kitmasters
        this.currentKitMasters.forEach((kitMaster) => {
            kitMaster.totals = [];
            kitMaster.labels = [];

            //maps segments to their parent kit master
            kitMaster.items = _.filter(segments, (segment: any) => {
                return _.includes(_.keys(segment.kit_master), String(kitMaster.id));
            });

            kitMaster.totalKittedQuantity = _.reduce(
                kitMaster.items,
                (total: number, segment: any) => {
                    return total + segment.kit_master[kitMaster.id];
                },
                0
            );

            kitMaster.totalKittedCost = _.reduce(
                kitMaster.items,
                (total: number, segment: any) => {
                    return total + segment.kit_master[kitMaster.id] * segment[this.filters.costType];
                },
                0
            );

            kitMaster.totals.push(kitMaster.totalKittedQuantity);
            kitMaster.totals.push(kitMaster.totalKittedCost);
        });

        const filteredSegments = _.filter(this.segments, this.kitMasterFilter(this.filters));

        this.totalKittedCost = _.reduce(
            filteredSegments,
            (total: number, segment: any) => {
                return total + segment.kitted_cost;
            },
            0
        );

        const kitted_quantity_totals: number[] = _.map(this.currentKitMasters, 'totalKittedQuantity') as number[];
        const kitted_cost_totals: number[] = _.map(this.currentKitMasters, 'totalKittedCost') as number[];
        let TKQ_Max: number = d3.max(kitted_quantity_totals);
        let TKC_Max: number = d3.max(kitted_cost_totals);

        //create data from currentKitMasters
        const data: any = _.reduce(
            this.currentKitMasters,
            (totals: any, km: any) => {
                const fullKm: any = _.find(this.kitMasters, { id: km.id });
                _.each(km.totals, (total, i) => {
                    totals.push({ total: total, label: fullKm.name, type: i });
                });
                //returns data back in alphabetical order
                return sortObjArray(totals, 'label');
            },
            []
        );

        //set chart height to corresponding
        this.chartHeight = 200 + 20 + data.length + 10 + this.currentKitMasters.length;

        // Create scales

        //Setup SVG
        const kiwcChart = d3.select('#kitted-with-inventory-cost-chart').style('margin-left', '-50px');
        const topAxis = d3.select('#top-axis').style('margin-left', '-50px');
        const bottomAxis = d3.select('#bottom-axis').style('margin-left', '-50px');

        const tickCount = 5;
        //x-axis rangeScale
        const rangeScale = d3
            .scaleLinear()
            .domain([0, Math.floor(TKQ_Max) + Math.floor(TKQ_Max) / (tickCount * 2)])
            .range([0, this.chartWidth - this.paddingH]);

        const rangeTopScale = d3
            .scaleLinear()
            .domain([0, Math.floor(TKC_Max) + Math.floor(TKC_Max) / (tickCount * 2)])
            .range([0, this.chartWidth - this.paddingH]);
        // empty out html
        kiwcChart.html('');
        topAxis.html('');
        bottomAxis.html('');

        const countTipTemplate = (c: any) => {
            const tkc = c.type === 0 ? c.total : formatCurrency(c.total, 'en', '$');
            const name = c.label;
            const type = c.type === 0 ? 'Total Kitted Quantity' : 'Total Kitted Cost';
            return `<div><ul><li>Kitmaster: ${name}<li><li>${type}: ${tkc}<li></ul></div>`;
        };

        const labels: any = _.uniq(_.map(data, 'label'));
        _.each(labels, (label: string) => {
            //appends div for each label
            kiwcChart
                .append('div')
                .attr('class', () => {
                    return this.labelClass(label, false);
                })
                .style('width', 'auto')
                .style('height', '50px')
                .style('display', 'block')
                .style('margin-left', '88px')
                .style('overflow', 'hidden') // keeps text from spilling out of container
                .append('div') //add div for text
                .style('margin-right', '10px')
                .style('width', '95px') //width for text div
                .style('height', '50px') //height for text div
                .style('float', 'left') //float text div left
                .append('p')
                .style('font-size', '12px')
                .html(label);
            //add div for bars
            d3.select(this.labelClass(label)).append('div').style('margin-right', '10px').classed('bars', true);

            const labelMatches: any = [];
            _.each(data, (datum: any) => {
                if (datum.label === label) {
                    const tip = d3tip().attr('class', 'd3-tip').html(countTipTemplate(datum)).offset([-8, 0]);

                    labelMatches.push(datum);
                    const datumSVG: any = d3
                        .select(`${this.labelClass(label)} .bars`)
                        .append('div')
                        .append('svg');

                    datumSVG.call(tip);
                    datumSVG
                        .attr('width', '630')
                        .attr('height', '20')
                        .append('g')
                        .append('rect')
                        .attr('fill', () => {
                            if (datum.type === 0) {
                                return '#044A82';
                            } else {
                                return '#52C7EA';
                            }
                        })
                        .attr('width', () => {
                            if (datum.type === 0) {
                                return rangeScale(datum.total);
                                return 30;
                            } else if (datum.type === 1) {
                                return rangeTopScale(datum.total);
                                return 30;
                            }
                        })
                        .attr('height', 10)
                        .on('mouseout', tip.hide)
                        .on('mouseover', tip.show);
                }
            });
        });

        const svgBottom: any = bottomAxis
            .append('div')
            .style('margin-left', '2px')
            .append('svg')
            .attr('width', this.chartWidth)
            .attr('height', 100);

        const svgTop: any = topAxis
            .append('div')
            .style('margin-left', '2px')
            .append('svg')
            .attr('width', this.chartWidth)
            .attr('height', 100);

        // x-axis
        const rangeAxis = d3.axisBottom(rangeScale).ticks(tickCount);

        const rangeTopAxis = d3
            .axisTop(rangeTopScale)
            .ticks(tickCount)
            .tickFormat(function (d) {
                return ' $' + d3.format(',')(d);
            });

        svgTop
            .append('g')
            .classed('range-axis', true)
            .attr('transform', `translate(${this.paddingH}, 40)`)
            .call(rangeAxis);

        svgBottom
            .append('g')
            .classed('range-axis', true)
            .attr('transform', `translate(${this.paddingH}, 55)`)
            .call(rangeTopAxis);
    }
}
