import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { CartResource } from '@resources/cart-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { KitResource } from '@resources/kit-resource.service';
import { TranslationService } from '@services/utils/translation.service';

// this service gives access to the assign kit to location/cart dispatch from multiple places
@Injectable()
export class AssignKitService {
    selectedKits: Array<any>;
    successMessage: string;

    constructor(
        private cartResource: CartResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private kitResource: KitResource,
        private translationService: TranslationService
    ) {}

    assignToCartOrLocation(data) {
        let assignType;
        let cartLocationName;
        let cartName;
        let carts = data.carts;
        let locationName;
        let locations = data.locations;
        let kitsCount;
        let override = data.override;
        let promises = [];
        let results = data.results;
        let selectedKits = data.selected;
        let translationKey;

        // the location was removed for the selected kits
        if (!!results.removeLocations) {
            selectedKits.forEach((kit) => {
                let removedLocationName = kit.location_name;

                delete kit.location;
                delete kit.location_id;
                delete kit.location_name;

                let promise = this.kitResource.kitDispatchUnset(kit.id, 'location').then(() => {
                    // if there is a cart, also remove from the cart.
                    if (!!kit.cart && !override) {
                        // update the kits list (we are not calling refreshKits here)
                        delete kit.cart_name;
                        delete kit.cart;

                        this.kitResource.kitDispatchUnset(kit.id, 'cart');
                    }
                });

                promises.push(promise);
                this.kcMatSnackBarService.open(
                    SnackBarTypes.SUCCESS,
                    `${kit.kit_master.name} ${kit.physical_label} removed from ${removedLocationName}`
                );
            });
        } else if (!!results.removeCarts) {
            selectedKits.forEach((kit) => {
                let removedCartName = kit.cart_name;
                delete kit.cart_name;
                delete kit.cart;

                let promise = this.kitResource.kitDispatchUnset(kit.id, 'cart').then(() => {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        `${kit.kit_master.name} ${kit.physical_label} removed from ${removedCartName}`
                    );
                });

                promises.push(promise);
            });
        } else if (results.location_id || results.cart_id || results.cart_location_id) {
            let promise = this.kitResource
                .kitDispatchBulk(results)
                .then(() => {
                    let selectedCart;
                    let selectedLocation;
                    let selectedCartLocation;

                    if (!!results.cart_id) {
                        selectedCart = _.find(carts, {
                            id: results.cart_id,
                        });
                        cartName = selectedCart?.name;
                    }

                    if (!!results.location_id) {
                        selectedLocation = locations.find((location) => location.id === results.location_id);
                        locationName = selectedLocation?.name;
                    }

                    if (!!results.cart_location_id) {
                        selectedCartLocation = locations.find((location) => location.id === results.cart_location_id);
                        cartLocationName = selectedCartLocation?.name;
                    }

                    kitsCount = selectedKits.length;
                    assignType = cartName && locationName ? 'cart_location' : cartName ? 'cart' : 'location';
                    translationKey = `inventory.assigned_success.${
                        kitsCount > 1 ? 'multiple' : 'single'
                    }.${assignType}`;

                    if (!!selectedCart?.aeroscout_asset_id) {
                        // not changing location if aeroscout cart
                        return;
                    } else if (!!results.cart_location_id) {
                        //if the cart location changed, dispatch the cart.
                        let dispatchPromise = this.cartResource.dispatch({
                            id: results.cart_id,
                            location_id: results.cart_location_id,
                        });

                        return dispatchPromise;
                    } else if (!!results.cart_id && !results.location_id) {
                        //when "Edit Location" is clicked, there's no cart. we only want this case for "Edit Cart", when there's no cart location selected
                        let removePromise = this.cartResource.removeLocation({
                            id: results.cart_id,
                            location_id: null,
                        });
                        return removePromise;
                    } else {
                        return;
                    }
                })
                .finally(() => {
                    if (!!translationKey) {
                        this.successMessage = this.translationService.instant(translationKey, {
                            kitsCount,
                            cartName,
                            locationName,
                        });
                    }
                    this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, this.successMessage);
                });
            promises.push(promise);
        }

        return promises;
    }
}
