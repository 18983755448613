import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '@services/config/configuration';
import { DynamicScriptLoaderService, LoadStatus } from '@services/system/dynamic-script-loader.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { getHighestHierarchyRole } from '@utils/user-roles';

@Injectable()
export class GainsightService {
    constructor(
        private config: ConfigurationProvider,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private groupLoginService: GroupLoginService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    load(): void {
        const configVars = this.config.getConfiguration();
        const name = 'gainsight';
        const src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${configVars.gainsight_px_key}`;

        const scriptCaller = 'aptrinsic';
        window[scriptCaller] =
            window[scriptCaller] ||
            function () {
                (window[scriptCaller].q = window[scriptCaller].q || []).push(arguments);
            };
        window[scriptCaller].p = configVars.gainsight_px_key;
        if (
            !this.dynamicScriptLoaderService.scripts[name] &&
            ['local', 'qa', 'integration', 'staging', 'production'].includes(configVars.environment)
        ) {
            this.dynamicScriptLoaderService.loadScript(name, src);
        }
    }

    onLogin(): void {
        let user = this.groupLoginService.getUser();
        let hospital = {
            id: this.hospitalInfoService.getHospitalId(),
            name: this.hospitalInfoService.getHospitalName(),
        };
        if (!!window['aptrinsic']) {
            window['aptrinsic'](
                'identify',
                {
                    id: `${user.id}`,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    signUpDate: user.user_created_at,
                    role: getHighestHierarchyRole(user.user_roles),
                },
                {
                    id: hospital.id,
                    name: hospital.name,
                }
            );
        }
    }

    trackCustomEvent(event): void {
        let user = this.groupLoginService.getUser();
        let hospital = {
            id: this.hospitalInfoService.getHospitalId(),
            name: this.hospitalInfoService.getHospitalName(),
        };
        if (!!window['aptrinsic']) {
            window['aptrinsic']('track', event, {
                user_id: `${user.id}`,
                hospital_id: hospital.id,
            });
        }
    }
}
