import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class TagAssociationBatchesResource {
    KC_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    indexTagAssociationBatches(expirationDate) {
        return this.http
            .get(`${this.KC_ENDPOINT}tag_association_batches/`, {
                params: {
                    expiration_date: expirationDate,
                },
            })
            .toPromise();
    }

    updateTagAssociationBatches(tagAssociationBatchID, newLotNumber, suppress) {
        return this.http
            .put(`${this.KC_ENDPOINT}tag_association_batches/${tagAssociationBatchID}`, {
                lot_num: newLotNumber,
            })
            .toPromise();
    }
}
