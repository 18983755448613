import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class ReportSubscriptionResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    subscriptionsList() {
        return this.http.get<any>(`${this.API_ENDPOINT}report_subscriptions`).toPromise();
    }

    frequenciesList() {
        return this.http.get<any>(`${this.API_ENDPOINT}report_subscriptions/frequencies`).toPromise();
    }

    /* This endpoint does not currently exist on the backend
    ** but was in the FE code I ported over.
    ** Should fix this on the BE in a different PR and then
    ** renable this.

    ** Should also add the ability to get the Subscription by report type, not just subscription id

    getSubscription(subscriptionId) {
      return this.http.get<any>(`${this.API_ENDPOINT}report_subscriptions/${subscriptionId}`).toPromise();
    }
    */

    createSubscription(data) {
        return this.http.post<any>(`${this.API_ENDPOINT}report_subscriptions`, data).toPromise();
    }

    editSubscription(subscriptionId, data) {
        return this.http.put<any>(`${this.API_ENDPOINT}report_subscriptions/${subscriptionId}`, data).toPromise();
    }

    deleteSubscription(subscriptionId) {
        return this.http.delete<any>(`${this.API_ENDPOINT}report_subscriptions/${subscriptionId}`).toPromise();
    }

    runSubscription(data) {
        return this.http.post<any>(`${this.API_ENDPOINT}report_subscriptions/execute`, data).toPromise();
    }
}
