import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { RemoveSegmentDialog } from '@components/dialogs/remove-segment-dialog/remove-segment-dialog';
import {
    SegmentResourceType,
    SegmentTemplateEditDialog,
} from '@components/dialogs/segment-template-edit/segment-template-edit-dialog';
import { Container } from '@models/core/container';
import { ContainerScanSummary } from '@models/core/container-scan';
import { Package } from '@models/core/package';
import { Counts, Segment } from '@models/core/segment';
import { SegmentTemplate } from '@models/core/segment-template';
import { SegmentResource } from '@resources/segment-resource.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { ActionService } from '@services/utils/action.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { TranslationService } from '@services/utils/translation.service';
import { StateService } from '@uirouter/core';
import { from } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { ContainerScanInfoDialog } from '../container-scan-info/container-scan-info-dialog';

@Component({
    selector: 'container-summary-tab',
    templateUrl: './container-summary-tab.html',
    styleUrls: ['./container-summary-tab.scss'],
})
export class ContainerSummaryTab extends TableComponent {
    @Input() containerData: Container;
    @Input() containerScanData: ContainerScanSummary;
    @Output() onSegmentSave = new EventEmitter<void>();
    @Output() onSegmentDelete = new EventEmitter<void>();
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSourceWithNaturalSort<Segment & { segment_counts: Counts }>;
    hasData: boolean;
    displayedColumns: string[] = [
        'name',
        'segment_counts.need_restocking',
        'expected',
        'segment_counts.total',
        'segment_counts.missing',
        'segment_counts.expired',
        'segment_counts.recalled',
        'segment_counts.expiring_soon',
        'containerActions',
    ];
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;
    singularUnitMeasure: string;
    pluralUnitMeasure: string;

    constructor(
        private dialog: MatDialog,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService,
        private $state: StateService,
        private segmentResource: SegmentResource,
        private actionService: ActionService,
        private translationService: TranslationService
    ) {
        super();
        this.setupTranslations();
    }

    ngOnInit() {
        this.isCreateContainerSegmentAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'create_container_segment',
            'Show New Segment button'
        );
        this.isDeleteContainerSegmentAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'delete_container_segment',
            'Show Delete Segment button'
        );

        if (!!this.containerData || !!this.containerScanData) {
            this.updateContainerSegmentsData(this.containerData.segments);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes && (changes.hasOwnProperty('containerData') || changes.hasOwnProperty('containerScanData'))) {
            this.updateContainerSegmentsData(this.containerData?.segments);
        }
    }

    setupTranslations() {
        this.singularUnitMeasure = this.translationService.instant('containers.container_summary.unit');
        this.pluralUnitMeasure = this.translationService.instant('containers.container_summary.units');
    }

    handleOnSegmentSave() {
        this.onSegmentSave.emit();
    }

    openContainerSegmentModal(segment?: Segment, segmentTemplate?: SegmentTemplate, editMode?: boolean) {
        const containerId = this.$state.params.id;
        this.loadingSpinnerService
            .spinnerifyPromise(this.formularyItemsService.getFormularyItems(), LoadingSpinnerTypes.BACKDROP)
            .then((formularyItems) => {
                const dialogConfirm = this.dialog.open(SegmentTemplateEditDialog, {
                    width: '900px',
                    height: 'max-content',
                    data: {
                        formularyItems,
                        segment,
                        segments: this.containerData.segments,
                        segmentTemplate,
                        resource: SegmentResourceType.container,
                        containerId,
                        segmentEditingMode: editMode,
                    },
                    autoFocus: false,
                });

                dialogConfirm.afterClosed().subscribe((confirmSave) => {
                    if (confirmSave) {
                        this.onSegmentSave.emit();
                    }
                });
            });
    }

    removeSegment(segmentId: number, segmentName: string) {
        const confirmDialog = this.dialog.open(RemoveSegmentDialog, {
            width: '460px',
            height: 'max-content',
            data: {
                segmentName,
            },
            autoFocus: false,
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.loadingSpinnerService.spinnerifyPromise(this.segmentResource.removeSegment(segmentId));

                this.onSegmentDelete.emit();
            }
        });
    }

    editSegment(segment: Segment) {
        const primarySegmentTemplate = segment.segment_templates.find((s) => s.primary_segment_template);

        this.openContainerSegmentModal(segment, primarySegmentTemplate, true);
    }

    getUnitMeasure(value) {
        return value > 1 || value === 0 ? 'containers.container_summary.units' : 'containers.container_summary.unit';
    }

    updateContainerSegmentsData(containerData: Segment[]) {
        if (!containerData) {
            return;
        }

        const formattedContainerData$ = from(containerData).pipe(
            // calculate segment quantity and PAR quantity
            map((segment) => this.calculateSegmentQuantityPAR(segment)),
            // calculate other quantities needed for the UI column
            map((segment) => this.calculateSegmentCounts(segment)),
            toArray()
        );

        formattedContainerData$.subscribe((data) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(data);

            setTimeout(() => {
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) {
                        return property.split('.').reduce((o, i) => o?.[i] || '', item);
                    }

                    return item?.[property];
                };

                this.dataSource.sort = this.sort;
            });
            this.hasData = this.dataSource?.data.length > 0;
        });
    }

    calculateSegmentQuantityPAR(segment: Segment) {
        let quantity = 0;
        let par_quantity = 0;

        segment.segment_templates.forEach((st) => {
            quantity += st.quantity;
            par_quantity += st.original_par;
        });

        return {
            ...segment,
            quantity,
            par_quantity,
        };
    }

    calculateSegmentCounts(segment: Segment) {
        const containerSegmentScanData =
            this.containerScanData?.segments?.find((s) => s.segment_id === segment.id) || ({} as Segment);

        const extra = containerSegmentScanData?.counts?.extra || 0;
        const missing = containerSegmentScanData?.counts?.missing || 0;
        const expired = containerSegmentScanData?.counts?.expired || 0;
        const recalled = containerSegmentScanData?.counts?.recalled || 0;
        const expiring_soon = containerSegmentScanData?.counts?.expiring_soon || 0;
        const need_restocking = containerSegmentScanData?.counts?.needs_restocking || 0;
        const total = containerSegmentScanData.items?.length + extra || 0;

        return {
            ...segment,
            segment_counts: {
                extra,
                need_restocking,
                missing,
                expired,
                recalled,
                expiring_soon,
                total,
            },
            ...containerSegmentScanData,
        };
    }

    openExpirationUnitsDialog(
        items: Array<{ windowed_package: Package }>,
        segmentName: string,
        reference: 'expiring_soon' | 'expired'
    ) {
        this.dialog.open(ContainerScanInfoDialog, {
            width: '1100px',
            height: 'max-content',
            data: {
                tableData: items.map(({ windowed_package }) => windowed_package),
                segmentName,
                modalTitleKey: `containers.container_summary.modals.${reference}_items`,
            },
            autoFocus: false,
        });
    }

    openRecalledUnitsDialog(items: Package[], segmentName: string) {
        this.dialog.open(ContainerScanInfoDialog, {
            width: '1100px',
            height: 'max-content',
            data: {
                tableData: items.filter(({ recalled }) => recalled),
                segmentName,
                modalTitleKey: 'containers.container_summary.modals.recalled_items',
            },
            autoFocus: false,
        });
    }
}
