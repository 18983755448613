import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[unitOfSaleNdc]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UnitOfSaleNdcValidateDirective),
            multi: true,
        },
    ],
})
export class UnitOfSaleNdcValidateDirective implements Validator {
    @Input('unitOfSaleNdc') unitOfUseNdc: string;
    validate(control: AbstractControl): { [key: string]: any } | null {
        return unitOfSaleNdcValidator(this.unitOfUseNdc)(control);
    }
}

export function unitOfSaleNdcValidator(unitOfUseNdc: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            const strippedValue = control.value.replace(/[^0-9a-zA-Z]/gi, '');
            if (
                (strippedValue.length !== 10 && strippedValue.length !== 11) ||
                control.value === unitOfUseNdc ||
                !control.value.match(/^[0-9\s\-]+$/)
            ) {
                return { unitOfSaleNdc: { value: control.value } };
            } else {
                return null;
            }
        }
        return null;
    };
}
