import { Component, Inject, OnInit } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import * as _ from 'lodash';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { TranslationService } from '@services/utils/translation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'upload-csv-dialog',
    templateUrl: './upload-csv-dialog.html',
    styleUrls: ['./upload-csv-dialog.scss'],
})
export class UploadCsvDialog {
    constructor(
        public dialogRef: MatDialogRef<UploadCsvDialog>,
        protected hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        protected kitMasterResource: KitMasterResource,
        protected translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    csvUploader = new FileUploader({});
    fileUploaderItem: FileItem;
    hasFile: boolean = false;
    kitMasterId: number;
    messages: string[];
    noun: string;
    nounTranslateParams: {};
    url: string;
    uploading: boolean = false;

    ngOnInit() {
        this.nounTranslateParams = { noun: this.data.noun };
        this.url = this.data.url;
        this.kitMasterId = this.data.kitMasterId;

        this.csvUploader = new FileUploader({
            url: this.url,
            authToken: this.data.authToken,
        });

        this.csvUploader.onAfterAddingFile = async (item: FileItem) => {
            item.withCredentials = false;
            this.hasFile = true;
            if (this.kitMasterId) {
                this.csvUploader.options.additionalParameter = {
                    kit_master_id: this.kitMasterId,
                };
            }
            this.fileUploaderItem = item;
        };

        this.csvUploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
            const results = JSON.parse(response);
            const count = results.count;
            this.clear();
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'modals.upload_csv.success_created',
                params: { count: count, noun: this.data.noun },
            });
            this.dialogRef.close(true);
        };

        this.csvUploader.onErrorItem = (item: FileItem, response: any, status: any, headers: any) => {
            const results = JSON.parse(response);
            // messages needs to be an array, and result.message may not be one, so we need to wrap it.
            this.messages = results.messages || _.flatten([results.message]);
            this.uploading = false;
        };
    }

    clear() {
        this.fileUploaderItem = undefined;
        this.hasFile = false;
        this.messages = undefined;
        this.uploading = false;
    }

    //required for the translation params in template
    getNoun() {
        return { noun: this.noun };
    }

    import() {
        this.uploading = true;
        this.fileUploaderItem.upload();
    }

    async downloadTemplate() {
        let csvData = await this.kitMasterResource.downloadSegments(this.kitMasterId, true);
        const hospName = _.snakeCase(this.hospitalInfoService.getHospitalName());
        let filename = await this.translationService.instant('modals.upload_csv.template_name');

        const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, `${filename}.csv`);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.setAttribute('download', `${filename}.csv`);
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        return;
    }

    onCancel() {
        this.dialogRef.close();
    }
}
