import { Component, Input } from '@angular/core';

@Component({
    selector: 'ng2-sorter',
    templateUrl: './sorter.html',
})
export class Sorter {
    @Input() active: boolean;
    @Input() sortDirection: string;
}
