import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-changes-formulary-item-dialog',
    templateUrl: './confirm-changes-formulary-item-dialog.html',
})
export class ConfirmChangesFormularyItemDialog {
    constructor(
        public dialogRef: MatDialogRef<ConfirmChangesFormularyItemDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {}

    save() {
        this.dialogRef.close({ save: true });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
