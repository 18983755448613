import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';

import { BadgeAuthService } from '@services/core/badge-auth.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BroadcastMessageService } from '@services/login/broadcast-message.service';
import { GroupLoginResource } from '@resources/group-login-resource.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LoginStateService, LoginStateTransitions, LoginStateParams } from '@services/login/login-state.service';
import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { TranslationService } from '@services/utils/translation.service';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable()
export class LogoutService {
    logoutObserver: BehaviorSubject<boolean> = new BehaviorSubject(null);
    loggingOut: boolean = false;

    constructor(
        private badgeAuthService: BadgeAuthService,
        private barcodeScanService: BarcodeScanService,
        private broadcastMessageService: BroadcastMessageService,
        private hardwareService: HardwareService,
        private groupLoginResource: GroupLoginResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private localStorageService: LocalStorageService,
        private loginStateService: LoginStateService,
        private ngxLoginService: NgxLoginService,
        private $state: StateService,
        private translationService: TranslationService
    ) {
        this.loginStateService.observeLoginState().subscribe((loginState: LoginStateParams) => {
            if (loginState?.transition === LoginStateTransitions.LOGGING_OUT) {
                this.logout(loginState.withoutBackend, loginState.timedOut);
            }
        });
    }

    observeLogout(): Observable<boolean> {
        return this.logoutObserver;
    }

    notifyLogout(): void {
        this.logoutObserver.next(true);
    }

    logout(withoutBackend: boolean = false, timedOut: boolean = false) {
        if (!this.loggingOut) {
            if (!!this.loginStateService.loggedIn) {
                const boundLogout = this.onLogout.bind(this);

                this.loggingOut = true;
                if (withoutBackend) {
                    boundLogout(timedOut);
                } else {
                    this.groupLoginResource.logout().finally(boundLogout(timedOut));
                }
            } else {
                this.$state.go('login', { inherit: false });
            }
        }
    }

    idpLogout(idpLogin: boolean) {
        if (idpLogin && this.localStorageService.get('loggedIn')) {
            this.ngxLoginService.cognitoLogout();
        }
    }

    async onLogout(timedOut: boolean = false) {
        const idpLogin: boolean = !!this.localStorageService.get('idpLogin');

        this.notifyLogout();

        await Promise.all([
            this.loadingSpinnerService.kill(),
            this.idpLogout(idpLogin),
            this.kcMatSnackBarService.clearAll(),
            this.barcodeScanService.clearBarcodeListener(),
            this.broadcastMessageService.clear(),
            this.hardwareService.clear(),
            this.badgeAuthService.clearAll(),
        ]);

        setTimeout(() => {
            this.localStorageService.clearAll('^((?!default).)*$');
            datadogRum.clearUser();
            // We need to preserve idpLogin between sessions in order to handle SAML logout properly.
            // This feels odd, and should be re-evaluated, but this unblocks an immediate customer issue
            if (idpLogin) {
                this.localStorageService.set('idpLogin', true);
            }
            this.translationService.setToBrowserLocale();
            this.$state.go('login', { inherit: false }).then(() => {
                if (!!timedOut) {
                    let logoutMsg = this.translationService.instant('modals.timeout.have_been_logged_out');
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, logoutMsg);
                    this.kcMatSnackBarService.lastSnackBar = null;
                }
            });
            // I don't like that this is in here, vs in the service itself
            // but if we do it in the service, it causes the styling to change
            // before logout is complete
            // Adding another observer/callback seemed unnecessarily complex
            this.loginStateService.loggedIn = false;
        });

        this.loggingOut = false;
    }
}
