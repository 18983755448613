import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LockableItem } from '@models/core/lockable-item';
import { TranslationService } from '@services/utils/translation.service';

interface ParamData {
    item: LockableItem;
    editDispatchLockText?: string;
    editReturnLockText?: string;
}

@Component({
    selector: 'assign-lock-dialog',
    templateUrl: './assign-lock-dialog.html',
    styleUrls: ['./assign-lock-dialog.scss'],
})
export class AssignLockDialog {
    item: LockableItem;
    locks: {
        dispatchLock?: string;
        returnLock?: string;
    };
    editDispatchLockText: string;
    editReturnLockText: string;

    constructor(
        public dialogRef: MatDialogRef<AssignLockDialog>,
        private translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.item = this.data.item;
        this.editDispatchLockText =
            this.data.editDispatchLockText || this.translationService.instant('modals.kit.edit_dispatch_locks');
        this.editReturnLockText =
            this.data.editReturnLockText || this.translationService.instant('modals.kit.edit_return_locks');

        this.locks = {
            dispatchLock: this.item.manual_locks?.dispatch_lock,
            returnLock: this.item.manual_locks?.return_lock,
        };
    }

    clearDispatchLock = () => {
        this.locks.dispatchLock = null;
    };

    clearReturnLock = () => {
        this.locks.returnLock = null;
    };

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close(this.locks);
    }
}
