import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'charge-sheet-template-manual-locks',
    templateUrl: './charge-sheet-template-manual-locks.html',
})
export class ChargeSheetTemplateManualLocks {
    @Input() kitSummary;
    @Input() returnLockHeader: string = null;

    constructor() {}

    ngOnInit() {}
}
