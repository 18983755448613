import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { PackageDescriptionsResource } from '@resources/package-descriptions-resource.service';

@Injectable({ providedIn: 'root' })
export class PackageDescriptionsResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private packageDescriptionsResource: PackageDescriptionsResource
    ) {}

    resolve(): Promise<any> {
        const packageDescriptionsPromise = this.packageDescriptionsResource
            .packageDescriptionList()
            .then(({ package_descriptions }) => package_descriptions);

        return this.loadingSpinnerService.spinnerifyPromise(packageDescriptionsPromise);
    }
}
