import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';

@Injectable({ providedIn: 'root' })
export class KitMasterTypesResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private kitMasterResource: KitMasterResource
    ) {}

    resolve(): Promise<any> {
        let kitMasterTypesPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'create_kit_master', 'Load kit master types')) {
            kitMasterTypesPromise = this.kitMasterResource.kitMasterTypes().then((data) => data.kit_master_types);
        } else {
            kitMasterTypesPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(kitMasterTypesPromise);
    }
}
