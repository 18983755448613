import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { TranslationService } from '@services/utils/translation.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HospitalAccess, UserSession } from '@models/core/user-session';

@Component({
    selector: 'lib-ngx-switch-hospital',
    templateUrl: './ngx-switch-hospital.component.html',
    styleUrls: ['./ngx-switch-hospital.component.scss'],
})
export class NgxSwitchHospitalComponent implements OnInit {
    selectClicked: boolean = false;
    hospitalAccess: HospitalAccess[];
    userName: string = this.loginService.getUserName();
    redirectToMobile: boolean;

    constructor(
        private loginService: NgxLoginService,
        private stateService: StateService,
        private ngxLoginEventService: NgxLoginEventService,
        private dialog: MatDialog,
        private featureFlagService: FeatureFlagService,
        private translationService: TranslationService,
        private configuration: ConfigurationProvider,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit() {
        this.loginService.switching = true;
        this.loginService.showPanel = true;
        this.hospitalAccess = this.loginService.getHospitalAccess();
        this.featureFlagService.getFeatureValue('redirect-to-mobile').subscribe((flagValue) => {
            this.redirectToMobile = flagValue;
        });
    }

    isAdminUser(): boolean {
        return this.loginService.isAdminUser();
    }

    async selectHospital(hospital: HospitalAccess) {
        console.log('selecting hospital');

        this.loginService.sessionHospital(hospital.id).subscribe(({ user }) => {
            this.featureFlagService.setHospital(
                { userContext: user, hospitalContext: hospital },
                null,
                async (_, flags) => {
                    // Update flag value in case the user needs to select a hospital
                    this.redirectToMobile = flags['redirect-to-mobile'];
                    await this.configureHospitalForSession(user);
                }
            );
        });
    }

    async configureHospitalForSession(user: UserSession) {
        this.selectClicked = true;
        const usingMobile = await this.translationService.get('switch_hospital.using_mobile');
        const usingMobileDescription = await this.translationService.get('switch_hospital.using_mobile_description');
        const confirmGoMobile = await this.translationService.get('switch_hospital.confirm_go_mobile');
        const cancelGoMobile = await this.translationService.get('switch_hospital.cancel_go_mobile');

        const isMobileDevice = this.deviceService.isTablet() || this.deviceService.isMobile();

        if (!isMobileDevice || !this.redirectToMobile) {
            this.startUserEntering(user);

            return;
        }

        const authToken = user.authentication_token;

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: usingMobile,
                description: usingMobileDescription,
                okButton: confirmGoMobile,
                cancelButton: cancelGoMobile,
            },
        });

        confirmDialog.afterClosed().subscribe((confirmation) => {
            if (confirmation) {
                // Temporarily added until we get all of the other environments in place
                this.loginService.storeLoginData(user);
                window.location.href = `${this.configuration.getConfiguration().mobile_app_url}?auth=${authToken}`;
                return;
            }

            this.startUserEntering(user);
        });
    }

    startUserEntering(user) {
        this.loginService.clearDataForSwitch();
        this.loginService.initiateLogin(user);
        this.ngxLoginEventService.triggerHospitalSelect();
    }

    cancelSwitch(): void {
        this.loginService.switching = false;
        if (this.loginService.isLoggedIn()) {
            this.loginService.showPanel = false;
            this.stateService.go('home');
        } else {
            this.stateService.go('login');
        }
    }
}
