import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { ChargeModelType } from '@models/core/hospital-settings';

@Injectable()
export class HospitalResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    hospitalData(hospitalId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hospitals/${hospitalId}`).toPromise();
    }

    updateHospital(hospitalId: number, data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}hospitals/${hospitalId}`, data).toPromise();
    }

    getChargeModelTypes(): Promise<Array<ChargeModelType>> {
        return this.http.get<any>(`${this.API_ENDPOINT}hospitals/charge_model_types`).toPromise();
    }

    getGracePeriodSubscriptionTags(): Promise<any> {
        const params = {
            lockout_grace_period: 'true',
        };

        return this.http.get<any>(`${this.API_ENDPOINT}hospitals/subscription_tags`, { params: params }).toPromise();
    }

    getLogoPng(hospitalId: number) {
        const options: any = { responseType: 'blob' as 'json' };
        return this.http.get<any>(`${this.API_ENDPOINT}hospitals/${hospitalId}/hospital_logo`, options).toPromise();
    }
}
