import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

// description and detail can contain html
interface ParamData {
    problems: Array<any>;
}

@Component({
    selector: 'pretagged-issue-dialog',
    templateUrl: './pretagged-issue-dialog.html',
    styleUrls: ['./pretagged-issue-dialog.scss'],
})
export class PretaggedIssueDialog {
    problems: Array<any>;
    checkboxes: { [key: string]: boolean } = {};
    notes: String = '';

    constructor(
        public dialogRef: MatDialogRef<PretaggedIssueDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.problems = this.data.problems;
        this.problems.forEach((problem) => {
            this.checkboxes[problem.name] = false;
        });
    }

    isDisabled() {
        if (this.notes.trim() && this.checkboxes['other']) {
            return false;
        }
        for (let problem in this.checkboxes) {
            if (this.checkboxes.hasOwnProperty(problem) && this.checkboxes[problem] && problem !== 'other') {
                return false;
            }
        }
        return true;
    }

    reportIssue() {
        this.dialogRef.close({ checkboxes: this.checkboxes, notes: this.notes.trim() });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
