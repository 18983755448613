import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { UnexpectedItem } from '@models/core/kit-summary';
import { determineShortageRange } from '@utils/kit-summary-util';

@Component({
    selector: 'mobile-cart-kit-summary-shortages',
    templateUrl: './shortages.html',
    styleUrls: ['./shortages.scss'],
})
export class MobileCartShortagesComponent implements OnInit, AfterViewInit {
    @Input() shortageItems: UnexpectedItem[];

    dataSource: MatTableDataSourceWithNaturalSort<UnexpectedItem>;
    displayedColumns: string[] = ['segment_summary', 'acceptable_range', 'current_kit_qty', 'normal_par'];
    @ViewChild(MatSort) sort: MatSort;

    constructor() {}

    ngOnInit() {
        this.shortageItems.forEach((item) => {
            item.shortageRange = determineShortageRange(item);
        });
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.shortageItems);
        this.dataSource.sort = this.sort;
    }
}
