import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GroupLoginService } from '@services/login/group-login.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { DownloadHelperService } from '@services/utils/download-helper.service';
import { parseUrl, stringify } from 'query-string';

@Component({
    selector: 'ng2-download-button',
    templateUrl: './download-button.html',
    styleUrls: ['./download-button.scss'],
})

/* deprecate this when possible and move to the material button version of kc-download-button*/
export class DownloadButton {
    @Input() apiUrl: string;
    @Input() downloadFilters?: any;
    @Input() disabled: boolean;
    @Input() iconOnly: boolean = false;

    constructor(
        private configuration: ConfigurationProvider,
        private groupLoginService: GroupLoginService,
        private downloadHelper: DownloadHelperService
    ) {}

    authToken() {
        return this.groupLoginService.authToken();
    }

    checkTestRun(event) {
        if (window['Cypress']) {
            event.preventDefault();
            this.downloadHelper.simulateDownload(this.getDownloadUrl(), this.authToken());
        }
    }

    getDownloadUrl() {
        let reportUrl = this.apiUrl;
        reportUrl = reportUrl.replace('/api/v1/', '');
        reportUrl += '.xlsx';

        let url = new URL(this.configuration.kcEndpointV1() + reportUrl);
        if (!!this.downloadFilters) {
            Object.keys(this.downloadFilters).forEach((downloadFilterKey) => {
                let filterValue = this.downloadFilters[downloadFilterKey];
                if (filterValue !== null) {
                    url.searchParams.append(downloadFilterKey, this.downloadFilters[downloadFilterKey]);
                }
            });
        }
        return url.toString();
    }
}
