import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { KitIndexParams } from '@models/core/kit-index-params';
import { stringify } from 'query-string';

@Injectable()
export class KitResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    // This one is being handled as an observable, so leaving it in that form
    asPdf(kitIds) {
        return this.http.post(
            `${this.API_ENDPOINT}kits/generate_pdf`,
            { kit_ids: kitIds },
            { responseType: 'arraybuffer' }
        );
    }

    kitList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits`).toPromise();
    }

    kitListWithParams(params: KitIndexParams): Promise<any> {
        const stringifiedParams = stringify(params);
        return this.http.get<any>(`${this.API_ENDPOINT}kits?${stringifiedParams}`).toPromise();
    }

    removeKit(data): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}kits/${data.id}`).toPromise();
    }

    removeKits(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kits/bulk_delete`, data).toPromise();
    }

    kitDispatch(kitId, data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kits/${kitId}/dispatch`, data).toPromise();
    }

    kitDispatchBulk(data): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kits/dispatch`, data).toPromise();
    }

    kitDispatchUnset(kitId: number, resource: string): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}kits/${kitId}/dispatch/${resource}`).toPromise();
    }

    kitData(kitId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}`).toPromise();
    }

    kitPrintBillingSheet(kitId: number, scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans/${scanId}/billing_sheet`).toPromise();
    }

    kitPrintChargeSheet(kitId: number, scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans/${scanId}/charge_sheet`).toPromise();
    }

    kitPrintPickLists(kitIds: number[]): Promise<any> {
        const data = {
            kit_ids: kitIds,
        };
        return this.http.post<any>(`${this.API_ENDPOINT}views/kit_check/kit_pick_list`, data).toPromise();
    }

    kitScanDetails(kitId: number, scanId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans/${scanId}`).toPromise();
    }

    transferKit(kitId: number): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}kits/${kitId}/transfer`, {}).toPromise();
    }

    replaceKitTag(kitId: number, printerId: number) {
        const data = {
            printer_hardware_id: printerId,
        };
        return this.http.post<any>(`${this.API_ENDPOINT}kits/${kitId}/tag`, data).toPromise();
    }

    replaceKitTagBasic(kitId: number, epc: string) {
        const data = { epc };
        return this.http.post<any>(`${this.API_ENDPOINT}kits/${kitId}/tag_association`, data).toPromise();
    }
}
