import { Component } from '@angular/core';
import { Transition } from '@uirouter/core';
import * as moment from 'moment';

import { AutoComplete } from '@components/common/auto-complete/auto-complete';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { TranslationService } from '@services/utils/translation.service';
import { ItemResource } from '@resources/item-resource.service';

import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

import FilterUtils from '@utils/filterUtils';

import { AdminPrintBatch } from '@models/admin/admin-print-batch';
import { AdminItem } from '@models/admin/admin-item';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'admin-item-expirations',
    templateUrl: './admin-item-expirations.html',
    styleUrls: ['./admin-item-expirations.scss'],
})
export class AdminItemExpirations {
    lookupError: string;
    dateError: string;
    form: {
        confirmExpiration: Date;
        item: null;
        lot: string;
        ndc: string;
        newExpiration: Date;
        step: number;
        currentExpiration: string;
    };

    item: AdminItem;
    printBatches: AdminPrintBatch[];
    totalItems: number;

    corrections: {
        created_at: {
            formatted: string;
            human_formatted: string;
        };
        created_by: string;
        id: number;
        new_expiration: string;
        old_expiration: string;
    }[];

    changing: boolean = false;
    correctionsHistory: string;
    ndcText: string;
    noPreviousCorrectionsText: string;
    customAutoCompleteForm: FormGroup;

    constructor(
        private $stateParams: Transition,
        private barcodeScanService: BarcodeScanService,
        private formularyItemsService: FormularyItemsService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private itemResource: ItemResource,
        private translationService: TranslationService,
        private formBuilder: FormBuilder
    ) {
        this.customAutoCompleteForm = this.formBuilder.group({
            ndc: new FormControl(''),
        });
    }

    ngOnInit() {
        this.resetForm();

        if (this.$stateParams.params().lot) {
            this.form.lot = this.$stateParams.params().lot;
        }

        if (this.$stateParams.params().ndc) {
            this.form.ndc = this.$stateParams.params().ndc;
        }

        this.barcodeScanService.registerListener((scanData) => {
            if (this.form.step !== 1) {
                return;
            }
            const formattedNdc = this.ndcScanUtilsService.extractNDCFromScan(scanData);
            this.form.ndc = formattedNdc;
            this.customAutoCompleteForm.setValue({ ndc: formattedNdc });

            const success = (data) => {
                this.item = data.items.length ? data.items[0] : null;
            };

            const failure = () => {
                this.form.ndc = null;
            };

            this.formularyItemsService.getFormularyItemsByNDC(this.form.ndc, undefined).then(success, failure);
        }, 'admin.items.expiration');

        this.translationService
            .get('item.uid.short_name')
            .then((translation) => {
                this.ndcText = translation;
            })
            .then(() => {
                this.translationService
                    .get('admin.update_expirations.no_previous_corrections', { ndc: this.ndcText })
                    .then((translation) => {
                        this.noPreviousCorrectionsText = translation;
                    });
            });
    }

    resetForm(): void {
        this.lookupError = '';
        this.dateError = '';
        this.form = {
            confirmExpiration: null,
            item: null,
            lot: '',
            ndc: '',
            newExpiration: null,
            step: 1,
            currentExpiration: '',
        };
    }

    selectAutoComplete(item?): void {
        this.form.ndc = !!item && item.ndc;
        this.item = item;
    }

    lookupAllowed(): boolean {
        return !!this.form.ndc && !!this.form.lot;
    }

    lookup(): Promise<any> {
        if (!this.lookupAllowed()) {
            return;
        }
        this.lookupError = '';

        const data = {
            ndc: this.item.ndc,
            lot_num: this.form.lot,
        };

        const lookupSuccess = (data) => {
            this.printBatches = data.item_expirations;
            if (this.printBatches.length === 0) {
                this.translationService.get('item.uid.short_name').then((ndcTranslation) => {
                    return this.translationService
                        .get('admin.update_expirations.none_found', { ndcTranslation })
                        .then((translation) => {
                            this.lookupError = translation;
                        });
                });
                return Promise.reject();
            }

            this.totalItems = _(this.printBatches)
                .map((batch: any) => batch.count)
                .reduce((total, n) => total + n);
            this.form.currentExpiration = this.printBatches[0].expiration_formatted;
        };

        const historySuccess = (data) => {
            this.corrections = data.manufacturer_expiration_corrections;
            this.previousCorrections();
        };

        const promises = [];

        promises.push(this.itemResource.lookupItem(data).then(lookupSuccess));
        promises.push(this.itemResource.lookupExpirationsHistory(data).then(historySuccess));

        Promise.all(promises).then(
            () => {
                this.form.step = 2;
            },
            () => {}
        );
    }

    formatDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    back(): void {
        this.form.step = 1;
    }

    changeEnabled(): boolean {
        const newDate = this.formatDate(this.form.newExpiration);
        const confirmDate = this.formatDate(this.form.confirmExpiration);
        return (
            !this.changing && newDate && newDate.length && confirmDate && confirmDate.length && newDate === confirmDate
        );
    }

    setDate(data: Date, dateType: string): void {
        if (dateType === 'newExpiration') {
            this.form.newExpiration = data;
        } else if (dateType === 'confirmExpiration') {
            this.form.confirmExpiration = data;
        }

        if (!this.form.newExpiration || !this.form.confirmExpiration) {
            return;
        }

        const newDate = this.formatDate(this.form.newExpiration);
        const confirmDate = this.formatDate(this.form.confirmExpiration);

        this.dateError = '';

        if (newDate !== confirmDate) {
            this.translationService.translateInto('admin.update_expirations.errors.dates_match', this, 'dateError');
        }
    }

    changeDate(): void {
        if (!this.changeEnabled()) {
            return;
        }

        this.changing = true;

        const data = {
            ndc: this.form.ndc,
            lot_num: this.form.lot,
            expiration: this.formatDate(this.form.newExpiration),
        };

        const success = (data) => {
            const count = data.updated_count;
            const date = moment(this.form.newExpiration).format('MMM DD, YYYY');

            const translationKey = 'admin.update_expirations.updated';
            const translationValues = {
                date,
                count,
                itemName: this.item.item_name,
                itemStrength: this.item.item_strength_formatted,
                itemStrengthUom: this.item.item_strength_uom,
                packageText: this.translationService.inflect('common.package', count).toLowerCase(),
            };

            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: translationKey,
                params: translationValues,
            });

            this.resetForm();
        };

        this.itemResource
            .replaceItemDate(data)
            .then(success)
            .finally(() => {
                this.changing = false;
            });
    }

    itemBatchTranslationValues() {
        return {
            totalItems: this.totalItems,
            printBatches: this.printBatches.length,
            itemText: this.translationService.inflect('common.item', this.totalItems).toLowerCase(),
            batchText: this.translationService.inflect('admin.update_expirations.batch', this.printBatches.length),
        };
    }

    itemPackageLongDescription(): string {
        return FilterUtils.packageLongDescription(this.item);
    }

    itemPackageLongName(): string {
        return FilterUtils.packageLongName(this.item);
    }

    previousCorrections(): void {
        const translationValues = {
            corrections: this.corrections.length,
            correctionsText: this.translationService.inflect(
                'admin.update_expirations.correction',
                this.corrections.length
            ),
            ndcText: this.ndcText,
        };

        this.translationService
            .get('admin.update_expirations.previous_corrections', translationValues)
            .then((translation) => {
                this.correctionsHistory = translation;
            });
    }
}
