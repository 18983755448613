import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BinResource } from '@resources/bin-resource.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { TranslationService } from '@services/utils/translation.service';
import { TableComponent } from '@components/common/table-component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

@Component({
    selector: 'segment-items',
    templateUrl: './segment-items.html',
})
export class SegmentItems extends TableComponent {
    @Input() bin: any;
    @Input() formulary: Array<any>;
    @Input() clinicalEquivalences: Array<any>;
    @Output() updateBinScan = new EventEmitter();

    showFormulary: boolean;
    title: string;

    constructor(
        private binResource: BinResource,
        private formularyItemsService: FormularyItemsService,
        private translationService: TranslationService,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.showFormulary = !!this.formulary;
        this.items = this.showFormulary ? this.formulary : this.clinicalEquivalences;
        this.sortBy('item_name', this.items);

        this.translationService
            .get(['headers.formulary_items', 'headers.generic_products'])
            .then(({ 'headers.formulary_items': formulary_items, 'headers.generic_products': generic_products }) => {
                return this.translationService
                    .get('bins.edit_bin.allowed_in_this_bin', {
                        objectText: this.showFormulary ? formulary_items : generic_products,
                    })
                    .then((allowedInBinTitle) => {
                        this.title = allowedInBinTitle;
                    });
            });
    }

    async removeItem(data: any) {
        const formularyItem = await this.translationService.get('headers.formulary_item');
        const genericProduct = await this.translationService.get('headers.generic_product');
        const objType = await this.translationService.get('common.bin');

        const description = await this.translationService.get('bins.edit_bin.are_you_sure_remove_from_bin', {
            objectText: this.showFormulary ? formularyItem : genericProduct,
        });
        const title = await this.translationService.get('bins.edit_bin.remove_from_bin');

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: title,
                description: description,
                okButton: this.translationService.instant('buttons.remove'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.removeItemFromBin(data).then((data) => {
                    this.items = data;
                });
            }
        });
    }

    removeItemFromBin(object: any): Promise<any> {
        if (this.showFormulary) {
            return this.binResource.removeFormularyItem(this.bin.id, object.id).then(() => {
                return this.removeItemFromList(object, this.items);
            });
        } else {
            return this.binResource.removeClinicalEquivalence(this.bin.id, object.id).then(() => {
                return this.removeItemFromList(object, this.items);
            });
        }
    }

    loadCeqFormularyItems(ceq: any, shouldLoad: boolean): void {
        if (shouldLoad) {
            this.formularyItemsService.loadCeqFormularyItems(ceq);
        }
    }

    private removeItemFromList(object: any, itemsArray: Array<any>): Promise<any> {
        return this.binResource.showBinScan(this.bin.id, 'current').then((data) => {
            this.updateBinScan.emit(data);
            return itemsArray.filter(function (value) {
                return value.id !== object.id;
            });
        });
    }
}
