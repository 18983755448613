import { FormularyItem, DeletedByUser } from '@models/core/formulary/formulary-item';

export const prepareItemName = (formularyItem: FormularyItem): string => {
    let itemName: string = formularyItem.item_name;

    if (formularyItem.preferred_item_name != null && formularyItem.preferred_item_name.length > 0) {
        itemName = formularyItem.preferred_item_name;
    }
    return itemName.trim();
};

export const prepareArchivedByName = (archivedByUser: DeletedByUser): string => {
    let kcEmailRegEx = /\S+(@bluesight.com)/g;

    if (archivedByUser == null || archivedByUser.admin_user || archivedByUser.email.search(kcEmailRegEx) > 0)
        return null;

    return archivedByUser.first_name + ' ' + archivedByUser.last_name;
};
