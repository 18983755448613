import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ScanResource } from '@resources/scan-resource.service';

@Injectable({ providedIn: 'root' })
export class DetailScanResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private scanResource: ScanResource
    ) {}

    resolve(): Promise<any> {
        const scanId = this.$state.transition.params().scanId;
        return this.loadingSpinnerService.spinnerifyPromise(this.scanResource.detailScan(scanId));
    }
}
