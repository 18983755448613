import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ActionService } from '@services/utils/action.service';
import { TranslationService } from '@services/utils/translation.service';
import { CommonCRUDAPIService } from '@services/core/common-crud-api.service';
import { MatDialog } from '@angular/material/dialog';

import { AssignableForm } from '../../assignable-form/assignable-form';
import { BarcodeImgComponent } from '@components/common/barcode-img/barcode-img';
import { BarcodeScanField } from '@components/common/barcode-scan-field/barcode-scan-field';

import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { BarcodeResource } from '@resources/barcode-resource.service';

@Component({
    selector: 'register-badge',
    templateUrl: './register-badge.html',
})
export class RegisterBadge extends AssignableForm {
    // bindings
    @Input() user: any;
    @Input() ngBadgeSet: boolean;
    @Input() ngRegisterType: string;

    @Output() userChange = new EventEmitter<any>();
    @Output() activateSave = new EventEmitter<any>();
    @Output() deactivateSave = new EventEmitter<any>();

    resetBadge: boolean;
    barcodeSelected: boolean;

    constructor(
        protected $state: StateService,
        protected actionService: ActionService,
        protected barcodeScanService: BarcodeScanService,
        protected commonCRUDAPIService: CommonCRUDAPIService,
        protected translation: TranslationService,
        protected barcodeResource: BarcodeResource,
        protected dialog: MatDialog,
        protected translationService: TranslationService,
        private cdRef: ChangeDetectorRef
    ) {
        super($state, actionService, barcodeScanService, commonCRUDAPIService, dialog, translationService);
        this.assignType = 'user';
        this.validateBarcode = this.validateBadgeBarcode.bind(this);
    }

    ngOnInit() {
        this.assignObject = this.user;
        super.ngOnInit();
    }

    handleError(messageKey: string): void {
        this.badgeScanError = true;
        this.translation.get(`register_badge.errors.${messageKey}`).then((message) => {
            this.barcodeErrorText = message;
            this.cdRef.detectChanges();
        });
        this.activateSave.emit(false);
    }

    validateBadgeBarcode(barcode: string) {
        this.barcodeResource
            .barcodeObject(barcode)
            .then((data) => {
                if (data && data.object) {
                    if (data.object['class'] === 'Tag') {
                        this.barcodeSelected = false;
                        this.updateUserBarcode(barcode);
                        this.activateSave.emit(true);
                    } else if (data.object['class'] === 'User') {
                        if (data.object.id !== parseInt(this.user.id)) {
                            this.handleError('already_associated_other_user');
                        } else {
                            this.handleError('already_associated_this_user');
                        }
                    } else {
                        this.handleError('not_a_badge');
                    }
                } else {
                    this.handleError('not_a_badge');
                }
            })
            .catch(() => {
                this.handleError('not_a_badge');
            });
        return true;
    }

    setBadge(): void {
        this.captureBarcode();
    }

    allowBadgeUpdates(): boolean {
        return this.actionService.isAllowAction(
            'user_settings',
            'change_other_badge_epc',
            "Allow changing a user's badge"
        );
    }

    badgeButtonTextKey(): string {
        if (this.barcodeSelected) {
            return 'register_badge.scan_badge_for_user';
        } else {
            if (!this.user.barcode) {
                return 'register_badge.click_to_set';
            } else {
                return this.user.barcode;
            }
        }
    }

    updateUserBarcode(barcode: string): void {
        this.user.barcode = barcode;
        this.userChange.emit(this.user);
    }

    changeBarcode(): void {
        this.ngBadgeSet = false;
        this.updateUserBarcode(undefined);
        this.captureBarcode();
    }

    removeBarcode(): void {
        this.ngBadgeSet = false;
        this.resetBadge = true;
        this.deactivateSave.emit(true);
        this.updateUserBarcode(undefined);
    }

    cancelBadgeReset(): void {
        this.ngBadgeSet = true;
        this.resetBadge = false;
        this.badgeScanError = false;
        this.barcodeSelected = false;
        this.deactivateSave.emit(false);
        this.clearBarcodeScan();
    }

    clearBarcodeScan(): void {
        if (this.user.oldBarcode) {
            this.updateUserBarcode(this.user.oldBarcode);
            this.ngBadgeSet = true;
        } else {
            this.updateUserBarcode(undefined);
            this.ngBadgeSet = false;
        }
        this.deactivateSave.emit(this.ngBadgeSet);
    }
}
