import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { FileUploadService } from '@services/utils/file-upload.service';
import { getMotorolaScanUrl } from '@utils/scanning-util';
import { Scanner } from '@models/hardware/scanner';
import * as $ from 'jquery';

const COMMAND_PSP = 'kitcheck_command.psp';
const UPLOAD_PSP = 'upload.psp';

@Injectable()
export class MotorolaResource {
    constructor(
        private http: HttpClient,
        private configuration: ConfigurationProvider,
        private fileUploadService: FileUploadService
    ) {}

    private setupUploadPspDefer = {};

    KC_API_ENDPOINT = this.configuration.kcEndpointV1();

    // kc-api resources used for motorola/fx9500/sirit model readers
    hardwareIndex() {
        return this.http.get<any>(`${this.KC_API_ENDPOINT}hardware_files`).toPromise();
    }

    healthCheckFile(scannerValue, fileName) {
        return this.http.get<any>(`${scannerValue}/user_apps/${fileName}?health_check=true`).toPromise();
    }

    runPspCommand(scannerValue, command) {
        return this.http.get<any>(`${scannerValue}/user_apps/${COMMAND_PSP}?${command}=true`).toPromise();
    }

    runRawPspCommand(scannerValue, rawCommand) {
        return this.http.get<any>(`${scannerValue}/user_apps/${COMMAND_PSP}?command=${rawCommand}`).toPromise();
    }

    runFirmwareUpgradeCommand(scannerValue, firmwareFilename) {
        return this.http
            .get<any>(`${scannerValue}/user_apps/${COMMAND_PSP}?upgrade_firmware=${firmwareFilename}`)
            .toPromise();
    }

    runCertUpgradeCommand(scannerValue, keyFileName, certFileName) {
        return this.http
            .get<any>(
                `${scannerValue}/user_apps/${COMMAND_PSP}?command=com.network.security.load_https_crt_key(https_crt_file=/apps/bin/${certFileName},https_key_file=/apps/bin/${keyFileName})`
            )
            .toPromise();
    }

    downloadFile(fileName) {
        return this.http
            .get(`${this.KC_API_ENDPOINT}hardware_files/${fileName}`, {
                observe: 'response',
                responseType: 'arraybuffer',
            })
            .toPromise();
    }

    private checkForScanFile(scanner: Scanner): Promise<any> {
        let fileName = scanner.hardware_settings.scan_endpoint.replace('user_apps/', '');
        return this.healthCheckFile(scanner.value, fileName).then((response) => {
            if (!response) {
                return this.installPspFile(scanner.value, fileName, true).catch((err) => {
                    throw { message: `Unable to install file: ${'fileName'}` };
                });
            }
        });
    }

    runScanRequest(scanner: Scanner, isUsingUpdatedPsp: boolean): Promise<any> {
        return this.checkForScanFile(scanner).then((result) => {
            return this.http
                .get(getMotorolaScanUrl(scanner))
                .toPromise()
                .then((result) => {
                    if (!result) {
                        // CORS errors don't trigger catch blocks, they set "result" to "null" when scan psp is missing
                        // so we simulate an error when we suspect a CORS error has occurred
                        throw {};
                    }
                    if (isUsingUpdatedPsp) {
                        return result['data'];
                    } else {
                        return result;
                    }
                });
        });
    }

    downloadPsPFile(fileName, asArrayBuffer = true) {
        let responseType;

        if (asArrayBuffer) {
            responseType = { responseType: 'arraybuffer' };
        }

        return this.http.get<any>(`${this.KC_API_ENDPOINT}hardware_files/psps/${fileName}`, responseType).toPromise();
    }

    pause(delayInMs = 5000): Promise<any> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({});
            }, delayInMs);
        });
    }

    installPspFile(scannerUrl, pspFileToInstall, useNewUploadPsp = false): Promise<any> {
        return this.downloadPsPFile(pspFileToInstall, true).then((newFile) => {
            const formData = this.fileUploadService.getFormData(newFile, pspFileToInstall);
            return this.uploadFile(scannerUrl, formData, useNewUploadPsp);
        });
    }

    uploadFile(url, file, useNewUploadPsp = false) {
        let targetUploadPsp = useNewUploadPsp ? 'upload_v2.psp' : 'upload.psp';
        let newUrl = `${url}/user_apps/${targetUploadPsp}`;

        return this.fileUploadService.uploadFileHttp(newUrl, file);
    }

    // install upload psp file via fwd2prnt_v2_1.psp port forwarding
    setupUploadPsp(url, uploadPspfile) {
        if (this.setupUploadPspDefer[url]) {
            return this.setupUploadPspDefer[url];
        } else {
            const boundary = 'WebKitFormBoundaryfg0Ut9WVEyfyOTPL';
            const tunnel_timeout = 10;

            // the url params "host" & "port" are configured to have fwd2prnt_v2_1.psp forward the "body" & "head" values to the scanner itself
            // this allows us to install a file without an exisitng upload.psp & dodge hospital network security hinderances
            const newUrl = `${url}/user_apps/fwd2prnt_v2_1.psp?host=localhost&port=80&allowed_origin=*&timeout=${tunnel_timeout}&quantity=1`;

            const convertedUploadPsp = this.convertArrayBufferToString(uploadPspfile);

            // "body" & "head" values are built to contain http packet data that is extracted from the fwd2prnt_v2.psp
            let body = `--${boundary}\r\n`;
            body += `Content-Disposition: form-data; name="appsfile"; filename="${UPLOAD_PSP}"\r\n`;
            body += 'Content-Type: application/octet-stream\r\n\r\n';
            body += convertedUploadPsp + '\r\n';
            body += `--${boundary}--\r\n`;

            let head = 'POST /advanced/appsupload.psp HTTP/1.1\r\n';
            head += 'Host: localhost:80\r\n';
            head += 'User-Agent: curl/7.43.0\r\n';
            head += 'Accept: */*\r\n';
            head += 'Cookie: login=admin; pwd=change; uptime=115.50\r\n';
            head += `Content-Type: multipart/form-data; boundary=${boundary}\r\n`;
            head += `Content-Length: ${body.length}\r\n\r\n`;

            this.setupUploadPspDefer[url] = new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: newUrl,
                    data: head + body,
                    contentType: 'application/x-www-form-urlencoded',
                    crossDomain: true,
                    dataType: 'html',
                    success: function () {
                        resolve();
                        this.setupUploadPspDefer[url] = undefined;
                    },
                    error: function (data) {
                        if (this.setupUploadPspDefer[url]) {
                            reject(data);
                            this.setupUploadPspDefer[url] = undefined;
                        }
                    },
                });

                setTimeout(() => {
                    if (this.setupUploadPspDefer[url]) {
                        resolve();
                        this.setupUploadPspDefer[url] = undefined;
                    }
                }, 60000);
            });

            return this.setupUploadPspDefer[url];
        }
    }

    private convertArrayBufferToString(buffer): string {
        const enc = new TextDecoder('utf-8');
        return enc.decode(buffer);
    }
}
