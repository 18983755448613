import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { TableComponent } from '../table-component';

@Component({
    selector: 'formulary-search',
    templateUrl: './formulary-search.html',
    styleUrls: ['./formulary-search.scss'],
})
export class FormularySearch extends TableComponent {
    private _formularyItems = [];
    @Input() set items(items: any[]) {
        this._formularyItems = items;
        this.filterItems();
    }

    get items(): any[] {
        return this._formularyItems;
    }

    @Input() formularySearch = '';
    @Output() updatePick: EventEmitter<any> = new EventEmitter();

    //attributess
    pickedItem: any = { id: null };
    filteredItems: any[];

    constructor(
        private barcodeScanService: BarcodeScanService,
        private ndcScanUtilsService: NdcScanUtilsService
    ) {
        super();
    }

    ngOnInit() {
        this.barcodeScanService.registerListener((scanData) => {
            this.formularySearch = this.ndcScanUtilsService.extractNDCFromScan(scanData);
            this.filterItems();
        }, 'formulary-search');
    }

    // filter by the ndc entered in the searchbox
    filterItems(): void {
        this.filteredItems = this.items;
        if (!!this.formularySearch && !!this.items) {
            let noDashNDCRegEx = new RegExp(this.formularySearch.replace(/-/g, ''), 'mi');
            let nameRegEx = new RegExp(this.formularySearch, 'mi');
            this.filteredItems = this.items.filter((item) => {
                return item.ndcSearch.match(noDashNDCRegEx) || item.item_name.match(nameRegEx);
            });
        }
    }

    clearSearch() {
        this.formularySearch = '';
        this.filteredItems = this.items;
    }

    sort(field: string): void {
        this.sortBy(field, this.items);
    }

    pick(item) {
        this.pickedItem = _.cloneDeep(item);
        this.updatePick.emit(this.pickedItem);
    }
}
