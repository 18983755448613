import { Input, Directive } from '@angular/core';
import { Injectable } from '@angular/core';
import { NaturalSortService, SortDirection } from '@services/utils/natural-sort.service';
import { sortDeep } from '@utils/sort';

interface Sort {
    field: string;
    direction: SortDirection;
}

@Injectable()
export abstract class TableComponent {
    _defaultSort: { field: string; direction: SortDirection };
    @Input()
    set defaultSort(sort: { field: string; direction: SortDirection | string }) {
        let dir: any = sort.direction;
        if (typeof dir === 'string') {
            dir = SortDirection[sort.direction];
            sort.direction = dir;
        }
        let defSort: { field: string; direction: SortDirection } = { field: sort.field, direction: dir };
        this._defaultSort = defSort;
        // Initialize the Sort object's field and direction when defaultSort is set.
        this._sort.field = defSort.field;
        this._sort.direction = defSort.direction;
    }

    _items: Array<any>;
    @Input()
    set items(items: Array<any>) {
        // When the items array changes (via filtering, etc.), re-sort it per the existing sort.

        /*
         ** NaturalSortService sorts strings by dropping the leading zeros to sort.
         ** this _can_ look strange when sorting NDCs
         ** so we may want to use a plain sort order moving forward
         ** decided to leave it as NaturalSort for now
         ** but leaving this in case we need to change it in the future.
         ** (also below)
         */

        //if (this._sort.field === 'ndc') {
        //    this._items = sortDeep(items, this._sort.field, this._sort.direction);
        // } else {
        this._items = NaturalSortService.sort(items, this._sort.field, this._sort.direction);
        // }
    }
    get items() {
        return this._items;
    }

    private _sort: Sort = {
        field: '',
        direction: SortDirection.asc,
    };
    get sortDirection(): SortDirection {
        return this._sort.direction;
    }
    get sortField(): string {
        return this._sort.field;
    }

    private expanded: string | null = null;

    // Make SortDirection available to templates.
    Sort_Direction = SortDirection;

    constructor() {}

    /**
     * Called by sub-class to sort its collection.
     * Calls to NaturalSortService.
     */
    protected sortBy = (field: string, collection: Array<any>): void => {
        if (this._sort.field === field) {
            if (this._sort.direction === SortDirection.asc) {
                this._sort.direction = SortDirection.desc;
            } else {
                this._sort.direction = SortDirection.asc;
            }
        } else {
            this._sort.field = field;
            this._sort.direction = SortDirection.asc;
        }

        // if (this._sort.field === 'ndc') {
        //     this._items = sortDeep(collection, this._sort.field, this._sort.direction);
        //} else {
        this._items = NaturalSortService.sort(collection, this._sort.field, this._sort.direction);
        // }
    };

    protected expand = (id: string, event: any): void => {
        if (this.expanded === id) {
            this.expanded = null;
        } else {
            this.expanded = id;
        }
        if (event) {
            event.stopPropagation();
        }
    };
}
