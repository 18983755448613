import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { MessageResource } from '@resources/message-resource.service';
import { TranslationService } from '@services/utils/translation.service';

@Injectable()
export class BroadcastMessageService {
    constructor(
        private localStorageService: LocalStorageService,
        private messageResource: MessageResource,
        private translationService: TranslationService
    ) {}

    clear() {
        this.localStorageService.remove('broadcastMessages');
    }

    dismissMessage(messageId: number): Promise<any> {
        const cachedMessages = this.localStorageService.get('broadcastMessages');

        return this.messageResource.dismissMessage(messageId).then(() => {
            _.remove(cachedMessages as any[], (message) => message.id === messageId);
            this.localStorageService.set('broadcastMessages', cachedMessages);

            return cachedMessages;
        });
    }

    getUserMessages(hardRefresh = false): Promise<any> {
        const cachedMessages = this.localStorageService.get('broadcastMessages');

        if (cachedMessages && !hardRefresh) {
            return Promise.resolve(cachedMessages);
        } else {
            return this.messageResource.getUserMessages().then((data) => {
                this.localStorageService.set('broadcastMessages', data.messages);
                let messages = data.messages;
                let chipContent = this.translationService.instant('dashboard.message_board.important_notification');
                messages.map(function (message) {
                    message.chip_content = chipContent;
                    message.recall_message = false;
                });
                return messages;
            });
        }
    }
}
