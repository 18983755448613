import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class HardwareResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    hardwareList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hardware`).toPromise();
    }

    hardwareData(hardwareId): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hardware/${hardwareId}`).toPromise();
    }

    updateHardware(data): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}hardware/${data.id}`, data).toPromise();
    }

    hardwareConfig(hardwareId, data): Promise<any> {
        if (!!data && !!data.firmware_version && data.firmware_version.length > 0) {
            return this.http.post<any>(`${this.API_ENDPOINT}hardware/${hardwareId}/config_check`, data).toPromise();
        }
    }

    resetZpl(hardwareId): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hardware/${hardwareId}/reset_zpl`).toPromise();
    }

    restoreZpl(hardwareId): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hardware/${hardwareId}/factory_restore_zpl`).toPromise();
    }

    sampleZpl(hardwareId): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}hardware/${hardwareId}/sample_zpl`).toPromise();
    }

    setFirmwareRevisions(hardwareId, firmware_revisions): Promise<any> {
        return this.http
            .put<any>(`${this.API_ENDPOINT}hardware/${hardwareId}/firmware_revisions`, {
                firmware_revisions: firmware_revisions,
            })
            .toPromise();
    }
}
