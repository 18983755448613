import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Scanner } from '@models/hardware/scanner';

@Component({
    selector: 'default-scanner-dialog',
    templateUrl: './default-scanner-dialog.html',
})
export class DefaultScannerDialog {
    selectedDefaultId: string = null;

    constructor(
        public dialogRef: MatDialogRef<DefaultScannerDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            scanners: Scanner[];
            currentDefault: number;
        }
    ) {}

    ngOnInit() {
        if (!!this.data.currentDefault) {
            this.selectedDefaultId = this.data.currentDefault.toString();
        }
    }
}
