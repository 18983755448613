import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class MidmarkDeviceBatteryResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    lowBatteryStates(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}midmark_device_batteries/low`).toPromise();
    }
}
