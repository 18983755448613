import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class GroupRolesResource {
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getGroupRoles(): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}group_roles`).toPromise();
    }
}
