import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

@Component({
    selector: 'generic-ndc-item-type-filter',
    templateUrl: './generic-ndc-item-type-filter.html',
    providers: [{ provide: 'IFilter', useExisting: GenericNdcItemTypeFilter }],
})
export class GenericNdcItemTypeFilter implements IFilter {
    @Input() apiSubscriptionData: any; // hash from subscription API
    @Input() filterData: string | null; // hash from report-filter component
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeGenericNdcItemType = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    ngOnInit() {
        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData = '';
            //TODO: add conversion from filterData here.
            this.apiFormattedData.emit(this.formatForApi());
        } else {
            this.reset();
        }
    }

    reset() {
        this.filterData = 'ndc_pkg_str';
        this.changeGenericNdcItemType.emit(this.filterData);
    }

    filterItemType(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeGenericNdcItemType.emit(this.filterData);
        this.incomplete.emit({ filter: 'genericNdcItemType', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): any {
        // TODO: add conversion from filterData to api data here.
        return [];
    }
}
