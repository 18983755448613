import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportResource } from '@resources/report-resource.service';
import { ReportScopeToDataService } from './report-scope-to-data.service';
import { cleanDate } from '@utils/objects';
const reportNameKitActivity = 'kit_activity';

@Injectable()
export class KitActivityReportService {
    constructor(
        private reportResource: ReportResource,
        private reportScopeToData: ReportScopeToDataService
    ) {}

    detectReport(reportName: string): boolean {
        return reportName === reportNameKitActivity;
    }

    reportData(scope: any): any {
        let data = this.reportScopeToData.nameKitActivity(scope);
        return data;
    }

    setFilterDefaults(scope: any): void {
        scope.filters.kit_ids = scope.filters.kit_ids || null;
        scope.filters.start_date = scope.filters.start_date
            ? cleanDate(scope.filters.start_date)
            : moment(new Date()).subtract('months', 1).startOf('month').toDate();
        scope.filters.end_date = scope.filters.end_date
            ? cleanDate(scope.filters.end_date)
            : moment(new Date()).subtract('months', 1).endOf('month').toDate();
        scope.showKitMasterFilter = true;
        scope.showActivityFilter = true;
        scope.showDateFilter = true;
        scope.filters.kit_master_id = scope.filters.kit_master_id || 'all';
        scope.reportTemplate = 'kit-activity';
        scope.showClear = false;

        if (typeof scope.filters.activities === 'string') {
            scope.filters.activities = _.map(scope.filters.activities.split('|'), (act: any) => {
                return { activity: act, name: _.capitalize(act) };
            });
        }
    }

    refreshReport(scope: any, data: any): Promise<any> {
        return this.reportResource.kitActivity(data).then((data: any) => {
            scope.ui.reportRunned = true;
            scope.activities = data.reports;
            scope.hasAnyData = data.reports.length > 0;
            scope.ui.reportHasData = data.reports.length !== 0;
            scope.reportDate = new Date();
        });
    }
}
