import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionResource } from '@resources/report-subscription-resource.service';
import { ReportingResource } from '@resources/reporting-resource.service';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { ChartingService } from '@services/report/charting.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { Report, ReportCategory } from '@models/core/report';
import { SegmentTemplate } from '@models/core/segment-template';
import { KitMaster, AdvancedSegmentOptimization, SegmentData } from './advanced-segment-optimization.model';

@Component({
    selector: 'advanced-segment-optimization',
    templateUrl: './report-advanced-segment-optimization.html',
    styleUrls: ['./report-advanced-segment-optimization.scss'],
})
export class ReportAdvancedSegmentOptimization {
    // bindings
    @Input() advancedSegmentOptimization: AdvancedSegmentOptimization;
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    // constants
    private SORT_ASC: string = 'ascending';
    private SORT_DESC: string = 'descending';

    CONSUMED: string = 'consumed';
    COUNT: string = 'count';
    PERCENT: string = 'dispatch_percent';

    // attributes
    report: Report;
    isDownloadable: boolean;
    downloadFilters: {
        kit_masters?: string;
        start_date?: string;
        end_date?: string;
    };
    currentSegment: SegmentData;
    filters: {
        kitMasters?: KitMaster[];
        start_date?: Date;
        end_date?: Date;
    } = {};
    placeholder: string;
    dateRange: FormControl;
    kitMasters: KitMaster[];
    segments: SegmentData[];
    segmentTemplateForSegment: SegmentTemplate;
    segmentTemplates: SegmentTemplate[] = [];
    scheduledReportsEnabled: boolean;

    filterOptionsKitMasters: KitMaster[];
    dropdownSettingsKitMasters = {
        singleSelection: false,
        text: 'Select Kit Masters',
        selectAllText: 'Select All Kit Masters',
        unSelectAllText: 'Clear All Kit Masters',
        enableSearchFilter: true,
        classes: 'kit-master-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Kit Masters Selected',
        objectTypeName: 'Kit Master',
    };

    chartHeight: number = 260;
    chartWidth: number = 750; // 617;
    paddingH: number = 75;
    paddingV: number = 30;

    chartSort: string = this.CONSUMED;
    chartSortDirection: string = this.SORT_ASC;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'name',
        'modified',
        'dispatches',
        'par',
        'max_consumed',
        'avg_consumed',
        'par_max_consumed',
        'percent_consuming_max',
    ];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        protected translationService: TranslationService,
        protected chartingService: ChartingService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportingResource: ReportingResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private segmentTemplateResource: SegmentTemplateResource,
        private hospitalInfoService: HospitalInfoService,
        private dialog: MatDialog
    ) {
        this.placeholder = this.translationService.instant('formats.placeholder');
    }

    private processData(advancedSegmentOptimizationData) {
        this.advancedSegmentOptimization = advancedSegmentOptimizationData;
        this.currentSegment = advancedSegmentOptimizationData.current_segment;
        this.kitMasters = this.advancedSegmentOptimization.kit_masters;
        this.filterOptionsKitMasters = this.kitMasters.map((kitMaster) => kitMaster);
        this.filters.kitMasters = _.cloneDeep(this.kitMasters);

        this.segments = this.advancedSegmentOptimization.segments;
        this.segments.forEach((segment) => {
            this.segmentTemplateResource.getSegmentTemplatesForSegment(segment, true).then((segment_template) => {
                this.segmentTemplates.push(segment_template['segment_template']);
            });
        });

        this.segments.forEach((segment) => {
            segment.name = `${segment.kit_master_name} - ${segment.segment_name}`;
            segment.percent_consuming_max = Math.round(segment.percent_consuming_max * 10000) / 100;

            _.each(segment.dispatch_consumption, (consumption: any, consumed) => {
                consumption.consumed = parseInt(consumed);
            });
        });

        this.dataSource = new MatTableDataSourceWithNaturalSort(this.segments);
        this.dataSource.sort = this.sort;

        this.updateDownloadFilters();
    }

    ngOnInit() {
        this.dateRange = new FormControl({
            begin: moment().subtract(1, 'month').toDate(),
            end: moment().toDate(),
        });

        this.filters.start_date = this.dateRange.value.begin;
        this.filters.end_date = this.dateRange.value.end;

        this.processData(this.advancedSegmentOptimization);

        const reportCategory = this.reports.report_categories.find((category) => category.name === 'analytics');
        this.report = reportCategory.reports.find((report) => report.name === 'advanced_segment_optimization');
        this.isDownloadable = this.report.is_downloadable;
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
    }

    ngAfterViewInit() {
        // wasn't sorting on initial load
        this.dataSource.sort = this.sort;
    }

    updateDownloadFilters() {
        const kit_masters = this.filters.kitMasters.map((km) => km.id).join('|');

        this.downloadFilters = {
            kit_masters,
            start_date: moment(this.filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.filters.end_date).format('YYYY-MM-DD'),
        };
    }

    refreshReport() {
        this.filters.start_date = this.dateRange.value.begin;
        this.filters.end_date = this.dateRange.value.end;

        const data = {
            start_date: moment(this.filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.filters.end_date).format('YYYY-MM-DD'),
        };

        const promise = this.reportingResource.advancedSegmentOptimization(data).then((advSegment) => {
            this.processData(advSegment);
            this.renderChart();
        });

        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal() {
        const subscribeFilters = {
            kitMasters: this.filters.kitMasters,
            start_date: this.dateRange.value.begin,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: { reportName: this.report.name, filterData: subscribeFilters, frequencies: frequencies },
            });
        });
    }

    onSelect(): void {
        this.filterByKitMasters();
        this.updateDownloadFilters();
    }

    onDeselect(): void {
        this.filterByKitMasters();
        this.updateDownloadFilters();
        if (this.currentSegment && !this.filters.kitMasters.find((km) => km.id === this.currentSegment.kit_master_id)) {
            this.currentSegment = undefined;
        }
    }

    filterByKitMasters(): void {
        const filteredSegments = this.segments.filter((segment) => {
            return this.filters.kitMasters.map((km) => km.id).includes(segment.kit_master_id);
        });

        this.dataSource = new MatTableDataSourceWithNaturalSort(filteredSegments);
        this.dataSource.sort = this.sort;
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    selectSegment(segment): void {
        this.chartSort = this.CONSUMED;
        this.chartSortDirection = this.SORT_ASC;
        this.currentSegment = segment;

        this.renderChart();
    }

    showSortAsc(type: string): boolean {
        return !this.currentSort(type) || this.chartSortDirection === this.SORT_ASC;
    }

    showSortDesc(type: string): boolean {
        return this.currentSort(type) && this.chartSortDirection === this.SORT_DESC;
    }

    currentSort(type: string): boolean {
        return this.chartSort === type;
    }

    sortChart(type: string): void {
        if (this.chartSort && this.chartSort === type) {
            if (this.chartSortDirection === this.SORT_ASC) {
                this.chartSortDirection = this.SORT_DESC;
            } else {
                this.chartSortDirection = this.SORT_ASC;
            }
        } else {
            this.chartSort = type;
            this.chartSortDirection = this.SORT_ASC;
        }

        this.renderChart();
    }

    segmentParHistory(segment: any): void {
        const selectedTooltip = $(`[data-segment-id=${segment.segment_id}] .par-tooltip`);
        $('.par-tooltip').not(selectedTooltip).css('display', 'none');
        selectedTooltip.toggle();
        this.segmentTemplateForSegment = this.segmentTemplates.find(
            (segmentTemp) => segmentTemp['segment_id'] === segment.segment_id
        );
    }

    // Chart Rendering

    renderBars(svg, data, rangeScale, attribute, bands, valueScale, tipTemplate): void {
        const barWidth = bands.bandwidth();

        const tip = d3tip().attr('class', 'd3-tip').html(tipTemplate).offset([-8, 0]);

        svg.call(tip);

        svg.selectAll('.chart')
            .data(data)
            .enter()
            .append('rect')
            .classed(`bar ${attribute}-bar`, true)
            .attr('x', (_c, i) => rangeScale(i) - barWidth / 2)
            .attr('y', (c) => valueScale(c[attribute]))
            .attr('width', barWidth)
            .attr('height', (c) => this.chartHeight - valueScale(c[attribute]) - this.paddingV)
            .on('mouseout', tip.hide)
            .on('mouseover', tip.show);
    }

    renderRangeAxis(svg, data, rangeScale): void {
        svg.selectAll('.chart')
            .data(data)
            .enter()
            .append('text')
            .classed('range-text', true)
            .text((s) => s.consumed)
            .attr('x', (_c, i) => rangeScale(i))
            .attr('y', () => this.chartHeight - this.paddingV + 20);
    }

    renderChart(): void {
        if (!this.currentSegment) {
            return;
        }

        // Set up data

        const s = this.currentSegment;
        const consumedVals: number[] = _.map(s.dispatch_consumption, this.CONSUMED) as number[];
        let consumedMax: number = d3.max(consumedVals);
        consumedMax = Math.max(consumedMax, s.par);

        const consumedRange: number[] = _.range(consumedMax + 1);
        let data = _.map(consumedRange, (i) => {
            if (s.dispatch_consumption[String(i)]) {
                return s.dispatch_consumption[String(i)];
            } else {
                return {
                    consumed: i,
                    dispatch_percent: 0,
                    count: 0,
                };
            }
        });

        const sortAttr: string = this.chartSort || this.CONSUMED;
        const sortDir: string = this.chartSortDirection || this.SORT_ASC;

        data.sort(function (a, b) {
            return d3[sortDir](a[sortAttr], b[sortAttr]);
        });

        const consumedList = data.map((segment) => segment.consumed);

        // Create scales

        const rangeScale = d3
            .scaleLinear()
            .domain([-0.5, consumedMax + 0.5])
            .range([this.paddingH, this.chartWidth - this.paddingH]);

        const countBands = d3
            .scaleBand()
            .domain(consumedRange)
            .range([this.paddingH, this.chartWidth - this.paddingH])
            .paddingInner(0.5);

        const percentBands = d3
            .scaleBand()
            .domain(consumedRange)
            .range([this.paddingH, this.chartWidth - this.paddingH])
            .paddingInner(0.85);

        const counts: any = data.map((segment) => segment.count);
        const countsMax = d3.max(counts);

        const countScale = d3
            .scaleLinear()
            .domain([countsMax, 0])
            .range([this.paddingV, this.chartHeight - this.paddingV]);

        const percents: any = data.map((segment) => segment.dispatch_percent);

        const percentScale = d3
            .scaleLinear()
            .domain([d3.max(percents), 0])
            .range([this.paddingV, this.chartHeight - this.paddingV]);

        // Set up SVG

        const consumedChart = d3.select('#consumed-chart');

        consumedChart.html('');

        const svg: any = consumedChart
            .append('svg')
            .classed('chart', true)
            .attr('width', this.chartWidth)
            .attr('height', this.chartHeight);

        // Range

        const rangeAxis = d3.axisBottom(rangeScale).tickValues(consumedList);

        svg.append('g')
            .classed('range-axis', true)
            .attr('transform', `translate(0, ${this.chartHeight - this.paddingV})`)
            .call(rangeAxis);

        this.translationService.get('reports.advanced_segment.items_consumed').then((text) => {
            const rangeAxisLabel: KC.ISVGText = {
                x: this.chartWidth / 2,
                y: 10,
                text,
                class: 'axis-label range-axis-label',
            };
            this.chartingService.addText(svg, rangeAxisLabel);
        });

        // Count

        const countAxis = d3.axisLeft(countScale).ticks(6);

        svg.append('g').attr('transform', `translate(${this.paddingH}, 0)`).call(countAxis);

        this.translationService.get('reports.advanced_segment.dispatch_count').then((text) => {
            const countAxisLabel: KC.ISVGText = {
                x: 0,
                y: 0,
                text,
                transform: 'rotate(-90)translate(-170,30)',
                class: 'axis-label',
            };

            this.chartingService.addText(svg, countAxisLabel);
        });

        // Percent

        const percentFormat = d3.format('.0%');

        const percentAxis = d3.axisRight(percentScale).ticks(6).tickFormat(percentFormat);

        svg.append('g')
            .attr('transform', `translate(${this.chartWidth - this.paddingH}, 0)`)
            .call(percentAxis);

        this.translationService.get('reports.advanced_segment.item_consumption').then((text) => {
            const percentAxisLabel: KC.ISVGText = {
                x: 0,
                y: 0,
                text,
                transform: 'rotate(-90)translate(-205,743)',
                class: 'axis-label',
            };

            this.chartingService.addText(svg, percentAxisLabel);
        });

        // Tooltips
        this.translationService
            .get([
                'reports.advanced_segment.count_tip.multi_dispatch.multi_items',
                'reports.advanced_segment.count_tip.multi_dispatch.single_item',
                'reports.advanced_segment.count_tip.single_dispatch.multi_items',
                'reports.advanced_segment.count_tip.single_dispatch.single_item',
            ])
            .then((translations) => {
                // e is the mouse event. d has the data for the item in the chart
                const countTipTemplate = (e: any, d: any) => {
                    const val = d.count;
                    const consumed = d.consumed;

                    const translationKey = `reports.advanced_segment.count_tip.${
                        val > 1 ? 'multi_dispatch' : 'single_dispatch'
                    }.${s.par > 1 ? 'multi_items' : 'single_item'}`;
                    const tipCompile = _.template(translations[translationKey]);
                    return `<div>${tipCompile({ dispatches: val, consumed, par: s.par })}</div>`;
                };

                this.renderBars(svg, data, rangeScale, this.COUNT, countBands, countScale, countTipTemplate);
            });

        this.translationService
            .get([
                'reports.advanced_segment.percent_tip.multi_items',
                'reports.advanced_segment.percent_tip.single_item',
            ])
            .then((translations) => {
                // e is the mouse event. d has the data for the item in the chart
                const percentTipTemplate = (e: any, d: any) => {
                    const val = percentFormat(d.dispatch_percent);
                    const consumed = d.consumed;

                    const translationKey = `reports.advanced_segment.percent_tip.${
                        s.par > 1 ? 'multi_items' : 'single_item'
                    }`;
                    const tipCompile = _.template(translations[translationKey]);
                    return `<div>${tipCompile({ dispatches: val, consumed, par: s.par })}</div>`;
                };

                this.renderBars(svg, data, rangeScale, this.PERCENT, percentBands, percentScale, percentTipTemplate);
            });

        // Axis
        this.renderRangeAxis(svg, data, rangeScale);
    }
}
