import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { SegmentsService } from '@services/core/segments.service';

@Injectable({ providedIn: 'root' })
export class ShortageSegmentsResolver {
    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentsService: SegmentsService
    ) {}

    resolve(): Promise<any> {
        let segmentPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kit_segment', 'Load Segments data')) {
            const stateKitMasterParam = this.$state.transition.params().kitMasterId;

            if (stateKitMasterParam) {
                segmentPromise = this.segmentsService.getSegments(stateKitMasterParam);
            } else {
                segmentPromise = this.segmentsService.getAllSegments('shortage');
            }
        } else {
            segmentPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(segmentPromise);
    }
}
