import { Component, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface Filters {
    page?: number;
    report_mode?: string; // bin or kit
    ndc?: string;
    lot_num?: string;
}

interface PackageData {
    manufacturer: string;
    name: string;
    ndc: string;
    package_description_name: string;
}

@Component({
    selector: 'report-items-by-ndc',
    templateUrl: './report-items-by-ndc.html',
    styleUrls: ['./report-items-by-ndc.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportItemsByNDC {
    @Input() reports: {
        report_categories: ReportCategory[];
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    expandedElement: any; // used to track the current expanded row
    filters: Filters;
    hasData: boolean;
    isDownloadable: boolean = true;
    reportDate: Date;
    report: Report;
    report_mode: string = 'kit';
    hasRun: boolean = false;

    kitMasterDisplayedColumns: string[] = [
        'expander',
        'kit_master_name',
        'kit_physical_label',
        'cart_name',
        'location_name',
        'expired_count',
        'expiring_soon_count',
        'soonest_name',
        'soonest',
    ];

    binDisplayedColumns: string[] = [
        'expander',
        'bin_name',
        'expired_count',
        'expiring_soon_count',
        'soonest_name',
        'soonest',
    ];

    innerDisplayedColumns: string[] = [
        'manufacturer',
        'full_item_name',
        'ndc',
        'package_formatted',
        'strength_formatted',
        'lot_num',
        'expiration',
    ];

    @ViewChild('outerSort') sort: MatSort;
    @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<PackageData>>;

    constructor(
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.reportDate = new Date();
        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'item_attribute'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'item_expiration_and_attributes_ndc_lot');
        this.filters = { report_mode: 'kit' };
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    displayColumns(): string[] {
        if (this.report_mode === 'bin') {
            return this.binDisplayedColumns;
        } else {
            return this.kitMasterDisplayedColumns;
        }
    }

    reportModeColumns(): number {
        if (this.report_mode === 'bin') {
            return this.binDisplayedColumns.length;
        } else {
            return this.kitMasterDisplayedColumns.length;
        }
    }

    refreshReport(event: any): void {
        this.filters = {
            page: 1,
            report_mode: event.binKit,
        };

        if (!!event.ndcLotSearch.ndc) {
            this.filters.ndc = event.ndcLotSearch.ndc;
        }

        if (!!event.ndcLotSearch.lot) {
            this.filters.lot_num = event.ndcLotSearch.lot;
        }

        const promise = this.reportResource.customNDCOrLotReport(this.filters).then((rawReportData) => {
            this.hasRun = true;
            if (this.filters.report_mode === 'bin') {
                rawReportData.bins.forEach((item, i) => {
                    item.id = i;
                    item.full_item_name = item.soonest_name;
                });

                this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.bins);
            } else {
                rawReportData.kits.forEach((item, i) => {
                    item.id = i;
                    item.full_item_name =
                        `${item.soonest_name} ${item.soonest_item_strength}${item.soonest_item_strength_uom}`.trim();
                });

                this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.kits);
            }
            this.report_mode = this.filters.report_mode;
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    toggleRow(row): void {
        // fix up the package and strength strings for display/sorting
        row.package_events.forEach((item) => {
            let package_size = [item.package_size, item.package_size_uom].filter((x) => x).join('');
            item.package_formatted = [package_size, item.package_description_name].filter((x) => x).join(' ');
            item.strength_formatted = [item.item_strength_formatted, item.item_strength_uom].filter((x) => x).join('');
            item.full_item_name = [item.name, item.strength_formatted].filter((x) => x).join(' ');
        });

        // only create the datasource for the inner table on toggle for performance reasons
        this.dataSource.data[row.id].package_events_data = new MatTableDataSourceWithNaturalSort(row.package_events);
        this.expandedElement = this.expandedElement === row ? null : row;
        this.changeDetectorRef.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSourceWithNaturalSort<PackageData>).sort =
                    this.innerSort.toArray()[index])
        );
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            binKit: event.binKit,
            ndcLotSearch: event.ndcLotSearch,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
