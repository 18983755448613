import { Component, Input, Output, OnInit, OnChanges, AfterViewInit, ViewChild, EventEmitter } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MarkOpenedDialog } from './mark-opened-dialog/mark-opened-dialog';
import { isAppUnderPostLockoutState, isScannedAfterLockoutState } from '@utils/charge-model-util';
import { Package } from '@models/core/package';
import { ChargeModelTypeName } from '@models/core/hospital-settings';

@Component({
    selector: 'kit-multidose',
    templateUrl: './kit-multidose.html',
    styleUrls: ['./kit-multidose.scss'],
})
export class KitMultidose {
    @Input() items;
    @Input() kitSummary;
    @Input() showMultidose;

    @Output() loadKitSummary = new EventEmitter();

    dataSource: MatTableDataSourceWithNaturalSort<any>;

    displayedColumns: string[] = ['name', 'ndc', 'epc', 'strength', 'package', 'lot', 'opened_date', 'expiration'];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;
    lockedOutItems: Package[];
    updateKitSegment: boolean;
    tabs: Array<[string, string]> = [
        ['kit_page.unopened', 'unopened'],
        ['common.all', 'all'],
    ];
    multidoseButton: string;
    subscription_items_lockout_date: string;
    postLockout: boolean = false;
    charge_model_type: ChargeModelTypeName;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog,
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        this.subscription_items_lockout_date =
            this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.charge_model_type = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;
        this.postLockout = isAppUnderPostLockoutState(this.subscription_items_lockout_date, this.charge_model_type);
    }

    ngAfterViewInit(): void {
        this.multidoseButton = 'unopened';
        this.setFilteredDataSource();
    }

    ngOnChanges() {
        this.setFilteredDataSource();
    }

    setFilteredDataSource() {
        let filteredItems = this.items;
        if (
            this.postLockout &&
            isScannedAfterLockoutState(this.subscription_items_lockout_date, this.kitSummary.scan.created_at)
        ) {
            // filter out any required subscription items
            this.lockedOutItems = this.items.filter((item) => item.subscription_required);
            filteredItems = this.items.filter((item) => !item.subscription_required);
        }

        if (this.multidoseButton === 'unopened') {
            filteredItems = filteredItems.filter((item) => {
                return !item.expiration_multidose_opened_on;
            });
        }
        this.dataSource = new MatTableDataSourceWithNaturalSort(filteredItems);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    toggleUnopened(tab) {
        this.multidoseButton = tab;
        this.setFilteredDataSource();
    }

    toggleMultidose = () => {
        this.showMultidose = !this.showMultidose;
    };

    markAsOpened(item) {
        const markOpenedDialog = this.dialog.open(MarkOpenedDialog, {
            width: '640px',
            height: 'max-content',
            data: {
                item: item,
            },
        });

        markOpenedDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.loadKitSummary.emit({ spinnerType: LoadingSpinnerTypes.BACKDROP });
            }
        });
    }
}
