import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mercy-narcotic-intubation',
    templateUrl: './mercy-narcotic-intubation.html',
})
export class MercyNarcoticIntubation implements OnInit {
    @Input() chargeData;
    @Input() kitSummary;

    constructor() {}

    ngOnInit() {}
}
