import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { AutoComplete } from '@components/common/auto-complete/auto-complete';
import { isEmpty } from '@utils/objects';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';

interface ApiData {
    ndc: string;
    lot_num: string;
}

export interface NdcLotFilterData {
    lot: any;
    ndc: any;
}

const BLANK_FILTER_DATA: NdcLotFilterData = Object.freeze({
    lot: null,
    ndc: null,
});

@Component({
    selector: 'ndc-lot-search-filter',
    templateUrl: './ndc-lot-search-filter.html',
    providers: [{ provide: 'IFilter', useExisting: NdcLotSearchFilter }],
})
export class NdcLotSearchFilter implements IFilter, AfterViewInit, OnInit {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: NdcLotFilterData = Object.assign({}, BLANK_FILTER_DATA);
    @Input() lotSearchFilterData: string | null;
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeLotSearch = new EventEmitter();
    @Output() changeNdcLotSearch = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    @ViewChild(AutoComplete) autoComplete: AutoComplete;

    customAutoCompleteForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private barcodeScanService: BarcodeScanService,
        private ndcScanUtilsService: NdcScanUtilsService
    ) {
        this.customAutoCompleteForm = this.formBuilder.group({
            ndc: new FormControl(''),
        });
    }

    ngOnInit() {
        this.setupListeners();
        // Parent Components can pass 'null'
        if (!this.filterData) {
            this.filterData = Object.assign({}, BLANK_FILTER_DATA);
        }

        // receives data from the api and convert to filterData format
        if (!!this.apiSubscriptionData) {
            this.filterData.lot = this.apiSubscriptionData.lot_num;
            this.filterData.ndc = this.apiSubscriptionData.ndc;
        }
        this.filterNdcLot();
    }

    ngAfterViewInit(): void {
        if (!!this.reportSubscription) {
            this.filterNdcLot();
        } else {
            this.reset();
        }
    }

    setupListeners() {
        this.barcodeScanService.registerListener((scanData) => {
            const ndcInput = document.getElementById('ndc-field');
            if (document.activeElement === ndcInput) {
                const formattedNdc = this.ndcScanUtilsService.extractNDCFromScan(scanData);
                this.customAutoCompleteForm.setValue({ ndc: formattedNdc });
            }
        }, 'setupNdcForNdcLotSearchReport');
    }

    reset(): void {
        this.filterData = Object.assign({}, BLANK_FILTER_DATA);
        this.changeNdcLotSearch.emit(this.filterData);
        this.autoComplete.resetSelection();
    }

    filterNdc(data?): void {
        if (typeof data === 'string') {
            //in the case of typed NDC not from dropdown, when NDC is invalid
            this.filterData.ndc = data;
        } else {
            this.filterData.ndc = !!data && data.ndc; //in the case of NDC selected from dropdown
        }
        this.filterNdcLot();
    }

    filterNdcLot(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeNdcLotSearch.emit(this.filterData);
        this.incomplete.emit({
            filter: 'ndcLotSearch',
            incomplete: isEmpty(this.filterData.ndc) && isEmpty(this.filterData.lot),
        });
    }

    formatForApi(): Array<any> {
        return [{ ndc: this.filterData.ndc }, { lot_num: this.filterData.lot }];
    }
}
