import { Component, Input, Output, ViewChild, EventEmitter, OnInit, AfterViewInit, SimpleChanges } from '@angular/core';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import * as $ from 'jquery';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'kits-table',
    templateUrl: './kits-table.html',
    styleUrls: ['./kits-table.scss'],
})
export class KitsTable implements OnInit, AfterViewInit {
    @Input() flags;
    @Input() kits;

    @Output() toggleCheckAll = new EventEmitter();
    @Output() toggleCheck = new EventEmitter();
    @Output() retrieveScanList = new EventEmitter();
    @Output() assignLocks = new EventEmitter();
    @Output() assignToLocation = new EventEmitter();
    @Output() assignToCartOrLocation = new EventEmitter();
    @Output() pageChange = new EventEmitter();

    dataSource: MatTableDataSourceWithNaturalSort<any>;

    displayedColumns: string[] = [
        'kit_master_name',
        'physical_label',
        'last_scan_date',
        'first_expiring_sort',
        'locks',
        'location_name',
        'cart_name',
    ];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;
    hasData: boolean;
    kitIds: Array<number>;

    constructor(private hospitalInfoService: HospitalInfoService) {}

    ngOnInit() {
        // create a fake date so that null dates will sort as older
        let fakeDate = moment().subtract('year', 50).toDate();

        this.kits.forEach((kit) => {
            kit.last_scan_date = kit.last_scan_created_at || fakeDate;
            kit.locks = kit.hasManualLocks;
        });

        if (this.flags?.showCheckbox) {
            this.displayedColumns = ['check', ...this.displayedColumns];
        }

        if (this.kits.some((kit) => kit.mineAtOtherHospital)) {
            this.displayedColumns.splice(-2, 0, 'hospital_name');
        }

        if (!this.hospitalInfoService.getHospitalSettings().manual_locks_enabled) {
            this.displayedColumns = this.displayedColumns.filter((c) => c !== 'locks');
        }
    }

    ngAfterViewInit() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.kits);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.sortAndEmit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // need to refresh the datasource if the filters are set in the parent
        if (!!changes.kits) {
            this.dataSource = new MatTableDataSourceWithNaturalSort(this.kits);
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.sortAndEmit();
        }
    }

    handlePageEvent(event: PageEvent) {
        this.sortAndEmit(event.pageSize, event.pageIndex);
    }

    handleSortChange(event: any) {
        this.sortAndEmit();
    }

    sortAndEmit(size = null, index = null) {
        const pageSize = size || this.dataSource.paginator?.pageSize || 25;
        const pageIndex = index || this.dataSource.paginator?.pageIndex || 0;
        let sortedData = this.dataSource.data;
        if (this.dataSource.sort) {
            sortedData = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort).map((kit) => kit.id);
        }
        const pageData = sortedData.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
        this.pageChange.emit(pageData);
    }

    onToggleCheckAll() {
        this.toggleCheckAll.emit();
    }

    onToggleCheck(kit) {
        this.toggleCheck.emit(kit);
    }

    toggleAndGetScans(kit) {
        $(`#kit-scan-history-${kit.id}`).toggle();
        this.retrieveScanList.emit(kit);
    }

    setLocks(kit) {
        this.assignLocks.emit(kit);
    }

    setLocation(kit) {
        this.assignToLocation.emit(kit);
    }

    setCart(kit) {
        this.assignToCartOrLocation.emit(kit);
    }
}
