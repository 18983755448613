import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DecommissionResource } from '@resources/decommission-resource.service';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    reasons: string; // a | joined list of reasons
}

@Component({
    selector: 'reasons-filter',
    templateUrl: './reasons-filter.html',
    providers: [{ provide: 'IFilter', useExisting: ReasonsFilter }],
})
export class ReasonsFilter implements IFilter {
    @Input() apiSubscriptionData: ApiData; // hash from subscription API
    @Input() filterData: Array<string>; // hash from report-filter component
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeReasons = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty

    reasons: Array<any>;
    componentData;
    prefilter: boolean = false;

    constructor(private decommissionResource: DecommissionResource) {}

    ngOnInit() {
        this.decommissionResource.getDecommissionReasons().then((decomData) => {
            this.reasons = decomData.decommission_reasons;
            this.prefilter = !!this.filterData;

            // receives data from the api and convert to filterData format
            if (!!this.apiSubscriptionData) {
                this.filterData = this.apiSubscriptionData.reasons.split('|');
            } else {
                this.filterData = this.reasons.map((reason) => reason.name);
            }

            if (this.reportSubscription || this.prefilter) {
                this.filterReasons();
            } else {
                this.reset();
            }
        });
    }

    filterReasons(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeReasons.emit(this.filterData);
        this.incomplete.emit({ filter: 'reasons', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): ApiData[] {
        return [{ reasons: this.filterData.join('|') }];
    }

    reset(): void {
        this.filterData = this.reasons.map((reason) => reason.name);
        this.changeReasons.emit(this.filterData);
    }
}
