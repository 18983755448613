import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { Segment } from '@models/core/segment';

@Component({
    selector: 'kit-scan-details',
    templateUrl: './kit-scan-details.html',
})
export class KitScanDetails {
    @Input() kitId;
    @Input() kitSummary;
    @Input() segments: Array<Segment>;

    @Output() loadKitSummary = new EventEmitter();

    refresh(spinnerType?) {
        this.loadKitSummary.emit(spinnerType);
    }
}
