import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { IFilter } from '@models/common/report-filter';
import { isEmpty } from '@utils/objects';

interface ApiData {
    // todo
}

@Component({
    selector: 'kit-drug-group-filter',
    templateUrl: './kit-drug-group-filter.html',
    providers: [{ provide: 'IFilter', useExisting: KitDrugGroupFilter }],
})
export class KitDrugGroupFilter implements IFilter {
    @Input() apiSubscriptionData: any; // hash from subscription API
    @Input() filterData: string | null; // hash from report-filter
    @Input() reportSubscription: boolean;

    @Output() apiFormattedData = new EventEmitter();
    @Output() changeKitDrugGroup = new EventEmitter();
    @Output() incomplete = new EventEmitter(); // used to disable the next button on subscripiton report if required and empty
    prefilter: boolean = false;

    ngOnInit() {
        this.prefilter = !!this.filterData;
        if (!!this.apiSubscriptionData) {
            this.filterData = '';
            //TODO: add conversion from filterData here.
            this.apiFormattedData.emit(this.formatForApi());
        } else if (!this.prefilter) {
            this.reset();
        }
    }

    filterGroup(): void {
        this.apiFormattedData.emit(this.formatForApi());
        this.changeKitDrugGroup.emit(this.filterData);
    }

    reset(): void {
        this.filterData = 'kitMaster';
        this.changeKitDrugGroup.emit(this.filterData);
        this.incomplete.emit({ filter: 'kitDrugGroup', incomplete: isEmpty(this.filterData) });
    }

    formatForApi(): Array<any> {
        // TODO: add conversion from filterData to api data here.
        return [];
    }
}
