import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatPaginator } from '@angular/material/paginator';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    DEFAULT_SHOW_FIRST_LAST_BUTTONS,
} from '@models/common/report-pagination';

import { MatDialog } from '@angular/material/dialog';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { RecallResource } from '@resources/recall-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { ActionService as ActionService } from '@services/utils/action.service';

import { IgnoreRecallDialog } from './ignore-recall-dialog/ignore-recall-dialog';

import { TableComponent } from '@components/common/table-component';
import { getNestedValue } from '@utils/objects';

import { AdminRecall, AdminNetworkRecall } from '@models/admin/admin-recall';

@Component({
    selector: 'admin-recalls-list',
    templateUrl: './admin-recalls-list.html',
    styleUrls: ['./admin-recalls-list.scss'],
})
export class AdminRecallsList {
    //attributes
    @Input() recalls: AdminRecall[];
    @Input() recallsNetwork: AdminNetworkRecall[];

    actionAllow: any;
    activeLink: string;
    activeRecalls: AdminRecall[];
    addedRecallLength: number;
    addingRecalls: boolean;
    checkedNetworkHeader: string;
    currentRecallView: string;
    hasData: boolean = false;
    hasNetworkData: boolean = false;
    ignoredRecalls: AdminRecall[];
    ignoredRecallLength: number;
    isLoading: boolean = true;
    networkRecallLength: number;
    shelvedInventoryEnabled: boolean;
    selected_recall: AdminRecall;

    recallDataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedRecallsColumns: string[] = [
        'removed',
        'ndc',
        'item_name',
        'lot_numbers',
        'number_of_kitted_or_binned_items_in_hospital',
        'updated_at_date',
        'display_name',
        'recallActions',
    ];
    @ViewChild('recallsTable') recallSort: MatSort;

    networkRecallDataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedNetworkRecallsColumns: string[] = [
        'formulary_item.ndc',
        'formulary_item.item_name',
        'shared_recall.lot_numbers',
        'number_of_kitted_or_binned_items_in_hospital',
        'created_at_date',
        'reason_display_name',
        'actions',
    ];
    @ViewChild('networkRecallsTable') networkSort: MatSort;

    ignoredDataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedIgnoredRecallsColumns: string[] = [
        'ndc',
        'item_name',
        'lot_numbers',
        'updated_at_date',
        'display_name',
        'reason_ignored',
        'ignoredActions',
    ];
    @ViewChild('ignoredRecallsTable') ignoredSort: MatSort;

    @ViewChild('paginatorNetwork') paginatorNetwork: MatPaginator;
    @ViewChild('paginatorActive') paginatorActive: MatPaginator;
    @ViewChild('paginatorIgnored') paginatorIgnored: MatPaginator;

    constructor(
        private $state: StateService,
        private actionService: ActionService,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private recallResource: RecallResource,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.actionAllow = this.actionService.allowModule('administration');
        this.shelvedInventoryEnabled = this.hospitalInfoService.allowShelvedInventory();

        if (this.$state.params.tab === 'active') {
            this.switchViewType('hospital');
        } else if (this.$state.params.tab === 'ignored') {
            this.switchViewType('ignored');
        } else {
            this.switchViewType('network');
        }

        //flattening so that the recall reason info is easier to handle in the template
        this.recalls.forEach((recall) => {
            recall.reason_display_name = getNestedValue(recall.recall_reason, 'display_name');
        });

        //separate out the ignored recalls
        this.ignoredRecalls = this.recalls.filter((recall) => !!recall.ignored_at);
        this.activeRecalls = this.recalls.filter((recall) => !recall.ignored_at);

        this.activeRecalls.forEach((recall) => {
            recall['removed'] = !recall.removed_at ? 0 : 1;
            recall['updated_at_date'] = new Date(recall.updated_at);
            recall['see_all_lots'] = false;
        });

        this.recallsNetwork.forEach((recall) => {
            recall.reason_display_name = getNestedValue(recall.shared_recall.recall_reason, 'display_name');
            recall['lot_numbers'] = [recall.shared_recall.lot_number];
            recall['created_at_date'] = new Date(recall.shared_recall['created_at_date_only']);
        });

        if (this.$state.params.ids) {
            const idList = this.$state.params.ids.split(',');
            this.recallsNetwork.forEach((recall) => {
                if (idList.includes(recall.formulary_item.formulary_item_id).toString()) {
                    recall.checked = true;
                }
            });
        }
    }

    ngAfterViewInit(): void {
        this.recallDataSource = new MatTableDataSourceWithNaturalSort(this.activeRecalls);
        this.recallDataSource.sort = this.recallSort;
        this.recallDataSource.paginator = this.paginatorActive;
        this.addedRecallLength = this.recallDataSource.data.length;

        this.ignoredDataSource = new MatTableDataSourceWithNaturalSort(this.ignoredRecalls);
        this.ignoredDataSource.sort = this.recallSort;
        this.ignoredDataSource.paginator = this.paginatorIgnored;
        this.ignoredRecallLength = this.ignoredDataSource.data.length;

        this.networkRecallDataSource = new MatTableDataSourceWithNaturalSort(this.recallsNetwork);
        this.networkRecallDataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) {
                return property.split('.').reduce((o, i) => o[i], item);
            }
            return item[property];
        };
        this.networkRecallDataSource.sort = this.networkSort;
        this.networkRecallDataSource.paginator = this.paginatorNetwork;
        this.networkRecallLength = this.networkRecallDataSource.data.length;

        this.isLoading = false;
        this.hasData = !!this.recalls.length;
        this.hasNetworkData = !!this.recallsNetwork.length;
    }

    isRecallView(view) {
        return (getNestedValue(this, 'currentRecallView') || 'hospital') === view;
    }

    switchViewType(link) {
        this.activeLink = link;
        return (this.currentRecallView = link);
    }

    checked(checkable) {
        return checkable.checked;
    }

    allChecked() {
        return this.recallsNetwork.every((recall) => recall.checked);
    }

    recallCount() {
        return this.recallsNetwork.filter((recall) => recall.checked).length;
    }

    anyChecked() {
        return this.recallsNetwork.some((recall) => recall.checked);
    }

    setChecked(recall) {
        recall.checked = !recall.checked;
        this.setSharedHeader();
    }

    setSharedHeader() {
        const recallText = this.translationService.inflect('admin.item_recalls.recall', this.recallCount());
        this.translationService
            .get('admin.item_recalls.network.selected', { recallCount: this.recallCount(), recallText: recallText })
            .then((translation) => {
                this.checkedNetworkHeader = translation;
            });
    }

    toggleCheckAll() {
        const newState = !this.allChecked();
        this.recallsNetwork.forEach((recall) => {
            recall.checked = newState;
        });
        this.setSharedHeader();
    }

    recallSuccessMsg(checkedItems: any[]) {
        let itemList = '';
        checkedItems.forEach((recall, idx) => {
            if (idx > 0) {
                itemList += ', ';
            }
            itemList += `${recall.formulary_item.item_name} ${recall.formulary_item.item_strength_formatted} ${recall.formulary_item.item_strength_uom}, ${recall.formulary_item.package_size_formatted} ${recall.formulary_item.package_size_uom} ${recall.formulary_item.package_description_name}`;
        });
        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
            key: 'admin.item_recalls.recall_added',
            params: { itemList },
        });
    }

    setupRecallsParams(checkedItems: any[]) {
        const data = { recalls_params: [] };
        data.recalls_params = checkedItems.map((item) => {
            return {
                formulary_item_id: item.formulary_item.formulary_item_id,
                lot_numbers: [item.shared_recall.lot_number],
                recall_reason_id: item.shared_recall.recall_reason.id,
            };
        });
        return data;
    }

    addRecall(recall) {
        recall.checked = true;
        this.addRecalls(recall);
    }

    addRecalls(recall = null) {
        this.addingRecalls = true;

        let checkedItems;
        if (!!recall) {
            checkedItems = [recall];
        } else {
            checkedItems = this.recallsNetwork.filter((recall) => recall.checked);
        }

        let data = this.setupRecallsParams(checkedItems);

        this.recallResource
            .addRecalls(data)
            .then(() => {
                this.recallSuccessMsg(checkedItems);
                this.$state.go('.', this.$state.params, { reload: true });
            })
            .catch((reason) => {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                    key: 'admin.item_recalls.recall_error',
                    params: { reason },
                });
            })
            .finally(() => {
                this.addingRecalls = false;
            });
    }

    locateRecall(recall) {
        this.$state.go('report-item-recalls', { recall_ids: String(recall.id) });
    }

    removeRecall(recall) {
        this.recallResource.removeRecall(recall.id).then(() => {
            this.$state.params['tab'] = 'active';
            this.$state.go('.', this.$state.params, { reload: true });
        });
    }

    unremoveRecall(recall) {
        this.recallResource.unremoveRecall(recall.id).then(() => {
            this.$state.params['tab'] = 'active';
            this.$state.go('.', this.$state.params, { reload: true });
        });
    }

    unignoreRecall(recall) {
        this.recallResource.unignoreRecall(recall.id).then(() => {
            this.$state.params['tab'] = 'ignored';
            this.$state.go('.', this.$state.params, { reload: true });
        });
    }

    ignoreRecall(recall) {
        const ignoreRecallDialog = this.dialog.open(IgnoreRecallDialog, {
            width: '800px',
            height: 'max-content',
            data: { recall: recall },
        });

        ignoreRecallDialog.afterClosed().subscribe((reason) => {
            if (reason) {
                this.recallResource
                    .ignoreRecall(recall.id, {
                        ignored_reason_id: reason['ignored_reason_id'],
                        ignored_reason_detail: reason['ignored_reason_detail'],
                    })
                    .then(() => {
                        this.$state.params['tab'] = 'active';
                        this.$state.go('.', this.$state.params, { reload: true });
                    });
            }
        });
    }

    addAndIgnoreRecall(recall) {
        const ignoreRecallDialog = this.dialog.open(IgnoreRecallDialog, {
            width: '800px',
            height: 'max-content',
            data: { recall: recall },
        });

        ignoreRecallDialog.afterClosed().subscribe((reason) => {
            if (reason) {
                // first need to add the recall to the hospital
                this.addingRecalls = true;

                let data = this.setupRecallsParams([recall]);

                this.recallResource
                    .addRecalls(data)
                    .then((results) => {
                        let ignorePromises = [];
                        results.recalls.forEach((recall) => {
                            ignorePromises.push(
                                this.recallResource.ignoreRecall(recall.id, {
                                    ignored_reason_id: reason['ignored_reason_id'],
                                    ignored_reason_detail: reason['ignored_reason_detail'],
                                })
                            );
                        });
                        Promise.all([ignorePromises]).then(() => {
                            // need to wait a sec before we reload
                            setTimeout(() => {
                                this.$state.go('.', this.$state.params, { reload: true });
                            }, 250);
                        });
                    })
                    .catch((reason) => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'admin.item_recalls.recall_error',
                            params: { reason },
                        });
                    })
                    .finally(() => {
                        this.addingRecalls = false;
                    });
            }
        });
    }
}
