import { Injectable } from '@angular/core';
import { ReportScopeToDataService } from './report-scope-to-data.service';
import { ReportResource } from '@resources/report-resource.service';
import { cleanDate } from '@utils/objects';

const reportNameItemsConsumed = 'itemsConsumed';
@Injectable()
export class ItemsConsumedReportService {
    constructor(
        private reportResource: ReportResource,
        private reportScopeToData: ReportScopeToDataService
    ) {}

    detectReport(reportName: string): boolean {
        return reportName === reportNameItemsConsumed;
    }

    reportData(scope: any): Function {
        return this.reportScopeToData.dateRange(scope);
    }

    setFilterDefaults(scope: any): void {
        scope.showDateFilter = true;
        if (scope.filters.end_date) {
            scope.filters.end_date = cleanDate(scope.filters.end_date);
        } else {
            scope.filters.end_date = new Date();
            scope.filters.end_date.setHours(0, 0, 0, 0);
        }

        scope.filters.start_date =
            cleanDate(scope.filters.start_date) ||
            new Date(
                scope.filters.end_date.getFullYear(),
                scope.filters.end_date.getMonth(),
                scope.filters.end_date.getDate() - 30
            );
    }

    refreshReport(scope: any, data: any): Promise<any> {
        return this.reportResource.itemsConsumedReport(data).then(function success(data: any) {
            scope.ui.reportRunned = true;
            scope.consumedData = data;
            for (const key in data) {
                scope.ui.reportHasData = scope.ui.reportHasData || !!scope.consumedData[key].length;
            }

            const formulary_items: Array<any> = [];

            scope.consumedData.formulary_items.forEach((item: any) => {
                formulary_items.push({
                    ndc: item[0],
                    name: item[1],
                    count: item[2],
                    strength: item[3],
                    packageSize: item[4],
                });
            });
            scope.consumedData.formulary_items = formulary_items;

            const kit_masters: Array<any> = [];
            scope.consumedData.kit_masters.forEach((item: any) => {
                kit_masters.push({
                    name: item[0],
                    count: item[1],
                });
            });
            scope.consumedData.kit_masters = kit_masters;

            const kit_masters_formulary_items: Array<any> = [];
            scope.consumedData.kit_masters_formulary_items.forEach((item: any) => {
                kit_masters_formulary_items.push({
                    kit_master_name: item[0],
                    ndc: item[1],
                    name: item[2],
                    count: item[3],
                    strength: item[4],
                    packageSize: item[5],
                });
            });
            scope.consumedData.kit_masters_formulary_items = kit_masters_formulary_items;
            scope.reportDate = new Date();
        });
    }
}
