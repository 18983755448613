import { Component, Input, NgZone } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import {
    LoadingSpinnerService,
    LoadingSpinnerData,
    LoadingSpinnerActions,
    LoadingSpinnerTypes,
} from '@services/system/loading-spinner.service';

@Component({
    selector: 'kc-spinner',
    templateUrl: './kc-spinner.html',
    styleUrls: ['./kc-spinner.scss'],
})
export class KCSpinner {
    @Input() diameter: number = 50;
    @Input() message: string = 'loading_spinner';
    @Input() color: string = 'primary'; //can be warn, error, etc. based on Material Theme - also can be white
    @Input() show: boolean = false;
    @Input() always: boolean = false;
    @Input() large: boolean;
    @Input() medium: boolean;
    @Input() tiny: boolean;
    @Input() overrideInline: boolean = false;

    private BASE_SIZE = 72;
    private TINY_SIZE = 25;
    private MEDIUM_SIZE = 62;
    private LARGE_SIZE = 92;

    outerStyle: any;
    inlineSpinner: boolean;

    private count = 0; // allows multiple promises to use the same spinner instance

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private $transitions: TransitionService,
        private zone: NgZone
    ) {}

    ngOnInit() {
        if (this.tiny) {
            this.diameter = this.TINY_SIZE;
        } else if (this.medium) {
            this.diameter = this.MEDIUM_SIZE;
        } else if (this.large) {
            this.diameter = this.LARGE_SIZE;
        }

        if (this.diameter < this.MEDIUM_SIZE && !this.overrideInline) {
            this.inlineSpinner = true;
        }

        if (this.diameter < this.BASE_SIZE) {
            this.outerStyle = {
                position: 'relative',
                top: '-1px',
            };
        }

        if (this.always) {
            return;
        }

        this.loadingSpinnerService.observeLoadingSpinner().subscribe((data: LoadingSpinnerData) => {
            if (!!data) {
                switch (data.action) {
                    case LoadingSpinnerActions.SHOW:
                        this.showSpinner(data.type);
                        break;
                    case LoadingSpinnerActions.HIDE:
                        this.hide();
                        break;
                    case LoadingSpinnerActions.USE:
                        this.useSpinner(data.type);
                        break;
                    case LoadingSpinnerActions.KILL:
                        this.kill();
                        break;
                    default:
                        break;
                }
            }
        });

        this.$transitions.onStart({}, () => {
            this.kill();
        });

        this.$transitions.onSuccess({}, () => {
            this.loadingSpinnerService.showFullSpinner = false;
        });

        this.$transitions.onError({}, () => {
            this.loadingSpinnerService.showFullSpinner = false;
        });
    }

    ngDoCheck() {
        this.zone.run(() => {
            if (this.always) {
                return;
            }

            if (this.count > 0) {
                this.show = true;
                if (!!this.loadingSpinnerService.showBackdropSpinner && this.loadingSpinnerService.showSpinner) {
                    this.loadingSpinnerService.showSpinner = false;
                } else if (!this.loadingSpinnerService.showBackdropSpinner && !this.loadingSpinnerService.showSpinner) {
                    this.loadingSpinnerService.showSpinner = true;
                }
            } else if (this.loadingSpinnerService.showSpinner || !!this.loadingSpinnerService.showBackdropSpinner) {
                setTimeout(() => {
                    this.show = false;
                    this.loadingSpinnerService.showSpinner = false;
                    this.loadingSpinnerService.showBackdropSpinner = false;
                    this.loadingSpinnerService.showFullSpinner = false;
                    this.loadingSpinnerService.showPanelSpinner = false;
                });
            }
        });
    }

    kill() {
        this.count = 0;
    }

    hide() {
        this.count = this.count === 0 ? 0 : this.count - 1;
    }

    showSpinner(type: LoadingSpinnerTypes) {
        this.useSpinner(type);

        this.count = this.count + 1;
    }

    useSpinner(type: LoadingSpinnerTypes): void {
        switch (type) {
            case LoadingSpinnerTypes.PANEL:
                this.loadingSpinnerService.showPanelSpinner = true;
                break;
            case LoadingSpinnerTypes.BACKDROP:
                this.loadingSpinnerService.showBackdropSpinner = true;
                break;
            case LoadingSpinnerTypes.FULL:
                this.loadingSpinnerService.showFullSpinner = true;
                break;
            default:
                break;
        }
    }
}
