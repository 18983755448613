import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Options } from '@angular-slider/ngx-slider';
import * as _ from 'lodash';

import { ActionService } from '@services/utils/action.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { SegmentsService } from '@services/core/segments.service';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

import { Segment } from '@models/core/segment';
import { SegmentTemplate, SegmentTemplateCEQ, SegmentTemplateFormularyItem } from '@models/core/segment-template';

interface STESegmentTemplateCEQ extends SegmentTemplateCEQ {
    expanded: boolean;
}

interface STESegmentTemplate extends SegmentTemplate {
    clinical_equivalences: STESegmentTemplateCEQ[];
    formularyItemsDataSource: MatTableDataSource<SegmentTemplateFormularyItem>;
    genericItemsDataSource: MatTableDataSource<STESegmentTemplateCEQ>;
    showShortageSlider: boolean;
    sliderMin: number;
    sliderMax: number;
    sliderOptions: Options;
}

@Component({
    selector: 'segment-template-edit',
    templateUrl: './segment-template-edit.html',
    styleUrls: ['./segment-template-edit.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SegmentTemplateEdit {
    @Input() segment;
    Segment;
    @Input() shortageTable: boolean;
    @Input() allowUpdate: boolean = true;

    maxTemplates: number;
    templatesEditable: boolean = true;
    segmentTemplates: STESegmentTemplate[];
    kitMasterId: number;
    segmentId: number;

    formularyItemsDisplayedColumns: string[] = ['ndc', 'item_name', 'strength', 'package', 'added'];
    genericItemsDisplayedColumns: string[] = ['expander', 'name', 'strength', 'package', 'added'];
    ceqFormularyItemsDisplayedColumns: string[] = ['ndc', 'item_name', 'strength', 'package'];

    constructor(
        private dialog: MatDialog,
        private actionService: ActionService,
        private formularyItemsService: FormularyItemsService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentsService: SegmentsService,
        private segmentTemplateResource: SegmentTemplateResource,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.maxTemplates = this.hospitalInfoService.getHospitalSettings().maximum_segment_templates;
        this.templatesEditable = true;
        this.kitMasterId = this.segment.kit_master_id;
        this.setupSegment(this.segment);
    }

    setupSegment(segment: Segment): void {
        this.segment = segment;
        this.segmentId = segment.id;
        this.segmentTemplates = this.segment.segment_templates || [];
        this.segmentTemplates.forEach((segmentTemplate) => {
            if (!segmentTemplate.formularyItemsDataSource) {
                segmentTemplate.formularyItemsDataSource = new MatTableDataSource(segmentTemplate.formulary_items);
            }
            segmentTemplate.formularyItemsDataSource.data = segmentTemplate.formulary_items;
            if (!segmentTemplate.genericItemsDataSource) {
                segmentTemplate.genericItemsDataSource = new MatTableDataSource(segmentTemplate.clinical_equivalences);
            }
            segmentTemplate.genericItemsDataSource.data = segmentTemplate.clinical_equivalences;
            segmentTemplate.showShortageSlider =
                _.isNumber(segmentTemplate.shortage_quantity_min) && _.isNumber(segmentTemplate.shortage_quantity_max);

            segmentTemplate.sliderOptions = {
                floor: 0,
                ceil: segmentTemplate.quantity,
            };

            if (!!segmentTemplate.showShortageSlider) {
                segmentTemplate.sliderMin = segmentTemplate.shortage_quantity_min;
                segmentTemplate.sliderMax = segmentTemplate.shortage_quantity_max;
            }
        });
        this.segment.newName = this.segment.name;
        this.segment.onShortage = this.segment.shortage;
    }

    allowAdd(): boolean {
        return (
            this.actionService.isAllowAction('kits_inventory', 'create_kit_segment', 'Show Add Fill Option button') &&
            this.allowUpdate &&
            (!this.segmentTemplates || this.segmentTemplates.length < this.maxTemplates)
        );
    }

    segmentTemplateEditDialog(targetTemplate: STESegmentTemplate): void {
        this.segmentsService
            .segmentTemplateEdit(this.segment, this.kitMasterId, targetTemplate)
            .then(() => {
                this.segmentsService.clear(this.kitMasterId);
                const segmentPromise = this.segmentsService.getSingleSegment(this.segmentId).then((segment) => {
                    this.setupSegment(segment);
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'manage_segment.success.updated_fill',
                    });
                });

                this.loadingSpinnerService.spinnerifyPromise(segmentPromise);
            })
            .catch(() => {});
    }

    deleteSegmentTemplate(segmentTemplate: STESegmentTemplate): void {
        const remove = this.translationService.instant('buttons.remove');
        const fill_option = this.translationService.instant('segment_templates.fill_option');
        const alt_fill = this.translationService.instant('segment_templates.this_alternate');

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: { title: `${remove} ${fill_option}`, description: alt_fill, okButton: remove },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.segmentTemplateResource
                    .removeSegmentTemplate({
                        id: segmentTemplate.id,
                    })
                    .then(() => {
                        this.segmentsService.clear(this.kitMasterId);
                        this.segmentsService.getSingleSegment(this.segmentId).then((segment) => {
                            this.setupSegment(segment);
                        });
                    });
            }
        });
    }

    setTemplateAsPreferred(segmentTemplate: STESegmentTemplate): void {
        this.segmentsService
            .updateSegment({
                id: this.segment.id,
                primary_segment_template_id: segmentTemplate.id,
            })
            .then(() => {
                this.segmentsService.clear(this.kitMasterId);
                this.segmentsService.getSingleSegment(this.segmentId).then((segment) => {
                    this.setupSegment(segment);
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'manage_segment.success.updated_preferred',
                    });
                });
            });
    }

    toggleRow(ceq: STESegmentTemplateCEQ): void {
        ceq.expanded = !ceq.expanded;
        if (ceq.expanded) {
            this.formularyItemsService.loadCeqFormularyItems(ceq);
        }
    }

    onUserChangeEnd(event, segmentTemplate: STESegmentTemplate): void {
        this.updateShortageRange(event.value, event.highValue, segmentTemplate.showShortageSlider, segmentTemplate);
    }

    toggleShowShortage(segmentTemplate: STESegmentTemplate): void {
        let min = null;
        let max = null;

        if (!segmentTemplate.showShortageSlider) {
            min = segmentTemplate.shortage_quantity_min || 0;
            if (!_.isNumber(segmentTemplate.shortage_quantity_max)) {
                max = segmentTemplate.quantity || 0;
            } else {
                max = segmentTemplate.shortage_quantity_max;
            }
        }

        this.updateShortageRange(min, max, !segmentTemplate.showShortageSlider, segmentTemplate);
    }

    updateShortageRange(
        min: number,
        max: number,
        showShortageSlider: boolean,
        segmentTemplate: STESegmentTemplate
    ): void {
        const data = {
            id: segmentTemplate.id,
            shortage_quantity_min: min,
            shortage_quantity_max: max,
        };

        this.segmentTemplateResource
            .updateSegmentTemplate(data)
            .then(() => {
                segmentTemplate.shortage_quantity_min = min;
                segmentTemplate.shortage_quantity_max = max;
                segmentTemplate.showShortageSlider = showShortageSlider;
            })
            .catch(() => {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                    key: 'manage_segment.errors.shortage_range',
                });
            })
            .finally(() => {
                segmentTemplate.sliderMin = segmentTemplate.shortage_quantity_min;
                segmentTemplate.sliderMax = segmentTemplate.shortage_quantity_max;
            });
    }
}
