import { MobileCartFooterComponent } from './home/footer/footer';
import { MobileCartHeaderComponent } from './home/header/header';
import { MobileCartLandingPageComponent } from './home/landing-page/landing-page';
import { MobileCartKitBlockComponent } from './kit-block/kit-block';
import { MobileCartKitSummaryItemsComponent } from './scan-summary/kit-summary-items/kit-summary-items';
import { MobileCartScanSummaryComponent } from './scan-summary/scan-summary';
import { MobileCartScanWarningsComponent } from './scan-summary/scan-warnings/scan-warnings';
import { MobileCartShortagesComponent } from './scan-summary/shortages/shortages';
import { MobileCartUnexpectedItemsComponent } from './scan-summary/unexpected-items/unexpected-items';

export const MobileCartComponents = [
    MobileCartHeaderComponent,
    MobileCartFooterComponent,
    MobileCartKitSummaryItemsComponent,
    MobileCartKitBlockComponent,
    MobileCartLandingPageComponent,
    MobileCartUnexpectedItemsComponent,
    MobileCartScanSummaryComponent,
    MobileCartShortagesComponent,
    MobileCartScanWarningsComponent,
];
