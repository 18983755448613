import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Component({
    selector: 'admin-locks',
    templateUrl: './admin-locks.html',
})
export class AdminLocks {
    locksForm = new FormGroup({
        manual_locks_enabled: new FormControl(false),
        assign_locks_to_carts: new FormControl(false),
        remove_lock_from_cart_removes_lock_on_kit: new FormControl(false),
        remove_lock_from_kit_removes_lock_on_cart: new FormControl(false),
    });

    constructor(
        private hospitalInfoService: HospitalInfoService,
        private groupLoginService: GroupLoginService,
        private hospitalResource: HospitalResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        const {
            manual_locks_enabled,
            assign_locks_to_carts,
            remove_lock_from_cart_removes_lock_on_kit,
            remove_lock_from_kit_removes_lock_on_cart,
        } = this.hospitalInfoService.getHospitalSettings();

        this.locksForm.setValue({
            assign_locks_to_carts,
            manual_locks_enabled,
            remove_lock_from_kit_removes_lock_on_cart,
            remove_lock_from_cart_removes_lock_on_kit,
        });
    }

    update() {
        const hospitalId = this.hospitalInfoService.getHospitalId();
        const newSettings = {
            ...this.locksForm.value,
        };
        this.hospitalResource
            .updateHospital(hospitalId, newSettings)
            .then((_) => this.groupLoginService.getRefreshedHospitalSettings())
            .then((hospitalSettings) => {
                this.localStorageService.set('hospitalSettings', hospitalSettings);
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'hospital_settings.updated_message',
                });
            })
            .catch((err) => {
                if (!!err.message) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                }
            });
    }
}
