import { Component, OnInit } from '@angular/core';
import { TemplateData } from '../../template-data';

@Component({
    selector: 'piedmont',
    templateUrl: './piedmont.html',
})
export class Piedmont implements OnInit {
    chargeData;
    kitSummary;

    constructor(public templateData: TemplateData) {}

    ngOnInit() {
        this.chargeData = this.templateData.chargeData;
        this.kitSummary = this.templateData.kitSummary;
        this.chargeData.location_name = this.chargeData.location_name || this.chargeData.kit?.location_name;
    }
}
