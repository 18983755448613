import { Injectable } from '@angular/core';
import { ReportScopeToDataService } from './report-scope-to-data.service';
import { ReportResource } from '@resources/report-resource.service';
import * as moment from 'moment';
import { cleanDate } from '@utils/objects';

const reportNameHospital = 'hospital';

@Injectable()
export class HospitalReportService {
    constructor(
        private reportResource: ReportResource,
        private reportScopeToData: ReportScopeToDataService
    ) {}

    detectReport(reportName: string): boolean {
        return reportName === reportNameHospital;
    }

    reportData(scope: any): any {
        return this.reportScopeToData.dateRange(scope);
    }

    setFilterDefaults(scope: any): void {
        scope.showKitMasterFilter = false;
        scope.showNDCFilter = false;
        scope.showLotNumberFilter = false;
        scope.showExpiresInFilter = false;
        scope.showIncludeExpiredFilter = false;
        scope.showDateFilter = true;
        scope.showClear = false;

        scope.filters.start_date = scope.filters.start_date
            ? cleanDate(scope.filters.start_date)
            : moment(new Date()).subtract('months', 1).startOf('month').toDate();
        scope.filters.end_date = scope.filters.end_date
            ? cleanDate(scope.filters.end_date)
            : moment(new Date()).subtract('months', 1).endOf('month').toDate();
    }

    refreshReport(scope: any, data: any): Promise<any> {
        return this.reportResource.hospitalActivityReport(data).then((data: any) => {
            scope.ui.reportRunned = true;
            scope.activities = data.reports;
            scope.hasAnyData = data.reports.length > 0;
            scope.ui.reportHasData = !!scope.activities.length;
            scope.reportDate = new Date();
        });
    }
}
