import { UnassociatedTag } from '@models/core/tag';

export interface Scan {
    created_at: string;
    created_at_formatted: string;
    created_by: string;
    id: number;
    is_pharmacist: boolean;
}

export interface ScanData {
    hardware_id?: number;
    scan_duration?: any;
    scan_results?: ScannedItem[];
    tags?: ScannedItem[];
}

export interface ScannedItem {
    antenna: string;
    epc?: string;
    repeat: string;
    rssi: string;
    tag_id?: string;
}

export class ScanError {
    associatedTags: any[] = [];
    closeScanModal: boolean = false;
    errorMessage: string = '';
    errorTranslated: string = '';
    dataProcessError: boolean = false;
    nothingDetectedError: boolean = false;
    unassociatedTagsError: boolean = false;
    unassociatedTags: UnassociatedTag[] = [];
    shelvedInventoryError: boolean = false;
}

export interface ScanResult {
    associated_tags: any[];
    bin_ids?: any[];
    contains_restricted_nephron_tags: any;
    id: number;
    kit_ids?: any[];
    payload_composition_type: string;
    scanError: ScanError;
    single_item_scan_epc?: any;
    unassociated_tags?: UnassociatedTag[];
    unknown_tags: boolean;
    view: string;
}

export interface DemoScan {
    label: string;
    state: string;
    params: any;
}
