import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';

@Injectable({ providedIn: 'root' })
export class LocationResolver {
    constructor(
        private $state: StateService,
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource
    ) {}

    resolve(): Promise<any> {
        const locationId = this.$state.transition.params().locationId;
        return this.loadingSpinnerService
            .spinnerifyPromise(this.locationResource.locationData(locationId))
            .then(({ location }) => {
                return location;
            });
    }
}
