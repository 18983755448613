import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ConfigurationProvider } from '@services/config/configuration';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {
    localeSet: boolean = false;

    constructor(
        private config: ConfigurationProvider,
        private hospitalInfoService: HospitalInfoService,
        private localStorageService: LocalStorageService,
        public translateService: TranslateService
    ) {}

    use(lang) {
        return this.translateService.use(lang);
    }

    get(translationKey: string | Array<string>, translationValues?: Object): Promise<any> {
        return this.translateService.get(translationKey, translationValues).toPromise();
    }

    setLocale(locale: string): void {
        if (this.config.getConfiguration().make_backwards === 'true') {
            this.makeBackwards();
            return;
        }

        this.localStorageService.set('NG_TRANSLATE_LANG_KEY', locale);
        moment.locale(locale.split('-')[0]);
        this.use(locale);

        // Set Angular 2 ngx-translate
        this.localeSet = true;
    }

    setToBrowserLocale(): void {
        const language = this.browserLanguage(); // use just language to simplify translation files
        this.setLocale(language);
    }

    setToHospitalLocale(): void {
        const hospitalLocale = this.hospitalInfoService.hospitalSetting('locale');
        if (hospitalLocale) {
            this.setLocale(hospitalLocale);
        }
    }

    makeBackwards() {
        // Force to `bl` if we're doing translation checking
        this.localStorageService.set('NG_TRANSLATE_LANG_KEY', 'bl');
        moment.locale('en');

        // Set Angular 2 ngx-translate
        this.localeSet = true;
    }

    translateInto(translationKey: string, obj: Object, objKey: string, values?: Object, quoteWrap?: boolean): void {
        this.get(translationKey, values).then((translation: string) => {
            translation = quoteWrap ? `"${translation}"` : translation;
            if (obj && objKey) {
                _.set(obj, objKey, translation);
            }
        });
    }

    inflect(translationKey: string, value: number): string {
        let finalKey = '' + translationKey + (value === 1 ? '_singular' : '_plural');
        return this.translateService.instant(finalKey, value);
    }

    instant(translationKey: string | Array<string>, translationValues?: Object) {
        return this.translateService.instant(translationKey, translationValues);
    }

    browserLocale(): string {
        const lang = navigator.language;

        const splitLang: string[] = (lang ? lang : 'en-US').split('-');
        if (splitLang[1]) {
            splitLang[1] = splitLang[1].toUpperCase(); // because some browsers have the locale all downcased
        }
        const normalizedLang: string = splitLang.join('-');

        return normalizedLang;
    }

    browserLanguage(): string {
        return this.browserLocale().split('-')[0];
    }

    language(): string {
        return this.localStorageService.get('NG_TRANSLATE_LANG_KEY') || this.browserLanguage();
    }
}
