import { Component, Inject, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { NgForm } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { KitMasterService } from '@services/core/kit-master.service';

import { KitMaster } from '@models/core/kit-master';

interface ParamData {
    kitMaster: KitMaster;
}

@Component({
    selector: 'archive-kit-master',
    templateUrl: './archive-kit-master-dialog.html',
    styleUrls: ['./archive-kit-master-dialog.scss'],
})
export class ArchiveKitMasterDialog {
    kitMaster: KitMaster;
    form: {
        id: number;
        name: string;
    };
    confirmArchive: false;

    @ViewChild('formItem') formItem: NgForm;

    constructor(
        private $state: StateService,
        public dialogRef: MatDialogRef<ArchiveKitMasterDialog>,
        private kitMasterResource: KitMasterResource,
        private kitMasterService: KitMasterService,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.kitMaster = this.data.kitMaster;
        this.form = {
            id: this.kitMaster.id,
            name: this.kitMaster.name,
        };
    }

    cancel() {
        this.dialogRef.close();
    }

    archive() {
        this.dialogRef.close(true);
    }
}
