import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Message } from '@models/core/message';

import { BroadcastMessageService } from '@services/login/broadcast-message.service';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { GainsightService } from '@services/vendor/gainsight.service';
import { RecallService } from '@services/core/recall.service.ts';

@Component({
    selector: 'message-board',
    templateUrl: './message-board.html',
    styleUrls: ['./message-board.scss'],
})
export class MessageBoard {
    @Input() messages: Message[] = [];
    @Input() loading: boolean = true;
    @Input() hasMessages: boolean = false;

    @Output() messageDismissed: EventEmitter<Message> = new EventEmitter();

    constructor(
        private broadcastMessageService: BroadcastMessageService,
        private dashboardResource: DashboardResource,
        private gainsightService: GainsightService,
        private recallService: RecallService
    ) {}

    ngOnInit() {
        const archivedMessages = this.messages.filter((message) => message.kits_archive_message);
        if (!!archivedMessages) {
            this.gainsightService.trackCustomEvent('Archive message');
        }
    }

    dismissMessage(messageToDismiss: Message) {
        let dismissPromise;
        if (messageToDismiss.recall_message) {
            dismissPromise = this.recallService.dismissRecallAlert(messageToDismiss.formulary_item_ids);
        } else if (messageToDismiss.active_recall_message) {
            dismissPromise = this.recallService.dismissActiveRecallAlert(messageToDismiss.recall_id);
        } else if (messageToDismiss.kits_archive_message) {
            dismissPromise = this.dashboardResource.dismissKitsArchiveMessage(messageToDismiss.id);
        } else {
            dismissPromise = this.broadcastMessageService.dismissMessage(messageToDismiss.id);
        }
        dismissPromise.then(() => {
            this.messageDismissed.emit(messageToDismiss);
        });
    }
}
