import { DoCheck, KeyValueDiffers, OnInit, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { HttpClient } from '@angular/common/http';
import { ScanRedirectionService } from '@services/core/scan-redirection.service';
import { StateService, Transition } from '@uirouter/core';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { BinTagPackageResource } from '@resources/bin-tag-package-resource.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { RecallResource } from '@resources/recall-resource.service';
import { ScanResource } from '@resources/scan-resource.service';
import { CanadianPackageVerificationService } from '@services/utils/canadian-package-verification.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { TranslationService } from '@services/utils/translation.service';
import { TableComponent } from '@components/common/table-component';
import { SortDirection } from '@services/utils/natural-sort.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { DecommissionPackagesDialog } from '@dialogs/decommission-packages/decommission-packages-dialog';
import { ConfirmDialog } from '@dialogs/confirm/confirm-dialog';
import { ChooseBinVerifyDialog } from '@dialogs/choose-bin-verify/choose-bin-verify-dialog';
import { isEmpty } from '@utils/objects';
import { titleize } from '@utils/strings';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';

export interface VerificationItem {
    kit_ids: Array<any>;
    bin_ids: Array<any>;
}

const REMOVE_FROM_INVENTORY = -1;
const REMOVE_FROM_BIN = -2;

@Component({
    selector: 'scan-batch-verify',
    templateUrl: './scan-batch-verify.html',
    styleUrls: ['./scan-batch-verify.scss'],
})
export class ScanBatchVerify extends TableComponent implements OnInit, DoCheck {
    private scanId: number;
    private status: string;
    private batchData: any;
    private verification_item: any;
    private item_package: any;
    private prefillBatchVerificationEnabled: boolean;
    private differ: any;
    private errorMsg: string;
    private bins: Array<any>;
    private currentBin: any;
    private binId: any;
    private binName: string;
    private expired: boolean;
    private newVerification: any;
    private isCanadianHospital: boolean;
    private tag_epcs: any;
    private tags: Array<any>;
    private tag_ids: Array<any>;
    private unknown_tags: boolean;
    private decommissionTagEpcs: any;
    private decommissionSuspectItems: any;
    private tooltip: string;
    private tooltipText: Function;
    private decommissionTooltip: string;
    private addToBinTooltip: string;
    private noBinsTooltip: string;
    private ndcMaxLength: number | 'null';
    private ui: any;
    private sizeValidationMessages = {};
    private doNotAddMsg: string;
    private removeFromShelvedInvMsg: string;
    private scanPromise;

    constructor(
        private $state: StateService,
        private $stateParams: Transition,
        private actionService: ActionService,
        private barcodeScanService: BarcodeScanService,
        @Inject(LOCAL_STORAGE) private localStorageService: WebStorageService,
        private scanRedirectionService: ScanRedirectionService,
        private formularyItemResource: FormularyItemResource,
        private binTagPackageResource: BinTagPackageResource,
        private recallResource: RecallResource,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private scanResource: ScanResource,
        private configuration: ConfigurationProvider,
        private canadianPackageVerificationService: CanadianPackageVerificationService,
        private translationService: TranslationService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private productModuleService: ProductModuleService,
        private differs: KeyValueDiffers,
        private http: HttpClient,
        private dialog: MatDialog
    ) {
        super();
        productModuleService.setModule(ModuleTypes.INVENTORY);

        this.scanId = $stateParams.params().scanId;
        this.status = 'loading';
        this.batchData = {};
        this.verification_item = {};
        this.verification_item.lotDisabled = false;
        this.verification_item.expireDisabled = false;
        this.verification_item.ndc = null;

        this.isCanadianHospital = this.hospitalInfoService.canadianHospital();

        this.item_package = {};
        this.currentBin = null;
        this.prefillBatchVerificationEnabled = this.hospitalInfoService.hospitalSetting(
            'prefill_batch_verification_enabled'
        );

        this.translationService
            .get([
                'batch_verify.are_you_trying_msg_tooltip',
                'batch_verify.verify_add_to_bin',
                'batch_verify.remove_from_shelved_inventory',
                'batch_verify.do_not_add_to_bin',
                'batch_verify.no_bins_for_item',
            ])
            .then((translations) => {
                this.decommissionTooltip = translations['batch_verify.are_you_trying_msg_tooltip'];
                this.addToBinTooltip = translations['batch_verify.verify_add_to_bin'];
                this.removeFromShelvedInvMsg = translations['batch_verify.remove_from_shelved_inventory'];
                this.doNotAddMsg = translations['batch_verify.do_not_add_to_bin'];
                this.noBinsTooltip = translations['batch_verify.no_bins_for_item'];
            });

        this.translationService
            .get([
                'manage_items.verification.errors.number',
                'manage_items.verification.errors.min',
                'manage_items.verification.errors.integer',
            ])
            .then((translations) => {
                this.sizeValidationMessages = {
                    //eslint-disable-next-line id-denylist
                    number: translations['manage_items.verification.errors.number'],
                    min: translations['manage_items.verification.errors.min'],
                    pattern: translations['manage_items.verification.errors.integer'],
                };
            });

        this.scanPromise = this.scanResource
            .scanData(this.scanId)
            .then((data) => {
                this.tags = data.associated_tags;
                this.tag_ids = this.tags.map((tag) => tag.id);
                this.tag_epcs = this.tags.map((tag) => tag.epc);
                if (data.view === 'scan_error') {
                    this.status = 'error';
                    this.errorMsg = titleize(data.payload_composition_type.split('_').join(' '));
                    return Promise.reject();
                } else if (data.view === 'batch_verification_scan') {
                    this.decommissionTagEpcs = '';
                    this.decommissionSuspectItems = [];
                    // For TableComponent
                    this.items = this.decommissionSuspectItems;
                    this.unknown_tags = !!data.unknown_tags;

                    return this.scanResource.getScanData(this.scanId);
                } else {
                    return Promise.reject(this.scanRedirectionService.redirectScan(data));
                }
            })
            .then((batchData) => {
                this.batchData = batchData;
                this.expired =
                    !!batchData.expiration_date_formatted && new Date(batchData.expiration_date_formatted) < new Date();
                this.tooltipText = () => {
                    if (!this.hasBins()) {
                        return 'There are no bins that accept this formulary item';
                    } else if (this.expired) {
                        return 'These items cannot be added to a bin because they are all expired';
                    }
                };
                this.decommissionTagEpcs = batchData.exceptions.decommissioned_tags;
                this.decommissionSuspectItems = batchData.exceptions.suspect_tags.items;
                // For TableComponent
                this.items = this.decommissionSuspectItems;
                if (this.hospitalAllowsBins()) {
                    return this.formularyItemResource
                        .bins(batchData.ndc, null)
                        .then(({ bins }) => {
                            this.bins = bins;
                            this.tooltip = this.tooltipText();
                            return bins;
                        })
                        .then(() => {
                            return this.getCurrentBinForItem(this.tag_epcs).then((result) => {
                                let bins: Array<any> = result.items.map((item: any) => item.current_bin);
                                this.currentBin = bins.length > 0 ? bins[0] : null;
                            });
                        });
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (this.prefillBatchVerificationEnabled) {
                    this.verification_item.expiration_date = this.batchData.expiration_date_formatted;
                    this.verification_item.lot_num = this.batchData.lot_num;
                    this.verification_item.quantity = this.batchData.package_count;
                    this.verification_item.ndc = this.batchData.ndc;
                    this.verification_item.item_txt =
                        this.batchData.item_name +
                        ' ' +
                        this.batchData.item_strength_formatted +
                        ' ' +
                        this.batchData.item_strength_uom;
                    if (this.verification_item.expiration_date === null) {
                        this.verification_item.expireDisabled = true;
                    }
                    if (this.verification_item.lot_num === null) {
                        this.verification_item.lotDisabled = true;
                    }
                }
                this.status = 'verify';
                setTimeout(() => {
                    $('#verify-ndc').focus();
                }, 100);
            })
            .catch((err) => {
                let message = err.message ? err.message : 'Scanner could not detect data';
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
            });

        this.barcodeScanService.registerListener((inputString: string) => {
            if ($('#verify-ndc').is(':focus')) {
                const scanInput: string = ndcScanUtilsService.extractNDCFromScan(inputString);

                $('#verify-ndc').blur();
                setTimeout(() => {
                    this.verification_item.ndc = scanInput;
                    $('#verify-ndc').val(scanInput);
                });
            }
        }, 'batch-verify');
    }

    ngOnInit() {
        this.loadingSpinnerService.spinnerifyPromise(this.scanPromise);
        this.defaultSort = { field: 'name', direction: SortDirection.asc }; // Set superclass sort.
        // Canadian max is 8. Otherwise -1 is no limit
        this.ndcMaxLength = this.isCanadianHospital ? 8 : 'null';
        this.defaultSort = { field: 'name', direction: SortDirection.asc };
        this.differ = this.differs.find(this.verification_item).create();
    }

    ngDoCheck() {
        if (!!this.isCanadianHospital) {
            // For Canadian hospitals only:
            // Subscribe to changes on ndc property; call
            // CanadianPackageVerificationService on changes, which
            // sets the 'package_types', model', and 'size' properties
            // in the current scope.
            const changes: any = this.differ.diff(this.verification_item);
            if (!!changes) {
                changes.forEachChangedItem((item: { key: string; value: any }) => {
                    if (item.key === 'ndc') {
                        this.canadianPackageVerificationService.debouncedSendSearchToAPI(this);
                    }
                });
            }
        }
    }

    getCurrentBinForItem(epcs: Array<string>): Promise<any> {
        let url: string = this.configuration.kcEndpointV1() + 'items/search/';

        return this.http.post(url, { epcs: epcs }).toPromise();
    }

    toggleLotNumDisabled(): void {
        this.verification_item.lotDisabled = !this.verification_item.lotDisabled;
    }

    toggleExpireDisabled(): void {
        this.verification_item.expireDisabled = !this.verification_item.expireDisabled;
    }

    sortKits(field: string) {
        this.sortBy(field, this.items);
    }

    onDateChange(result: Date): void {
        this.verification_item.expiration_date = result;
    }

    itemInBin(currentBin: any, bins: Array<any>): boolean {
        if (!currentBin) {
            return false;
        } else {
            return !!bins && bins.length > 0 && bins.map((bin) => bin.id).includes(currentBin.id);
        }
    }

    hospitalAllowsBins = (): boolean => {
        return this.hospitalInfoService.allowShelvedInventory();
    };

    binsDataLoading = (): boolean => {
        return !this.bins;
    };

    hasBins = (): boolean => {
        if (this.bins) {
            return this.bins.length > 0;
        }

        return false;
    };

    disableBinsButton = (): boolean => {
        return this.binsDataLoading() || !this.hasBins() || this.expired;
    };

    resetScan = (): void => {
        this.$state.go('home');
    };

    statusError = (): boolean => {
        return this.status === 'error';
    };

    statusVerify = (): boolean => {
        return (
            this.status === 'verify' &&
            this.verification_item &&
            !this.newVerification &&
            this.actionService.isAllowAction('kits_tagging', 'verify_batch_scan', 'Show Batch Verify scan message')
        );
    };

    isValidVerification = (): boolean => {
        if (this.isCanadianHospital) {
            return (
                this.verification_item &&
                (this.verification_item.expireDisabled || this.verification_item.expiration_date) &&
                (this.verification_item.lotDisabled || this.verification_item.lot_num) &&
                this.verification_item.quantity &&
                this.verification_item.ndc &&
                this.item_package &&
                this.item_package.size &&
                this.item_package.model
            );
        } else {
            return (
                this.verification_item &&
                (this.verification_item.expireDisabled || this.verification_item.expiration_date) &&
                (this.verification_item.lotDisabled || this.verification_item.lot_num) &&
                this.verification_item.quantity &&
                this.verification_item.ndc
            );
        }
    };

    async checkForRecalls(): Promise<any> {
        if (!this.verification_item.lotDisabled && !this.verification_item.expireDisabled) {
            const params = {
                ndc: this.verification_item.ndc,
                lot_number: this.verification_item.lot_num,
            };

            let data = await this.recallResource.recallSearch(params);

            if (!isEmpty(data.recalls)) {
                return await this.confirmVerification();
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    }

    async confirmVerification(): Promise<any> {
        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('modals.confirm_batch_verify.recalled_item'),
                description: this.translationService.instant('modals.confirm_batch_verify.item_has_been_recalled'),
                okButton: this.translationService.instant('modals.confirm_batch_verify.verify_anyway'),
            },
        });

        let result = confirmDialog
            .afterClosed()
            .toPromise()
            .then((confirmed) => {
                if (confirmed) {
                    return Promise.resolve();
                } else {
                    return Promise.reject({
                        message: 'Verification cancelled.',
                    });
                }
            });
        return result;
    }

    verify(): void {
        if (!this.hospitalAllowsBins()) {
            this.submitVerification(); // Hospital doesn't use bins (no 'allowShelvedInventory' setting), so just verify.
        } else if (!this.bins || this.bins.length < 1) {
            this.submitVerification(); // Hospital doesn't have any bins accepting this item, so just verify.
        } else {
            this.chooseBin();
        }
    }

    chooseBin = (): void => {
        let bins: Array<any> = [...this.bins]; // Clone bins
        // Is item in a bin, or not in a bin?
        if (this.itemInBin(this.currentBin, this.bins)) {
            // It's in a bin; give option to remove from inventory.
            bins.push({
                id: REMOVE_FROM_INVENTORY,
                name: this.removeFromShelvedInvMsg,
            });
        } else {
            // It's not in a bin; give option to leave out of bin.
            bins.push({ id: REMOVE_FROM_BIN, name: this.doNotAddMsg });
        }

        const verifyDialog = this.dialog.open(ChooseBinVerifyDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                bins: bins,
                currentBin: this.currentBin,
            },
        });

        let result = verifyDialog.afterClosed().subscribe((data) => {
            if (!!data) {
                this.binId = data.bin_id;
                if (data.bin_id === REMOVE_FROM_INVENTORY) {
                    // Remove item from shelved inv.
                    this.verifyAndRemoveFromBin(this.currentBin.id);
                } else if (data.bin_id === REMOVE_FROM_BIN) {
                    // Remove item from bins.
                    this.submitVerification(); // In this case, the item wasn't previously in a bin.
                } else {
                    this.verifyAndAddItemToBin(this.binId);
                }
            }
        });
    };

    verifyAndRemoveFromBin = (binId: number) => {
        let tagIds: Array<number> = this.tags.map((tag) => tag.id);
        let tagIdsParam: string = tagIds.join('|');

        if (!!binId) {
            let bin: Array<any> = this.bins.filter((bin: any) => bin.id === binId);
            this.binName = bin[0].name;
        }
        this.checkForRecalls()
            .then(() => {
                if (this.verification_item.lotDisabled) {
                    this.verification_item.lot_num = null;
                }

                if (this.verification_item.expireDisabled) {
                    this.verification_item.expiration_date = null;
                } else {
                    this.verification_item.expiration_date = moment(this.verification_item.expiration_date).format(
                        'YYYY-MM-DD'
                    );
                }

                const data = _.pick(this.verification_item, 'lot_num', 'expiration_date', 'ndc', 'quantity');

                if (this.isCanadianHospital) {
                    _.defaults(data, {
                        package_size: this.item_package.size,
                        package_size_uom: this.item_package.model.uom,
                        package_description_id: this.item_package.model.description_id,
                    });
                }

                return this.scanResource.scanVerification(this.scanId, data);
            })
            .then((data) => {
                this.binTagPackageResource
                    .removeBinTagPackage(binId, tagIdsParam)
                    .then((data) => {
                        this.$state.go('home', {
                            transferredMessage: this.binName,
                            newVerification: 'batch-verify-remove',
                        });
                    })
                    .catch((err) => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'verify_and_add_to_bin.items_were_verified_but_not_removed_from_bin',
                            params: { bin: this.binName },
                        });
                    });
            })
            .catch((err) => {
                if (err.message) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                } else {
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'verify_and_add_to_bin.items_were_not_verified_and_not_removed_from_bin',
                        params: { bin: this.binName },
                    });
                }
            });
    };

    verifyAndAddItemToBin = (binId: number): void => {
        if (this.binId) {
            let bin: Array<any> = this.bins.filter((bin: any) => bin.id === this.binId);
            this.binName = bin[0].name;
        }
        this.checkForRecalls()
            .then(() => {
                if (this.verification_item.lotDisabled) {
                    this.verification_item.lot_num = null;
                }

                if (this.verification_item.expireDisabled) {
                    this.verification_item.expiration_date = null;
                } else {
                    this.verification_item.expiration_date = moment(this.verification_item.expiration_date).format(
                        'YYYY-MM-DD'
                    );
                }

                const data = _.pick(this.verification_item, 'lot_num', 'expiration_date', 'ndc', 'quantity');

                if (this.isCanadianHospital) {
                    _.defaults(data, {
                        package_size: this.item_package.size,
                        package_size_uom: this.item_package.model.uom,
                        package_description_id: this.item_package.model.description_id,
                    });
                }
                return this.scanResource.scanVerification(this.scanId, data);
            })
            .then((data) => {
                this.binTagPackageResource
                    .addBinTagPackage(binId, this.tag_epcs)
                    .then((data) => {
                        this.$state.go('home', {
                            transferredMessage: this.binName,
                            newVerification: 'batch-verify',
                        });
                    })
                    .catch((err) => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                            key: 'verify_and_add_to_bin.items_were_verified_but_not_added_to_bin',
                            params: { bin: this.binName },
                        });
                    });
            })
            .catch((err) => {
                if (err.message) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                } else {
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'verify_and_add_to_bin.items_were_not_verified_and_not_added_to_bin',
                        params: { bin: this.binName },
                    });
                }
            });
    };

    submitVerification = (): void => {
        this.checkForRecalls()
            .then(() => {
                if (this.verification_item.lotDisabled) {
                    this.verification_item.lot_num = null;
                }

                if (this.verification_item.expireDisabled) {
                    this.verification_item.expiration_date = null;
                } else {
                    this.verification_item.expiration_date = moment(this.verification_item.expiration_date).format(
                        'YYYY-MM-DD'
                    );
                }

                const data = _.pick(this.verification_item, 'lot_num', 'expiration_date', 'ndc', 'quantity');

                if (this.isCanadianHospital) {
                    _.defaults(data, {
                        package_size: this.item_package.size,
                        package_size_uom: this.item_package.model.uom,
                        package_description_id: this.item_package.model.description_id,
                    });
                }

                return this.scanResource.scanVerification(this.scanId, data);
            })
            .then((data) => {
                if (data.items_transferred) {
                    this.$state.go('home', {
                        transferredMessage: data.items_transferred,
                        newVerification: 'verify',
                    });
                }
                this.$state.go('home', {
                    transferredMessage: null,
                    newVerification: 'verify',
                });
            })
            .catch((err) => {
                if (err.message) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                } else {
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                        key: 'batch_verify.server_could_not_verify_your_items',
                        params: {
                            quantity: this.verification_item.quantity,
                        },
                    });
                }
            });
    };

    allowDecommission = (): boolean => {
        return this.actionService.isAllowAction('kits_inventory', 'decommission_tag', 'Show Decommission option');
    };

    decommission = (): void => {
        this.scanResource
            .detailScan(this.scanId)
            .then((data) => {
                return data.packages;
            })
            .then((packages) => {
                const modalDialog = this.dialog.open(DecommissionPackagesDialog, {
                    width: '1000px',
                    height: 'max-content',
                    data: { packages: packages, reason: undefined },
                });

                modalDialog.afterClosed().subscribe((decomReason) => {
                    if (!decomReason) {
                        return;
                    } else {
                        const reason = decomReason;
                        let msgKey;
                        if (reason.name === 'used_outside') {
                            msgKey = 'batch_verify.decommission_success_msg_one';
                        } else {
                            msgKey = 'batch_verify.decommission_success_msg_two';
                        }
                        let msg = this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: msgKey,
                            params: { reason: reason.display_name },
                        });
                        msg.afterOpened().subscribe(() => {
                            this.$state.go('home');
                        });
                    }
                });
            });
    };
}
