import { Component, Input } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { NgForm } from '@angular/forms';

import { ActionService } from '@services/utils/action.service';

import { AssignableForm } from '../assignable-form/assignable-form';
import { BarcodeImgComponent } from '@components/common/barcode-img/barcode-img';
import { BarcodeScanField } from '@components/common/barcode-scan-field/barcode-scan-field';
import { CommonCRUDAPIService } from '@services/core/common-crud-api.service';
import { TranslationService } from '@services/utils/translation.service';
import { MatDialog } from '@angular/material/dialog';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { AdminLocation } from '@models/admin/admin-location';

@Component({
    selector: 'admin-locations-create',
    templateUrl: './admin-locations-create.html',
    styleUrls: ['./admin-locations-create.scss'],
})
export class AdminLocationsCreate extends AssignableForm {
    @Input() location: AdminLocation;

    canSubmitAsset: boolean;
    editMode: boolean;
    eventListeners: { (): void }[];

    constructor(
        protected $state: StateService,
        protected $stateParams: Transition,
        protected actionService: ActionService,
        protected barcodeScanService: BarcodeScanService,
        protected commonCRUDAPIService: CommonCRUDAPIService,
        protected dialog: MatDialog,
        protected translationService: TranslationService
    ) {
        super($state, actionService, barcodeScanService, commonCRUDAPIService, dialog, translationService);
        this.assignType = 'location';
        this.editMode = false;
        this.canSubmitAsset = true;
    }

    ngOnInit(): void {
        this.assignObject = this.location;
        super.ngOnInit();
        this.editMode = !!this.$stateParams.params().locationId;
    }

    remove(): void {
        this.confirmRemove(this.location);
    }

    assetWasSelected(name: string, id: string): void {
        this.canSubmitAsset = true;
    }
}
