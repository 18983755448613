import { Input, OnInit, Component, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'tag-add-to-bin',
    templateUrl: './tag-add-to-bin.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagAddToBin {
    @Input() tag;
    @Input() pickedItem;

    @Output() tagChange = new EventEmitter();

    itemText: string;
    bin;

    constructor(private translationService: TranslationService) {}

    ngOnInit() {
        this.itemText = this.translationService.inflect('common.item', this.tag.quantity).toLowerCase();
    }

    changeBin(event) {
        this.bin = event;
        this.tag.addToBin = event !== 'none' ? event : null;
        this.tagChange.emit(this.tag);
    }
}
