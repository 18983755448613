import { Injectable } from '@angular/core';
import { ConfigurationProvider } from '../config/configuration';
import { HttpClient } from '@angular/common/http';
import { BadgeInfo } from '../../models/core/badge-authorization';

@Injectable()
export class BadgeVerificationResource {
    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    API_ENDPOINT = this.configuration.kcEndpointV1();

    verifyBadgePin(badgeCredentials: BadgeInfo): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}badge_verifications`, badgeCredentials).toPromise();
    }
}
