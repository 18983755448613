import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

import { HardwareService } from '@services/hardware/hardware.service';
import { AeroscoutService } from '@services/hardware/aeroscout.service';

// description and detail can contain html
interface ParamData {
    url: string;
    okButton: string;
}

@Component({
    selector: 'aeroscout-dialog',
    templateUrl: './aeroscout-dialog.html',
})
export class AeroscoutDialog {
    url: string;
    urlUnreachable: boolean = false;
    urlInvalid: boolean = false;
    scanner: any;
    spinner: boolean = false;

    constructor(
        private hardwareService: HardwareService,
        private aeroscoutService: AeroscoutService,
        public dialogRef: MatDialogRef<AeroscoutDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.url = this.data.url;

        this.hardwareService.getAnOnlineScanner().then((scanner) => {
            this.scanner = scanner;
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onOk() {
        let url = this.url;
        this.urlUnreachable = false;
        let callback = (response) => {
            if (url === this.url) {
                // success response doesn't have `status`
                // this will also be true when the scanner is disconnected
                //  allow for 405 - the status code we get from dummy scanners (local development / tests)
                if (response.status === 405 || response.status === 401 || !response.status) {
                    this.dialogRef.close(this.url);
                } else {
                    this.urlUnreachable = true;
                }
            }
        };

        if (!!this.url.match(/^http[s]?:\/\/*/)) {
            this.urlInvalid = false;
            this.spinner = true;
            if (this.scanner) {
                this.aeroscoutService.aeroscoutLogin(this.url).then(callback).catch(callback);
            } else {
                callback({});
            }
        } else {
            this.urlInvalid = true;
        }
    }
}
