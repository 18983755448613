import { Injectable } from '@angular/core';
import { ReportScopeToDataService } from './report-scope-to-data.service';
import { ReportResource } from '@resources/report-resource.service';
import { cleanDate } from '@utils/objects';

const reportNameItemsTagged: string = 'itemsTagged';

@Injectable()
export class ItemsTaggedReportService {
    constructor(
        private reportResource: ReportResource,
        private reportScopeToData: ReportScopeToDataService
    ) {}

    detectReport(reportName: string): boolean {
        return reportName === reportNameItemsTagged;
    }

    reportData(scope: any): any {
        return this.reportScopeToData.dateRange(scope);
    }

    setFilterDefaults(scope: any): void {
        scope.showKitMasterFilter = false;
        scope.showNDCFilter = false;
        scope.showLotNumberFilter = false;
        scope.showExpiresInFilter = false;
        scope.showIncludeExpiredFilter = false;
        scope.showDateFilter = true;
        scope.showClear = false;

        if (scope.filters.end_date) {
            scope.filters.end_date = cleanDate(scope.filters.end_date);
        } else {
            scope.filters.end_date = new Date();
            scope.filters.end_date.setHours(0, 0, 0, 0);
        }

        scope.filters.start_date =
            cleanDate(scope.filters.start_date) ||
            new Date(
                scope.filters.end_date.getFullYear(),
                scope.filters.end_date.getMonth(),
                scope.filters.end_date.getDate() - 30
            );
    }

    refreshReport(scope: any, data: any): Promise<any> {
        return this.reportResource.itemsTaggedReport(data).then((data: any): void => {
            scope.ui.reportRunned = true;
            scope.activities = data.reports;
            scope.hasAnyData = data.reports.length > 0;
            scope.ui.reportHasData = !!scope.activities.length;
            scope.reportDate = new Date();
        });
    }
}
