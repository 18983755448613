import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TableComponent } from '@components/common/table-component';
import { SortDirection } from '@services/utils/natural-sort.service';

@Component({
    selector: 'bin-summary',
    templateUrl: './bin-summary.html',
    styleUrls: ['./bin-summary.scss'],
})
export class BinSummary extends TableComponent {
    itemsBy: 'generic' | 'formulary';
    @Input() binScan;
    @Input() formulary: Array<any>;
    @Input() custom: Array<any>;
    @Input() wrong: Array<any>;

    constructor() {
        super();
    }
    ngOnInit() {
        this.sortBy('name', this.items);
        this.defaultSort = { field: 'name', direction: SortDirection.asc };
        this.itemsBy = 'generic';
    }

    sortGenericItems(field: string): void {
        this.sortBy(field, this.items);
    }

    showCustom(): any {
        return Array.isArray(this.custom) && this.custom.length > 0;
    }

    showGeneric(): any {
        return Array.isArray(this.items) && this.items.length > 0;
    }
}
