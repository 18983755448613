import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { PagingParams } from '@models/core/paging-params';
import { stringify } from 'query-string';
@Injectable()
export class KitScanResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    kitScanListPaged(kitId: number, paging: PagingParams): Promise<any> {
        const stringifiedParams = stringify(paging);
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans?${stringifiedParams}`).toPromise();
    }

    kitSummary(kitId: number, scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans/${scanId}/summary`).toPromise();
    }

    kitLatestSummary(kitId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}kits/${kitId}/scans/latest/summary`).toPromise();
    }
}
