import {
    Component,
    Inject,
    AfterViewInit,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewChildren,
    ElementRef,
    QueryList,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationService } from '@services/system/application.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { TranslationService } from '@services/utils/translation.service';

import { BarcodeResource } from '@resources/barcode-resource.service';
import { BadgeVerificationResource } from '@resources/badge-verification-resource.service';

import { BadgeInfo } from '@models/core/badge-authorization';
import { BehaviorSubject } from 'rxjs';

interface DialogData {
    badgeOnly: any;
    httpRejection: any;
}

@Component({
    selector: 'badge-authorization-dialog',
    templateUrl: './badge-authorization-dialog.html',
    styleUrls: ['./badge-authorization-dialog.scss'],
})
export class BadgeAuthorizationDialog implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('pinInput') pinInput: QueryList<ElementRef>;
    form: BadgeInfo;
    inputStep: string = 'badge';
    errorMessage: string = null;
    userName: string;
    inputStepObserver: BehaviorSubject<string> = new BehaviorSubject(this.inputStep);
    isLoading: boolean = false;

    constructor(
        private applicationService: ApplicationService,
        private badgeVerificationResource: BadgeVerificationResource,
        private barcodeResource: BarcodeResource,
        private barcodeScanService: BarcodeScanService,
        private cdRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<BadgeAuthorizationDialog>,
        private localStorageService: LocalStorageService,
        private translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.userName = this.applicationService.user.formatted_name;
        this.form = {
            epc: '',
            pin: '',
        };

        if (this.data?.httpRejection?.reason_code === 'bad_credentials') {
            this.errorMessage = this.translationService.instant('modals.badge_authorization.incorrect_pin');
        }

        this.barcodeScanService.registerListener(this.handleScannedBadge, 'verify-badge-pin');
    }

    ngAfterViewInit() {
        this.pinInput.changes.subscribe(() => {
            if (this.pinInput.length > 0) {
                // focus on pinInput once it appears
                this.pinInput.first.nativeElement.focus();
            }
        });
    }

    ngOnDestroy() {
        this.barcodeScanService.removeListener('verify-badge-pin');
    }

    verifyCredentials() {
        this.errorMessage = null;
        this.badgeVerificationResource.verifyBadgePin(this.form).then((response) => {
            if (response.valid) {
                this.isLoading = true;
                this.barcodeScanService.removeListener('verify-badge-pin');
                this.dialogRef.close(this.form);
            } else {
                this.errorMessage = this.translationService.instant('modals.badge_authorization.incorrect_pin');
            }
            this.cdRef.detectChanges();
        });
    }

    handleScannedBadge = (scanData) => {
        this.barcodeResource
            .barcodeObject(scanData)
            .then((data) => {
                if (data?.object?.class === 'User') {
                    if (this.checkUserBadge(data)) {
                        this.form.epc = scanData;
                        if (!this.data.badgeOnly) {
                            this.inputStep = 'pin';
                            this.inputStepObserver.next(this.inputStep);
                            this.errorMessage = null;
                        } else {
                            this.dialogRef.close(this.form);
                        }
                    } else {
                        this.errorMessage = this.translationService.instant(
                            'modals.badge_authorization.other_users_badge'
                        );
                    }
                }
            })
            .finally(() => {
                this.cdRef.detectChanges();
            });
    };

    checkUserBadge(data) {
        const userId = this.localStorageService.get('userId');
        return data?.object?.id === parseInt(userId);
    }
}
