import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'base-label',
    templateUrl: './base-label.html',
    styleUrls: ['./base-label.scss'],
})
export class BaseLabel implements OnInit {
    // Data
    @Input() chargeData;
    @Input() kitSummary;

    @Input() customTemplate: TemplateRef<any>;

    constructor() {}

    ngOnInit() {}
}
