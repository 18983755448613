import { Component, Inject, ViewChild, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

import { SatPopover } from '@ncstate/sat-popover';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { SegmentResource } from '@resources/segment-resource.service';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { FormularyItem } from '@models/core/formulary/formulary-item';
import { Segment } from '@models/core/segment';
import { SegmentTemplate, SegmentTemplateFormularyItem, SegmentTemplateCEQ } from '@models/core/segment-template';
import { ContainerResource } from '@resources/container-resource.service';

interface STEDFormularyItem extends FormularyItem {
    disable: boolean;
    inCurrentTemplate: boolean;
    inKitMaster: boolean;
    inOtherTemplate: boolean;
}

export enum SegmentResourceType {
    kitMaster,
    container,
}

interface SegmentTemplateData {
    formularyItems: STEDFormularyItem[];
    segment: Segment;
    segments: Segment[];
    segmentTemplate: SegmentTemplate;
    resource: SegmentResourceType;
    containerId?: number;
    insertMode?: boolean;
    segmentEditingMode?: boolean;
}

interface CEQHash {
    clinical_equivalence_id: number;
    capacity: {
        scalar: number | string;
        uom: string;
    };
}

@Component({
    selector: 'segment-template-edit-dialog',
    templateUrl: './segment-template-edit-dialog.html',
    styleUrls: ['./segment-template-edit-dialog.scss'],
})
export class SegmentTemplateEditDialog implements AfterViewInit, OnInit {
    formularyItems: STEDFormularyItem[];
    segment: Segment;
    segments: Segment[];
    segmentTemplate: SegmentTemplate;

    showShortageSlider: boolean = false;
    newTemplate: boolean;
    newSegment: boolean;
    saving: boolean = false;
    scanning: boolean = true;
    modalTitle: string;
    shortageMin: number;
    shortageMax: number;
    shortageRangeModified: boolean;
    currentSelectedItem: STEDFormularyItem;
    formularyItemSearch: string = '';
    currentPopover: SatPopover;
    sliderOptions: Options;
    showSlider: boolean = false;

    cannotSelectGeneric: boolean = false;

    formularyItemsDataSource: MatTableDataSourceWithNaturalSort<FormularyItem>;
    formularyItemsDisplayedColumns: string[] = [
        'ndc',
        'manufacturer_name',
        'item_name',
        'strength',
        'package',
        'metadata',
    ];
    @ViewChild('formularyItemsSort') formularyItemsSort: MatSort;
    stFormularyItemsDataSource: MatTableDataSourceWithNaturalSort<SegmentTemplateFormularyItem>;
    stFormularyItemsDisplayedColumns: string[] = [
        'ndc',
        'manufacturer_name',
        'item_name',
        'strength',
        'package',
        'remove',
    ];
    @ViewChild('stFormularyItemsSort') stFormularyItemsSort: MatSort;
    stGenericItemsDataSource: MatTableDataSourceWithNaturalSort<SegmentTemplateCEQ>;
    stGenericItemsDisplayedColumns: string[] = ['item_name', 'strength', 'package', 'remove'];
    @ViewChild('stGenericItemsSort') stGenericItemsSort: MatSort;

    @ViewChild('nameForm') nameForm: NgForm;
    @ViewChild('valueForm') valueForm: NgForm;

    constructor(
        private barcodeScanService: BarcodeScanService,
        public dialogRef: MatDialogRef<SegmentTemplateEditDialog>,
        private kcMatSnackBarService: KCMatSnackBarService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private segmentResource: SegmentResource,
        private segmentTemplateResource: SegmentTemplateResource,
        private $state: StateService,
        private translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: SegmentTemplateData,
        private containerResource: ContainerResource
    ) {}

    ngOnInit() {
        this.newTemplate = !this.data.segmentTemplate;
        this.newSegment = !this.data.segment;

        this.formularyItems = _.clone(this.data.formularyItems);

        this.segment = this.newSegment ? ({} as Segment) : _.clone(this.data.segment);
        this.segmentTemplate = this.newTemplate ? ({} as SegmentTemplate) : _.cloneDeep(this.data.segmentTemplate);
        this.segmentTemplate.formulary_items = this.newTemplate
            ? []
            : _.cloneDeep(this.data.segmentTemplate.formulary_items);
        this.segmentTemplate.clinical_equivalences = this.newTemplate
            ? []
            : _.cloneDeep(this.data.segmentTemplate.clinical_equivalences);

        if (this.newSegment) {
            this.modalTitle = this.translationService.instant('segment_template_modal.title.new_segment');
        } else if (this.newTemplate) {
            this.modalTitle = this.translationService.instant('segment_template_modal.title.add_alternate', {
                segmentName: this.segment.name,
            });
        } else {
            const alternate = this.translationService.instant('segment_template_modal.alternate');
            const preferred = this.translationService.instant('segment_template_modal.preferred');
            const optionType =
                this.newTemplate || !this.segmentTemplate.primary_segment_template ? alternate : preferred;
            this.modalTitle = this.translationService.instant('segment_template_modal.title.edit_for', {
                segmentName: this.segment.name,
                optionType,
            });
        }

        if (!!this.segmentTemplate.shortage_quantity_min && !!this.segmentTemplate.shortage_quantity_max) {
            this.toggleShowShortage();
        }

        this.shortageMin = this.segmentTemplate.shortage_quantity_min || 0;

        if (this.segmentTemplate.shortage_quantity_max === 0) {
            this.shortageMax = 0;
        } else {
            this.shortageMax = this.segmentTemplate.shortage_quantity_max || this.segmentTemplate.quantity || 0;
        }

        this.sliderOptions = {
            floor: 0,
            ceil: this.segmentTemplate.quantity,
        };

        this.scanning = true;

        this.barcodeScanService.registerListener((scanData) => {
            if (!this.scanning) {
                return;
            }
            this.formularyItemSearch = this.ndcScanUtilsService.extractNDCFromScan(scanData);
            this.applyFilter();
        }, 'segment-template-edit-dialog');

        this.refreshFormularyItems();
    }

    ngAfterViewInit(): void {
        this.formularyItemsDataSource = new MatTableDataSourceWithNaturalSort(this.formularyItems);
        this.formularyItemsDataSource.sort = this.formularyItemsSort;
        this.stFormularyItemsDataSource = new MatTableDataSourceWithNaturalSort(this.segmentTemplate.formulary_items);
        this.stFormularyItemsDataSource.sort = this.stFormularyItemsSort;
        this.stGenericItemsDataSource = new MatTableDataSourceWithNaturalSort(
            this.segmentTemplate.clinical_equivalences
        );
        this.stGenericItemsDataSource.sort = this.stGenericItemsSort;

        // this is hacky, but the only way I've found to make the slider draw properly initially
        setTimeout(() => {
            this.showSlider = true;
        }, 500);
    }

    sliderChanged(): void {
        this.shortageRangeModified = true;
    }

    toggleShowShortage(): void {
        this.showShortageSlider = !this.showShortageSlider;
        this.sliderChanged();
    }

    quantityUpdated(): void {
        this.shortageMax =
            this.segmentTemplate.quantity < this.shortageMax ? this.segmentTemplate.quantity : this.shortageMax;
        this.shortageMin =
            this.segmentTemplate.quantity < this.shortageMin ? this.segmentTemplate.quantity : this.shortageMin;
    }

    applyFilter(): void {
        this.formularyItemsDataSource.filter = this.formularyItemSearch;
    }

    clearSearch(): void {
        this.formularyItemSearch = '';
        this.applyFilter();
    }

    refreshFormularyItems(): void {
        this.formularyItems.forEach((formularyItem) => {
            formularyItem.inCurrentTemplate =
                this.formularyItemInTemplate(this.segmentTemplate, formularyItem) ||
                this.clinicalEquivalenceInTemplate(this.segmentTemplate, formularyItem);

            formularyItem.inKitMaster =
                !!this.segments &&
                this.segments.some((checkSegment) => {
                    return (
                        (!this.segment || checkSegment.id !== this.segment.id) &&
                        (this.formularyItemInSegment(checkSegment, formularyItem) ||
                            this.clinicalEquivalenceInSegment(checkSegment, formularyItem))
                    );
                });

            formularyItem.inOtherTemplate =
                this.newSegment && !this.data.segmentEditingMode
                    ? false
                    : this.formularyItemInSegment(this.segment, formularyItem) ||
                      this.clinicalEquivalenceInSegment(this.segment, formularyItem);

            formularyItem.disable = formularyItem.inCurrentTemplate || formularyItem.inKitMaster;
        });
        if (!!this.stFormularyItemsDataSource) {
            this.stFormularyItemsDataSource.data = this.segmentTemplate.formulary_items;
        }
        if (!!this.stGenericItemsDataSource) {
            this.stGenericItemsDataSource.data = this.segmentTemplate.clinical_equivalences;
        }
    }

    formularyItemInTemplate(segmentTemplate: SegmentTemplate, formularyItem: STEDFormularyItem): boolean {
        return !!segmentTemplate.formulary_items.find((fi) => fi.formulary_item_id === formularyItem.formulary_item_id);
    }

    clinicalEquivalenceInTemplate(segmentTemplate: SegmentTemplate, formularyItem: STEDFormularyItem): boolean {
        return (
            !!formularyItem &&
            !!formularyItem.clinical_equivalence_id &&
            !!formularyItem.package_size_formatted &&
            !!formularyItem.package_size_uom &&
            !!segmentTemplate.clinical_equivalences.find((ceq: any) => {
                return (
                    ceq.clinical_equivalence_id === formularyItem.clinical_equivalence_id &&
                    ceq.package_size_formatted === formularyItem.package_size_formatted &&
                    ceq.package_size_uom.toLowerCase() === formularyItem.package_size_uom.toLowerCase()
                );
            })
        );
    }

    formularyItemInSegment(checkSegment: Segment, formularyItem: STEDFormularyItem): boolean {
        return checkSegment.segment_templates.some((segmentTemplate) => {
            return (
                segmentTemplate.id !== this.segmentTemplate.id &&
                this.formularyItemInTemplate(segmentTemplate, formularyItem)
            );
        });
    }

    clinicalEquivalenceInSegment(checkSegment: Segment, formularyItem: STEDFormularyItem): boolean {
        return checkSegment.segment_templates.some((segmentTemplate) => {
            return (
                segmentTemplate.id !== this.segmentTemplate.id &&
                this.clinicalEquivalenceInTemplate(segmentTemplate, formularyItem)
            );
        });
    }

    formularyItemIDs(template: SegmentTemplate): number[] {
        return (template.formulary_items || []).map((item) => item.formulary_item_id).sort();
    }

    clinicalEquivalenceHashes(template: SegmentTemplate): CEQHash[] {
        return (template.clinical_equivalences || []).map((ceq) => {
            return {
                clinical_equivalence_id: ceq.clinical_equivalence_id,
                capacity: {
                    scalar: ceq.package_size_formatted,
                    uom: ceq.package_size_uom,
                },
            };
        });
    }

    disableSelectGeneric(): boolean {
        return (
            this.currentSelectedItem &&
            (!this.currentSelectedItem.clinical_equivalence_id ||
                _.isUndefined(this.currentSelectedItem.package_size) ||
                _.isNull(this.currentSelectedItem.package_size) ||
                !this.currentSelectedItem.package_size_uom)
        );
    }

    selectForAdding(formularyItem: STEDFormularyItem, popover: SatPopover): void {
        if (formularyItem.disable) {
            return;
        }

        if (!!this.currentPopover) {
            this.currentPopover.close();
        }

        if (popover !== this.currentPopover) {
            popover.open();
            this.currentPopover = popover;
        } else {
            this.currentPopover = undefined;
        }

        if (this.currentSelectedItem === formularyItem) {
            this.currentSelectedItem = undefined;
        } else {
            this.currentSelectedItem = formularyItem;
        }

        this.cannotSelectGeneric = !!this.disableSelectGeneric();
    }

    addGenericToSegmentTemplate(formularyItem: STEDFormularyItem): void {
        if (formularyItem.disable || this.disableSelectGeneric()) {
            return;
        }

        const ceq = {
            clinical_equivalence_id: formularyItem.clinical_equivalence_id,
            item_name: formularyItem.clinical_equivalence_name,
            item_strength_formatted: formularyItem.item_strength_formatted,
            item_strength_uom: formularyItem.item_strength_uom,
            package_size_formatted: formularyItem.package_size_formatted,
            package_size_uom: formularyItem.package_size_uom,
        };

        this.segmentTemplate.clinical_equivalences.push(ceq);
        this.currentSelectedItem = undefined;
        this.closePopover();
        this.refreshFormularyItems();
    }

    addToSegmentTemplate(formularyItem: STEDFormularyItem): void {
        if (formularyItem.disable) {
            return;
        }

        this.segmentTemplate.formulary_items.push(formularyItem);
        this.currentSelectedItem = undefined;
        this.closePopover();
        this.refreshFormularyItems();
    }

    closePopover(): void {
        this.currentPopover.close();
        this.currentPopover = undefined;
    }

    removeFromSegmentTemplate(formularyItem: STEDFormularyItem): void {
        this.segmentTemplate.formulary_items = _.without(this.segmentTemplate.formulary_items, formularyItem);
        this.refreshFormularyItems();
    }

    removeCeqFromSegmentTemplate(clinicalEquivalence: SegmentTemplateCEQ): void {
        this.segmentTemplate.clinical_equivalences = _.without(
            this.segmentTemplate.clinical_equivalences,
            clinicalEquivalence
        );
        this.refreshFormularyItems();
    }

    private hasSegmentModifications() {
        return (
            this.segment.name &&
            this.segment.name.length &&
            this.segmentTemplate.quantity !== undefined &&
            !isNaN(this.segmentTemplate.quantity)
        );
    }

    private hasSegmentTemplateModifications() {
        return (
            this.segmentTemplate.billing_code !== '' ||
            this.segmentTemplate.quantity !== undefined ||
            this.formularyItemIDs(this.segmentTemplate).length > 0 ||
            this.clinicalEquivalenceHashes(this.segmentTemplate).length > 0
        );
    }

    private hasPreferredFillOptionsModifications() {
        return (
            this.billingCodeChanged() ||
            this.segmentTemplate.quantity !== this.data.segmentTemplate.quantity ||
            !_.isEqual(this.formularyItemIDs(this.segmentTemplate), this.formularyItemIDs(this.data.segmentTemplate)) ||
            !_.isEqual(
                this.clinicalEquivalenceHashes(this.segmentTemplate),
                this.clinicalEquivalenceHashes(this.data.segmentTemplate)
            ) ||
            this.shortageRangeModified
        );
    }

    private hasEditingModeModifications(): boolean {
        return (
            this.hasSegmentModifications() ||
            this.hasSegmentTemplateModifications() ||
            this.hasPreferredFillOptionsModifications()
        );
    }

    private hasNonEditingModeModifications(): boolean {
        return (
            (this.newSegment && this.hasSegmentModifications()) ||
            (this.newTemplate && this.hasSegmentTemplateModifications()) ||
            this.hasPreferredFillOptionsModifications()
        );
    }

    allowSave() {
        const hasModifications = this.data.segmentEditingMode
            ? this.hasEditingModeModifications()
            : this.hasNonEditingModeModifications();

        return !this.saving && this.valueForm && this.valueForm.valid && hasModifications;
    }

    billingCodeChanged(): boolean {
        const compareBillingCode =
            this.segmentTemplate.billing_code && this.segmentTemplate.billing_code.length
                ? this.segmentTemplate.billing_code
                : null;
        return this.newTemplate
            ? !!(compareBillingCode && compareBillingCode.length)
            : compareBillingCode !== this.data.segmentTemplate.billing_code;
    }

    handleSaveSuccess(): void {
        this.barcodeScanService.removeListener('segment-template-edit-dialog');
        this.dialogRef.close(true);
    }

    handleSaveError(): void {
        this.barcodeScanService.removeListener('segment-template-edit-dialog');
        this.dialogRef.close();
    }

    saveSegment(saveData) {
        if (this.data.resource === SegmentResourceType.kitMaster) {
            return this.segmentResource.addSegment(this.$state.params.kitMasterId, saveData);
        }

        return this.containerResource.addContainerSegment(this.data.containerId, saveData);
    }

    save(): void {
        const saveData: any = {};
        const originalTemplate: any = this.data.segmentTemplate || {};

        if ((this.newSegment || this.data.segmentEditingMode) && this.segment.name && this.segment.name.length) {
            saveData.name = this.segment.name;
        }

        if (this.billingCodeChanged()) {
            saveData.billing_code = this.segmentTemplate.billing_code;
        }

        saveData.shortage_quantity_min = !this.showShortageSlider ? null : this.shortageMin;
        saveData.shortage_quantity_max = !this.showShortageSlider ? null : this.shortageMax;

        saveData.quantity = this.segmentTemplate.quantity;

        if (
            this.data.insertMode ||
            !_.isEqual(this.formularyItemIDs(this.segmentTemplate), this.formularyItemIDs(originalTemplate))
        ) {
            saveData.formulary_item_ids = this.formularyItemIDs(this.segmentTemplate);
        }

        if (
            !_.isEqual(
                this.clinicalEquivalenceHashes(this.segmentTemplate),
                this.clinicalEquivalenceHashes(originalTemplate)
            )
        ) {
            saveData.clinical_equivalence_hashes = this.clinicalEquivalenceHashes(this.segmentTemplate);
        }

        if (_.keys(saveData).length) {
            this.saving = true;

            if (this.data.segmentEditingMode) {
                saveData.id = this.segmentTemplate.id;
                Promise.all([
                    this.segmentResource.updateSegment(this.segment.id, {
                        name: saveData.name,
                    }),
                    this.segmentTemplateResource.updateSegmentTemplate(saveData),
                ])
                    .then(() => this.handleSaveSuccess())
                    .catch(() => this.handleSaveError());

                return;
            }

            if (this.newSegment) {
                this.saveSegment(saveData)
                    .then(() => {
                        this.handleSaveSuccess();
                    })
                    .catch(() => {
                        this.handleSaveError();
                    });
            } else if (this.newTemplate) {
                saveData.name = this.segment.name;

                this.segmentTemplateResource
                    .addSegmentTemplate(this.segment.id, saveData)
                    .then(() => {
                        this.handleSaveSuccess();
                    })
                    .catch((err) => {
                        if (err && err.__status === 422 && err.message) {
                            err.$handledExternally = true;
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                        this.handleSaveError();
                    });
            } else {
                saveData.id = this.segmentTemplate.id;
                this.segmentTemplateResource
                    .updateSegmentTemplate(saveData)
                    .then(() => {
                        this.handleSaveSuccess();
                    })
                    .catch(() => {
                        this.handleSaveError();
                    });
            }
        }
    }

    stopScanning(): void {
        this.scanning = false;
    }

    startScanning(): void {
        this.scanning = true;
    }

    dismiss(): void {
        this.barcodeScanService.removeListener('segment-template-edit-dialog');
        this.dialogRef.close();
    }
}
