import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class SegmentTemplateResource {
    API_ENDPOINT_V2 = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    addSegmentTemplate(segment_id, data) {
        return this.http.post<any>(`${this.API_ENDPOINT_V2}segments/${segment_id}/segment_templates`, data).toPromise();
    }

    updateSegmentTemplate(data, suppressError = false) {
        return this.http.put<any>(`${this.API_ENDPOINT_V2}segment_templates/${data.id}`, data).toPromise();
    }

    getSegmentTemplatesForSegment(segment, with_rank: boolean = false) {
        let template_index_param = '';
        const { segment_id, segment_version_fill_option_level } = segment;
        if (with_rank) {
            template_index_param = `?template_index=${segment_version_fill_option_level}`;
        }
        return this.http
            .get<any>(`${this.API_ENDPOINT_V2}segments/${segment_id}/segment_templates${template_index_param}`)
            .toPromise();
    }

    removeSegmentTemplate(data) {
        return this.http.delete<any>(`${this.API_ENDPOINT_V2}segment_templates/${data.id}`).toPromise();
    }
}
