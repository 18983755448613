import { Component, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Transition } from '@uirouter/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { isEmpty } from '@utils/objects';

// this report is not currently subscribable
// however, I left in this subscription dialog code in case we change item_strength
// to enable it, just change the reportIsSubscribable attribute on the report-filters in the html to true
import { ReportSubscriptionDialog } from '@components/dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { MatDialog } from '@angular/material/dialog';

interface Filters {
    page?: number;
    per_page?: number;
    recall_ids?: string;
    report_mode?: string; // bin or kit
    group_ids?: string;
}

interface FilterData {
    binKit: string;
    recalls: Array<any>;
}

interface PackageData {
    manufacturer: string;
    name: string;
    ndc: string;
    package_description_name: string;
    strength: string;
    lot_number: string;
}

@Component({
    selector: 'report-recalls',
    templateUrl: './report-recalls.html',
    styleUrls: ['./report-recalls.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportRecalls {
    @Input() reports: {
        report_categories: ReportCategory[]; //system or null
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    expandedElement: any; // used to track the current expanded row
    filters: Filters;
    filterData: FilterData;
    hasData: boolean;
    isDownloadable: boolean = true;
    reportDate: Date;
    hasRun: boolean = false;
    report: Report;
    report_mode: string = 'kit';
    isSystem: boolean = false;
    reportFilters: string[] = ['binKit', 'recalls'];
    resultsLength: number = 0;
    perPage: number = 100;
    lastEvent: any;
    showReport: boolean = false;

    kitDisplayedColumns: string[] = ['expander', 'kit_master_name', 'kit_physical_label', 'cart_name', 'location_name'];

    binDisplayedColumns: string[] = ['expander', 'bin_name'];

    systemKitDisplayedColumns: string[] = [
        'expander',
        'hospital_group',
        'hospital_name',
        'kit_master_name',
        'kit_physical_label',
        'cart_name',
        'location_name',
    ];

    systemBinDisplayedColumns: string[] = ['expander', 'hospital_group', 'hospital_name', 'bin_name'];

    innerDisplayedColumns: string[] = [
        'manufacturer',
        'full_item_name',
        'ndc',
        'package_formatted',
        'strength_formatted',
        'lot_num',
    ];

    @ViewChild('outerSort') sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<PackageData>>;

    constructor(
        protected $stateParams: Transition,
        private reportResource: ReportResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private cd: ChangeDetectorRef,
        private dialog: MatDialog,
        private reportSubscriptionResource: ReportSubscriptionResource
    ) {}

    ngOnInit() {
        this.reportDate = new Date();
        this.isSystem = this.$stateParams.params().isSystem;
        if (this.isSystem) {
            this.reportFilters = ['hospitalGroups', 'binKit', 'recalls'];
        }

        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'item_attribute'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'item_recalls');

        let recalls = null;
        let recallIDParams = this.$stateParams.params().recall_ids;
        if (!!recallIDParams) {
            recalls = this.$stateParams
                .params()
                .recall_ids.split(',')
                .map((recall) => {
                    return { id: Number(recall), selected: true };
                });
        }
        if (this.$stateParams.params().prefilter) {
            this.filterData = this.$stateParams.params().prefilter;
        } else {
            this.showReport = true;
            this.filterData = {
                binKit: 'kit',
                recalls: recalls,
            };
        }
    }

    runPrefilteredReport(event) {
        if (this.$stateParams.params().prefilter) {
            this.refreshReport(this.$stateParams.params().prefilter);
            this.showReport = true;
        }
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    displayColumns(): string[] {
        if (this.isSystem) {
            if (this.report_mode === 'bin') {
                return this.systemBinDisplayedColumns;
            } else {
                return this.systemKitDisplayedColumns;
            }
        } else {
            if (this.report_mode === 'bin') {
                return this.binDisplayedColumns;
            } else {
                return this.kitDisplayedColumns;
            }
        }
    }

    reportModeColumns(): number {
        if (this.isSystem) {
            if (this.report_mode === 'bin') {
                return this.systemBinDisplayedColumns.length;
            } else {
                return this.systemKitDisplayedColumns.length;
            }
        } else {
            if (this.report_mode === 'bin') {
                return this.binDisplayedColumns.length;
            } else {
                return this.kitDisplayedColumns.length;
            }
        }
    }

    loadPage(event) {
        this.perPage = event.pageSize;
        this.refreshReport(this.lastEvent, event.pageIndex + 1);
    }

    refreshReport(event: any, page: number = 1): void {
        // used to send event data in pagination
        this.lastEvent = event;

        this.filters = {
            page: page,
            per_page: this.perPage,
            recall_ids: event.recalls
                .map((recall) => recall.id)
                .filter((n) => n)
                .join('|'),
            report_mode: event.binKit,
        };

        if (this.isSystem && event.groupIds) {
            this.filters.group_ids = event.groupIds;
        }

        const promise = this.reportResource.itemsRecalledReport(this.filters, this.isSystem).then((rawReportData) => {
            let data;
            if (this.filters.report_mode === 'bin') {
                data = rawReportData.bins;
            } else {
                data = rawReportData.kits;
            }

            data.forEach((item, i) => {
                item.id = i;
            });

            this.dataSource = new MatTableDataSourceWithNaturalSort(data);
            this.report_mode = this.filters.report_mode;
            this.hasData = !!this.dataSource.data.length;
            this.resultsLength = rawReportData.total_rows;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.hasRun = true;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    toggleRow(row): void {
        // fix up the package and strength strings for display/sorting
        row.package_events.forEach((item) => {
            item.package_formatted = [item.package_size, item.package_size_uom, item.package_description_name]
                .filter((x) => x)
                .join(' ');
            item.strength_formatted = [item.item_strength_formatted, item.item_strength_uom].filter((x) => x).join(' ');
            item.full_item_name = [item.name, item.item_strength, item.item_strength_uom].filter((x) => x).join(' ');
        });

        // only create the datasource for the inner table on toggle for performance reasons
        this.dataSource.data[row.id].package_events_data = new MatTableDataSourceWithNaturalSort(row.package_events);
        this.expandedElement = this.expandedElement === row ? null : row;
        this.cd.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSourceWithNaturalSort<PackageData>).sort =
                    this.innerSort.toArray()[index])
        );
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            binKit: event.binKit,
            recalls: event.recalls,
            group_ids: event.hospitalGroups.group_ids,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
