import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class ScanResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();
    API_V2_ENDPOINT = this.configuration.kcEndpointV2();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    detailScan(scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}scans/${scanId}/detail`).toPromise();
    }

    getScanData(scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}scans/${scanId}/batch_data`).toPromise();
    }

    scanData(scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}scans/${scanId}`).toPromise();
    }

    scanVerification(scanId: number, data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}scans/${scanId}/verifications`, data).toPromise();
    }

    getBillingSheetForScan(scanId: number): Promise<any> {
        return this.http.get<any>(`${this.API_V2_ENDPOINT}views/kit_check/scans/${scanId}/billing_sheet`).toPromise();
    }
}
